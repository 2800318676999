import { AfterViewInit, ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { IGroupedProjectCaseProjectTypes, ProjectsProvider } from '../../providers/projects-provider';
import { ProjectsEditProjectAcademicComponent } from '../../components/edit-project-academic/edit-project-academic';
import { ProjectsEditProjectSituationComponent } from '../../components/edit-project-situation/edit-project-situation';
import { ProjectsEditProjectFundingComponent } from '../../components/edit-project-funding/edit-project-funding';
import {
    ProjectsEditProjectMiscellaneousComponent
} from '../../components/edit-project-miscellaneous/edit-project-miscellaneous';
import { ProjectsEditProjectPartnershipComponent } from '../../components/edit-project-partnership/edit-project-partnership';
import { ProjectsEditProjectProjectComponent } from '../../components/edit-project-project/edit-project-project';
import { ProjectsEditProjectTeamComponent } from '../../components/edit-project-team/edit-project-team';
import { ProjectsEditProjectReviewComponent } from '../../components/edit-project-review/edit-project-review';
import {
    ProjectCaseProjectTypes,
    ProjectCaseTherapeuticArea,
    ProjectsEditProjectCaseComponent
} from '../../components/edit-project-case/edit-project-case';
import { ProjectRights } from '../../providers/project-rights';
import {
    Project,
    ProjectCase,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectMembership,
    ProjectPastFunding,
    ProjectPrize,
    ProjectTeamMember,
    User,
    User_AcademicInstitution
} from '../../../_common/providers/models';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'projects-edit-page',
    providers: [],
    styleUrls: ['./edit.scss'],
    templateUrl: './edit.html'
})
export class ProjectsEditPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public isSaveLoading: boolean = false;
    public is401: boolean = false;
    public is404: boolean = false;
    public listingProgress: number = 0;
    public rights: ProjectRights = new ProjectRights(this._utils);

    public backMenu: MenuItem = null;
    public backUrl: string = null;
    public backTitle: string = this._translater.instant('Back');

    public project: Project = new Project();
    public membership: ProjectMembership = null;
    public projectTypeIds: number[] = [];
    public projectCaseProjectType: ProjectCase_ProjectType[] = null;
    public groupedProjectCaseProjectType: IGroupedProjectCaseProjectTypes[] = null;
    public projectCaseTherapeuticArea: ProjectCase_TherapeuticArea[] = null;
    public therapeuticAreaIds: number[] = [];
    public prizes: ProjectPrize[] = [];
    public pastFundings: ProjectPastFunding[] = [];
    public teamMembers: ProjectTeamMember[] = [];
    public projectCases: ProjectCase[] = [];
    public lastUpdater: User = null;

    @ViewChild(MatStepper, {static: false}) public stepper: MatStepper;
    @ViewChild(ProjectsEditProjectAcademicComponent, {static: false}) public academicComponent: ProjectsEditProjectAcademicComponent;
    @ViewChild(ProjectsEditProjectCaseComponent, {static: false}) public casesComponent: ProjectsEditProjectCaseComponent;
    @ViewChild(ProjectsEditProjectSituationComponent, {static: false}) public situationComponent: ProjectsEditProjectSituationComponent;
    @ViewChild(ProjectsEditProjectFundingComponent, {static: false}) public fundingComponent: ProjectsEditProjectFundingComponent;
    @ViewChild(ProjectsEditProjectMiscellaneousComponent, {static: false}) public miscellaneousComponent: ProjectsEditProjectMiscellaneousComponent;
    @ViewChild(ProjectsEditProjectPartnershipComponent, {static: false}) public partnershipComponent: ProjectsEditProjectPartnershipComponent;
    @ViewChild(ProjectsEditProjectProjectComponent, {static: false}) public projectComponent: ProjectsEditProjectProjectComponent;
    @ViewChild(ProjectsEditProjectTeamComponent, {static: false}) public teamComponent: ProjectsEditProjectTeamComponent;
    @ViewChild(ProjectsEditProjectReviewComponent, {static: false}) public reviewComponent: ProjectsEditProjectReviewComponent;

    @ViewChildren(ProjectsEditProjectCaseComponent) public casesComponents!: QueryList<ProjectsEditProjectCaseComponent>;

    public mandatoryFields: ProjectListingFieldsGroup[] = [];
    //
    public readonly REVIEW_IDENTIFIER: string = 'projectcase-';

    //
    //
    // CONSTRUCTOR
    //
    private _user_academicInstitutions: User_AcademicInstitution[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _toaster: MatSnackBar,
        private _dialoger: MatDialog,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _changesGuard: HasPendingChangesGuard,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        // hack pour corriger position du stepper, inspiré de : https://github.com/angular/material2/issues/8881
        this.stepper.selectionChange.subscribe((event: any) => {
            this.scrollToStep(event.selectedIndex);
        });

        this.navigation.updateMenus();
        this._route.params.subscribe((params: any) => {
            this.isLoading = true;
            this.loadProject(parseInt(params.projectId))
                .then(() => {
                    this.loadData()
                        .then(() => {
                            this.stepper.selectionChange.subscribe(() => this.onStepChanged());
                            this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);
                            this.initFormFields();
                            this.reviewComponent.updateMissingMandatoryFields();
                            this.updateListingProgress();
                            this.isLoading = false;
                        })
                        .catch((error: any) => this.onError(error));
                })
                .catch((error: any) => this.onError(error));

            this._changeDetector.detectChanges();
        });

        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backUrl = params.backUrl;
                if (params.backTitle) {
                    this.backTitle = params.backTitle;
                }
                this.backMenu = {
                    icon: 'arrow_back',
                    title: this.backTitle,
                    url: this.backUrl
                };
                this._changeDetector.detectChanges();
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canProjectCasesSave(): boolean {
        // this.casesComponents.some returns true if any of canSave() is false, so the return value has to be negated
        return !this.casesComponents.some((element) => element.canSave() === false);
    }

    public canSave(): boolean {
        return this.rights.EDIT
            && this.situationComponent && this.situationComponent.canSave()
            && this.fundingComponent && this.fundingComponent.canSave()
            // && this.miscellaneousComponent && this.miscellaneousComponent.canSave()
            && this.partnershipComponent && this.partnershipComponent.canSave()
            && this.projectComponent && this.projectComponent.canSave()
            && this.teamComponent && this.teamComponent.canSave()
            && this.academicComponent && this.academicComponent.canSave()
            && this.canProjectCasesSave();
    }

    public onListUsersClicked(): void {
        this._router.navigate(['academic-ventures', this.project.id, 'memberships'], {
            queryParams: {
                backUrl: this._router.url
            }
        });
    }

    public onSaveClicked(): void {
        this.isLoading = true;
        this.isSaveLoading = true;
        this.save()
            .then(() => {
                // au cas où le nom du projet ait changé ...
                this.navigation.updateMenus();
                this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);

                this.isLoading = false;
                this.isSaveLoading = false;
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
                this._changesGuard.unregisterChanges();
            })
            .catch((error: any) => this.onError(error));
    }

    public async onRemoveProjectCase(projectCase: ProjectCase): Promise<void> {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_projectCase'
            }
        });
        dialog.afterClosed().subscribe(async (confirmed: boolean) => {
            if (confirmed) {
                try {
                    this.isLoading = true;
                    await this.projectsProvider.deleteProjectCase(projectCase);
                    this._toaster.open(this._translater.instant('ProjectCase_deleted'), '', {duration: 5000});

                    // remove projectCase
                    this.projectCases = this.projectCases.filter((item) => item.id !== projectCase.id);

                    // remove mandatory fields
                    this.mandatoryFields[1].fields = this.mandatoryFields[1].fields.filter((item) => item.identifier !== projectCase.id);

                    // remove projectTypes and therapeuticAreas
                    this.projectCaseProjectType = this.projectCaseProjectType.filter((item) => item.projectCaseId !== projectCase.id);
                    this.projectCaseTherapeuticArea = this.projectCaseTherapeuticArea.filter((item) => item.projectCaseId !== projectCase.id);

                    // add empty FormControl if no projectCase exist for review component
                    if (this.projectCases.length === 0) {
                        this.mandatoryFields[1].fields.push({
                            fieldId: 'step_project_cases',
                            name: 'Projects',
                            formControl: new FormControl()
                        });
                    }
                    this.isLoading = false;
                    this.onFormChanged();
                } catch (e) {
                    this.onError(e);
                }
            }
        });
    }

    public onFormChanged(): void {
        this.reviewComponent.updateMissingMandatoryFields();
        this.updateListingProgress();
    }

    public onAddProjectCase(projectCasesId: number): void {
        const prefix = this.REVIEW_IDENTIFIER + projectCasesId;
        // access projectCaseComponent from childrens with index
        const children = this.casesComponents.toArray();
        const projectCaseComponent = children[this.projectCases.findIndex((projectCase) => projectCase.id === projectCasesId)];
        this.mandatoryFields[1].fields.push(
            {
                fieldId: `${prefix}_title`,
                name: 'Name',
                formControl: projectCaseComponent.titleFormControl,
                identifier: projectCasesId
            },
            {
                fieldId: `${prefix}_type`,
                name: 'Type_of_project',
                formControl: projectCaseComponent.projectTypeFormControl,
                identifier: projectCasesId
            },
            {
                fieldId: `${prefix}_area`,
                name: 'Therapeutic_area',
                formControl: projectCaseComponent.therapeuticAreaFormControl,
                identifier: projectCasesId
            },
            {
                fieldId: `${prefix}_description`,
                name: 'Description',
                formControl: projectCaseComponent.descriptionFormControl,
                identifier: projectCasesId
            },
            {
                fieldId: `${prefix}_developmentPhase`,
                name: 'Phase_of_development',
                formControl: projectCaseComponent.developmentPhaseFormControl,
                identifier: projectCasesId
            },
            {
                fieldId: `${prefix}_patent`,
                name: 'Patent',
                formControl: projectCaseComponent.patentStateFormControl,
                identifier: projectCasesId
            },
        );
        this.reviewComponent.updateMissingMandatoryFields();
    }

    public onStepChanged(): void {
        this.reviewComponent.updateMissingMandatoryFields();
    }

    public onAskedForReview(): void {
        this.isLoading = true;
        this.isSaveLoading = true;
        this.save()
            .then(() => {
                // au cas où le nom du projet ait changé ...
                this.navigation.updateMenus();
                this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);
                this._changesGuard.unregisterChanges();

                this.askForReview()
                    .then(() => {
                        this.isLoading = false;
                        this.isSaveLoading = false;
                        this.rights.evaluate({
                            project: this.project,
                            membership: this.membership,
                            user: this.currentUser.user,
                            user_academicInstitutions: this._user_academicInstitutions
                        });
                        this.is401 = !this.rights.SEE_EDIT_FORM;
                        this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
                    })
                    .catch((error: any) => this.onError(error));
            })
            .catch((error: any) => this.onError(error));
    }

    public onDeleteClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_project'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this.projectsProvider.deleteProject(this.project)
                    .then(() => {
                        this._toaster.open(this._translater.instant('Project_deleted'), '', {duration: 5000});
                        this._router.navigate(['/']);
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    public onChangeStepClicked(index: number) {
        if (index > this.stepper.selectedIndex) {
            const diff: number = index - this.stepper.selectedIndex;
            for (let i = 0; i < diff; i++) {
                this.stepper.next();
            }
        } else if (index < this.stepper.selectedIndex) {
            const diff: number = this.stepper.selectedIndex - index;
            for (let i = 0; i < diff; i++) {
                this.stepper.previous();
            }
        }
    }

    public onAddNewProjectCaseClicked(): void {
        this.isLoading = true;
        this.saveProjectCase()
            .then(() => {
                this.isLoading = false;
                // reset mandatory field for projectCase after adding one
                if (this.projectCases.length > 0) {
                    this.mandatoryFields[1].fields = [];
                    this.onFormChanged();
                }
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public getProjectCaseProjectType(projectCaseId: number) {
        return this.projectCaseProjectType.filter((item) => item.projectCaseId === projectCaseId);
    }

    public getProjectCaseTherapeuticArea(projectCaseId: number) {
        return this.projectCaseTherapeuticArea.filter((item) => item.projectCaseId === projectCaseId);
    }

    public onProjectCaseProjectTypeChange(projectCaseProjectType: ProjectCaseProjectTypes) {
        // remove all entries related to projectCaseId
        this.projectCaseProjectType = this.projectCaseProjectType.filter((item) => item.projectCaseId !== projectCaseProjectType.projectCaseId);
        // add newly
        this.projectCaseProjectType.push(...projectCaseProjectType.types);
    }

    public onProjectCaseTherapeuticAreaChange(projectCaseTherapeuticArea: ProjectCaseTherapeuticArea) {
        // remove all entries related to projectCaseId
        this.projectCaseTherapeuticArea = this.projectCaseTherapeuticArea.filter((item) => item.projectCaseId !== projectCaseTherapeuticArea.projectCaseId);
        // add newly
        this.projectCaseTherapeuticArea.push(...projectCaseTherapeuticArea.areas);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    // creates new empty projectCase and save it with all projectCases in case adding and updating at the same time
    // needed, because required field reference in ask-for-review-component needs identifier for each project case
    private saveProjectCase(): Promise<ProjectCase> {
        return new Promise<ProjectCase>((resolve, reject) => {
            const singleProjectCase: ProjectCase = new ProjectCase();
            singleProjectCase.projectId = this.project.id;
            this.projectsProvider.saveProjectCase(this.project.id, [...this.projectCases, singleProjectCase], this.projectCaseProjectType, this.projectCaseTherapeuticArea)
                .then((projectCase: ProjectCase[]) => {
                    this.projectCases = projectCase;
                    resolve(singleProjectCase);
                })
                .catch((error: any) => this.onError(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadProject(projectId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.currentUser.isLoggedIn()) {
                reject({status: 401});
            }

            Promise.all([
                this._storage.select(Project).where('id').equals(projectId).get(1),
                this._storage.select(ProjectMembership)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('projectId').equals(projectId)
                    .get(1),
                this._storage.select(ProjectCase).where('projectId').equals(projectId).get()
            ])
                .then((data: any[]) => {
                    if (!data[0]) {
                        reject({status: 404});
                        return;
                    }

                    const project: Project = data[0].clone(); // TODO si on clone pas, il y a un scénario quelque part que j'ai pas réussi à identifier qui fait altère le model en DB et je pige pas bien pourquoi ... à creuser
                    if (project.raisingFundsAmount) {
                        project.raisingFundsAmount = project.raisingFundsAmount / 100; // on convertit le montant en dollars (il est en cents en DB)
                    }
                    this.project = project;

                    this.membership = data[1];

                    this.projectCases = data[2];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                /* 0 */ this._storage.select(ProjectCase_ProjectType).where('projectCaseId').in(this._utils.getKeyValues(this.projectCases, 'id')).get(),
                /* 1 */ this._storage.select(ProjectCase_TherapeuticArea).where('projectCaseId').in(this._utils.getKeyValues(this.projectCases, 'id')).get(),
                /* 2 */ this._storage.select(ProjectPrize).where('projectId').equals(this.project.id).get(),
                /* 3 */ this._storage.select(ProjectPastFunding).where('projectId').equals(this.project.id).get(),
                /* 4 */ this._storage.select(ProjectTeamMember).where('projectId').equals(this.project.id).get(),
                /* 5 */ this._storage.select(User).where('id').equals(this.project.updaterId).get(1),
                /* 6 */ this._storage.select(User_AcademicInstitution).where('userId').equals(this.currentUser.user.id).get(),
            ])
                .then((data: any[]) => {
                    this.projectTypeIds = this._utils.getKeyValues(data[0], 'projectTypeId');
                    this.projectCaseProjectType = data[0];
                    this.therapeuticAreaIds = this._utils.getKeyValues(data[1], 'therapeuticAreaId');
                    this.projectCaseTherapeuticArea = data[1];
                    this.prizes = data[2];
                    const pastFundings: ProjectPastFunding[] = data[3];
                    for (let i = 0; i < pastFundings.length; i++) {
                        pastFundings[i] = pastFundings[i];
                        if (pastFundings[i].amount) {
                            pastFundings[i].amount = pastFundings[i].amount / 100; // on convertit le montant en dollars (il est en cents en DB)
                        }
                    }
                    this.pastFundings = data[3];
                    this.teamMembers = data[4];
                    this.lastUpdater = data[5];
                    this._user_academicInstitutions = data[6];

                    this.rights.evaluate({
                        user: this.currentUser.user,
                        project: this.project,
                        membership: this.membership,
                        user_academicInstitutions: this._user_academicInstitutions
                    });
                    this.is401 = !this.rights.SEE_EDIT_FORM;

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private initFormFields(): void {
        this.mandatoryFields = [
            // les step mentionné dans les fieldId ne correspondent plus aux stepIndex parce qu'on en a rajouté par la suite ...
            {
                stepIndex: 0,
                stepName: 'Biotech_venture',
                fields: [
                    {fieldId: 'step0_name', name: 'Name', formControl: this.projectComponent.nameFormControl},
                ]
            },
            {
                stepIndex: 1,
                stepName: 'Projects',
                fields: []
            },
            {
                stepIndex: 2,
                stepName: 'Current_situation',
                fields: [
                    {
                        fieldId: 'step2_incorporationYear',
                        name: 'Year_of_incorporation',
                        formControl: this.situationComponent.incorporationYearFormControl
                    },
                    {
                        fieldId: 'step2_tradeType',
                        name: 'Project_trade_type',
                        formControl: this.situationComponent.projectTradeTypeFormControl
                    }
                ]
            },
            {
                stepIndex: 3,
                stepName: 'Past_funding_and_prizes',
                fields: []
            },
            {
                stepIndex: 4,
                stepName: 'Partnership_and_outlicensing',
                fields: []
            },
            {
                stepIndex: 5,
                stepName: 'Team_and_contacts',
                fields: [
                    {
                        fieldId: 'step5_firstName',
                        name: 'Contact_first_name',
                        formControl: this.teamComponent.contactFirstNameFormControl
                    },
                    {
                        fieldId: 'step5_lastName',
                        name: 'Contact_last_name',
                        formControl: this.teamComponent.contactLastNameFormControl
                    },
                    {
                        fieldId: 'step5_address',
                        name: 'Contact_address',
                        formControl: this.teamComponent.contactAddressFormControl
                    },
                    {fieldId: 'step5_email', name: 'Contact_email', formControl: this.teamComponent.contactEmailFormControl},
                    {fieldId: 'step5_website', name: 'Website', formControl: this.teamComponent.contactWebsiteFormControl},
                ]
            },
            {
                stepIndex: 6,
                stepName: 'Academic_link',
                fields: []
            },
            {
                stepIndex: 7,
                stepName: 'Miscellaneous',
                fields: []
            }
        ];

        // add empty FormControl if no projectCase exist
        if (this.projectCases.length === 0) {
            this.mandatoryFields[1].fields.push({
                fieldId: 'step_project_cases',
                name: 'Projects',
                formControl: new FormControl()
            });
        }
    }

    private save(): Promise<void> {
        let project: Project = this.project.clone();
        if (project.raisingFundsAmount) {
            project.raisingFundsAmount *= 100; // on convertit en cents ...
        }
        // on remet les fichiers à leur emplacement
        project.logo = this.project.logo;
        project.illustration = this.project.illustration;
        project.teamPicture = this.project.teamPicture;

        let pastFundings: ProjectPastFunding[] = [];
        for (let i = 0; i < this.pastFundings.length; i++) {
            pastFundings.push(this.pastFundings[i].clone());
            if (pastFundings[i].amount) {
                pastFundings[i].amount *= 100; // on convertit en cents ...
            }
        }

        const shouldSaveCases = this.projectCases.length > 0;

        return new Promise<void>((resolve, reject) => {
            Promise.all([
                /* 0 */ this.projectsProvider.saveProject(project),
                /* 3 1 */ this.projectsProvider.saveProjectPrizes(this.project.id, this.prizes),
                /* 4 2*/ this.projectsProvider.saveProjectPastFundings(this.project.id, pastFundings),
                /* 5  3*/ this.projectsProvider.saveProjectTeamMembers(this.project.id, this.teamMembers),
                /* 6 4*/ shouldSaveCases && this.projectsProvider.saveProjectCase(this.project.id, this.projectCases, this.projectCaseProjectType, this.projectCaseTherapeuticArea)
            ])
                .then((results: any[]) => {
                    const promises: Promise<any>[] = [];
                    if (this.rights.EDIT_ACADEMIC_INSTITUTION_1) {
                        promises.push(this.projectsProvider.saveAcademicInsitution1(this.project.id, this.project.academicInstitution1Id));
                    }

                    Promise.all(promises)
                        .then((nextResults: any[]) => {
                            project = results[0];
                            if (project.raisingFundsAmount) {
                                project.raisingFundsAmount = project.raisingFundsAmount / 100; // on convertit en dollars
                            }
                            if (nextResults.length > 0) {
                                project.academicInstitution1Id = nextResults[0].academicInstitution1Id;
                            }

                            pastFundings = results[2];
                            for (let i = 0; i < this.pastFundings.length; i++) {
                                if (pastFundings[i].amount) {
                                    pastFundings[i].amount = pastFundings[i].amount / 100; // on convertit en dollars
                                }
                            }

                            this.project = project;
                            this.projectCases = shouldSaveCases ? results[4] : this.projectCases;
                            this.prizes = results[1];
                            this.pastFundings = pastFundings;
                            this.teamMembers = results[3];

                            // delete mandatory fields for projects (they are added again on view render)
                            this.mandatoryFields[1].fields = [];

                            resolve();
                        })
                        .catch(reject);
                })
                .catch((error: any) => reject(error));
        });
    }

    private askForReview(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.projectsProvider.askForReview(this.project)
                .then((project: Project) => {
                    this.project = project;
                    this.rights.evaluate({
                        user: this.currentUser.user,
                        investorProfile: null,
                        membership: this.membership,
                        project: this.project,
                        user_academicInstitutions: this._user_academicInstitutions
                    });
                    this.is401 = !this.rights.SEE_EDIT_FORM;

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private scrollToStep(index: number): void {
        setTimeout(() => {
            document.getElementsByTagName('html')[0].scrollTo({
                left: 0,
                top: 96 + ((index - 1) * 72),
                behavior: 'smooth'
            });
        }, 250);
    }

    private updateListingProgress(): void {
        this.listingProgress = this.projectsProvider.computeListingProgress(
            this.project,
            this.projectTypeIds.length,
            this.therapeuticAreaIds.length,
            this.teamMembers.length
        );
    }

    private onError(error: any): void {
        this.isLoading = false;
        this.isSaveLoading = false;

        if (error.status === 404) {
            this.is404 = true;
        } else if (error.status === 422) {
            if (error.error && error.error.errors && error.error.errors) {
                if (error.error.errors['item.logo']) {
                    this._toaster.open(this._translater.instant('Image_has_invalid_dimensions'), '', {duration: 5000});
                    this.stepper.selectedIndex = 1;
                } else if (error.error.errors['item.illustration']) {
                    this._toaster.open(this._translater.instant('Image_has_invalid_dimensions'), '', {duration: 5000});
                    this.stepper.selectedIndex = 1;
                } else if (error.error.errors['item.teamPicture']) {
                    this._toaster.open(this._translater.instant('Image_has_invalid_dimensions'), '', {duration: 5000});
                    this.stepper.selectedIndex = 5;
                } else {
                    this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
                    console.log(error);
                }
            } else {
                this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
                console.log(error);
            }
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
            console.log(error);
        }
    }
}

export interface ProjectListingFieldsGroup {
    stepIndex: number;
    stepName: string;
    fields: {
        name: string,
        identifier?: number,
        formControl: FormControl,
        fieldId: string // pour pouvoir le focus, avec le formcontrol c'est pas possible... https://github.com/angular/angular/issues/12463
    }[];
}
