import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Conf } from '../../../_conf';
import { ProjectsProvider, ProjectTypeGoupWithTypes } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { Utils } from '../../../_common/providers/utils';
import { ProjectRights } from '../../providers/project-rights';
import {
    DevelopmentPhase,
    PatentState,
    ProjectCase,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectType,
    TherapeuticArea
} from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-case',
    providers: [],
    styleUrls: ['./edit-project-case.scss'],
    templateUrl: './edit-project-case.html'
})
export class ProjectsEditProjectCaseComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly TITLE_MAX_LENGTH: number = 250;
    public readonly SUBTITLE_MAX_LENGTH: number = 250;
    public readonly DESCRIPTION_MAX_LENGTH: number = 1000;
    public readonly UNIQUENESS_MAX_LENGTH: number = 1000;
    public readonly UNMET_NEED_MAX_LENGTH: number = 1000;
    public readonly MARKET_SIZE_MAX_LENGTH: number = 1000;
    public readonly COMPETITION_MAX_LENGTH: number = 1000;
    public readonly REVIEW_IDENTIFIER: string = 'projectcase-';

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showMore: boolean = false;

    public titleFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.TITLE_MAX_LENGTH),
    ]);
    public subtitleFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.SUBTITLE_MAX_LENGTH)
    ]);
    public descriptionFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
    ]);
    public uniquenessFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.UNIQUENESS_MAX_LENGTH)
    ]);
    public unmetNeedFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.UNMET_NEED_MAX_LENGTH)
    ]);
    public marketSizeFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.MARKET_SIZE_MAX_LENGTH)
    ]);
    public competitionFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.COMPETITION_MAX_LENGTH)
    ]);
    public projectTypeFormControl: FormControl = new FormControl('', [
        Validators.min(1),
    ]);
    public therapeuticAreaFormControl: FormControl = new FormControl('', [
        Validators.min(1),
    ]);

    public developmentPhaseFormControl: FormControl = new FormControl('', [
        Validators.min(1),
    ]);
    public developmentPhases: DevelopmentPhase[] = [];
    public patentStateFormControl: FormControl = new FormControl('', [
        Validators.min(1),
    ]);

    public _projectTypeIds: number[] = [];
    public _therapeuticAreaIds: number[] = [];
    @Output() public onProjectCaseProjectTypeChange: EventEmitter<ProjectCaseProjectTypes> = new EventEmitter<ProjectCaseProjectTypes>();
    @Output() public onProjectCaseTherapeuticAreaChange: EventEmitter<ProjectCaseTherapeuticArea> = new EventEmitter<ProjectCaseTherapeuticArea>();
    @Output() public onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onAddProjectCase: EventEmitter<number> = new EventEmitter<number>();
    @Output() public onRemoveProjectCase: EventEmitter<ProjectCase> = new EventEmitter<ProjectCase>();
    public projectTypeGroups: ProjectTypeGoupWithTypes[] = [];
    public projectTypes: ProjectType[] = [];
    public therapeuticAreas: TherapeuticArea[] = [];
    public patentStates: PatentState[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _dialoger: MatDialog,
        private _utils: Utils,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _projectCase: ProjectCase = new ProjectCase();

    public get projectCase() {
        return this._projectCase;
    }

    @Input()
    public set projectCase(projectCase: ProjectCase) {
        this._projectCase = projectCase;
        this.titleFormControl.setValue(projectCase.title ? projectCase.title : '');
        this.developmentPhaseFormControl.setValue(projectCase.developmentPhaseId);
        this.patentStateFormControl.setValue(projectCase.patentStateId);
        this.subtitleFormControl.setValue(projectCase.subtitle ? projectCase.subtitle : '');
        this.descriptionFormControl.setValue(projectCase.description ? projectCase.description : '');
        this.uniquenessFormControl.setValue(projectCase.uniqueness ? projectCase.uniqueness : '');
        this.unmetNeedFormControl.setValue(projectCase.unmetNeed ? projectCase.unmetNeed : '');
        this.marketSizeFormControl.setValue(projectCase.marketSize ? projectCase.marketSize : '');
        this.competitionFormControl.setValue(projectCase.competition ? projectCase.competition : '');
        this.updateRights();
    }

    private _rights: ProjectRights = new ProjectRights(this._utils);

    public get rights() {
        return this._rights;
    }

    @Input()
    public set rights(rights: ProjectRights) {
        this._rights = rights;
        this.updateRights();
    }

    // bind projectCaseProjectType and filter with projectCaseId
    private _projectCaseProjectType: ProjectCase_ProjectType[] = [];

    public get projectCaseProjectType() {
        return this._projectCaseProjectType;
    }

    @Input()
    public set projectCaseProjectType(projectCaseProjectType: ProjectCase_ProjectType[]) {
        this._projectCaseProjectType = projectCaseProjectType;
        this._projectTypeIds = this._utils.getKeyValues(projectCaseProjectType, 'projectTypeId');
        this.projectTypeFormControl.setValue(this._projectTypeIds);
    }

    // bind projectCaseTherapeuticArea and filter for projectCases
    private _projectCaseTherapeuticArea: ProjectCase_TherapeuticArea[] = [];

    public get projectCaseTherapeuticArea() {
        return this._projectCaseTherapeuticArea;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set projectCaseTherapeuticArea(projectCaseTherapeuticArea: ProjectCase_TherapeuticArea[]) {
        this._projectCaseTherapeuticArea = projectCaseTherapeuticArea;
        this.therapeuticAreaFormControl.setValue(this._utils.getKeyValues(projectCaseTherapeuticArea, 'therapeuticAreaId'));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public async ngAfterViewInit() {
        try {
            await this.loadStaticData();
            this.onAddProjectCase.emit(this._projectCase.id);
        } catch (e) {
            this.onError(e);
        }
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onProjectTypeIdChanged(): void {
        this._onFormChanged();
    }

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public canSave(): boolean {
        return !this.descriptionFormControl.invalid
            && !this.titleFormControl.invalid
            && !this.uniquenessFormControl.invalid
            && !this.marketSizeFormControl.invalid
            && !this.competitionFormControl.invalid
            && !this.projectTypeFormControl.invalid
            && !this.subtitleFormControl.invalid
            && !this.therapeuticAreaFormControl.invalid
            && !this.uniquenessFormControl.invalid
            && !this.developmentPhaseFormControl.invalid;
    }

    public onAllProjectTypesClicked(): void {
        this.projectTypeFormControl.setValue(this._utils.getKeyValues(this.projectTypes, 'id'));
        this.onProjectTypeIdChanged();
    }

    public onNoProjectTypesClicked(): void {
        this.projectTypeFormControl.setValue([]);
        this.onProjectTypeIdChanged();
    }

    public getProjectGroupTypeIcon(groupData: ProjectTypeGoupWithTypes): string {
        const selectedItems: number[] = this._utils.findManyIn(this._projectTypeIds, groupData.types);
        if (selectedItems.length === groupData.types.length) {
            return 'check_box';
        } else if (selectedItems.length === 0) {
            return 'check_box_outline_blank';
        }

        return 'indeterminate_check_box';
    }

    public onProjectTypeGroupClicked(groupData: ProjectTypeGoupWithTypes): void {
        const selectedItems: ProjectType[] = this._utils.findManyIn(this._projectTypeIds, groupData.types);
        if (selectedItems.length === groupData.types.length) {
            selectedItems.forEach((item: ProjectType) => {
                this._projectTypeIds.splice(this._projectTypeIds.indexOf(item.id), 1);
            });
        } else {
            groupData.types.forEach((type: ProjectType) => {
                if (this._projectTypeIds.indexOf(type.id) < 0) {
                    this._projectTypeIds.push(type.id);
                }
            });
        }

        this.projectTypeFormControl.setValue(this._projectTypeIds);
        this.onProjectTypeIdChanged();
    }

    public onAllTherapeuticAreasClicked(): void {
        this.therapeuticAreaFormControl.setValue(this._utils.getKeyValues(this.therapeuticAreas, 'id'));
        this._onFormChanged();
    }

    public onNoTherapeuticAreasClicked(): void {
        this.therapeuticAreaFormControl.setValue([]);
        this._onFormChanged();
    }

    public onToggleShowMore(): void {
        this.showMore = !this.showMore;
        if (!this.showMore) {
            setTimeout(() => {
                document.getElementsByTagName('html')[0].scrollTo({
                    left: 0,
                    top: 96,
                    behavior: 'smooth'
                });
            }, 10);
        }
    }

    public getIdField(field: string) {
        return `${this.REVIEW_IDENTIFIER}${this._projectCase.id}_${field}`;
    }

    public removeProjectCase(): void {
        this.onRemoveProjectCase.emit(this._projectCase);
    }

    public hasMissingFields(): boolean {
        return !((this._projectCase.title !== '' && this._projectCase.title !== null) &&
            this._projectCase.developmentPhaseId !== null &&
            (this._projectCase.description !== null && this._projectCase.description !== '') &&
            this._projectCase.patentStateId !== null &&
            this._projectCaseProjectType.length > 0 &&
            this._projectCaseTherapeuticArea.length > 0);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.projectsProvider.getProjectTypeGroups(),
                this._storage.select(TherapeuticArea).orderBy('name').get(),
                this._storage.select(ProjectType).get(),
                this._storage.select(PatentState).get(),
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get()
            ])
                .then((data: any[]) => {
                    this.projectTypeGroups = data[0];
                    this.therapeuticAreas = data[1];
                    this.projectTypes = data[2];
                    this.patentStates = data[3];
                    this.developmentPhases = data[4];
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.titleFormControl.enable();
            this.subtitleFormControl.enable();
            this.projectTypeFormControl.enable();
            this.therapeuticAreaFormControl.enable();
            this.descriptionFormControl.enable();
            this.uniquenessFormControl.enable();
            this.unmetNeedFormControl.enable();
            this.marketSizeFormControl.enable();
            this.competitionFormControl.enable();
            this.developmentPhaseFormControl.enable();
            this.patentStateFormControl.enable();
        } else {
            this.titleFormControl.disable();
            this.subtitleFormControl.disable();
            this.projectTypeFormControl.disable();
            this.therapeuticAreaFormControl.disable();
            this.descriptionFormControl.disable();
            this.uniquenessFormControl.disable();
            this.unmetNeedFormControl.disable();
            this.marketSizeFormControl.disable();
            this.competitionFormControl.disable();
            this.developmentPhaseFormControl.disable();
            this.patentStateFormControl.disable();
        }
    }

    private fireChange(): void {
        this._projectCase.title = this.titleFormControl.value;
        this._projectCase.subtitle = this.subtitleFormControl.value;
        this._projectCase.description = this.descriptionFormControl.value;
        this._projectCase.uniqueness = this.uniquenessFormControl.value;
        this._projectCase.unmetNeed = this.unmetNeedFormControl.value;
        this._projectCase.marketSize = this.marketSizeFormControl.value;
        this._projectCase.competition = this.competitionFormControl.value;
        this._projectCase.developmentPhaseId = this.developmentPhaseFormControl.value;
        this._projectCase.patentStateId = this.patentStateFormControl.value;

        this._projectTypeIds = this.projectTypeFormControl.value ? this.projectTypeFormControl.value : [];
        this._therapeuticAreaIds = this.therapeuticAreaFormControl.value ? this.therapeuticAreaFormControl.value : [];

        // filter projectTypes
        if (this._projectTypeIds.length < 1) {
            // remove all
            this._projectCaseProjectType = [];
            // this._projectCaseProjectType = this._projectCaseProjectType.filter((item) => item.projectCaseId !== this._projectCase.id);
        } else {
            // remove items which are not selected anymore
            this._projectTypeIds.forEach((itemId) => {

                const selectedItemsOnly = (item) => this._projectTypeIds.indexOf(item.projectTypeId) > -1;
                this._projectCaseProjectType = this._projectCaseProjectType.filter(selectedItemsOnly);

                // add new items
                if (!this._projectCaseProjectType.some((e) => e.projectTypeId === itemId)) {
                    const newItem = new ProjectCase_ProjectType();
                    newItem.projectCaseId = this._projectCase.id;
                    newItem.projectTypeId = itemId;
                    this._projectCaseProjectType.push(newItem);
                }
            });
        }

        // filter therapeuticArea
        if (this._therapeuticAreaIds.length < 1) {
            this._projectCaseTherapeuticArea = [];
        } else {
            // remove items which are not selected anymore
            this._therapeuticAreaIds.forEach((itemId) => {

                const selectedItemsOnly = (item) => this._therapeuticAreaIds.indexOf(item.therapeuticAreaId) > -1;
                this._projectCaseTherapeuticArea = this._projectCaseTherapeuticArea.filter(selectedItemsOnly);

                // add new items
                if (!this._projectCaseTherapeuticArea.some((e) => e.therapeuticAreaId === itemId)) {
                    const newItem = new ProjectCase_TherapeuticArea();
                    newItem.projectCaseId = this._projectCase.id;
                    newItem.therapeuticAreaId = itemId;
                    this._projectCaseTherapeuticArea.push(newItem);
                }
            });
        }

        this.onProjectCaseProjectTypeChange.emit({projectCaseId: this._projectCase.id, types: this._projectCaseProjectType});
        this.onProjectCaseTherapeuticAreaChange.emit({
            projectCaseId: this._projectCase.id,
            areas: this._projectCaseTherapeuticArea
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }

}

export interface ProjectCaseProjectTypes {
    projectCaseId: number;
    types: ProjectCase_ProjectType[];
}

export interface ProjectCaseTherapeuticArea {
    projectCaseId: number;
    areas: ProjectCase_TherapeuticArea[];
}
