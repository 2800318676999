import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export const REVIEW_MARK_SELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ReviewMarksReviewMarkSelectComponent),
    multi: true
};

// doc pour le ngModel : http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'review-marks-review-mark-select',
    providers: [REVIEW_MARK_SELECT_VALUE_ACCESSOR],
    styleUrls: ['./review-mark-select.scss'],
    templateUrl: './review-mark-select.html'
})
export class ReviewMarksReviewMarkSelectComponent implements AfterViewInit, ControlValueAccessor {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public label: string = '';
    @Input() public minValue: number = 0;
    @Input() public maxValue: number = 5;
    @Input() public lowerIcon: string = 'star';
    @Input() public greaterIcon: string = 'star_border';
    @Input() public value: number = 0;
    @Input() public disabled: boolean = false;
    @Output() public valueChange: EventEmitter<any> = new EventEmitter();
    @Output('select') public onSelectEvent: EventEmitter<number> = new EventEmitter();

    public availableMarks: number[] = [];
    public roundedValue: number = 0;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        for (let i = this.minValue; i <= this.maxValue; i++) {
            this.availableMarks.push(i);
        }
    }

    public writeValue(value: any): void {
        this.value = value;
        this.roundedValue = Math.round(this.value);
    }

    @HostListener('valueChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onMarkClicked(value: number): void {
        this.writeValue(value);
        this.onChange(value);
        this.onSelect(value);
    }

    public onMarkSelectChanged(): void {
        this.onChange(this.value);
        this.onSelect(this.value);
    }

    public onSelect(value: number): void {
        this.onSelectEvent.emit(value);
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
