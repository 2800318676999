<div mat-dialog-title>{{ file.name }}</div>
<mat-dialog-content>
    <div *ngIf="isLoading"
         class="loading-container">
        <mat-spinner [diameter]="48"></mat-spinner>
        <div>{{ 'Loading' | translate }}</div>
    </div>
    <img *ngIf="!isLoading"
         class="image"
         src="{{ src }}"/>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="onDownloadClicked()"
            [disabled]="isDownloadLoading"
            mat-button>
        {{ 'Download' | translate }}
    </button>
    <button mat-button
            mat-dialog-close>
        {{ 'Close' | translate}}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isDownloadLoading" mode="indeterminate"></mat-progress-bar>
</div>
