import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../providers/utils';
import { ModelsStorage } from '../../providers/models-storage';
import { MenuItem } from '../side-menu/side-menu';
import { CurrentUser } from '../../providers/current-user';

@Component({
    selector: 'header',
    providers: [],
    styleUrls: ['./header.scss'],
    templateUrl: './header.html'
})
export class HeaderComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public backMenu: MenuItem = null;
    @Input() public pageTitle: string = null;
    @Input() public pageSubtitle: string = null;
    @Input() public loginNextUrl: string = null;
    @Input() public loginText: string = 'Connection';
    @Input() public hasSideMenu: boolean = true;
    @Input() public headerMarginBottom: number = 32;
    @Input() public logoAlwaysDisplayed: boolean = false;

    public hasScrolled: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public utils: Utils,
        private _translater: TranslateService,
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    @HostListener('window:scroll', []) onWindowsScrolled(evend: any): void {
        this.hasScrolled = document.getElementsByTagName('html')[0].scrollTop > this.headerMarginBottom;
    }

    public onBackClicked(): void {
        if (this.backMenu.onClicked) {
            this.backMenu.onClicked();
            return;
        }

        this._router.navigateByUrl(this.backMenu.url);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
