<div class="wrapper background-color-body">
    <div class="container">
        <div class="title">:,-(</div>
        <div class="subtitle">{{ 'Error_404' | translate }}</div>
        <div class="message">{{ message }}</div>
        <div class="actions">
            <button *ngFor="let action of actions"
                    color="accent"
                    mat-raised-button
                    routerLink="{{ action.link }}">{{ action.name | translate }}</button>
        </div>
    </div>
</div>
