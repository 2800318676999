<div class="height-hack"></div>
<div [class.has-side-menu]="hasSideMenu"
     [class.mat-elevation-z2]="2>1 || hasScrolled"
     class="bar">
    <div [class.logo-hideable]="!logoAlwaysDisplayed"
         class="left">
        <img [routerLink]="'/'"
             [src]="conf.assetsUrl + 'img/sbv-logo.svg'"
             class="logo"/>
    </div>
    <div class="center">
        <div class="center-left">
            <button (click)="onBackClicked()"
                    *ngIf="backMenu"
                    class="back-button"
                    mat-button>
                <mat-icon>arrow_back</mat-icon>&nbsp;{{ backMenu.title }}
            </button>
            <div *ngIf="pageTitle"
                 class="title">
                {{ pageTitle }}
            </div>
            <div *ngIf="pageSubtitle"
                 class="subtitle">
                {{ pageSubtitle }}
            </div>
        </div>
        <div class="center-right">
            <ng-content select="[right-content]"></ng-content>
        </div>
    </div>
    <div class="right">
        <users-user-menu [loginNextUrl]="loginNextUrl"
                         [loginText]="loginText"
                         class="right-menu">
        </users-user-menu>
    </div>
</div>
