import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../_common/providers/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'projects-invite-user-dialog',
    providers: [],
    styleUrls: ['invite-user-dialog.scss'],
    templateUrl: 'invite-user-dialog.html'
})
export class InviteUserDialogComponent {

    public readonly NAME_MAX_LENGTH: number = 200;

    /**
     * @see https://stackoverflow.com/questions/47884655/display-custom-validator-error-with-mat-error
     *
     * @type {FormGroup}
     */
    public inviteUserForm: FormGroup;
    public submitted = false;
    public user: User = {} as User;

    constructor(
        public dialog: MatDialogRef<InviteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private fb: FormBuilder,
    ) {
        this.inviteUserForm = this.fb.group({
            firstName: new FormControl('', [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]),
            lastName: new FormControl('', [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]),
            email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(this.NAME_MAX_LENGTH)])
        });
    }

    get f() {
        return this.inviteUserForm.controls;
    }

    public onInviteClicked(): void {
        this.submitted = true;

        if (this.inviteUserForm.invalid) {
            return;
        }

        // update user model (bind form values to user model)
        this.updateUser(this.inviteUserForm.value);

        this.dialog.close(this.user);
    }

    updateUser(values: Object) {
        Object.assign(this.user, values);
    }
}
