import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'main-action',
    providers: [],
    styleUrls: ['./main-action.scss'],
    templateUrl: './main-action.html'
})
export class MainActionComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public displayMode: string = 'fab';
    @Input() public isLoading: boolean = false;
    @Input() public title: string = '';
    @Input() public icon: string = null;
    @Input() public disabled: boolean = false;
    @Output() public onAction: EventEmitter<any> = new EventEmitter<any>();
    @Input() public link: string = null;
    @Input() public right: number = 32;
    @Input() public bottom: number = 16;
    @Input() public color: string = 'accent';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _router: Router,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onClicked(): void {
        if (this.link) {
            this._router.navigateByUrl(this.link);
        }

        this.onAction.emit();
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
