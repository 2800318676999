import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { Utils } from '../../../_common/providers/utils';
import { InvestorDataRow, InvestorFiltersState, InvestorsProvider } from '../../providers/investors-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InvestorRights } from '../../providers/investor-rights';
import {
    Alert,
    Company,
    CompanyCategory,
    CompanyCompanyCategory,
    DevelopmentPhase,
    DevelopmentPhaseOfInterest,
    InvestorProfile,
    InvestorValidationState,
    TherapeuticArea,
    TherapeuticAreaOfInterest,
    User
} from '../../../_common/providers/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-list',
    providers: [],
    styleUrls: ['./list.scss'],
    templateUrl: './list.html'
})
export class InvestorsListComponent implements AfterViewInit, OnDestroy {

    //
    //
    // CONSTANTS
    //
    //

    public readonly AMOUNT_RAISED_MIN: number = 0;
    public readonly AMOUNT_RAISED_MAX: number = 99999999999; // 100 mia

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isReady: boolean = false;
    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public columns: string[] = [];

    public searchInput: FormControl = new FormControl('');
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhases: DevelopmentPhase[] = [];
    public companyCategories: CompanyCompanyCategory[] = [];

    public companyCategoriesCheckboxes: boolean[] = [];
    public filteredCompanyCategories: CompanyCompanyCategory[] = [];
    public therapeuticAreasCheckboxes: boolean[] = [];
    public filteredTherapeuticAreas: TherapeuticArea[] = [];
    public developmentPhasesCheckboxes: boolean[] = [];
    public filteredDevelopmentPhases: DevelopmentPhase[] = [];
    public investmentRangeToFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.investmentRangeToFormControl, this.investmentRangeFromFormControl, null, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public investmentRangeFromFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.investmentRangeFromFormControl, null, this.investmentRangeToFormControl, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public filteredOthers: Alert = new Alert(); // mini hack, on utilise directement cette structure pour binder les oui/non - c'est plus simple
    public moreFiltersSelectionLabel: string = null;

    @Input() public title: string = null;
    @Input() public noDataMessage: string = 'There_are_no_investors_to_display';
    @Input() public hideNameColumn: boolean = false;
    @Input() public hideValidationStateColumn: boolean = false;
    @Input() public hideCompanyColumn: boolean = false;
    @Input() public hideCreateDateColumn: boolean = false;
    @Input() public hideContactableColumn: boolean = false;
    @Input() public hideActionsColumn: boolean = false;
    @Input() public disableSearchEngine: boolean = false;
    @Input() public preserveFilters: boolean = false;
    @Output() public onRowClicked: EventEmitter<InvestorDataRow> = new EventEmitter<InvestorDataRow>();
    @Output() public onCompanyClicked: EventEmitter<InvestorDataRow> = new EventEmitter<InvestorDataRow>();
    @Output() public onDeleteRowClicked: EventEmitter<InvestorDataRow> = new EventEmitter<InvestorDataRow>();
    @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    private _allRows: InvestorDataRow[] = [];

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _dialoger: MatDialog,
        private _translater: TranslateService,
        private _route: ActivatedRoute,
        private _toaster: MatSnackBar,
        private _router: Router,
        private _storage: ModelsStorage,
        private _investorsProvider: InvestorsProvider,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _investorProfiles: InvestorProfile[] = [];

    public get investorProfiles() {
        return this._investorProfiles;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set investorProfiles(investorProfiles: InvestorProfile[]) {
        this._investorProfiles = investorProfiles;
        if (this._investorProfiles && this._investorProfiles.length) {
            this.loadData(investorProfiles)
                .then((data: InvestorDataRow[]) => {
                    this._allRows = data;
                    this.applyFilters();
                })
                .catch((error: any) => this.onError(error));
        } else {
            this._allRows = [];
            this.applyFilters();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initColumns();
        this.initDataSource();
        this.loadStaticData()
            .then(() => {
                this._route.params.subscribe((params: any) => {
                    this.initFilters(this.preserveFilters ? this._investorsProvider.investorFiltersState : null)
                        .then(() => this.isReady = true)
                        .catch((error: any) => this.onError(error));
                });
            })
            .catch((error: any) => this.onError(error));
    }

    public ngOnDestroy() {
        if (this.preserveFilters) {
            this.saveFiltersState();
        }
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    public hasFilters(): boolean {
        return this.filteredCompanyCategories.length > 0
            || this.filteredDevelopmentPhases.length > 0
            || this.filteredTherapeuticAreas.length > 0
            || this.moreFiltersSelectionLabel != null;
    }

    public isMoreFiltersValid(): boolean {
        return !this.investmentRangeFromFormControl.invalid
            && !this.investmentRangeToFormControl.invalid;
    }

    public searchInputChanged(filter: string): void {
        this.dataSource.filter = this.utils.normalizeString(filter);
    }

    public onAllCompanyCategoriesClicked(event: any): void {
        event.stopPropagation();
        this.filteredCompanyCategories = this.companyCategories.slice();
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, true);
        this.applyFilters();
    }

    public onNoCompanyCategoriesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredCompanyCategories = [];
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, false);
        this.applyFilters();
    }

    public onCompanyCategoryClicked(event: any, category: CompanyCompanyCategory): void {
        setTimeout(() => {
            this.toggleFilterSelection(category, this.filteredCompanyCategories);
            this.applyFilters();
        }, 1);
    }

    public onAllDevelopmentPhasesClicked(event: any): void {
        event.stopPropagation();
        this.filteredDevelopmentPhases = this.developmentPhases.slice();
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, true);
        this.applyFilters();
    }

    public onNoDevelopmentPhasesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.applyFilters();
    }

    public onDevelopmentPhaseClicked(event: any, phase: DevelopmentPhase): void {
        setTimeout(() => {
            this.toggleFilterSelection(phase, this.filteredDevelopmentPhases);
            this.applyFilters();
        }, 1);
    }

    public onAllTherapeuticAreasClicked(event: any): void {
        event.stopPropagation();
        this.filteredTherapeuticAreas = this.therapeuticAreas.slice();
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, true);
        this.applyFilters();
    }

    public onNoTherapeuticAreasClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.applyFilters();
    }

    public onTherapeuticAreaClicked(event: any, area: TherapeuticArea): void {
        setTimeout(() => {
            this.toggleFilterSelection(area, this.filteredTherapeuticAreas);
            this.applyFilters();
        }, 1);
    }

    public stopPropagation(event): void {
        event.stopPropagation();
    }

    public onResetFiltersClicked(): void {
        this.filteredCompanyCategories = [];
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, false);
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.filteredOthers.isSeekingPartnerhips = null;
        this.filteredOthers.isNotSeekingPartnerhips = null;
        this.filteredOthers.isOutlicensing = null;
        this.filteredOthers.isNotOutlicensing = null;
        this.filteredOthers.isNotForProfit = null;
        this.filteredOthers.isNotNotForProfit = null;
        this.investmentRangeFromFormControl.setValue(null);
        this.investmentRangeToFormControl.setValue(null);
        this.moreFiltersSelectionLabel = null;

        this.applyFilters();
    }

    public onMoreFiltersItemClicked(event: any): void {
        setTimeout(() => {
            const selectionLabels: string[] = [];

            this.investmentRangeFromFormControl.updateValueAndValidity();
            this.investmentRangeToFormControl.updateValueAndValidity();
            if (this.investmentRangeFromFormControl.value || this.investmentRangeToFormControl.value) {
                selectionLabels.push('Investment_range');
            }

            if (this.filteredOthers.isSeekingPartnerhips || this.filteredOthers.isNotSeekingPartnerhips) {
                selectionLabels.push('Intersted_in_partnerships');
            }
            if (this.filteredOthers.isOutlicensing || this.filteredOthers.isNotOutlicensing) {
                selectionLabels.push('Intersted_to_in_licence_a_technology_of_product');
            }
            if (this.filteredOthers.isNotForProfit || this.filteredOthers.isNotNotForProfit) {
                selectionLabels.push('Intersted_in_non_profit_ventures');
            }

            if (selectionLabels.length === 0) {
                this.moreFiltersSelectionLabel = null;
            } else if (selectionLabels.length === 1) {
                this.moreFiltersSelectionLabel = this._translater.instant(selectionLabels[0]);
            } else {
                this.moreFiltersSelectionLabel = this._translater.instant('n_filters', {count: selectionLabels.length});
            }

            this.applyFilters();
        }, 1);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initColumns(): void {
        const columns: string[] = [];
        if (!this.hideValidationStateColumn) {
            columns.push('validationState');
        }
        if (!this.hideNameColumn) {
            columns.push('name');
        }
        if (!this.hideCompanyColumn) {
            columns.push('company');
        }
        if (!this.hideCreateDateColumn) {
            columns.push('createDate');
        }
        if (!this.hideContactableColumn) {
            columns.push('contactable');
        }
        if (!this.hideActionsColumn) {
            columns.push('actions');
        }

        this.columns = columns;
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (row: InvestorDataRow, filter: string) => {
            return this.utils.normalizeString(
                row.user.firstName +
                row.user.lastName +
                (row.profile.companyId || '') +
                (row.company && row.company.name || '') +
                (row.companyCategory || '')
            ).includes(filter);
        };
        this.searchInput.valueChanges.subscribe((value: string) => this.searchInputChanged(value)); // on utilise pas un bête keyup parce que si on sélectionne le texte et qu'on le coupe par exemple, on catch pas l'event ...

        this.dataSource.addSortDefinition(
            'validationState',
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(a.profile.investorValidationStateId, b.profile.investorValidationStateId),
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(b.profile.investorValidationStateId, a.profile.investorValidationStateId)
        );

        this.dataSource.addSortDefinition(
            'name',
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase(), b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase()),
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase(), a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'company',
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(a.company.name.toLowerCase(), b.company.name.toLowerCase()),
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(b.company.name.toLowerCase(), a.company.name.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'createDate',
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(a.profile.createDate, b.profile.createDate),
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(b.profile.createDate, a.profile.createDate)
        );

        this.dataSource.addSortDefinition(
            'contactable',
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(a.profile.acceptToBeContacted, b.profile.acceptToBeContacted),
            (a: InvestorDataRow, b: InvestorDataRow) => this.utils.sortRow(b.profile.acceptToBeContacted, a.profile.acceptToBeContacted)
        );
    }

    private loadStaticData(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this._storage.select(TherapeuticArea).get(),
                this._storage.select(CompanyCompanyCategory).orderBy('ordinal').get()
            ])
                .then((data: any[]) => {
                    this.developmentPhases = data[0];
                    this.therapeuticAreas = data[1];
                    this.companyCategories = data[2];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private loadData(investorProfiles: InvestorProfile[]): Promise<InvestorDataRow[]> {
        return new Promise<any>((resolve, reject) => {
            Promise.all([
                this._storage.select(User).where('id').in(this.utils.getKeyValues(investorProfiles, 'userId')).get(),
                this._storage.select(CompanyCompanyCategory).get(),
                this._storage.select(InvestorValidationState).get(),
                this._storage.select(TherapeuticAreaOfInterest).get(),
                this._storage.select(DevelopmentPhaseOfInterest).get(),
                this._storage.select(Company).where('id').in(this.utils.getKeyValues(investorProfiles, 'companyId')).get()
            ])
                .then((data: any[]) => {
                    const rows: InvestorDataRow[] = [];
                    investorProfiles.forEach((profile: InvestorProfile, i: number) => {
                        const company: Company = profile.companyId ? this.utils.findIn(profile.companyId, data[5]) : null;
                        const companyCategory: CompanyCompanyCategory = company ? this.utils.findIn(company.companyCompanyCategoryId, data[1]) : null;
                        const rights: InvestorRights = new InvestorRights(this.utils);
                        rights.evaluate({
                            user: this.currentUser.user,
                            investorProfile: profile
                        });
                        const therapeuticAreasIdsOfInterest: number[] = this.utils.getKeyValues(this.utils.findManyIn([profile.id], data[3], 'investorProfileId'), 'therapeuticAreaId');
                        const developmentPhasesIdsOfInterest: number[] = this.utils.getKeyValues(this.utils.findManyIn([profile.id], data[4], 'investorProfileId'), 'developmentPhaseId');
                        rows.push({
                            profile,
                            user: this.utils.findIn(profile.userId, data[0]),
                            validationState: this.utils.findIn(profile.investorValidationStateId, data[2]),
                            company,
                            companyCategory,
                            isLoading: false,
                            rights,
                            therapeuticAreasOfInterest: this.utils.findManyIn(therapeuticAreasIdsOfInterest, this.therapeuticAreas),
                            developmentPhasesOfInterest: this.utils.findManyIn(developmentPhasesIdsOfInterest, this.developmentPhases),
                            therapeuticAreasIdsOfInterest,
                            developmentPhasesIdsOfInterest
                        });
                    });
                    this.dataSource.replaceAllWith(rows);

                    resolve(rows);
                })
                .catch((error: any) => reject(error));
        });
    }

    private applyFilters(): void {
        const rows: InvestorDataRow[] = [];
        for (let i = 0; i < this._allRows.length; i++) {
            if (this.matchFilterSomeList([this._allRows[i].companyCategory ? this._allRows[i].companyCategory.id : null], this.filteredCompanyCategories)
                && this.matchFilterSomeList(this._allRows[i].therapeuticAreasIdsOfInterest, this.filteredTherapeuticAreas)
                && this.matchFilterSomeList(this._allRows[i].developmentPhasesIdsOfInterest, this.filteredDevelopmentPhases)
                && this.matchFilterMoreFilters(this._allRows[i])
                && this.inFilterRange(this.investmentRangeFromFormControl.value * 100, this.investmentRangeToFormControl.value * 100, this._allRows[i].profile.investmentRangeFrom, this._allRows[i].profile.investmentRangeTo)) {
                rows.push(this._allRows[i]);
            }
        }

        this.dataSource.replaceAllWith(rows);
    }

    private switchAllCheckboxesWithGroup(list: boolean[][], data: any, keyName: string, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i][keyName].length; j++) {
                list[i][j] = value;
            }
        }
    }

    private switchAllCheckboxes(list: boolean[], data: any, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            list[i] = value;
        }
    }

    private checkMinMaxFormControl(targetControl: FormControl, minControl: FormControl, maxControl: FormControl, minFallback: number, maxFallback: number): any {
        if (!targetControl || !targetControl.value) {
            return null;
        }

        if (minControl && minControl.value && targetControl.value < minControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value < minFallback) {
            return {consistency: {valid: false}};
        }

        if (maxControl && maxControl.value && targetControl.value > maxControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value > maxFallback) {
            return {consistency: {valid: false}};
        }

        return null;
    }

    private inFilterRange(filterFrom: number, filterTo: number, valueFrom: number, valueTo: number): boolean {
        if (filterFrom) {
            if (!valueFrom || filterFrom > valueTo) {
                return false;
            }
        }

        if (filterTo) {
            if (!valueTo || filterTo < valueTo) {
                return false;
            }
        }

        return true;
    }

    private matchFilterSomeList(keys: number[], filters: any[]): boolean {
        // s'il n'y a pas de filter, ça match ...
        if (filters.length === 0) {
            return true;
        }

        // on prend pas en considération les clés qui seraient null ...
        const _keys: number[] = keys.filter((val: number) => val ? true : false);

        return this.utils.findManyIn(_keys, filters, 'id').length > 0;
    }

    private matchFilterYesNo(yesValue: number, noValue: number, actualValue: number): boolean {
        //      si rien n'est coché, on ignore le filtre (l'utilisateur n'a pas renseigner l'info ...)
        //      si les deux sont cochés, on ignore le filtre (l'utilisateur indique explicitement qu'il veut les 2, et y a que 2 possibilités, donc ce sera forcément true)
        //      si un seul des deux est cochés, on check que ca match avec le model
        if (yesValue) {
            if (!noValue) {
                if (!(actualValue == 1)) {
                    return false;
                }
            }
        } else {
            if (noValue) {
                if (actualValue == 1) {
                    return false;
                }
            }
        }

        return true;
    }

    private matchFilterMoreFilters(row: InvestorDataRow): boolean {
        // *100 ; on a le montant en cents, et on l'utilisateur le saisit en dollars
        if (this.investmentRangeFromFormControl.value && (!row.profile.investmentRangeFrom || (this.investmentRangeFromFormControl.value * 100) > row.profile.investmentRangeFrom)) {
            return false;
        }
        if (this.investmentRangeToFormControl.value && (!row.profile.investmentRangeTo || (this.investmentRangeToFormControl.value * 100) < row.profile.investmentRangeTo)) {
            return false;
        }
        if (!this.matchFilterYesNo(this.filteredOthers.isSeekingPartnerhips, this.filteredOthers.isNotSeekingPartnerhips, row.profile.isInterstedInPartnerships)) {
            return false;
        }
        if (!this.matchFilterYesNo(this.filteredOthers.isOutlicensing, this.filteredOthers.isNotOutlicensing, row.profile.isInterstedInLicenses)) {
            return false;
        }
        if (!this.matchFilterYesNo(this.filteredOthers.isNotForProfit, this.filteredOthers.isNotNotForProfit, row.profile.isInterstedInNotForProfit)) {
            return false;
        }

        return true;
    }

    private toggleFilterSelection(item: any, list: any[]): boolean {
        const index: number = list.indexOf(item);
        if (index >= 0) {
            list.splice(index, 1);

            return false;
        }

        list.push(item);

        return true;
    }

    private saveFiltersState(): void {
        this._investorsProvider.investorFiltersState = {
            companyCategoryIds: this.utils.getKeyValues(this.filteredCompanyCategories),
            developmentPhaseIds: this.utils.getKeyValues(this.filteredDevelopmentPhases),
            therapeuticAreaIds: this.utils.getKeyValues(this.filteredTherapeuticAreas),
            investmentRangeFrom: this.investmentRangeFromFormControl.value,
            investmentRangeTo: this.investmentRangeToFormControl.value,
            isNotForProfit: this.filteredOthers.isNotForProfit,
            isNoNotForProfit: this.filteredOthers.isNotNotForProfit,
            isOutlicensing: this.filteredOthers.isOutlicensing,
            isNotOutlicensing: this.filteredOthers.isNotOutlicensing,
            isSeekingPartnerships: this.filteredOthers.isSeekingPartnerhips,
            isNotSeekingPartnerships: this.filteredOthers.isNotSeekingPartnerhips,
            pageIndex: this.paginator.pageIndex
        };
    }

    private initFilters(filtersState: InvestorFiltersState): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (!filtersState) {
                filtersState = {
                    companyCategoryIds: [],
                    developmentPhaseIds: [],
                    therapeuticAreaIds: [],
                    investmentRangeFrom: null,
                    investmentRangeTo: null,
                    isNoNotForProfit: 0,
                    isNotForProfit: 0,
                    isNotOutlicensing: 0,
                    isNotSeekingPartnerships: 0,
                    isOutlicensing: 0,
                    isSeekingPartnerships: 0,
                    pageIndex: 0
                };
            }

            this.filteredCompanyCategories = [];
            this.companyCategoriesCheckboxes = [];
            this.filteredTherapeuticAreas = [];
            this.therapeuticAreasCheckboxes = [];
            this.filteredDevelopmentPhases = [];
            this.developmentPhasesCheckboxes = [];

            this.companyCategories.forEach((item: CompanyCategory, i: number) => {
                if (filtersState.companyCategoryIds.indexOf(item.id) >= 0) {
                    this.companyCategoriesCheckboxes.push(true);
                    this.filteredCompanyCategories.push(item);
                } else {
                    this.companyCategoriesCheckboxes.push(false);
                }
            });

            this.therapeuticAreas.forEach((item: TherapeuticArea, i: number) => {
                if (filtersState.therapeuticAreaIds.indexOf(item.id) >= 0) {
                    this.therapeuticAreasCheckboxes.push(true);
                    this.filteredTherapeuticAreas.push(item);
                } else {
                    this.therapeuticAreasCheckboxes.push(false);
                }
            });

            this.developmentPhases.forEach((item: DevelopmentPhase, i: number) => {
                if (filtersState.developmentPhaseIds.indexOf(item.id) >= 0) {
                    this.developmentPhasesCheckboxes.push(true);
                    this.filteredDevelopmentPhases.push(item);
                } else {
                    this.developmentPhasesCheckboxes.push(false);
                }
            });

            this.investmentRangeFromFormControl.setValue(filtersState.investmentRangeFrom);
            this.investmentRangeToFormControl.setValue(filtersState.investmentRangeTo);
            this.filteredOthers.isNotForProfit = filtersState.isNotForProfit;
            this.filteredOthers.isNotNotForProfit = filtersState.isNoNotForProfit;
            this.filteredOthers.isOutlicensing = filtersState.isOutlicensing;
            this.filteredOthers.isNotOutlicensing = filtersState.isNotOutlicensing;
            this.filteredOthers.isSeekingPartnerhips = filtersState.isSeekingPartnerships;
            this.filteredOthers.isNotSeekingPartnerhips = filtersState.isNotSeekingPartnerships;
            this.paginator.pageIndex = filtersState.pageIndex;

            this.onMoreFiltersItemClicked({}); // vilain hack pour refresh le control ...

            this.applyFilters();
            resolve();
        });
    }

    private onError(error: any): void {
        console.log(error);
        this.isReady = true;
    }
}
