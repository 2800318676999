import { Injectable } from '@angular/core';
import { Utils } from '../../_common/providers/utils';

@Injectable()
export class NotificationsProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    constructor(
        private _utils: Utils,
    ) {
    }

    private _notifications: Notification[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    public get notifications() {
        return this._notifications;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public add(notification: Notification): NotificationsProvider {
        if (!this.find(notification.id)) {
            this._notifications.push(notification);
        }

        return this;
    }

    public remove(id: string): NotificationsProvider {
        for (let i = 0; i < this._notifications.length; i++) {
            if (this._notifications[i].id === id) {
                this._notifications.splice(i, 1);
                return this;
            }
        }

        return this;
    }

    public clear(): NotificationsProvider {
        this._notifications.length = 0; // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript

        return this;
    }

    public replaceAll(notifications: Notification[]): NotificationsProvider {
        this._notifications = notifications;

        return this;
    }

    public find(id: string): Notification {
        return this._utils.findIn(id, this._notifications, 'id');
    }

    //
    //
    // PRIVATE METHODS
    //
    // )
}

export interface Notification {
    id: string;
    icon: string;
    title: string;
    text: string;
    legend: string;
    url: string;
    date: string;
}
