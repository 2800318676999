import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InvestorsProvider } from '../../providers/investors-provider';
import { InvestorProfile, InvestorValidationState } from '../../../_common/providers/models';

@Component({
    selector: 'investors-investor-state-indicator',
    providers: [],
    styleUrls: ['./investor-state-indicator.scss'],
    templateUrl: './investor-state-indicator.html'
})
export class InvestorsInvestorStateIndicatorComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly STATE_ID_PENDING_VALIDATION: number = InvestorValidationState.ID_PENDING;
    public readonly STATE_ID_ACCEPTED: number = InvestorValidationState.ID_ACCEPTED;
    public readonly STATE_ID_REFUSED: number = InvestorValidationState.ID_REFUSED;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //
    @Input() public state: InvestorValidationState = new InvestorValidationState();
    @Input() public displayMode: 'chip' | 'text' | 'chip-text' = 'chip-text';

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        public investorsProvider: InvestorsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _investorProfile: InvestorProfile = null;

    public get investorProfile() {
        return this._investorProfile;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set investorProfile(investorProfile: InvestorProfile) {
        this._investorProfile = investorProfile;
        if (this._investorProfile) {
            this.loadState().catch((error: any) => this.onError(error));
        } else {
            this.state = new InvestorValidationState();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadState(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(InvestorValidationState).where('id').equals(this._investorProfile.investorValidationStateId).get(1)
                .then((state: InvestorValidationState) => {
                    this.state = state;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}
