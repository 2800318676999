class ModelSerialization {
    public static deserialize(instance: any, data: any): any {
        return Object.assign(new instance(), data);
    }
}

//
//
// Lang
//
//

export class Lang {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Lang';
    public static readonly ID_EN: number = 1;
    public static readonly ID_FR: number = 2;
    public static readonly ID_DEFAULT: number = 1;
    public id: number;

    //
    // CONSTANTS
    //
    public name: string;
    public code: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id', 'name', 'code'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'code'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Lang {
        const item = new Lang();
        item.id = data['id'];
        item.name = data['name'];
        item.code = data['code'];
        return item;
    }

    public static fromAPI(data: any): Lang {
        const item: Lang = new Lang();
        item.id = parseFloat(data['id']);
        item.name = data['name'];
        item.code = data['code'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Lang[] {
        const items: Lang[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Lang.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            code: this.code
        };
        return item;
    }

    public clone(): Lang {
        return Lang.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// User
//
//

export class User {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'User';
    public id: number;
    public langId: number;
    public email: string;
    public isAdmin: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public isInvestor: number;
    public isGuest: number;
    public isProjectHolder: number;

    //
    // ATTRIBUTES
    //
    public isLocalExpert: number;
    public firstName: string;
    public lastName: string;
    public avatar: string;
    public intro: string;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'langId', 'email', 'isAdmin', 'isInvestor', 'isGuest', 'isProjectHolder', 'isLocalExpert', 'firstName', 'lastName', 'avatar', 'intro', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'langId', 'email', 'isAdmin', 'isInvestor', 'isGuest', 'isProjectHolder', 'isLocalExpert', 'firstName', 'lastName', 'avatar', 'intro', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): User {
        const item = new User();
        item.id = data['id'];
        item.langId = data['langId'];
        item.email = data['email'];
        item.isAdmin = data['isAdmin'];
        item.isInvestor = data['isInvestor'];
        item.isGuest = data['isGuest'];
        item.isProjectHolder = data['isProjectHolder'];
        item.isLocalExpert = data['isLocalExpert'];
        item.firstName = data['firstName'];
        item.lastName = data['lastName'];
        item.avatar = data['avatar'];
        item.intro = data['intro'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): User {
        const item: User = new User();
        item.id = parseFloat(data['id']);
        item.langId = parseFloat(data['langId']);
        item.email = data['email'];
        item.isAdmin = parseFloat(data['isAdmin']);
        item.isInvestor = parseFloat(data['isInvestor']);
        item.isGuest = parseFloat(data['isGuest']);
        item.isProjectHolder = parseFloat(data['isProjectHolder']);
        item.isLocalExpert = parseFloat(data['isLocalExpert']);
        item.firstName = data['firstName'];
        item.lastName = data['lastName'];
        item.avatar = data['avatar'];
        item.intro = data['intro'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): User[] {
        const items: User[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(User.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            langId: this.langId,
            email: this.email,
            isAdmin: this.isAdmin,
            isInvestor: this.isInvestor,
            isGuest: this.isGuest,
            isProjectHolder: this.isProjectHolder,
            isLocalExpert: this.isLocalExpert,
            firstName: this.firstName,
            lastName: this.lastName,
            avatar: this.avatar,
            intro: this.intro,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): User {
        return User.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// UserLink
//
//

export class UserLink {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'UserLink';
    public static readonly ID_LINKEDIN: number = 1;
    public static readonly ID_TWITTER: number = 2;
    public static readonly ID_GITHUB: number = 3;
    public static readonly ID_STACKOVERFLOW: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_INSTAGRAM: number = 5;
    public id: number;
    public name: string;
    public description: string;
    public matchWith: string;

    //
    // FACTORIES
    //
    public hint: string;
    public icon: string;

    constructor() {
    }

    //
    // ATTRIBUTES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id', 'matchWith', 'icon'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description', 'hint'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description', 'matchWith', 'hint', 'icon'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): UserLink {
        const item = new UserLink();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        item.matchWith = data['matchWith'];
        item.hint = data['hint'];
        item.icon = data['icon'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): UserLink {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['userLinkId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: UserLink = new UserLink();
        item.id = parseFloat(data['id']);
        item.matchWith = data['matchWith'];
        item.icon = data['icon'];
        item.name = translationData['name'];
        item.description = translationData['description'];
        item.hint = translationData['hint'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): UserLink[] {
        const items: UserLink[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(UserLink.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description,
            matchWith: this.matchWith,
            hint: this.hint,
            icon: this.icon
        };
        return item;
    }

    public clone(): UserLink {
        return UserLink.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Tag
//
//

export class Tag {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Tag';
    public id: number;
    public name: string;
    public colorHex: string;
    public textColorHex: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // ATTRIBUTES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'colorHex', 'textColorHex', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return ['name'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'colorHex', 'textColorHex', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Tag {
        const item = new Tag();
        item.id = data['id'];
        item.name = data['name'];
        item.colorHex = data['colorHex'];
        item.textColorHex = data['textColorHex'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): Tag {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['tagId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: Tag = new Tag();
        item.id = parseFloat(data['id']);
        item.colorHex = data['colorHex'];
        item.textColorHex = data['textColorHex'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        item.name = translationData['name'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): Tag[] {
        const items: Tag[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Tag.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            colorHex: this.colorHex,
            textColorHex: this.textColorHex,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Tag {
        return Tag.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Discussion
//
//

export class Discussion {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Discussion';
    public id: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    //
    // ATTRIBUTES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Discussion {
        const item = new Discussion();
        item.id = data['id'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Discussion {
        const item: Discussion = new Discussion();
        item.id = parseFloat(data['id']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Discussion[] {
        const items: Discussion[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Discussion.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Discussion {
        return Discussion.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// DiscussionMessage
//
//

export class DiscussionMessage {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'DiscussionMessage';
    public id: number;
    public discussionId: number;
    public message: string;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'discussionId', 'message', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'discussionId', 'message', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): DiscussionMessage {
        const item = new DiscussionMessage();
        item.id = data['id'];
        item.discussionId = data['discussionId'];
        item.message = data['message'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): DiscussionMessage {
        const item: DiscussionMessage = new DiscussionMessage();
        item.id = parseFloat(data['id']);
        item.discussionId = parseFloat(data['discussionId']);
        item.message = data['message'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): DiscussionMessage[] {
        const items: DiscussionMessage[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(DiscussionMessage.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            discussionId: this.discussionId,
            message: this.message,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): DiscussionMessage {
        return DiscussionMessage.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// FileFamily
//
//

export class FileFamily {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'FileFamily';
    public static readonly ID_OTHER: number = 1;
    public static readonly ID_IMAGE: number = 2;
    public static readonly ID_AUDIO: number = 3;
    public static readonly ID_VIDEO: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_PDF: number = 5;
    public static readonly ID_WORD: number = 6;
    public static readonly ID_EXCEL: number = 7;
    public static readonly ID_POWERPOINT: number = 8;
    public static readonly ID_FOLDER: number = 9;
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): FileFamily {
        const item = new FileFamily();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): FileFamily {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['fileFamilyId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: FileFamily = new FileFamily();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): FileFamily[] {
        const items: FileFamily[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(FileFamily.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): FileFamily {
        return FileFamily.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// FileType
//
//

export class FileType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'FileType';
    public static readonly ID_OTHER: number = 1;
    public static readonly ID_AAC: number = 2;
    public static readonly ID_AVI: number = 3;
    public static readonly ID_CSV: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_DOC: number = 5;
    public static readonly ID_DOCX: number = 6;
    public static readonly ID_GIF: number = 7;
    public static readonly ID_JPEG: number = 8;
    public static readonly ID_JPG: number = 9;
    public static readonly ID_JSON: number = 10;
    public static readonly ID_MID: number = 11;
    public static readonly ID_MIDI: number = 12;
    public static readonly ID_MPEG: number = 13;
    public static readonly ID_PNG: number = 14;
    public static readonly ID_PDF: number = 15;
    public static readonly ID_PPT: number = 16;
    public static readonly ID_PPTX: number = 17;
    public static readonly ID_RAR: number = 18;
    public static readonly ID_RTF: number = 19;
    public static readonly ID_SVG: number = 20;
    public static readonly ID_WEBA: number = 21;
    public static readonly ID_WEBM: number = 22;
    public static readonly ID_WEBP: number = 23;
    public static readonly ID_XLS: number = 24;
    public static readonly ID_XLSX: number = 25;
    public static readonly ID_XML: number = 26;
    public static readonly ID_ZIP: number = 27;
    public static readonly ID_3GPA: number = 28;
    public static readonly ID_3GPV: number = 29;
    public static readonly ID_7Z: number = 30;
    public static readonly ID_MP3: number = 31;
    public static readonly ID_MP4: number = 32;
    public static readonly ID_MKV: number = 33;
    public static readonly ID_FOLDER: number = 34;
    public id: number;
    public fileFamilyId: number;
    public name: string;
    public extension: string;

    //
    // FACTORIES
    //
    public mimeType: string;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'fileFamilyId', 'extension', 'mimeType'];
    }

    //
    // ATTRIBUTES
    //

    public static getTranslatableFields(): string[] {
        return ['name'];
    }

    public static getFields(): string[] {
        return ['id', 'fileFamilyId', 'name', 'extension', 'mimeType'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): FileType {
        const item = new FileType();
        item.id = data['id'];
        item.fileFamilyId = data['fileFamilyId'];
        item.name = data['name'];
        item.extension = data['extension'];
        item.mimeType = data['mimeType'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): FileType {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['fileTypeId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: FileType = new FileType();
        item.id = parseFloat(data['id']);
        item.fileFamilyId = parseFloat(data['fileFamilyId']);
        item.extension = data['extension'];
        item.mimeType = data['mimeType'];
        item.name = translationData['name'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): FileType[] {
        const items: FileType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(FileType.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            fileFamilyId: this.fileFamilyId,
            name: this.name,
            extension: this.extension,
            mimeType: this.mimeType
        };
        return item;
    }

    public clone(): FileType {
        return FileType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// FileGroup
//
//

export class FileGroup {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'FileGroup';
    public id: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    //
    // ATTRIBUTES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): FileGroup {
        const item = new FileGroup();
        item.id = data['id'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): FileGroup {
        const item: FileGroup = new FileGroup();
        item.id = parseFloat(data['id']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): FileGroup[] {
        const items: FileGroup[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(FileGroup.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): FileGroup {
        return FileGroup.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// File
//
//

export class File {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'File';
    public id: number;
    public fileGroupId: number;
    public fileTypeId: number;
    public parentFileId: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public file: string;
    public name: string;
    public size: number;

    //
    // ATTRIBUTES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'fileGroupId', 'fileTypeId', 'parentFileId', 'file', 'name', 'size', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'fileGroupId', 'fileTypeId', 'parentFileId', 'file', 'name', 'size', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): File {
        const item = new File();
        item.id = data['id'];
        item.fileGroupId = data['fileGroupId'];
        item.fileTypeId = data['fileTypeId'];
        item.parentFileId = data['parentFileId'];
        item.file = data['file'];
        item.name = data['name'];
        item.size = data['size'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): File {
        const item: File = new File();
        item.id = parseFloat(data['id']);
        item.fileGroupId = parseFloat(data['fileGroupId']);
        item.fileTypeId = parseFloat(data['fileTypeId']);
        item.parentFileId = parseFloat(data['parentFileId']);
        item.file = data['file'];
        item.name = data['name'];
        item.size = parseFloat(data['size']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): File[] {
        const items: File[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(File.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            fileGroupId: this.fileGroupId,
            fileTypeId: this.fileTypeId,
            parentFileId: this.parentFileId,
            file: this.file,
            name: this.name,
            size: this.size,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): File {
        return File.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// PrivateDiscussion
//
//

export class PrivateDiscussion {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'PrivateDiscussion';
    public id: number;
    public discussionId: number;
    public isGroup: number;
    public name: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public image: string;
    public createDate: string;
    public createrId: number;

    //
    // ATTRIBUTES
    //
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'discussionId', 'isGroup', 'name', 'image', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'discussionId', 'isGroup', 'name', 'image', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): PrivateDiscussion {
        const item = new PrivateDiscussion();
        item.id = data['id'];
        item.discussionId = data['discussionId'];
        item.isGroup = data['isGroup'];
        item.name = data['name'];
        item.image = data['image'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): PrivateDiscussion {
        const item: PrivateDiscussion = new PrivateDiscussion();
        item.id = parseFloat(data['id']);
        item.discussionId = parseFloat(data['discussionId']);
        item.isGroup = parseFloat(data['isGroup']);
        item.name = data['name'];
        item.image = data['image'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): PrivateDiscussion[] {
        const items: PrivateDiscussion[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(PrivateDiscussion.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            discussionId: this.discussionId,
            isGroup: this.isGroup,
            name: this.name,
            image: this.image,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): PrivateDiscussion {
        return PrivateDiscussion.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// PrivateDiscussion_User
//
//

export class PrivateDiscussion_User {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'PrivateDiscussion_User';
    public id: number;
    public privateDiscussionId: number;
    public userId: number;
    public lastSeen: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public lastNotification: string;
    public createDate: string;
    public createrId: number;

    //
    // ATTRIBUTES
    //
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'privateDiscussionId', 'userId', 'lastSeen', 'lastNotification', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'privateDiscussionId', 'userId', 'lastSeen', 'lastNotification', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): PrivateDiscussion_User {
        const item = new PrivateDiscussion_User();
        item.id = data['id'];
        item.privateDiscussionId = data['privateDiscussionId'];
        item.userId = data['userId'];
        item.lastSeen = data['lastSeen'];
        item.lastNotification = data['lastNotification'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): PrivateDiscussion_User {
        const item: PrivateDiscussion_User = new PrivateDiscussion_User();
        item.id = parseFloat(data['id']);
        item.privateDiscussionId = parseFloat(data['privateDiscussionId']);
        item.userId = parseFloat(data['userId']);
        item.lastSeen = data['lastSeen'];
        item.lastNotification = data['lastNotification'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): PrivateDiscussion_User[] {
        const items: PrivateDiscussion_User[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(PrivateDiscussion_User.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            privateDiscussionId: this.privateDiscussionId,
            userId: this.userId,
            lastSeen: this.lastSeen,
            lastNotification: this.lastNotification,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): PrivateDiscussion_User {
        return PrivateDiscussion_User.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// User_UserLink
//
//

export class User_UserLink {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'User_UserLink';
    public id: number;
    public userId: number;
    public userLinkId: number;
    public link: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // ATTRIBUTES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'userId', 'userLinkId', 'link', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'userId', 'userLinkId', 'link', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): User_UserLink {
        const item = new User_UserLink();
        item.id = data['id'];
        item.userId = data['userId'];
        item.userLinkId = data['userLinkId'];
        item.link = data['link'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): User_UserLink {
        const item: User_UserLink = new User_UserLink();
        item.id = parseFloat(data['id']);
        item.userId = parseFloat(data['userId']);
        item.userLinkId = parseFloat(data['userLinkId']);
        item.link = data['link'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): User_UserLink[] {
        const items: User_UserLink[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(User_UserLink.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            userId: this.userId,
            userLinkId: this.userLinkId,
            link: this.link,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): User_UserLink {
        return User_UserLink.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// LikeCounter
//
//

export class LikeCounter {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'LikeCounter';
    public id: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    //
    // ATTRIBUTES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): LikeCounter {
        const item = new LikeCounter();
        item.id = data['id'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): LikeCounter {
        const item: LikeCounter = new LikeCounter();
        item.id = parseFloat(data['id']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): LikeCounter[] {
        const items: LikeCounter[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(LikeCounter.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): LikeCounter {
        return LikeCounter.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// LikeCounter_User
//
//

export class LikeCounter_User {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'LikeCounter_User';
    public id: number;
    public likeCounterId: number;
    public userId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'likeCounterId', 'userId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'likeCounterId', 'userId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): LikeCounter_User {
        const item = new LikeCounter_User();
        item.id = data['id'];
        item.likeCounterId = data['likeCounterId'];
        item.userId = data['userId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): LikeCounter_User {
        const item: LikeCounter_User = new LikeCounter_User();
        item.id = parseFloat(data['id']);
        item.likeCounterId = parseFloat(data['likeCounterId']);
        item.userId = parseFloat(data['userId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): LikeCounter_User[] {
        const items: LikeCounter_User[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(LikeCounter_User.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            likeCounterId: this.likeCounterId,
            userId: this.userId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): LikeCounter_User {
        return LikeCounter_User.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// CompanyCategoryGroup
//
//

export class CompanyCategoryGroup {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'CompanyCategoryGroup';
    public static readonly ID_LIFE_SCIENCE_INDUSTRY: number = 1;
    public id: number;
    public name: string;
    public description: string;

    //
    // CONSTANTS
    //

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): CompanyCategoryGroup {
        const item = new CompanyCategoryGroup();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): CompanyCategoryGroup {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['companyCategoryGroupId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: CompanyCategoryGroup = new CompanyCategoryGroup();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): CompanyCategoryGroup[] {
        const items: CompanyCategoryGroup[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(CompanyCategoryGroup.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): CompanyCategoryGroup {
        return CompanyCategoryGroup.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// CompanyCategory
//
//

export class CompanyCategory {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'CompanyCategory';
    public id: number;
    public companyCategoryGroupId: number;
    public name: string;
    public description: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public ordinal: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyCategoryGroupId', 'ordinal'];
    }

    //
    // ATTRIBUTES
    //

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'companyCategoryGroupId', 'name', 'description', 'ordinal'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): CompanyCategory {
        const item = new CompanyCategory();
        item.id = data['id'];
        item.companyCategoryGroupId = data['companyCategoryGroupId'];
        item.name = data['name'];
        item.description = data['description'];
        item.ordinal = data['ordinal'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): CompanyCategory {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['companyCategoryId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: CompanyCategory = new CompanyCategory();
        item.id = parseFloat(data['id']);
        item.companyCategoryGroupId = parseFloat(data['companyCategoryGroupId']);
        item.ordinal = parseFloat(data['ordinal']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): CompanyCategory[] {
        const items: CompanyCategory[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(CompanyCategory.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            companyCategoryGroupId: this.companyCategoryGroupId,
            name: this.name,
            description: this.description,
            ordinal: this.ordinal
        };
        return item;
    }

    public clone(): CompanyCategory {
        return CompanyCategory.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// InvestorValidationState
//
//

export class InvestorValidationState {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'InvestorValidationState';
    public static readonly ID_PENDING: number = 1;
    public static readonly ID_ACCEPTED: number = 2;
    public static readonly ID_REFUSED: number = 3;
    public id: number;

    //
    // CONSTANTS
    //
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): InvestorValidationState {
        const item = new InvestorValidationState();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): InvestorValidationState {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['investorValidationStateId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: InvestorValidationState = new InvestorValidationState();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): InvestorValidationState[] {
        const items: InvestorValidationState[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(InvestorValidationState.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): InvestorValidationState {
        return InvestorValidationState.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// InvestorProfile
//
//

export class InvestorProfile extends ModelSerialization {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'InvestorProfile';
    public id: number;
    public userId: number;
    public investorValidationStateId: number;
    public companyId: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    public investmentRangeFrom: number;
    public investmentRangeTo: number;
    public isInterstedInNotForProfit: number;

    //
    // ATTRIBUTES
    //
    public isInterstedInPartnerships: number;
    public isInterstedInLicenses: number;
    public acceptToBeContacted: number;
    public isNotificationsAlertEnabled: number;
    public isNotificationsDevPhaseEnabled: number;
    public isNotificationsSeekingFundsEnabled: number;
    public isNotificationsLeadInvestorEnabled: number;
    public phone: string;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    public static getNonTranslatableFields(): string[] {
        return ['id', 'userId', 'investorValidationStateId', 'companyId', 'investmentRangeFrom', 'investmentRangeTo', 'isInterstedInNotForProfit', 'isInterstedInPartnerships', 'isInterstedInLicenses', 'acceptToBeContacted', 'isNotificationsAlertEnabled', 'isNotificationsDevPhaseEnabled', 'isNotificationsSeekingFundsEnabled', 'isNotificationsLeadInvestorEnabled', 'phone', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'userId', 'investorValidationStateId', 'companyId', 'investmentRangeFrom', 'investmentRangeTo', 'isInterstedInNotForProfit', 'isInterstedInPartnerships', 'isInterstedInLicenses', 'acceptToBeContacted', 'isNotificationsAlertEnabled', 'isNotificationsDevPhaseEnabled', 'isNotificationsSeekingFundsEnabled', 'isNotificationsLeadInvestorEnabled', 'phone', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    //
    public static deserialize(data: any): InvestorProfile {
        return super.deserialize(InvestorProfile, data);
    }

    public static fromAPI(data: any): InvestorProfile {
        const item = new InvestorProfile();
        item.id = parseFloat(data['id']);
        item.userId = parseFloat(data['userId']);
        item.investorValidationStateId = parseFloat(data['investorValidationStateId']);
        item.companyId = parseFloat(data['companyId']);
        item.investmentRangeFrom = parseFloat(data['investmentRangeFrom']);
        item.investmentRangeTo = parseFloat(data['investmentRangeTo']);
        item.isInterstedInNotForProfit = parseFloat(data['isInterstedInNotForProfit']);
        item.isInterstedInPartnerships = parseFloat(data['isInterstedInPartnerships']);
        item.isInterstedInLicenses = parseFloat(data['isInterstedInLicenses']);
        item.acceptToBeContacted = parseFloat(data['acceptToBeContacted']);
        item.isNotificationsAlertEnabled = parseFloat(data['isNotificationsAlertEnabled']);
        item.isNotificationsDevPhaseEnabled = parseFloat(data['isNotificationsDevPhaseEnabled']);
        item.isNotificationsSeekingFundsEnabled = parseFloat(data['isNotificationsSeekingFundsEnabled']);
        item.isNotificationsLeadInvestorEnabled = parseFloat(data['isNotificationsLeadInvestorEnabled']);
        item.phone = data['phone'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    public static fromAPIList(data: any[]): InvestorProfile[] {
        const items: InvestorProfile[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(InvestorProfile.fromAPI(data[i]));
        }
        return items;
    }

    public clone(): InvestorProfile {
        return InvestorProfile.deserialize(JSON.parse(JSON.stringify(this)));
    }
}

//
//
// TherapeuticAreaOfInterest
//
//

export class TherapeuticAreaOfInterest {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'TherapeuticAreaOfInterest';
    public id: number;
    public investorProfileId: number;
    public therapeuticAreaId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'investorProfileId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'investorProfileId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): TherapeuticAreaOfInterest {
        const item = new TherapeuticAreaOfInterest();
        item.id = data['id'];
        item.investorProfileId = data['investorProfileId'];
        item.therapeuticAreaId = data['therapeuticAreaId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): TherapeuticAreaOfInterest {
        const item: TherapeuticAreaOfInterest = new TherapeuticAreaOfInterest();
        item.id = parseFloat(data['id']);
        item.investorProfileId = parseFloat(data['investorProfileId']);
        item.therapeuticAreaId = parseFloat(data['therapeuticAreaId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): TherapeuticAreaOfInterest[] {
        const items: TherapeuticAreaOfInterest[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(TherapeuticAreaOfInterest.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            investorProfileId: this.investorProfileId,
            therapeuticAreaId: this.therapeuticAreaId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): TherapeuticAreaOfInterest {
        return TherapeuticAreaOfInterest.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// DevelopmentPhaseOfInterest
//
//

export class DevelopmentPhaseOfInterest {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'DevelopmentPhaseOfInterest';
    public id: number;
    public investorProfileId: number;
    public developmentPhaseId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'investorProfileId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'investorProfileId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): DevelopmentPhaseOfInterest {
        const item = new DevelopmentPhaseOfInterest();
        item.id = data['id'];
        item.investorProfileId = data['investorProfileId'];
        item.developmentPhaseId = data['developmentPhaseId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): DevelopmentPhaseOfInterest {
        const item: DevelopmentPhaseOfInterest = new DevelopmentPhaseOfInterest();
        item.id = parseFloat(data['id']);
        item.investorProfileId = parseFloat(data['investorProfileId']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): DevelopmentPhaseOfInterest[] {
        const items: DevelopmentPhaseOfInterest[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(DevelopmentPhaseOfInterest.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            investorProfileId: this.investorProfileId,
            developmentPhaseId: this.developmentPhaseId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): DevelopmentPhaseOfInterest {
        return DevelopmentPhaseOfInterest.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectFavorite
//
//

export class ProjectFavorite {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectFavorite';
    public id: number;
    public userId: number;
    public projectId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'userId', 'projectId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'userId', 'projectId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectFavorite {
        const item = new ProjectFavorite();
        item.id = data['id'];
        item.userId = data['userId'];
        item.projectId = data['projectId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectFavorite {
        const item: ProjectFavorite = new ProjectFavorite();
        item.id = parseFloat(data['id']);
        item.userId = parseFloat(data['userId']);
        item.projectId = parseFloat(data['projectId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectFavorite[] {
        const items: ProjectFavorite[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectFavorite.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            userId: this.userId,
            projectId: this.projectId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectFavorite {
        return ProjectFavorite.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert
//
//

export class Alert {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Alert';
    public id: number;
    public ownerId: number;
    public name: string;
    public isCompanyIncorporated: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public isCompanyNotIncorporated: number;
    public yearOfIncorporationFrom: number;
    public yearOfIncorporationTo: number;

    //
    // ATTRIBUTES
    //
    public yearOfPlannedIncorporationFrom: number;
    public yearOfPlannedIncorporationTo: number;
    public isOutlicensing: number;
    public isNotOutlicensing: number;
    public isSeekingPartnerhips: number;
    public isNotSeekingPartnerhips: number;
    public isNotForProfit: number;
    public isNotNotForProfit: number;
    public isRaisingFunds: number;
    public isNotRaisingFunds: number;
    public raisingFundsAmountFrom: number;
    public raisingFundsAmountTo: number;
    public isPublicCompany: number;
    public isPrivateCompany: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'ownerId', 'name', 'isCompanyIncorporated', 'isCompanyNotIncorporated', 'yearOfIncorporationFrom', 'yearOfIncorporationTo', 'yearOfPlannedIncorporationFrom', 'yearOfPlannedIncorporationTo', 'isOutlicensing', 'isNotOutlicensing', 'isSeekingPartnerhips', 'isNotSeekingPartnerhips', 'isNotForProfit', 'isNotNotForProfit', 'isRaisingFunds', 'isNotRaisingFunds', 'raisingFundsAmountFrom', 'raisingFundsAmountTo', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'isPublicCompany', 'isPrivateCompany'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'ownerId', 'name', 'isCompanyIncorporated', 'isCompanyNotIncorporated', 'yearOfIncorporationFrom', 'yearOfIncorporationTo', 'yearOfPlannedIncorporationFrom', 'yearOfPlannedIncorporationTo', 'isOutlicensing', 'isNotOutlicensing', 'isSeekingPartnerhips', 'isNotSeekingPartnerhips', 'isNotForProfit', 'isNotNotForProfit', 'isRaisingFunds', 'isNotRaisingFunds', 'raisingFundsAmountFrom', 'raisingFundsAmountTo', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'isPublicCompany', 'isPrivateCompany'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert {
        const item = new Alert();
        item.id = data['id'];
        item.ownerId = data['ownerId'];
        item.name = data['name'];
        item.isCompanyIncorporated = data['isCompanyIncorporated'];
        item.isCompanyNotIncorporated = data['isCompanyNotIncorporated'];
        item.yearOfIncorporationFrom = data['yearOfIncorporationFrom'];
        item.yearOfIncorporationTo = data['yearOfIncorporationTo'];
        item.yearOfPlannedIncorporationFrom = data['yearOfPlannedIncorporationFrom'];
        item.yearOfPlannedIncorporationTo = data['yearOfPlannedIncorporationTo'];
        item.isOutlicensing = data['isOutlicensing'];
        item.isNotOutlicensing = data['isNotOutlicensing'];
        item.isSeekingPartnerhips = data['isSeekingPartnerhips'];
        item.isNotSeekingPartnerhips = data['isNotSeekingPartnerhips'];
        item.isNotForProfit = data['isNotForProfit'];
        item.isNotNotForProfit = data['isNotNotForProfit'];
        item.isRaisingFunds = data['isRaisingFunds'];
        item.isNotRaisingFunds = data['isNotRaisingFunds'];
        item.raisingFundsAmountFrom = data['raisingFundsAmountFrom'];
        item.raisingFundsAmountTo = data['raisingFundsAmountTo'];
        item.isPublicCompany = data['isPublicCompany'];
        item.isPrivateCompany = data['isPrivateCompany'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert {
        const item: Alert = new Alert();
        item.id = parseFloat(data['id']);
        item.ownerId = parseFloat(data['ownerId']);
        item.name = data['name'];
        item.isCompanyIncorporated = parseFloat(data['isCompanyIncorporated']);
        item.isCompanyNotIncorporated = parseFloat(data['isCompanyNotIncorporated']);
        item.yearOfIncorporationFrom = parseFloat(data['yearOfIncorporationFrom']);
        item.yearOfIncorporationTo = parseFloat(data['yearOfIncorporationTo']);
        item.yearOfPlannedIncorporationFrom = parseFloat(data['yearOfPlannedIncorporationFrom']);
        item.yearOfPlannedIncorporationTo = parseFloat(data['yearOfPlannedIncorporationTo']);
        item.isOutlicensing = parseFloat(data['isOutlicensing']);
        item.isNotOutlicensing = parseFloat(data['isNotOutlicensing']);
        item.isSeekingPartnerhips = parseFloat(data['isSeekingPartnerhips']);
        item.isNotSeekingPartnerhips = parseFloat(data['isNotSeekingPartnerhips']);
        item.isNotForProfit = parseFloat(data['isNotForProfit']);
        item.isNotNotForProfit = parseFloat(data['isNotNotForProfit']);
        item.isRaisingFunds = parseFloat(data['isRaisingFunds']);
        item.isNotRaisingFunds = parseFloat(data['isNotRaisingFunds']);
        item.raisingFundsAmountFrom = parseFloat(data['raisingFundsAmountFrom']);
        item.raisingFundsAmountTo = parseFloat(data['raisingFundsAmountTo']);
        item.isPublicCompany = parseFloat(data['isPublicCompany']);
        item.isPrivateCompany = parseFloat(data['isPrivateCompany']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Alert[] {
        const items: Alert[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            ownerId: this.ownerId,
            name: this.name,
            isCompanyIncorporated: this.isCompanyIncorporated,
            isCompanyNotIncorporated: this.isCompanyNotIncorporated,
            yearOfIncorporationFrom: this.yearOfIncorporationFrom,
            yearOfIncorporationTo: this.yearOfIncorporationTo,
            yearOfPlannedIncorporationFrom: this.yearOfPlannedIncorporationFrom,
            yearOfPlannedIncorporationTo: this.yearOfPlannedIncorporationTo,
            isOutlicensing: this.isOutlicensing,
            isNotOutlicensing: this.isNotOutlicensing,
            isSeekingPartnerhips: this.isSeekingPartnerhips,
            isNotSeekingPartnerhips: this.isNotSeekingPartnerhips,
            isNotForProfit: this.isNotForProfit,
            isNotNotForProfit: this.isNotNotForProfit,
            isRaisingFunds: this.isRaisingFunds,
            isNotRaisingFunds: this.isNotRaisingFunds,
            raisingFundsAmountFrom: this.raisingFundsAmountFrom,
            raisingFundsAmountTo: this.raisingFundsAmountTo,
            isPrivateCompany: this.isPrivateCompany,
            isPublicCompany: this.isPublicCompany,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert {
        return Alert.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert_ProjectType
//
//

export class Alert_ProjectType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Alert_ProjectType';
    public id: number;
    public alertId: number;
    public projectTypeId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'alertId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'alertId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert_ProjectType {
        const item = new Alert_ProjectType();
        item.id = data['id'];
        item.alertId = data['alertId'];
        item.projectTypeId = data['projectTypeId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert_ProjectType {
        const item: Alert_ProjectType = new Alert_ProjectType();
        item.id = parseFloat(data['id']);
        item.alertId = parseFloat(data['alertId']);
        item.projectTypeId = parseFloat(data['projectTypeId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Alert_ProjectType[] {
        const items: Alert_ProjectType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert_ProjectType.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            alertId: this.alertId,
            projectTypeId: this.projectTypeId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert_ProjectType {
        return Alert_ProjectType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert_TherapeuticArea
//
//

export class Alert_TherapeuticArea {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Alert_TherapeuticArea';
    public id: number;
    public alertId: number;
    public therapeuticAreaId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'alertId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'alertId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert_TherapeuticArea {
        const item = new Alert_TherapeuticArea();
        item.id = data['id'];
        item.alertId = data['alertId'];
        item.therapeuticAreaId = data['therapeuticAreaId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert_TherapeuticArea {
        const item: Alert_TherapeuticArea = new Alert_TherapeuticArea();
        item.id = parseFloat(data['id']);
        item.alertId = parseFloat(data['alertId']);
        item.therapeuticAreaId = parseFloat(data['therapeuticAreaId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Alert_TherapeuticArea[] {
        const items: Alert_TherapeuticArea[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert_TherapeuticArea.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            alertId: this.alertId,
            therapeuticAreaId: this.therapeuticAreaId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert_TherapeuticArea {
        return Alert_TherapeuticArea.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert_DevelopmentPhase
//
//

export class Alert_DevelopmentPhase {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Alert_DevelopmentPhase';
    public id: number;
    public alertId: number;
    public developmentPhaseId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'alertId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'alertId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert_DevelopmentPhase {
        const item = new Alert_DevelopmentPhase();
        item.id = data['id'];
        item.alertId = data['alertId'];
        item.developmentPhaseId = data['developmentPhaseId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert_DevelopmentPhase {
        const item: Alert_DevelopmentPhase = new Alert_DevelopmentPhase();
        item.id = parseFloat(data['id']);
        item.alertId = parseFloat(data['alertId']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Alert_DevelopmentPhase[] {
        const items: Alert_DevelopmentPhase[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert_DevelopmentPhase.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            alertId: this.alertId,
            developmentPhaseId: this.developmentPhaseId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert_DevelopmentPhase {
        return Alert_DevelopmentPhase.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert_FundingRound
//
//

export class Alert_FundingRound {

    public static readonly tableName = 'Alert_FundingRound';
    public id: number;
    public alertId: number;
    public fundingRoundId: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'alertId', 'fundingRoundId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'alertId', 'fundingRoundId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert_FundingRound {
        const item = new Alert_FundingRound();
        item.id = data['id'];
        item.alertId = data['alertId'];
        item.fundingRoundId = data['fundingRoundId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert_FundingRound {
        const item: Alert_FundingRound = new Alert_FundingRound();
        item.id = parseFloat(data['id']);
        item.alertId = parseFloat(data['alertId']);
        item.fundingRoundId = parseFloat(data['fundingRoundId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    public static fromAPIList(data: any[]): Alert_FundingRound[] {
        const items: Alert_FundingRound[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert_FundingRound.fromAPI(data[i]));
        }
        return items;
    }

    public toJson(): Object {
        const item: any = {
            id: this.id,
            alertId: this.alertId,
            fundingRoundId: this.fundingRoundId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert_FundingRound {
        return Alert_FundingRound.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Alert_AcademicInstitution
//
//

export class Alert_AcademicInstitution {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Alert_AcademicInstitution';
    public id: number;
    public alertId: number;
    public academicInstitutionId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'alertId', 'academicInstitutionId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'alertId', 'academicInstitutionId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Alert_AcademicInstitution {
        const item = new Alert_AcademicInstitution();
        item.id = data['id'];
        item.alertId = data['alertId'];
        item.academicInstitutionId = data['academicInstitutionId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Alert_AcademicInstitution {
        const item: Alert_AcademicInstitution = new Alert_AcademicInstitution();
        item.id = parseFloat(data['id']);
        item.alertId = parseFloat(data['alertId']);
        item.academicInstitutionId = parseFloat(data['academicInstitutionId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Alert_AcademicInstitution[] {
        const items: Alert_AcademicInstitution[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Alert_AcademicInstitution.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            alertId: this.alertId,
            academicInstitutionId: this.academicInstitutionId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Alert_AcademicInstitution {
        return Alert_AcademicInstitution.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

export class ProjectTradeType extends ModelSerialization {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectTradeType';
    public static readonly ID_PUBLIC: number = 1;
    public static readonly ID_PRIVATE: number = 2;
    public id: number;
    public name: string;

    //
    // CONSTANTS
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name'];
    }

    public static getFields(): string[] {
        return ['id', 'name'];
    }

    //
    // FACTORIES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static deserialize(data: any): ProjectTradeType {
        return super.deserialize(ProjectTradeType, data);
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectTradeType {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectTradeTypeId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectTradeType = new ProjectTradeType();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        return item;
    }

    public static fromAPIList(data: any[], translationsData: any[]): ProjectTradeType[] {
        const items: ProjectTradeType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectTradeType.fromAPI(data[i], translationsData));
        }
        return items;
    }

    public clone(): ProjectTradeType {
        return ProjectTradeType.deserialize(JSON.parse(JSON.stringify(this)));
    }
}

//
//
// ProjectTypeGroup
//
//

export class ProjectTypeGroup {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectTypeGroup';
    public static readonly ID_MEDTECH: number = 1;
    public static readonly ID_BIOTECH: number = 2;
    public static readonly ID_HEALTHCARE: number = 3;
    public id: number;

    //
    // CONSTANTS
    //
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectTypeGroup {
        const item = new ProjectTypeGroup();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectTypeGroup {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectTypeGroupId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectTypeGroup = new ProjectTypeGroup();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): ProjectTypeGroup[] {
        const items: ProjectTypeGroup[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectTypeGroup.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): ProjectTypeGroup {
        return ProjectTypeGroup.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectType
//
//

export class ProjectType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectType';
    public id: number;
    public projectTypeGroupId: number;
    public name: string;
    public description: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectTypeGroupId'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getFields(): string[] {
        return ['id', 'projectTypeGroupId', 'name', 'description'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectType {
        const item = new ProjectType();
        item.id = data['id'];
        item.projectTypeGroupId = data['projectTypeGroupId'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectType {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectTypeId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectType = new ProjectType();
        item.id = parseFloat(data['id']);
        item.projectTypeGroupId = parseFloat(data['projectTypeGroupId']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): ProjectType[] {
        const items: ProjectType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectType.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectTypeGroupId: this.projectTypeGroupId,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): ProjectType {
        return ProjectType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// TherapeuticArea
//
//

export class TherapeuticArea {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'TherapeuticArea';
    public static readonly ID_ANAESTHESIOLOGY: number = 1;
    public static readonly ID_ANGIOLOGY: number = 2;
    public static readonly ID_CARDIOLOGY: number = 3;
    public static readonly ID_DERMATOLOGY: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_ENDOCRINOLOGY: number = 5;
    public static readonly ID_GASTROENTEROLOGY: number = 6;
    public static readonly ID_GERIATRICS: number = 7;
    public static readonly ID_GYNECOLOGY: number = 8;
    public static readonly ID_HERMATOLOGY: number = 9;
    public static readonly ID_IMMUNOLOGY: number = 10;
    public static readonly ID_INFECTIOUS_DISEASES: number = 11;
    public static readonly ID_NEPHROLOGY: number = 12;
    public static readonly ID_NEUROLOGY: number = 13;
    public static readonly ID_ONCOLOGY: number = 14;
    public static readonly ID_OPHTHALMOLOGY: number = 15;
    public static readonly ID_ORTHOPAEDICS: number = 16;
    public static readonly ID_OTOROHINOLARYNGOLOGY: number = 17;
    public static readonly ID_PAEDIATRICS: number = 18;
    public static readonly ID_PULMONOLOGY: number = 18;
    public static readonly ID_PRIMARY_CARE: number = 20;
    public static readonly ID_PSYCHIATRY: number = 21;
    public static readonly ID_RADIOLOGY: number = 22;
    public static readonly ID_RHEUMATOLOGY: number = 23;
    public static readonly ID_SURGERY: number = 24;
    public static readonly ID_TROPICAL_AND_HUMANITARIAN_MEDICINE: number = 25;
    public static readonly ID_UROLOGY: number = 26;
    public static readonly ID_RARE_DISEASES: number = 27;
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): TherapeuticArea {
        const item = new TherapeuticArea();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): TherapeuticArea {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['therapeuticAreaId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: TherapeuticArea = new TherapeuticArea();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): TherapeuticArea[] {
        const items: TherapeuticArea[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(TherapeuticArea.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): TherapeuticArea {
        return TherapeuticArea.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// DevelopmentPhase
//
//

export class DevelopmentPhase {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'DevelopmentPhase';
    public static readonly ID_DISCOVERY: number = 2;
    public static readonly ID_PROTOTYPING: number = 3;
    public static readonly ID_CLINICAL_DEVELOPMENT: number = 4;
    public static readonly ID_CANDIDATE: number = 5;

    //
    // CONSTANTS
    //
    public static readonly ID_CLINICAL_TRIALS: number = 6;
    public static readonly ID_APPROVAL: number = 7;
    public static readonly ID_PRODUCT: number = 8;
    public id: number;
    public name: string;
    public description: string;
    public ordinal: number;

    //
    // FACTORIES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'ordinal'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getFields(): string[] {
        return ['id', 'name', 'description', 'ordinal'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): DevelopmentPhase {
        const item = new DevelopmentPhase();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        item.ordinal = data['ordinal'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): DevelopmentPhase {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['developmentPhaseId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: DevelopmentPhase = new DevelopmentPhase();
        item.id = parseFloat(data['id']);
        item.ordinal = parseFloat(data['ordinal']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): DevelopmentPhase[] {
        const items: DevelopmentPhase[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(DevelopmentPhase.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description,
            ordinal: this.ordinal
        };
        return item;
    }

    public clone(): DevelopmentPhase {
        return DevelopmentPhase.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// AcademicInstitutionGroup
//
//

export class AcademicInstitutionGroup {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'AcademicInstitutionGroup';
    public static readonly ID_UNIVERSITIES: number = 1;
    public static readonly ID_UNIVERSITIES_OF_APPLIED_SCIENCES: number = 2;
    public static readonly ID_OTHER: number = 3;
    public static readonly ID_ETH: number = 4;

    //
    // CONSTANTS
    //
    public id: number;
    public name: string;
    public description: string;
    public ordinal: number;

    //
    // FACTORIES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'ordinal'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getFields(): string[] {
        return ['id', 'name', 'description', 'ordinal'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): AcademicInstitutionGroup {
        const item = new AcademicInstitutionGroup();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        item.ordinal = data['ordinal'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): AcademicInstitutionGroup {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['academicInstitutionGroupId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: AcademicInstitutionGroup = new AcademicInstitutionGroup();
        item.id = parseFloat(data['id']);
        item.ordinal = parseFloat(data['ordinal']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): AcademicInstitutionGroup[] {
        const items: AcademicInstitutionGroup[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(AcademicInstitutionGroup.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description,
            ordinal: this.ordinal
        };
        return item;
    }

    public clone(): AcademicInstitutionGroup {
        return AcademicInstitutionGroup.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// AcademicInstitution
//
//

export class AcademicInstitution {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'AcademicInstitution';
    public static readonly ID_EPFL: number = 1;
    public static readonly ID_ETHZ: number = 2;
    public static readonly ID_UNI_ZH: number = 3;
    public static readonly ID_UNI_BS: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_UNI_BE: number = 5;
    public static readonly ID_UNI_SG: number = 6;
    public static readonly ID_UNI_LU: number = 7;
    public static readonly ID_UNI_NE: number = 8;
    public static readonly ID_UNI_FR: number = 9;
    public static readonly ID_UNI_GE: number = 10;
    public static readonly ID_UNI_VD: number = 11;
    public static readonly ID_UNI_TI: number = 12;
    public static readonly ID_BFH: number = 13;
    public static readonly ID_FHNW: number = 14;
    public static readonly ID_ZFH: number = 15;
    public static readonly ID_HSLU: number = 16;
    public static readonly ID_FHO: number = 17;
    public static readonly ID_KALAIDOS: number = 18;
    public static readonly ID_HESSO: number = 19;
    public static readonly ID_SUPSI: number = 20;
    public static readonly ID_NONE: number = 21;
    public static readonly ID_OTHER: number = 22;
    public static readonly ID_PSI: number = 23;
    public static readonly ID_EMPA: number = 24;
    public id: number;
    public academicInstitutionGroupId: number;
    public name: string;
    public description: string;

    //
    // FACTORIES
    //
    public ordinal: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'academicInstitutionGroupId', 'ordinal'];
    }

    //
    // ATTRIBUTES
    //

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'academicInstitutionGroupId', 'name', 'description', 'ordinal'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): AcademicInstitution {
        const item = new AcademicInstitution();
        item.id = data['id'];
        item.academicInstitutionGroupId = data['academicInstitutionGroupId'];
        item.name = data['name'];
        item.description = data['description'];
        item.ordinal = data['ordinal'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): AcademicInstitution {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['academicInstitutionId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: AcademicInstitution = new AcademicInstitution();
        item.id = parseFloat(data['id']);
        item.academicInstitutionGroupId = parseFloat(data['academicInstitutionGroupId']);
        item.ordinal = parseFloat(data['ordinal']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): AcademicInstitution[] {
        const items: AcademicInstitution[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(AcademicInstitution.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            academicInstitutionGroupId: this.academicInstitutionGroupId,
            name: this.name,
            description: this.description,
            ordinal: this.ordinal
        };
        return item;
    }

    public clone(): AcademicInstitution {
        return AcademicInstitution.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// TeamMemberType
//
//

export class TeamMemberType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'TeamMemberType';
    public static readonly ID_MEMBER: number = 1;
    public static readonly ID_ADVISORY_BOARD: number = 2;
    public static readonly ID_BOARD_OF_DIRECTORS: number = 3;
    public id: number;

    //
    // CONSTANTS
    //
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): TeamMemberType {
        const item = new TeamMemberType();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): TeamMemberType {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['teamMemberTypeId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: TeamMemberType = new TeamMemberType();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): TeamMemberType[] {
        const items: TeamMemberType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(TeamMemberType.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): TeamMemberType {
        return TeamMemberType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectVisibility
//
//

export class ProjectVisibility {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectVisibility';
    public static readonly ID_NONE: number = 1;
    public static readonly ID_TITLE: number = 2;
    public static readonly ID_TITLE_SUBTITLE: number = 3;
    public static readonly ID_TITLE_SUBTITLE_DESCRIPTION: number = 4;

    //
    // CONSTANTS
    //
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectVisibility {
        const item = new ProjectVisibility();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectVisibility {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectVisibilityId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectVisibility = new ProjectVisibility();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): ProjectVisibility[] {
        const items: ProjectVisibility[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectVisibility.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): ProjectVisibility {
        return ProjectVisibility.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// PatentState
//
//

export class PatentState {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'PatentState';
    public static readonly ID_PLANNED: number = 1;
    public static readonly ID_FILED: number = 2;
    public static readonly ID_GRANTED: number = 3;
    public static readonly ID_NOT_PLANNED: number = 4;

    //
    // CONSTANTS
    //
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): PatentState {
        const item = new PatentState();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): PatentState {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['patentStateId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: PatentState = new PatentState();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): PatentState[] {
        const items: PatentState[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(PatentState.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): PatentState {
        return PatentState.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// FundingRound
//
//

export class FundingRound {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'FundingRound';
    public static readonly ID_PRE_SEED: number = 1;
    public static readonly ID_SEED: number = 2;
    public static readonly ID_SERIES_A: number = 3;
    public static readonly ID_SERIES_B: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_SERIES_C: number = 5;
    public static readonly ID_OTHER: number = 6;
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): FundingRound {
        const item = new FundingRound();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): FundingRound {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['fundingRoundId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: FundingRound = new FundingRound();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): FundingRound[] {
        const items: FundingRound[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(FundingRound.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): FundingRound {
        return FundingRound.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectValidationState
//
//

export class ProjectValidationState {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectValidationState';
    public static readonly ID_DRAFT: number = 1;
    public static readonly ID_PENDING_VALIDATION: number = 2;
    public static readonly ID_PUBLISHED: number = 3;
    public static readonly ID_REFUSED: number = 4;

    //
    // CONSTANTS
    //
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectValidationState {
        const item = new ProjectValidationState();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectValidationState {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectValidationStateId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectValidationState = new ProjectValidationState();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): ProjectValidationState[] {
        const items: ProjectValidationState[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectValidationState.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): ProjectValidationState {
        return ProjectValidationState.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectPrize
//
//

export class ProjectPrize {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectPrize';
    public id: number;
    public projectId: number;
    public name: string;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'name', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'name', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectPrize {
        const item = new ProjectPrize();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.name = data['name'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectPrize {
        const item: ProjectPrize = new ProjectPrize();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.name = data['name'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectPrize[] {
        const items: ProjectPrize[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectPrize.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            name: this.name,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectPrize {
        return ProjectPrize.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectPastFunding
//
//

export class ProjectPastFunding {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectPastFunding';
    public id: number;
    public projectId: number;
    public source: string;
    public amount: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // ATTRIBUTES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'source', 'amount', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'source', 'amount', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectPastFunding {
        const item = new ProjectPastFunding();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.source = data['source'];
        item.amount = data['amount'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectPastFunding {
        const item: ProjectPastFunding = new ProjectPastFunding();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.source = data['source'];
        item.amount = parseFloat(data['amount']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectPastFunding[] {
        const items: ProjectPastFunding[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectPastFunding.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            source: this.source,
            amount: this.amount,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectPastFunding {
        return ProjectPastFunding.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectTeamMember
//
//

export class ProjectTeamMember {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectTeamMember';
    public id: number;
    public projectId: number;
    public teamMemberTypeId: number;
    public firstName: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public lastName: string;
    public teamFunction: string;
    public createDate: string;

    //
    // ATTRIBUTES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'teamMemberTypeId', 'firstName', 'lastName', 'teamFunction', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'teamMemberTypeId', 'firstName', 'lastName', 'teamFunction', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectTeamMember {
        const item = new ProjectTeamMember();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.teamMemberTypeId = data['teamMemberTypeId'];
        item.firstName = data['firstName'];
        item.lastName = data['lastName'];
        item.teamFunction = data['teamFunction'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectTeamMember {
        const item: ProjectTeamMember = new ProjectTeamMember();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.teamMemberTypeId = parseFloat(data['teamMemberTypeId']);
        item.firstName = data['firstName'];
        item.lastName = data['lastName'];
        item.teamFunction = data['teamFunction'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectTeamMember[] {
        const items: ProjectTeamMember[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectTeamMember.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            teamMemberTypeId: this.teamMemberTypeId,
            firstName: this.firstName,
            lastName: this.lastName,
            teamFunction: this.teamFunction,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectTeamMember {
        return ProjectTeamMember.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Project
//
//

export class Project extends ModelSerialization {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Project';
    public id: number;
    public ownerId: number;
    public projectValidationStateId: number;
    public fileGroupId: number;
    public projectVisibilityId: number;
    public developmentPhaseId: number;
    public academicInstitution1Id: number;

    //
    // ATTRIBUTES
    //
    public academicInstitution2Id: number;
    public patentStateId: number;
    public fundingRoundId: number;
    public name: string;
    public pipeline: string;
    public keywords: string;
    public logo: string;
    public illustration: string;
    public isNotForProfit: number;
    public isCompanyIncorporated: number;
    public incorporationYear: number;
    public isSpinOff: number;
    public customAcademicInstitution: string;
    public isRaisingFunds: number;
    public raisingFundsAmount: number;
    public dateOfClosingRound: string;
    public customFundingRound: string;
    public isSeekingPartnerships: number;
    public partnershipTerms: string;
    public isOfferingLicensing: number;
    public licensingOffer: string;
    public teamPicture: string;
    public contactWebsite: string;
    public contactFirstName: string;
    public contactLastName: string;
    public contactEmail: string;
    public contactPhone: string;
    public contactAddress: string;
    public isLeadInvestorIdentified: number;
    public isTTOAgreementAccepted: number;
    public TTOContact: string;
    public isInstitutionLicenseOwned: number;
    public developmentPhaseUpdateDate: string;
    public isRaisingFundsUpdateDate: string;
    public isLeadInvestorIdentifiedUpdateDate: string;
    public publishDate: string;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;
    public biotechDirectoryUrl: string;
    public projectTradeTypeId: number;

    public static getNonTranslatableFields(): string[] {
        return ['id', 'ownerId', 'projectValidationStateId', 'fileGroupId', 'projectVisibilityId', 'developmentPhaseId', 'academicInstitution1Id', 'academicInstitution2Id', 'patentStateId', 'fundingRoundId', 'logo', 'illustration', 'isNotForProfit', 'isCompanyIncorporated', 'incorporationYear', 'isSpinOff', 'customAcademicInstitution', 'isRaisingFunds', 'raisingFundsAmount', 'dateOfClosingRound', 'customFundingRound', 'isSeekingPartnerships', 'isOfferingLicensing', 'teamPicture', 'contactWebsite', 'contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone', 'contactAddress', 'isLeadInvestorIdentified', 'isTTOAgreementAccepted', 'TTOContact', 'isInstitutionLicenseOwned', 'developmentPhaseUpdateDate', 'isRaisingFundsUpdateDate', 'isLeadInvestorIdentifiedUpdateDate', 'publishDate', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'biotechDirectoryUrl', 'projectTradeTypeId'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'pipeline', 'keywords', 'partnershipTerms', 'licensingOffer'];
    }

    public static getFields(): string[] {
        return ['id', 'ownerId', 'projectValidationStateId', 'fileGroupId', 'projectVisibilityId', 'developmentPhaseId', 'academicInstitution1Id', 'academicInstitution2Id', 'patentStateId', 'fundingRoundId', 'name', 'pipeline', 'keywords', 'logo', 'illustration', 'isNotForProfit', 'isCompanyIncorporated', 'incorporationYear', 'isSpinOff', 'customAcademicInstitution', 'isRaisingFunds', 'raisingFundsAmount', 'dateOfClosingRound', 'customFundingRound', 'isSeekingPartnerships', 'partnershipTerms', 'isOfferingLicensing', 'licensingOffer', 'teamPicture', 'contactWebsite', 'contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone', 'contactAddress', 'isLeadInvestorIdentified', 'isTTOAgreementAccepted', 'TTOContact', 'isInstitutionLicenseOwned', 'developmentPhaseUpdateDate', 'isRaisingFundsUpdateDate', 'isLeadInvestorIdentifiedUpdateDate', 'publishDate', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'biotechDirectoryUrl', 'projectTradeTypeId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static deserialize(data: any): Project {
        return super.deserialize(Project, data);
    }

    public static fromAPI(data: any, translationsData: any[]): Project {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: Project = new Project();
        item.id = parseFloat(data['id']);
        item.ownerId = parseFloat(data['ownerId']);
        item.projectValidationStateId = parseFloat(data['projectValidationStateId']);
        item.fileGroupId = parseFloat(data['fileGroupId']);
        item.projectVisibilityId = parseFloat(data['projectVisibilityId']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.academicInstitution1Id = data['academicInstitution1Id'];
        item.academicInstitution2Id = data['academicInstitution2Id'];
        item.patentStateId = parseFloat(data['patentStateId']);
        item.fundingRoundId = parseFloat(data['fundingRoundId']);
        item.logo = data['logo'];
        item.illustration = data['illustration'];
        item.isNotForProfit = parseFloat(data['isNotForProfit']);
        item.isCompanyIncorporated = parseFloat(data['isCompanyIncorporated']);
        item.incorporationYear = parseFloat(data['incorporationYear']);
        item.isSpinOff = parseFloat(data['isSpinOff']);
        item.customAcademicInstitution = data['customAcademicInstitution'];
        item.isRaisingFunds = parseFloat(data['isRaisingFunds']);
        item.raisingFundsAmount = parseFloat(data['raisingFundsAmount']);
        item.dateOfClosingRound = data['dateOfClosingRound'];
        item.customFundingRound = data['customFundingRound'];
        item.isSeekingPartnerships = parseFloat(data['isSeekingPartnerships']);
        item.isOfferingLicensing = parseFloat(data['isOfferingLicensing']);
        item.teamPicture = data['teamPicture'];
        item.contactWebsite = data['contactWebsite'];
        item.contactFirstName = data['contactFirstName'];
        item.contactLastName = data['contactLastName'];
        item.contactEmail = data['contactEmail'];
        item.contactPhone = data['contactPhone'];
        item.contactAddress = data['contactAddress'];
        item.isLeadInvestorIdentified = parseFloat(data['isLeadInvestorIdentified']);
        item.isTTOAgreementAccepted = parseFloat(data['isTTOAgreementAccepted']);
        item.TTOContact = data['TTOContact'];
        item.isInstitutionLicenseOwned = parseFloat(data['isInstitutionLicenseOwned']);
        item.developmentPhaseUpdateDate = data['developmentPhaseUpdateDate'];
        item.isRaisingFundsUpdateDate = data['isRaisingFundsUpdateDate'];
        item.isLeadInvestorIdentifiedUpdateDate = data['isLeadInvestorIdentifiedUpdateDate'];
        item.publishDate = data['publishDate'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        item.name = translationData['name'];
        item.pipeline = translationData['pipeline'];
        item.keywords = translationData['keywords'];
        item.partnershipTerms = translationData['partnershipTerms'];
        item.licensingOffer = translationData['licensingOffer'];
        item.biotechDirectoryUrl = data['biotechDirectoryUrl'];
        item.projectTradeTypeId = parseFloat(data['projectTradeTypeId']);
        return item;
    }

    public static fromAPIList(data: any[], translationsData: any[]): Project[] {
        const items: Project[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Project.fromAPI(data[i], translationsData));
        }
        return items;
    }

    public clone(): Project {
        return Project.deserialize(JSON.parse(JSON.stringify(this)));
    }
}

//
//
// ProjectMembership
//
//

export class ProjectMembership {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectMembership';
    public id: number;
    public projectId: number;
    public userId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'userId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'userId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectMembership {
        const item = new ProjectMembership();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.userId = data['userId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectMembership {
        const item: ProjectMembership = new ProjectMembership();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.userId = parseFloat(data['userId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectMembership[] {
        const items: ProjectMembership[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectMembership.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            userId: this.userId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectMembership {
        return ProjectMembership.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectCase_ProjectType
//
//

export class ProjectCase_ProjectType {

    public static readonly tableName = 'ProjectCase_ProjectType';
    public id: number;
    public projectCaseId: number;
    public projectTypeId: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectCaseId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectCaseId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectCase_ProjectType {
        const item = new ProjectCase_ProjectType();
        item.id = data['id'];
        item.projectCaseId = data['projectCaseId'];
        item.projectTypeId = data['projectTypeId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectCase_ProjectType {
        const item: ProjectCase_ProjectType = new ProjectCase_ProjectType();
        item.id = parseFloat(data['id']);
        item.projectCaseId = parseFloat(data['projectCaseId']);
        item.projectTypeId = parseFloat(data['projectTypeId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    public static fromAPIList(data: any[]): ProjectCase_ProjectType[] {
        const items: ProjectCase_ProjectType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectCase_ProjectType.fromAPI(data[i]));
        }
        return items;
    }

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectCaseId: this.projectCaseId,
            projectTypeId: this.projectTypeId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectCase_ProjectType {
        return ProjectCase_ProjectType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectCase_TherapeuticArea
//
//

export class ProjectCase_TherapeuticArea {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectCase_TherapeuticArea';
    public id: number;
    public projectCaseId: number;
    public therapeuticAreaId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectCaseId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectCaseId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectCase_TherapeuticArea {
        const item = new ProjectCase_TherapeuticArea();
        item.id = data['id'];
        item.projectCaseId = data['projectCaseId'];
        item.therapeuticAreaId = data['therapeuticAreaId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): ProjectCase_TherapeuticArea {
        const item: ProjectCase_TherapeuticArea = new ProjectCase_TherapeuticArea();
        item.id = parseFloat(data['id']);
        item.projectCaseId = parseFloat(data['projectCaseId']);
        item.therapeuticAreaId = parseFloat(data['therapeuticAreaId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): ProjectCase_TherapeuticArea[] {
        const items: ProjectCase_TherapeuticArea[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectCase_TherapeuticArea.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectCaseId: this.projectCaseId,
            therapeuticAreaId: this.therapeuticAreaId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectCase_TherapeuticArea {
        return ProjectCase_TherapeuticArea.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// DevelopmentPhase_ProjectTypeGroup
//
//

export class DevelopmentPhase_ProjectTypeGroup {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'DevelopmentPhase_ProjectTypeGroup';
    public id: number;
    public developmentPhaseId: number;
    public projectTypeGroupId: number;

    constructor() {
    }

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id', 'developmentPhaseId', 'projectTypeGroupId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'developmentPhaseId', 'projectTypeGroupId'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): DevelopmentPhase_ProjectTypeGroup {
        const item = new DevelopmentPhase_ProjectTypeGroup();
        item.id = data['id'];
        item.developmentPhaseId = data['developmentPhaseId'];
        item.projectTypeGroupId = data['projectTypeGroupId'];
        return item;
    }

    public static fromAPI(data: any): DevelopmentPhase_ProjectTypeGroup {
        const item: DevelopmentPhase_ProjectTypeGroup = new DevelopmentPhase_ProjectTypeGroup();
        item.id = parseFloat(data['id']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.projectTypeGroupId = parseFloat(data['projectTypeGroupId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): DevelopmentPhase_ProjectTypeGroup[] {
        const items: DevelopmentPhase_ProjectTypeGroup[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(DevelopmentPhase_ProjectTypeGroup.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            developmentPhaseId: this.developmentPhaseId,
            projectTypeGroupId: this.projectTypeGroupId
        };
        return item;
    }

    public clone(): DevelopmentPhase_ProjectTypeGroup {
        return DevelopmentPhase_ProjectTypeGroup.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// NotificationType
//
//

export class NotificationType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'NotificationType';
    public static readonly ID_FAVORITE_PHASE_CHANGED: number = 1;
    public static readonly ID_ALERT_MATCH: number = 2;
    public static readonly ID_FAVORITE_SEEKING_FUNDS_CHANGED: number = 3;
    public static readonly ID_FAVORITE_LEAD_INVESTOR_CHANGED: number = 4;

    //
    // CONSTANTS
    //
    public static readonly ID_PROJECT_OBSOLESCENCE_REMINDER_1: number = 5;
    public static readonly ID_PROJECT_OBSOLESCENCE_REMINDER_2: number = 6;
    public static readonly ID_PROJECT_OBSOLESCENCE_DELETE: number = 7;
    public id: number;
    public name: string;
    public description: string;

    constructor() {
    }

    //
    // FACTORIES
    //

    public static getNonTranslatableFields(): string[] {
        return ['id'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    public static getFields(): string[] {
        return ['id', 'name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): NotificationType {
        const item = new NotificationType();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): NotificationType {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['notificationTypeId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: NotificationType = new NotificationType();
        item.id = parseFloat(data['id']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): NotificationType[] {
        const items: NotificationType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(NotificationType.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description
        };
        return item;
    }

    public clone(): NotificationType {
        return NotificationType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// ProjectCase
//
//

export class ProjectCase {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'ProjectCase';
    public id: number;
    public projectId: number;
    public developmentPhaseId: number;
    public patentStateId: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public title: string;
    public subtitle: string;
    public description: string;

    //
    // ATTRIBUTES
    //
    public uniqueness: string;
    public unmetNeed: string;
    public marketSize: string;
    public competition: string;
    public developmentPhaseUpdateDate: string;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'developmentPhaseId', 'patentStateId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return ['title', 'subtitle', 'description', 'uniqueness', 'unmetNeed', 'marketSize', 'competition'];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'developmentPhaseId', 'patentStateId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'title', 'subtitle', 'description', 'uniqueness', 'unmetNeed', 'marketSize', 'competition'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): ProjectCase {
        const item = new ProjectCase();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.developmentPhaseId = data['developmentPhaseId'];
        item.patentStateId = data['patentStateId'];
        item.title = data['title'];
        item.subtitle = data['subtitle'];
        item.description = data['description'];
        item.uniqueness = data['uniqueness'];
        item.unmetNeed = data['unmetNeed'];
        item.marketSize = data['marketSize'];
        item.competition = data['competition'];
        item.developmentPhaseUpdateDate = data['developmentPhaseUpdateDate'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): ProjectCase {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['projectCaseId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: ProjectCase = new ProjectCase();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.patentStateId = parseFloat(data['patentStateId']);
        item.developmentPhaseUpdateDate = data['developmentPhaseUpdateDate'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        item.title = translationData['title'];
        item.subtitle = translationData['subtitle'];
        item.description = translationData['description'];
        item.uniqueness = translationData['uniqueness'];
        item.unmetNeed = translationData['unmetNeed'];
        item.marketSize = translationData['marketSize'];
        item.competition = translationData['competition'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): ProjectCase[] {
        const items: ProjectCase[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(ProjectCase.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            developmentPhaseId: this.developmentPhaseId,
            patentStateId: this.patentStateId,
            title: this.title,
            subtitle: this.subtitle,
            description: this.description,
            uniqueness: this.uniqueness,
            unmetNeed: this.unmetNeed,
            marketSize: this.marketSize,
            competition: this.competition,
            developmentPhaseUpdateDate: this.developmentPhaseUpdateDate,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): ProjectCase {
        return ProjectCase.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Notification
//
//

export class Notification {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Notification';
    public id: number;
    public notificationTypeId: number;
    public userId: number;
    public isEmailed: boolean;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public isSeen: boolean;
    public isOpen: boolean;
    public link: string;

    //
    // ATTRIBUTES
    //
    public linkTitle: string;
    public title: string;
    public message: string;
    public icon: string;
    public payload: string;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'notificationTypeId', 'userId', 'isEmailed', 'isSeen', 'isOpen', 'link', 'linkTitle', 'title', 'message', 'icon', 'payload', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'notificationTypeId', 'userId', 'isEmailed', 'isSeen', 'isOpen', 'link', 'linkTitle', 'title', 'message', 'icon', 'payload', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Notification {
        const item = new Notification();
        item.id = data['id'];
        item.notificationTypeId = data['notificationTypeId'];
        item.userId = data['userId'];
        item.isEmailed = data['isEmailed'];
        item.isSeen = data['isSeen'];
        item.isOpen = data['isOpen'];
        item.link = data['link'];
        item.linkTitle = data['linkTitle'];
        item.title = data['title'];
        item.message = data['message'];
        item.icon = data['icon'];
        item.payload = data['payload'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Notification {
        const item: Notification = new Notification();
        item.id = parseFloat(data['id']);
        item.notificationTypeId = parseFloat(data['notificationTypeId']);
        item.userId = parseFloat(data['userId']);
        item.isEmailed = data['isEmailed'];
        item.isSeen = data['isSeen'];
        item.isOpen = data['isOpen'];
        item.link = data['link'];
        item.linkTitle = data['linkTitle'];
        item.title = data['title'];
        item.message = data['message'];
        item.icon = data['icon'];
        item.payload = data['payload'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Notification[] {
        const items: Notification[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Notification.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            notificationTypeId: this.notificationTypeId,
            userId: this.userId,
            isEmailed: this.isEmailed,
            isSeen: this.isSeen,
            isOpen: this.isOpen,
            link: this.link,
            linkTitle: this.linkTitle,
            title: this.title,
            message: this.message,
            icon: this.icon,
            payload: this.payload,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Notification {
        return Notification.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// User_AcademicInstitution
//
//

export class User_AcademicInstitution {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'User_AcademicInstitution';
    public id: number;
    public userId: number;
    public academicInstitutionId: number;
    public isDispatcher: boolean;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // ATTRIBUTES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'userId', 'academicInstitutionId', 'isDispatcher', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'userId', 'academicInstitutionId', 'isDispatcher', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): User_AcademicInstitution {
        const item = new User_AcademicInstitution();
        item.id = data['id'];
        item.userId = data['userId'];
        item.academicInstitutionId = data['academicInstitutionId'];
        item.isDispatcher = data['isDispatcher'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): User_AcademicInstitution {
        const item: User_AcademicInstitution = new User_AcademicInstitution();
        item.id = parseFloat(data['id']);
        item.userId = parseFloat(data['userId']);
        item.academicInstitutionId = parseFloat(data['academicInstitutionId']);
        item.isDispatcher = data['isDispatcher'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): User_AcademicInstitution[] {
        const items: User_AcademicInstitution[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(User_AcademicInstitution.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            userId: this.userId,
            academicInstitutionId: this.academicInstitutionId,
            isDispatcher: this.isDispatcher,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): User_AcademicInstitution {
        return User_AcademicInstitution.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// MetricsRecord
//
//

export class MetricsRecord {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'MetricsRecord';
    public static readonly TAG_PROJECT_SEEN: string = 'seen';
    public static readonly TAG_FAVORITE_ADDED: string = 'favorite-added';
    public static readonly TAG_FAVORITE_REMOVED: string = 'favorite-removed';
    public id: number;

    //
    // CONSTANTS
    //
    public projectId: number;
    public tag: string;
    public payload: string;

    //
    // FACTORIES
    //
    public createDate: string;
    public createrId: number;
    public updateDate: string;

    //
    // ATTRIBUTES
    //
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'projectId', 'tag', 'payload', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'projectId', 'tag', 'payload', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): MetricsRecord {
        const item = new MetricsRecord();
        item.id = data['id'];
        item.projectId = data['projectId'];
        item.tag = data['tag'];
        item.payload = data['payload'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): MetricsRecord {
        const item: MetricsRecord = new MetricsRecord();
        item.id = parseFloat(data['id']);
        item.projectId = parseFloat(data['projectId']);
        item.tag = data['tag'];
        item.payload = data['payload'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): MetricsRecord[] {
        const items: MetricsRecord[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(MetricsRecord.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            projectId: this.projectId,
            tag: this.tag,
            payload: this.payload,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): MetricsRecord {
        return MetricsRecord.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Company
//
//

export class Company extends ModelSerialization {

    public static readonly tableName = 'Company';
    public id: number;
    public companyCompanyCategoryId: number;
    public isDisplayedOnHomepage: number;
    public name: string;
    public logo: string;
    public description: string;
    public website: string;
    public noWebsite: number;
    public noLogo: number;
    public investmentRangeFrom: number;
    public investmentRangeTo: number;
    public investmentComment: string;
    public isInterstedInPartnerships: number;
    public isInterstedInLicenses: number;
    public street1: string;
    public street2: string;
    public city: string;
    public country: string;
    public phone: string;
    public email: string;
    public noContactInfos: number;
    public createDate: string;
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;
    public isInvestingInPrivate: number;
    public isInvestingInPublic: number;
    public isCompleted: number;

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyCompanyCategoryId', 'isDisplayedOnHomepage', 'name', 'logo', 'description', 'website', 'investmentRangeFrom', 'investmentRangeTo', 'investmentComment', 'isInterstedInPartnerships', 'isInterstedInLicenses', 'street1', 'street2', 'city', 'country', 'phone', 'email', 'noContactInfos', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'noWebsite', 'noLogo', 'isInvestingInPrivate', 'isInvestingInPublic', 'isCompleted'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'companyCompanyCategoryId', 'isDisplayedOnHomepage', 'name', 'logo', 'description', 'website', 'investmentRangeFrom', 'investmentRangeTo', 'investmentComment', 'isInterstedInPartnerships', 'isInterstedInLicenses', 'street1', 'street2', 'city', 'country', 'phone', 'email', 'noContactInfos', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId', 'noWebsite', 'noLogo', 'isInvestingInPrivate', 'isInvestingInPublic', 'isCompleted'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static deserialize(data: any): Company {
        return super.deserialize(Company, data);
    }

    public static fromAPI(data: any): Company {
        const item = new Company();
        item.id = parseFloat(data['id']);
        item.companyCompanyCategoryId = parseFloat(data['companyCompanyCategoryId']);
        item.isDisplayedOnHomepage = parseFloat(data['isDisplayedOnHomepage']);
        item.name = data['name'];
        item.logo = data['logo'];
        item.noLogo = parseFloat(data['noLogo']);
        item.noWebsite = parseFloat(data['noWebsite']);
        item.description = data['description'];
        item.website = data['website'];
        item.investmentRangeFrom = parseFloat(data['investmentRangeFrom']);
        item.investmentRangeTo = parseFloat(data['investmentRangeTo']);
        item.investmentComment = data['investmentComment'];
        item.isInterstedInPartnerships = parseFloat(data['isInterstedInPartnerships']);
        item.isInterstedInLicenses = parseFloat(data['isInterstedInLicenses']);
        item.street1 = data['street1'];
        item.street2 = data['street2'];
        item.city = data['city'];
        item.country = data['country'];
        item.phone = data['phone'];
        item.email = data['email'];
        item.noContactInfos = parseFloat(data['noContactInfos']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        item.isInvestingInPrivate = parseFloat(data['isInvestingInPrivate']);
        item.isInvestingInPublic = parseFloat(data['isInvestingInPublic']);
        item.isCompleted = parseFloat(data['isCompleted']);
        return item;
    }

    public static fromAPIList(data: any[]): Company[] {
        const items: Company[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Company.fromAPI(data[i]));
        }
        return items;
    }

    public clone(): Company {
        return Company.deserialize(JSON.parse(JSON.stringify(this)));
    }
}

//
//
// Company_TherapeuticArea
//
//

export class Company_TherapeuticArea {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Company_TherapeuticArea';
    public id: number;
    public companyId: number;
    public therapeuticAreaId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'companyId', 'therapeuticAreaId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Company_TherapeuticArea {
        const item = new Company_TherapeuticArea();
        item.id = data['id'];
        item.companyId = data['companyId'];
        item.therapeuticAreaId = data['therapeuticAreaId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Company_TherapeuticArea {
        const item: Company_TherapeuticArea = new Company_TherapeuticArea();
        item.id = parseFloat(data['id']);
        item.companyId = parseFloat(data['companyId']);
        item.therapeuticAreaId = parseFloat(data['therapeuticAreaId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Company_TherapeuticArea[] {
        const items: Company_TherapeuticArea[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Company_TherapeuticArea.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            companyId: this.companyId,
            therapeuticAreaId: this.therapeuticAreaId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Company_TherapeuticArea {
        return Company_TherapeuticArea.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// Company_DevelopmentPhase
//
//

export class Company_DevelopmentPhase {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Company_DevelopmentPhase';
    public id: number;
    public companyId: number;
    public developmentPhaseId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'companyId', 'developmentPhaseId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Company_DevelopmentPhase {
        const item = new Company_DevelopmentPhase();
        item.id = data['id'];
        item.companyId = data['companyId'];
        item.developmentPhaseId = data['developmentPhaseId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Company_DevelopmentPhase {
        const item: Company_DevelopmentPhase = new Company_DevelopmentPhase();
        item.id = parseFloat(data['id']);
        item.companyId = parseFloat(data['companyId']);
        item.developmentPhaseId = parseFloat(data['developmentPhaseId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Company_DevelopmentPhase[] {
        const items: Company_DevelopmentPhase[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Company_DevelopmentPhase.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            companyId: this.companyId,
            developmentPhaseId: this.developmentPhaseId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Company_DevelopmentPhase {
        return Company_DevelopmentPhase.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

export class Company_ProjectType {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'Company_ProjectType';
    public id: number;
    public companyId: number;
    public projectTypeId: number;
    public createDate: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;

    //
    // ATTRIBUTES
    //
    public deleteDate: string;
    public deleterId: number;

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'companyId', 'projectTypeId', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): Company_ProjectType {
        const item = new Company_ProjectType();
        item.id = data['id'];
        item.companyId = data['companyId'];
        item.projectTypeId = data['projectTypeId'];
        item.createDate = data['createDate'];
        item.createrId = data['createrId'];
        item.updateDate = data['updateDate'];
        item.updaterId = data['updaterId'];
        item.deleteDate = data['deleteDate'];
        item.deleterId = data['deleterId'];
        return item;
    }

    public static fromAPI(data: any): Company_ProjectType {
        const item: Company_ProjectType = new Company_ProjectType();
        item.id = parseFloat(data['id']);
        item.companyId = parseFloat(data['companyId']);
        item.projectTypeId = parseFloat(data['projectTypeId']);
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[]): Company_ProjectType[] {
        const items: Company_ProjectType[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(Company_ProjectType.fromAPI(data[i]));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            companyId: this.companyId,
            projectTypeId: this.projectTypeId,
            createDate: this.createDate,
            createrId: this.createrId,
            updateDate: this.updateDate,
            updaterId: this.updaterId,
            deleteDate: this.deleteDate,
            deleterId: this.deleterId
        };
        return item;
    }

    public clone(): Company_ProjectType {
        return Company_ProjectType.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// CompanyCompanyCategory
//
//

export class CompanyCompanyCategory {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'CompanyCompanyCategory';
    public id: number;
    public name: string;
    public description: string;
    public ordinal: number;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    //

    constructor() {
    }

    public static getNonTranslatableFields(): string[] {
        return ['id', 'ordinal'];
    }

    public static getTranslatableFields(): string[] {
        return ['name', 'description'];
    }

    //
    // ATTRIBUTES
    //

    public static getFields(): string[] {
        return ['id', 'name', 'description', 'ordinal'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    public static fromStorageRow(data: any): CompanyCompanyCategory {
        const item = new CompanyCompanyCategory();
        item.id = data['id'];
        item.name = data['name'];
        item.description = data['description'];
        item.ordinal = data['ordinal'];
        return item;
    }

    public static fromAPI(data: any, translationsData: any[]): CompanyCompanyCategory {
        let translationData = {};
        for (let i = 0; i < translationsData.length; i++) {
            if (translationsData[i]['companyCompanyCategoryId'] === data['id']) {
                translationData = translationsData[i];
                i = translationsData.length;
            }
        }
        const item: CompanyCompanyCategory = new CompanyCompanyCategory();
        item.id = parseFloat(data['id']);
        item.ordinal = parseFloat(data['ordinal']);
        item.name = translationData['name'];
        item.description = translationData['description'];
        return item;
    }

    //
    // CONSTRUCTOR
    //

    public static fromAPIList(data: any[], translationsData: any[]): CompanyCompanyCategory[] {
        const items: CompanyCompanyCategory[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(CompanyCompanyCategory.fromAPI(data[i], translationsData));
        }
        return items;
    }

    //
    // METHODS
    //

    public toJson(): Object {
        const item: any = {
            id: this.id,
            name: this.name,
            description: this.description,
            ordinal: this.ordinal
        };
        return item;
    }

    public clone(): CompanyCompanyCategory {
        return CompanyCompanyCategory.fromStorageRow(JSON.parse(JSON.stringify(this.toJson())));
    }
}

//
//
// CompanyContact
//
//

export class CompanyContact extends ModelSerialization {

    //
    // DESCRIPTORS
    //

    public static readonly tableName = 'CompanyContact';
    public id: number;
    public companyId: number;
    public firstName: string;
    public lastName: string;

    //
    // CONSTANTS
    //

    //
    // FACTORIES
    public email: string;
    public phone: string;
    public createDate: string;

    //
    // ATTRIBUTES
    //
    public createrId: number;
    public updateDate: string;
    public updaterId: number;
    public deleteDate: string;
    public deleterId: number;

    public static getNonTranslatableFields(): string[] {
        return ['id', 'companyId', 'firstName', 'lastName', 'email', 'phone', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getTranslatableFields(): string[] {
        return [];
    }

    public static getFields(): string[] {
        return ['id', 'companyId', 'firstName', 'lastName', 'email', 'phone', 'createDate', 'createrId', 'updateDate', 'updaterId', 'deleteDate', 'deleterId'];
    }

    public static getKeyFields(): string[] {
        return ['id'];
    }

    //
    public static deserialize(data: any): CompanyContact {
        return super.deserialize(CompanyContact, data);
    }

    public static fromAPI(data: any): CompanyContact {
        const item = new CompanyContact();
        item.id = parseFloat(data['id']);
        item.companyId = parseFloat(data['companyId']);
        item.firstName = data['firstName'];
        item.lastName = data['lastName'];
        item.email = data['email'];
        item.phone = data['phone'];
        item.createDate = data['createDate'];
        item.createrId = parseFloat(data['createrId']);
        item.updateDate = data['updateDate'];
        item.updaterId = parseFloat(data['updaterId']);
        item.deleteDate = data['deleteDate'];
        item.deleterId = parseFloat(data['deleterId']);
        return item;
    }

    public static fromAPIList(data: any[]): CompanyContact[] {
        const items: CompanyContact[] = [];
        for (let i = 0; i < data.length; i++) {
            items.push(CompanyContact.fromAPI(data[i]));
        }
        return items;
    }

    public clone(): CompanyContact {
        return CompanyContact.deserialize(JSON.parse(JSON.stringify(this)));
    }
}
