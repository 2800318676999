<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Investors' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isAdmin"></error-401>
    <investors-list (onCompanyClicked)="onCompanyClicked($event)"
                    (onDeleteRowClicked)="onDeleteRowClicked($event)"
                    (onRowClicked)="onRowClicked($event)"
                    *ngIf="currentUser.user.isAdmin"
                    [hideContactableColumn]="true"
                    [hideValidationStateColumn]="true"
                    [investorProfiles]="investorProfiles"
                    [noDataMessage]="'No_investors_yet'"
                    [preserveFilters]="true"
                    [title]="">
    </investors-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
