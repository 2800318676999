import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../../_conf';
import { Utils } from '../../../providers/utils';
import { CurrentUser } from '../../../providers/current-user';
import { LangsProvider } from '../../../providers/langs-provider';
import { ModelsStorage } from '../../../providers/models-storage';
import { SyncServiceProvider } from '../../../providers/sync-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'auth-dialog',
    providers: [],
    styleUrls: ['./auth-dialog.scss'],
    templateUrl: './auth-dialog.html'/*,
    animations: [translateFadeBeforeAfterAnimation]*/
})
export class AuthDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public FLOW_DEFAULT: string = 'default';
    public FLOW_LOGIN: string = 'login';
    public FLOW_REGISTER: string = 'register';
    public FLOW_UNAUTHORIZED: string = 'unauthorized';
    public FLOW_RESET_PASSWORD: string = 'resetPassword';
    public PASSWORD_MIN_LENGTH: number = 6;

    public SLIDE_TRANSITION_DURATION: number = 300; // ms

    public image: string = this.conf.assetsUrl + 'img/sbv-logo.svg';
    public title: string = '';
    public nextUrl: string = null;
    public defaultNextUrl: string = null;
    public isRegistrationDisabled: boolean = false;

    @ViewChild('emailControl', {static: false}) public emailControl: ElementRef;
    public emailFormControl: FormControl = new FormControl('', [
        Validators.email
    ]);

    @ViewChild('passwordControl', {static: false}) public passwordControl: ElementRef;
    public passwordWrongError: boolean = false;
    public passwordFormControl: FormControl = new FormControl('', [
        Validators.required,
        // doc : https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html#building-a-custom-validator
        (control: any) => {
            return !this.passwordWrongError ? null : {passwordWrong: {valid: false}};
        }
    ]);
    @ViewChild('resetPasswordControl', {static: false}) public resetPasswordControl: ElementRef;
    public resetPasswordFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]);

    @ViewChild('resetPassword2Control', {static: false}) public resetPassword2Control: ElementRef;
    public resetPassword2FormControl: FormControl = new FormControl('', [
        // Validators.required,
        (control: any) => {
            return null;
            /*
            return this.resetPasswordFormControl &&
                this.resetPassword2FormControl &&
                this.resetPasswordFormControl.value == this.resetPassword2FormControl.value ?
                    null : { passwordsDontMatch: { valid: false } };
            */
        }
    ]);

    @ViewChild('registerPasswordControl', {static: false}) public registerPasswordControl: ElementRef;
    public registerPasswordFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]);
    @ViewChild('registerFirstNameControl', {static: false}) public registerFirstNameControl: ElementRef;
    public registerFirstNameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);
    @ViewChild('registerLastNameControl', {static: false}) public registerLastNameControl: ElementRef;
    public registerLastNameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    @ViewChild('applicationMessageControl', {static: false}) public applicationMessageControl: ElementRef;
    public applicationMessageFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    public isLoading: boolean = true;
    public isNewUser: boolean = false;
    public isIHaveAnAccountText: boolean = true;
    public emailStepState: string = 'before';
    public passwordStepState: string = 'after';
    public unauthorizedStepState: string = 'after';
    public forgotPasswordState: string = 'after';
    public resetPasswordState: string = 'after';
    public registerStepState: string = 'after';

    public rememberMe: boolean = true;
    public resetPasswordToken: string = null;

    public showLoginPassword: boolean = false;
    public showResetPasswordPassword: boolean = false;

    @ViewChild('dialogContainer', {static: false}) public dialogContainer: any;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _storage: ModelsStorage,
        private _langs: LangsProvider,
        private _sync: SyncServiceProvider,
        private _router: Router,
        private _route: ActivatedRoute,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _changeDetector: ChangeDetectorRef,
        private _dialogRef: MatDialogRef<AuthDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data.title) {
            this.title = _data.title;
        }
        if (_data.image) {
            this.image = _data.image;
        }
        if (_data.nextUrl) {
            this.nextUrl = _data.nextUrl;
        }
        if (_data.defaultNextUrl) {
            this.defaultNextUrl = _data.defaultNextUrl;
        }
        if (_data.isRegistrationDisabled) {
            this.isRegistrationDisabled = true;
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.retrieveParams();

        if (this.currentUser.isLoggedIn()) {
            this.startLoggedFlow()
                .then(() => {
                    this.isLoading = false;
                    this._changeDetector.detectChanges();
                })
                .catch((error) => this.onError(error));
        } else {
            if (this.resetPasswordToken) {
                this.checkResetPassword()
                    .then((result: any) => {
                        this.isLoading = false;
                        if (result) {
                            this.selectFlow(this.FLOW_RESET_PASSWORD);
                        } else {
                            this.selectFlow(this.FLOW_DEFAULT);
                        }
                    })
                    .catch((error: any) => this.onError(error));
            } else {
                this.isLoading = false;
                this.selectFlow(this.FLOW_DEFAULT);
            }
            this._changeDetector.detectChanges();
        }

        this.resetPasswordFormControl.valueChanges.subscribe(() => {
            this.resetPassword2FormControl.updateValueAndValidity();
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onCheckEmailClicked(event: any): void {
        // on blur systématiquement tous les events issu d'un textbox
        // car si l'utilisateur déclenche cet event via la toucher enter
        // le textbox reste focus même s'il est plus affiché
        // et l'utilisateur risque de taper au mauvais endroit ...
        event.target.blur();

        if (this.isLoading || this.emailFormControl.hasError('required') || this.emailFormControl.hasError('email')) {
            return;
        }

        this.isLoading = true;
        this.checkEmail()
            .then((flow: string) => {
                this.selectFlow(flow);
                this.isLoading = false;
            })
            .catch((error) => this.onError(error));
    }

    public onCheckPasswordClicked(event: any): void {
        // on blur systématiquement tous les events issu d'un textbox
        // car si l'utilisateur déclenche cet event via la toucher enter
        // le textbox reste focus même s'il est plus affiché
        // et l'utilisateur risque de taper au mauvais endroit ...
        event.target.blur();

        if (this.isLoading
            || this.passwordFormControl.hasError('required')
            || this.passwordFormControl.hasError('passwordWrong')) {
            return;
        }

        this.isLoading = true;
        this.checkPassword()
            .then((result) => {
                this._translater.use(this._langs.getBestLangCode());
                this.startLoggedFlow()
                    .then(() => {
                        this.isLoading = false;
                        this._changeDetector.detectChanges();
                    })
                    .catch((error) => this.onError(error));
            })
            .catch((error) => {
                if (error.code === 1) {
                    this.isLoading = false;
                    this.passwordWrongError = true;
                    this.passwordFormControl.updateValueAndValidity();
                } else {
                    this.onError(error);
                }
            });
    }

    public onPasswordKeyPressed(): void {
        this.passwordWrongError = false;
    }

    public onNotYouClicked(): void {
        this.passwordFormControl.setValue('');
        this.selectFlow(this.FLOW_DEFAULT);
    }

    public onForgotPasswordClicked(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        this.sendPasswordResetEmail()
            .then((result) => {
                this.passwordStepState = 'before';
                this.forgotPasswordState = 'active';
                this.isLoading = false;
            })
            .catch((error) => this.onError(error));
    }

    public onResetPasswordClicked(event: any): void {
        // on blur systématiquement tous les events issu d'un textbox
        // car si l'utilisateur déclenche cet event via la toucher enter
        // le textbox reste focus même s'il est plus affiché
        // et l'utilisateur risque de taper au mauvais endroit ...
        event.target.blur();

        if (this.isLoading || this.resetPasswordFormControl.invalid || this.resetPassword2FormControl.invalid) {
            return;
        }

        this.isLoading = true;
        this.resetPassword()
            .then(() => {
                // on simule un login droit derrière avec les nouveaux creditentials
                // pour connecter le type et le rediriger là où il devrait
                this.rememberMe = false;
                this.passwordFormControl.setValue(this.resetPasswordFormControl.value);
                this.checkPassword()
                    .then(() => {
                        this.isLoading = false;
                        this.redirectUser();
                    })
                    .catch((error) => this.onError(error));
            })
            .catch((error) => this.onError(error));
    }

    public onCheckRegisterClicked(event: any): void {
        // on blur systématiquement tous les events issu d'un textbox
        // car si l'utilisateur déclenche cet event via la toucher enter
        // le textbox reste focus même s'il est plus affiché
        // et l'utilisateur risque de taper au mauvais endroit ...
        event.target.blur();

        if (this.isLoading
            || this.registerPasswordFormControl.hasError('required')
            || this.registerPasswordFormControl.hasError('minlength')
            || this.registerFirstNameFormControl.hasError('required')
            || this.registerLastNameFormControl.hasError('required')) {
            return;
        }

        this.isLoading = true;
        this.register()
            .then(() => {
                this.startLoggedFlow()
                    .then(() => {
                        this.isLoading = false;
                        this._changeDetector.detectChanges();
                    })
                    .catch((error) => this.onError(error));
            })
            .catch((error) => this.onError(error));
    }

    public onUseAccountClicked(): void {
        this.isIHaveAnAccountText = true;
    }

    public onNoAccountClicked(): void {
        this.isIHaveAnAccountText = false;
    }

    public onCompleteProfileClicked(): void {
        this._dialogRef.afterClosed().subscribe(() => this._router.navigate(['users', this.currentUser.user.id, 'edit']));
        this._dialogRef.close();
    }

    public onBackHomeClicked(): void {
        // pour une sombre raison, le routing standard change l'url dans le navigateur
        // mais n'initie pas de rechargement de la page en fonction de l'url ...
        // du coup recharge tout de façon abrupte ...
        // this._router.navigate(['/']);
        window.location.href = '';
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private retrieveParams(): void {
        this._route.queryParamMap.subscribe((params) => {
            if (params.has('authEmail') && params.has('authToken')) {
                this.emailFormControl.setValue(params.get('authEmail'));
                this.resetPasswordToken = params.get('authToken');
            } else if (params.has('nextUrl')) {
                this.nextUrl = params.get('nextUrl');
            }
            // pour éviter l'erreur décrite ici : https://github.com/angular/angular/issues/6005
            // on utilise ce workaround : https://stackoverflow.com/questions/34827334/triggering-angular2-change-detection-manually
            this._changeDetector.detectChanges();
        });
    }

    private onError(error: any): void {
        this.isLoading = false;
        console.log(error);
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }

    private checkEmail(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.currentUser.checkEmail(this.emailFormControl.value)
                .then((result) => {
                    resolve(result.flow);
                })
                .catch((error) => reject(error));
        });
    }

    private checkResetPassword(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.currentUser.checkResetPassword(this.emailFormControl.value)
                .then((result: boolean) => {
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    }

    private checkPassword(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.currentUser.login(this.emailFormControl.value, this.passwordFormControl.value, this.rememberMe)
                .then(() => {
                    this._sync.sync(true, 0)
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    private sendPasswordResetEmail(): Promise<any> {
        const resetPasswordUrl: string = window.location.origin + this.conf.apiRootUrl + 'auth';

        return this.currentUser.sendPasswordResetEmail(this.emailFormControl.value, resetPasswordUrl);
    }

    private resetPassword(): Promise<any> {
        return this.currentUser.resetPassword(this.emailFormControl.value, this.resetPasswordFormControl.value, this.resetPasswordToken);
    }

    private register(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.currentUser.register(
                this.emailFormControl.value,
                this.registerPasswordFormControl.value,
                this.rememberMe,
                this._langs.getBestLangId(),
                this.registerFirstNameFormControl.value,
                this.registerLastNameFormControl.value
            )
                .then(() => {
                    this._sync.sync()
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    private startLoggedFlow(): Promise<void> {
        return new Promise((resolve, reject) => {
            resolve();
            this.redirectUser();
        });
    }

    private selectFlow(flow: string): void {
        if (flow === this.FLOW_DEFAULT) {
            this.emailStepState = 'active';
            this.passwordStepState = 'after';
            this.forgotPasswordState = 'after';
            this.unauthorizedStepState = 'after';
            this.resetPasswordState = 'after';
            this.registerStepState = 'after';
            setTimeout(() => this.emailControl.nativeElement.focus(), this.SLIDE_TRANSITION_DURATION);
        } else if (flow === this.FLOW_LOGIN) {
            this.emailStepState = 'before';
            this.passwordStepState = 'active';
            setTimeout(() => this.passwordControl.nativeElement.focus(), this.SLIDE_TRANSITION_DURATION);
        } else if (flow === this.FLOW_RESET_PASSWORD) {
            this.emailStepState = 'before';
            this.resetPasswordState = 'active';
            setTimeout(() => this.resetPasswordControl.nativeElement.focus(), this.SLIDE_TRANSITION_DURATION);
        } else if (flow === this.FLOW_UNAUTHORIZED) {
            this.emailStepState = 'before';
            this.passwordStepState = 'before';
            this.unauthorizedStepState = 'active';
        } else if (flow === this.FLOW_REGISTER) {
            this.isNewUser = true;
            this.emailStepState = 'before';
            this.registerStepState = 'active';
            setTimeout(() => this.registerFirstNameControl.nativeElement.focus(), this.SLIDE_TRANSITION_DURATION);
        } else {
            this.onError({error: 'Unknown auth flow[' + flow + ']'});
        }

        // hack pour corriger un problème d'affichage lié à l'animation d'ouverture de la popup + les animations de slide
        setInterval(() => {
            this.dialogContainer.nativeElement.scrollTop = 0;
        }, 1);
    }

    private redirectUser(): void {
        this._dialogRef.afterClosed().subscribe(() => {
            if (this.nextUrl) {
                this._router.navigateByUrl(this.nextUrl);
            } else if (this.defaultNextUrl) {
                this._router.navigateByUrl(this.defaultNextUrl);
            } else {
                this._router.navigate(['']);
            }
        });
        this._dialogRef.close();
    }
}
