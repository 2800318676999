import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InvestorDataRow } from '../../providers/investors-provider';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { InvestorProfile, InvestorValidationState } from '../../../_common/providers/models';

@Component({
    selector: 'investors-pending-validation-page',
    providers: [],
    styleUrls: ['./pending-validation.scss'],
    templateUrl: './pending-validation.html'
})
export class InvestorsPendingValidationPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public investorProfiles: InvestorProfile[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._title.setTitle(this._translater.instant('Investors_pending_validation') + ' | ' + this.conf.platformName);
        this.loadData()
            .then(() => {
                this.isLoading = false;
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRowClicked(row: InvestorDataRow): void {
        this._router.navigate(['users', row.profile.userId, 'edit']);
    }

    public onCompanyClicked(row: InvestorDataRow): void {
        this._router.navigate(['investors', 'companies', row.company.id], {
            queryParams: {
                'backUrl': this._router.url,
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(InvestorProfile)
                .where('investorValidationStateId').equals(InvestorValidationState.ID_PENDING)
                .get()
                .then((data: InvestorProfile[]) => {
                    this.investorProfiles = data;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
