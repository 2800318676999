import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { IProjectCaseSearchResultRow, ProjectsProvider } from '../../providers/projects-provider';
import { ListBaseComponent } from '../../../_common/components/list-base/list-base';
import { Company } from '../../../_common/providers/models';

@Component({
    selector: 'projects-projects-page',
    providers: [],
    styleUrls: ['./projects.scss'],
    templateUrl: './projects.html'
})
export class ProjectsProjectsPageComponent extends ListBaseComponent implements AfterViewInit {

    public company: Company = null;

    constructor(
        conf: Conf,
        currentUser: CurrentUser,
        navigation: AcventuresNavigationProvider,
        _title: Title,
        _router: Router,
        _dialoger: MatDialog,
        _utils: Utils,
        _toaster: MatSnackBar,
        _route: ActivatedRoute,
        _changeDetector: ChangeDetectorRef,
        _projectsProvider: ProjectsProvider,
        _translater: TranslateService,
        _storage: ModelsStorage
    ) {
        super(conf, currentUser, navigation, _title, _router, _dialoger, _utils,
            _toaster, _route, _changeDetector, _projectsProvider, _translater, _storage);
    }

    public async ngAfterViewInit() {
        try {
            await super.loadData();
            if (this.investorProfile) {
                this.company = await this._storage.select(Company).where('id').equals(this.investorProfile.companyId).get(1);
            }
            this._title.setTitle(`${this._translater.instant('Projects')} | ${this.conf.platformName}`);
            this.isLoading = false;
            super.ngAfterViewInit();
        } catch (e) {
            this.onError(e);
        }

    }

    public onRowClicked(row: IProjectCaseSearchResultRow): void {
        this._router.navigate(['academic-ventures', row.project.id], {
            queryParams: {
                'backUrl': this._router.url,
                'backTitle': this._translater.instant('Search_results')
            }
        });
    }

}
