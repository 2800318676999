// TODO work in progres ...
import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
    transition('* <=> *', [
        /* order */
        // * 1 */ query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%', opacity: '0' }), { optional: true }),
        /* 2 */ group([
            query('.routerTransitionClassEnter', [
                    style({transform: 'translateX(100%)', opacity: '1'}),
                    animate('1.3s ease-in-out', style({transform: 'translateX(0%)'}))
                ],
                {optional: true}
            ),
            query('.routerTransitionClassLeave', [
                    style({transform: 'translateX(0%)'}),
                    animate('1.3s ease-in-out', style({transform: 'translateX(-100%)'}))
                ],
                {optional: true}
            )
        ])
    ])
]);
