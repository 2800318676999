import { Utils } from '../../_common/providers/utils';
import { User } from '../../_common/providers/models';

export class UserRights {

    //
    //
    // ATTRIBUTES
    //
    //

    private _utils: Utils;
    private _params: UserRightsParams = null;
    private _see: boolean = false;
    private _edit: boolean = false;
    private _delete: boolean = false;
    private _seeEditForm: boolean = false;
    private _addAdmin: boolean = false;
    private _addGuest: boolean = false;
    private _addLocalExpert: boolean = false;
    private _changePassword: boolean = false;

    constructor(
        utils: Utils
    ) {
        this._utils = utils;
    }

    public get SEE() {
        return this._see;
    }

    public get EDIT() {
        return this._edit;
    }

    public get DELETE() {
        return this._delete;
    }

    public get SEE_EDIT_FORM() {
        return this._seeEditForm;
    }

    public get ADD_ADMIN() {
        return this._addAdmin;
    }

    public get ADD_GUEST() {
        return this._addGuest;
    }

    public get ADD_LOCAL_EXPERT() {
        return this._addLocalExpert;
    }

    public get CHANGE_PASSWORD() {
        return this._changePassword;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public evaluate(params: UserRightsParams): void {
        this._params = params;

        if (params.user.isAdmin) {
            this.evaluateAdmin();
        } else {
            this.evaluateOther();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private evaluateAdmin(): void {
        this._see = true;
        this._seeEditForm = true;
        this._addAdmin = true;
        this._addGuest = true;
        this._addLocalExpert = true;

        if (this._params.subjectUser && this._params.subjectUser.id === this._params.user.id) {
            this._edit = true;
            this._delete = false;
            this._changePassword = true;
        } else {
            this._edit = false;
            this._delete = true;
            this._changePassword = false;
        }
    }

    private evaluateOther(): void {
        if (this._params.subjectUser && this._params.subjectUser.id === this._params.user.id) {
            this._see = true;
            this._edit = true;
            this._delete = false;
            this._seeEditForm = true;
            this._changePassword = true;
        } else {
            this._see = false;
            this._edit = false;
            this._delete = false;
            this._seeEditForm = false;
            this._changePassword = false;
        }
        this._addAdmin = false;
        this._addGuest = false;
        this._addLocalExpert = false;
    }
}

export interface UserRightsParams {
    user: User;
    subjectUser?: User;
}
