import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { ProjectSearchResultRow, ProjectsProvider } from '../../providers/projects-provider';
import {
    AcademicInstitution,
    Project,
    ProjectValidationState,
    User_AcademicInstitution
} from '../../../_common/providers/models';

@Component({
    selector: 'projects-pending-validation-page',
    providers: [],
    styleUrls: ['./pending-validation.scss'],
    templateUrl: './pending-validation.html'
})
export class ProjectsPendingValidationPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public projects: Project[] = [];
    public institutions: AcademicInstitution[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this.loadData()
            .then(() => {
                this._title.setTitle(this._translater.instant('Projects_pending_validation') + ' | ' + this.conf.platformName);
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRowClicked(row: ProjectSearchResultRow): void {
        // this._router.navigate(['academic-ventures', row.project.id]);
        this._router.navigate(['academic-ventures', row.project.id, 'edit'], {
            queryParams: {
                'backUrl': this._router.url,
                'backTitle': this._translater.instant('Projects_pending_validation')
            }
        });
    }

    public onDeleteClicked(row: ProjectSearchResultRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_project'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this.deleteProject(row.project)
                    .then(() => {
                        this.projects = this.projects.slice();
                        this._toaster.open(this._translater.instant('Project_deleted'), '', {duration: 5000});
                    })
                    .catch((error: any) => {
                        row.isLoading = false;
                        this.onError(error);
                    });
            }
        });
    }

    public onEditRowClicked(row: ProjectSearchResultRow): void {
        this.onRowClicked(row);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(Project)
                    .where('projectValidationStateId').equals(ProjectValidationState.ID_PENDING_VALIDATION)
                    .orderBy('name')
                    .get(),
                this._storage.select(AcademicInstitution)
                    .where('id').equals(User_AcademicInstitution, 'academicInstitutionId')
                    .and('userId', User_AcademicInstitution).equals(this.currentUser.user.id)
                    .get()
            ])
                .then((data: any[]) => {
                    this.projects = data[0];
                    this.institutions = data[1];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private deleteProject(project: Project): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._projectsProvider.deleteProject(project)
                .then(() => {
                    for (let i = 0; i < this.projects.length; i++) {
                        if (this.projects[i].id === project.id) {
                            this.projects.splice(i, 1);
                            i = this.projects.length;
                        }
                    }

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
