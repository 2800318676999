import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

// inspired from : https://stackoverflow.com/a/49532776
export class TranslatablePaginatorIntl extends MatPaginatorIntl {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public translate: TranslateService;

    public itemsPerPageLabel: string = 'Items_per_page';
    public nextPageLabel: string = 'Next_page';
    public previousPageLabel: string = 'Previous_page';
    public lastPageLabel: string = 'Last_page';
    public firstPageLabel: string = 'First_page';

    constructor() {
        super();
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public getRangeLabel: any = (page, pageSize, length) => {
        const of = this.translate ? this.translate.instant('of') : 'of';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    };

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public injectTranslateService(translate: TranslateService) {
        this.translate = translate;

        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    public translateLabels() {
        this.itemsPerPageLabel = this.translate.instant('Items_per_page');
        this.nextPageLabel = this.translate.instant('Next_page');
        this.previousPageLabel = this.translate.instant('Previous_page');
        this.lastPageLabel = this.translate.instant('Last_page');
        this.firstPageLabel = this.translate.instant('First_page');
    }

    //
    //
    // PRIVATE METHODS
    //
    //

}
