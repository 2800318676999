<div [ngClass]="{ 'with-action': actionIcon, 'without-action': !actionIcon }"
     [style.background-color]="color"
     [style.color]="textColor"
     class="container">
    <button (click)="onActionClicked()"
            *ngIf="actionIcon"
            [matTooltipPosition]="'above'"
            mat-icon-button
            matTooltip="{{ actionTooltip | translate }}">
        <mat-icon>{{ actionIcon }}</mat-icon>
    </button>
    <div class="text">{{ text }}</div>
</div>
