import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'error-401-page',
    providers: [],
    styleUrls: ['./error-401.scss'],
    templateUrl: './error-401.html'
})
export class Error401PageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
