<div *ngIf="message"
     [ngClass]="{'is-author': isAuthor}"
     class="container">
    <div *ngIf="!isAuthor"
         class="author-container">
        <button (click)="onDeleteMessageClicked()"
                *ngIf="deleteMessagePattern != DELETE_MESSAGE_PATTERNS.NONE"
                [matTooltip]="'Remove' | translate"
                color="warn"
                mat-icon-button
                matTooltipPosition="above">
            <mat-icon>close</mat-icon>
        </button>
        <users-user-sticker [avatarHideable450]="true"
                            [legend]="message.createDate | dateClosest"
                            [orientation]="'right'"
                            [project]="project"
                            [user]="author"></users-user-sticker>
    </div>
    <div class="message-wrapper">
        <div class="message-container">
            <div [innerHtml]="message.message | autoLinker" class="message"></div>
            <div [ngClass]="{ 'arrow-right': isAuthor, 'arrow-left': !isAuthor }"></div>
        </div>
    </div>
    <div *ngIf="isAuthor"
         class="author-container">
        <button (click)="onDeleteMessageClicked()"
                *ngIf="deleteMessagePattern != DELETE_MESSAGE_PATTERNS.NONE"
                [matTooltip]="'Remove' | translate"
                color="warn"
                mat-icon-button
                matTooltipPosition="above">
            <mat-icon>close</mat-icon>
        </button>
        <users-user-sticker [avatarHideable450]="true"
                            [legend]="message.createDate | dateClosest"
                            [project]="project"
                            [user]="author"></users-user-sticker>
    </div>
</div>
