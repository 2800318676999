<div *ngIf="title"
     class="title" mat-dialog-title>
    <mat-icon>info_outline</mat-icon>&nbsp;{{ title | translate }}
</div>
<mat-dialog-content>
    <div *ngIf="text"
         class="line">
        {{ text | translate }}
    </div>
    <div *ngFor="let text of texts"
         class="line">
        {{ text | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Close' | translate }}</button>
</mat-dialog-actions>
