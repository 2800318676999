<div class="container">
    <tags-tag (click)="onTagClicked(tag)"
              *ngFor="let tag of tags"
              [color]="tag.colorHex"
              [matTooltipPosition]="'above'"
              [matTooltip]="isSelected(tag) ? ('Deactivate' | translate) : ('Activate' | translate)"
              [ngClass]="{'unselected': !isSelected(tag), 'selectable': !disabled}"
              [textColor]="tag.textColorHex"
              [text]="tag.name">
    </tags-tag>
</div>
