import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { ProjectChartData, ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project } from '../../../_common/providers/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'projects-dashboard-metrics',
    providers: [],
    styleUrls: ['./dashboard-metrics.scss'],
    templateUrl: './dashboard-metrics.html'
})
export class ProjectsDashboardMetricsComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly CHART_HEIGHT: number = 400;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public isLoading: boolean = false;
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    public fromDate: string = null;
    public toDate: string = null;
    public chartData: any[] = [];
    public serie1Name: string = this._translater.instant('Details_views');
    public serie2Name: string = this._translater.instant('Unique_investors');
    public serie3Name: string = this._translater.instant('Bookmarks');
    public serie1Color: string = '#005aff';
    public serie2Color: string = '#af00ff';
    public serie3Color: string = '#ff0000';
    public customColors: any[] = [
        {name: this.serie1Name, value: this.serie1Color},
        {name: this.serie2Name, value: this.serie2Color},
        {name: this.serie3Name, value: this.serie3Color}
    ];
    public pieColorScheme: string = 'vivid';
    public viewsChartData: any[] = [];
    public investorsChartData: any[] = [];
    public bookmarksChartData: any[] = [];
    public allData: ProjectChartData = null;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _dialoger: MatDialog,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        if (project) {
            if (!this.fromDate) {
                this.fromDate = project.createDate;
            }
            if (!this.toDate) {
                this.toDate = this._utils.DateToYmdHis(new Date());
            }
            this.loadData().catch((error: any) => this.onError(error));
        } else {
            this.chartData = [];
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public filterData(): void {
        // TODO
        if (this.allData) {
            this.chartData = this.allData.chart.slice();
            this.viewsChartData = this.allData.viewsPie.slice();
            this.investorsChartData = this.allData.investorsPie.slice();
            this.bookmarksChartData = this.allData.favoritesPie.slice();
        } else {
            this.chartData = [];
            this.viewsChartData = [];
            this.investorsChartData = [];
            this.bookmarksChartData = [];
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._projectsProvider.getProjectChartData(this.project, 'week', [this.serie1Name, this.serie2Name, this.serie3Name])
            ])
                .then((data: any[]) => {
                    this.allData = data[0];
                    this.filterData();

                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
