import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../providers/current-user';
import { SyncServiceProvider } from '../../providers/sync-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'logout-page',
    providers: [],
    styleUrls: ['./logout.scss'],
    templateUrl: './logout.html'
})
export class LogoutPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _translater: TranslateService,
        private _currentUser: CurrentUser,
        private _sync: SyncServiceProvider
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._currentUser.logout()
            .then(() => {
                this._sync.restart()
                    .then(() => this._router.navigate(['']))
                    .catch((error: any) => this.onError(error));
            })
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
