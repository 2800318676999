<module-content [isLoading]="isLoading"
                [title]="'Metrics' | translate">
    <div *ngIf="!allData">{{ 'No_data_available_yet' | translate }}</div>
    <ng-container *ngIf="allData">
        <div [style.height]="CHART_HEIGHT + 'px'"
             class="chart-container">
            <ngx-charts-line-chart [customColors]="customColors"
                                   [legendPosition]="'below'"
                                   [legendTitle]=""
                                   [legend]="false"
                                   [results]="chartData"
                                   [showGridLines]="true"
                                   [view]="null"
                                   [xAxis]="true"
                                   [yAxis]="true">
            </ngx-charts-line-chart>
        </div>
        <div class="pies-container">
            <div *ngIf="chartData.length && chartData[0].series.length"
                 class="pie-wrapper">
                <div class="title-container">
                    <div [style.background-color]="serie1Color"
                         class="color">
                    </div>
                    <div class="title">{{ chartData[0].series[chartData[0].series.length - 1].value }}</div>
                    <div class="subtitle">{{ serie1Name }}</div>
                </div>
                <div class="pie-container">
                    <ngx-charts-pie-chart [explodeSlices]="false"
                                          [results]="viewsChartData"
                                          [scheme]="pieColorScheme">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div *ngIf="chartData.length && chartData[1].series.length"
                 class="pie-wrapper">
                <div class="title-container">
                    <div [style.background-color]="serie2Color"
                         class="color">
                    </div>
                    <div class="title">{{ chartData[1].series[chartData[1].series.length - 1].value }}</div>
                    <div class="subtitle">{{ serie2Name }}</div>
                </div>
                <div class="pie-container">
                    <ngx-charts-pie-chart [explodeSlices]="false"
                                          [results]="investorsChartData"
                                          [scheme]="pieColorScheme">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div *ngIf="chartData.length && chartData[2].series.length"
                 class="pie-wrapper">
                <div class="title-container">
                    <div [style.background-color]="serie3Color"
                         class="color">
                    </div>
                    <div class="title">{{ chartData[2].series[chartData[2].series.length - 1].value }}</div>
                    <div class="subtitle">{{ serie3Name }}</div>
                </div>
                <div class="pie-container">
                    <ngx-charts-pie-chart [explodeSlices]="false"
                                          [results]="bookmarksChartData"
                                          [scheme]="pieColorScheme">
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </div>
    </ng-container>
</module-content>
