<module-content>
    <!--
    <div class="line">
        <mat-checkbox [(ngModel)]="isNotForProfit"
                      color="primary"
                      (change)="_onFormChanged()">
            {{ 'Is_not_for_profit_translational_project' | translate }}
        </mat-checkbox>
        <mat-icon [matTooltip]="'Is_not_for_profit_translational_project_hint' | translate">info_outline</mat-icon>
    </div>
    -->
    <div class="line">
        <true-false-radio (change)="onIsIncorporatedChanged()"
                          [(ngModel)]="isIncorporated"
                          [disabled]="!rights.EDIT"
                          [label]="('The_company_is_incorporated' | translate) + ' *'">
        </true-false-radio>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="incorporationYearFormControl"
                   [placeholder]="((isIncorporated? 'Year_of_incorporation' : 'Targeted_year_of_icorporation') | translate) + ' *'"
                   id="step2_incorporationYear"
                   matInput
                   type="number">
            <mat-error *ngIf="incorporationYearFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="line">
        <public-private-radio (change)="onProjectTradeTypeChanged()"
                              [(ngModel)]="projectTradeType"
                              [disabled]="!rights.EDIT"
                              [label]="('Project_trade_type' | translate) + ' *'"
                              id="step2_tradeType">
        </public-private-radio>
    </div>
</module-content>
<module-content [title]="'Funding' | translate"
                class="funding-container">
    <div class="line">
        <true-false-radio (change)="_onFormChanged()"
                          [(ngModel)]="isRaisingFunds"
                          [disabled]="!rights.EDIT"
                          [label]="('Currently_raising_funds' | translate) + ' *'">
        </true-false-radio>
        <mat-form-field [hintLabel]="">
            <mat-select (selectionChange)="_onFormChanged()"
                        [formControl]="fundingRoundFormControl"
                        [placeholder]="(isRaisingFunds ? 'Funding_round' : 'Upcoming_funding_round') | translate">
                <mat-option *ngFor="let item of fundingRounds"
                            [value]="item.id">
                    {{ item.name }}
                </mat-option>
                <mat-option (click)="onfundingRoundNoneClicked()"
                            class="none-option">
                    {{ 'None' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="fundingRoundFormControl.value == FUNDING_ROUND_OTHER"
                        [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="customFundingRoundFormControl"
                   [maxlength]="CUSTOM_FUNDING_ROUND_MAX_LENGTH"
                   [placeholder]="'Funding_round_name' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="customFundingRoundFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ customFundingRoundFormControl.value.length }}
                /{{ CUSTOM_FUNDING_ROUND_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="!isRaisingFunds"
                        [hintLabel]="'date_format_hint' | translate"
                        color="accent">
            <input (keyup)="_onFormChanged()"
                   [formControl]="closingRoundDateFormControl"
                   [matDatepicker]="picker"
                   [max]="closingRoundMaxDate"
                   [min]="closingRoundMinDate"
                   [placeholder]="(isRaisingFunds ? 'Date_of_closing_round' : 'Approximate_date_of_opening') | translate"
                   matInput>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker
                            (closed)="_onFormChanged()">
            </mat-datepicker>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="fundingAmountFormControl"
                   [placeholder]="'Amount' | translate"
                   matInput
                   type="number">
            <span matPrefix>CHF&nbsp;</span>
            <mat-error *ngIf="fundingAmountFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isRaisingFunds"
                        [hintLabel]="'date_format_hint' | translate"
                        color="accent">
            <input (keyup)="_onFormChanged()"
                   [formControl]="closingRoundDateFormControl"
                   [matDatepicker]="picker"
                   [max]="closingRoundMaxDate"
                   [min]="closingRoundMinDate"
                   [placeholder]="(isRaisingFunds ? 'Date_of_closing_round' : 'Approximate_date_of_opening') | translate"
                   matInput>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker
                            (closed)="_onFormChanged()">
            </mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngIf="isRaisingFunds"
         class="line">
        <true-false-radio (change)="_onFormChanged()"
                          [(ngModel)]="isLeadInvestorIdentified"
                          [disabled]="!rights.EDIT"
                          [label]="'Lead_investor_identified' | translate">
        </true-false-radio>
    </div>
</module-content>
