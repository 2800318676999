<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Local_experts' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isAdmin"></error-401>
    <users-local-experts-list (onAcademicInstitutionsChanged)="onAcademicInstitutionsChanged($event)"
                              (onAddClicked)="onAddClicked()"
                              (onDeleteRowClicked)="onDeleteRowClicked($event)"
                              (onRowClicked)="onRowClicked($event)"
                              *ngIf="currentUser.user.isAdmin"
                              [addTitle]="'Add_local_expert' | translate"
                              [isAddEnabled]="rights.ADD_LOCAL_EXPERT"
                              [noDataMessage]="'No_local_experts' | translate"
                              [users]="users">
    </users-local-experts-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
