import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { Utils } from '../../providers/utils';
import { CurrentUser } from '../../providers/current-user';
import { LangsProvider } from '../../providers/langs-provider';
import { ModelsStorage } from '../../providers/models-storage';
import { SyncServiceProvider } from '../../providers/sync-service';
import { translateFadeBeforeAfterAnimation } from '../../animations/translate-fade-before-after';
import { AuthDialogComponent } from './auth-dialog/auth-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'auth',
    providers: [],
    styleUrls: ['./auth.scss'],
    templateUrl: './auth.html',
    animations: [translateFadeBeforeAfterAnimation]
})
export class AuthComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public image: string = '';
    @Input() public title: string = '';
    @Input() public nextUrl: string = null;
    @Input() public defaultNextUrl: string = null;
    @Input() public detectPasswordResets: boolean = false;
    @Input() public isRegistrationDisabled: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _storage: ModelsStorage,
        private _langs: LangsProvider,
        private _sync: SyncServiceProvider,
        private _router: Router,
        private _route: ActivatedRoute,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._route.queryParamMap.subscribe((params) => {
            if (params.has('promptLogin')) {
                // on met ça dans un timeout pour éviter un bug d'initialisation de la popup
                // (bug lié à la librairie material donc ...)
                setTimeout(() => {
                    this.onClicked();
                }, 1);
            } else if (this.detectPasswordResets) {
                // c'est que l'utilisateur a cliqué sur un lien de renouvellement du mot de passe
                // on affiche donc le dialog qui va permettre de mettre à jour son mot de passe
                if ((params.has('authEmail') && params.has('authToken'))) {
                    // on met ça dans un timeout pour éviter un bug d'initialisation de la popup
                    // (bug lié à la librairie material donc ...)
                    setTimeout(() => {
                        this.onClicked();
                    }, 1);
                }
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onClicked(): void {
        const dialog: any = this._dialoger.open(AuthDialogComponent, {
            data: {
                title: this.title,
                image: this.image,
                nextUrl: this.nextUrl,
                defaultNextUrl: this.defaultNextUrl ? this.defaultNextUrl : '/?' + (new Date()).getTime(), // vilain hack pour forcer un refresh de la homepage (il se passe plein de trucs quand on l'init...)
                isRegistrationDisabled: this.isRegistrationDisabled
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
