import { Utils } from '../../_common/providers/utils';
import { Company, InvestorProfile, User } from '../../_common/providers/models';

export class CompanyRights {

    //
    //
    // ATTRIBUTES
    //
    //

    private _utils: Utils;
    private _params: CompanyRightsParams = null;
    private _add: boolean = false;
    private _see: boolean = false;
    private _edit: boolean = false;
    private _delete: boolean = false;

    constructor(
        utils: Utils
    ) {
        this._utils = utils;
    }

    public get ADD() {
        return this._add;
    }

    public get SEE() {
        return this._see;
    }

    public get EDIT() {
        return this._edit;
    }

    public get DELETE() {
        return this._delete;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public evaluate(params: CompanyRightsParams): void {
        this._params = params;
        if (params.user.isAdmin) {
            this.evaluateAdmin();
        } else if (params.user.isInvestor) {
            this.evaluateInvestor();
        } else if (params.user.isGuest) {
            this.evaluateGuest();
        } else if (params.user.isProjectHolder) {
            this.evaluateProjectHolder();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private evaluateAdmin(): void {
        this._add = true;
        this._see = true;
        this._edit = true;
        this._delete = true;
    }

    private evaluateGuest(): void {
        this._add = false;
        this._see = false;
        this._edit = false;
        this._delete = false;
    }

    private evaluateInvestor(): void {
        // Investor can see and edit company, if he is linked to it
        const hasCompanyLink = this._params.investorProfile && this._params.investorProfile.companyId === this._params.company.id;
        this._see = hasCompanyLink;
        this._edit = hasCompanyLink;
        this._add = false;
        this._delete = false;
    }

    private evaluateProjectHolder(): void {
        this._add = false;
        this._see = true;
        this._edit = false;
        this._delete = false;
    }
}

export interface CompanyRightsParams {
    user: User;
    company?: Company;
    investorProfile?: InvestorProfile;
}
