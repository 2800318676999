import { AfterViewInit, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from '../../../_common/providers/utils';
import { ProjectsProvider } from '../../providers/projects-provider';
import { AcademicInstitutionGroup, Project, ProjectVisibility } from '../../../_common/providers/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'projects-create-dialog',
    providers: [],
    styleUrls: ['./create-dialog.scss'],
    templateUrl: './create-dialog.html'
})
export class ProjectsCreateDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 200;
    public readonly EXCLUDED_INSTITUTION1_GROUP_IDS: number[] = [AcademicInstitutionGroup.ID_OTHER];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public nameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<ProjectsCreateDialogComponent>,
        private _utils: Utils,
        private _projectsProvider: ProjectsProvider,
        private _toaster: MatSnackBar,
        private _router: Router,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onSaveClicked(): void {
        this.isLoading = true;
        this.save()
            .then((project: Project) => {
                this.isLoading = false;
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
                this.dialog.close(project);
                this._router.navigate(['academic-ventures', project.id, 'edit']);
            })
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private save(): Promise<Project> {
        return new Promise<Project>((resolve, reject) => {
            const project: Project = new Project();
            project.name = this.nameFormControl.value;
            project.projectVisibilityId = ProjectVisibility.ID_NONE;
            this._projectsProvider.saveProject(project)
                .then((project: Project) => {
                    resolve(project);
                })
                .catch((error: any) => this.onError(error));
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
