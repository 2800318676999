import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUser } from '../../_common/providers/current-user';
import { IsLoggedInGuard } from '../../_common/guards/is-logged-in';
import {
    Company,
    InvestorProfile,
    InvestorValidationState,
    Project,
    ProjectValidationState
} from '../../_common/providers/models';
import { ModelsStorage } from '../../_common/providers/models-storage';

@Injectable()
export class CanSeeCompany implements CanActivate {

    constructor(
        private _currentUser: CurrentUser,
        private _isLoggedIn: IsLoggedInGuard,
        private _storage: ModelsStorage,
        private _router: Router
    ) {
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Promise<boolean> {

        try {
            const isLoggedIn = await this._isLoggedIn.canActivate(route, state);
            if (isLoggedIn) {
                if (this._currentUser.user.isAdmin) {
                    return true;
                } else if (this._currentUser.user.isProjectHolder) {
                    const isProjectValidated = await this._storage.select(Project).where('ownerId').equals(this._currentUser.user.id)
                        .and('projectValidationStateId').equals(ProjectValidationState.ID_PUBLISHED).get(1);
                    if (isProjectValidated) {
                        return true;
                    }
                } else if (this._currentUser.user.isInvestor) {
                    const hasLink: InvestorProfile = await this._storage.select(InvestorProfile)
                        .where('userId').equals(this._currentUser.user.id)
                        .and('companyId', InvestorProfile).equals(Number(route.params.id))
                        .get(1);

                    const linkedCompany: Company = await this._storage.select(Company)
                        .where('id').equals(hasLink.companyId).get(1);

                    // SB-787 Allow investor to update company before validation when company is not completed
                    if (!linkedCompany.isCompleted && hasLink) {
                        return true;
                    }
                    return hasLink.investorValidationStateId === InvestorValidationState.ID_ACCEPTED;
                }
            }

            this.redirect();
            return false;

        } catch (e) {
            this.redirect();
            return false;
        }
    }

    private redirect(): void {
        this._router.navigate(['401']);
    }

}
