<div *ngIf="itemsCount > 0"
     class="container">
    <div class="indicator">{{ 'x_of_y' | translate:{current: currentItem, total: itemsCount} }}</div>
    <button (click)="onPreviousClicked()"
            [disabled]="currentItem <= 1"
            mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button (click)="onNextClicked()"
            [disabled]="currentItem >= itemsCount"
            mat-icon-button>
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>
