import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { Company } from '../../../_common/providers/models';

@Component({
    selector: 'acventures-common-company-logos',
    providers: [],
    styleUrls: ['./company-logos.scss'],
    templateUrl: './company-logos.html'
})
export class AcventuresCommonCompanyLogosComponent implements AfterViewInit, OnDestroy {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public companies: Company[] = [];
    public currentIndex: number = 0;
    public aIndex: number = 0;
    public bIndex: number = 0;
    public nextSlideTimestamp: number = 0;
    public sliderTimer: number = 2000;

    private intervalId = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _router: Router,
        private _route: ActivatedRoute,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _storage: ModelsStorage,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.startSlider();
    }

    public ngOnDestroy(): void {
        this.stopSlider();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onNextClicked(stopSlider: boolean = true): void {
        if (this.currentIndex === this.companies.length - 1) {
            this.currentIndex = 0;
        } else {
            this.currentIndex++;
        }
        this.updateABIndexes();

        if (stopSlider) {
            this.stopSlider();
        }
    }

    public onPreviousClicked(stopSlider: boolean = true): void {
        if (this.currentIndex === 0) {
            this.currentIndex = this.companies.length - 1;
        } else {
            this.currentIndex--;
        }
        this.updateABIndexes();

        if (stopSlider) {
            this.stopSlider();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private startSlider(): void {
        this.intervalId = setInterval(() => {
            this.onNextClicked(false);
        }, this.sliderTimer);
    }

    private stopSlider(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    private updateABIndexes(): void {
        if (this.currentIndex % 2 === 0) {
            this.aIndex = this.currentIndex;
        } else {
            this.bIndex = this.currentIndex;
        }
    }

    private onError(error: any): void {
        console.log(error);
    }
}
