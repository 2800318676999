import * as _moment from 'moment';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { LangsProvider } from '../../../_common/providers/langs-provider';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { AcademicInstitution, FundingRound, Project } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-situation',
    providers: [],
    styleUrls: ['./edit-project-situation.scss'],
    templateUrl: './edit-project-situation.html'
})
export class ProjectsEditProjectSituationComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly FUNDING_ROUND_OTHER: number = FundingRound.ID_OTHER;
    public readonly CUSTOM_FUNDING_ROUND_MAX_LENGTH: number = 250;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public isNotForProfit: number = null;
    public isIncorporated: boolean = false;
    public incorporationYearFormControl: FormControl = new FormControl('', [
        (control: any) => {
            if (control.value && control.value != '') {
                return control.value >= (this.isIncorporated ? 1900 : (new Date()).getFullYear()) ? null : {min: {valid: false}};
            }

            return null;
        },
        (control: any) => {
            if (control.value && control.value != '') {
                return control.value <= (this.isIncorporated ? (new Date()).getFullYear() : 2050) ? null : {max: {valid: false}};
            }

            return null;
        },
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public isRaisingFunds: boolean = null;
    public isLeadInvestorIdentified: boolean = null;
    public projectTradeType: number = null;
    public projectTradeTypeFormControl: FormControl = new FormControl('', []);
    public fundingRoundFormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);
    public customFundingRoundFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.CUSTOM_FUNDING_ROUND_MAX_LENGTH)
    ]);
    public fundingAmountFormControl: FormControl = new FormControl('', [
        Validators.min(0),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public closingRoundDateFormControl: FormControl = new FormControl('', []);
    public closingRoundMinDate: Date = new Date('1900-01-01 00:00:00');
    public closingRoundMaxDate: Date = new Date('2050-12-31 23:59:59');
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    public fundingRounds: FundingRound[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router,
        private _langsProvider: LangsProvider,
        private _dateAdapter: DateAdapter<any>
    ) {
        const lang: any = this._utils.findIn(this._langsProvider.getBestLangId(), this.conf.availableLangs, 'id');
        this._dateAdapter.setLocale(lang.dateLocale);
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.isNotForProfit = project.isNotForProfit;
        this.isIncorporated = project.isCompanyIncorporated ? true : false;
        this.projectTradeType = project.projectTradeTypeId;
        this.projectTradeTypeFormControl.setValue(this.projectTradeType);
        this.incorporationYearFormControl.setValue(project.incorporationYear);
        this.isRaisingFunds = project.isRaisingFunds ? true : false;
        this.isLeadInvestorIdentified = project.isLeadInvestorIdentified ? true : false;
        this.fundingRoundFormControl.setValue(project.fundingRoundId);
        this.customFundingRoundFormControl.setValue(project.customFundingRound ? project.customFundingRound : '');
        this.fundingAmountFormControl.setValue(project.raisingFundsAmount);
        this.closingRoundDateFormControl.setValue(project.dateOfClosingRound);
        this.updateRights();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        return !this.incorporationYearFormControl.invalid
            && !this.fundingRoundFormControl.invalid
            && !this.customFundingRoundFormControl.invalid
            && !this.fundingAmountFormControl.invalid
            && !this.closingRoundDateFormControl.invalid
            && !this.projectTradeTypeFormControl.invalid;
    }

    public onIsIncorporatedChanged(): void {
        this.incorporationYearFormControl.setValue(this.incorporationYearFormControl.value); // pour réévaluer les conditions de validation ...
        this.incorporationYearFormControl.markAsTouched();
        this._onFormChanged();
    }

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onProjectTradeTypeChanged(): void {
        this.projectTradeTypeFormControl.setValue(this.projectTradeType);
        this.projectTradeTypeFormControl.markAsTouched();
        this._onFormChanged();
    }

    public onfundingRoundNoneClicked(): void {
        this.fundingRoundFormControl.setValue(null);
        this._onFormChanged();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(FundingRound).get(),
            ])
                .then((data: any[]) => {
                    this.fundingRounds = data[0];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.incorporationYearFormControl.enable();
            this.fundingRoundFormControl.enable();
            this.fundingAmountFormControl.enable();
            this.customFundingRoundFormControl.enable();
            this.closingRoundDateFormControl.enable();
            this.projectTradeTypeFormControl.enable();
        } else {
            this.incorporationYearFormControl.disable();
            this.fundingRoundFormControl.disable();
            this.fundingAmountFormControl.disable();
            this.customFundingRoundFormControl.disable();
            this.closingRoundDateFormControl.disable();
            this.projectTradeTypeFormControl.disable();
        }
    }

    private fireChange(): void {
        this._project.isNotForProfit = this.isNotForProfit ? 1 : 0;
        this._project.isCompanyIncorporated = this.isIncorporated ? 1 : 0;
        this._project.incorporationYear = this.incorporationYearFormControl.value;
        this._project.isRaisingFunds = this.isRaisingFunds ? 1 : 0;
        this._project.isLeadInvestorIdentified = this.isLeadInvestorIdentified ? 1 : 0;
        this._project.fundingRoundId = this.fundingRoundFormControl.value;
        this._project.customFundingRound = this.customFundingRoundFormControl.value;
        this._project.raisingFundsAmount = this.fundingAmountFormControl.value;
        this._project.dateOfClosingRound = this.closingRoundDateFormControl.value && _moment(this.closingRoundDateFormControl.value).format('YYYY-MM-DD HH:mm:ss');
        // on empêche de saisir des données pas cohérentes - ca peut avoir un impact sur la recherche par la suite ...
        if (this._project.isCompanyIncorporated) {
            this._project.academicInstitution2Id = null;
        } else {
            this._project.isSpinOff = null;
        }
        if (this._project.academicInstitution1Id !== AcademicInstitution.ID_OTHER && this._project.academicInstitution2Id !== AcademicInstitution.ID_OTHER) {
            this._project.customAcademicInstitution = null;
        }

        if (!this._project.isRaisingFunds) {
            this._project.isLeadInvestorIdentified = null;
        } else if (this._project.fundingRoundId !== FundingRound.ID_OTHER) {
            this._project.customFundingRound = null;
        }
        this._project.projectTradeTypeId = this.projectTradeTypeFormControl.value;

        this.projectChange.emit(this._project);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
