<div *ngIf="displayMode == 'chip' || displayMode == 'chip-text'"
     [class.accepted]="state.id === STATE_ID_ACCEPTED"
     [class.pending-validation]="state.id === STATE_ID_PENDING_VALIDATION"
     [class.refused]="state.id === STATE_ID_REFUSED"
     [matTooltip]="displayMode == 'chip' ? state.name : null"
     class="chip">
</div>
<div *ngIf="displayMode == 'text' || displayMode == 'chip-text'"
     class="title">
    {{ state.name }}
</div>
