import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ModuleMenu } from '../../../_common/components/module-content/module-content';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { UsersRow } from '../../providers/users-provider';
import { UserRights } from '../../providers/user-rights';
import { User } from '../../../_common/providers/models';

@Component({
    selector: 'users-list',
    providers: [],
    styleUrls: ['./list.scss'],
    templateUrl: './list.html'
})
export class UsersListComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public columns: string[] = [];

    @Input() public title: string = null;
    @Input() public addTitle: string = 'Add';
    @Input() public noDataMessage: string = 'No_data_yet';
    @Input() public isAddEnabled: boolean = false;
    @Input() public hideNameColumn: boolean = false;
    @Input() public hideEmailColumn: boolean = false;
    @Input() public hideCreateDateColumn: boolean = false;
    @Input() public hideActionsColumns: boolean = false;
    @Output() public onRowClicked: EventEmitter<UsersRow> = new EventEmitter<UsersRow>();
    @Output() public onAddClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onDeleteRowClicked: EventEmitter<UsersRow> = new EventEmitter<UsersRow>();
    @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    public addMenu: ModuleMenu = null;

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _dialoger: MatDialog,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _router: Router,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _users: User[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set users(users: User[]) {
        this._users = users;
        this.loadData(users).catch((error: any) => this.onError(error));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initColumns();
        this.initDataSource();
        this.addMenu = {
            icon: 'add',
            title: this.addTitle,
            url: null,
            onClick: () => this.onAddClicked.emit()
        };
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initColumns(): void {
        const columns: string[] = [];
        if (!this.hideNameColumn) {
            columns.push('name');
        }
        if (!this.hideEmailColumn) {
            columns.push('email');
        }
        if (!this.hideCreateDateColumn) {
            columns.push('createDate');
        }
        if (!this.hideActionsColumns) {
            columns.push('actions');
        }

        this.columns = columns;
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.paginator = this.paginator;

        this.dataSource.addSortDefinition(
            'name',
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase(), b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase()),
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase(), a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'email',
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(a.user.email.toLowerCase(), b.user.email.toLowerCase()),
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(b.user.email.toLowerCase(), a.user.email.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'createDate',
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(a.user.createDate, b.user.createDate),
            (a: UsersRow, b: UsersRow) => this.utils.sortRow(b.user.createDate, a.user.createDate)
        );
    }

    private loadData(users: User[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // si on devait charger les users links ... c'est bien de garder un promise
            const rows: UsersRow[] = [];
            users.forEach((user: User) => {
                const rights: UserRights = new UserRights(this.utils);
                rights.evaluate({
                    user: this.currentUser.user,
                    subjectUser: user
                });
                rows.push({
                    user,
                    rights
                });
            });
            this.dataSource.replaceAllWith(rows);

            resolve();
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}
