import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NamePickerComponent } from '../../../_common/components/name-picker/name-picker';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AlertsProvider } from '../../../alerts/providers/alerts-provider';
import {
    DevelopmentPhaseWithAssociatedProjectTypeGroups,
    ProjectFiltersState,
    ProjectSearchResultRow,
    ProjectsProvider,
    ProjectTypeGoupWithTypes
} from '../../providers/projects-provider';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import {
    Alert,
    Alert_DevelopmentPhase,
    Alert_FundingRound,
    Alert_ProjectType,
    Alert_TherapeuticArea,
    DevelopmentPhase,
    FundingRound,
    InvestorProfile,
    Project,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectFavorite,
    ProjectType,
    TherapeuticArea,
    User_AcademicInstitution
} from '../../../_common/providers/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'projects-list',
    providers: [],
    styleUrls: ['./list.scss'],
    templateUrl: './list.html'
})
export class ProjectsListComponent implements AfterViewInit, OnDestroy {
    public readonly INCORPORATION_YEAR_MIN: number = 1900;
    public readonly INCORPORATION_YEAR_MAX: number = (new Date()).getFullYear();

    //
    //
    // CONSTANTS
    //
    //
    public readonly TARGETED_INCORPORATION_YEAR_MIN: number = (new Date()).getFullYear();
    public readonly TARGETED_INCORPORATION_YEAR_MAX: number = 2050;
    public readonly AMOUNT_RAISED_MIN: number = 0;
    public readonly AMOUNT_RAISED_MAX: number = 99999999999; // 100 mia
    public readonly MAX_PROJECT_TYPES_DISPLAYED: number = 5;
    public isReady: boolean = false;
    public isAlertLoading: boolean = false;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //
    public rights: ProjectRights = new ProjectRights(this.utils);
    @Input() public disableSearchEngine: boolean = false;
    @Input() public moduleTitle: string = null;
    @Input() public hideLogoColumn: boolean = false;
    @Input() public hideNameColumn: boolean = false;
    @Input() public hideWebsiteColumn: boolean = false;
    @Input() public hideProjectTypeColumn: boolean = false;
    @Input() public hideActionsColumn: boolean = false;
    @Input() public investorProfile: InvestorProfile = null;
    @Input() public preserveFilters: boolean = false;
    @Output() public onRowClicked: EventEmitter<ProjectSearchResultRow> = new EventEmitter<ProjectSearchResultRow>();
    @Output() public onFavoriteClicked: EventEmitter<ProjectSearchResultRow> = new EventEmitter<ProjectSearchResultRow>();
    @Output() public onDeleteClicked: EventEmitter<ProjectSearchResultRow> = new EventEmitter<ProjectSearchResultRow>();
    @Output() public onEditClicked: EventEmitter<ProjectSearchResultRow> = new EventEmitter<ProjectSearchResultRow>();
    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public columns: string[] = [];
    @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    public searchInput: FormControl = new FormControl('');
    public projectTypesCheckboxes: boolean[][] = [];
    public filteredProjectTypes: ProjectType[] = [];
    public therapeuticAreasCheckboxes: boolean[] = [];
    public filteredTherapeuticAreas: TherapeuticArea[] = [];
    public developmentPhasesCheckboxes: boolean[] = [];
    public filteredDevelopmentPhases: DevelopmentPhase[] = [];
    public fundingRoundsCheckboxes: boolean[] = [];
    public filteredFundingRound: FundingRound[] = [];
    public filteredOthers: Alert = new Alert(); // mini hack, on utilise directement cette structure pour binder les oui/non - c'est plus simple
    public collaborationSelectionLabel: string = null;
    public moreFiltersSelectionLabel: string = null;
    public yearOfIncorporationToFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.yearOfIncorporationToFormControl, this.yearOfIncorporationFromFormControl, null, this.INCORPORATION_YEAR_MIN, this.INCORPORATION_YEAR_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public yearOfIncorporationFromFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.yearOfIncorporationFromFormControl, null, this.yearOfIncorporationToFormControl, this.INCORPORATION_YEAR_MIN, this.INCORPORATION_YEAR_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public targetedYearOfIncorporationToFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.targetedYearOfIncorporationToFormControl, this.targetedYearOfIncorporationFromFormControl, null, this.TARGETED_INCORPORATION_YEAR_MIN, this.TARGETED_INCORPORATION_YEAR_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public targetedYearOfIncorporationFromFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.targetedYearOfIncorporationFromFormControl, null, this.targetedYearOfIncorporationToFormControl, this.TARGETED_INCORPORATION_YEAR_MIN, this.TARGETED_INCORPORATION_YEAR_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public amountRaisedToFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.amountRaisedToFormControl, this.amountRaisedFromFormControl, null, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public amountRaisedFromFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.amountRaisedFromFormControl, null, this.amountRaisedToFormControl, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public projectTypeGroups: ProjectTypeGoupWithTypes[] = [];
    public projectTypes: ProjectType[] = [];
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhases: DevelopmentPhase[] = [];
    public fundingRound: FundingRound[] = [];
    public developmentPhasesWithAssociatedProjectTypeGroups: DevelopmentPhaseWithAssociatedProjectTypeGroups[] = [];
    private _allRows: ProjectSearchResultRow[] = [];

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _dialoger: MatDialog,
        private _router: Router,
        private _route: ActivatedRoute,
        private _toaster: MatSnackBar,
        private _alertsProvider: AlertsProvider,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _projects: Project[] = [];

    public get projects() {
        return this._projects;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set projects(projects: Project[]) {
        this._projects = projects;
        if (projects && projects.length) {
            this.loadStaticData()
                .then(() => {
                    this.buildDataRows(projects)
                        .then((rows: ProjectSearchResultRow[]) => {
                            this._allRows = rows;
                            this.applyFilters();
                        })
                        .catch((error: any) => this.onError(error));
                })
                .catch((error: any) => this.onError(error));
        } else {
            this._allRows = [];
            this.applyFilters();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initColumns();
        this.initDataSource();
        this.loadStaticData()
            .then(() => {
                this._route.params.subscribe((params: any) => {
                    this.rights.evaluate({
                        investorProfile: this.investorProfile,
                        user: this.currentUser.user
                    });
                    const alertId: number = parseInt(params.alertId, 10);
                    if (alertId) {
                        this.getFiltersStateFromAlert(alertId)
                            .then((state: ProjectFiltersState) => {
                                this.initFilters(state)
                                    .then(() => this.isReady = true)
                                    .catch((error: any) => this.onError(error));
                            })
                            .catch((error: any) => this.onError(error));
                    } else if (this.preserveFilters) {
                        this.initFilters(this.projectsProvider.projectFiltersState)
                            .then(() => this.isReady = true)
                            .catch((error: any) => this.onError(error));
                    } else {
                        this.initFilters(null)
                            .then(() => this.isReady = true)
                            .catch((error: any) => this.onError(error));
                    }
                });
            })
            .catch((error: any) => this.onError(error));
    }

    public ngOnDestroy() {
        if (this.preserveFilters) {
            this.saveFiltersState();
        }
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    public searchInputChanged(filter: string): void {
        this.dataSource.filter = this.utils.normalizeString(filter);
    }

    public _onRowClicked(row: ProjectSearchResultRow): void {
        if (row.rights.SEE) {
            this.onRowClicked.emit(row);
        }
    }

    public onAllProjectTypesClicked(event: any): void {
        event.stopPropagation();
        this.filteredProjectTypes = this.projectTypes.slice();
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', true);
        this.applyFilters();
    }

    public onNoProjectTypesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredProjectTypes = [];
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', false);
        this.applyFilters();
    }

    public onAllTherapeuticAreasClicked(event: any): void {
        event.stopPropagation();
        this.filteredTherapeuticAreas = this.therapeuticAreas.slice();
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, true);
        this.applyFilters();
    }

    public onNoTherapeuticAreasClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.applyFilters();
    }

    public onAllDevelopmentPhasesClicked(event: any): void {
        event.stopPropagation();
        this.filteredDevelopmentPhases = this.developmentPhases.slice();
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, true);
        this.applyFilters();
    }

    public onNoDevelopmentPhasesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.applyFilters();
    }

    public onAllFundingRoundsClicked(event: any): void {
        event.stopPropagation();
        this.filteredFundingRound = this.fundingRound.slice();
        this.switchAllCheckboxes(this.fundingRoundsCheckboxes, this.fundingRound, true);
        this.applyFilters();
    }

    public onNoFundingRoundsClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredFundingRound = [];
        this.switchAllCheckboxes(this.fundingRoundsCheckboxes, this.fundingRound, false);
        this.applyFilters();
    }

    public onProjectTypeClicked(event: any, type: ProjectType): void {
        setTimeout(() => {
            this.toggleFilterSelection(type, this.filteredProjectTypes);
            this.applyFilters();
        }, 1);
    }

    public onTherapeuticAreaClicked(event: any, area: TherapeuticArea): void {
        setTimeout(() => {
            this.toggleFilterSelection(area, this.filteredTherapeuticAreas);
            this.applyFilters();
        }, 1);
    }

    public onDevelopmentPhaseClicked(event: any, phase: DevelopmentPhase): void {
        setTimeout(() => {
            this.toggleFilterSelection(phase, this.filteredDevelopmentPhases);
            this.applyFilters();
        }, 1);
    }

    public onFundingRoundClicked(event: any, round: FundingRound): void {
        setTimeout(() => {
            this.toggleFilterSelection(round, this.filteredFundingRound);
            this.applyFilters();
        }, 1);
    }

    public onMoreFiltersItemClicked(event: any): void {
        setTimeout(() => {
            const selectionLabels: string[] = [];

            this.yearOfIncorporationFromFormControl.updateValueAndValidity();
            this.yearOfIncorporationToFormControl.updateValueAndValidity();
            this.targetedYearOfIncorporationFromFormControl.updateValueAndValidity();
            this.targetedYearOfIncorporationToFormControl.updateValueAndValidity();
            this.amountRaisedFromFormControl.updateValueAndValidity();
            this.amountRaisedToFormControl.updateValueAndValidity();

            if (this.filteredOthers.isSeekingPartnerhips || this.filteredOthers.isNotSeekingPartnerhips) {
                selectionLabels.push('Seeking_partnerships');
            }
            if (this.filteredOthers.isOutlicensing || this.filteredOthers.isNotOutlicensing) {
                selectionLabels.push('Out_licensing_opportunities');
            }
            if (this.filteredOthers.isNotForProfit || this.filteredOthers.isNotNotForProfit) {
                selectionLabels.push('Not_for_profit_ventures');
            }
            if (this.filteredOthers.isCompanyIncorporated) {
                selectionLabels.push('Company_incorporated');
            } else {
                this.yearOfIncorporationFromFormControl.setValue(null);
                this.yearOfIncorporationToFormControl.setValue(null);
            }
            if (this.filteredOthers.isCompanyNotIncorporated) {
                selectionLabels.push('Company_not_incorporated');
            } else {
                this.targetedYearOfIncorporationFromFormControl.setValue(null);
                this.targetedYearOfIncorporationToFormControl.setValue(null);
            }
            if (this.filteredOthers.isRaisingFunds) {
                selectionLabels.push('Currently_raising_funds');
            } else {
                this.amountRaisedFromFormControl.setValue(null);
                this.amountRaisedToFormControl.setValue(null);
            }
            if (this.filteredOthers.isNotRaisingFunds) {
                selectionLabels.push('Currently_not_raising_funds');
            }

            if (selectionLabels.length === 0) {
                this.moreFiltersSelectionLabel = null;
            } else if (selectionLabels.length === 1) {
                this.moreFiltersSelectionLabel = this._translater.instant(selectionLabels[0]);
            } else {
                this.moreFiltersSelectionLabel = this._translater.instant('n_filters', {count: selectionLabels.length});
            }

            this.applyFilters();
        }, 1);
    }

    public onResetFiltersClicked(): void {
        this.filteredProjectTypes = [];
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', false);
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.filteredFundingRound = [];
        this.switchAllCheckboxes(this.fundingRoundsCheckboxes, this.fundingRound, false);
        this.filteredOthers.isSeekingPartnerhips = null;
        this.filteredOthers.isNotSeekingPartnerhips = null;
        this.filteredOthers.isOutlicensing = null;
        this.filteredOthers.isNotOutlicensing = null;
        this.filteredOthers.isNotForProfit = null;
        this.filteredOthers.isNotNotForProfit = null;
        this.filteredOthers.isCompanyIncorporated = null;
        this.filteredOthers.isCompanyNotIncorporated = null;
        this.filteredOthers.isRaisingFunds = null;
        this.filteredOthers.isNotRaisingFunds = null;
        this.yearOfIncorporationFromFormControl.setValue(null);
        this.yearOfIncorporationToFormControl.setValue(null);
        this.targetedYearOfIncorporationFromFormControl.setValue(null);
        this.targetedYearOfIncorporationToFormControl.setValue(null);
        this.amountRaisedFromFormControl.setValue(null);
        this.amountRaisedToFormControl.setValue(null);
        this.moreFiltersSelectionLabel = null;

        this.applyFilters();
    }

    public onProjectTypeGroupClicked(event: any, row: ProjectTypeGoupWithTypes, rowIndex: number): void {
        const matchingItems: ProjectType[] = this.utils.findManyIn(
            this.utils.getKeyValues(row.types, 'id'),
            this.filteredProjectTypes,
            'id'
        );

        // si tout était déjà sélectionné ...
        if (matchingItems.length === row.types.length) {
            // on enlève tout
            for (let i = 0; i < row.types.length; i++) {
                this.filteredProjectTypes.splice(this.filteredProjectTypes.indexOf(row.types[i]), 1);
                this.projectTypesCheckboxes[rowIndex][i] = false;
            }
        } else {
            // sinon on ajoute celles qui manquent
            for (let i = 0; i < row.types.length; i++) {
                if (this.filteredProjectTypes.indexOf(row.types[i]) < 0) {
                    this.filteredProjectTypes.push(row.types[i]);
                    this.projectTypesCheckboxes[rowIndex][i] = true;
                }
            }
        }

        this.applyFilters();
    }

    public stopPropagation(event): void {
        event.stopPropagation();
    }

    public onAddAlertClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(NamePickerComponent, {
            data: {
                name: '',
                title: 'Add_an_alert',
                placeholder: 'Alert_name',
                maxLength: 255,
                explaination: 'Add_alert_explaination'
            }
        });
        dialog.afterClosed().subscribe((name: string) => {
            if (name) {
                this.isAlertLoading = true;
                this.saveAlert(name)
                    .then((alert: Alert) => {
                        this.isAlertLoading = false;
                        this._router.navigate(['alerts', alert.id])
                            .then(() => this._toaster.open(this._translater.instant('Alert_saved'), '', {duration: 5000}))
                            .catch((error: any) => this.onError(error));
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    public areFiltersValid(): boolean {
        // les seuls filtres qui peuvent potentiellement être invalides sont les inputs
        // (je vois pas comment les checkbox pourront être invalides ...)
        return this.isMoreFiltersValid();
    }

    public isMoreFiltersValid(): boolean {
        return !this.amountRaisedFromFormControl.invalid
            && !this.amountRaisedToFormControl.invalid
            && !this.targetedYearOfIncorporationFromFormControl.invalid
            && !this.targetedYearOfIncorporationToFormControl.invalid
            && !this.yearOfIncorporationFromFormControl.invalid
            && !this.yearOfIncorporationToFormControl.invalid;
    }

    public hasFilters(): boolean {
        return this.filteredProjectTypes.length > 0
            || this.filteredTherapeuticAreas.length > 0
            || this.filteredDevelopmentPhases.length > 0
            || this.filteredFundingRound.length > 0
            || this.collaborationSelectionLabel != null
            || this.moreFiltersSelectionLabel != null;
    }

    public _onFavoriteClicked(row: ProjectSearchResultRow): void {
        this.onFavoriteClicked.emit(row);
    }

    public getGroupCheckboxIcon(items: any[], checkAgainst: any): string {
        const matchingItems: number[] = this.utils.findManyIn(this.utils.getKeyValues(checkAgainst), items, 'id');
        if (matchingItems.length === 0) {
            return 'check_box_outline_blank';
        } else if (matchingItems.length === items.length) {
            return 'check_box';
        }

        return 'indeterminate_check_box';
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(ProjectType).get(),
                this._storage.select(TherapeuticArea).orderBy('name').get(),
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this.projectsProvider.getProjectTypeGroups(),
                this.projectsProvider.getDevelopmentPhasesWithAssociatedProjectTypeGroups(),
                this._storage.select(FundingRound).get(),
            ])
                .then((data: any[]) => {
                    this.projectTypes = data[0];
                    this.therapeuticAreas = data[1];
                    this.developmentPhases = data[2];
                    this.projectTypeGroups = data[3];
                    this.developmentPhasesWithAssociatedProjectTypeGroups = data[4];
                    this.fundingRound = data[5];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private applyFilters(): void {
        if (!this.areFiltersValid()) {
            return;
        }

        const rows: ProjectSearchResultRow[] = [];
        for (let i = 0; i < this._allRows.length; i++) {
            if (this.matchFilterSomeList(this._allRows[i].projectTypeIds, this.filteredProjectTypes)
                && this.matchFilterSomeList(this._allRows[i].therapeuticAreaIds, this.filteredTherapeuticAreas)
                && this.matchFilterSomeList([this._allRows[i].project.developmentPhaseId], this.filteredDevelopmentPhases)
                && this.matchFilterSomeList([this._allRows[i].fundingRoundId], this.filteredFundingRound)
                && this.matchFilterCollaboration(this._allRows[i])
                && this.matchFilterMoreFilters(this._allRows[i])) {
                rows.push(this._allRows[i]);
            }
        }

        this.dataSource.replaceAllWith(rows);
    }

    private toggleFilterSelection(item: any, list: any[]): boolean {
        const index: number = list.indexOf(item);
        if (index >= 0) {
            list.splice(index, 1);

            return false;
        }

        list.push(item);

        return true;
    }

    private buildDataRows(projects: Project[]): Promise<ProjectSearchResultRow[]> {
        return new Promise<ProjectSearchResultRow[]>((resolve, reject) => {
            if (projects.length === 0) {
                resolve([]);
                return;
            }

            const projectIds: number[] = this.utils.getKeyValues(projects, 'id');
            Promise.all([
                this._storage.select(ProjectCase_ProjectType).where('projectId').in(projectIds).get(),
                this._storage.select(ProjectCase_TherapeuticArea).where('projectId').in(projectIds).get(),
                this._storage.select(ProjectFavorite).where('userId').equals(this.currentUser.user.id).get(),
                this._storage.select(User_AcademicInstitution).where('userId').equals(this.currentUser.user.id).get()
            ])
                .then((relatedData: any[]) => {
                    const data: ProjectSearchResultRow[] = [];
                    for (let i = 0; i < projects.length; i++) {
                        const rights: ProjectRights = new ProjectRights(this.utils);
                        rights.evaluate({
                            project: projects[i],
                            user: this.currentUser.user,
                            investorProfile: this.investorProfile,
                            user_academicInstitutions: relatedData[3]
                        });

                        const projectTypesIds: number[] = this.utils.getKeyValues(this.utils.findManyIn([projects[i].id], relatedData[0], 'projectId'), 'projectTypeId');
                        const therapeuticAreaIds: number[] = this.utils.getKeyValues(this.utils.findManyIn([projects[i].id], relatedData[1], 'projectId'), 'therapeuticAreaId');
                        const row: ProjectSearchResultRow = {
                            project: projects[i],
                            institution1: null,
                            fundingRoundId: projects[i].isRaisingFunds === 1 && this.utils.findIn(projects[i].fundingRoundId, this.fundingRound) ? this.utils.findIn(projects[i].fundingRoundId, this.fundingRound).id : 0,
                            rights,
                            projectTypes: this.utils.findManyIn(projectTypesIds, this.projectTypes),
                            therapeuticAreas: this.utils.findManyIn(therapeuticAreaIds, this.therapeuticAreas),
                            projectTypeIds: projectTypesIds,
                            therapeuticAreaIds,
                            isLoading: false,
                            isFavorite: this.utils.findIn(projects[i].id, relatedData[2], 'projectId') !== null
                        };

              // FIXME: deactivate thumbnail because it is not resized (> 1MB) and list loads multiple images, which block
              //        the execution of other requests
            // if (projects[i].logo) { // on remplace le logo par l'illustration, mais c'est pas encore définitif...
              // if (projects[i].illustration) {
              // this._filesProvider.downloadFile('projects/' + projects[i].id + '/download-logo')
              // this._filesProvider.downloadFile('projects/' + projects[i].id + '/download-illustration', null, {noCache: true})
              //   .then((fileData: any) => {
              //     const reader: FileReader = new FileReader();
              //     reader.addEventListener('load', () => row.logoImage = reader.result, false);
              //     reader.readAsDataURL(fileData);
              //   })
              //   .catch((error: any) => this.onError(error));
              // } else {
              row.logoImage = null;
              // }

                        data.push(row);
                    }

                    resolve(data);
                })
                .catch((error: any) => reject(error));
        });
    }

    private saveFiltersState(): void {
        const projectTypeIds: number[] = this.utils.getKeyValues(this.filteredProjectTypes);
        const therapeuticAreaIds: number[] = this.utils.getKeyValues(this.filteredTherapeuticAreas);
        const developmentPhaseIds: number[] = this.utils.getKeyValues(this.filteredDevelopmentPhases);
        const fundingRoundIds: number[] = this.utils.getKeyValues(this.filteredFundingRound);
        const alert: Alert = this.filteredOthers.clone();

        this.projectsProvider.projectFiltersState = {
            alert,
            developmentPhaseIds,
            fundingRoundIds,
            projectTypeIds,
            therapeuticAreaIds,
            pageIndex: this.paginator.pageIndex
        };
    }

    private getFiltersStateFromAlert(alertId: number): Promise<ProjectFiltersState> {
        return new Promise<ProjectFiltersState>((resolve, reject) => {
            alertId = alertId ? alertId : 0;
            Promise.all([
                this._storage.select(Alert_ProjectType).where('alertId').equals(alertId).get(),
                this._storage.select(Alert_TherapeuticArea).where('alertId').equals(alertId).get(),
                this._storage.select(Alert_DevelopmentPhase).where('alertId').equals(alertId).get(),
                this._storage.select(Alert).where('id').equals(alertId).get(1),
                this._storage.select(Alert_FundingRound).where('alertId').equals(alertId).get(),
            ])
                .then((data: any[]) => {
                    resolve({
                        alert: data[3],
                        developmentPhaseIds: this.utils.getKeyValues(data[2], 'developmentPhaseId'),
                        projectTypeIds: this.utils.getKeyValues(data[0], 'projectTypeId'),
                        therapeuticAreaIds: this.utils.getKeyValues(data[1], 'therapeuticAreaId'),
                        fundingRoundIds: this.utils.getKeyValues(data[1], 'fundingRoundId'),
                        pageIndex: 0
                    });
                })
                .catch((error: any) => reject(error));
        });
    }

    private initFilters(filtersState: ProjectFiltersState): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                filtersState = filtersState ? filtersState : {
                    alert: new Alert(),
                    developmentPhaseIds: [],
                    fundingRoundIds: [],
                    projectTypeIds: [],
                    therapeuticAreaIds: [],
                    pageIndex: 0
                };

                this.filteredProjectTypes = [];
                this.projectTypesCheckboxes = [];
                this.filteredTherapeuticAreas = [];
                this.therapeuticAreasCheckboxes = [];
                this.filteredDevelopmentPhases = [];
                this.developmentPhasesCheckboxes = [];
                this.filteredFundingRound = [];
                this.fundingRoundsCheckboxes = [];

                this.projectTypeGroups.forEach((item: ProjectTypeGoupWithTypes, i: number) => {
                    const rows: boolean[] = [];
                    item.types.forEach((type: ProjectType, j: number) => {
                        if (filtersState.projectTypeIds.indexOf(type.id) >= 0) {
                            rows.push(true);
                            this.filteredProjectTypes.push(type);
                        } else {
                            rows.push(false);
                        }
                    });
                    this.projectTypesCheckboxes.push(rows);
                });

                this.therapeuticAreas.forEach((item: TherapeuticArea, i: number) => {
                    if (filtersState.therapeuticAreaIds.indexOf(item.id) >= 0) {
                        this.therapeuticAreasCheckboxes.push(true);
                        this.filteredTherapeuticAreas.push(item);
                    } else {
                        this.therapeuticAreasCheckboxes.push(false);
                    }
                });

                this.fundingRound.forEach((item: FundingRound, i: number) => {
                    if (filtersState.fundingRoundIds) {
                        if (filtersState.fundingRoundIds.indexOf(item.id) >= 0) {
                            this.fundingRoundsCheckboxes.push(true);
                            this.filteredFundingRound.push(item);
                        } else {
                            this.fundingRoundsCheckboxes.push(false);
                        }
                    }
                });

                this.developmentPhases.forEach((item: DevelopmentPhase, i: number) => {
                    if (filtersState.developmentPhaseIds.indexOf(item.id) >= 0) {
                        this.developmentPhasesCheckboxes.push(true);
                        this.filteredDevelopmentPhases.push(item);
                    } else {
                        this.developmentPhasesCheckboxes.push(false);
                    }
                });

                this.filteredOthers = filtersState.alert ? filtersState.alert.clone() : new Alert();
                this.filteredOthers.id = 0; // pour être sûr de ne pas lier de futures recherches avec cette alerte
                this.onMoreFiltersItemClicked({}); // vilain hack pour refresh le control ...

                this.applyFilters();

                this.paginator.pageIndex = filtersState.pageIndex;

                resolve();
            }, 1);
        });
    }

  private initColumns(): void {
    const columns: string[] = [];
    // if (!this.hideLogoColumn) {
    //   columns.push('logo');
    // }
    if (!this.hideNameColumn) {
      columns.push('name');
    }
    if (!this.hideWebsiteColumn) {
      columns.push('website');
    }
    if (!this.hideProjectTypeColumn) {
      columns.push('type');
    }
    if (!this.hideActionsColumn) {
      columns.push('actions');
    }

        this.columns = columns;
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (row: ProjectSearchResultRow, filter: string) => {
            return this.utils.normalizeString(
                row.project.name +
                (row.project.keywords ? row.project.keywords : '') +
                (row.project.pipeline ? row.project.pipeline : '') +
                (row.institution1 ? row.institution1.name : '')
            ).includes(filter);
        };
        this.searchInput.valueChanges.subscribe((value: string) => this.searchInputChanged(value)); // on utilise pas un bête keyup parce que si on sélectionne le texte et qu'on le coupe par exemple, on catch pas l'event ...

        this.dataSource.addSortDefinition(
            'name',
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => this.utils.sortRow(a.project.name.toLowerCase(), b.project.name.toLowerCase()),
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => this.utils.sortRow(b.project.name.toLowerCase(), a.project.name.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'website',
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => {
                if (!a.project.contactWebsite) {
                    return -1;
                } else if (!b.project.contactWebsite) {
                    return 1;
                }

                return this.utils.sortRow(a.project.contactWebsite.toLowerCase(), b.project.contactWebsite.toLowerCase());
            },
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => {
                if (!b.project.contactWebsite) {
                    return -1;
                } else if (!a.project.contactWebsite) {
                    return 1;
                }

                return this.utils.sortRow(b.project.contactWebsite.toLowerCase(), a.project.contactWebsite.toLowerCase());
            }
        );

        this.dataSource.addSortDefinition(
            'type',
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => this.utils.sortRow(this.utils.getKeyValues(a.projectTypes, 'name').join('').toLowerCase(), this.utils.getKeyValues(b.projectTypes, 'name').join('').toLowerCase()),
            (a: ProjectSearchResultRow, b: ProjectSearchResultRow) => this.utils.sortRow(this.utils.getKeyValues(b.projectTypes, 'name').join('').toLowerCase(), this.utils.getKeyValues(a.projectTypes, 'name').join('').toLowerCase())
        );
    }

    private switchAllCheckboxes(list: boolean[], data: any, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            list[i] = value;
        }
    }

    private switchAllCheckboxesWithGroup(list: boolean[][], data: any, keyName: string, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i][keyName].length; j++) {
                list[i][j] = value;
            }
        }
    }

    private checkMinMaxFormControl(targetControl: FormControl, minControl: FormControl, maxControl: FormControl, minFallback: number, maxFallback: number): any {
        if (!targetControl || !targetControl.value) {
            return null;
        }

        if (minControl && minControl.value && targetControl.value < minControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value < minFallback) {
            return {consistency: {valid: false}};
        }

        if (maxControl && maxControl.value && targetControl.value > maxControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value > maxFallback) {
            return {consistency: {valid: false}};
        }

        return null;
    }

    private matchFilterSomeList(keys: number[], filters: any[]): boolean {
        // s'il n'y a pas de filter, ça match ...
        if (filters.length === 0) {
            return true;
        }

        // on prend pas en considération les clés qui seraient null ...
        const _keys: number[] = keys.filter((val: number) => val ? true : false);

        return this.utils.findManyIn(_keys, filters, 'id').length > 0;
    }

    private matchFilterCollaboration(row: ProjectSearchResultRow): boolean {
        if (!this.matchFilterYesNo(this.filteredOthers.isSeekingPartnerhips, this.filteredOthers.isNotSeekingPartnerhips, row.project.isSeekingPartnerships)) {
            return false;
        }

        if (!this.matchFilterYesNo(this.filteredOthers.isOutlicensing, this.filteredOthers.isNotOutlicensing, row.project.isOfferingLicensing)) {
            return false;
        }

        if (!this.matchFilterYesNo(this.filteredOthers.isNotForProfit, this.filteredOthers.isNotNotForProfit, row.project.isNotForProfit)) {
            return false;
        }

        return true;
    }

    private matchFilterMoreFilters(row: ProjectSearchResultRow): boolean {
        if (!this.matchFilterYesNo(this.filteredOthers.isRaisingFunds, this.filteredOthers.isNotRaisingFunds, row.project.isRaisingFunds)) {
            return false;
        }
        // *100 ; on a le montant en cents, et on l'utilisateur le saisit en dollars
        if (this.amountRaisedFromFormControl.value && (!row.project.raisingFundsAmount || (this.amountRaisedFromFormControl.value * 100) > row.project.raisingFundsAmount)) {
            return false;
        }
        if (this.amountRaisedToFormControl.value && (!row.project.raisingFundsAmount || (this.amountRaisedToFormControl.value * 100) < row.project.raisingFundsAmount)) {
            return false;
        }

        if (!this.matchFilterYesNo(this.filteredOthers.isCompanyIncorporated, this.filteredOthers.isCompanyNotIncorporated, row.project.isCompanyIncorporated)) {
            return false;
        }
        if (row.project.isCompanyIncorporated) {
            if (this.yearOfIncorporationFromFormControl.value && (!row.project.incorporationYear || this.yearOfIncorporationFromFormControl.value > row.project.incorporationYear)) {
                return false;
            }
            if (this.yearOfIncorporationToFormControl.value && (!row.project.incorporationYear || this.yearOfIncorporationToFormControl.value < row.project.incorporationYear)) {
                return false;
            }
        } else {
            if (this.targetedYearOfIncorporationFromFormControl.value && (!row.project.incorporationYear || this.targetedYearOfIncorporationFromFormControl.value > row.project.incorporationYear)) {
                return false;
            }
            if (this.targetedYearOfIncorporationToFormControl.value && (!row.project.incorporationYear || this.targetedYearOfIncorporationToFormControl.value < row.project.incorporationYear)) {
                return false;
            }
        }

        return true;
    }

    private matchFilterYesNo(yesValue: number, noValue: number, actualValue: number): boolean {
        //      si rien n'est coché, on ignore le filtre (l'utilisateur n'a pas renseigner l'info ...)
        //      si les deux sont cochés, on ignore le filtre (l'utilisateur indique explicitement qu'il veut les 2, et y a que 2 possibilités, donc ce sera forcément true)
        //      si un seul des deux est cochés, on check que ca match avec le model
        if (yesValue) {
            if (!noValue) {
                if (!(actualValue == 1)) {
                    return false;
                }
            }
        } else {
            if (noValue) {
                if (actualValue == 1) {
                    return false;
                }
            }
        }

        return true;
    }

    private saveAlert(name: string): Promise<Alert> {
        return new Promise<Alert>((resolve, reject) => {
            const alert: Alert = this.filteredOthers.clone();
            alert.id = 0; // on peut pas modifier une alerte, on en créé systématiquement des nouvelles
            alert.name = name;
            alert.isCompanyIncorporated = alert.isCompanyIncorporated ? 1 : 0;
            alert.isCompanyNotIncorporated = alert.isCompanyNotIncorporated ? 1 : 0;
            alert.isSeekingPartnerhips = alert.isSeekingPartnerhips ? 1 : 0;
            alert.isNotSeekingPartnerhips = alert.isNotSeekingPartnerhips ? 1 : 0;
            alert.isOutlicensing = alert.isOutlicensing ? 1 : 0;
            alert.isNotOutlicensing = alert.isNotOutlicensing ? 1 : 0;
            alert.isNotForProfit = alert.isNotForProfit ? 1 : 0;
            alert.isNotNotForProfit = alert.isNotNotForProfit ? 1 : 0;
            alert.isRaisingFunds = alert.isRaisingFunds ? 1 : 0;
            alert.isNotRaisingFunds = alert.isNotRaisingFunds ? 1 : 0;

            this._alertsProvider.saveAlert(alert)
                .then((savedAlert: Alert) => {
                    Promise.all([
                        this._alertsProvider.saveAlertProjectTypes(savedAlert.id, this.utils.getKeyValues(this.filteredProjectTypes, 'id')),
                        this._alertsProvider.saveAlertTherapeuticAreas(savedAlert.id, this.utils.getKeyValues(this.filteredTherapeuticAreas, 'id')),
                        this._alertsProvider.saveAlertDevelopmentPhases(savedAlert.id, this.utils.getKeyValues(this.filteredDevelopmentPhases, 'id')),
                        this._alertsProvider.saveAlertFundingRounds(savedAlert.id, this.utils.getKeyValues(this.filteredFundingRound, 'id'))
                    ])
                        .then(() => resolve(savedAlert))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        this.isReady = true;
        this.isAlertLoading = false;
        console.log(error);
        if (error.status === 429) {
            return;
        }
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
