<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Investor Dashboard' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isInvestor"></error-401>

    <div class="favorites-wrapper">
        <favorites-list *ngIf="currentUser.user.isInvestor || currentUser.user.isGuest"></favorites-list>
    </div>

    <alerts-list *ngIf="currentUser.user.isInvestor || currentUser.user.isGuest"></alerts-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
