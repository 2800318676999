<div class="container">
    <div *ngIf="isStacked && isStackable"
         class="expand-container">
        <button (click)="onExpandClicked()"
                color="accent"
                mat-button>
            <mat-icon>chat_bubble_outline</mat-icon>&nbsp;
            <span *ngIf="messages.length > 0">{{ 'Comments_count' | translate:{count: messages.length} }}</span>
            <span *ngIf="messages.length == 0">{{ noMessageText | translate }}</span>
        </button>
    </div>
    <div *ngIf="!isStacked || !isStackable"
         class="unstacked-container">
        <div [scrollTop]="messagesScrollTop"
             class="scrollable-content">
            <div *ngIf="title || isStackable"
                 class="title-container">
                <div *ngIf="title"
                     class="title">
                    <mat-icon>chat_bubble_outline</mat-icon>&nbsp;{{ title | translate }}
                </div>
                <button (click)="onContractClicked()"
                        *ngIf="isStackable"
                        color="accent"
                        mat-button>
                    {{ 'Hide' | translate }}
                </button>
            </div>
            <div class="messages-container">
                <div *ngIf="messages.length === 0"
                     class="no-message">
                    <mat-icon>info_outline</mat-icon>&nbsp;{{ noMessageText | translate }}
                </div>
                <div *ngIf="messages.length > 0">
                    <div *ngIf="!isShowMore && messages.length > messagesDisplayedCount"
                         class="show-more-button">
                        <button (click)="onShowMoreClicked()"
                                color="accent"
                                mat-button>
                            {{ 'Show_n_more_previous_comments' | translate:{count: messages.length - messagesDisplayedCount} }}
                        </button>
                    </div>
                    <ng-container *ngFor="let message of messages; let i = index">
                        <discussions-message (onDeleteMessage)="onDeleteMessage($event)"
                                             *ngIf="isShowMore || i > messages.length - messagesDisplayedCount - 1"
                                             [(ngModel)]="message.message"
                                             [deleteMessagePattern]="deleteMessagePattern"
                                             [project]="project"></discussions-message>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="input-container">
            <ng-container *ngIf="!newMessageDisableMessage">
                <div class="message-like">
                    <mat-form-field class="input">
                        <textarea #messageInput
                                  (keyup)="onInputChange()"
                                  [(ngModel)]="newMessage"
                                  [matAutosizeMaxRows]="inputMaxRows"
                                  class="text-area-unresizable"
                                  matInput
                                  matTextareaAutosize
                                  placeholder="{{ newMessagePlaceholder | translate }}"></textarea>
                        <mat-hint *ngIf="messageInput.value.length >= (MESSAGE_MAX_LENGTH - MESSAGE_MAX_LENGTH * 0.1)"
                                  align="end">
                            {{ messageInput.value.length }} / {{ MESSAGE_MAX_LENGTH }}
                        </mat-hint>
                    </mat-form-field>
                </div>
                <button (click)="onSendClicked()"
                        *ngIf="!isLoading"
                        [disabled]="newMessage.length === 0 || disableNewMessage"
                        [matTooltipPosition]="'above'"
                        color="accent"
                        mat-icon-button
                        matTooltip="{{ 'Send' | translate }}">
                    <mat-icon>send</mat-icon>
                </button>
                <mat-spinner *ngIf="isLoading" [diameter]="24"></mat-spinner>
            </ng-container>
            <div *ngIf="newMessageDisableMessage"
                 class="new-messages-diabled-container">
                <mat-icon>info_outline</mat-icon>&nbsp;{{ newMessageDisableMessage | translate }}
            </div>
        </div>
    </div>
</div>
