<ng-content select="[left-menu]"></ng-content>
<div class="wrapper">
    <div class="container">
        <div class="width-wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <ng-content select="[footer]"></ng-content>
</div>