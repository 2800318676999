import { Injectable } from '@angular/core';
import { Utils } from './utils';
import { Conf } from '../../_conf';
import { KeyValueStorage } from './key-value-storage';

@Injectable()
export class ModelsStorage {

    //
    //
    // CONSTANTS
    //
    //

    public static STORAGE_PREFIX: string = 'modelsStorage_';
    public static STORAGE_TYPE: string = KeyValueStorage.STORE_MEMORY; // on stock des trucs pas sérialisables, du coup si on est tenté de mettre ca dans du local, ca va plus fonctionner ...

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _storage: KeyValueStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    /**
     * Récupère le Model correspondant à/aux identifiant(s) passés en paramètre. Retourne null si aucun Model ne correspond.
     * @param modelClass
     * @param identifierValues
     */
    public fromId(modelClass: any, identifierValues: number[], includeDeleted: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this.fromIds(modelClass, [identifierValues], includeDeleted)
                .then((results) => {
                    if (results.length) {
                        resolve(results[0].clone());
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Récupère les Model correspondant à la liste d'identifiant(s) passés en paramètre. Retourne un tableau vide si aucun ne correspond.
     * @param modelClass
     * @param identifierValues
     */
    public fromIds(modelClass: any, listOfIdentifierValues: any, includeDeleted: boolean = false): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const table = TableStore.get(modelClass, this._storage);
            if (table) {
                const results: any[] = [];
                for (let i = 0; i < listOfIdentifierValues.length; i++) {
                    const index = table.getRowIndex(listOfIdentifierValues[i]);
                    if (index >= 0) {
                        if (includeDeleted || !table.data[index].deleterId) {
                            results.push(table.data[index].clone());
                        }
                    }
                }
                resolve(results);
            } else {
                reject({message: 'Table Store not found', forParams: [modelClass, listOfIdentifierValues]});
            }
        });
    }

    /**
     * Récupère les Model correspondant aux critères de recherche passés en paramètre. Retourne un tableau vide si aucun ne correspond.
     * Un Model doit avoir les attributs/méthodes suivants :
     * - tableName
     * - getFields()
     * - fromStorageRow()
     * - clone()
     * @param modelClass
     */
    public select(modelClass: any, includeDeleted: boolean = false): ModelQuery {
        return new ModelQuery(this._storage, modelClass, includeDeleted);
    }

    /**
     * Met à jour les Model passés en paramètre qui existent déjà dans la base de données et insére ceux qui n'existent pas.
     * Un Model doit avoir les attributs/méthodes suivants :
     * - tableName
     * - getFields()
     * - getKeyFields()
     * - toJson()
     * - clone()
     * @param models
     */
    public save(models: any[], keepDeleted: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            const table = this.getTableStore(models);
            if (table) {
                for (let i = 0; i < models.length; i++) {
                    // les relations avec des tables de liaisons contenant des models supprimés n'est pas supporté en l'état
                    // du coup pour empêcher tout problème, on supprime les models qui sont marqués comme supprimés
                    // (c'est un vilain hack pas très sexy, mais au moins ca marche)
                    if (!keepDeleted && models[i].deleteDate) {
                        table.delete(models[i]);
                    } else {
                        table.updateOrAdd(models[i].clone());
                    }
                }
                table.save();
                resolve(table);
            } else {
                resolve(table);
            }
        });
    }

    /**
     * Supprime les Model passés en paramètre qui existent dans la base de données.
     * Un Model doit avoir les attributs/méthodes suivants :
     * - tableName
     * - getFields()
     * - getKeyFields()
     * - toJson()
     * @param models
     */
    public delete(models: any[]): Promise<any | void> {
        return new Promise<void>((resolve, reject): void => {
            const table = this.getTableStore(models);
            if (table) {
                for (let i = 0; i < models.length; i++) {
                    table.delete(models[i]);
                }
                table.save();
                resolve();
            } else {
                resolve();
            }
        });
    }

    /**
     * Supprime les models du store "model" dont la valeur de l'attribut "key" correspond à la valeur de "value".
     * @param model
     * @param key
     * @param value
     */
    public deleteOn(model: any, key: string, value: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.select(model).where(key).equals(value).get()
                .then((items: any[]) => {
                    this.delete(items)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public deleteMultipleOn(model: any, key: string, value: number[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.select(model).where(key).in(value).get()
                .then((items: any[]) => {
                    this.delete(items)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private getTableStore(model: any): TableStore {
        if (model.constructor.tableName) {
            return TableStore.get(model, this._storage);
        }
        if (model.length && model[0].constructor.tableName) {
            return TableStore.get(model[0], this._storage);
        }
        return null;
    }
}

//
//
// SUBCLASSESS
//
//

export class TableStore {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    public name: string;
    public modelClass: any;
    public index: any;

    //
    //
    // ATTRIBUTES
    //
    //
    public data: any[];
    public keyFields: string[];
    private _storage: KeyValueStorage;

    constructor() {
    }

    public static get(modelClass: any, storage: KeyValueStorage): TableStore {
        const name: string = TableStore.getName(modelClass);
        let store: TableStore = storage.get(name, null, ModelsStorage.STORAGE_TYPE);
        if (store) {
            return store;
        }

        store = new TableStore();
        store.name = name;
        store.modelClass = modelClass;
        store.index = {};
        store.data = [];
        store.keyFields = TableStore.getStatic(modelClass).getKeyFields();
        store._storage = storage;

        return store;
    }

    public static getName(modelClass: any): string {
        return ModelsStorage.STORAGE_PREFIX + TableStore.getStatic(modelClass).tableName;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    private static getStatic(modelClass: any): any {
        if (modelClass.tableName) {
            return modelClass;
        }

        return modelClass.constructor;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public save(): void {
        this._storage.set(this.name, this, ModelsStorage.STORAGE_TYPE);
    }

    public updateOrAdd(model: any): TableStore {
        const identifierValues: number[] = this.getIdentifierValues(model);
        let index: number = this.getRowIndex(identifierValues);
        if (index >= 0) {
            this.data[index] = model;
        } else {
            index = this.data.length;
            this.data.push(model);
            this.index[this.getIndexNameFromModel(model)] = index;
        }
        return this;
    }

    public delete(model: any): TableStore {
        const indexName = this.getIndexNameFromModel(model);
        const index: number = this.index[indexName];
        if (index >= 0) {
            this.data.splice(index, 1);
            delete this.index[indexName];
            this.reindex(index);
        }

        return this;
    }

    public reindex(fromRowIndex: number = 0): TableStore {
        for (let i = fromRowIndex; i < this.data.length; i++) {
            this.index[this.getIndexNameFromModel(this.data[i])] = i;
        }

        return this;
    }

    public getRowIndex(identifierValues: number[]): number {
        const key = this.getIndexNameFromValues(identifierValues);
        if (this.index[key] >= 0) {
            return this.index[key];
        }

        return -1;
    }

    public hasWhatWhoWhen(): boolean {
        const columns: string[] = TableStore.getStatic(this.modelClass).getFields();
        return columns.indexOf('createrId') >= 0 && columns.indexOf('updaterId') >= 0 && columns.indexOf('deleterId') >= 0;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private getIndexNameFromValues(identifierValues: any): string {
        if (typeof identifierValues === 'number') {
            identifierValues = [identifierValues];
        }
        return '' + identifierValues.join('_');
    }

    private getIndexNameFromModel(model: any): string {
        return this.getIndexNameFromValues(this.getIdentifierValues(model));
    }

    private getIdentifierValues(model: any): number[] {
        const keys: number[] = [];
        for (let i = 0; i < this.keyFields.length; i++) {
            keys.push(model[this.keyFields[i]]);
        }

        return keys;
    }
}

export class ModelQuery {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _storage: KeyValueStorage;
    private _classItem: any;
    private _clause: QueryClause;
    private _orderBy: any;
    private _includeDeleted: boolean;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        storage: any,
        modelClass: any,
        includeDeleted: boolean
    ) {
        this._storage = storage;
        this._classItem = modelClass;
        this._includeDeleted = includeDeleted;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public where(column: string, modelClass: any = null): QueryClause {
        if (modelClass === null) {
            modelClass = this._classItem;
        }

        this._clause = new QueryClause(this, this._storage, column, modelClass);
        return this._clause;
    }

    public orderBy(fieldName: string, direction: string = 'asc'): ModelQuery {
        this._orderBy = {
            column: fieldName,
            direction
        };
        return this;
    }

    /**
     * Exécute la requête et retourne la liste des Model correspondant aux critères de la requête. Retourne un tableau vide si aucun ne correspond.
     * @param howMany Le nombre de Model récupéré au maximum. Si howMany vaut 1, le résultat sera le Model directement (pas dans un tableau) et null si aucun ne correspond.
     * @param from L'index à partir duquel on souhaite récupérer les models (utile pour la pagination).
     */
    public get(howMany: number = -1, from: number = -1): Promise<any> {
        return new Promise((resolve, reject) => {
            const table = TableStore.get(this._classItem, this._storage);

            if (table) {
                let matchCounter: number = 0;
                const results: any[] = [];
                let loop: any = null;
                if (howMany > -1) {
                    loop = (i: number, dataLength: number, resultLength: number, howMany: number) => {
                        return i < dataLength && resultLength < howMany;
                    };
                } else {
                    loop = (i: number, dataLength: number, resultLength: number, howMany: number) => {
                        return i < dataLength;
                    };
                }

                let i: number = 0;
                while (loop(i, table.data.length, results.length, howMany)) {
                    if (!this._clause || this._clause.match(table.data[i])) {
                        if (this._includeDeleted || !table.hasWhatWhoWhen() || !table.data[i].deleterId) {
                            matchCounter++;
                            if (matchCounter > from) {
                                results.push(table.data[i].clone());
                            }
                        }
                    }
                    i++;
                }

                if (howMany === 1) {
                    if (results.length) {
                        resolve(results[0]);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(this.orderItems(results));
                }
            } else {
                reject({message: 'Table Store not found', forModelClass: this._classItem});
            }
            resolve(null);
        });
    }

    public setClause(clause: QueryClause): ModelQuery {
        this._clause = clause;
        return this;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private orderItems(models: any[]): any[] {
        if (!this._orderBy || models.length <= 1) {
            return models;
        }

        const columnType: string = typeof models[0][this._orderBy.column];
        if (columnType === 'number') {
            return this.orderItemsNumerically(models);
        } else if (columnType === 'string') {
            return this.orderItemsAlphabetically(models);
        }

        console.log('Unsupported ordering column type [' + columnType + '] of column [' + this._orderBy.column + ']');
        return models;
    }

    private orderItemsNumerically(models: any[]): any[] {
        models = models.sort((a: any, b: any) => {
            if (a[this._orderBy.column] < b[this._orderBy.column]) {
                return -1;
            }
            if (a[this._orderBy.column] > b[this._orderBy.column]) {
                return 1;
            }

            return 0;
        });

        if (this._orderBy.direction === 'desc') {
            return models.reverse();
        }

        return models;
    }

    private orderItemsAlphabetically(models: any[]): any[] {
        models = models.sort((a: any, b: any) => {
            const aString: string = a[this._orderBy.column] ? a[this._orderBy.column].toLowerCase() : '';
            const bString: string = b[this._orderBy.column] ? b[this._orderBy.column].toLowerCase() : '';
            if (aString < bString) {
                return -1;
            }
            if (aString > bString) {
                return 1;
            }

            return 0;
        });

        if (this._orderBy.direction === 'desc') {
            return models.reverse();
        }

        return models;
    }
}

export class QueryClause {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _builder: ModelQuery;
    private _clause: any;
    private _clauses: any = {
        items: [],
        groupingPattern: 'and'
    };
    private _storage: KeyValueStorage;

    private _operators: any = {
        '=': (val1: any, val2: any): boolean => {
            // null == undefined ...
            if (val1 === undefined) {
                val1 = null;
            }
            if (val2 === undefined) {
                val2 = null;
            }

            return val1 === val2;
        },
        '!=': (val1: any, val2: any): boolean => {
            return val1 !== val2;
        },
        '>': (val1: any, val2: any): boolean => {
            return val1 > val2;
        },
        '>=': (val1: any, val2: any): boolean => {
            return val1 >= val2;
        },
        '<': (val1: any, val2: any): boolean => {
            return val1 < val2;
        },
        '<=': (val1: any, val2: any): boolean => {
            return val1 <= val2;
        },
        'in': (val1: any, val2: any[]): boolean => {
            for (let i = 0; i < val2.length; i++) {
                if (val1 === val2[i]) {
                    return true;
                }
            }

            return false;
        },
        'notIn': (val1: any, val2: any): boolean => {
            for (let i = 0; i < val2.length; i++) {
                if (val1 === val2[i]) {
                    return false;
                }
            }

            return true;
        }
    };

    private _matchCache: any = {};

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        builder: ModelQuery,
        storage: any,
        column: string,
        modelClass: any
    ) {
        this._builder = builder;
        this._storage = storage;
        this._clause = this.newClause(column, modelClass);
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public equals(value: any, on: string = null): QueryClause {
        this._clause.operator = '=';
        this._clause.value = value;
        this._clause.on = on;
        return this;
    }

    public notEquals(value: any): QueryClause {
        this._clause.operator = '!=';
        this._clause.value = value;
        return this;
    }

    public greater(value: any): QueryClause {
        this._clause.operator = '>';
        this._clause.value = value;
        return this;
    }

    public greaterOrEqual(value: any): QueryClause {
        this._clause.operator = '>=';
        this._clause.value = value;
        return this;
    }

    public lower(value: any): QueryClause {
        this._clause.operator = '<';
        this._clause.value = value;
        return this;
    }

    public lowerOrEqual(value: any): QueryClause {
        this._clause.operator = '<=';
        this._clause.value = value;
        return this;
    }

    public in(values: any[]): QueryClause {
        this._clause.operator = 'in';
        this._clause.value = values;
        return this;
    }

    public notIn(values: any[]): QueryClause {
        this._clause.operator = 'notIn';
        this._clause.value = values;
        return this;
    }

    public and(column: string, modelClass: any = null): QueryClause {
        if (modelClass === null) {
            modelClass = this._clause.modelClass;
        }

        this.addClause();
        this._clause = this.newClause(column, modelClass);
        this._clauses.groupingPattern = 'and';
        return this;
    }

    public or(column: string, modelClass: any = null): QueryClause {
        if (modelClass === null) {
            modelClass = this._clause.modelClass;
        }

        this.addClause();
        this._clause = this.newClause(column, modelClass);
        this._clauses.groupingPattern = 'or';
        return this;
    }

    public orderBy(fieldName: string, direction: string = 'asc'): ModelQuery {
        this.addClause();
        return this._builder.setClause(this).orderBy(fieldName, direction);
    }

    public get(howMany: number = -1, from: number = -1): any {
        this.addClause();
        return this._builder.setClause(this).get(howMany, from);
    }

    public match(model: any): boolean {
        this._matchCache = {};
        this._matchCache[model.constructor.tableName] = [model];

        // si on veut des "and", quand on va parcourir les clauses
        // on ve retourner false si une clause ne match pas
        // donc si on arrive jusqu'à la fin de la méthode
        // c'est que toutes les clauses match
        // à l'inverse, si on veut des "or", quand on va parcourir les clauses
        // on va retourner true si une clause match
        // donc si on arrive jusqu'à la fin de la méthode
        // c'est que toutes les clauses ne match pas
        const matchByDefault: boolean = this._clauses.groupingPattern === 'and';

        for (let i = 0; i < this._clauses.items.length; i++) {
            const doesMatch: boolean = this.clauseMatch(this._clauses.items[i]);
            if (doesMatch && !matchByDefault) {
                return true;
            }
            if (!doesMatch && matchByDefault) {
                return false;
            }
        }

        return matchByDefault;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private newClause(column: string, modelClass: any, operator: string = '', value: any = '', on: string = null): any {
        const clause = {
            modelClass,
            column,
            operator,
            value,
            on
        };

        return clause;
    }

    private addClause(): void {
        this._clauses.items.push(this._clause);
    }

    private clauseMatch(clause: any): boolean {
        let data1: any[] = this.getMatchCache(clause.modelClass);
        let data2: any[] = null;
        const data2On: string = '';

        if (clause.on) {
            data2 = this.getMatchCache(clause.value);

            data1 = this.getMatchingItems(data1, data2, clause.operator, clause.column, clause.on);
            this._matchCache[clause.modelClass.tableName] = data1;

            data2 = this.getMatchingItems(data2, data1, this.revertOperator(clause.operator), clause.on, clause.column); // TODO operator !!!
            this._matchCache[clause.value.tableName] = data2;

            return data1.length > 0 && data2.length > 0;
        }

        data1 = this.getMatchingItems(data1, [{fakeColumn: clause.value}], clause.operator, clause.column, 'fakeColumn');
        this._matchCache[clause.modelClass.tableName] = data1;

        return data1.length > 0;
    }

    private getMatchCache(modelClass: any): any {
        if (!this._matchCache[modelClass.tableName]) {
            const table = TableStore.get(modelClass, this._storage);
            if (table) {
                this._matchCache[modelClass.tableName] = table.data;
            } else {
                console.log('StoreTable[' + modelClass.tableName + '] not found');
            }
        }

        return this._matchCache[modelClass.tableName];
    }

    private getMatchingItems(data1: any[], data2: any[], operator: string, data1Column, data2Column: string): any[] {
        const matchingItems: any[] = [];
        for (let i = 0; i < data1.length; i++) {
            for (let j = 0; j < data2.length; j++) {
                if (this._operators[operator](data1[i][data1Column], data2[j][data2Column])) {
                    matchingItems.push(data1[i].clone());
                    j = data2.length;
                }
            }
        }

        return matchingItems;
    }

    private revertOperator(operator: string): string {
        if (operator === '<') {
            return '>';
        }
        if (operator === '<=') {
            return '>=';
        }
        if (operator === '>') {
            return '<';
        }
        if (operator === '>=') {
            return '<=';
        }

        return operator;
    }
}
