import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.css'],
})
export class RichTextEditorComponent implements OnInit {

    public quillConfig = {
        toolbar: [
            ['bold', 'italic'],
            [{list: 'ordered'}, {list: 'bullet'}],
            [{script: 'sub'}, {script: 'super'}],
            ['link', 'video'],
            ['clean'],
        ],
    };

    public styles = {
        width: '100%',
        minHeight: '85px',
        fontSize: '14px',
    };

    @Input() public control: FormControl;
    @Input() public id: string;
    @Input() public maxFieldLength: number;
    @Input() public placeholder: string;
    @Output() public changeFunction: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    public ngOnInit() {
        this.control = this.control || new FormControl();
    }

    public onContentChanged(event): void {
        this.changeFunction.emit();
    }

}
