<div *ngIf="imageConf.current || previewImage" class="image-container">
    <div class="placeholder">{{ placeholder | translate }}</div>
    <div (click)="onFocus()" class="preview-container">
        <div class="preview-image">
            <img *ngIf="imageConf.current && !previewImage && imageHost"
                 alt="{{ placeholder | translate }}"
                 src="{{ imageHost + imageConf.current }}"/>
            <img *ngIf="previewImage"
                 alt="{{ placeholder | translate }}"
                 src="{{ previewImage }}"/>
            <button (click)="onRemoveImageClicked()"
                    [disabled]="disabled"
                    [matTooltipPosition]="'above'"
                    class="remove-button"
                    mat-icon-button
                    matTooltip="{{ 'Remove_image' | translate }}">
                <mat-icon color="warn">remove_circle</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="!imageFormControl.invalid"
         class="hints-container">
        <div *ngIf="hintLeft"
             class="preview-hint-left">
            {{ hintLeft }}
        </div>
        <div *ngIf="hint"
             class="preview-hint">
            {{ hint }}
        </div>
    </div>
    <div *ngIf="imageFormControl.hasError('extension')"
         class="preview-error">
        {{ 'Image_extension_error' | translate:{extension: allowedExtensions.join(', ')} }}
    </div>
    <div *ngIf="imageFormControl.hasError('size')"
         class="preview-error">
        {{ 'Image_size_error' | translate:{size: maxFileSize + ' Mo'} }}
    </div>
</div>
<input #fileInput (change)="onFileSelectionChange($event)"
       accept="{{ '.' + allowedExtensions.join(',.') }}"
       class="file-input"
       tabIndex="-1"
       type="file">

<mat-form-field *ngIf="!imageConf.current && !previewImage"
                [hintLabel]="hintLeft"
                color="accent">
    <input #textInput (click)="onFocus()"
           (keyup)="onTextChange()"
           [formControl]="imageFormControl"
           matInput
           maxlength="1"
           placeholder="{{ placeholder | translate }}"
           type="text">
    <mat-hint *ngIf="hint" align="end" class="hint-label">{{ hint }}</mat-hint>
    <mat-error>{{ 'Image_extension_error' | translate:{extension: allowedExtensions.join(', ')} }}</mat-error>
    <mat-error
        *ngIf="imageFormControl.hasError('size')">{{ 'Image_size_error' | translate:{size: maxFileSize + ' Mo'} }}</mat-error>
    <mat-error *ngIf="imageFormControl.invalid && _required">{{ 'No_logo_validation' | translate }}</mat-error>
</mat-form-field>
<div class="add-button-container">
    <button (click)="onFocus()"
            *ngIf="!imageConf.current && !previewImage"
            [disabled]="disabled"
            mat-mini-fab
            matTooltip="{{ 'Add_file' | translate }}">
        <mat-icon>add</mat-icon>
    </button>
</div>
