import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export const TAG_SELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TagsTagSelectComponent),
    multi: true
};

// doc pour le ngModel : http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'tags-tag-select',
    providers: [TAG_SELECT_VALUE_ACCESSOR],
    styleUrls: ['./tag-select.scss'],
    templateUrl: './tag-select.html'
})
export class TagsTagSelectComponent implements AfterViewInit, ControlValueAccessor {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public tags: TagData[] = [];
    @Input() public values: number[] = [];
    @Input() public disabled: boolean = false;
    @Output() public valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output('select') public onSelectEvent: EventEmitter<TagData> = new EventEmitter();
    @Output('unselect') public onUnselectEvent: EventEmitter<TagData> = new EventEmitter();

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    public writeValue(value: any): void {
        if (value) {
            this.values = value;
        } else {
            this.values = [];
        }
    }

    @HostListener('valueChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public isSelected(tag: TagData): boolean {
        return this.getSelectionIndex(tag) >= 0;
    }

    public onTagClicked(tag: TagData): void {
        if (this.disabled) {
            return;
        }

        const index: number = this.getSelectionIndex(tag);
        if (index >= 0) {
            this.values.splice(index, 1);
            this.onUnselect(tag);
        } else {
            this.values.push(tag.id);
            this.onSelect(tag);
        }

        this.onChange(this.values);
    }

    public onSelect(tag: TagData): void {
        this.onSelectEvent.emit(tag);
    }

    public onUnselect(tag: TagData): void {
        this.onUnselectEvent.emit(tag);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private getSelectionIndex(tag: TagData): number {
        for (let i = 0; i < this.values.length; i++) {
            if (this.values[i] === tag.id) {
                return i;
            }
        }

        return -1;
    }
}

export interface TagData {

    id: number;
    text: string;
    colorHex: string;
    textColorHex: string;

}
