import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'data-list',
    providers: [],
    styleUrls: ['./data-list.scss'],
    templateUrl: './data-list.html'
})
export class DataListComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input()
    public headers: any[];
    @Input()
    public rowsData: string[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRowClicked(link: string, params: any = null): void {
        if (params) {
            this._router.navigateByUrl(link, params);
        } else {
            this._router.navigateByUrl(link);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
