import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// inspiré de : https://github.com/ngx-translate/core/issues/223#issuecomment-333335132

@Pipe({name: 'translateCut'})
export class TranslateCutPipe implements PipeTransform {
    constructor(
        private _translater: TranslateService
    ) {
    }

    public transform(str: string, params: TranslatecutParams) {
        if (!params.separator) {
            params.separator = '||';
        }

        const translated: string = this._translater.instant(str);

        return translated.split(params.separator)[Number(params.index)];
    }
}

export interface TranslatecutParams {
    separator: string;
    index: number;
}
