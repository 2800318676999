<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="backMenu"
            [pageTitle]="'Project_details_sheet' | translate"
            header>
    </header>
    <error-401 *ngIf="!is404 && !rights.SEE"></error-401>
    <error-404 *ngIf="is404"></error-404>
    <ng-container *ngIf="rights.SEE">
        <div class="columns-container">
            <div class="left">
                <div class="title-box">
                    <div>
                        <div class="title">{{ project.name }}</div>
                    </div>
                    <button (click)="onFavoriteClicked()"
                            *ngIf="rights.ADD_TO_FAVORITES"
                            [matTooltip]="(isFavorite ? 'Remove_from_favorites' : 'Investor_welcome_section1') | translate"
                            class="favorite-button"
                            mat-icon-button>
                        <ng-container *ngIf="!isFavoriteLoading">
                            <mat-icon *ngIf="isFavorite">star</mat-icon>
                            <mat-icon *ngIf="!isFavorite">star_outline</mat-icon>
                        </ng-container>
                        <mat-spinner *ngIf="isFavoriteLoading"
                                     [diameter]="24"
                                     color="accent">
                        </mat-spinner>
                    </button>
                </div>
                <module-content>
                    <div class="logo-container">
                        <div *ngIf="!logo"></div>
                        <img *ngIf="logo"
                             [src]="logo"
                             class="logo"/>
                        <div *ngIf="rights.SEE_METAS_INFORMATION"
                             class="metas-information">
                            {{ 'Last_modified' | translate }}:&nbsp;{{ project.updateDate | dateClosest }}
                        </div>
                    </div>
                    <div class="highlights">
                        <div
                            *ngIf="(project.academicInstitution1Id && project.academicInstitution1Id != INSTUTION_ID_NONE) || (project.academicInstitution2Id && project.academicInstitution2Id != INSTUTION_ID_NONE)"
                            class="line">
                            <div class="title">
                                {{ 'Academic_link' | translate }}:
                            </div>
                            <div class="text">
                                <ng-container
                                    *ngIf="project.academicInstitution1Id && project.academicInstitution1Id != INSTUTION_ID_NONE">{{ academicInstitution1 }}</ng-container>
                                <ng-container
                                    *ngIf="(project.academicInstitution1Id && project.academicInstitution1Id != INSTUTION_ID_NONE) && (project.academicInstitution2Id && project.academicInstitution2Id != INSTUTION_ID_NONE)">
                                    ,&nbsp;
                                </ng-container>
                                <ng-container
                                    *ngIf="project.academicInstitution2Id && project.academicInstitution2Id != INSTUTION_ID_NONE">{{ academicInstitution2 }}</ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="rights.SEE_TTO_INFORMATION">
                            <div class="line">
                                <div class="title">{{ 'Intellectual_prorerty' | translate }}:</div>
                                <div class="text">
                                    <span
                                        *ngIf="project.isTTOAgreementAccepted">{{ 'TTO_agreement_accepted' | translate }}</span>
                                    <span
                                        *ngIf="project.isInstitutionLicenseOwned">{{ 'License_from_academic_institution' | translate }}</span>
                                </div>
                            </div>
                            <div *ngIf="project.TTOContact"
                                 class="line">
                                <div class="title">{{ 'Contact_at_TTO' | translate }}:</div>
                                <div class="text">{{ project.TTOContact }}</div>
                            </div>
                        </ng-container>

                        <div *ngIf="project.incorporationYear"
                             class="line">
                            <div
                                class="title">{{ (project.isCompanyIncorporated ? 'Inscorporated_since' : 'Targeted_year_of_incorporation') | translate }}
                                :
                            </div>
                            <div class="text">{{ project.incorporationYear }}</div>
                        </div>
                        <div *ngIf="project.projectTradeTypeId"
                             class="line">
                            <div class="title">{{ 'Project_trade_type' | translate }}:</div>
                            <div
                                class="text">{{ (project.projectTradeTypeId === 1 ? 'Public_company' : 'Private_company') | translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="project.pipeline"
                         class="content">
                        <div class="title">{{ 'Pipeline' | translate }}</div>
                        <extensible-p [max]="P_MAX_LENGTH"
                                      [text]="project.pipeline">
                        </extensible-p>
                    </div>
                    <div *ngIf="files.length"
                         class="content">
                        <div class="title">{{ 'Documents' | translate }}</div>
                        <files-files [(files)]="files"
                                     [currentUser]="currentUser.user"
                                     [downloadApi]="'projects/' + project.id + '/files'"
                                     [editionLevel]="FILES_EDITION_LEVEL"
                                     [fileGroupId]="project.fileGroupId"
                                     [hideChangedColumn]="true"
                                     [hideHeadersWhenEmpty]="true"
                                     [hideHeaders]="true"
                                     [hideSizeColumn]="false"
                                     [hideTypeColumn]="true"
                                     [isDisabled]="isLoading"
                                     [isFoldersEnabled]="false">
                        </files-files>
                    </div>
                </module-content>

                <div *ngFor="let singleProjectCase of projectCaseView; let i = index">
                    <ng-container *ngIf="shouldDisplayProjectCase(singleProjectCase)">
                        <projects-view-project-case [projectCase]="singleProjectCase">
                        </projects-view-project-case>
                    </ng-container>
                </div>

            </div>
            <div class="right">
                <div class="illustration-wrapper">
                    <img [src]="illustration ? illustration : conf.assetsUrl + 'img/projects/default-illustration-2.jpg'"
                         class="illustration"/>
                    <button (click)="onEditIllustrationClicked()"
                            *ngIf="rights.EDIT"
                            [disabled]="isLoading"
                            class="edit-illustration-button"
                            mat-raised-button>
                        <mat-icon>image</mat-icon>&nbsp;{{ 'Change_illustration' | translate }}
                    </button>
                </div>
                <input #illustrationInput
                       (change)="onFileSelectionChange($event)"
                       accept="{{ '.' + projectsProvider.ILLUSTRATION_FILE_TYPES.join(',.') }}"
                       class="illustration-input"
                       tabIndex="-1"
                       type="file">
                <div class="important-box">
                    <ng-container *ngIf="project.isRaisingFunds">
                        <div class="title">{{ 'Currently_raising_funds' | translate }}</div>
                        <div class="content">
                            <div *ngIf="fundingRound">{{ fundingRound }}</div>
                            <div *ngIf="project.raisingFundsAmount">
                                &nbsp;{{ (project.raisingFundsAmount / 100) | currency:'CHF ' : 'symbol' : '1.0-0'  }}</div>
                        </div>
                        <div *ngIf="project.dateOfClosingRound && displayDateOfClosing()"
                             class="legend">
                            <span>{{ 'Date_of_closing_round' | translate }}:&nbsp;</span>
                            <span>{{ project.dateOfClosingRound | dateClosest:'FjY' }}</span>
                        </div>
                        <div *ngIf="project.isLeadInvestorIdentified"
                             class="legend">
                            <span>{{ 'Lead_investor_identified' | translate }}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!project.isRaisingFunds">
                        <div class="title">{{ 'Currently_not_raising_funds' | translate }}</div>
                        <ng-container *ngIf="upcomingFundingItems.length">
                            <div class="legend margin-top-32">{{ 'Upcoming_funding_round' | translate }}:</div>
                            <div class="legend">
                                <span>{{ upcomingFundingItems.join(', ') }}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <mat-accordion *ngIf="project.isSeekingPartnerships"
                               [hideToggle]="true"
                               class="collab-box">
                    <mat-expansion-panel (closed)="isOpen0 = false"
                                         (opened)="isOpen0 = true"
                                         *ngIf="project.isSeekingPartnerships"
                                         [disabled]="!project.partnershipTerms">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'Seeking_partnership__alt' | translate }}
                            </mat-panel-title>
                            <mat-panel-description *ngIf="project.partnershipTerms">
                                <mat-icon>{{ isOpen0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>{{ project.partnershipTerms }}</p>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf=" project.isOfferingLicensing"
                               [hideToggle]="true"
                               class="collab-box">
                    <mat-expansion-panel (closed)="isOpen1 = false"
                                         (opened)="isOpen1 = true"
                                         *ngIf="project.isOfferingLicensing"
                                         [disabled]="!project.licensingOffer">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'Seeking_outlicense' | translate }}
                            </mat-panel-title>
                            <mat-panel-description *ngIf="project.licensingOffer">
                                <mat-icon>{{ isOpen1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>{{ project.licensingOffer }}</p>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="contact-box">
                    <div class="left">
                        <div class="thin">{{ 'Main_contact' | translate }}</div>
                        <div>
                            <span>{{ project?.contactFirstName }}</span>&nbsp;<span>{{ project?.contactLastName }}</span>
                        </div>
                        <div *ngIf="project.contactAddress">{{ project.contactAddress }}</div>
                    </div>
                    <div class="right">
                        <button (click)="onContactMailClicked()"
                                *ngIf="project.contactEmail"
                                mat-button>
                            <mat-icon>email</mat-icon>
                            {{ project.contactEmail }}
                        </button>
                        <button (click)="onContactPhoneClicked()"
                                *ngIf="project.contactPhone"
                                mat-button>
                            <mat-icon>phone</mat-icon>
                            {{ project.contactPhone }}
                        </button>
                        <button (click)="onContactWebsiteClicked()"
                                *ngIf="project.contactWebsite"
                                mat-button>
                            <mat-icon>open_in_new</mat-icon>
                            {{ project.contactWebsite }}
                        </button>
                    </div>
                </div>
                <module-content>
                    <div *ngIf="teamMembers.length || boardMembers.length"
                         class="columns-content">
                        <div *ngIf="teamMembers.length"
                             class="content">
                            <div class="title">{{ 'Team' | translate }}</div>
                            <ul>
                                <li *ngFor="let member of teamMembers">{{ member.firstName + ' ' + member.lastName }}
                                    <span *ngIf="member.teamFunction"
                                          class="legend"><br/>{{ member.teamFunction }}</span></li>
                            </ul>
                        </div>
                        <div *ngIf="boardMembers.length"
                             class="content">
                            <div class="title">{{ 'Advisory_board_alt1' | translate }}</div>
                            <ul>
                                <li *ngFor="let member of boardMembers">{{ member.firstName + ' ' + member.lastName }}</li>
                            </ul>
                        </div>
                        <div *ngIf="directorsMembers.length"
                             class="content">
                            <div class="title">{{ 'Board_of_directors' | translate }}</div>
                            <ul>
                                <li *ngFor="let member of directorsMembers">{{ member.firstName + ' ' + member.lastName }}</li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="teamPicture"
                         class="teamPicture">
                        <img [src]="teamPicture"/>
                    </div>
                    <div *ngIf="prizes.length || pastFundings.length"
                         class="columns-content">
                        <div *ngIf="prizes.length"
                             class="content">
                            <div class="title">{{ 'Prizes' | translate }}</div>
                            <ul>
                                <li *ngFor="let prize of prizes">{{ prize.name }}</li>
                            </ul>
                        </div>
                        <div *ngIf="pastFundings.length"
                             class="content">
                            <div class="title">{{ 'Past_and_current_funding' | translate }}</div>
                            <ul>
                                <li *ngFor="let funding of pastFundings">{{ funding.source }}<span
                                    *ngIf="funding.amount"> ({{ (funding.amount / 100) | currency:'CHF ' : 'symbol' : '1.0-0' }}
                                    )</span></li>
                            </ul>
                        </div>
                    </div>
                </module-content>
            </div>
        </div>
        <main-action (onAction)="onEditClicked()"
                     *ngIf="rights.SEE_EDIT_FORM"
                     [disabled]="!rights.EDIT"
                     [displayMode]="'fab'"
                     [icon]="'edit'"
                     [title]="'Edit' | translate">
        </main-action>
        <main-action (onAction)="onListUsersClicked()"
                     *ngIf="rights.LIST_PROJECT_MEMBERSHIPS"
                     [bottom]="96"
                     [color]="null"
                     [displayMode]="'fab'"
                     [icon]="'people'"
                     [title]="'Users_list' | translate">
        </main-action>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
