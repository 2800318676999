import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Utils {

    //
    //
    // CONSTANTS
    //
    //

    public EMAIL_REGEX: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    public SWISS_PHONE_NUMBER_REGEX: RegExp = /^(0041|041|\+41|\+\+41|41)?(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/;
    public INTERNATIONAL_PHONE_NUMBER_REGEX: RegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/; // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
    public URL_REGEX: RegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; // https://regexr.com/3e6m0

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService
    ) {

    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public removeDuplicates(arr: any[]): any[] {
        const uniqueArr = [];
        for (let i = 0; i < arr.length; i++) {
            if (uniqueArr.indexOf(arr[i]) < 0) {
                uniqueArr.push(arr[i]);
            }
        }
        return uniqueArr;
    }

    public findIn(key: any, items: any[], keyName: string = 'id'): any {
        for (let i = 0; i < items.length; i++) {
            if (items[i][keyName] === key) {
                return items[i];
            }
        }
        return null;
    }

    public findManyIn(keys: any[], items: any[], keyName: string = 'id'): any[] {
        const match: any[] = [];
        for (let i = 0; i < items.length; i++) {
            let found: boolean = false;
            for (let j = 0; j < keys.length && !found; j++) {
                if (items[i][keyName] == keys[j]) {
                    found = true;
                    match.push(items[i]);
                }
            }
        }

        return match;
    }

    public getKeyValues(items: any[], keyName: string = 'id', removeDuplicate: boolean = true, filterNull: boolean = true): any[] {
        let values = [];
        for (let i = 0; i < items.length; i++) {
            if (!filterNull || items[i][keyName]) {
                values.push(items[i][keyName]);
            }
        }
        if (removeDuplicate) {
            values = this.removeDuplicates(values);
        }
        return values;
    }

    public hasOneOfs(items: any[], searchItems: any[]): boolean {
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < searchItems.length; j++) {
                if (items[i] === searchItems[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    public normalizeString(str: string): string {
        // inspiré de : https://stackoverflow.com/a/37511463
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim();
    }

    public sortRow(a: string | number, b: string | number, nullAfter: boolean = true): number {
        if (a) {
            if (b) {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            }

            return -1;
        }

        if (b) {
            return 1;
        }

        return 0;
    }

    public nowISO(): string {
        return (new Date()).toISOString();
        // toISOString convertit tout seul la date en UTC :o)
        /*
        let now = new Date();
        now.setMinutes(now.getMinutes() + now.getTimezoneOffset());
        return now.toISOString();
        */
    }

    public nowTimestamp(): number {
        const now = new Date();
        const timestamp = Math.round(now.getTime() / 1000);
        const timezoneOffset = now.getTimezoneOffset() * 60;
        return timestamp - timezoneOffset;
    }

    public ISODateToYmdHis(date: string): string {
        return date.replace('T', ' ').substr(0, 19);
    }

    public DateToYmdHis(date: Date, adjustTimezone: boolean = true): string {
        if (adjustTimezone) {
            return this.ISODateToYmdHis(date.toISOString());
        }

        const newDate: Date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));

        return this.ISODateToYmdHis(newDate.toISOString());
    }

    public YmdHisToDate(date: string): Date {
        // on reçoit la date au format YYYY-mm-dd HH:ii:ss (iso8601)
        // mais safari n'arrive pas à parser correctement ce format...
        // let date: Date = new Date(dateStr);
        const parts: string[] = date.split(' ');
        if (parts.length !== 2) {
            return null;
        }
        const dateParts: string[] = parts[0].split('-');
        const timeParts: string[] = parts[1].split(':');
        if (dateParts.length !== 3 || timeParts.length !== 3) {
            return null;
        }
        return new Date(
            parseInt(dateParts[0], 10),
            parseInt(dateParts[1], 10) - 1,
            parseInt(dateParts[2], 10),
            parseInt(timeParts[0], 10),
            parseInt(timeParts[1], 10),
            parseInt(timeParts[2], 10),
            0
        );
    }

    public dateAtMidnight(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    public addTrailingChar(str: string, char: string, targetStrLength: number): string {
        const toAddCount: number = targetStrLength - str.length;
        for (let i = 0; i < toAddCount; i++) {
            str = char + str;
        }

        return str;
    }

    public formatDate(dateStr: string, format: string, adjustTimezone: boolean = true): string {
        // TODO finir ca ...

        const oneDay: number = 1000 * 60 * 60 * 24;
        const months: string[] = [
            'January', 'February', 'March',
            'April', 'May', 'June',
            'July', 'August', 'September',
            'October', 'November', 'December'
        ];
        const days: string[] = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday',
            'Thursday', 'Friday', 'Saturday'
        ];

        if (!dateStr || dateStr.length === 0) {
            return '';
        }

        const date: Date = this.YmdHisToDate(dateStr);
        if (!date) {
            return '';
        }
        let dateTimestamp: number = date.getTime();
        if (adjustTimezone) {
            dateTimestamp = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        }

        if (format === 'closest' || format === 'closest-short') {
            // examples (closest) :
            //  - DateFormat_FjY:           December 23, 2021
            //  - DateFormat_NextlG:        Next Monday, at 7:33
            //  - DateFormat_TomorrowG:     Tomorrow, at 10:51
            //  - DateFormat_TodayG:        Today, at 18:23
            //  - DateFormat_YesterdayG:    Yesterday, at 9:21
            //  - DateFormat_LastlG:        Last Tuesday, at 15:05
            //  - DateFormat_FjY:           August 4, 2017
            // examples (closest-short) :
            //  - DateFormat_mdY:               12.23.2017
            //  - DateFormat_Tomorrow:          Tomorrow
            //  - DateFormat_Hi:                18:23
            //  - DateFormat_Yesterday:         Yesterday
            //  - DateFormat_mdY:               08.04.2017

            const yesterday: Date = new Date();
            yesterday.setHours(0);
            yesterday.setMinutes(0);
            yesterday.setSeconds(0);
            yesterday.setMilliseconds(0);
            const tomorrow: Date = new Date();
            tomorrow.setHours(23);
            tomorrow.setMinutes(59);
            tomorrow.setSeconds(59);
            tomorrow.setMilliseconds(999);
            const yesterdayTimestamp: number = yesterday.getTime();
            const tomorrowTimestamp: number = tomorrow.getTime();

            if (dateTimestamp < yesterdayTimestamp) {
                // on va chercher dans le passé ...
                if (dateTimestamp >= (yesterdayTimestamp - oneDay)) {
                    if (format === 'closest') {
                        return this._translater.instant('DateFormat_YesterdayG', {
                            hours: date.getHours(),
                            minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                        });
                    } else if (format === 'closest-short') {
                        return this._translater.instant('DateFormat_Yesterday');
                    }
                } else if (dateTimestamp >= (yesterdayTimestamp - (6 * oneDay))) {
                    if (format === 'closest') {
                        return this._translater.instant('DateFormat_LastlG', {
                            weekDay: this._translater.instant(days[date.getDay()]),
                            hours: date.getHours(),
                            minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                        });
                    } else if (format === 'closest-short') {
                        return this._translater.instant('DateFormat_mdY', {
                            year: date.getFullYear(),
                            month: this.addTrailingChar('' + (date.getMonth() + 1), '0', 2),
                            day: this.addTrailingChar('' + (date.getDate() + 1), '0', 2)
                        });
                    }
                }
            } else if (dateTimestamp > tomorrowTimestamp) {
                // on va chercher dans le futur ...
                if (dateTimestamp <= (tomorrowTimestamp + oneDay)) {
                    if (format === 'closest') {
                        return this._translater.instant('DateFormat_TomorrowG', {
                            hours: date.getHours(),
                            minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                        });
                    } else if (format === 'closest-short') {
                        return this._translater.instant('DateFormat_Tomorrow');
                    }
                } else if (dateTimestamp <= (tomorrowTimestamp + (6 * oneDay))) {
                    if (format === 'closest') {
                        return this._translater.instant('DateFormat_NextlG', {
                            weekDay: this._translater.instant(days[date.getDay()]),
                            hours: date.getHours(),
                            minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                        });
                    } else if (format === 'closest-short') {
                        return this._translater.instant('DateFormat_mdY', {
                            year: date.getFullYear(),
                            month: this.addTrailingChar('' + (date.getMonth() + 1), '0', 2),
                            day: this.addTrailingChar('' + (date.getDate() + 1), '0', 2)
                        });
                    }
                }
            } else {
                // c'est aujourd'hui ...
                if (format === 'closest') {
                    return this._translater.instant('DateFormat_TodayG', {
                        hours: date.getHours(),
                        minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                    });
                } else if (format === 'closest-short') {
                    return this._translater.instant('DateFormat_Hi', {
                        hours: this.addTrailingChar('' + date.getHours(), '0', 2),
                        minutes: this.addTrailingChar('' + date.getMinutes(), '0', 2)
                    });
                }
            }

            if (format === 'closest') {
                return this._translater.instant('DateFormat_FjY', {
                    year: date.getFullYear(),
                    month: this._translater.instant(months[date.getMonth()]),
                    day: date.getDate()
                });
            } else if (format === 'closest-short') {
                return this._translater.instant('DateFormat_mdY', {
                    year: date.getFullYear(),
                    month: this.addTrailingChar('' + (date.getMonth() + 1), '0', 2),
                    day: this.addTrailingChar('' + (date.getDate() + 1), '0', 2)
                });
            }
        } else if (format === 'full-date') {
            return this._translater.instant('DateFormat_lFdY', {
                weekDay: this._translater.instant(days[date.getDay()]),
                day: date.getDate(),
                month: this._translater.instant(months[date.getMonth()]),
                year: date.getFullYear()
            });
        } else if (format === 'FjY') {
            return this._translater.instant('DateFormat_FjY', {
                year: date.getFullYear(),
                month: this._translater.instant(months[date.getMonth()]),
                day: date.getDate()
            });
        } else if (format === 'mdY') {
            return this._translater.instant('DateFormat_mdY', {
                year: date.getFullYear(),
                month: this.addTrailingChar('' + (date.getMonth() + 1), '0', 2),
                day: this.addTrailingChar('' + date.getDate(), '0', 2)
            });
        }

        return dateStr;
    }

    public formatNumber(num: number, decimalsCount: number): string {
        let formated = num.toFixed(decimalsCount);
        const parts = ('' + formated).split('.');
        formated = parts[0].replace(/(.)(?=(\d{3})+$)/g, "$1'");
        if (parts.length > 1) {
            formated += '.' + parts[1];
        }
        return formated;
    }

    public randomString(length: number, buffer: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'): string {
        let str: string = '';
        for (let i = 0; i < length; i++) {
            str += buffer.charAt(Math.floor(Math.random() * buffer.length));
        }

        return str;
    }

    public convertToHours(minutes: number): string {
        const hours: number = parseInt('' + (minutes / 60));
        const remainingMinutes: number = minutes % 60;

        return this._translater.instant(hours > 1 ? 'x_hours' : 'x_hour', {x: hours}) +
            ' ' +
            this._translater.instant(remainingMinutes > 1 ? 'x_minutes' : 'x_minute', {x: remainingMinutes});
    }

    /**
     * Return an object { isValid: true } if validation succeeds. Return an object { isValid: false, code: 745, message: <the error message ready to be displayed>} otherwise.
     * Validations supported (examples) :
     * - type[string]:      'nullable|noTrim|min:5|max:5|in:salut,hello,guten tag,hola'
     * - type[int]:         'nullable|min:-3|max:1200|in:3,4,500'
     * - type[decimal]:     'nullable|min:-2.1|max:22.0|in:1.0,1.1,1.2'
     * - type[url]:         'nullable'
     * @param data
     * @param type
     * @param rulesString
     * @param fieldName
     */
    public validateFormField(data: any, type: string, rulesString: string, fieldName: string): any {
        // TODO remplacer une bonne partie de ce cheni par validator.js : https://github.com/chriso/validator.js
        // le jour où je saurais comment en faire un module angular ...

        const validReturn = {isValid: true};

        const error: any = (messageKey: string, params: any[]): any => {
            const message: string = this._translater.instant(messageKey, params);
            return {isValid: false, code: 745, message};
        };

        const has: any = (key: string, rules: string[]): boolean => {
            for (let i = 0; i < rules.length; i++) {
                if (rules[i].split(':')[0] === key) {
                    return true;
                }
            }
            return false;
        };

        const rules: string[] = rulesString.split('|');

        const canBeNull: boolean = has('nullable', rules);
        if (canBeNull && (data === null || data === undefined)) {
            return validReturn;
        }

        //
        // STRING
        //
        if (type === 'string') {

            if (data && !has('noTrim', rules)) {
                data = data.trim();
            }

            for (let i = 0; i < rules.length; i++) {
                const rule: string[] = rules[i].split(':');
                if (rule[0] === 'min') {
                    if (!data || data.length < parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_y_characters_at_min', {fieldName, min: rule[1]});
                    }
                } else if (rule[0] === 'max') {
                    if (!data || data.length > parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_y_characters_at_max', {fieldName, max: rule[1]});
                    }
                } else if (rule[0] === 'in') {
                    const items: string[] = rules[1].split(',');
                    let isIn: boolean = false;
                    for (let j = 0; j < items.length && !isIn; j++) {
                        isIn = items[j] === data;
                    }
                    if (!isIn) {
                        return error('The_field_x_must_be_one_of_y', {fieldName, items: items.join(', ')});
                    }
                }
            }
        }

            //
            // INTEGER
        //
        else if (type === 'integer') {
            const int: number = parseInt(data, 10);
            if (isNaN(int)) {
                return error('The_field_x_must_be_an_integer', {fieldName});
            }

            for (let i = 0; i < rules.length; i++) {
                const rule: string[] = rules[i].split(':');
                if (rule[0] === 'min') {
                    if (int < parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_greater_than_y', {fieldName, min: rule[1]});
                    }
                } else if (rule[0] === 'max') {
                    if (int > parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_lower_than_y', {fieldName, max: rule[1]});
                    }
                } else if (rule[0] === 'in') {
                    const items: string[] = rules[1].split(',');
                    let isIn: boolean = false;
                    for (let j = 0; j < items.length && !isIn; j++) {
                        isIn = parseInt(items[j], 10) === int;
                    }
                    if (!isIn) {
                        return error('The_field_x_must_be_one_of_y', {fieldName, items: items.join(', ')});
                    }
                }
            }
        }

            //
            // DECIMAL
        //
        else if (type === 'decimal') {
            const float: number = parseFloat(data);
            if (isNaN(float)) {
                return error('The_field_x_must_be_a_decimal', {fieldName});
            }

            for (let i = 0; i < rules.length; i++) {
                const rule: string[] = rules[i].split(':');
                if (rule[0] === 'min') {
                    if (float < parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_greater_than_y', {fieldName, min: rule[1]});
                    }
                } else if (rule[0] === 'max') {
                    if (float > parseInt(rule[1], 10)) {
                        return error('The_field_x_must_be_lower_than_y', {fieldName, max: rule[1]});
                    }
                } else if (rule[0] === 'in') {
                    const items: string[] = rules[1].split(',');
                    let isIn: boolean = false;
                    for (let j = 0; j < items.length && !isIn; j++) {
                        isIn = parseFloat(items[j]) === float;
                    }
                    if (!isIn) {
                        return error('The_field_x_must_be_one_of_y', {fieldName, items: items.join(', ')});
                    }
                }
            }
        }

            //
            // URL
        //
        else if (type === 'url') {
            // http://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
            // surement pas la meilleure regex du monde pour ca, mais ca va pour nos besoins
            const expression: string = '[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?';
            const regex: RegExp = new RegExp(expression, 'gi');
            const match: string[] = data.match(regex);
            if (match.length !== 1 || match[0] !== data) {
                return error('The_field_x_must_be_a_valid_url', {fieldName});
            }
        }

        return validReturn;
    }

    public buildTranslationParam(translations: any[]): any {
        const param: any = {};
        for (let i = 0; i < translations.length; i++) {
            const item: any = {};
            for (const key in translations[i].translation) {
                if (translations[i].translation.hasOwnProperty(key)) {
                    item[key] = translations[i].translation[key];
                }
            }
            param[translations[i].lang.code] = item;
        }

        return param;
    }

    public truncateText(text: string, maxChars: number, options: any = {}): string {
        if (maxChars < 5) {
            maxChars = 5;
        }
        if (options.ellipsize === undefined) {
            options.ellipsize = false;
        }
        if (options.ellipsisPosition === undefined) {
            options.ellipsisPosition = 'end';
        }

        if (text.length <= maxChars) {
            return text;
        }

        if (options.ellipsize) {
            if (options.ellipsisPosition === 'center') {
                const maxCharsHalf: number = parseInt('' + (maxChars / 2), 10);
                return text.substr(0, maxCharsHalf - 2) + ' ... ' + text.substr(text.length - maxCharsHalf + 3);
            } else {
                return text.substr(0, maxChars - 3) + '...';
            }
        }

        return text.substr(0, maxChars);
    }

    public buildModelParams(models: any[], langIds: number[]): any[] {
        /**
         * Construit un tableau d'objets ayant la structure suivante :
         *   [{
         *       item: { key: value, ... }
         *       translations: [
         *           { langId: number, key: value, ... },
         *       ]
         *   },]
         * Les valeurs traductibles du model sont recopiées dans toutes les langues activées
         */

        const items: any[] = [];
        for (let i = 0; i < models.length; i++) {
            const nonTranslatableValues: Object = {};
            const nonTranslatableKeys: string[] = models[i].constructor.getNonTranslatableFields();
            for (let j = 0; j < nonTranslatableKeys.length; j++) {
                nonTranslatableValues[nonTranslatableKeys[j]] =
                    !models[i][nonTranslatableKeys[j]] && (models[i][nonTranslatableKeys[j]] === undefined || isNaN(models[i][nonTranslatableKeys[j]])) ?
                        null : models[i][nonTranslatableKeys[j]];
            }

            const translations: Object[] = [];
            const translatableKeys: string[] = models[i].constructor.getTranslatableFields();
            for (let j = 0; j < langIds.length; j++) {
                const translation: Object = {langId: langIds[j]};
                for (let k = 0; k < translatableKeys.length; k++) {
                    translation[translatableKeys[k]] =
                        !models[i][translatableKeys[k]] && (models[i][translatableKeys[k]] === undefined || isNaN(models[i][translatableKeys[k]])) ?
                            null : models[i][translatableKeys[k]];
                }

                translations.push(translation);
            }

            items.push({
                item: nonTranslatableValues,
                translations
            });
        }

        return items;
    }

    public groupBy(arr: any[], property: string): any[] {
        return arr.reduce((acc, cur) => {
            acc[cur[property]] = [...acc[cur[property]] || [], cur];
            return acc;
        }, {});
    }

    //
    //
    // PRIVATE METHODS
    //
    //

}
