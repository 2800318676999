import { Utils } from '../../_common/providers/utils';
import {
    InvestorProfile,
    InvestorValidationState,
    Project,
    ProjectMembership,
    ProjectValidationState,
    User,
    User_AcademicInstitution
} from '../../_common/providers/models';

export class ProjectRights {

    //
    //
    // ATTRIBUTES
    //
    //

    private _utils: Utils;
    private _params: ProjectRightsParams = null;
    private _listProjects: boolean = false;
    private _listProjectMemberships: boolean = false;
    private _addProjectMembership: boolean = false;
    private _deleteProjectMembership: boolean = false;
    private _see: boolean = false;
    private _edit: boolean = false;
    private _delete: boolean = false;
    private _seeEditForm: boolean = false;
    private _addToFavorites: boolean = false;
    private _addAlerts: boolean = false;
    private _askForReview: boolean = false;
    private _validate: boolean = false;
    private _seeTTOInformation: boolean = false;
    private _seeMetasInforomation: boolean = false;
    private _editAcademicInstitution1: boolean = false;
    private _seeDashboard: boolean = false;

    constructor(
        utils: Utils
    ) {
        this._utils = utils;
    }

    public get LIST_PROJECTS() {
        return this._listProjects;
    }

    public get LIST_PROJECT_MEMBERSHIPS() {
        return this._listProjectMemberships;
    }

    public get ADD_PROJECT_MEMBERSHIP() {
        return this._addProjectMembership;
    }

    public get DELETE_PROJECT_MEMBERSHIP() {
        return this._deleteProjectMembership;
    }

    public get SEE() {
        return this._see;
    }

    public get EDIT() {
        return this._edit;
    }

    public get DELETE() {
        return this._delete;
    }

    public get SEE_EDIT_FORM() {
        return this._seeEditForm;
    }

    public get ADD_TO_FAVORITES() {
        return this._addToFavorites;
    }

    public get ADD_ALERTS() {
        return this._addAlerts;
    }

    public get ASK_FOR_REVIEW() {
        return this._askForReview;
    }

    public get VALIDATE() {
        return this._validate;
    }

    public get SEE_TTO_INFORMATION() {
        return this._seeTTOInformation;
    }

    public get SEE_METAS_INFORMATION() {
        return this._seeMetasInforomation;
    }

    public get EDIT_ACADEMIC_INSTITUTION_1() {
        return this._editAcademicInstitution1;
    }

    public get SEE_DASHBOARD() {
        return this._seeDashboard;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public evaluate(params: ProjectRightsParams): void {
        this._params = params;

        if (params.user.isAdmin) {
            this.evaluateAdmin();
        } else if (params.user.isInvestor) {
            this.evaluateInvestor();
        } else if (params.user.isGuest) {
            this.evaluateGuest();
        } else if (params.user.isProjectHolder) {
            this.evaluateProjectHolder();
        } else if (params.user.isLocalExpert) {
            this.evaluateLocalExpert();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private evaluateAdmin(): void {
        this._listProjects = true;
        this._see = true;
        this._edit = true;
        this._editAcademicInstitution1 = true;
        this._delete = true;
        this._seeEditForm = true;
        this._addToFavorites = false;
        this._addAlerts = false;
        this._askForReview = false;
        this._listProjectMemberships = true;
        this._addProjectMembership = false;
        this._deleteProjectMembership = false;
        this._seeTTOInformation = true;
        this._seeMetasInforomation = true;
        this._seeDashboard = false;
        if (this._params.project && this._params.project.projectValidationStateId === ProjectValidationState.ID_PENDING_VALIDATION) {
            this._validate = true;
        } else {
            this._validate = false;
        }
    }

    private evaluateGuest(): void {
        this._listProjects = true;
        this._see = true;
        this._edit = false;
        this._editAcademicInstitution1 = false;
        this._delete = false;
        this._seeEditForm = false;
        this._addToFavorites = true;
        this._addAlerts = true;
        this._askForReview = false;
        this._validate = false;
        this._listProjectMemberships = false;
        this._addProjectMembership = false;
        this._deleteProjectMembership = false;
        this._seeTTOInformation = false;
        this._seeMetasInforomation = false;
        this._seeDashboard = false;
    }

    private evaluateInvestor(): void {
        if (this._params.investorProfile && this._params.investorProfile.investorValidationStateId === InvestorValidationState.ID_ACCEPTED) {
            this.evaluateGuest(); // un investisseur validé est exactement comme un guest
            return;
        }

        // s'il n'est pas validé, il peut rien faire...
        this._listProjects = false;
        this._see = false;
        this._edit = false;
        this._editAcademicInstitution1 = false;
        this._delete = false;
        this._seeEditForm = false;
        this._addToFavorites = false;
        this._addAlerts = false;
        this._askForReview = false;
        this._validate = false;
        this._listProjectMemberships = false;
        this._addProjectMembership = false;
        this._deleteProjectMembership = false;
        this._seeTTOInformation = false;
        this._seeMetasInforomation = false;
        this._seeDashboard = false;
    }

    private evaluateProjectHolder(): void {
        this._listProjects = false;
        this._addToFavorites = false;
        this._addAlerts = false;
        this._validate = false;
        this._seeTTOInformation = false;
        this._seeMetasInforomation = false;

        if (this._params.membership) {
            this._see = true;
            this._seeEditForm = true;
            this._seeDashboard = true;
            this._listProjectMemberships = true;
            this._addProjectMembership = true;
            this._deleteProjectMembership = true;
            this._delete = true;
            // si le projet est en cours de validation ou refusé, on peut plus le toucher
            if ([ProjectValidationState.ID_PENDING_VALIDATION, ProjectValidationState.ID_REFUSED].indexOf(this._params.project.projectValidationStateId) >= 0) {
                this._edit = false;
            } else {
                this._edit = true;
            }

            if (this._params.project && this._params.project.projectValidationStateId === ProjectValidationState.ID_DRAFT) {
                this._askForReview = true;
                this._editAcademicInstitution1 = true;
            } else {
                this._askForReview = false;
                this._editAcademicInstitution1 = false;
            }
        } else {
            this._see = false;
            this._seeEditForm = false;
            this._seeDashboard = false;
            this._edit = false;
            this._delete = false;
            this._askForReview = false;
            this._editAcademicInstitution1 = false;
            this._listProjectMemberships = false;
            this._addProjectMembership = false;
            this._deleteProjectMembership = false;
            this._delete = false;
        }
    }

    private evaluateLocalExpert(): void {
        this._listProjects = true;
        this._see = true;
        this._editAcademicInstitution1 = false;
        this._addToFavorites = false;
        this._addAlerts = false;
        this._askForReview = false;
        this._listProjectMemberships = false;
        this._addProjectMembership = false;
        this._deleteProjectMembership = false;
        this._seeDashboard = false;

        if (this._params.project
            && this._params.user_academicInstitutions
            && this._utils.findIn(this._params.project.academicInstitution1Id, this._params.user_academicInstitutions, 'academicInstitutionId')) {
            this._edit = true;
            this._delete = true;
            this._seeEditForm = true;
            this._seeTTOInformation = true;
            this._seeMetasInforomation = true;

            if (this._params.project.projectValidationStateId === ProjectValidationState.ID_PENDING_VALIDATION) {
                this._validate = true;

            } else {
                this._validate = false;
            }
        } else {
            this._validate = false;
            this._edit = false;
            this._delete = false;
            this._seeEditForm = false;
            this._seeTTOInformation = false;
            this._seeMetasInforomation = false;
        }
    }
}

export interface ProjectRightsParams {
    user: User;
    project?: Project;
    membership?: ProjectMembership;
    investorProfile?: InvestorProfile;
    user_academicInstitutions?: User_AcademicInstitution[];
}
