import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { ProjectMembershipRow, ProjectsMembershipsProvider } from '../../providers/projects-memberships-provider';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModuleMenu } from '../../../_common/components/module-content/module-content';
import { Utils } from '../../../_common/providers/utils';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ProjectsProvider } from '../../../projects/providers/projects-provider';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'project-membership-list',
    providers: [],
    styleUrls: ['./list.scss'],
    templateUrl: './list.html'
})
export class ProjectMembershipListComponent implements OnInit, AfterViewInit {
    public dataSource = new SimpleMatDataSource([]);
    public displayedColumns: string[] = ['name', 'email', 'createDate', 'action'];
    public project;

    @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) public sorter: MatSort;
    @Output() public onAddMembershipClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() public canAddMembership: boolean = false;
    public addMembershipMenu: ModuleMenu = {
        icon: 'add',
        title: 'Invite_user',
        onClick: () => this.onAddMembershipClicked.emit(),
        url: null
    };
    @Output() public onDeleteClicked: EventEmitter<ProjectMembershipRow> = new EventEmitter<ProjectMembershipRow>();
    private projectId: number;
    private projectsMembershipsProvider: ProjectsMembershipsProvider;

    constructor(
        public _projectsProvider: ProjectsProvider,
        public currentUser: CurrentUser,
        private _route: ActivatedRoute,
        private _toaster: MatSnackBar,
        private _translater: TranslateService,
        private _utils: Utils,
        private _dialoger: MatDialog,
        projectsMembershipsProvider: ProjectsMembershipsProvider
    ) {
        this.projectsMembershipsProvider = projectsMembershipsProvider;
    }

    @Input()
    set projectMemberships(projectMemberships: ProjectMembershipRow[]) {
        this.dataSource.replaceAllWith(projectMemberships);
    }

    public ngAfterViewInit() {

    }

    public ngOnInit() {
        this._route.params.subscribe((params: any) => {
            this.projectId = +params.projectId;
        });

        this.initDataSource();

        this.project = this._projectsProvider.getProject(this.projectId).then((result) => {
            this.project = result;
        });
    }

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    private initDataSource(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sorter;

        this.dataSource.addSortDefinition(
            'name',
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase(), b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase()),
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase(), a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'email',
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(a.user.email.toLowerCase(), b.user.email.toLowerCase()),
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(b.user.email.toLowerCase(), a.user.email.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'createDate',
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(a.membership.createDate, b.membership.createDate),
            (a: ProjectMembershipRow, b: ProjectMembershipRow) => this._utils.sortRow(b.membership.createDate, a.membership.createDate)
        );
    }
}
