<div *ngIf="currentUser.isLoggedIn()" class="avatar-container">
    <img *ngIf="currentUser.user.avatar"
         [attr.src]="conf.storageRootUrl+currentUser.user.avatar"
         [matMenuTriggerFor]="menu"
         class="avatar"/>
    <mat-icon *ngIf="!currentUser.user.avatar"
              [matMenuTriggerFor]="menu">
        account_circle
    </mat-icon>
    <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item routerLink="/users/{{ currentUser.user.id }}/edit">{{ 'My_account' | translate }}</button>
        <button (click)="onLogoutClicked()" mat-menu-item>{{ 'Disconnect' | translate }}</button>
    </mat-menu>
</div>
<div *ngIf="!currentUser.isLoggedIn()" class="accessor">
    <auth [detectPasswordResets]="true"
          [isRegistrationDisabled]="conf.isRegistrationDisabled"
          [nextUrl]="loginNextUrl">
        <button mat-button>
            {{ loginText | translate }}
        </button>
    </auth>
</div>
