import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';

export class SimpleDataSource extends DataSource<any> {
    public data: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(_data: any[]) {
        super();
        this._count = _data.length;
        this.data.next(_data);
    }

    private _count: number = 0;

    public get count() {
        return this._count;
    }

    public set count(count: number) {
    }

    public connect(): Observable<any[]> {
        return this.data;
    }

    public disconnect() {
    }

    public push(item: any): void {
        const data: any[] = this.data.value;
        data.push(item);
        this._count = data.length;
        this.data.next(data);
    }

    public set(index: number, item: any): void {
        const data: any[] = this.data.value;
        data[index] = item;
        this.data.next(data);
    }

    public get(index: number): any {
        return this.data.value[index];
    }

    public getAll(): any[] {
        return this.data.value;
    }

    public remove(index: number): void {
        const data: any[] = this.data.value;
        data.splice(index, 1);
        this._count = data.length;
        this.data.next(data);
    }
}
