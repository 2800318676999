import { AfterViewInit, Component, Input } from '@angular/core';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'error-404',
    providers: [],
    styleUrls: ['./error-404.scss'],
    templateUrl: './error-404.html'
})
export class Error404Component implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input()
    public message: string = '';
    @Input()
    public actions: any[] = [{name: 'Okay_then...', link: '/'}];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
