<page-content>
    <side-menu [menuGroups]="navigation.menus" left-menu></side-menu>
    <header [backMenu]="backMenu"
            [pageSubtitle]="project?.name"
            [pageTitle]="'Project_users' | translate"
            header>
    </header>
    <error-401 *ngIf="!rights.LIST_PROJECT_MEMBERSHIPS"></error-401>
    <error-404 *ngIf="is404"></error-404>
    <ng-container *ngIf="!is404 && rights.LIST_PROJECT_MEMBERSHIPS">
        <project-membership-list (onAddMembershipClicked)="inviteUserDialog()"
                                 (onDeleteClicked)="onDeleteClicked($event)"
                                 [canAddMembership]="rights.ADD_PROJECT_MEMBERSHIP"
                                 [projectMemberships]="projectMemberships">
        </project-membership-list>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading"></full-width-loader>
