import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'ellipsize'})
export class EllipsizePipe implements PipeTransform {
    constructor() {
    }

    public transform(str: string, maxLength: number) {
        if (!maxLength) {
            return str;
        }

        if (str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        }

        return str;
    }
}
