import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { ProjectSearchResultRow, ProjectsProvider } from '../../providers/projects-provider';
import { InvestorProfile, Project, ProjectFavorite } from '../../../_common/providers/models';

@Component({
    selector: 'favorites-list',
    providers: [],
    styleUrls: ['./favorites-list.scss'],
    templateUrl: './favorites-list.html'
})
export class FavoritesListComponent implements AfterViewInit {

    public isLoading: boolean = false;

    public projects: Project[] = [];
    public investorProfile: InvestorProfile = null;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this.loadData()
            .then(() => {
                this._title.setTitle(this._translater.instant('Favorites') + ' | ' + this.conf.platformName);
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    public onRowClicked(row: ProjectSearchResultRow): void {
        this._router.navigate(['academic-ventures', row.project.id], {
            queryParams: {
                'backUrl': this._router.url,
                'backTitle': this._translater.instant('Favorites')
            }
        });
    }

    public onFavoriteClicked(row: ProjectSearchResultRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_favorite_warning',
                confirmActionText: 'Remove'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this.deleteFavorite(row.project)
                    .then(() => {
                        this.projects = this.projects.slice(); // pour trigger un refresh
                        this._toaster.open(this._translater.instant('Favorite_deleted'), '', {duration: 5000});
                    })
                    .catch((error: any) => {
                        row.isLoading = false;
                        this.onError(error);
                    });
            }
        });
    }

    private loadData(): Promise<void> {
        return Promise.all([
            this._storage.select(Project)
                .where('id').equals(ProjectFavorite, 'projectId')
                .and('userId', ProjectFavorite).equals(this.currentUser.user.id)
                .orderBy('name')
                .get(),
            this._storage.select(InvestorProfile).where('userId').equals(this.currentUser.user.id).get(1)
        ])
            .then((data: any[]) => {
                this.projects = data[0];
                this.investorProfile = data[1];

            });
    }

    private deleteFavorite(project: Project): Promise<void> {
        return this._projectsProvider.toggleProjectFavorite(project)
            .then(() => {
                for (let i = 0; i < this.projects.length; i++) {
                    if (this.projects[i].id === project.id) {
                        this.projects.splice(i, 1);
                        i = this.projects.length;
                    }

                    break;
                }
            });
    }

    private onError(error: any): void {
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
