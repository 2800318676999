import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ProjectsRegisterDialogComponent } from '../../../projects/components/register-dialog/register-dialog';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { AcademicInstitution } from '../../../_common/providers/models';

@Component({
    selector: 'projects-home-page',
    providers: [],
    styleUrls: ['./home.scss'],
    templateUrl: './home.html'
})
export class ProjectsHomePageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public institutions: AcademicInstitution[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _route: ActivatedRoute,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._title.setTitle(this.conf.platformName);

        this.loadData()
            .then(() => this.onCreateProjectAccountClicked()) // pour afficher la popup directement
            .catch((error: any) => this.onError(error));

        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onCreateProjectAccountClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ProjectsRegisterDialogComponent, {
            data: {}
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(AcademicInstitution).where('id').notIn([AcademicInstitution.ID_OTHER, AcademicInstitution.ID_NONE]).get()
                .then((data: AcademicInstitution[]) => {
                    this.institutions = data;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}
