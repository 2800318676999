import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { routerTransition } from './_common/animations/router-transition';

export const ROOT_SELECTOR = 'app';

@Component({
    selector: 'app',
    providers: [],
    styleUrls: ['./app.component.css'],
    templateUrl: './app.component.html',
    animations: [routerTransition]
})
export class AppComponent implements OnInit {

    public isLoading: boolean = true;

    private _lastPoppedUrl: string;
    private _yScrollStack: number[] = [];

    constructor(
        private _router: Router,
        private _location: Location
    ) {
        // Tant que la route n'est pas compl�tement load�e, on affiche un waiter
        // (c'est surtout utile pour laisser le temps � la synchronisation des donn�es de se faire)
        const loadingListener = this._router.events.pipe(
            filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.isLoading = false;
                loadingListener.unsubscribe();
            });
    }

    public ngOnInit() {
        this.fixScrollNavigationIssue();
        // permet de corriger le problème lorsqu'on navigue et que la nouvelle route
        // charge le même composant (la page n'est pas reloadée ...)
        // inspiré de : https://stackoverflow.com/a/45737269
        this._router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    public getTransition(): string {
        // TODO pour pas calculer la transition toutes les 2ms,
        // peut �tre utiliser une variable qui se met � jour
        // quand on d�tecte un navigationStart/navigationEnd
        return 'todo';
    }

    private fixScrollNavigationIssue(): void {
        // r�soud le probl�me de la position du scroll quand on navigue d'une page � une autre.
        // (si sur une page on click sur un lien qui se trouve plus bas que la hauteur view port
        // et que le lien pointe vers une page plus grande que la hauteur du viewport
        // on arrive le scroll est conserv� ...)
        // doc : https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change
        this._location.subscribe((ev: PopStateEvent) => {
            this._lastPoppedUrl = ev.url;
        });
        this._router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url != this._lastPoppedUrl) {
                    this._yScrollStack.push(window.scrollY);
                }
            } else if (ev instanceof NavigationEnd) {
                if (ev.url == this._lastPoppedUrl) {
                    this._lastPoppedUrl = undefined;
                    window.scrollTo(0, this._yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
    }
}

/**
 * Please review the https://github.com/AngularClass/angular2-examples/ repo for
 * more angular app examples that you may copy/paste
 * (The examples may not be updated as quickly. Please open an issue on github for us to update it)
 * For help or questions please contact us at @AngularClass on twitter
 * or our chat on Slack at https://AngularClass.com/slack-join
 */
