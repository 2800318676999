<table cellpadding="0" cellspacing="0">
    <thead *ngIf="headers.length > 0">
    <tr>
        <td *ngFor="let header of headers" [style.width]="header.width + '%'">{{ header.title }}</td>
    </tr>
    </thead>
    <tbody>
    <tr (click)="onRowClicked(row.link, row.linkParams)" *ngFor="let row of rowsData">
        <td *ngFor="let item of row.items">{{ item }}</td>
    </tr>
    </tbody>
</table>
