import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { LangsProvider } from '../../_common/providers/langs-provider';
import { CurrentUser } from '../../_common/providers/current-user';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { ProjectRights } from './project-rights';
import {
    AcademicInstitution,
    AcademicInstitutionGroup,
    Alert,
    CompanyCategory,
    DevelopmentPhase,
    DevelopmentPhase_ProjectTypeGroup,
    File,
    FileGroup,
    MetricsRecord,
    PatentState,
    Project,
    ProjectCase,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectFavorite,
    ProjectMembership,
    ProjectPastFunding,
    ProjectPrize,
    ProjectTeamMember,
    ProjectType,
    ProjectTypeGroup,
    TherapeuticArea,
    User
} from '../../_common/providers/models';

@Injectable()
export class ProjectsProvider {

    //
    //
    // CONSTANTS
    //
    //

    public readonly ILLUSTRATION_MAX_SIZE: number = 2; // Mo
    public readonly ILLUSTRATION_MAX_HEIGHT: number = 5000;
    public readonly ILLUSTRATION_MAX_WIDTH: number = 5000;
    public readonly ILLUSTRATION_FILE_TYPES: string[] = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public projectFiltersState: ProjectFiltersState = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _translater: TranslateService,
        private _router: Router,
        private _api: ApiBaseService,
        private _langs: LangsProvider,
        private _utils: Utils,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getProjectTypeGroups() {
        return new Promise<ProjectTypeGoupWithTypes[]>((resolve, reject) => {
            Promise.all([
                this._storage.select(ProjectTypeGroup).get(),
                this._storage.select(ProjectType).get()
            ])
                .then((data: any[]) => {
                    const items: ProjectTypeGoupWithTypes[] = [];

                    // vilain hack degueux pour afficher les groupes dans l'ordre que le client veut...
                    const sortIndex: any = {};
                    sortIndex['' + ProjectTypeGroup.ID_BIOTECH] = 0;
                    sortIndex['' + ProjectTypeGroup.ID_MEDTECH] = 1;
                    sortIndex['' + ProjectTypeGroup.ID_HEALTHCARE] = 2;
                    data[0].forEach(() => {
                        items.push(null);
                    });

                    for (let i = 0; i < data[0].length; i++) {
                        const index: number = sortIndex[data[0][i].id] >= 0 ? sortIndex[data[0][i].id] : i;
                        items[index] = {
                            group: data[0][i],
                            types: this._utils.findManyIn([data[0][i].id], data[1], 'projectTypeGroupId')
                        };
                    }

                    resolve(items);
                })
                .catch((error: any) => reject(error));
        });
    }

    public getAcademicInstitionGroups() {
        return new Promise<AcademicInstitutionGroupWithInstitution[]>((resolve, reject) => {
            Promise.all([
                this._storage.select(AcademicInstitutionGroup).orderBy('ordinal').get(),
                this._storage.select(AcademicInstitution).orderBy('ordinal').get()
            ])
                .then((data: any[]) => {
                    const items: AcademicInstitutionGroupWithInstitution[] = [];
                    for (let i = 0; i < data[0].length; i++) {
                        items.push({
                            group: data[0][i],
                            institutions: this._utils.findManyIn([data[0][i].id], data[1], 'academicInstitutionGroupId')
                        });
                    }
                    resolve(items);
                })
                .catch((error: any) => reject(error));
        });
    }

    public getDevelopmentPhasesWithAssociatedProjectTypeGroups() {
        return new Promise<DevelopmentPhaseWithAssociatedProjectTypeGroups[]>((resolve, reject) => {
            Promise.all([
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this._storage.select(ProjectTypeGroup).get(),
                this._storage.select(DevelopmentPhase_ProjectTypeGroup).get()
            ])
                .then((data: any[]) => {
                    const items: DevelopmentPhaseWithAssociatedProjectTypeGroups[] = [];
                    for (let i = 0; i < data[0].length; i++) {
                        const groupIds: number[] = this._utils.getKeyValues(this._utils.findManyIn([data[0][i].id], data[2], 'developmentPhaseId'), 'projectTypeGroupId');
                        items.push({
                            developmentPhase: data[0][i],
                            groups: this._utils.findManyIn(groupIds, data[1], 'id')
                        });
                    }

                    resolve(items);
                })
                .catch((error: any) => reject(error));
        });
    }

    public getAvailableDevelopmentPhases(projectTypeIds: number[]): Promise<DevelopmentPhase[]> {
        // Note FG:
        // for the time being, we don't filter development phases based on project types
        // but this might change in the future
        return this._storage.select(DevelopmentPhase).orderBy('ordinal').get();
        /*
          return this._storage.select(DevelopmentPhase)
              .where('id').equals(DevelopmentPhase_ProjectTypeGroup, 'developmentPhaseId')
              .and('projectTypeGroupId', DevelopmentPhase_ProjectTypeGroup).equals(ProjectType, 'projectTypeGroupId')
              .and('id', ProjectType).in(projectTypeIds)
              .orderBy('ordinal')
              .get();
         */
    }

    public saveProject(project: Project): Promise<Project> {
        return new Promise<Project>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + (project.id ? project.id : 0),
                this._utils.buildModelParams([project], this._langs.getLangIds())[0],
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    project = Project.fromAPI(data.project, data.projectTranslations);
                    const fileGroup: FileGroup = FileGroup.fromAPI(data.fileGroup);
                    const memberships: ProjectMembership[] = ProjectMembership.fromAPIList(data.projectMemberships);
                    Promise.all([
                        this._storage.save([project]),
                        this._storage.save([fileGroup]),
                        this._storage.save(memberships)
                    ])
                        .then(() => resolve(project))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveProjectCase(projectId: number, projectCase: ProjectCase[], projectType: ProjectCase_ProjectType[], therapeuticArea: ProjectCase_TherapeuticArea[]): Promise<ProjectCase[]> {

        const groupedProjectType = this._utils.groupBy(projectType, 'projectCaseId');
        const groupedTherapeuticArea = this._utils.groupBy(therapeuticArea, 'projectCaseId');

        // add empty object, so that ProjectCase relations are deleted, when all items are removed
        projectCase.forEach((item) => {
            if (item.id) {
                if (groupedProjectType[item.id] === undefined) {
                    const emptyProjectType = new ProjectCase_ProjectType();
                    groupedProjectType[item.id] = {emptyProjectType};
                }
                if (groupedTherapeuticArea[item.id] === undefined) {
                    const emptyTherapeuticArea = new ProjectCase_TherapeuticArea();
                    groupedTherapeuticArea[item.id] = {emptyTherapeuticArea};
                }
            }
        });

        return new Promise<any>((resolve, reject) => {
            Promise.all([
                /* 1 */ Object.keys(groupedProjectType).forEach((id) => this.saveProjectTypes(parseFloat(id), groupedProjectType[id])),
                /* 2 */ Object.keys(groupedTherapeuticArea).forEach((id) => this.saveTherapeuticAreas(parseFloat(id), groupedTherapeuticArea[id])),
                this._api.call(
                    'post',
                    'projects/' + projectId + '/project-case',
                    {projectCase: this._utils.buildModelParams(projectCase, this._langs.getLangIds())},
                    this._currentUser.getAccessToken()
                )
            ])
                .then((data: any) => {
                    projectCase = ProjectCase.fromAPIList(data[2].projectCase, data[2].projectCaseTranslations);
                    this._storage.select(ProjectCase).where('projectId').equals(projectId).get()
                        .then((toDelete: ProjectCase[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(projectCase)
                            ])
                                .then(() => resolve(projectCase))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveProjectTypes(projectCaseId: number, projectType: ProjectCase_ProjectType[]): Promise<ProjectCase_ProjectType[]> {
        return new Promise<ProjectCase_ProjectType[]>((resolve, reject) => {

            this._api.call(
                'post',
                'projects/project-case/' + projectCaseId + '/project-types',
                {ProjectCase_ProjectTypes: this._utils.buildModelParams(projectType, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    projectType = ProjectCase_ProjectType.fromAPIList(data.projectCase_projectTypes);
                    this._storage.select(ProjectCase_ProjectType).where('projectCaseId').equals(projectCaseId).get()
                        .then((toDelete: ProjectCase_ProjectType[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(projectType)
                            ])
                                .then(() => resolve(projectType))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveTherapeuticAreas(projectCaseId: number, therapteuticAreaIds: ProjectCase_TherapeuticArea[]): Promise<ProjectCase_TherapeuticArea[]> {
        return new Promise<ProjectCase_TherapeuticArea[]>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/project-case/' + projectCaseId + '/therapeutic-areas',
                {ProjectCase_TherapeuticAreas: this._utils.buildModelParams(therapteuticAreaIds, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    therapteuticAreaIds = ProjectCase_TherapeuticArea.fromAPIList(data.projectCase_therapeuticAreas);
                    this._storage.select(ProjectCase_TherapeuticArea).where('projectCaseId').equals(projectCaseId).get()
                        .then((toDelete: ProjectCase_TherapeuticArea[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(therapteuticAreaIds)
                            ])
                                .then(() => resolve(therapteuticAreaIds))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveProjectPrizes(projectId: number, prizes: ProjectPrize[]): Promise<ProjectPrize[]> {
        return new Promise<ProjectPrize[]>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/prizes',
                {projectPrizes: this._utils.buildModelParams(prizes, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(ProjectPrize).where('projectId').equals(projectId).get()
                        .then((oldPrizes: ProjectPrize[]) => {
                            prizes = ProjectPrize.fromAPIList(data.projectPrizes);
                            Promise.all([
                                this._storage.delete(oldPrizes),
                                this._storage.save(prizes)
                            ])
                                .then(() => resolve(prizes))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveProjectPastFundings(projectId: number, fundings: ProjectPastFunding[]): Promise<ProjectPastFunding[]> {
        return new Promise<ProjectPastFunding[]>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/past-fundings',
                {projectPastFundings: this._utils.buildModelParams(fundings, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    fundings = ProjectPastFunding.fromAPIList(data.projectPastFundings);
                    this._storage.save(fundings)
                        .then(() => resolve(fundings))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveProjectTeamMembers(projectId: number, members: ProjectTeamMember[]): Promise<ProjectTeamMember[]> {
        return new Promise<ProjectTeamMember[]>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/team-members',
                {projectTeamMembers: this._utils.buildModelParams(members, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    members = ProjectTeamMember.fromAPIList(data.projectTeamMembers);
                    this._storage.select(ProjectTeamMember).where('projectId').equals(projectId).get()
                        .then((toDelete: ProjectTeamMember[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(members)
                            ])
                                .then(() => resolve(members))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public addFiles(project: Project, files: File[]): Promise<File[]> {
        return new Promise<File[]>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + project.id + '/files/add',
                {files: this._utils.buildModelParams(files, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    files = File.fromAPIList(data.files);
                    this._storage.save(files)
                        .then(() => resolve(files))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public updateFile(project: Project, file: File): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + project.id + '/files/' + file.id,
                this._utils.buildModelParams([file], this._langs.getLangIds())[0],
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    file = File.fromAPI(data.file);
                    this._storage.save([file])
                        .then(() => resolve(file))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public deleteFile(project: Project, file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'projects/' + project.id + '/files/' + file.id,
                this._utils.buildModelParams([file], this._langs.getLangIds())[0],
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.delete([file])
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public toggleProjectFavorite(project: Project): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._storage.select(ProjectFavorite)
                .where('userId').equals(this._currentUser.user.id)
                .and('projectId').equals(project.id)
                .get(1)
                .then((favorite: ProjectFavorite) => {
                    const isFavorite: boolean = favorite != null;
                    this._api.call(
                        isFavorite ? 'delete' : 'post',
                        'projects/' + project.id + '/favorite',
                        {},
                        this._currentUser.getAccessToken()
                    )
                        .then((data: any) => {
                            const promise: Promise<any> = isFavorite ? this._storage.delete([favorite]) : this._storage.save([ProjectFavorite.fromAPI(data.projectFavorite)]);
                            promise
                                .then(() => {
                                    resolve(!isFavorite);
                                })
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public validateProject(project: Project, newState: number, message: string = null): Promise<Project> {
        return new Promise<Project>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + project.id + '/validate',
                {projectValidationStateId: newState, message},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    project = Project.fromAPI(data.project, data.projectTranslations);
                    this._storage.save([project])
                        .then(() => resolve(project))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public askForReview(project: Project): Promise<Project> {
        return new Promise<Project>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + project.id + '/ask-for-review',
                {},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    project = Project.fromAPI(data.project, data.projectTranslations);
                    this._storage.save([project])
                        .then(() => resolve(project))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public registerAccount(firstName: string, lastName: string, email: string, password: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/account',
                {
                    firstName,
                    lastName,
                    email,
                    password
                }
            )
                // on stock pas localement les données parce qu'après cette opération, on serait censé lancé un sync ...
                .then((data: any) => resolve())
                .catch((error: any) => reject(error));
        });
    }

    public getProject(id): Promise<Project> {
        return this._storage.fromId(Project, id);
    }

    public deleteProject(project: Project): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'projects/' + project.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    Promise.all([
                        this._storage.delete([project]),
                        this._storage.deleteOn(ProjectFavorite, 'projectId', project.id),
                        this._storage.deleteOn(ProjectPrize, 'projectId', project.id),
                        this._storage.deleteOn(ProjectPastFunding, 'projectId', project.id),
                        this._storage.deleteOn(ProjectTeamMember, 'projectId', project.id),
                        this._storage.deleteOn(ProjectMembership, 'projectId', project.id),
                        this._storage.select(ProjectCase).where('projectId').equals(project.id).get()
                    ])
                        .then((data: any[]) => {
                            const projectCaseId: number[] = data[6];
                            Promise.all([
                                this._storage.deleteOn(ProjectCase, 'projectId', project.id),
                                this._storage.deleteMultipleOn(ProjectCase_ProjectType, 'projectCaseId', projectCaseId),
                                this._storage.deleteMultipleOn(ProjectCase_TherapeuticArea, 'projectCaseId', projectCaseId),
                            ]).then(() => resolve())
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public deleteProjectCase(singleProjectCase: ProjectCase): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'projects/project-case/' + singleProjectCase.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    Promise.all([
                        this._storage.delete([singleProjectCase]),
                        this._storage.deleteOn(ProjectCase_ProjectType, 'projectCaseId', singleProjectCase.id),
                        this._storage.deleteOn(ProjectCase_TherapeuticArea, 'projectCaseId', singleProjectCase.id),
                    ])
                        .then(() => resolve())
                        .catch((error: any) => reject(error));

                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAcademicInsitution1(projectId: number, academicInstitution1Id: number): Promise<Project> {
        return new Promise<Project>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/academic-institution-1',
                {academicInstitution1Id},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    const project: Project = Project.fromAPI(data.project, data.projectTranslations);
                    this._storage.save([project])
                        .then(() => resolve(project))
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public dispatchNotification(project: Project, user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + project.id + '/dispatch-notification/' + user.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => resolve())
                .catch(reject);
        });
    }

    public computeListingProgress(project: Project, projectTypesCount: number, therapeuticAreasCount: number, teamMembersCount: number): number {
        let progress: number = 0;

        project.name ? progress++ : progress += 0;
        projectTypesCount ? progress++ : progress += 0;
        therapeuticAreasCount ? progress++ : progress += 0;
        project.pipeline ? progress++ : progress += 0;
        project.keywords ? progress++ : progress += 0;
        project.logo ? progress++ : progress += 0;
        project.illustration ? progress++ : progress += 0;
        project.developmentPhaseId ? progress++ : progress += 0;
        project.patentStateId ? progress++ : progress += 0;
        project.incorporationYear ? progress++ : progress += 0;
        project.academicInstitution1Id ? progress++ : progress += 0;
        teamMembersCount ? progress++ : progress += 0;
        project.teamPicture ? progress++ : progress += 0;
        project.contactFirstName ? progress++ : progress += 0;
        project.contactLastName ? progress++ : progress += 0;
        project.contactEmail ? progress++ : progress += 0;
        project.contactWebsite ? progress++ : progress += 0;
        project.contactPhone ? progress++ : progress += 0;
        project.contactAddress ? progress++ : progress += 0;
        project.isTTOAgreementAccepted || project.isInstitutionLicenseOwned ? progress++ : progress += 0;

        return Math.round((progress / 26) * 100);
    }

    public addProjectMetrics(projectId: number, tag: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/metrics',
                {
                    tag
                },
                this._currentUser.getAccessToken()
            )
                .then(() => resolve())
                .catch(reject);
        });
    }

    public getProjectChartData(project: Project, groupBy: 'day' | 'week', seriesNames: string[]): Promise<ProjectChartData> {
        return new Promise<ProjectChartData>((resolve, reject) => {
            Promise.all([
                this._storage.select(MetricsRecord).where('projectId').equals(project.id).get(),
                this._storage.select(CompanyCategory).orderBy('ordinal').get()
            ])
                .then((data: any[]) => {
                    if (data[0].length === 0) {
                        resolve(null);
                        return;
                    }

                    const viewsRecords: MetricsRecord[] = data[0].filter((record: MetricsRecord) => record.tag === MetricsRecord.TAG_PROJECT_SEEN);
                    const favoritesRecords: MetricsRecord[] = data[0].filter((record: MetricsRecord) => record.tag === MetricsRecord.TAG_FAVORITE_ADDED);
                    const unfavoritesRecords: MetricsRecord[] = data[0].filter((record: MetricsRecord) => record.tag === MetricsRecord.TAG_FAVORITE_REMOVED);
                    const investorsIndex: any = {};
                    const investorsRecords: MetricsRecord[] = viewsRecords.filter((record: MetricsRecord) => {
                        const payload: any = JSON.parse(record.payload);
                        // a noter qu'avec ce système, si un investisseur change de catégorie d'entreprise
                        // il sera affiché avec sa première catégorie, pas la dernière en date
                        // de plus, ça créé un delta entre les uniques investors et les details views
                        // (mais c'est normal, la personne physique derrière ne change pas !)
                        if (payload.investorProfile && !investorsIndex[payload.investorProfile.id]) {
                            investorsIndex[payload.investorProfile.id] = true;
                            return true;
                        }

                        return false;
                    });

                    const fromDate: string = project.publishDate ? project.publishDate : project.createDate;
                    const toDate: string = this._utils.DateToYmdHis(new Date());

                    const viewsData: any = {
                        name: seriesNames[0],
                        series: this.buildChartSeries(viewsRecords, [], fromDate, toDate, groupBy)
                    };

                    const investorsData: any = {
                        name: seriesNames[1],
                        series: this.buildChartSeries(investorsRecords, [], fromDate, toDate, groupBy)
                    };

                    const bookmarksData: any = {
                        name: seriesNames[2],
                        series: this.buildChartSeries(favoritesRecords, unfavoritesRecords, fromDate, toDate, groupBy)
                    };

                    resolve({
                        chart: [viewsData, investorsData, bookmarksData],
                        viewsPie: this.buildPieData(viewsRecords, [], data[1]),
                        investorsPie: this.buildPieData(investorsRecords, [], data[1]),
                        favoritesPie: this.buildPieData(favoritesRecords, unfavoritesRecords, data[1])
                    });
                })
                .catch(reject);
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private buildChartSeries(records: MetricsRecord[], soustractRecords: MetricsRecord[], fromDate: string, toDate: string, groupBy: 'day' | 'week'): any[] {
        const dailyData: any[] = [];
        const _records: MetricsRecord[] = records.slice();
        const _soustractRecords: MetricsRecord[] = soustractRecords.slice();

        const date: Date = this._utils.dateAtMidnight(new Date(fromDate));
        const toDateTmp: Date = this._utils.dateAtMidnight(new Date(toDate));
        toDateTmp.setDate(toDateTmp.getDate() + 1);
        const toTimestamp: number = toDateTmp.getTime();
        let value: number = 0;
        while (date.getTime() <= toTimestamp) {
            const dateStr: string = this._utils.DateToYmdHis(date).split(' ')[0];
            const dayData: any = {
                name: dateStr,
                value
            };

            let i: number = 0;
            while (i < _records.length) {
                if (_records[i].createDate.split(' ')[0] === dateStr) {
                    value++;
                    dayData.value = value;
                    _records.splice(i, 1);
                } else {
                    i++;
                }
            }
            i = 0;
            while (i < _soustractRecords.length) {
                if (_soustractRecords[i].createDate.split(' ')[0] === dateStr) {
                    value--;
                    dayData.value = value;
                    _soustractRecords.splice(i, 1);
                } else {
                    i++;
                }
            }

            dailyData.push(dayData);
            date.setDate(date.getDate() + 1);
        }

        if (groupBy === 'day') {
            dailyData.forEach((row: any) => row.name = this._utils.formatDate(row.name + ' 00:00:00', 'mdY'));

            return dailyData;
        }

        const weeklyData: any[] = [];
        for (let i = dailyData.length - 1; i >= 0; i -= 7) {
            weeklyData.push({
                name: this._utils.formatDate(dailyData[i].name + ' 00:00:00', 'mdY'),
                value: dailyData[i].value
            });
        }
        weeklyData.reverse();

        return weeklyData;
    }

    private buildPieData(records: MetricsRecord[], soustractRecords: MetricsRecord[], companyCategories: CompanyCategory[]): any[] {
        const data: any[] = [];
        companyCategories.forEach((category: CompanyCategory) => {
            data.push({
                id: category.id,
                name: category.name,
                value: 0
            });
        });
        data.push({
            id: null,
            name: this._translater.instant('Unknown'),
            value: 0
        });

        const computedRecords: MetricsRecord[] = records.slice();
        soustractRecords.forEach((record: MetricsRecord) => {
            for (let i = 0; i < computedRecords.length; i++) {
                if (record.createrId === computedRecords[i].createrId) {
                    computedRecords.splice(i, 1);
                    i = computedRecords.length;
                }
            }
        });

        computedRecords.forEach((record: MetricsRecord) => {
            const payload: any = JSON.parse(record.payload);
            if (payload.investorProfile && payload.investorProfile.companyCategoryId) {
                const row: any = this._utils.findIn(payload.investorProfile.companyCategoryId, data, 'id');
                row.value++;
            } else {
                data[data.length - 1].value++;
            }
        });

        return data;
    }
}

export interface ProjectTypeGoupWithTypes {
    group: ProjectTypeGroup;
    types: ProjectType[];
}

export interface AcademicInstitutionGroupWithInstitution {
    group: AcademicInstitutionGroup;
    institutions: AcademicInstitution[];
}

export interface DevelopmentPhaseWithAssociatedProjectTypeGroups {
    developmentPhase: DevelopmentPhase;
    groups: ProjectTypeGroup[];
}

export interface ProjectSearchResultRow {
    project: Project;
    institution1: AcademicInstitution;
    fundingRoundId: number;
    rights: ProjectRights;
    projectTypes: ProjectType[];
    projectTypeIds: number[];
    therapeuticAreas: TherapeuticArea[];
    therapeuticAreaIds: number[];
    logoImage?: any;
    institution2?: AcademicInstitution;
    isLoading?: boolean;
    isFavorite?: boolean;
}

export interface IProjectCaseSearchResultRow {
    projectCase: ProjectCase;
    project: Project;
    fundingRoundId: number;
    rights: ProjectRights;
    projectTypes: ProjectType[];
    projectTypeIds: number[];
    therapeuticAreas: TherapeuticArea[];
    therapeuticAreaIds: number[];
    logoImage?: any;
    isLoading?: boolean;
    isFavorite?: boolean;
}

export interface ProjectFiltersState {
    projectTypeIds: number[];
    therapeuticAreaIds: number[];
    developmentPhaseIds: number[];
    fundingRoundIds?: number[];
    alert: Alert;
    pageIndex: number;
}

export interface ProjectChartData {
    chart: {
        name: string,
        series: {
            name: string,
            value: number
        }[]
    }[];
    viewsPie: {
        id: number,
        name: string,
        value: number
    }[];
    investorsPie: {
        id: number,
        name: string,
        value: number
    }[];
    favoritesPie: {
        id: number,
        name: string,
        value: number
    }[];
}

export interface IGroupedProjectCaseTherapeuticArea {
    projectCaseId: number;
    therapeuticArea: ProjectCase_TherapeuticArea[];
}

export interface IGroupedProjectCaseProjectTypes {
    projectCaseId: number;
    projectTypes: ProjectCase_ProjectType[];
}

export interface IProjectCaseView {
    projectCaseId: number;
    projectCase: ProjectCase;
    projectTypes: ProjectType[];
    therapeuticArea: TherapeuticArea[];
    developmentPhase: DevelopmentPhase;
    patentState: PatentState;
}
