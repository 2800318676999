import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';
import { Conf } from '../../_conf';

@Injectable()
export class ApiBaseService {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _http: HttpClient,
        private _conf: Conf,
        private _utils: Utils
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    /**
     * Base method to call a webservice
     * @param method one of 'get'|'post'|'put'
     * @param url the full url of the webservice
     * @param params key-value object containing the parameters to send with the webservice call
     * @param extra an literal object containing extra configuration of the webservice call ; 'accessToken' add a header container the bearer authorization token.
     */
    public call(method: string, url: string, params: Object = {}, extra: any = null): Promise<any | void> {
        return new Promise((resolve, reject) => {
            const callParams: any = {};

            let headers: HttpHeaders = new HttpHeaders();
            headers = headers
                .set('Access-Control-Allow-Origin', '*')
                .set('Access-Control-Allow-Headers', 'origin, content-type, accept, authorization')
                .set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS, HEAD');

            if (typeof extra === 'string') {
                extra = {accessToken: extra};
            } else if (!extra) {
                extra = {};
            }

            if (extra.accessToken) {
                headers = headers.set('Authorization', 'Bearer ' + extra.accessToken);
            }

            if (['json', 'file'].indexOf(extra.resultType) < 0) {
                extra.resultType = 'json';
            } else if (extra.resultType === 'file') {
                callParams.responseType = 'blob';
            }

            url = "/api/" + url

            console.log(url);

            callParams.headers = headers;

            let call = null;
            if (method === 'get') {
                call = this._http.get(url + this.encodeParams(params), callParams);
            } else if (method === 'post') {
                call = this._http.post(url, this.buildFormData(params), callParams);
            } else if (method === 'put') {
                call = this._http.put(url, this.buildFormData(params), callParams);
            } else if (method === 'delete') {
                call = this._http.delete(url + this.encodeParams(params), callParams);
            } else if (method === 'options') {
                call = this._http.options(url + this.encodeParams(params), callParams);
            } else {
                reject({error: 'API call method unrecognized: ' + method});
                return;
            }

            call.subscribe(
                (data) => resolve(data),
                (error) => reject(error)
            );

            /*

            if (extra.resultType === 'json') {
                call.pipe(map((res: Response) => { console.log(res); })).subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
            else if (extra.resultType === 'file') {
                call.pipe(map((res: Response) => res.blob())).subscribe(
                    (file: any) => {
                        resolve(file);
                    },
                    (error: any) => reject(error)
                );
            }
            */
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private encodeParams(params: any): string {
        const paramsElems = [];
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                paramsElems.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
            }
        }
        let paramsEncoded = '';
        if (paramsElems.length) {
            paramsEncoded += '?' + paramsElems.join('&');
        }

        return paramsEncoded;
    }

    private buildFormData(params: any): FormData {
        const getData = (keys: string[], data: any): any[] => {
            let items: any[] = [];
            const currentKey: string = keys[keys.length - 1];

            if (data[currentKey] instanceof File) {
                const firstKey: string = keys.splice(0, 1)[0];
                let keysStr: string = firstKey;
                if (keys.length) {
                    keysStr += '[' + keys.join('][') + ']';
                }
                items.push({key: keysStr, value: data[currentKey], fileName: data[currentKey].name});
            } else if (data[currentKey] instanceof Array) {
                for (let i = 0; i < data[currentKey].length; i++) {
                    items = items.concat(getData(keys.concat(['' + i]), data[currentKey]));
                }
            } else if (data[currentKey] instanceof Object) {
                for (const itemKey in data[currentKey]) {
                    if (data[currentKey].hasOwnProperty(itemKey)) {
                        items = items.concat(getData(keys.concat([itemKey]), data[currentKey]));
                    }
                }
            } else {
                let dataValue: any = data[currentKey];
                if (dataValue === undefined || dataValue === null) {
                    // c'est un hack très vilain
                    // mais formdata.append(null) revient à faire formdata.append('null')
                    // ce qui est vraiment pas ce qu'on veut ...
                    dataValue = '';
                }
                if (dataValue !== null) {
                    const firstKey: string = keys.splice(0, 1)[0];
                    let keysStr: string = firstKey;
                    if (keys.length) {
                        keysStr += '[' + keys.join('][') + ']';
                    }
                    items.push({key: keysStr, value: dataValue});
                }
            }

            return items;
        };

        const formData: FormData = new FormData();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const data: any = getData([key], params);
                for (let i = 0; i < data.length; i++) {
                    if (data[i].fileName) {
                        formData.append(data[i].key, data[i].value, data[i].fileName);
                    } else {
                        formData.append(data[i].key, data[i].value);
                    }
                }
            }
        }

        return formData;
    }
}
