import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { ProjectsAskChangesDialogComponent } from '../ask-changes-dialog/ask-changes-dialog';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectListingFieldsGroup } from '../../pages/edit/edit';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectValidationState } from '../../../_common/providers/models';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'projects-edit-project-review',
    providers: [],
    styleUrls: ['./edit-project-review.scss'],
    templateUrl: './edit-project-review.html'
})
export class ProjectsEditProjectReviewComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly STATE_ID_DRAFT: number = ProjectValidationState.ID_DRAFT;
    public readonly STATE_ID_REVIEW: number = ProjectValidationState.ID_PENDING_VALIDATION;
    public readonly STATE_ID_PUBLISHED: number = ProjectValidationState.ID_PUBLISHED;
    public readonly STATE_ID_REFUSED: number = ProjectValidationState.ID_REFUSED;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public isLoading: boolean = false;
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Input() public canSave: boolean = false;
    @Input() public mandatoryFields: ProjectListingFieldsGroup[] = [];
    @Input() public stepper: MatStepper;
    @Output() public onAskedForReview: EventEmitter<any> = new EventEmitter<any>();
    public missingMandatoryFields: ProjectListingFieldsGroup[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _dialoger: MatDialog,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canAskForReview(): boolean {
        return this.canSave
            && this.missingMandatoryFields.length == 0
            && !this.isLoading;
    }

    public updateMissingMandatoryFields(): void {
        const fields: ProjectListingFieldsGroup[] = [];
        for (let i = 0; i < this.mandatoryFields.length; i++) {
            let group: ProjectListingFieldsGroup = null;
            for (let j = 0; j < this.mandatoryFields[i].fields.length; j++) {
                if (!this.mandatoryFields[i].fields[j].formControl.value || this.mandatoryFields[i].fields[j].formControl.value.length === 0) {
                    if (!group) {
                        group = {
                            stepIndex: this.mandatoryFields[i].stepIndex,
                            stepName: this.mandatoryFields[i].stepName,
                            fields: []
                        };
                    }

                    group.fields.push(this.mandatoryFields[i].fields[j]);
                }
            }

            if (group) {
                fields.push(group);
            }
        }

        this.missingMandatoryFields = fields;
    }

    public onFieldClicked(stepIndex: number, fieldId: string): void {
        if (this.stepper) {
            this.stepper.selectedIndex = stepIndex;
            setTimeout(() => document.getElementById(fieldId).focus(), 300); // le temps que l'animation se termine
        }
    }

    public onAsForReviewClicked(): void {
        /*
        let dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Review_explanation_1',
                confirmActionText: 'Ask_for_review',
                confirmColor: 'primary'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.onAskedForReview.emit();
            }
        });
         */
        this.onAskedForReview.emit();
    }

    public onPublishClicked(): void {
        /*
        let dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Review_explanation_3',
                confirmActionText: 'Publish',
                confirmColor: 'primary'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this._projectsProvider.validateProject(this.project, ProjectValidationState.ID_PUBLISHED)
                    .then(() => {
                        this.isLoading = false;
                        this._router.navigate(['academic-ventures', this.project.id]);
                        this._toaster.open(this._translater.instant('Project_saved'), '', { duration: 5000 });
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
        */
        this.isLoading = true;
        this._projectsProvider.validateProject(this.project, ProjectValidationState.ID_PUBLISHED)
            .then(() => {
                this.isLoading = false;
                this._router.navigate(['academic-ventures', this.project.id]);
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onRefuseClicked(): void {
        /*
        let dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Review_explanation_4',
                confirmActionText: 'Refuse',
                confirmColor: 'warn'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this._projectsProvider.validateProject(this.project, ProjectValidationState.ID_REFUSED)
                    .then(() => {
                        this.isLoading = false;
                        this._router.navigate(['academic-ventures', 'pending-validation']);
                        this._toaster.open(this._translater.instant('Project_saved'), '', { duration: 5000 });
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
        */
        this.isLoading = true;
        this._projectsProvider.validateProject(this.project, ProjectValidationState.ID_REFUSED)
            .then(() => {
                this.isLoading = false;
                this._router.navigate(['academic-ventures', 'pending-validation']);
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onAskChangesClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ProjectsAskChangesDialogComponent);
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                this.isLoading = true;
                this._projectsProvider.validateProject(this.project, ProjectValidationState.ID_DRAFT, result.message)
                    .then(() => {
                        this.isLoading = false;
                        this._router.navigate(['academic-ventures', 'pending-validation']);
                        this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
