import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { InfoDialogComponent } from '../../../_common/components/info-dialog/info-dialog';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModuleMenu } from '../../../_common/components/module-content/module-content';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project } from '../../../_common/providers/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'projects-dashboard-key-data',
    providers: [],
    styleUrls: ['./dashboard-key-data.scss'],
    templateUrl: './dashboard-key-data.html'
})
export class ProjectsDashboardKeyDataComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public isLoading: boolean = false;
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    public menus: ModuleMenu[] = [{
        icon: 'info_outline',
        title: 'Information',
        onClick: () => {
            this.onHelpClicked();
        },
        url: null
    }];
    public isRaisingFundsEditMode: boolean = false;
    public isRaisingFunds: boolean = false;
    public isLeadInvestorIdentifiedEditMode: boolean = false;
    public isLeadInvestorIdentified: boolean = false;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _dialoger: MatDialog,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.isRaisingFunds = Boolean(this.project.isRaisingFunds);
        this.isLeadInvestorIdentified = Boolean(this.project.isLeadInvestorIdentified);
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onHelpClicked(): void {
        this._dialoger.open(InfoDialogComponent, {
            data: {
                text: 'Why_these_are_key_data'
            }
        });
    }

    public onEditIsRaisingFundsClicked(): void {
        this.isRaisingFundsEditMode = true;
        this.isRaisingFunds = Boolean(this.project.isRaisingFunds);
    }

    public onCancelEditIsRaisingFundsClicked(): void {
        this.isRaisingFundsEditMode = false;
    }

    public onEditIsLeadInvestorIdentifiedClicked(): void {
        this.isLeadInvestorIdentifiedEditMode = true;
        this.isLeadInvestorIdentified = Boolean(this.project.isLeadInvestorIdentified);
    }

    public onCancelEditIsLeadInvestorIdentifiedClicked(): void {
        this.isLeadInvestorIdentifiedEditMode = false;
    }

    public onSaveClicked(): void {
        this.isLoading = true;
        this.save()
            .then(() => {
                this.isLoading = false;
                this.isRaisingFundsEditMode = false;
                this.isLeadInvestorIdentifiedEditMode = false;
                this._toaster.open(this._translater.instant('Project_saved'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private save(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const project: Project = this.project.clone();
            project.isRaisingFunds = this.isRaisingFunds ? 1 : 0;
            project.isLeadInvestorIdentified = this.isLeadInvestorIdentified ? 1 : 0;
            this._projectsProvider.saveProject(project)
                .then((savedProject: Project) => {
                    this.project = savedProject;
                    this.projectChange.emit(this.project);

                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
