import { AfterViewInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Notification, NotificationsProvider } from '../../providers/notifications-provider';

@Component({
    selector: 'notifications-notifications-menu',
    providers: [],
    styleUrls: ['./notifications-menu.scss'],
    templateUrl: './notifications-menu.html'
})
export class NotificationsNotificationsMenuComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public notifications: NotificationsProvider,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onNotificationClicked(notification: Notification): void {

    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
