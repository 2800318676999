<div class="container">
    <div class="label">{{ label | translate }}</div>
    <div class="large-screen">
        <button (click)="onMarkClicked(availableMark)"
                *ngFor="let availableMark of availableMarks"
                [disabled]="disabled ? 'true' : null"
                [matTooltipPosition]="'above'"
                class="size-32"
                mat-icon-button
                matTooltip="{{ availableMark }}">
            <mat-icon *ngIf="availableMark <= roundedValue" class="size-16">{{ lowerIcon }}</mat-icon>
            <mat-icon *ngIf="availableMark > roundedValue" class="size-16">{{ greaterIcon }}</mat-icon>
        </button>
        <div class="selected-mark">{{ value }}</div>
    </div>
    <div class="small-screen">
        <mat-form-field *ngIf="!disabled"
                        class="mark-select no-min-width no-underline no-padding">
            <mat-select (change)="onMarkSelectChanged()"
                        [(ngModel)]="value"
                        placeholder="{{ 'Mark' | translate }}">
                <mat-option *ngFor="let availableMark of availableMarks" [value]="availableMark">
                    {{ availableMark }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="disabled" class="select-disabled">{{ value }}</div>
    </div>
</div>
