import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Conf } from '../../../_conf';
import { Project, User } from '../../../_common/providers/models';
import { UsersUserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog';

@Component({
    selector: 'users-user-sticker',
    providers: [],
    styleUrls: ['./user-sticker.scss'],
    templateUrl: './user-sticker.html'
})
export class UsersUserStickerComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public user: User = new User();
    @Input() public isEmailVisible: boolean = false;
    @Input() public isRegistrationDateVisible: boolean = false;
    @Input() public legend: string = '';
    @Input() public avatarHideable450: boolean = false;
    @Input() public avatarOnly: boolean = false;
    @Input() public avatarOnlyTooltip: string = null;
    @Input() public project: Project = null;
    @Input() public orientation: string = 'left';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _translater: TranslateService,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        if (!this.avatarOnlyTooltip) {
            this.avatarOnlyTooltip = this.user.firstName + ' ' + this.user.lastName;
            this._changeDetector.detectChanges();
        }
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onClicked(event: any): void {
        event.stopPropagation();
        const dialog: MatDialogRef<any> = this._dialoger.open(UsersUserProfileDialogComponent, {
            data: {
                user: this.user,
                project: this.project
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
