import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from '../../../_common/providers/utils';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { DiscussionsProvider, PrivateDiscussionData } from '../../providers/discussions-provider';
import { Conf } from '../../../_conf';
import { PrivateDiscussion, PrivateDiscussion_User, User } from '../../../_common/providers/models';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'discussions-edit-group-dialog',
    providers: [],
    styleUrls: ['./edit-group-dialog.scss'],
    templateUrl: './edit-group-dialog.html'
})
export class DiscussionsEditGroupDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public MAX_LENGTH: number = 100;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public title: string = 'New_group';
    public isLoading: boolean = true;

    public usersDataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public usersColumns: string[] = ['image', 'name', 'actions'];

    @ViewChild(MatPaginator, {static: false}) public usersPaginator: MatPaginator;

    public nameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    private _privateDiscussion: PrivateDiscussion = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public dialog: MatDialogRef<DiscussionsEditGroupDialogComponent>,
        public utils: Utils,
        private _toaster: MatSnackBar,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage,
        private _translater: TranslateService,
        private _discussions: DiscussionsProvider,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data.privateDiscussion) {
            this._privateDiscussion = _data.privateDiscussion;
            this.nameFormControl.setValue(this._privateDiscussion.name);
        } else {
            this._privateDiscussion = new PrivateDiscussion();
            this._privateDiscussion.isGroup = 1;
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadData()
            .then(() => {
                this.isLoading = false;
                this.usersDataSource.paginator = this.usersPaginator;
            })
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        return !this.isLoading
            && !this.nameFormControl.invalid;
    }

    public onFilterUsersKeyup(filter: string): void {
        this.usersDataSource.filter = filter.trim().toLowerCase();
    }

    public onSubmitClicked(): void {
        this.isLoading = true;
        this.save()
            .then((group: PrivateDiscussion) => {
                this._discussions.startGroupDiscussion(group)
                    .then(() => {
                        this._toaster.open(this._translater.instant('Group_saved'), '', {duration: 5000});
                        this.dialog.close({});
                    })
                    .catch((error: any) => this.onError(error));

            })
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(User)
                    .get(),
                this._storage.select(PrivateDiscussion_User)
                    .where('privateDiscussionId').equals(this._privateDiscussion.id)
                    .get()
            ])
                .then((data: any[]) => {
                    data[0].sort((a: User, b: User) => this.utils.sortRow(a.firstName + ' ' + a.lastName, b.firstName + ' ' + b.lastName));
                    if (this._privateDiscussion.discussionId) {

                    }

                    const rows: UsersListRow[] = [];
                    for (let i = 0; i < data[0].length; i++) {
                        if (data[0][i].id !== this._currentUser.user.id) {
                            rows.push({
                                user: data[0][i],
                                isSelected: this.utils.findIn(data[0][i].id, data[1], 'userId') !== null
                            });
                        }
                    }

                    this.usersDataSource.replaceAllWith(rows);

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private save(): Promise<PrivateDiscussion> {
        return new Promise<PrivateDiscussion>((resolve, reject) => {
            const privateDiscussion: PrivateDiscussion = this._privateDiscussion.clone();
            privateDiscussion.name = this.nameFormControl.value;

            const usersIds: number[] = [this._currentUser.user.id];
            for (let i = 0; i < this.usersDataSource.count; i++) {
                const row: UsersListRow = this.usersDataSource.get(i);
                if (row.isSelected) {
                    usersIds.push(row.user.id);
                }
            }

            this._discussions.savePrivateDiscussion(privateDiscussion, usersIds)
                .then((data: PrivateDiscussionData) => {
                    resolve(data.privateDiscussion);
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        this.isLoading = false;
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});

        console.log(error);
    }
}

export interface UsersListRow {
    user: User;
    isSelected: boolean;
}
