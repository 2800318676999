import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'projects-ask-changes-dialog',
    providers: [],
    styleUrls: ['./ask-changes-dialog.scss'],
    templateUrl: './ask-changes-dialog.html'
})
export class ProjectsAskChangesDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly MESSAGE_MAX_LENGTH: number = 500;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public messageFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.MESSAGE_MAX_LENGTH)
    ]);

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<ProjectsAskChangesDialogComponent>,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {

    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onConfirmClicked(): void {
        this.dialog.close({
            confirmed: true,
            message: this.messageFormControl.value
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
