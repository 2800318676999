import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { ProjectsProvider } from '../../providers/projects-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectMembership } from '../../../_common/providers/models';

@Component({
    selector: 'projects-dashboard-page',
    providers: [],
    styleUrls: ['./dashboard.scss'],
    templateUrl: './dashboard.html'
})
export class ProjectsDashboardPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public is404: boolean = false;
    public rights: ProjectRights = new ProjectRights(this._utils);

    public project: Project = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._route.params.subscribe((params: any) => {
            this.loadProject(parseInt(params.projectId))
                .then(() => {
                    this.loadData()
                        .then(() => {
                            this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);
                            this.isLoading = false;
                        })
                        .catch((error: any) => this.onError(error));
                })
                .catch((error: any) => this.onError(error));
        });
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadProject(projectId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(Project).where('id').equals(projectId).get(1)
                .then((project: Project) => {
                    if (!project) {
                        reject({status: 404});
                        return;
                    }

                    this.project = project;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                /* 0 */ this._storage.select(ProjectMembership)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('projectId').equals(this.project.id)
                    .get(1)
            ])
                .then((data: any[]) => {
                    this.rights.evaluate({
                        project: this.project,
                        user: this.currentUser.user,
                        membership: data[0]
                    });

                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        this.isLoading = false;
        console.log(error);

        if (error.status === 404) {
            this.is404 = true;
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }
    }
}
