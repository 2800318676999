import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { Utils } from '../../../_common/providers/utils';
import { CompanyDataRow, InvestorFiltersState, InvestorsProvider } from '../../providers/investors-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import {
    Alert,
    Company,
    Company_DevelopmentPhase,
    Company_ProjectType,
    Company_TherapeuticArea,
    CompanyCompanyCategory,
    CompanyContact,
    DevelopmentPhase,
    ProjectType,
    TherapeuticArea
} from '../../../_common/providers/models';
import { CompanyRights } from '../../providers/company-rights';
import { ModuleMenu } from '../../../_common/components/module-content/module-content';
import { ProjectsProvider, ProjectTypeGoupWithTypes } from '../../../projects/providers/projects-provider';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-list-companies',
    providers: [],
    styleUrls: ['./list-companies.scss'],
    templateUrl: './list-companies.html'
})
export class InvestorsListCompaniesComponent implements AfterViewInit, OnDestroy {

    //
    //
    // CONSTANTS
    //
    //

    public readonly AMOUNT_RAISED_MIN: number = 0;
    public readonly AMOUNT_RAISED_MAX: number = 99999999999; // 100 mia

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isReady: boolean = false;
    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public columns: string[] = [];

    public searchInput: FormControl = new FormControl('');
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhases: DevelopmentPhase[] = [];
    public projectTypes: ProjectType[] = [];
    public companyCategories: CompanyCompanyCategory[] = [];
    public projectTypeGroups: ProjectTypeGoupWithTypes[] = [];
    public projectTypesCheckboxes: boolean[][] = [];

    public companyCategoriesCheckboxes: boolean[] = [];
    public filteredCompanyCategories: CompanyCompanyCategory[] = [];
    public therapeuticAreasCheckboxes: boolean[] = [];
    public filteredTherapeuticAreas: TherapeuticArea[] = [];
    public developmentPhasesCheckboxes: boolean[] = [];
    public filteredDevelopmentPhases: DevelopmentPhase[] = [];
    public filteredProjectTypes: ProjectType[] = [];
    public filteredInvestingInPrivate: boolean;
    public filteredInvestingInPublic: boolean;
    public investmentRangeToFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.investmentRangeToFormControl, this.investmentRangeFromFormControl, null, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public investmentRangeFromFormControl: FormControl = new FormControl('', [
        (control: any) => this.checkMinMaxFormControl(this.investmentRangeFromFormControl, null, this.investmentRangeToFormControl, this.AMOUNT_RAISED_MIN, this.AMOUNT_RAISED_MAX),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public filteredOthers: Alert = new Alert(); // mini hack, on utilise directement cette structure pour binder les oui/non - c'est plus simple
    public moreFiltersSelectionLabel: string = null;

    @Input() public title: string = null;
    @Input() public noDataMessage: string = 'No_data';
    @Input() public hideNameColumn: boolean = false;
    @Input() public hideLogoColumn: boolean = false;
    @Input() public hideWebsiteColumn: boolean = false;
    @Input() public hideCategoryColumn: boolean = false;
    @Input() public hideActionsColumn: boolean = false;
    @Input() public hideIsDisplayedOnHomepageColumn: boolean = true;
    @Input() public disableSearchEngine: boolean = false;
    @Input() public isClickable: boolean = true;
    @Input() public isAddEnabled: boolean = false;
    @Output() public onRowClicked: EventEmitter<CompanyDataRow> = new EventEmitter<CompanyDataRow>();
    @Output() public onEditRowClicked: EventEmitter<CompanyDataRow> = new EventEmitter<CompanyDataRow>();
    @Output() public onDeleteRowClicked: EventEmitter<CompanyDataRow> = new EventEmitter<CompanyDataRow>();
    @Output() public onAddClicked: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    public addMenu: ModuleMenu = null;
    private _allRows: CompanyDataRow[] = [];

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _dialoger: MatDialog,
        private _translater: TranslateService,
        private _route: ActivatedRoute,
        private _toaster: MatSnackBar,
        private _router: Router,
        private _storage: ModelsStorage,
        private _investorsProvider: InvestorsProvider,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _companies: Company[] = [];

    public get companies() {
        return this._companies;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set companies(companies: Company[]) {
        this._companies = companies;
        if (this._companies && this._companies.length) {
            this.loadData(companies)
                .then((data: CompanyDataRow[]) => {
                    this._allRows = data;
                    this.applyFilters();
                })
                .catch((error: any) => this.onError(error));
        } else {
            this._allRows = [];
            this.applyFilters();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initColumns();
        this.initDataSource();
        this.loadStaticData()
            .then(() => {
                this._route.params.subscribe((params: any) => {
                    this.initFilters(null)
                        .then(() => this.isReady = true)
                        .catch((error: any) => this.onError(error));
                });
            })
            .catch((error: any) => this.onError(error));
        this.addMenu = {
            icon: 'add',
            title: 'Add_company',
            url: null,
            onClick: () => this.onAddClicked.emit()
        };
    }

    public ngOnDestroy() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public _onRowClicked(row: CompanyDataRow): void {
        if (this.isClickable) {
            this.onRowClicked.emit(row);
        }
    }

    public onWebsiteClicked(row: CompanyDataRow): void {
        let url: string = row.company.website;
        if (!url.startsWith('http')) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
    }

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    public hasFilters(): boolean {
        return this.filteredCompanyCategories.length > 0
            || this.filteredDevelopmentPhases.length > 0
            || this.filteredProjectTypes.length > 0
            || this.filteredTherapeuticAreas.length > 0
            || this.moreFiltersSelectionLabel != null;
    }

    public isMoreFiltersValid(): boolean {
        return !this.investmentRangeFromFormControl.invalid
            && !this.investmentRangeToFormControl.invalid;
    }

    public searchInputChanged(filter: string): void {
        this.dataSource.filter = this.utils.normalizeString(filter);
    }

    public onAllCompanyCategoriesClicked(event: any): void {
        event.stopPropagation();
        this.filteredCompanyCategories = this.companyCategories.slice();
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, true);
        this.applyFilters();
    }

    public onNoCompanyCategoriesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredCompanyCategories = [];
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, false);
        this.applyFilters();
    }

    public onCompanyCategoryClicked(event: any, category: CompanyCompanyCategory): void {
        setTimeout(() => {
            this.toggleFilterSelection(category, this.filteredCompanyCategories);
            this.applyFilters();
        }, 1);
    }

    public onAllDevelopmentPhasesClicked(event: any): void {
        event.stopPropagation();
        this.filteredDevelopmentPhases = this.developmentPhases.slice();
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, true);
        this.applyFilters();
    }

    public onAllProjectTypesClicked(event: any): void {
        event.stopPropagation();
        this.filteredProjectTypes = this.projectTypes.slice();
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', true);
        this.applyFilters();
    }

    public onNoProjectTypesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredProjectTypes = [];
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', false);
        this.applyFilters();
    }

    public onProjectTypeClicked(event: any, type: ProjectType): void {
        setTimeout(() => {
            this.toggleFilterSelection(type, this.filteredProjectTypes);
            this.applyFilters();
        }, 1);
    }

    public getGroupCheckboxIcon(items: any[], checkAgainst: any): string {
        const matchingItems: number[] = this.utils.findManyIn(this.utils.getKeyValues(checkAgainst), items, 'id');
        if (matchingItems.length === 0) {
            return 'check_box_outline_blank';
        } else if (matchingItems.length === items.length) {
            return 'check_box';
        }

        return 'indeterminate_check_box';
    }

    public onProjectTypeGroupClicked(event: any, row: ProjectTypeGoupWithTypes, rowIndex: number): void {
        const matchingItems: ProjectType[] = this.utils.findManyIn(
            this.utils.getKeyValues(row.types, 'id'),
            this.filteredProjectTypes,
            'id'
        );

        // if everything was already selected ...
        if (matchingItems.length === row.types.length) {
            // we remove everything
            row.types.forEach((item, index) => {
                this.filteredProjectTypes.splice(this.filteredProjectTypes.indexOf(row.types[index]), 1);
                this.projectTypesCheckboxes[rowIndex][index] = false;
            });
        } else {
            // otherwise we add the missing ones
            row.types.forEach((item, index) => {
                if (this.filteredProjectTypes.indexOf(row.types[index]) < 0) {
                    this.filteredProjectTypes.push(row.types[index]);
                    this.projectTypesCheckboxes[rowIndex][index] = true;
                }
            });
        }

        this.applyFilters();
    }

    public onNoDevelopmentPhasesClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.applyFilters();
    }

    public onDevelopmentPhaseClicked(event: any, phase: DevelopmentPhase): void {
        setTimeout(() => {
            this.toggleFilterSelection(phase, this.filteredDevelopmentPhases);
            this.applyFilters();
        }, 1);
    }

    public onAllTherapeuticAreasClicked(event: any): void {
        event.stopPropagation();
        this.filteredTherapeuticAreas = this.therapeuticAreas.slice();
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, true);
        this.applyFilters();
    }

    public onNoTherapeuticAreasClicked(event: any = null): void {
        if (event) {
            event.stopPropagation();
        }
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.applyFilters();
    }

    public onTherapeuticAreaClicked(event: any, area: TherapeuticArea): void {
        setTimeout(() => {
            this.toggleFilterSelection(area, this.filteredTherapeuticAreas);
            this.applyFilters();
        }, 1);
    }

    public stopPropagation(event): void {
        event.stopPropagation();
    }

    public onResetFiltersClicked(): void {
        this.filteredCompanyCategories = [];
        this.switchAllCheckboxes(this.companyCategoriesCheckboxes, this.companyCategories, false);
        this.filteredTherapeuticAreas = [];
        this.switchAllCheckboxes(this.therapeuticAreasCheckboxes, this.therapeuticAreas, false);
        this.filteredDevelopmentPhases = [];
        this.switchAllCheckboxes(this.developmentPhasesCheckboxes, this.developmentPhases, false);
        this.filteredProjectTypes = [];
        this.switchAllCheckboxesWithGroup(this.projectTypesCheckboxes, this.projectTypeGroups, 'types', false);
        this.filteredOthers.isSeekingPartnerhips = null;
        this.filteredOthers.isNotSeekingPartnerhips = null;
        this.filteredOthers.isOutlicensing = null;
        this.filteredOthers.isNotOutlicensing = null;
        this.filteredOthers.isNotForProfit = null;
        this.filteredOthers.isNotNotForProfit = null;
        this.filteredInvestingInPrivate = null;
        this.filteredInvestingInPublic = null;
        this.investmentRangeFromFormControl.setValue(null);
        this.investmentRangeToFormControl.setValue(null);
        this.moreFiltersSelectionLabel = null;

        this.applyFilters();
    }

    public onMoreFiltersItemClicked(event: any): void {
        setTimeout(() => {
            const selectionLabels: string[] = [];

            this.investmentRangeFromFormControl.updateValueAndValidity();
            this.investmentRangeToFormControl.updateValueAndValidity();
            if (this.investmentRangeFromFormControl.value
                || this.investmentRangeToFormControl.value) {
                selectionLabels.push('Investment_range');
            }

            if (this.filteredOthers.isSeekingPartnerhips
                || this.filteredOthers.isNotSeekingPartnerhips) {
                selectionLabels.push('Intersted_in_partnerships');
            }
            if (this.filteredOthers.isOutlicensing || this.filteredOthers.isNotOutlicensing) {
                selectionLabels.push('Intersted_to_in_licence_a_technology_of_product');
            }

            if (this.filteredInvestingInPublic || this.filteredInvestingInPrivate) {
                selectionLabels.push('Investing_in');
            }

            if (selectionLabels.length === 0) {
                this.moreFiltersSelectionLabel = null;
            } else if (selectionLabels.length === 1) {
                this.moreFiltersSelectionLabel = this._translater.instant(selectionLabels[0]);
            } else {
                this.moreFiltersSelectionLabel = this._translater.instant(
                    'n_filters',
                    {count: selectionLabels.length}
                );
            }

            this.applyFilters();
        }, 1);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initColumns(): void {
        const columns: string[] = [];
        if (!this.hideLogoColumn) {
            columns.push('logo');
        }
        if (!this.hideNameColumn) {
            columns.push('name');
        }
        if (!this.hideCategoryColumn) {
            columns.push('category');
        }
        if (!this.hideWebsiteColumn) {
            columns.push('website');
        }
        if (!this.hideIsDisplayedOnHomepageColumn) {
            columns.push('isDisplayedOnHomepage');
        }
        if (!this.hideActionsColumn) {
            columns.push('actions');
        }

        this.columns = columns;
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (row: CompanyDataRow, filter: string) => {
            return this.utils.normalizeString(
                (row.company ? row.company.name : '') +
                (row.companyCategory ? row.companyCategory.name : '')
            ).includes(filter);
        };
        // on utilise pas un bête keyup
        // parce que si on sélectionne le texte
        // et qu'on le coupe par exemple, on catch pas l'event ...
        this.searchInput.valueChanges.subscribe((value: string) => this.searchInputChanged(value));

        this.dataSource.addSortDefinition(
            'name',
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(a.company.name), this.utils.normalizeString(b.company.name)
            ),
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(b.company.name), this.utils.normalizeString(a.company.name)
            )
        );

        this.dataSource.addSortDefinition(
            'category',
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(a.companyCategory.name), this.utils.normalizeString(b.companyCategory.name)
            ),
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(b.companyCategory.name), this.utils.normalizeString(a.companyCategory.name)
            )
        );

        this.dataSource.addSortDefinition(
            'website',
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(a.company.website || ''), this.utils.normalizeString(b.company.website || '')
            ),
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                this.utils.normalizeString(b.company.website || ''), this.utils.normalizeString(a.company.website || '')
            )
        );

        this.dataSource.addSortDefinition(
            'isDisplayedOnHomepage',
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                a.company.isDisplayedOnHomepage ? 1 : 0, b.company.isDisplayedOnHomepage ? 1 : 0
            ),
            (a: CompanyDataRow, b: CompanyDataRow) => this.utils.sortRow(
                b.company.isDisplayedOnHomepage ? 1 : 0, a.company.isDisplayedOnHomepage ? 1 : 0
            )
        );
    }

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this._storage.select(TherapeuticArea).get(),
                this._storage.select(CompanyCompanyCategory).orderBy('ordinal').get(),
                this.projectsProvider.getProjectTypeGroups(),
                this._storage.select(ProjectType).get()
            ])
                .then((data: any[]) => {
                    this.developmentPhases = data[0];
                    this.therapeuticAreas = data[1];
                    this.companyCategories = data[2];
                    this.projectTypeGroups = data[3];
                    this.projectTypes = data[4];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private loadData(companies: Company[]): Promise<CompanyDataRow[]> {
        return new Promise<any>((resolve, reject) => {
            Promise.all([
                this._storage.select(Company)
                    .where('id').in(this.utils.getKeyValues(companies, 'id'))
                    .get(),
                this._storage.select(CompanyCompanyCategory).get(),
                this._storage.select(Company_TherapeuticArea).get(),
                this._storage.select(Company_DevelopmentPhase).get(),
                this._storage.select(CompanyContact).get(),
                this._storage.select(Company_ProjectType).get()
            ])
                .then((data: any[]) => {
                    const rows: CompanyDataRow[] = [];
                    companies.forEach((company: Company, i: number) => {
                        const companyCategory: CompanyCompanyCategory = this.utils.findIn(
                            company.companyCompanyCategoryId,
                            data[1]
                        );
                        const rights: CompanyRights = new CompanyRights(this.utils);
                        rights.evaluate({
                            user: this.currentUser.user,
                            company
                        });
                        const therapeuticAreasIdsOfInterest: number[] = this.utils.getKeyValues(
                            this.utils.findManyIn([company.id], data[2], 'companyId'),
                            'therapeuticAreaId'
                        );
                        const developmentPhasesIdsOfInterest: number[] = this.utils.getKeyValues(
                            this.utils.findManyIn([company.id], data[3], 'companyId'),
                            'developmentPhaseId'
                        );

                        const projectTypesIdsOfInterest: number[] = this.utils.getKeyValues(
                            this.utils.findManyIn([company.id], data[5], 'companyId'),
                            'projectTypeId'
                        );

                        rows.push({
                            company,
                            companyCategory,
                            companyContacts: this.utils.findManyIn([company.id], data[4], 'companyId'),
                            isLoading: false,
                            rights,
                            therapeuticAreasOfInterest: this.utils.findManyIn(
                                therapeuticAreasIdsOfInterest,
                                this.therapeuticAreas
                            ),
                            developmentPhasesOfInterest: this.utils.findManyIn(
                                developmentPhasesIdsOfInterest,
                                this.developmentPhases
                            ),
                            projectTypesOfInterest: this.utils.findManyIn(
                                projectTypesIdsOfInterest,
                                this.projectTypes
                            ),
                            projectTypesIdsOfInterest,
                            therapeuticAreasIdsOfInterest,
                            developmentPhasesIdsOfInterest
                        });
                    });
                    this.dataSource.replaceAllWith(rows);

                    resolve(rows);
                })
                .catch((error: any) => reject(error));
        });
    }

    private applyFilters(): void {
        const rows: CompanyDataRow[] = [];
        for (let i = 0; i < this._allRows.length; i++) {
            if (this.matchFilterSomeList([
                    this._allRows[i].companyCategory ? this._allRows[i].companyCategory.id : null
                ], this.filteredCompanyCategories)
                && this.matchFilterSomeList(this._allRows[i].therapeuticAreasIdsOfInterest, this.filteredTherapeuticAreas)
                && this.matchFilterSomeList(this._allRows[i].developmentPhasesIdsOfInterest, this.filteredDevelopmentPhases)
                && this.matchFilterSomeList(this._allRows[i].projectTypesIdsOfInterest, this.filteredProjectTypes)
                && this.matchFilterMoreFilters(this._allRows[i])
                && this.inFilterRange(this.investmentRangeFromFormControl.value * 100, this.investmentRangeToFormControl.value * 100, this._allRows[i].company.investmentRangeFrom, this._allRows[i].company.investmentRangeTo)) {
                rows.push(this._allRows[i]);
            }
        }

        this.dataSource.replaceAllWith(rows);
    }

    private switchAllCheckboxesWithGroup(list: boolean[][], data: any, keyName: string, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i][keyName].length; j++) {
                list[i][j] = value;
            }
        }
    }

    private switchAllCheckboxes(list: boolean[], data: any, value: boolean): void {
        for (let i = 0; i < data.length; i++) {
            list[i] = value;
        }
    }

    private checkMinMaxFormControl(targetControl: FormControl, minControl: FormControl, maxControl: FormControl, minFallback: number, maxFallback: number): any {
        if (!targetControl || !targetControl.value) {
            return null;
        }

        if (minControl && minControl.value && targetControl.value < minControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value < minFallback) {
            return {consistency: {valid: false}};
        }

        if (maxControl && maxControl.value && targetControl.value > maxControl.value) {
            return {consistency: {valid: false}};
        } else if (targetControl.value > maxFallback) {
            return {consistency: {valid: false}};
        }

        return null;
    }

    private inFilterRange(filterFrom: number, filterTo: number, valueFrom: number, valueTo: number): boolean {
        if (filterFrom) {
            if (!valueFrom || filterFrom > valueTo) {
                return false;
            }
        }

        if (filterTo) {
            if (!valueTo || filterTo < valueTo) {
                return false;
            }
        }

        return true;
    }

    private matchFilterSomeList(keys: number[], filters: any[]): boolean {
        // s'il n'y a pas de filter, ça match ...
        if (filters.length === 0) {
            return true;
        }

        // on prend pas en considération les clés qui seraient null ...
        const _keys: number[] = keys.filter((val: number) => val ? true : false);

        return this.utils.findManyIn(_keys, filters, 'id').length > 0;
    }

    private matchFilterYesNo(yesValue: number, noValue: number, actualValue: number): boolean {
        // si rien n'est coché, on ignore le filtre (l'utilisateur n'a pas renseigner l'info ...)
        //  si les deux sont cochés, on ignore le filtre
        //  (l'utilisateur indique explicitement qu'il veut les 2, et y a que 2 possibilités, donc ce sera forcément true)
        //  si un seul des deux est cochés, on check que ca match avec le model
        if (yesValue) {
            if (!noValue) {
                if (!(actualValue == 1)) {
                    return false;
                }
            }
        } else {
            if (noValue) {
                if (actualValue == 1) {
                    return false;
                }
            }
        }

        return true;
    }

    private matchFilterMoreFilters(row: CompanyDataRow): boolean {
        // *100 ; on a le montant en cents, et on l'utilisateur le saisit en dollars
        if (this.investmentRangeFromFormControl.value
            && (!row.company.investmentRangeFrom
                || (this.investmentRangeFromFormControl.value * 100) > row.company.investmentRangeFrom)
        ) {
            return false;
        }
        if (this.investmentRangeToFormControl.value
            && (!row.company.investmentRangeTo
                || (this.investmentRangeToFormControl.value * 100) < row.company.investmentRangeTo)
        ) {
            return false;
        }
        if (!this.matchFilterYesNo(
            this.filteredOthers.isSeekingPartnerhips,
            this.filteredOthers.isNotSeekingPartnerhips,
            row.company.isInterstedInPartnerships ? 1 : 0)
        ) {
            return false;
        }
        if (!this.matchFilterYesNo(
            this.filteredOthers.isOutlicensing,
            this.filteredOthers.isNotOutlicensing,
            row.company.isInterstedInLicenses ? 1 : 0)
        ) {
            return false;
        }

        if (this.filteredInvestingInPublic && !row.company.isInvestingInPublic) {
            return false;
        }
        if (this.filteredInvestingInPrivate && !row.company.isInvestingInPrivate) {
            return false;
        }

        return true;
    }

    private toggleFilterSelection(item: any, list: any[]): boolean {
        const index: number = list.indexOf(item);
        if (index >= 0) {
            list.splice(index, 1);

            return false;
        }

        list.push(item);

        return true;
    }

    private initFilters(filtersState: InvestorFiltersState): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (!filtersState) {
                filtersState = {
                    companyCategoryIds: [],
                    developmentPhaseIds: [],
                    therapeuticAreaIds: [],
                    projectTypeIds: [],
                    investmentRangeFrom: null,
                    investmentRangeTo: null,
                    isNoNotForProfit: 0,
                    isNotForProfit: 0,
                    isNotOutlicensing: 0,
                    isNotSeekingPartnerships: 0,
                    isOutlicensing: 0,
                    isSeekingPartnerships: 0,
                    pageIndex: 0
                };
            }

            this.filteredCompanyCategories = [];
            this.companyCategoriesCheckboxes = [];
            this.filteredTherapeuticAreas = [];
            this.therapeuticAreasCheckboxes = [];
            this.filteredDevelopmentPhases = [];
            this.developmentPhasesCheckboxes = [];
            this.filteredProjectTypes = [];
            this.projectTypesCheckboxes = [];

            this.companyCategories.forEach((item: CompanyCompanyCategory, i: number) => {
                if (filtersState.companyCategoryIds.indexOf(item.id) >= 0) {
                    this.companyCategoriesCheckboxes.push(true);
                    this.filteredCompanyCategories.push(item);
                } else {
                    this.companyCategoriesCheckboxes.push(false);
                }
            });

            this.therapeuticAreas.forEach((item: TherapeuticArea, i: number) => {
                if (filtersState.therapeuticAreaIds.indexOf(item.id) >= 0) {
                    this.therapeuticAreasCheckboxes.push(true);
                    this.filteredTherapeuticAreas.push(item);
                } else {
                    this.therapeuticAreasCheckboxes.push(false);
                }
            });

            this.developmentPhases.forEach((item: DevelopmentPhase, i: number) => {
                if (filtersState.developmentPhaseIds.indexOf(item.id) >= 0) {
                    this.developmentPhasesCheckboxes.push(true);
                    this.filteredDevelopmentPhases.push(item);
                } else {
                    this.developmentPhasesCheckboxes.push(false);
                }
            });

            this.projectTypeGroups.forEach((item: ProjectTypeGoupWithTypes, i: number) => {
                const rows: boolean[] = [];
                item.types.forEach((type: ProjectType, j: number) => {
                    if (filtersState.projectTypeIds.indexOf(type.id) >= 0) {
                        rows.push(true);
                        this.filteredProjectTypes.push(type);
                    } else {
                        rows.push(false);
                    }
                });
                this.projectTypesCheckboxes.push(rows);
            });

            this.investmentRangeFromFormControl.setValue(filtersState.investmentRangeFrom);
            this.investmentRangeToFormControl.setValue(filtersState.investmentRangeTo);
            this.filteredOthers.isNotForProfit = filtersState.isNotForProfit;
            this.filteredOthers.isNotNotForProfit = filtersState.isNoNotForProfit;
            this.filteredOthers.isOutlicensing = filtersState.isOutlicensing;
            this.filteredOthers.isNotOutlicensing = filtersState.isNotOutlicensing;
            this.filteredOthers.isSeekingPartnerhips = filtersState.isSeekingPartnerships;
            this.filteredOthers.isNotSeekingPartnerhips = filtersState.isNotSeekingPartnerships;
            this.paginator.pageIndex = filtersState.pageIndex;

            this.onMoreFiltersItemClicked({}); // vilain hack pour refresh le control ...

            this.applyFilters();
            resolve();
        });
    }

    private onError(error: any): void {
        console.log(error);
        this.isReady = true;
    }
}
