<module-content>
    <div class="line">
        <true-false-radio (change)="_onFormChanged()"
                          [(ngModel)]="isSeekingPartnership"
                          [disabled]="!rights.EDIT"
                          [label]="'Seeking_partnership' | translate">
        </true-false-radio>
        <mat-form-field *ngIf="isSeekingPartnership"
                        [hintLabel]="'Partnership_terms_hint' | translate"
                        color="primary">
            <textarea (keyup)="_onFormChanged()"
                      [formControl]="partnershipTermsFormControl"
                      [maxlength]="PARTNERSHIP_TERMS_MAX_LENGTH"
                      [placeholder]="'Partnership_terms' | translate"
                      matInput
                      matTextareaAutosize></textarea>
            <mat-error *ngIf="partnershipTermsFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ partnershipTermsFormControl.value.length }}
                /{{ PARTNERSHIP_TERMS_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="line">
        <true-false-radio (change)="_onFormChanged()"
                          [(ngModel)]="isOutlicensing"
                          [disabled]="!rights.EDIT"
                          [label]="'Licensing_opportunity' | translate">
        </true-false-radio>
        <mat-form-field *ngIf="isOutlicensing"
                        [hintLabel]="'outlicensing_hint' | translate"
                        color="primary">
            <textarea (keyup)="_onFormChanged()"
                      [formControl]="outlicensingFormControl"
                      [maxlength]="OUTLICENSING_MAX_LENGTH"
                      [placeholder]="'Outlicensing_offer' | translate"
                      matInput
                      matTextareaAutosize></textarea>
            <mat-error *ngIf="outlicensingFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ outlicensingFormControl.value.length }}/{{ OUTLICENSING_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
</module-content>
