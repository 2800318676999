<div [style.backgroundImage]="'url(\'' + conf.assetsUrl + 'img/home/section-1-background.jpg\')'"
     class="section-1">
    <!-- https://www.shutterstock.com/fr/video/clip-33795343?irgwc=1&utm_medium=Affiliate&utm_campaign=Eezy%2C+LLC&utm_source=38919&utm_term=www.videezy.com -->
    <!--
    <video autoplay
            muted
            loop>
        <source [src]="conf.assetsUrl + 'videos/home.mp4'" type="video/mp4">
    </video>
    -->
    <div class="card">
        {{ 'home_section1_text1' | translate }}
    </div>
    <acventures-common-company-logos *ngIf="companies.length"
                                     [companies]="companies">
    </acventures-common-company-logos>
    <div class="call-to-action-container">
        <ng-content></ng-content>
    </div>
</div>
