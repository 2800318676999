<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header header>
    </header>
    <div class="card-wrapper">
        <module-content
            *ngIf="!rights.LIST_PROJECTS && investorProfile && investorProfile.investorValidationStateId == INVESTOR_VALIDATION_STATE_PENDING">
            <div class="info">
                <mat-icon>info_outline</mat-icon>
                {{ 'You_can_not_access_here_yet' | translate }}
                <button [routerLink]="'/welcome'"
                        class="align-right"
                        color="accent"
                        mat-raised-button>
                    {{ 'Complete_account' | translate }}
                </button>
            </div>
        </module-content>
    </div>
    <module-content
        *ngIf="investorProfile && investorProfile.investorValidationStateId === INVESTOR_VALIDATION_STATE_ACCEPTED && company && !company.isCompleted">
        <div class="info">
            <mat-icon>info_outline</mat-icon>
            {{ 'company_incomplete_warning' | translate }}
            <button [routerLink]="'/investors/companies/' + company.id + '/edit'"
                    class="align-right"
                    color="accent"
                    mat-raised-button>
                {{ 'complete_company_action' | translate }}
            </button>
        </div>
    </module-content>
    <error-401
        *ngIf="!rights.LIST_PROJECTS && (!investorProfile || investorProfile.investorValidationStateId != INVESTOR_VALIDATION_STATE_PENDING)"></error-401>
    <project-cases-list
        (onDeleteClicked)="onDeleteClicked($event)"
        (onEditClicked)="onEditClicked($event)"
        (onFavoriteClicked)="onFavoriteClicked($event)"
        (onRowClicked)="onRowClicked($event)"
        *ngIf="rights.LIST_PROJECTS && projectCases.length > 0 && (!investorProfile || (company && company.isCompleted))"
        [investorProfile]="investorProfile"
        [preserveFilters]="true"
        [projectCases]="projectCases">
    </project-cases-list>
    <module-content *ngIf="projectCases.length === 0"
                    class="legend">
        <p>{{ 'No_projects' | translate }}</p>
    </module-content>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
