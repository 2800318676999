import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {
    public units: string[] = ['o', 'ko', 'Mo', 'Go', 'To'];

    constructor() {
    }

    public transform(str: string, unit: string) {
        let unitIndex = this.units.indexOf(unit);
        if (unitIndex < 0) {
            unitIndex = 0;
        }

        let value: number = parseFloat(str);
        while (value > 1024) {
            value = value / 1024;
            unitIndex++;
        }

        return Math.round(value) + ' ' + this.units[unitIndex];
    }
}
