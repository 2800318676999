import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { Utils } from '../../../_common/providers/utils';
import { FilesFilesComponent } from '../../../files/components/files/files';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ImageData } from '../../../_common/components/input-image/input-image';
import { ProjectRights } from '../../providers/project-rights';
import { File, FileType, Project, ProjectValidationState } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-project',
    providers: [],
    styleUrls: ['./edit-project-project.scss'],
    templateUrl: './edit-project-project.html'
})
export class ProjectsEditProjectProjectComponent {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 250;
    public readonly SUBTITLE_MAX_LENGTH: number = 250;
    public readonly DESCRIPTION_MAX_LENGTH: number = 5000;
    public readonly BIOTECH_URL_MAX_LENGTH: number = 255;
    public readonly UNIQUENESS_MAX_LENGTH: number = 2000;
    public readonly PIPELINE_MAX_LENGTH: number = 2000;
    public readonly UNMET_NEED_MAX_LENGTH: number = 2000;
    public readonly MARKET_SIZE_MAX_LENGTH: number = 2000;
    public readonly COMPETITION_MAX_LENGTH: number = 2000;
    public readonly KEYWORDS_MAX_LENGTH: number = 250;
    public FILES_EDITION_LEVEL: number = FilesFilesComponent.EDITION_LEVEL_ALL_FILES;
    public FILE_MAX_SIZE: number = 10;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showMore: boolean = false;

    public nameFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public pipelineFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.PIPELINE_MAX_LENGTH)
    ]);
    public keywordsFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.KEYWORDS_MAX_LENGTH)
    ]);
    public projectTypeFormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);
    public therapeuticAreaFormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);
    public biotechUrlFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.BIOTECH_URL_MAX_LENGTH)
    ]);
    public logoPreview: any = null;
    public logoImageData: ImageData = {current: null, new: null};
    public illustrationPreview: any = null;
    public illustrationImageData: ImageData = {current: null, new: null};
    public files: File[] = [];
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.nameFormControl.setValue(project.name ? project.name : '');
        this.pipelineFormControl.setValue(project.pipeline ? project.pipeline : '');
        this.keywordsFormControl.setValue(project.keywords ? project.keywords : '');
        this.biotechUrlFormControl.setValue(project.biotechDirectoryUrl ? project.biotechDirectoryUrl : '');
        this.logoImageData.current = project.logo;
        this.illustrationImageData.current = project.illustration;
        this._filesProvider.initPreviewImage((data: any) => this.logoPreview = data, this.logoImageData, 'projects/' + this.project.id + '/download-logo');
        this._filesProvider.initPreviewImage((data: any) => this.illustrationPreview = data, this.illustrationImageData, 'projects/' + this.project.id + '/download-illustration');
        this.loadFiles().catch((error: any) => this.onError(error));
        this.updateRights();

        this.showMore = !(project && ([ProjectValidationState.ID_DRAFT, ProjectValidationState.ID_PENDING_VALIDATION].indexOf(project.projectValidationStateId) >= 0));
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onFileRenamed(file: File): void {
        this.isLoading = true;
        this.updateFiles(file)
            .then(() => {
                this.isLoading = false;
                const text: string = file.fileTypeId === FileType.ID_FOLDER ? 'Folder_saved' : 'Files_saved';
                this._toaster.open(this._translater.instant(text), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onFilesAdded(files: File[]): void {
        if (!files.length) {
            return;
        }

        this.isLoading = true;
        this.addFiles(files)
            .then(() => {
                this.isLoading = false;
                const text: string = files[0].fileTypeId === FileType.ID_FOLDER ? 'Folder_saved' : 'Files_saved';
                this._toaster.open(this._translater.instant(text), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onFileRemoved(file: File): void {
        this.isLoading = true;
        this.deleteFile(file)
            .then(() => {
                this.isLoading = false;
                const text: string = file.fileTypeId === FileType.ID_FOLDER ? 'Folder_deleted' : 'File_deleted';
                this._toaster.open(this._translater.instant(text), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public canSave(): boolean {
        return !this.keywordsFormControl.invalid
            && !this.nameFormControl.invalid
            && !this.pipelineFormControl.invalid
            && !this.projectTypeFormControl.invalid
            && !this.therapeuticAreaFormControl.invalid;
    }

    public onToggleShowMore(): void {
        this.showMore = !this.showMore;
        if (!this.showMore) {
            setTimeout(() => {
                document.getElementsByTagName('html')[0].scrollTo({
                    left: 0,
                    top: 96,
                    behavior: 'smooth'
                });
            }, 10);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.nameFormControl.enable();
            this.projectTypeFormControl.enable();
            this.therapeuticAreaFormControl.enable();
            this.pipelineFormControl.enable();
            this.keywordsFormControl.enable();
            this.biotechUrlFormControl.enable();
            this.FILES_EDITION_LEVEL = FilesFilesComponent.EDITION_LEVEL_ALL_FILES;
        } else {
            this.nameFormControl.disable();
            this.projectTypeFormControl.disable();
            this.therapeuticAreaFormControl.disable();
            this.pipelineFormControl.disable();
            this.keywordsFormControl.disable();
            this.biotechUrlFormControl.disable();
            this.FILES_EDITION_LEVEL = FilesFilesComponent.EDITION_LEVEL_NONE;
        }
    }

    private loadFiles(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(File).where('fileGroupId').equals(this._project.fileGroupId).get()
                .then((files: File[]) => {
                    this.files = files;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private addFiles(files: File[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.projectsProvider.addFiles(this._project, files)
                .then((data: File[]) => {
                    this.files = this.files.concat(data);// TODO tester (que ca refresh aussi)

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateFiles(file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.projectsProvider.updateFile(this._project, file)
                .then((data: File) => {
                    for (let i = 0; i < this.files.length; i++) {
                        if (data.id === this.files[i].id) {
                            this.files[i] = data;
                            i = this.files.length;
                        }
                    }

                    this.files = this.files.slice(); // pour trigger un refresh
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private deleteFile(file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.projectsProvider.deleteFile(this._project, file)
                .then(() => {
                    for (let i = 0; i < this.files.length; i++) {
                        if (this.files[i].id === file.id) {
                            this.files.splice(i, 1);
                            i = this.files.length;
                        }
                    }

                    this.files = this.files.slice(); // pour trigger un refresh
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private fireChange(): void {
        this._project.name = this.nameFormControl.value;
        this._project.pipeline = this.pipelineFormControl.value;
        this._project.keywords = this.keywordsFormControl.value;
        this._project.logo = this.logoImageData.new ? this.logoImageData.new : this.logoImageData.current;
        this._project.illustration = this.illustrationImageData.new ? this.illustrationImageData.new : this.illustrationImageData.current;
        this._project.biotechDirectoryUrl = this.biotechUrlFormControl.value;

        this.projectChange.emit(this._project);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
