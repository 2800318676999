import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AcademicInstitutionGroupWithInstitution, ProjectsProvider } from '../../providers/projects-provider';
import { SyncServiceProvider } from '../../../_common/providers/sync-service';
import { CurrentUser } from '../../../_common/providers/current-user';
import { AcademicInstitutionGroup } from '../../../_common/providers/models';
import { Utils } from '../../../_common/providers/utils';

@Component({
    selector: 'projects-register-dialog',
    providers: [],
    styleUrls: ['./register-dialog.scss'],
    templateUrl: './register-dialog.html'
})
export class ProjectsRegisterDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 100;
    public readonly EMAIL_MAX_LENGTH: number = 200;
    public readonly PASSWORD_MAX_LENGTH: number = 200;
    public readonly PASSWORD_MIN_LENGTH: number = 8;
    public readonly EXCLUDED_INSTITUTION1_GROUP_IDS: number[] = [AcademicInstitutionGroup.ID_OTHER];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showPassword: boolean = false;

    public firstNameFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public lastNameFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public emailUnavailableError: boolean = false;
    public emailFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.EMAIL_MAX_LENGTH),
        Validators.email,
        Validators.pattern(this.utils.EMAIL_REGEX),
        (control: any) => {
            return this.emailUnavailableError ? {unavailable: {valid: false}} : null;
        }
    ]);
    public passwordFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.PASSWORD_MAX_LENGTH),
        Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]);
    public terms: boolean = false;

    public academicInstitionGroups: AcademicInstitutionGroupWithInstitution[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<ProjectsRegisterDialogComponent>,
        public utils: Utils,
        private _translater: TranslateService,
        private _projectsProvider: ProjectsProvider,
        private _sync: SyncServiceProvider,
        private _currentUser: CurrentUser,
        private _router: Router,
        private _toaster: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {

    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRegisterClicked(): void {
        this.isLoading = true;
        this.register()
            .then(() => {
                this.dialog.afterClosed().subscribe(() => this._router.navigate(['academic-ventures', 'welcome']));
                this.dialog.close();
                this._toaster.open(this._translater.instant('Account_created'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onEmailChanged(): void {
        this.emailUnavailableError = false;
        this.emailFormControl.updateValueAndValidity();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._projectsProvider.getAcademicInstitionGroups()
            ])
                .then((data: any[]) => {
                    this.academicInstitionGroups = data[0];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private register(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._projectsProvider.registerAccount(
                this.firstNameFormControl.value,
                this.lastNameFormControl.value,
                this.emailFormControl.value,
                this.passwordFormControl.value
            )
                .then(() => {
                    this._currentUser.login(this.emailFormControl.value, this.passwordFormControl.value, false)
                        .then(() => {
                            this._sync.sync(true, 0)
                                .then(() => {
                                    resolve();
                                })
                                .catch((error) => reject(error));
                        })
                        .catch((error) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);
        this.isLoading = false;

        if (error.status === 422 && error.error.errors['email']) {
            this.emailUnavailableError = true;
            this.emailFormControl.updateValueAndValidity();
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }
    }
}
