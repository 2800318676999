import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { CompanyDataRow, InvestorsProvider } from '../../providers/investors-provider';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { Company, Project, ProjectValidationState } from '../../../_common/providers/models';
import { CompanyRights } from '../../providers/company-rights';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-companies-page',
    providers: [],
    styleUrls: ['./companies.scss'],
    templateUrl: './companies.html'
})
export class InvestorsCompaniesPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public companies: Company[] = [];
    public rights: CompanyRights = new CompanyRights(this._utils);
    public accessBlocked: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _investorsProvider: InvestorsProvider,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        const pageTitle = this._translater.instant('Companies') + ' | ' + this.conf.platformName;
        this._title.setTitle(pageTitle);
        this.loadData()
            .then(() => {
                this.isLoading = false;
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRowClicked(row: CompanyDataRow): void {
        this._router.navigate(['investors', 'companies', row.company.id]);
    }

    public onDeleteRowClicked(row: CompanyDataRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_company'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this.deleteCompany(row)
                    .then(() => {
                        this.companies = this.companies.slice();
                        const message = this._translater.instant('Company_deleted');
                        this._toaster.open(message, '', {duration: 5000});
                    })
                    .catch((error: any) => this.onError(error, row));
            }
        });
    }

    public onEditRowClicked(row: CompanyDataRow): void {
        this._router.navigate(['investors', 'companies', row.company.id, 'edit']);
    }

    public onAddClicked(): void {
        this._router.navigate(['investors', 'companies', 'new']);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private async loadData(): Promise<any> {
        try {
            const companies: Company[] = await this._storage.select(Company).get();

            if (this.currentUser.user.isProjectHolder) {
                const isProjectValidated = await this._storage.select(Project).where('ownerId').equals(this.currentUser.user.id)
                    .and('projectValidationStateId').equals(ProjectValidationState.ID_PUBLISHED).get(1);
                this.accessBlocked = !isProjectValidated;
            }

            companies.sort((a: Company, b: Company) => this._utils.sortRow(
                this._utils.normalizeString(a.name),
                this._utils.normalizeString(b.name)
            ));
            this.companies = companies;
            this.rights.evaluate({
                user: this.currentUser.user,
                company: null
            });

        } catch (e) {
            this.onError(e);
        }
    }

    private deleteCompany(companyData: CompanyDataRow): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._investorsProvider.deleteCompany(companyData.company)
                .then(() => {
                    const rowIndex = this.companies.findIndex((item: Company) => item.id === companyData.company.id);
                    this.companies.splice(rowIndex, 1);
                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any, row: CompanyDataRow = null): void {
        this.isLoading = false;
        if (row) {
            row.isLoading = false;
        }
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
