import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

import '../styles/styles.scss';

//
//
// CUSTOM STUFF IMPORTS
//
//
//
// _COMMON
//
import { Conf } from './_conf';

import { AuthComponent } from './_common/components/auth/auth';
import { AuthDialogComponent } from './_common/components/auth/auth-dialog/auth-dialog';
import { ConfirmDialogComponent } from './_common/components/confirm-dialog/confirm-dialog';
import { DataListComponent } from './_common/components/data-list/data-list';
import { EmbedMapComponent } from './_common/components/embed-map/embed-map';
import { Error401Component } from './_common/components/error-401/error-401';
import { Error404Component } from './_common/components/error-404/error-404';
import { FooterComponent } from './_common/components/footer/footer';
import { FullScreenLoaderComponent } from './_common/components/full-screen-loader/full-screen-loader';
import { FullWidthLoaderComponent } from './_common/components/full-width-loader/full-width-loader';
import { HeaderComponent } from './_common/components/header/header';
import { InfoDialogComponent } from './_common/components/info-dialog/info-dialog';
import { InputImageComponent } from './_common/components/input-image/input-image';
import { MainActionComponent } from './_common/components/main-action/main-action';
import { ModuleContentComponent } from './_common/components/module-content/module-content';
import { NamePickerComponent } from './_common/components/name-picker/name-picker';
import { PageContentComponent } from './_common/components/page-content/page-content';
import { SideMenuComponent } from './_common/components/side-menu/side-menu';
import { SliderControlComponent } from './_common/components/slider-control/slider-control';
import { WorkspaceSelectorComponent } from './_common/components/workspace-selector/workspace-selector';
import { TrueFalseRadioComponent } from './_common/components/true-false-radio/true-false-radio';
import { ItemListDialogComponent } from './_common/components/item-list-dialog/item-list-dialog';
import { ExtensiblePComponent } from './_common/components/extensible-p/extensible-p';

import { GuardsSequenceGuard } from './_common/guards/guards-sequence';
import { HasHomepageRedirectGuard } from './_common/guards/has-homepage-redirect';
import { HasPendingChangesGuard } from './_common/guards/has-pending-changes';
import { IsAdminGuard } from './_common/guards/is-admin';
import { IsAppReadyGuard } from './_common/guards/is-app-ready';
import { IsLoggedInGuard } from './_common/guards/is-logged-in';

import { Error401PageComponent } from './_common/pages/error-401/error-401';
import { Error404PageComponent } from './_common/pages/error-404/error-404';
import { HomePageComponent } from './_common/pages/home/home';
import { LogoutPageComponent } from './_common/pages/logout/logout';
import { TermsPageComponent } from './_common/pages/terms/terms';

import { AutoLinkerPipe } from './_common/pipes/auto-linker';
import { DateClosestPipe } from './_common/pipes/date-closest';
import { DurationPipe } from './_common/pipes/duration';
import { EllipsizePipe } from './_common/pipes/ellipsize';
import { FileSizePipe } from './_common/pipes/file-size';
import { SafePipe } from './_common/pipes/safe';
import { CurrencyPipe } from '@angular/common';
import { TranslateCutPipe } from './_common/pipes/translate-cut';
import { MyStripHttpPipe } from './_common/pipes/strip-http';

import { ApiBaseService } from './_common/providers/api-base-service';
import { CurrentUser } from './_common/providers/current-user';
import { KeyValueStorage } from './_common/providers/key-value-storage';
import { LangsProvider } from './_common/providers/langs-provider';
import { ModelsStorage } from './_common/providers/models-storage';
import { SyncServiceProvider } from './_common/providers/sync-service';
import { Utils } from './_common/providers/utils';
import { TranslatablePaginatorIntl } from './_common/providers/translatable-paginator-intl';

//
// DISCUSSIONS
//
import { DiscussionsDiscussionComponent } from './discussions/components/discussion/discussion';
import { DiscussionsMessageComponent } from './discussions/components/message/message';
import {
    DiscussionsPrivateDiscussionWindowComponent
} from './discussions/components/private-discussion-window/private-discussion-window';
import {
    DiscussionsPrivateDiscussionsMenuComponent
} from './discussions/components/private-discussions-menu/private-discussions-menu';
import { DiscussionsEditGroupDialogComponent } from './discussions/components/edit-group-dialog/edit-group-dialog';

import { DiscussionsProvider } from './discussions/providers/discussions-provider';

//
// FILES
//
import { FilesFilesComponent } from './files/components/files/files';
import { FilesImageViewerDialogComponent } from './files/components/image-viewer-dialog/image-viewer-dialog';

import { FilesProvider } from './files/providers/files-provider';

//
// LIKE_COUNTERS
//
import { LikeCountersLikeCounterComponent } from './like-counters/components/like-counter/like-counter';

import { LikeCountersProvider } from './like-counters/providers/like-counters-provider';

//
// NOTIFICATIONS
//
import { NotificationsNotificationsMenuComponent } from './notifications/components/notifications-menu/notifications-menu';

import { NotificationsProvider } from './notifications/providers/notifications-provider';
import { NotificationsTodosProvider } from './notifications/providers/todos-provider';

//
// REVIEW-MARKS
//
import { ReviewMarksReviewMarkSelectComponent } from './review-marks/components/review-mark-select/review-mark-select';

//
// TAGS
//
import { TagsTagComponent } from './tags/components/tag/tag';
import { TagsTagPickerComponent } from './tags/components/tag-picker/tag-picker';
import { TagsTagSelectComponent } from './tags/components/tag-select/tag-select';

//
// USERS
//
import { UsersChangePasswordDialogComponent } from './users/components/change-password-dialog/change-password-dialog';
import { UsersPickUsersDialogComponent } from './users/components/pick-users-dialog/pick-users-dialog';
import { UsersUserMenuComponent } from './users/components/user-menu/user-menu';
import { UsersUserProfileDialogComponent } from './users/components/user-profile-dialog/user-profile-dialog';
import { UsersUserStickerComponent } from './users/components/user-sticker/user-sticker';
import { UsersUserPickerComponent } from './users/components/user-picker/user-picker';
import { UsersListComponent } from './users/components/list/list';
import { UsersAddUserDialogComponent } from './users/components/add-user-dialog/add-user-dialog';
import { UsersLocalExpertsListComponent } from './users/components/local-experts-list/local-experts-list';

import { UsersCanEditUserGuard } from './users/guards/can-edit-user';

import { UsersEditPageComponent } from './users/pages/edit/edit';
import { UsersAdminPageComponent } from './users/pages/admins/admins';
import { UsersGuestsPageComponent } from './users/pages/guests/guests';
import { UsersLocalExpertsPageComponent } from './users/pages/local-experts/local-experts';

import { UsersProvider } from './users/providers/users-provider';

//
// ACVENTURES_COMMON
//
import { AcventuresCommonHomeComponent } from './_acventures-common/components/home-welcome/home-welcome';
import { AcventuresCommonHomeShowroomComponent } from './_acventures-common/components/home-showroom/home-showroom';
import { AcventuresCommonCompanyLogosComponent } from './_acventures-common/components/company-logos/company-logos';
import {
    AcventuresCommonHomeShowroomDialogComponent
} from './_acventures-common/components/home-showroom-dialog/home-showroom-dialog';

import { AcventuresNavigationProvider } from './_acventures-common/providers/navigation-provider';

//
// PROJECTS
//
import { ProjectsEditProjectProjectComponent } from './projects/components/edit-project-project/edit-project-project';
import { ProjectsEditProjectSituationComponent } from './projects/components/edit-project-situation/edit-project-situation';
import {
    ProjectsEditProjectPartnershipComponent
} from './projects/components/edit-project-partnership/edit-project-partnership';
import {
    ProjectsEditProjectMiscellaneousComponent
} from './projects/components/edit-project-miscellaneous/edit-project-miscellaneous';
import { ProjectsEditProjectFundingComponent } from './projects/components/edit-project-funding/edit-project-funding';
import { ProjectsEditProjectTeamComponent } from './projects/components/edit-project-team/edit-project-team';
import { ProjectsEditProjectReviewComponent } from './projects/components/edit-project-review/edit-project-review';
import { ProjectsEditProjectAcademicComponent } from './projects/components/edit-project-academic/edit-project-academic';
import { ProjectsProjectStateIndicatorComponent } from './projects/components/project-state-indicator/project-state-indicator';
import { ProjectsListComponent } from './projects/components/list/list';
import { ProjectsFilterBlockComponent } from './projects/components/filter-block/filter-block';
import { ProjectsAskChangesDialogComponent } from './projects/components/ask-changes-dialog/ask-changes-dialog';
import { ProjectsRegisterDialogComponent } from './projects/components/register-dialog/register-dialog';
import { ProjectsDashboardOverviewComponent } from './projects/components/dashboard-overview/dashboard-overview';
import { ProjectsDashboardKeyDataComponent } from './projects/components/dashboard-key-data/dashboard-key-data';
import { ProjectsDashboardMetricsComponent } from './projects/components/dashboard-metrics/dashboard-metrics';
import { ProjectsCreateDialogComponent } from './projects/components/create-dialog/create-dialog';
import { ProjectsHomeIntroductionComponent } from './projects/components/home-introduction/home-introduction';

import { ProjectsEditPageComponent } from './projects/pages/edit/edit';
import { ProjectsProjectsPageComponent } from './projects/pages/projects/projects';
import { ProjectsProjectPageComponent } from './projects/pages/project/project';
import { ProjectsFavoritesPageComponent } from './projects/pages/favorites/favorites';
import { ProjectsPendingValidationPageComponent } from './projects/pages/pending-validation/pending-validation';
import { ProjectsNotProjectYetPageComponent } from './projects/pages/no-project-yet/no-project-yet';
import { ProjectsDraftsPageComponent } from './projects/pages/drafts/drafts';
import { ProjectsDispatchPageComponent } from './projects/pages/dispatch/dispatch';
import { ProjectsDashboardPageComponent } from './projects/pages/dashboard/dashboard';
import { ProjectsHomePageComponent } from './projects/pages/home/home';

import { ProjectsProvider } from './projects/providers/projects-provider';

//
// INVESTORS
//
import { InvestorsEditInvestorProfileComponent } from './investors/components/edit-investor-profile/edit-investor-profile';
import {
    InvestorsInvestorStateIndicatorComponent
} from './investors/components/investor-state-indicator/investor-state-indicator';
import { InvestorsListComponent } from './investors/components/list/list';
import { InvestorsRegisterDialogComponent } from './investors/components/register-dialog/register-dialog';
import { InvestorsHomeIntroductionComponent } from './investors/components/home-introduction/home-introduction';
import { InvestorsListCompaniesComponent } from './investors/components/list-companies/list-companies';

import { InvestorsPendingValidationPageComponent } from './investors/pages/pending-validation/pending-validation';
import { InvestorsInvestorsPageComponent } from './investors/pages/investors/investors';
import { InvestorsHomePageComponent } from './investors/pages/home/home';
import { InvestorsWelcomePageComponent } from './investors/pages/welcome/welcome';
import { InvestorsCompaniesPageComponent } from './investors/pages/companies/companies';
import { InvestorsEditCompanyPageComponent } from './investors/pages/edit-company/edit-company';
import { InvestorsCompanyPageComponent } from './investors/pages/company/company';

import { CanSeeCompanies } from './investors/guards/can-see-companies';

import { InvestorsProvider } from './investors/providers/investors-provider';

//
// ALERTS
//
import { AlertsListComponent } from './alerts/components/list/list';

import { AlertsAlertsPageComponent } from './alerts/pages/alerts/alerts';
import { AlertsAlertPageComponent } from './alerts/pages/alert/alert';

import { AlertsProvider } from './alerts/providers/alerts-provider';

//
// THIRD PARTY LIBS
//
// Translations
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InviteUserDialogComponent } from './project-membership/invite-user-dialog/invite-user-dialog';
import { ProjectMembershipListComponent } from './project-membership/components/list/list';
import { ProjectsMembershipsPageComponent } from './projects/pages/memberships/memberships';
import { ProjectsMembershipsProvider } from './project-membership/providers/projects-memberships-provider';
// charts
import { ChartCommonModule, LineChartModule, PieChartModule } from '@swimlane/ngx-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectsEditProjectCaseComponent } from './projects/components/edit-project-case/edit-project-case';
import { ProjectsViewProjectCaseComponent } from './projects/components/view-project-case/view-project-case';
import { ProjectCasesListComponent } from './projects/components/list-project-cases/list-project-cases';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CanSeeCompany } from './investors/guards/can-see-company';
import { ListBaseComponent } from './_common/components/list-base/list-base';
import { PublicPrivateRadioComponent } from './_common/components/public-private-radio/public-private-radio';
import { RichTextEditorModule } from './rich-text-editor/rich-text-editor.module';
import { QuillModule } from 'ngx-quill';
import { InvestorsDashboardPageComponent } from './investors/pages/dashboard/dashboard';
import { FavoritesListComponent } from './projects/components/favorites-list/favorites-list';
import { MatPaginatorIntl } from '@angular/material/paginator';

export function createTranslateLoader(http: HttpClient, conf: Conf) {
    return new TranslateHttpLoader(http, conf.assetsUrl + 'i18n/', '.json');
}

// Autolinker : https://github.com/gregjacobs/Autolinker.js
export const AutoLinker = require('autolinker');

//
//
// /CUSTOM STUFF IMPORTS
//
//

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        SafePipe,
        TranslateCutPipe,
        MyStripHttpPipe,
        EllipsizePipe,
        DateClosestPipe,
        AutoLinkerPipe,
        FileSizePipe,
        DurationPipe,
        AppComponent,
        AuthDialogComponent,
        EmbedMapComponent,
        DataListComponent,
        HeaderComponent,
        FooterComponent,
        PageContentComponent,
        ModuleContentComponent,
        AuthComponent,
        FullScreenLoaderComponent,
        FullWidthLoaderComponent,
        Error401Component,
        Error404Component,
        NamePickerComponent,
        ItemListDialogComponent,
        ExtensiblePComponent,
        InfoDialogComponent,
        InputImageComponent,
        MainActionComponent,
        WorkspaceSelectorComponent,
        TrueFalseRadioComponent,
        PublicPrivateRadioComponent,
        DiscussionsDiscussionComponent,
        DiscussionsMessageComponent,
        DiscussionsPrivateDiscussionWindowComponent,
        DiscussionsPrivateDiscussionsMenuComponent,
        DiscussionsEditGroupDialogComponent,
        FilesFilesComponent,
        FilesImageViewerDialogComponent,
        NotificationsNotificationsMenuComponent,
        TagsTagComponent,
        TagsTagPickerComponent,
        TagsTagSelectComponent,
        ReviewMarksReviewMarkSelectComponent,
        LikeCountersLikeCounterComponent,
        SideMenuComponent,
        ConfirmDialogComponent,
        UsersChangePasswordDialogComponent,
        ProjectsEditProjectProjectComponent,
        ProjectsEditProjectCaseComponent,
        ProjectsEditProjectSituationComponent,
        ProjectsEditProjectPartnershipComponent,
        ProjectsEditProjectMiscellaneousComponent,
        ProjectsEditProjectFundingComponent,
        ProjectsEditProjectTeamComponent,
        ProjectsEditProjectReviewComponent,
        ProjectsViewProjectCaseComponent,
        ProjectsEditProjectAcademicComponent,
        ProjectsProjectStateIndicatorComponent,
        ProjectsListComponent,
        ProjectCasesListComponent,
        ProjectsFilterBlockComponent,
        ProjectsAskChangesDialogComponent,
        ProjectsRegisterDialogComponent,
        ProjectsCreateDialogComponent,
        ProjectsHomeIntroductionComponent,
        ProjectsDashboardOverviewComponent,
        ProjectsDashboardKeyDataComponent,
        ProjectsDashboardMetricsComponent,
        InvestorsEditInvestorProfileComponent,
        InvestorsInvestorStateIndicatorComponent,
        InvestorsListComponent,
        InvestorsRegisterDialogComponent,
        InvestorsHomeIntroductionComponent,
        InvestorsListCompaniesComponent,
        InvestorsPendingValidationPageComponent,
        InvestorsInvestorsPageComponent,
        InvestorsHomePageComponent,
        InvestorsWelcomePageComponent,
        InvestorsDashboardPageComponent,
        InvestorsCompaniesPageComponent,
        InvestorsEditCompanyPageComponent,
        InvestorsCompanyPageComponent,
        AlertsListComponent,
        UsersPickUsersDialogComponent,
        UsersUserMenuComponent,
        UsersUserProfileDialogComponent,
        UsersAddUserDialogComponent,
        UsersUserStickerComponent,
        UsersUserPickerComponent,
        UsersListComponent,
        UsersLocalExpertsListComponent,
        SliderControlComponent,
        HomePageComponent,
        Error401PageComponent,
        Error404PageComponent,
        LogoutPageComponent,
        TermsPageComponent,
        UsersEditPageComponent,
        UsersAdminPageComponent,
        UsersGuestsPageComponent,
        UsersLocalExpertsPageComponent,
        ListBaseComponent,
        ProjectsEditPageComponent,
        ProjectsProjectsPageComponent,
        ProjectsMembershipsPageComponent,
        ProjectMembershipListComponent,
        InviteUserDialogComponent,
        ProjectsProjectPageComponent,
        ProjectsFavoritesPageComponent,
        FavoritesListComponent,
        ProjectsPendingValidationPageComponent,
        ProjectsNotProjectYetPageComponent,
        ProjectsDraftsPageComponent,
        ProjectsDispatchPageComponent,
        ProjectsDashboardPageComponent,
        ProjectsHomePageComponent,
        AlertsAlertsPageComponent,
        AlertsAlertPageComponent,
        AcventuresCommonHomeComponent,
        AcventuresCommonCompanyLogosComponent,
        AcventuresCommonHomeShowroomComponent,
        AcventuresCommonHomeShowroomDialogComponent
    ],
    /**
     * Import Angular's modules.
     */
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, {
            useHash: false,
            preloadingStrategy: PreloadAllModules
        }),
        MaterialModule,
        ChartCommonModule,
        LineChartModule,
        PieChartModule,
        FontAwesomeModule,
        MatMomentDateModule,
        RichTextEditorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, Conf]
            }
        }),
        QuillModule,
    ],
    /**
     * Expose our Services and Providers into Angular's dependency injection.
     */
    providers: [
        environment.ENV_PROVIDERS,
        APP_PROVIDERS,
        Conf,
        Utils,
        CurrencyPipe,
        ApiBaseService,
        KeyValueStorage,
        ModelsStorage,
        CurrentUser,
        LangsProvider,
        UsersProvider,
        ProjectsMembershipsProvider,
        AcventuresNavigationProvider,
        ProjectsProvider,
        InvestorsProvider,
        AlertsProvider,
        SyncServiceProvider,
        DiscussionsProvider,
        FilesProvider,
        LikeCountersProvider,
        NotificationsProvider,
        NotificationsTodosProvider,
        GuardsSequenceGuard,
        CanSeeCompanies,
        CanSeeCompany,
        IsAppReadyGuard,
        IsLoggedInGuard,
        UsersCanEditUserGuard,
        IsAdminGuard,
        HasPendingChangesGuard,
        HasHomepageRedirectGuard,
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new TranslatablePaginatorIntl();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        }
    ]
})
export class AppModule {
}
