import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot } from '@angular/router';
import { Conf } from '../../_conf';
import { CurrentUser } from '../providers/current-user';

@Injectable()
export class HasHomepageRedirectGuard implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _router: Router,
        private _conf: Conf,
        private _currentUser: CurrentUser
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this._currentUser.isLoggedIn() && this._conf.homepageRedirect) {
                this.redirect(route.queryParamMap);
                resolve(false);
            }

            resolve(true);
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private redirect(params: ParamMap): void {
        // on fait tout ce schmiblik de paramètre pour les conserver dans le cas
        // où l'utilisateur aurait cliqué sur un lien de renouvellement de mot de passe
        let paramsString: string = '';
        if (params && params.keys.length > 0) {
            const items: string[] = [];
            for (let i = 0; i < params.keys.length; i++) {
                items.push([params.keys[i]] + '=' + params.get(params.keys[i]));
            }

            paramsString = '?' + items.join('&');
        }

        this._router.navigateByUrl(this._conf.homepageRedirect + paramsString);
    }

}
