import { AfterViewInit, Component, Input } from '@angular/core';
import { Conf } from '../../../_conf';
import { InfoDialogComponent } from '../../../_common/components/info-dialog/info-dialog';
import { TranslateService } from '@ngx-translate/core';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'footer',
    providers: [],
    styleUrls: ['./footer.scss'],
    templateUrl: './footer.html'
})
export class FooterComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public hasLeftMenu: boolean = true;

    public year: number = 2000;

    public faFacebook = faFacebookF;
    public faTwitter = faTwitter;
    public faLinkedin = faLinkedinIn;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _dialoger: MatDialog,
        private _translater: TranslateService
    ) {
        this.year = (new Date()).getFullYear();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onHelpClicked(): void {
        window.location.href = 'mailto:' + this.conf.helpEmail;
    }

    public onAboutClicked(): void {
        this._dialoger.open(InfoDialogComponent, {
            data: {
                texts: ['About_text_1', 'About_text_2'],
                title: this._translater.instant('About_x', {x: this.conf.platformName})
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
