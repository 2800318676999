import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../providers/utils';

@Pipe({name: 'dateClosest'})
export class DateClosestPipe implements PipeTransform {
    constructor(
        private _utils: Utils
    ) {
    }

    public transform(str: string, format: string = 'closest') {
        return this._utils.formatDate(str, format);
    }
}
