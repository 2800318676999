<h1 mat-dialog-title>{{ 'New_account' | translate }}</h1>
<mat-dialog-content>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input [formControl]="firstNameFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="'First_name' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="firstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ firstNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input [formControl]="lastNameFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="'Last_name' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="lastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ lastNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onEmailChanged()"
                   [formControl]="emailFormControl"
                   [maxlength]="EMAIL_MAX_LENGTH"
                   [placeholder]="'Email' | translate"
                   matInput
                   type="email">
            <mat-error
                *ngIf="emailFormControl.invalid && !emailFormControl.hasError('unavailable')">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-error
                *ngIf="emailFormControl.hasError('unavailable')">{{ 'Email_unavailable' | translate }}</mat-error>
            <mat-hint align="end">{{ emailFormControl.value.length }}/{{ EMAIL_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="'New_password_hint' | translate"
                        color="primary">
            <input [formControl]="passwordFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="'Password' | translate"
                   [type]="showPassword ? 'text' : 'password'"
                   matInput>
            <mat-error *ngIf="passwordFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <button (click)="showPassword = !showPassword"
                    [matTooltip]="(showPassword ? 'Hide' : 'Show') | translate"
                    mat-icon-button
                    matSuffix>
                <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-checkbox [(ngModel)]="terms"
                      color="primary">
            {{ 'I_agree_with_terms' | translateCut: {index: 0} }}<a class="terms-link" href="/terms"
                                                                    target="_blank">{{ 'I_agree_with_terms' | translateCut: {index: 1} }}</a>{{ 'I_agree_with_terms' | translateCut: {index: 2} }}
        </mat-checkbox>
    </div>
    <div>&nbsp;</div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
    <button (click)="onRegisterClicked()"
            [disabled]="isLoading || firstNameFormControl.invalid || lastNameFormControl.invalid || emailFormControl.invalid || passwordFormControl.invalid || !terms"
            color="primary"
            mat-raised-button>
        {{ 'Register' | translate }}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading"
                      color="accent"
                      mode="indeterminate">
    </mat-progress-bar>
</div>
