import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { ShowRoomData } from '../home-showroom/home-showroom';

@Component({
    selector: 'acventures-common-home-showroom-dialog',
    providers: [],
    styleUrls: ['./home-showroom-dialog.scss'],
    templateUrl: './home-showroom-dialog.html'
})
export class AcventuresCommonHomeShowroomDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public currentIndex: number = 0;
    public showRoomsData: ShowRoomData[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.showRoomsData = _data.showRoomsData;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onNextClicked(): void {
        this.currentIndex++;
    }

    public onPreviousClicked(): void {
        this.currentIndex--;
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
