<div [class.draft]="state.id === STATE_ID_DRAFT"
     [class.pending-validation]="state.id === STATE_ID_PENDING_VALIDATION"
     [class.published]="state.id === STATE_ID_PUBLISHED"
     [class.refused]="state.id === STATE_ID_REFUSED"
     [class.size-big]="size === 'big'"
     [class.size-normal]="size === 'normal'"
     class="chip"></div>
<div [class.size-big]="size === 'big'"
     [class.size-normal]="size === 'normal'"
     class="title">
    {{ state.name }}
</div>
