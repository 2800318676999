import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { SyncServiceProvider } from '../../../_common/providers/sync-service';
import { DiscussionsProvider } from '../../providers/discussions-provider';
import { Discussion, DiscussionMessage, Project } from '../../../_common/providers/models';
import { DeleteMessagePattern } from '../message/message';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'discussions-discussion',
    providers: [],
    styleUrls: ['./discussion.scss'],
    templateUrl: './discussion.html'
})
export class DiscussionsDiscussionComponent implements AfterViewInit, OnDestroy {

    //
    //
    // CONSTANTS
    //
    //

    public MESSAGE_MAX_LENGTH: number = 1000;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //
    @Input() public newMessagePlaceholder: string = 'Your_comment';
    @Input() public disableNewMessage: boolean = false;
    @Input() public sendAPI: string = null;
    @Input() public deleteAPI: string = null;
    @Input() public project: Project = null;
    @Input() public newMessageDisableMessage: string = null;
    @Input() public isStacked: boolean = true;
    @Input() public isStackable: boolean = true;
    @Input() public title: string = 'Comments';
    @Input() public isShowMore: boolean = true;
    @Input() public messagesDisplayedCount: number = 3;
    @Input() public noMessageText: string = 'No_comment_yet';
    @Input() public autoScrollToBottom: boolean = false;
    @Input() public deleteMessagePattern: DeleteMessagePattern = DeleteMessagePattern.NONE;
    @Input() public focusMessageInputOnLoad: boolean = false;
    @Input() public inputMaxRows: number = 25;
    @ViewChild('messageInput', {static: false}) public messageInput: ElementRef;
    public isLoading: boolean = false;
    public discussion: Discussion = new Discussion();
    public messages: any[] = [];
    public newMessage: string = '';
    public messagesScrollTop: number = 0;
    private _syncListenerId: string = null;
    private _syncMaxId: number = 0;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _title: Title,
        private _router: Router,
        private _route: ActivatedRoute,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _sync: SyncServiceProvider,
        private _storage: ModelsStorage,
        private _discussions: DiscussionsProvider,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _discussionId;

    public get discussionId() {
        return this._discussionId;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set discussionId(value: number) {
        this.isLoading = true;
        this.loadDiscussion(value)
            .then(() => {
                if (this.autoScrollToBottom) {
                    this.scrollToBottom();
                }
                this.isLoading = false;
            })
            .catch((error) => this.onError(error));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        if (this.focusMessageInputOnLoad) {
            this.focusInput();
        }
    }

    public ngOnDestroy() {
        if (this._syncListenerId) {
            this._sync.removeSyncListener(this._syncListenerId);
        }
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onInputChange(): void {
        if (this.newMessage.length > this.MESSAGE_MAX_LENGTH) {
            this.newMessage = this.newMessage.substr(0, this.MESSAGE_MAX_LENGTH);
        }
    }

    public onSendClicked(): void {
        this.isLoading = true;
        this.sendNewMessage()
            .then(() => {
                // this.isShowMore = true;
                this.isLoading = false;
                if (this.autoScrollToBottom) {
                    this.scrollToBottom();
                }
            })
            .catch((error) => this.onError(error));
    }

    public onShowMoreClicked(): void {
        this.isShowMore = true;
    }

    public onExpandClicked(): void {
        this.isStacked = false;
    }

    public onContractClicked(): void {
        this.isStacked = true;
    }

    public onDeleteMessage(message: DiscussionMessage): void {
        if (this.deleteAPI) {
            this.isLoading = true;
            this._discussions.deleteMessage(this.deleteAPI, message)
                .then(() => {
                    for (let i = 0; i < this.messages.length; i++) {
                        if (this.messages[i].message.id === message.id) {
                            this.messages.splice(i, 1);
                            this.isLoading = false;
                            return;
                        }
                    }

                    this.isLoading = false;
                })
                .catch((error) => this.onError(error));
        }
    }

    public focusInput(): void {
        setTimeout(() => {
            this.messageInput.nativeElement.focus();
        }, 1);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadDiscussion(discussionId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            Promise.all([
                this._storage.fromId(Discussion, [discussionId]),
                this._storage.select(DiscussionMessage).where('discussionId').equals(discussionId).get()
            ])
                .then((results: any[]) => {
                    this._discussionId = discussionId;
                    this.discussion = results[0];
                    this.messages = [];
                    for (let i = 0; i < results[1].length; i++) {
                        this.pushMessage(results[1][i]);
                    }

                    this.initSync();

                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private sendNewMessage(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.sendAPI) {
                const message: DiscussionMessage = new DiscussionMessage();
                message.message = this.newMessage;
                message.createrId = this.currentUser.user.id;
                this._discussions.sendNewMessage(this.sendAPI, message)
                    .then((result: DiscussionMessage) => {
                        this.pushMessage(result);
                        this.messagesDisplayedCount++;
                        this.newMessage = '';

                        resolve(result);
                    })
                    .catch((error) => reject(error));
            } else {
                reject({error: 'No sending API defined'});
            }
        });
    }

    private pushMessage(message: DiscussionMessage): void {
        // hack pour corriger un problème d'assignation de variables dans les template angular
        // si on parcourt directement la liste des messages et qu'on fait un [(ngModel)] sur
        // chaque message, ca fait planter.
        // si on utilise un index et qu'on assigne [(ngModel)] sur la valeur du tableau à cet index
        // ca plante aussi
        this.messages.push({message});
    }

    private onError(error: any): void {
        this.isLoading = false;
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }

    private scrollToBottom(): void {
        setTimeout(() => this.messagesScrollTop += 100000, 50);
    }

    private initSync(): void {
        if (this._syncListenerId) {
            this._sync.removeSyncListener(this._syncListenerId);
        }
        this._syncMaxId = 0;
        if (this.messages.length > 0) {
            this._syncMaxId = this.messages[this.messages.length - 1].message.id;
        }
        this._syncListenerId = this._sync.addSyncListener({
            id: null,
            onCommonDataSynced: null,
            onUserDataSynced: () => {
                this.checkForNewMessages();
            }
        });
    }

    private checkForNewMessages(): void {
        this._storage.select(DiscussionMessage)
            .where('discussionId').equals(this.discussionId)
            .and('id').greater(this._syncMaxId)
            .get()
            .then((newMessages: DiscussionMessage[]) => {
                let hasNewMessage: boolean = false;
                let newSyncMaxId: number = this._syncMaxId;
                for (let i = 0; i < newMessages.length; i++) {
                    // le message peut déjà être dans la liste s'il a été écrit par l'utilisateur connecté
                    // dans ce cas on ne l'ajoute pas
                    let alreadyIn: boolean = false;
                    for (let j = this.messages.length - 1; !alreadyIn && j >= 0 && this.messages[j].message.id >= this._syncMaxId; j--) {
                        alreadyIn = this.messages[j].message.id === newMessages[i].id;
                    }

                    if (!alreadyIn) {
                        this.pushMessage(newMessages[i]);
                        this.messagesDisplayedCount++;
                        hasNewMessage = true;
                    }
                }

                if (hasNewMessage) {
                    this.scrollToBottom();
                    for (let i = this.messages.length - 1; i >= 0 && this.messages[i].message.id !== this._syncMaxId; i--) {
                        if (this.messages[i].message.id > newSyncMaxId) {
                            newSyncMaxId = this.messages[i].message.id;
                        }
                    }
                    this._syncMaxId = newSyncMaxId;
                }
            })
            .catch((error: any) => this.onError(error));
    }
}
