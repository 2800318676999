<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Companies' | translate"
            header>
    </header>
    <module-content *ngIf="accessBlocked">
        <div class="info">
            <mat-icon>info_outline</mat-icon>
            {{ 'Venture_incomplete_warning' | translate }}
        </div>
    </module-content>
    <ng-container *ngIf="!accessBlocked">
        <investors-list-companies (onAddClicked)="onAddClicked()"
                                  (onDeleteRowClicked)="onDeleteRowClicked($event)"
                                  (onEditRowClicked)="onEditRowClicked($event)"
                                  (onRowClicked)="onRowClicked($event)"
                                  [companies]="companies"
                                  [hideActionsColumn]="!currentUser.user.isAdmin"
                                  [hideIsDisplayedOnHomepageColumn]="!currentUser.user.isAdmin"
                                  [isAddEnabled]="rights.ADD"
                                  [isClickable]="currentUser.user.isAdmin || currentUser.user.isProjectHolder"
                                  [noDataMessage]="'No_companies_yet'"
                                  [title]="">
        </investors-list-companies>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
