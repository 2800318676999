<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Investors_pending_validation' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isAdmin"></error-401>
    <investors-list (onCompanyClicked)="onCompanyClicked($event)"
                    (onRowClicked)="onRowClicked($event)"
                    *ngIf="currentUser.user.isAdmin"
                    [disableSearchEngine]="true"
                    [hideActionsColumn]="true"
                    [hideContactableColumn]="true"
                    [investorProfiles]="investorProfiles"
                    [noDataMessage]="'No_investors_pending_validation'"
                    [title]="">
    </investors-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
