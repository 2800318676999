import { Utils } from '../../_common/providers/utils';
import { InvestorProfile, InvestorValidationState, User } from '../../_common/providers/models';

export class InvestorRights {

    //
    //
    // ATTRIBUTES
    //
    //

    private _utils: Utils;
    private _params: InvestorRightsParams = null;
    private _see: boolean = false;
    private _edit: boolean = false;
    private _delete: boolean = false;
    private _seeEditForm: boolean = false;
    private _validate: boolean = false;

    constructor(
        utils: Utils
    ) {
        this._utils = utils;
    }

    public get SEE() {
        return this._see;
    }

    public get EDIT() {
        return this._edit;
    }

    public get DELETE() {
        return this._delete;
    }

    public get SEE_EDIT_FORM() {
        return this._seeEditForm;
    }

    public get VALIDATE() {
        return this._validate;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public evaluate(params: InvestorRightsParams): void {
        this._params = params;

        if (params.user.isAdmin) {
            this.evaluateAdmin();
        } else if (params.user.isInvestor) {
            this.evaluateInvestor();
        } else if (params.user.isGuest) {
            this.evaluateGuest();
        } else if (params.user.isProjectHolder) {
            this.evaluateProjectHolder();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private evaluateAdmin(): void {
        this._see = true;
        this._edit = false;
        this._delete = true;
        this._seeEditForm = true;
        if (this._params.investorProfile && this._params.investorProfile.investorValidationStateId === InvestorValidationState.ID_PENDING) {
            this._validate = true;
        } else {
            this._validate = false;
        }
    }

    private evaluateGuest(): void {
        this._see = false;
        this._edit = false;
        this._delete = false;
        this._seeEditForm = false;
        this._validate = false;
    }

    private evaluateInvestor(): void {
        this._delete = false;
        this._validate = false;
        if (this._params.user.id === this._params.investorProfile.userId) {
            this._see = true;
            this._seeEditForm = true;

            if ([InvestorValidationState.ID_ACCEPTED, InvestorValidationState.ID_PENDING].indexOf(this._params.investorProfile.investorValidationStateId) >= 0) {
                this._edit = true;
            } else {
                this._edit = true;
            }
        } else {
            this._see = false;
            this._edit = false;
            this._seeEditForm = false;
        }
    }

    private evaluateProjectHolder(): void {
        this._see = false;
        this._edit = false;
        this._delete = false;
        this._seeEditForm = false;
        this._validate = false;
    }
}

export interface InvestorRightsParams {
    user: User;
    investorProfile?: InvestorProfile;
}
