import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import {
    AcventuresCommonHomeShowroomDialogComponent
} from '../../../_acventures-common/components/home-showroom-dialog/home-showroom-dialog';
import { ShowRoomData } from '../../../_acventures-common/components/home-showroom/home-showroom';

@Component({
    selector: 'projects-home-introduction',
    providers: [],
    styleUrls: ['./home-introduction.scss'],
    templateUrl: './home-introduction.html'
})
export class ProjectsHomeIntroductionComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public showRoomMode: 'normal' | 'dialog' = 'normal';
    public showRoomsData: ShowRoomData[] = [
        {
            orientation: 'right',
            icon: 'assignment',
            image: this.conf.assetsUrl + 'img/home/section-3-showroom-1.jpg',
            title: 'home_section3_showroom1_title',
            text: 'home_section3_showroom1_text'
        }
    ];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _router: Router,
        private _route: ActivatedRoute,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onMoreInformationClicked(): void {
        this._dialoger.open(AcventuresCommonHomeShowroomDialogComponent, {
            data: {
                showRoomsData: this.showRoomsData
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
