<mat-dialog-content>
    <div class="container">
        <div class="avatar-container">
            <img [src]="user.avatar ? conf.storageRootUrl + user.avatar : conf.assetsUrl + 'img/avatar.png'"
                 class="avatar"/>
        </div>
        <div class="name">{{ user.firstName + ' ' + user.lastName }}</div>
        <div class="intro">{{ user.intro }}</div>
        <div *ngIf="userLinksData.length > 0" class="user-links">
            <a *ngFor="let userLinkData of userLinksData"
               [matTooltip]="'Account_x' | translate:{account: userLinkData.userLink.name}"
               href="{{ userLinkData.user_userLink.link }}"
               matTooltipPosition="left"
               tabindex="-1"
               target="_blank">
                <img src="{{ conf.assetsUrl + userLinkData.userLink.icon }}"/>
            </a>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="currentUser.isLoggedIn()">
        <button (click)="onDiscussClicked()"
                *ngIf="user.id !== currentUser.user.id && !user.deleteDate"
                mat-button
                mat-dialog-close>
            <mat-icon>message</mat-icon>&nbsp;{{ 'Discuss' | translate }}
        </button>
    </ng-container>
    <button mat-button
            mat-dialog-close>
        {{ 'Close' | translate}}
    </button>
</mat-dialog-actions>
