<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="userForm" novalidate>
        <div class="line">
            <mat-form-field>
                <input formControlName="firstName"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{ 'First_name' | translate }}">
                <mat-error
                    *ngIf="userForm.controls.firstName.errors && userForm.controls.firstName.errors.required">{{ 'Field_required' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input formControlName="lastName"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{ 'Last_name' | translate }}">
                <mat-error
                    *ngIf="userForm.controls.lastName.errors && userForm.controls.lastName.errors.required">{{ 'Field_required' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div class="line">
            <mat-form-field>
                <input (keyup)="onEmailChanged()"
                       formControlName="email"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{'Email' | translate}}">
                <mat-error
                    *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.required">{{ 'Field_required' | translate }}</mat-error>
                <mat-error
                    *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.email">{{ 'Field_invalid_email' | translate }}</mat-error>
                <mat-error
                    *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.unavailable">{{ 'Email_unavailable' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </form>
    <p *ngIf="infoMessage">{{ infoMessage | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button
            mat-dialog-close>
        {{ 'Cancel' | translate }}
    </button>
    <button (click)="onAddClicked()"
            [disabled]="userForm.invalid"
            color="primary"
            mat-dialog-close
            mat-raised-button>
        {{ 'Add' | translate }}
    </button>
</mat-dialog-actions>

