<div mat-dialog-title>{{ title | translate }}</div>
<mat-dialog-content>
    <mat-form-field class="text-box">
        <input (keyup)="onTyped()"
               (keyup.enter)="onSaveClicked()"
               [formControl]="nameFormControl"
               matInput
               maxlength="255"
               placeholder="{{ placeholder | translate }}"/>
        <mat-error *ngIf="nameFormControl.hasError('required')">{{ 'Field_required' | translate }}</mat-error>
        <mat-hint *ngIf="maxLength" align="end">{{ nameFormControl.value?.length }} / {{ maxLength }}</mat-hint>
    </mat-form-field>
    <div *ngIf="explaination"
         class="explaination">
        <mat-icon>info_outline</mat-icon>
        <div>
            {{ explaination | translate }}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onSaveClicked()"
            [disabled]="nameFormControl.hasError('required')"
            color="accent"
            mat-raised-button>
        {{ 'Save' | translate}}
    </button>
</mat-dialog-actions>
