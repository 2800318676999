<div class="rich-text-wrapper">
    <label [ngClass]="{'ng-invalid' : control.invalid}" class="rich-text-label" for="{{ id }}">{{ placeholder }}</label>
    <div [ngClass]="{'ng-invalid' : control.invalid}">
        <quill-editor
            (onContentChanged)="onContentChanged($event)"
            [formControl]="control"
            [maxLength]="maxFieldLength"
            [modules]="quillConfig"
            [placeholder]="''"
            [styles]="styles"
            id="{{ id }}">
        </quill-editor>
    </div>
    <div class="mat-helper-wrapper">
        <mat-error *ngIf="control.invalid" class="error">{{ 'Invalid_value' | translate }}</mat-error>
        <mat-hint class="hint">{{ control.value?.length || 0 }}/{{ maxFieldLength }}</mat-hint>
    </div>
</div>
