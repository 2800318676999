import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InvestorDataRow } from '../../providers/investors-provider';
import { UsersProvider } from '../../../users/providers/users-provider';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { InvestorProfile, InvestorValidationState, User } from '../../../_common/providers/models';

@Component({
    selector: 'investors-investors-page',
    providers: [],
    styleUrls: ['./investors.scss'],
    templateUrl: './investors.html'
})
export class InvestorsInvestorsPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public investorProfiles: InvestorProfile[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _usersProvider: UsersProvider,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._title.setTitle(this._translater.instant('Investors') + ' | ' + this.conf.platformName);
        this.loadData()
            .then(() => {
                this.isLoading = false;
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRowClicked(row: InvestorDataRow): void {
        this._router.navigate(['users', row.profile.userId, 'edit'], {
            queryParams: {
                'backUrl': this._router.url,
                'backTitle': this._translater.instant('Search_results')
            }
        });
    }

    public onCompanyClicked(row: InvestorDataRow): void {
        this._router.navigate(['investors', 'companies', row.company.id], {
            queryParams: {
                'backUrl': this._router.url,
                'backTitle': this._translater.instant('Search_results')
            }
        });
    }

    public onDeleteRowClicked(row: InvestorDataRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_user'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this.deleteUser(row.user)
                    .then(() => {
                        this.investorProfiles = this.investorProfiles.slice();
                        this._toaster.open(this._translater.instant('User_deleted'), '', {duration: 5000});
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(InvestorProfile)
                .where('investorValidationStateId').equals(InvestorValidationState.ID_ACCEPTED)
                .get()
                .then((data: InvestorProfile[]) => {
                    this.investorProfiles = data;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private deleteUser(user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._usersProvider.deleteUser(user)
                .then(() => {
                    for (let i = 0; i < this.investorProfiles.length; i++) {
                        if (this.investorProfiles[i].userId === user.id) {
                            this.investorProfiles.splice(i, 1);
                            i = this.investorProfiles.length;
                        }
                    }

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
