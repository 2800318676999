import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Conf } from '../../../_conf';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { CurrentUser } from '../../../_common/providers/current-user';

@Component({
    selector: 'investors-dashboard-page',
    providers: [],
    styleUrls: ['./dashboard.scss'],
    templateUrl: './dashboard.html'
})
export class InvestorsDashboardPageComponent implements AfterViewInit {

    public isLoading: boolean = false;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _changeDetector: ChangeDetectorRef,
    ) {
    }

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
    }
}
