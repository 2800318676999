import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { Utils } from '../../providers/utils';
import { ModelsStorage } from '../../providers/models-storage';
import { CurrentUser } from '../../providers/current-user';

@Component({
    selector: 'workspace-selector',
    providers: [],
    styleUrls: ['./workspace-selector.scss'],
    templateUrl: './workspace-selector.html'
})
export class WorkspaceSelectorComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public activeWorkspaceLogo: string = null;
    public activeWorkspaceName: string = '';

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public utils: Utils,
        private _translater: TranslateService,
        private _changeDetector: ChangeDetectorRef,
        private _route: ActivatedRoute,
        private _router: Router,
        private _storage: ModelsStorage
    ) {
    }

    private _workspaces: any[] = null;

    public get workspaces() {
        return this._workspaces;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set workspaces(workspaces: any[]) {
        this._workspaces = workspaces;
        if (this._workspaces) {
            const id: any = workspaces && workspaces.length > 0 ? workspaces[0].id : null;
            this.updateActiveWorkspace(id);
        } else {
            this.initWorkspaces()
                .catch((error: any) => this.onError(error));
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initWorkspaces()
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private initWorkspaces(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this._workspaces || !this.currentUser.isLoggedIn()) {
                resolve();
                return;
            }
        });
    }

    private updateActiveWorkspace(id: any): void {
        let found: boolean = false;
        for (let i = 0; i < this._workspaces.length; i++) {
            if (this._workspaces[i].id === id) {
                found = true;
                this.activeWorkspaceLogo = this._workspaces[i].logo;
                this.activeWorkspaceName = this._workspaces[i].name;
                this._workspaces[i].active = true;
            } else {
                this._workspaces[i].active = false;
            }
        }

        if (!found) {
            this.activeWorkspaceLogo = null;
            this.activeWorkspaceName = '';
        }
    }

    private onError(error: any): void {
        console.log(error);
    }
}

export interface Workspace {
    id: any;
    name: string;
    logo: string;
    url: string;
    active: boolean;
}
