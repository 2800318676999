import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { CompanyDataRow, InvestorsProvider } from '../../providers/investors-provider';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import {
    Company,
    Company_DevelopmentPhase,
    Company_ProjectType,
    Company_TherapeuticArea,
    CompanyCompanyCategory,
    CompanyContact,
    DevelopmentPhase,
    InvestorProfile,
    InvestorValidationState,
    ProjectType,
    TherapeuticArea
} from '../../../_common/providers/models';
import { CompanyRights } from '../../providers/company-rights';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { FormControl, Validators } from '@angular/forms';
import { ImageData, InputImageComponent } from '../../../_common/components/input-image/input-image';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { ProjectsProvider, ProjectTypeGoupWithTypes } from '../../../projects/providers/projects-provider';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-edit-company-page',
    providers: [],
    styleUrls: ['./edit-company.scss'],
    templateUrl: './edit-company.html'
})
export class InvestorsEditCompanyPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //
    @ViewChild(InputImageComponent, {static: false}) public logoComponent: InputImageComponent;
    public logoDisabled = false;

    public isLoading = true;
    public isSaveLoading = false;
    public is404 = false;
    public is401 = false;

    public backMenu: MenuItem = {
        icon: 'back',
        title: this._translater.instant('Companies'),
        url: '/investors/companies'
    };

    public companyData: CompanyDataRow = null;

    public formGroup = {
        nameFormControl: new FormControl('', [
            Validators.required,
            Validators.maxLength(this.investorsProvider.COMPANY_NAME_MAX_LENGTH)
        ]),
        descriptionFormControl: new FormControl('', [
            Validators.maxLength(this.investorsProvider.COMPANY_DESCRIPTION_MAX_LENGTH)
        ]),
        websiteFormControl: new FormControl('', [
            Validators.pattern(this.utils.URL_REGEX)
        ]),
        companyCategoryFormControl: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ]),
        therapeuticAreaFormControl: new FormControl('', [
            Validators.min(1)
        ]),
        projectTypeFormControl: new FormControl('', [
            Validators.min(1)
        ]),
        developmentPhaseFormControl: new FormControl('', []),
        investmentCommentFormControl: new FormControl('', [
            Validators.maxLength(this.investorsProvider.COMPANY_INVESTMENT_COMMENT_MAX_LENGTH)
        ])
    };

    public investmentRangeFromFormControl: FormControl = new FormControl('', [
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);
    public investmentRangeToFormControl: FormControl = new FormControl('', [
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);

    public contactInformationFormControl: CompanyContactInformationFormControl = {
        street1FormControl: new FormControl('', [
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH),
        ]),
        street2FormControl: new FormControl('', [
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH)
        ]),
        cityFormControl: new FormControl('', [
            Validators.required,
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH)
        ]),
        countryFormControl: new FormControl('', [
            Validators.required,
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH)
        ]),
        emailFormControl: new FormControl('', [
            Validators.required,
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_EMAIL_MAX_LENGTH),
            Validators.email,
            Validators.pattern(this.utils.EMAIL_REGEX),
        ]),
        phoneFormControl: new FormControl('', [
            Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH),
            Validators.pattern(this.utils.INTERNATIONAL_PHONE_NUMBER_REGEX)
        ]),
    };

    public isInterstedInPartnerships = 0;
    public noLogo = 0;
    public noWebsite = 0;
    public noContactInfos = 0;
    public isInterstedInLicenses = 0;
    public isInvestingInPrivate = 0;
    public isInvestingInPublic = 0;
    public isDisplayedOnHomepage = 0;
    public companyContactExists = true;
    public logoImageData: ImageData = null;
    public companyContactFormControls: CompanyContactFormControl[] = [];

    public categories: CompanyCompanyCategory[] = [];
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhases: DevelopmentPhase[] = [];
    public projectTypes: ProjectType[] = [];
    public rights: CompanyRights = new CompanyRights(this.utils);
    public projectTypeGroups: ProjectTypeGoupWithTypes[] = [];

    private investorProfile: InvestorProfile = null;
    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        public utils: Utils,
        public investorsProvider: InvestorsProvider,
        public projectsProvider: ProjectsProvider,
        private _title: Title,
        private _router: Router,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changesGuard: HasPendingChangesGuard,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backMenu = {
                    icon: 'arrow_back',
                    title: params.backTitle || this._translater.instant('Back'),
                    url: params.backUrl || '/investors/companies'
                };
                this._changeDetector.detectChanges();
            }
        });
        this._route.params.subscribe((params: any) => {
            this.initPage(parseInt(params.id, 10)).catch((error) => this.onError(error));
        });
        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onProjectTypeIdChanged(): void {
        this.onFormChanged();
    }

    public onAllProjectTypesClicked(): void {
        this.formGroup.projectTypeFormControl.setValue(this.utils.getKeyValues(this.projectTypes, 'id'));
        this.onProjectTypeIdChanged();
    }

    public onNoProjectTypesClicked(): void {
        this.formGroup.projectTypeFormControl.setValue([]);
        this.onProjectTypeIdChanged();
    }

    public getProjectGroupTypeIcon(groupData: ProjectTypeGoupWithTypes): string {
        const selectedItems: number[] = this.utils.findManyIn(this.formGroup.projectTypeFormControl.value, groupData.types);
        if (selectedItems.length === groupData.types.length) {
            return 'check_box';
        } else if (selectedItems.length === 0) {
            return 'check_box_outline_blank';
        }

        return 'indeterminate_check_box';
    }

    public onProjectTypeGroupClicked(groupData: ProjectTypeGoupWithTypes): void {
        const selectedItems: ProjectType[] = this.utils.findManyIn(this.companyData.projectTypesIdsOfInterest, groupData.types);
        if (selectedItems.length === groupData.types.length) {
            selectedItems.forEach((item: ProjectType) => {
                this.companyData.projectTypesIdsOfInterest.splice(this.companyData.projectTypesIdsOfInterest.indexOf(item.id), 1);
            });
        } else {
            groupData.types.forEach((type: ProjectType) => {
                if (this.companyData.projectTypesIdsOfInterest.indexOf(type.id) < 0) {
                    this.companyData.projectTypesIdsOfInterest.push(type.id);
                }
            });
        }

        this.formGroup.projectTypeFormControl.setValue(this.companyData.projectTypesIdsOfInterest);
    }

    public onAllTherapeuticAreasClicked(): void {
        this.formGroup.therapeuticAreaFormControl.setValue(this.utils.getKeyValues(this.therapeuticAreas, 'id'));
    }

    public onNoTherapeuticAreasClicked(): void {
        this.formGroup.therapeuticAreaFormControl.setValue([]);
    }

    public onAllDevelopmentPhasesClicked(): void {
        this.formGroup.developmentPhaseFormControl.setValue(
            this.utils.getKeyValues(this.developmentPhases, 'id')
        );
    }

    public onNoDevelopmentPhasesClicked(): void {
        this.formGroup.developmentPhaseFormControl.setValue([]);
    }

    public validateFields(): void {

        this.companyContactFormControls.forEach((control) => {
            Object.keys(control).forEach((key) => {
                if (control[key] instanceof FormControl) {
                    control[key].markAsTouched({onlySelf: true});
                }
            });
        });

        if (Object.keys(this.companyContactFormControls).length === 0) {
            this.companyContactExists = false;
        }

        Object.keys(this.contactInformationFormControl).forEach((field) => {
            const control = this.contactInformationFormControl[field];
            control.markAsTouched({onlySelf: true});
        });

        Object.keys(this.formGroup).forEach((field) => {
            const control = this.formGroup[field];
            control.markAsTouched({onlySelf: true});
        });

        this.investmentRangeFromFormControl.markAsTouched({onlySelf: true});
        this.investmentRangeToFormControl.markAsTouched({onlySelf: true});

        this.logoComponent.imageFormControl.markAsTouched({onlySelf: true});
    }

    public validateRequiredFieldsForAdmin(): void {
        this.formGroup.nameFormControl.markAsTouched({onlySelf: true});
        this.formGroup.companyCategoryFormControl.markAsTouched({onlySelf: true});
    }

    public canSave(): boolean {
        let canSave = true;
        for (const formControl of this.companyContactFormControls) {
            if (formControl.firstNameFormControl.invalid
                || formControl.lastNameFormControl.invalid
                || formControl.emailFormControl.invalid
                || formControl.phoneFormControl.invalid) {
                canSave = false;
            }
        }

        if (this.currentUser.user.isInvestor) {
            // check if image or noLogo checkbox is set
            // validation check on input-image formControl does not work, because image does not set field value when added
            if (!this.noLogo && !this.logoImageData.new && !this.logoImageData.current) {
                canSave = false;
            }
            if (!this.areContactInfosValid()) {
                canSave = false;
            }

            if (Object.keys(this.companyContactFormControls).length === 0) {
                canSave = false;
            }

        }

        if (this.isLoading
            || this.isSaveLoading
            || this.formGroup.developmentPhaseFormControl.invalid
            || this.formGroup.descriptionFormControl.invalid
            || this.formGroup.companyCategoryFormControl.invalid
            || this.formGroup.websiteFormControl.invalid
            || this.investmentRangeFromFormControl.invalid
            || this.investmentRangeToFormControl.invalid
            || this.formGroup.investmentCommentFormControl.invalid
            || this.formGroup.nameFormControl.invalid
            || this.formGroup.therapeuticAreaFormControl.invalid
            || this.formGroup.projectTypeFormControl.invalid) {
            canSave = false;
        }

        return canSave;

    }

    public onAddCompanyContactFormControl(registerChanges: boolean = true): CompanyContactFormControl {
        const control: CompanyContactFormControl = {
            companyContactId: 0,
            firstNameFormControl: new FormControl('', [
                Validators.required,
                Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH)
            ]),
            lastNameFormControl: new FormControl('', [
                Validators.required,
                Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH)
            ]),
            emailFormControl: new FormControl('', [
                Validators.email,
                Validators.required,
                Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH),
                Validators.pattern(this.utils.EMAIL_REGEX)
            ]),
            phoneFormControl: new FormControl('', [
                Validators.maxLength(this.investorsProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH),
                Validators.pattern(this.utils.INTERNATIONAL_PHONE_NUMBER_REGEX)
            ])
        };
        this.companyContactFormControls.push(control);

        this.companyContactExists = true;

        if (registerChanges) {
            this.onFormChanged();
        }

        return control;
    }

    public onRemoveCompanyContact(index: number): void {
        this.companyContactFormControls.splice(index, 1);
        if (this.companyContactFormControls.length === 0) {
            this.companyContactExists = false;
        }
        this.onFormChanged();
    }

    public onInvestmentRangeChanged(): void {
        this.investmentRangeFromFormControl.updateValueAndValidity();
        this.investmentRangeToFormControl.updateValueAndValidity();
    }

    public onSaveClicked(): void {

        if (this.currentUser.user.isInvestor) {
            this.validateFields();
        }

        if (this.currentUser.user.isAdmin) {
            this.validateRequiredFieldsForAdmin();
        }

        if (!this.canSave()) {
            this._toaster.open(this._translater.instant('Field_validation_failure'), '', {duration: 5000});
            return;
        }

        this.isSaveLoading = true;
        this.saveCompany(this.collectData())
            .then((company: Company) => {
                this._changesGuard.unregisterChanges();
                const promise: Promise<any> = this.companyData.company.id ?
                    this.initPage(this.companyData.company.id) :
                    this._router.navigate(['investors', 'companies', company.id, 'edit']);

                if (this.currentUser.user.isInvestor && this.investorProfile.investorValidationStateId === InvestorValidationState.ID_PENDING) {
                    this._router.navigate(['welcome']);
                }

                promise
                    .then(() => {
                        this.isSaveLoading = false;
                        this._toaster.open(this._translater.instant('Company_saved'), '', {duration: 5000});
                    })
                    .catch((error) => this.onError(error));
            })
            .catch((error) => this.onError(error));
    }

    public onDeleteClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_company'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this.deleteCompany(this.companyData)
                    .then(() => {
                        this._changesGuard.unregisterChanges();
                        const message = this._translater.instant('Company_deleted');
                        this._toaster.open(message, '', {duration: 5000});
                        this._router.navigate(['investors', 'companies']);
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    public onFormChanged(): void {
        this._changesGuard.registerChanges();
    }

    public onCheckboxChanged(): void {
        // enable or disable website field if noWebsite is checked
        if (this.noWebsite) {
            this.formGroup.websiteFormControl.setValue('');
            this.formGroup.websiteFormControl.disable();
            this.formGroup.websiteFormControl.clearValidators();
        } else {
            this.formGroup.websiteFormControl.enable();
            if (this.currentUser.user.isInvestor) {
                this.formGroup.websiteFormControl.setValidators(Validators.required);
            }
            this.formGroup.websiteFormControl.updateValueAndValidity();
        }

        // disable logo field and clear image if noLogo is checked
        this.logoDisabled = Boolean(this.noLogo);
        if (this.logoDisabled) {
            this.logoImageData = {current: null, new: null};
        }
        this._changesGuard.registerChanges();
    }

    private areContactInfosValid(): boolean {
        if (this.noContactInfos) {
            return true;
        }

        for (const control in this.contactInformationFormControl) {
            if (this.contactInformationFormControl[control].invalid) {
                return false;
            }
        }

        return true;
    }

    private makeCompanyFieldsRequired(): void {
        this.formGroup.therapeuticAreaFormControl.setValidators(Validators.required);
        this.formGroup.therapeuticAreaFormControl.updateValueAndValidity();
        this.formGroup.projectTypeFormControl.setValidators(Validators.required);
        this.formGroup.projectTypeFormControl.updateValueAndValidity();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initPage(companyId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.isLoading = true;
            this.loadData(companyId)
                .then(() => {
                    this._title.setTitle(
                        (this.companyData.company.name || this._translater.instant('New_company'))
                        + ' | ' + this.conf.platformName
                    );
                    this.initFormFields();
                    this.is401 = !this.rights.EDIT;
                    this.isLoading = false;

                    resolve();
                })
                .catch(reject);

            this._changeDetector.detectChanges();
        });
    }

    private loadData(companyId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(Company).where('id').equals(companyId).get(1),
                this._storage.select(CompanyContact).where('companyId').equals(companyId).get(),
                this._storage.select(Company_TherapeuticArea).where('companyId').equals(companyId).get(),
                this._storage.select(Company_DevelopmentPhase).where('companyId').equals(companyId).get(),
                this._storage.select(CompanyCompanyCategory).orderBy('ordinal').get(),
                this._storage.select(TherapeuticArea).get(),
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this._storage.select(Company_ProjectType).where('companyId').equals(companyId).get(),
                this._storage.select(ProjectType).get(),
                this.projectsProvider.getProjectTypeGroups(),
                this.currentUser.user.isInvestor && this._storage.select(InvestorProfile).where('userId').equals(this.currentUser.user.id).get(1)
            ])
                .then((data: any[]) => {
                    // if the companyId was provided and we can't find it, it's a 404
                    // otherwise we assume that we are trying to create a new company
                    if (!data[0] && companyId > 0) {
                        this.is404 = true;
                        reject({});
                        return;
                    }

                    const company: Company = data[0] || new Company();

                    this.rights.evaluate({
                        user: this.currentUser.user,
                        company: data[0],
                        investorProfile: this.currentUser.user.isInvestor ? data[10] : null
                    });
                    this.investorProfile = this.currentUser.user.isInvestor ? data[10] : null;
                    this.companyData = {
                        company,
                        companyCategory: this.utils.findIn(company.companyCompanyCategoryId, data[4]),
                        companyContacts: data[1],
                        therapeuticAreasOfInterest: data[2],
                        therapeuticAreasIdsOfInterest: this.utils.getKeyValues(data[2], 'therapeuticAreaId'),
                        developmentPhasesOfInterest: data[3],
                        developmentPhasesIdsOfInterest: this.utils.getKeyValues(data[3], 'developmentPhaseId'),
                        projectTypesOfInterest: data[7],
                        projectTypesIdsOfInterest: this.utils.getKeyValues(data[7], 'projectTypeId'),
                        rights: this.rights
                    };

                    this.categories = data[4];
                    this.therapeuticAreas = data[5];
                    this.developmentPhases = data[6];
                    this.projectTypes = data[8];
                    this.projectTypeGroups = data[9];

                    resolve();

                })
                .catch(reject);
        });
    }

    private initFormFields(): void {
        this.logoImageData = {current: this.companyData.company.logo, new: null};
        this.formGroup.nameFormControl.setValue(this.companyData.company.name || '');
        this.formGroup.descriptionFormControl.setValue(this.companyData.company.description || '');
        this.formGroup.websiteFormControl.setValue(this.companyData.company.website || '');
        this.noWebsite = this.companyData.company.noWebsite;
        if (this.noWebsite) {
            this.formGroup.websiteFormControl.disable();
        }
        this.noLogo = this.companyData.company.noLogo;
        this.logoDisabled = Boolean(this.noLogo);
        this.formGroup.companyCategoryFormControl.setValue(this.companyData.company.companyCompanyCategoryId);
        this.investmentRangeFromFormControl.setValue(
            this.companyData.company.investmentRangeFrom ? this.companyData.company.investmentRangeFrom / 100 : null
        );
        this.investmentRangeToFormControl.setValue(
            this.companyData.company.investmentRangeTo ? this.companyData.company.investmentRangeTo / 100 : null
        );
        this.formGroup.investmentCommentFormControl.setValue(this.companyData.company.investmentComment || '');
        this.isDisplayedOnHomepage = this.companyData.company.isDisplayedOnHomepage;
        this.isInterstedInPartnerships = this.companyData.company.isInterstedInPartnerships;
        this.isInterstedInLicenses = this.companyData.company.isInterstedInLicenses;
        this.isInvestingInPublic = this.companyData.company.isInvestingInPublic;
        this.isInvestingInPrivate = this.companyData.company.isInvestingInPrivate;
        this.formGroup.therapeuticAreaFormControl.setValue(this.companyData.therapeuticAreasIdsOfInterest || []);
        this.formGroup.developmentPhaseFormControl.setValue(this.companyData.developmentPhasesIdsOfInterest || []);
        this.formGroup.projectTypeFormControl.setValue(this.companyData.projectTypesIdsOfInterest || []);

        // company contact information form control
        this.noContactInfos = this.companyData.company.noContactInfos;
        this.contactInformationFormControl.street1FormControl.setValue(this.companyData.company.street1 || '');
        this.contactInformationFormControl.street2FormControl.setValue(this.companyData.company.street2 || '');
        this.contactInformationFormControl.cityFormControl.setValue(this.companyData.company.city || '');
        this.contactInformationFormControl.countryFormControl.setValue(this.companyData.company.country || '');
        this.contactInformationFormControl.emailFormControl.setValue(this.companyData.company.email || '');
        this.contactInformationFormControl.phoneFormControl.setValue(this.companyData.company.phone || '');

        // make some fields required for investor
        if (this.currentUser.user.isInvestor) {
            this.makeCompanyFieldsRequired();
            this.formGroup.websiteFormControl.setValidators(Validators.required);
            this.formGroup.websiteFormControl.updateValueAndValidity();
        }

        this.companyContactFormControls = [];
        for (const contact of this.companyData.companyContacts) {
            const control: CompanyContactFormControl = this.onAddCompanyContactFormControl(false);
            control.companyContactId = contact.id;
            control.firstNameFormControl.setValue(contact.firstName);
            control.lastNameFormControl.setValue(contact.lastName);
            control.emailFormControl.setValue(contact.email);
            control.phoneFormControl.setValue(contact.phone || '');
        }
    }

    private checkInvestmentRange(): any {
        if (
            this.investmentRangeFromFormControl
            && this.investmentRangeToFormControl
            && this.investmentRangeFromFormControl.value
            && this.investmentRangeToFormControl.value
            && this.investmentRangeFromFormControl.value > this.investmentRangeToFormControl.value
        ) {
            return {inconsistency: {valid: false}};
        }

        return null;
    }

    private collectData(): CompanyDataRow {
        const company: Company = this.companyData.company.clone();
        company.name = this.formGroup.nameFormControl.value;
        company.description = this.formGroup.descriptionFormControl.value;
        company.website = this.formGroup.websiteFormControl.value;
        company.noWebsite = Number(this.noWebsite);
        company.logo = this.logoImageData.new ? this.logoImageData.new : this.logoImageData.current;
        company.noLogo = Number(this.noLogo);
        company.companyCompanyCategoryId = this.formGroup.companyCategoryFormControl.value;
        company.investmentRangeFrom = this.investmentRangeFromFormControl.value ?
            this.investmentRangeFromFormControl.value * 100 :
            null;
        company.investmentRangeTo = this.investmentRangeToFormControl.value ?
            this.investmentRangeToFormControl.value * 100 :
            null;
        company.investmentComment = this.formGroup.investmentCommentFormControl.value;
        company.isInterstedInLicenses = this.isInterstedInLicenses;
        company.isInvestingInPrivate = this.isInvestingInPrivate;
        company.isInvestingInPublic = this.isInvestingInPublic;
        company.isInterstedInPartnerships = this.isInterstedInPartnerships;
        company.isDisplayedOnHomepage = this.isDisplayedOnHomepage;

        company.street1 = this.contactInformationFormControl.street1FormControl.value;
        company.street2 = this.contactInformationFormControl.street2FormControl.value;
        company.city = this.contactInformationFormControl.cityFormControl.value;
        company.country = this.contactInformationFormControl.countryFormControl.value;
        company.email = this.contactInformationFormControl.emailFormControl.value;
        company.phone = this.contactInformationFormControl.phoneFormControl.value;
        company.noContactInfos = Number(this.noContactInfos);

        const contacts: CompanyContact[] = [];
        for (const control of this.companyContactFormControls) {
            const contact: CompanyContact = new CompanyContact();
            contact.id = control.companyContactId;
            contact.firstName = control.firstNameFormControl.value;
            contact.lastName = control.lastNameFormControl.value;
            contact.email = control.emailFormControl.value;
            contact.phone = control.phoneFormControl.value;
            contacts.push(contact);
        }

        return {
            company,
            companyCategory: this.utils.findIn(company.companyCompanyCategoryId, this.categories),
            companyContacts: contacts,
            therapeuticAreasOfInterest: this.utils.findManyIn(this.formGroup.therapeuticAreaFormControl.value, this.therapeuticAreas),
            therapeuticAreasIdsOfInterest: this.formGroup.therapeuticAreaFormControl.value,
            developmentPhasesOfInterest: this.utils.findManyIn(this.formGroup.developmentPhaseFormControl.value, this.developmentPhases),
            developmentPhasesIdsOfInterest: this.formGroup.developmentPhaseFormControl.value,
            projectTypesOfInterest: this.utils.findManyIn(this.formGroup.projectTypeFormControl.value, this.projectTypes),
            projectTypesIdsOfInterest: this.formGroup.projectTypeFormControl.value,
            rights: this.companyData.rights
        };
    }

    // check if all required fields of the company are set
    // not possible to check formControls, because the required fields are different for investors or admins
    private checkCompanyRequiredFields(companyData: CompanyDataRow): number {
        return Number(!(companyData.company.name === '' || companyData.company.companyCompanyCategoryId === null ||
            (companyData.company.website === '' && companyData.company.noWebsite === 0) ||
            (companyData.company.logo === null && companyData.company.noLogo === 0) ||
            companyData.therapeuticAreasIdsOfInterest.length === 0 ||
            companyData.projectTypesOfInterest.length === 0 ||
            (companyData.company.noContactInfos === 0 &&
                companyData.company.city === '' ||
                companyData.company.country === '' ||
                companyData.company.email === '')
            || companyData.companyContacts.length === 0));
    }

    private saveCompany(companyData: CompanyDataRow): Promise<Company> {
        // if all required fields of the company are set, then the company is marked as isCompleted
        // if an admin deletes an required field, the company is not completed anymore
        companyData.company.isCompleted = this.checkCompanyRequiredFields(companyData);
        return new Promise<Company>((resolve, reject) => {
            this.investorsProvider.saveCompany(companyData.company)
                .then((company: Company) => {
                    Promise.all([
                        this.investorsProvider.saveCompanyTherapeuticAreas(company.id, companyData.therapeuticAreasIdsOfInterest),
                        this.investorsProvider.saveCompanyDevelopmentPhases(company.id, companyData.developmentPhasesIdsOfInterest),
                        this.investorsProvider.saveCompanyProjectTypes(company.id, companyData.projectTypesIdsOfInterest),
                        this.investorsProvider.saveCompanyContacts(company.id, companyData.companyContacts)
                    ])
                        .then(() => {
                            resolve(company);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    private deleteCompany(companyData: CompanyDataRow): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.investorsProvider.deleteCompany(companyData.company)
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any, row: CompanyDataRow = null): void {
        this.isLoading = false;
        this.isSaveLoading = false;
        if (row) {
            row.isLoading = false;
        }
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}

export interface CompanyContactFormControl {
    companyContactId: number;
    firstNameFormControl: FormControl;
    lastNameFormControl: FormControl;
    emailFormControl: FormControl;
    phoneFormControl: FormControl;
}

export interface CompanyContactInformationFormControl {
    street1FormControl: FormControl;
    street2FormControl: FormControl;
    cityFormControl: FormControl;
    countryFormControl: FormControl;
    emailFormControl: FormControl;
    phoneFormControl: FormControl;
}
