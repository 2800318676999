<module-content [title]="'Investment_preferences' | translate">
    <div class="line">
        <mat-form-field>
            <mat-select (selectionChange)="_onFormChanged()"
                        [formControl]="therapeuticAreaFormControl"
                        [placeholder]="('Therapeutic_areas_of_interest' | translate) + '*'"
                        multiple>
                <div class="all-none-container">
                    <button (click)="onAllTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
                <mat-option *ngFor="let area of therapeuticAreas"
                            [value]="area.id">
                    {{ area.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="therapeuticAreaFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field>
            <mat-select (selectionChange)="_onFormChanged()"
                        [formControl]="developmentPhaseFormControl"
                        [placeholder]="('Development_phases_of_interest' | translate) + '*'"
                        multiple>
                <mat-option *ngFor="let phase of developmentPhasesWithAssociatedProjectTypeGroups"
                            [value]="phase.developmentPhase.id">
                    {{ phase.developmentPhase.name }}
                    <!--&nbsp;<span class="legend">(</span><span *ngFor="let group of phase.groups; let i = index" class="legend">{{ group.name + (i<phase.groups.length - 1 ? ', ' : '') }}</span><span class="legend">)</span>-->
                </mat-option>
            </mat-select>
            <mat-error *ngIf="developmentPhaseFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div>
        <div class="title">{{ 'Investment_range' | translate }}</div>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onInvestmentRangeChanged()"
                   [formControl]="investmentRangeFromFormControl"
                   [placeholder]="'Investment_range_from' | translate"
                   matInput
                   type="number">
            <span matPrefix>CHF&nbsp;</span>
            <mat-error *ngIf="investmentRangeFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onInvestmentRangeChanged()"
                   [formControl]="investmentRangeToFormControl"
                   [placeholder]="'Investment_range_to' | translate"
                   matInput
                   type="number">
            <span matPrefix>CHF&nbsp;</span>
            <mat-error *ngIf="investmentRangeToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isInterstedInNotForProfit"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Intersted_in_non_profit_ventures' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isInterstedInPartnerships"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Intersted_in_partnerships' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isInterstedInLicenses"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Intersted_to_in_licence_a_technology_of_product' | translate }}</span>
        </mat-checkbox>
    </div>
    <!--
    <div class="line">
        <mat-checkbox [(ngModel)]="acceptedToBeContacted"
                      color="primary"
                      [disabled]="!investorRights.EDIT"
                      (change)="_onFormChanged()">
            <span>{{ 'I_accept_to_be_contacted_if_deals_match_preferences' | translate }}</span>
        </mat-checkbox>
    </div>
    -->
</module-content>
<module-content [title]="'Notifications' | translate">
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isNotificationsAlertEnabled"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Receive_notification_new_project' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isNotificationsDevPhaseEnabled"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Receive_notification_dev_phase' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isNotificationsSeekingFundsEnabled"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Receive_notification_seeking_funds' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="_onFormChanged()"
                      [(ngModel)]="isNotificationsLeadInvestorEnabled"
                      [disabled]="!investorRights.EDIT"
                      color="primary">
            <span>{{ 'Receive_notification_lead_investor' | translate }}</span>
        </mat-checkbox>
    </div>
</module-content>

<alerts-list *ngIf="currentUser.user.isInvestor || currentUser.user.isGuest"></alerts-list>

<module-content [title]="'Company' | translate" class="margin-top-32">
    <div class="line">
        <div class="company-line">
            <div *ngIf="currentUser.user.isAdmin; else viewCompany">
                <mat-select (selectionChange)="_onFormChanged()"
                            [formControl]="companyFormControl"
                            [placeholder]="'Company_name' | translate">
                    <mat-option *ngFor="let company of companies"
                                [value]="company.id">
                        {{ company.name }}
                    </mat-option>
                </mat-select>
            </div>
            <ng-template #viewCompany>
                <div *ngIf="linkedCompany">{{ linkedCompany.name }}</div>
            </ng-template>
        </div>
        <div *ngIf="currentUser.user.isAdmin || investorProfile.investorValidationStateId === ID_ACCEPTED">
            <button
                [matMenuTriggerFor]="menu"
                mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button (click)="onEditClicked()"
                        [disabled]="false"
                        mat-menu-item>
                    <mat-icon>edit</mat-icon>&nbsp;{{ 'Edit' | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</module-content>
<module-content *ngIf="investorRights.VALIDATE"
                [isLoading]="isLoading"
                [title]="'Validation' | translate">
    <p>{{ 'Investor_validation_explanation_1' | translate }}</p>
    <p>{{ 'Investor_validation_explanation_2' | translate }}</p>
    <div class="title">{{ 'Company' | translate }}</div>
    <p *ngIf="linkedCompany">
        {{ 'Investor_validation_company_completed' | translate }}
        {{ linkedCompany.isCompleted ? ('Yes' | translate) : ('No' | translate) }}
    </p>
    <div class="actions">
        <button (click)="onValidateClicked(true)"
                [disabled]="isLoading"
                color="primary"
                mat-raised-button>
            {{ 'Accept' | translate }}
        </button>
        <button (click)="onValidateClicked(false)"
                [disabled]="isLoading"
                color="warn"
                mat-raised-button>
            {{ 'Refuse' | translate }}
        </button>
    </div>
</module-content>
