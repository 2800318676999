<div mat-dialog-title>{{ title | translate }}</div>
<mat-dialog-content>
    <div class="line">
        <mat-form-field color="primary">
            <input [formControl]="nameFormControl"
                   matInput
                   maxlength="255"
                   placeholder="{{ 'Name' | translate }}"/>
            <mat-error *ngIf="nameFormControl.hasError('required')">{{ 'Field_required' | translate }}</mat-error>
            <mat-hint align="end">{{ nameFormControl.value.length }} / {{ MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <!--
    <div class="line">
        <input-image [placeholder]="'Image'"
                     [allowedExtensions]="['jpg', 'jpeg', 'png']"
                     [imageHost]="conf.storageRootUrl"
                     [maxFileSize]="5"
                     [(ngModel)]="image">
        </input-image>
    </div>
        -->
    <div class="participants-container">
        <div class="title">{{ 'Group_participants' | translate }}</div>
        <div *ngIf="usersDataSource.count === 0" class="legend">{{ 'No_user_yet' | translate }}</div>
        <mat-form-field *ngIf="usersDataSource.count > 0"
                        class="search-bar"
                        color="primary">
            <input (keyup)="onFilterUsersKeyup($event.target.value)"
                   matInput
                   maxlength="255"
                   placeholder="{{ 'Filter' | translate }}"
                   type="text">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <mat-table *ngIf="usersDataSource.count > 0"
                   [dataSource]="usersDataSource">
            <mat-header-row *matHeaderRowDef="usersColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: usersColumns;"></mat-row>
            <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef class="image-cell hideable-500"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="image-cell hideable-500">
                    <img *ngIf="row.user.avatar"
                         [src]="conf.storageRootUrl + row.user.avatar"/>
                    <mat-icon *ngIf="!row.user.avatar">
                        account_circle
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef class="name-cell"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="name-cell">
                    <div>{{ row.user.firstName + ' ' + row.user.lastName }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="actions-cell">
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="actions-cell">
                    <mat-checkbox [(ngModel)]="row.isSelected"></mat-checkbox>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator [class.hidden]="usersDataSource.count == 0"
                       [pageSizeOptions]="[10]"
                       hidePageSize
                       showFirstLastButtons>
        </mat-paginator>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onSubmitClicked()"
            [disabled]="!canSave()"
            color="accent"
            mat-raised-button>
        {{ 'Save' | translate}}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading"
                      color="accent"
                      mode="indeterminate">
    </mat-progress-bar>
</div>
