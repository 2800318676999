<mat-dialog-content>
    <p>{{ 'Review_explanation_5' | translate }}</p>
    <mat-form-field [hintLabel]="''"
                    color="primary">
        <textarea [formControl]="messageFormControl"
                  [maxlength]="MESSAGE_MAX_LENGTH"
                  [placeholder]="'Message' | translate"
                  matInput
                  matTextareaAutosize></textarea>
        <mat-error *ngIf="messageFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        <mat-hint align="end">{{ messageFormControl.value.length }}/{{ MESSAGE_MAX_LENGTH }}</mat-hint>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
    <button (click)="onConfirmClicked()" color="primary" mat-raised-button>{{ 'Ask_changes' | translate }}</button>
</mat-dialog-actions>
