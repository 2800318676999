<module-content [mainActions]="canAddMembership ? [addMembershipMenu] : []"
                [title]="null">
    <mat-table (matSortChange)="onDataSortChange($event)"
               [dataSource]="dataSource"
               matSort>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="name-cell">
                {{row.user.firstName}}&nbsp;{{row.user.lastName}}
                <ng-container *ngIf="row.user.id === project.ownerId">&nbsp;<mat-icon
                    [matTooltip]="'Project_owner' | translate">error_outline
                </mat-icon>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef class="hideable-500"
                             mat-sort-header>{{ 'Email' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="hideable-500">{{row.user.email}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef class="hideable-500"
                             mat-sort-header>{{ 'Added_date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row"
                      class="hideable-500">{{row.membership.createDate | date:'mediumDate' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <button *ngIf="row.rights.DELETE_PROJECT_MEMBERSHIP && !row.isLoading"
                        [matMenuTriggerFor]="menu"
                        mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button (click)="onDeleteClicked.emit(row)"
                            [disabled]="!row.rights.DELETE_PROJECT_MEMBERSHIP || row.user.id === project.ownerId"
                            mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'Delete' | translate }}</span>
                    </button>
                </mat-menu>
                <mat-spinner *ngIf="row.isLoading"
                             [diameter]="24">
                </mat-spinner>
            </mat-cell>
        </ng-container>
    </mat-table>
    <mat-paginator [hidePageSize]="true"
                   [pageSizeOptions]="[10]">
    </mat-paginator>
</module-content>
