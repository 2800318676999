import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { AcademicInstitutionGroupWithInstitution, ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { AcademicInstitution, AcademicInstitutionGroup, Project } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-academic',
    providers: [],
    styleUrls: ['./edit-project-academic.scss'],
    templateUrl: './edit-project-academic.html'
})
export class ProjectsEditProjectAcademicComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly CUSTOM_INSTITUTION_MAX_LENGTH: number = 250;
    public readonly INSTITUTION_OTHER: number = AcademicInstitution.ID_OTHER;
    public readonly EXCLUDED_INSTITUTION1_GROUP_IDS: number[] = [AcademicInstitutionGroup.ID_OTHER];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public academicInstitution1FormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);
    public academicInstitution2FormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);
    public customAcademicInstitutionFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.CUSTOM_INSTITUTION_MAX_LENGTH)
    ]);
    public isTTOAgreementAccepted: boolean = false;
    public isInstitutionLicenseOwned: boolean = false;
    public ttoContactFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.CUSTOM_INSTITUTION_MAX_LENGTH)
    ]);
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    public academicInstitionGroups: AcademicInstitutionGroupWithInstitution[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.academicInstitution1FormControl.setValue(project.academicInstitution1Id);
        this.academicInstitution2FormControl.setValue(project.academicInstitution2Id);
        this.customAcademicInstitutionFormControl.setValue(project.customAcademicInstitution ? project.customAcademicInstitution : '');
        this.isTTOAgreementAccepted = project.isTTOAgreementAccepted ? true : false;
        this.isInstitutionLicenseOwned = project.isInstitutionLicenseOwned ? true : false;
        this.ttoContactFormControl.setValue(project.TTOContact ? project.TTOContact : '');

        this.manageAcademicInstitutionStuff(); // doit être appelé après avoir initialiser les données !!!
        this.updateRights();
    }

    private _rights: ProjectRights = new ProjectRights(this._utils);

    public get rights() {
        return this._rights;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set rights(rights: ProjectRights) {
        this._rights = rights;
        this.updateRights();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        return !this.academicInstitution1FormControl.invalid
            && !this.academicInstitution2FormControl.invalid
            && !this.customAcademicInstitutionFormControl.invalid
            && !this.customAcademicInstitutionFormControl.invalid;
    }

    public onAcademicInstitutionChanged(): void {
        this.manageAcademicInstitutionStuff();
        this._onFormChanged();
    }

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onIsInstitutionLicenseOwnedChanged(): void {
        this.isTTOAgreementAccepted = false;
        this._onFormChanged();
    }

    public onIsTTOAgreementAcceptedChanged(): void {
        this.isInstitutionLicenseOwned = false;
        this._onFormChanged();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._projectsProvider.getAcademicInstitionGroups()
            ])
                .then((data: any[]) => {
                    this.academicInstitionGroups = data[0];

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.academicInstitution2FormControl.enable();
            this.customAcademicInstitutionFormControl.enable();
            this.ttoContactFormControl.enable();
        } else {
            this.academicInstitution2FormControl.disable();
            this.customAcademicInstitutionFormControl.disable();
            this.ttoContactFormControl.disable();
        }
        if (this.rights.EDIT_ACADEMIC_INSTITUTION_1) {
            this.academicInstitution1FormControl.enable();
        } else {
            this.academicInstitution1FormControl.disable();
        }
    }

    private manageAcademicInstitutionStuff(): void {
        if (this.academicInstitution1FormControl.value != AcademicInstitution.ID_OTHER
            && this.academicInstitution2FormControl.value != AcademicInstitution.ID_OTHER) {
            this.customAcademicInstitutionFormControl.setValue('');
        }
        this.fireChange();
    }

    private fireChange(): void {
        this._project.academicInstitution1Id = this.academicInstitution1FormControl.value;
        this._project.academicInstitution2Id = this.academicInstitution2FormControl.value;
        this._project.customAcademicInstitution = this.customAcademicInstitutionFormControl.value;
        this._project.isTTOAgreementAccepted = this.isTTOAgreementAccepted ? 1 : 0;
        this._project.isInstitutionLicenseOwned = this.isInstitutionLicenseOwned ? 1 : 0;
        this._project.TTOContact = this.ttoContactFormControl.value;

        if (this._project.academicInstitution1Id !== AcademicInstitution.ID_OTHER && this._project.academicInstitution2Id !== AcademicInstitution.ID_OTHER) {
            this._project.customAcademicInstitution = null;
        }

        this.projectChange.emit(this._project);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
