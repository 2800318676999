import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { UsersProvider } from '../../providers/users-provider';
import { CurrentUser } from '../../../_common/providers/current-user';
import { User, } from '../../../_common/providers/models';

@Component({
    selector: 'users-change-password-dialog',
    providers: [],
    styleUrls: ['./change-password-dialog.scss'],
    templateUrl: './change-password-dialog.html'
})
export class UsersChangePasswordDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public PASSWORD_MIN_LENGTH = 8;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showPassword: boolean = false;
    public passwordWrongError: boolean = false;
    public currentPasswordFormControl: FormControl = new FormControl('', [
        Validators.required,
        // doc : https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html#building-a-custom-validator
        (control: FormControl) => this.passwordWrongError ? {wrong: {valid: false}} : null
    ]);
    public newPassword1FormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]);
    public newPassword2FormControl: FormControl = new FormControl('', [
        // Validators.required,
        (control: FormControl) => this.checkPasswords()
    ]);

    public user: User = new User();

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public dialog: MatDialogRef<UsersChangePasswordDialogComponent>,
        public utils: Utils,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _users: UsersProvider,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.user = _data.user;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onSaveClicked(): void {
        this.isLoading = true;
        this.save()
            .then((result) => {
                this._toaster.open(this._translater.instant('Password_updated'), '', {duration: 5000});
                this.isLoading = false;
                this.dialog.close(result);
            })
            .catch((error) => this.onError(error));
    }

    public onCurrentPasswordChange(): void {
        this.passwordWrongError = false;
    }

    public onPassword1Change(): void {
        if (!this.newPassword2FormControl.pristine) {
            this.newPassword2FormControl.updateValueAndValidity();
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private save(): Promise<any> {
        return this._users.changePassword(this.user, this.newPassword1FormControl.value, this.currentPasswordFormControl.value);
    }

    private onError(error: any): void {
        this.isLoading = false;

        if (error.status === 401) {
            this.passwordWrongError = true;
            this.currentPasswordFormControl.updateValueAndValidity();
            return;
        }

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }

    private checkPasswords(): any {
        return null;
        /*
        if (!this.newPassword1FormControl
            || !this.newPassword2FormControl
            || this.newPassword1FormControl.value === this.newPassword2FormControl.value) {
            return null;
        }

        return { match: { valid: false } };
        */
    }
}
