<page-content *ngIf="!isError404">
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="backMenu"
            [pageTitle]="'Account_settings' | translate"
            header>
        <ng-container right-content>
            <investors-investor-state-indicator *ngIf="investorRights.SEE_EDIT_FORM"
                                                [investorProfile]="investorProfile">
            </investors-investor-state-indicator>
        </ng-container>
    </header>
    <investors-edit-investor-profile *ngIf="investorRights.SEE_EDIT_FORM"
                                     [(developmentPhaseIds)]="developmentPhaseIdsOfInterest"
                                     [(investorProfile)]="investorProfile"
                                     [(therapeuticAreaIds)]="therapeuticAreaIdsOfInterest"
                                     [investorRights]="investorRights">
    </investors-edit-investor-profile>
    <div *ngIf="userRights.SEE_EDIT_FORM"
         class="module-content">
        <module-content [title]="'Account' | translate">
            <mat-form-field class="line">
                <input (keyup)="onFormChange()"
                       [formControl]="firstNameFormControl"
                       matInput
                       maxlength="255"
                       placeholder="{{ 'First_name' | translate }}*"/>
                <mat-error
                    *ngIf="firstNameFormControl.hasError('required')">{{ 'The_first_name_required' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field class="line">
                <input (keyup)="onFormChange()"
                       [formControl]="lastNameFormControl"
                       matInput
                       maxlength="255"
                       placeholder="{{ 'Last_name' | translate }}*"/>
                <mat-error
                    *ngIf="lastNameFormControl.hasError('required')">{{ 'The_last_name_required' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field class="line">
                <input (keyup)="onEmailChange()"
                       [formControl]="emailFormControl"
                       matInput
                       maxlength="255"
                       placeholder="{{ 'Email_address' | translate }}*"/>
                <mat-error *ngIf="emailFormControl.hasError('email')">{{ 'Email_invalid' | translate }}</mat-error>
                <mat-error
                    *ngIf="emailFormControl.hasError('unavailable')">{{ 'Email_unavailable' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="this.user.isInvestor" class="line">
                <input (keyup)="onFormChange()"
                       [formControl]="phoneFormControl"
                       [maxlength]="investorProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH.toString()"
                       matInput
                       placeholder="{{ 'Phone' | translate }}*"/>
                <mat-error
                    *ngIf="phoneFormControl.invalid">{{ 'International_phone_number_hint' | translate }}</mat-error>
                <mat-hint>{{ 'Account_phone_hint' | translate }}</mat-hint>
                <mat-hint align="end">{{ phoneFormControl.value.length }}
                    /{{ investorProvider.COMPANY_NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <!--
            <mat-form-field class="line">
                <textarea matInput
                            class="text-area-unresizable"
                            [formControl]="introFormControl"
                            #userIntroInput
                            matTextareaAutosize
                            placeholder="{{ 'User_intro' | translate }}"
                            (keyup)="onIntroChange()"></textarea>
                <mat-hint align="end">{{ userIntroInput.value.length }} / {{ DESCRIPTION_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <mat-form-field class="line">
                <mat-select placeholder="{{ 'Lang' | translate }}"
                            [formControl]="langFormControl"
                            (change)="onLangChange()">
                    <mat-option *ngFor="let lang of langs" [value]="lang.id">
                        {{ lang.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            -->
            <input-image (change)="onAvatarImageChange()"
                         *ngIf="!investorProfile"
                         [(ngModel)]="avatarImage"
                         [allowedExtensions]="['jpg', 'jpeg', 'png']"
                         [disabled]="!userRights.EDIT"
                         [imageHost]="conf.storageRootUrl"
                         [maxFileSize]="5"
                         [placeholder]="'Avatar'"
                         class="line"></input-image>
            <button (click)="onChangePasswordClicked()"
                    *ngIf="userRights.CHANGE_PASSWORD"
                    [disabled]="isLoading"
                    color="primary"
                    mat-raised-button>
                {{ 'Change_password' | translate }}
            </button>
        </module-content>
    </div>
    <div class="module-content">
        <module-content *ngIf="userRights.DELETE"
                        [title]="'Admin' | translate">
            <button (click)="onDeleteClicked()"
                    [disabled]="isLoading"
                    color="warn"
                    mat-raised-button>
                {{ 'Delete_account' | translate }}
            </button>
        </module-content>
    </div>
    <main-action (onAction)="onSaveClicked()"
                 *ngIf="userRights.EDIT || currentUser.user.isAdmin"
                 [color]="currentUser.user.isInvestor ? (canSave() ? 'accent' : '') : 'accent'"
                 [disabled]="isLoading"
                 [displayMode]="'fab'"
                 [icon]="'check'"
                 [isLoading]="isLoading"
                 [title]="'Save'">
    </main-action>
    <footer footer></footer>
</page-content>
<error-404 *ngIf="isError404"></error-404>
