<div mat-dialog-title>{{ 'Change_password' | translate }}</div>
<mat-dialog-content>
    <div *ngIf="1>2 && !currentUser.user.isAdmin" class="line">
        <mat-form-field>
            <input (keyup)="onCurrentPasswordChange()"
                   [formControl]="currentPasswordFormControl"
                   matInput
                   placeholder="{{ 'Your_current_password' | translate }}"
                   type="password">
            <mat-error
                *ngIf="currentPasswordFormControl.hasError('required')">{{ 'Password_required' | translate }}</mat-error>
            <mat-error
                *ngIf="currentPasswordFormControl.hasError('wrong')">{{ 'Password_wrong' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field>
            <input (keyup)="onPassword1Change()"
                   [formControl]="newPassword1FormControl"
                   [type]="showPassword ? 'text' : 'password'"
                   matInput
                   placeholder="{{ 'New_password' | translate }}">
            <mat-error
                *ngIf="newPassword1FormControl.hasError('required')">{{ 'New_password_required' | translate }}</mat-error>
            <mat-error
                *ngIf="newPassword1FormControl.hasError('minlength')">{{ 'Password_too_short' | translate:{length: PASSWORD_MIN_LENGTH} }}</mat-error>
            <button (click)="showPassword = !showPassword"
                    [matTooltip]="(showPassword ? 'Hide' : 'Show') | translate"
                    mat-icon-button
                    matSuffix>
                <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <!--
    <div class="line">
        <mat-form-field>
            <input matInput
                   type="password"
                   [formControl]="newPassword2FormControl"
                   placeholder="{{ 'Repeat_password' | translate }}">
            <mat-error *ngIf="newPassword2FormControl.hasError('required')">{{ 'New_password_required' | translate }}</mat-error>
            <mat-error *ngIf="newPassword2FormControl.hasError('match')">{{ 'Passwords_dont_match' | translate }}</mat-error>
        </mat-form-field>
    </div>
    -->
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onSaveClicked()"
            [disabled]="isLoading
                || newPassword1FormControl.invalid
                || newPassword2FormControl.invalid
                ? 'true' : null"
            color="accent"
            mat-raised-button>
        {{ 'Save' | translate}}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
