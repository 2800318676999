<div (click)="onToggleMenuClicked($event)"
     *ngIf="isMenuVisibleOnSmallScreen"
     class="menu-toggler-background">
</div>
<button (click)="onToggleMenuClicked($event)"
        class="menu-toggler"
        mat-icon-button>
    <mat-icon *ngIf="!isMenuVisibleOnSmallScreen">menu</mat-icon>
    <mat-icon *ngIf="isMenuVisibleOnSmallScreen">close</mat-icon>
</button>
<div [class.small-screen-hider]="!isMenuVisibleOnSmallScreen"
     class="wrapper">
    <div class="top">
        <img *ngIf="isMenuVisibleOnSmallScreen"
             [routerLink]="'/'"
             [src]="conf.assetsUrl + 'img/sbv-logo.svg'"
             class="logo"/>
    </div>
    <div class="container">
        <div class="menu-items">
            <div *ngFor="let group of menuGroups"
                 class="menu-group-container">
                <div class="title">{{ group.title }}</div>
                <button (click)="onMenuItemClicked(menu)"
                        *ngFor="let menu of group.menus"
                        [class.selected]="currentUrl == menu.url"
                        [class.text-primary-color]="currentUrl != menu.url"
                        class="menu-item"
                        mat-button>
                    <div class="menu-item-wrapper">
                        <mat-icon *ngIf="menu.icon && !menu.isLoading"
                                  color="accent">
                            {{ menu.icon }}
                        </mat-icon>
                        <mat-spinner *ngIf="menu.isLoading"
                                     [diameter]="24"
                                     color="accent">
                        </mat-spinner>
                        <span>{{ menu.title | translate }}</span>
                        <div *ngIf="menu.chip"
                             [class.accent]="menu.chipColor === 'accent'"
                             [class.primary]="menu.chipColor === 'primary'"
                             [class.warn]="menu.chipColor === 'warn'"
                             class="chip">
                            {{ menu.chip }}
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="width-hack"></div>
