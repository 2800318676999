import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { FilesProvider } from '../../providers/files-provider';
import { CurrentUser } from '../../../_common/providers/current-user';
import { File, FileType } from '../../../_common/providers/models';

import * as FileSaver from 'file-saver';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'files-image-viewer-dialog',
    providers: [],
    styleUrls: ['./image-viewer-dialog.scss'],
    templateUrl: './image-viewer-dialog.html'
})
export class FilesImageViewerDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public isDownloadLoading: boolean = false;
    public src: any = null;

    public file: File = new File();
    public type: FileType = new FileType();
    public downloadApi: string = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public dialog: MatDialogRef<FilesImageViewerDialogComponent>,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _files: FilesProvider,
        private _translater: TranslateService,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.file = this._data.file;
        this.type = this._data.type;
        this.downloadApi = this._data.downloadApi;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadImage()
            .then(() => this.isLoading = false)
            .catch((error) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDownloadClicked(): void {
        if (this.downloadApi) {
            this.isDownloadLoading = true;
            this._files.downloadFile(this.downloadApi, this.file)
                .then((fileData: any) => {
                    const fileName: string = this.file.name + '.' + this.type.extension;
                    FileSaver.saveAs(fileData, fileName);
                    this.isDownloadLoading = false;
                })
                .catch((error) => this.onError(error));
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadImage(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.downloadApi) {
                this._files.downloadFile(this.downloadApi, this.file)
                    .then((fileData: any) => {
                        // tiré de : https://stackoverflow.com/questions/45530752/getting-image-from-api-in-angular-4
                        const reader: FileReader = new FileReader();
                        reader.addEventListener('load', () => this.src = reader.result, false);
                        reader.readAsDataURL(fileData);
                        resolve();
                    })
                    .catch((error) => this.onError(error));
            } else {
                reject({error: 'No download API'});
            }
        });
    }

    private onError(error: any): void {
        this.isDownloadLoading = false;
        this.isLoading = false;

        console.log(error);
    }
}
