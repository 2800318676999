<button [color]="isInvalid ? 'warn' : (selectionLabel ? 'accent' : null)"
        [matMenuTriggerFor]="menu"
        [matTooltip]="label"
        class="trigger-button"
        mat-raised-button>
    <div class="flex-hack">
        <div class="text">{{ selectionLabel ? selectionLabel : label }}&nbsp;</div>
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</button>
<mat-menu #menu="matMenu">
    <ng-content></ng-content>
</mat-menu>
