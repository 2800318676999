<mat-dialog-content>
    <ng-container *ngFor="let showRoom of showRoomsData; let i = index">
        <acventures-common-home-showroom [hidden]="currentIndex !== i"
                                         [icon]="showRoom.icon"
                                         [image]="showRoom.image"
                                         [orientation]="showRoom.orientation"
                                         [text]="showRoom.text | translate"
                                         [title]="showRoom.title | translate">
        </acventures-common-home-showroom>
    </ng-container>
    <div class="paginator">
        <div>{{ (currentIndex + 1) + ' / ' + showRoomsData.length }}</div>
        <button (click)="onPreviousClicked()"
                [disabled]="currentIndex == 0"
                [matTooltip]="'Previous' | translate"
                mat-icon-button>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button (click)="onNextClicked()"
                [disabled]="currentIndex == showRoomsData.length - 1"
                [matTooltip]="'Next' | translate"
                mat-icon-button>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button
            mat-dialog-close>
        {{ 'Close' | translate }}
    </button>
</mat-dialog-actions>
