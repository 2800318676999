import { AfterViewInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { DiscussionsEditGroupDialogComponent } from '../edit-group-dialog/edit-group-dialog';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ActivePrivateDiscussion, DiscussionsProvider, PrivateDiscussionData } from '../../providers/discussions-provider';
import { CurrentUser } from '../../../_common/providers/current-user';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'discussions-private-discussions-menu',
    providers: [],
    styleUrls: ['./private-discussions-menu.scss'],
    templateUrl: './private-discussions-menu.html'
})
export class DiscussionsPrivateDiscussionsMenuComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public discussions: DiscussionsProvider,
        private _translater: TranslateService,
        private _dialoger: MatDialog,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    public onPrivateDiscussionClicked(discussionData: PrivateDiscussionData): void {
        if (discussionData.privateDiscussion.isGroup) {
            this.discussions.startGroupDiscussion(discussionData.privateDiscussion)
                .catch((error: any) => this.onError(error));
        } else {
            this.discussions.startOneToOneDiscussion(discussionData.usersData[0].user)
                .catch((error: any) => this.onError(error));
        }
    }

    public onPrivateDiscussionClosed(discussion: ActivePrivateDiscussion): void {
        this.discussions.closePrivateDiscussion(discussion);
    }

    public onCreateGroupClicked(): void {
        const dialog: MatDialogRef<DiscussionsEditGroupDialogComponent> = this._dialoger.open(DiscussionsEditGroupDialogComponent, {
            data: {}
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
