import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SyncServiceProvider } from '../../../_common/providers/sync-service';

@Component({
    selector: 'users-user-menu',
    providers: [],
    styleUrls: ['./user-menu.scss'],
    templateUrl: './user-menu.html'
})
export class UsersUserMenuComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public loginText: string = 'Access_platform';
    @Input() public loginNextUrl: string = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _sync: SyncServiceProvider,
        private _translater: TranslateService,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onLogoutClicked(): void {
        this._router.navigate(['logout']);
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
