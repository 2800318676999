<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header header>
    </header>
    <error-401 *ngIf="!currentUser.user.isInvestor && !currentUser.user.isGuest"></error-401>
    <favorites-list *ngIf="currentUser.user.isInvestor || currentUser.user.isGuest"></favorites-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
