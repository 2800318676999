<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="!currentUser.user.isInvestor && backMenu"
            [pageTitle]="'Company_details' | translate"
            header>
    </header>
    <error-404 *ngIf="is404"></error-404>
    <error-401 *ngIf="is401"></error-401>
    <ng-container *ngIf="companyData && !is404 && !is401">
        <module-content>
            <div class="top">
                <img *ngIf="companyData.company.logo"
                     [alt]="'Logo' | translate"
                     [src]="conf.storageRootUrl + companyData.company.logo">
                <div class="main-information">
                    <div class="title">{{ companyData.company.name }}</div>
                    <div class="subtitle">{{ companyData.companyCategory.name }}</div>
                    <div *ngIf="companyData.company.description" class="description">
                        <quill-view-html [content]="companyData.company.description"></quill-view-html>
                    </div>

                    <div *ngIf="companyData.company.website"
                         class="website">
                        <a (click)="onWebsiteClicked()">{{ companyData.company.website | myStripHttp }}</a>
                    </div>
                </div>
            </div>
        </module-content>
        <module-content [title]="'Investment_preferences'">
            <div class="middle">
                <div class="line">
                    <span>{{ 'Investment_range' | translate }}:</span>
                    <span *ngIf="!companyData.company.investmentRangeFrom && !companyData.company.investmentRangeTo"
                          class="legend">{{ 'Not_specified' | translate }}</span>
                    <span
                        *ngIf="companyData.company.investmentRangeFrom">{{ (companyData.company.investmentRangeFrom / 100) | currency:'CHF ' : 'symbol' : '1.0-0' }}</span>
                    <span
                        *ngIf="companyData.company.investmentRangeFrom && companyData.company.investmentRangeTo"> - </span>
                    <span
                        *ngIf="companyData.company.investmentRangeTo">{{ (companyData.company.investmentRangeTo / 100) | currency:'CHF ' : 'symbol' : '1.0-0'  }}</span>
                </div>
                <div *ngIf="companyData.company.investmentComment"
                     class="line description">
                    {{ companyData.company.investmentComment }}
                </div>
                <div *ngIf="companyData.company.isInterstedInLicenses"
                     class="line">
                    <mat-icon>check_box</mat-icon>
                    <span>{{ 'Intersted_to_in_licence_a_technology_of_product' | translate }}</span>
                </div>
                <div *ngIf="companyData.company.isInterstedInLicenses"
                     class="line">
                    <mat-icon>check_box</mat-icon>
                    <span>{{ 'Intersted_in_partnerships' | translate }}</span>
                </div>
                <div *ngIf="companyData.company.isInvestingInPublic"
                     class="line">
                    <mat-icon>check_box</mat-icon>
                    <span>{{ 'Investing_in_public' | translate }}</span>
                </div>
                <div *ngIf="companyData.company.isInvestingInPrivate"
                     class="line">
                    <mat-icon>check_box</mat-icon>
                    <span>{{ 'Investing_in_private' | translate }}</span>
                </div>
            </div>
            <div class="bottom">
                <div class="box">
                    <div class="title">{{ 'Therapeutic_areas_of_interest' | translate }}</div>
                    <div *ngIf="!companyData.therapeuticAreasOfInterest.length"
                         class="legend">{{ 'Not_specified' | translate }}</div>
                    <ul *ngIf="companyData.therapeuticAreasOfInterest.length">
                        <li *ngFor="let item of companyData.therapeuticAreasOfInterest">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="box">
                    <div class="title">{{ 'Development_phases_of_interest' | translate }}</div>
                    <div *ngIf="!companyData.developmentPhasesIdsOfInterest.length"
                         class="legend">{{ 'Not_specified' | translate }}</div>
                    <ul *ngIf="companyData.developmentPhasesIdsOfInterest.length">
                        <li *ngFor="let item of companyData.developmentPhasesOfInterest">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="box">
                    <div class="title">{{ 'Project_types_of_interest' | translate }}</div>
                    <div *ngIf="!companyData.projectTypesIdsOfInterest.length"
                         class="legend">{{ 'Not_specified' | translate }}</div>
                    <ul *ngIf="companyData.projectTypesIdsOfInterest.length">
                        <li *ngFor="let item of companyData.projectTypesOfInterest">{{ item.name }}</li>
                    </ul>
                </div>
            </div>
        </module-content>
        <module-content *ngIf="displayAdress()"
                        [title]="'Contact' | translate">
            <div class="bottom">
                <div class="box">
                    <div class="title contact-title">{{ 'Adress' | translate }}:</div>
                    <span class="adress-element">{{ companyData.company.street1 }},</span>
                    <span *ngIf="companyData.company.street2" class="adress-element">{{ companyData.company.street2 }}
                        ,</span>
                    <span class="adress-element">{{ companyData.company.city }},</span>
                    <span class="adress-element">{{ companyData.company.country }}</span>
                </div>
                <div class="box">
                    <div class="title contact-title">{{ 'Email_switchboard' | translate }}:</div>
                    <span><a
                        [href]="'mailto:' + companyData.company.email "> {{ companyData.company.email  }}</a></span>
                </div>
                <div class="box">
                    <div class="title contact-title">{{ 'Phone_switchboard' | translate }}:</div>
                    <span><a [href]="'tel:' + companyData.company.phone "> {{ companyData.company.phone  }}</a></span>
                </div>
            </div>
        </module-content>
        <module-content *ngIf="companyData.companyContacts.length"
                        [title]="'Contacts' | translate">
            <mat-table (matSortChange)="onDataSortChange($event)"
                       *ngIf="dataSource.count > 0"
                       [dataSource]="dataSource"
                       matSort>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns; let i = index;"></mat-row>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef class="name-cell"
                                     mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="name-cell">
                        {{ row.firstName + ' ' + row.lastName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.email }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Phone' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <a [href]="'tel:' + row.phone">{{ row.phone }}</a>
                    </mat-cell>
                </ng-container>
            </mat-table>
        </module-content>
        <module-content>
            <div class="disclaimer">{{ 'Company_information_disclaimer' | translate }}</div>
        </module-content>
        <main-action (onAction)="onEditClicked()"
                     *ngIf="rights.EDIT"
                     [displayMode]="'fab'"
                     [icon]="'edit'"
                     [title]="'Edit'">
        </main-action>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
