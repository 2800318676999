import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { Utils } from '../../../_common/providers/utils';
import { NamePickerComponent } from '../../../_common/components/name-picker/name-picker';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { FilesImageViewerDialogComponent } from '../image-viewer-dialog/image-viewer-dialog';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../providers/files-provider';
import { File, FileFamily, FileType, User } from '../../../_common/providers/models';

import * as FileSaver from 'file-saver';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'files-files',
    styleUrls: ['./files.scss'],
    templateUrl: './files.html'
})
export class FilesFilesComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public static readonly EDITION_LEVEL_NONE: number = 1;
    public static readonly EDITION_LEVEL_OWN_FILES: number = 2;
    public static readonly EDITION_LEVEL_ALL_FILES: number = 3;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    @ViewChild('fileInput', {static: false}) public fileInput;
    @Input() public editionLevel: number = FilesFilesComponent.EDITION_LEVEL_NONE;
    @Input() public currentUser: User = new User();
    @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
    @Input() public fileGroupId: number = 0;
    @Input() public downloadApi: string = null;
    @Input() public maxFileSize: number = 30720; // 30 Mo en ko
    @Input() public isDisabled: boolean = false;
    @Input() public hideIconColumn: boolean = false;
    @Input() public hideTypeColumn: boolean = false;
    @Input() public hideChangedColumn: boolean = false;
    @Input() public hideSizeColumn: boolean = false;
    @Input() public isFoldersEnabled: boolean = true;
    @Input() public hideHeaders: boolean = false;
    @Input() public hideHeadersWhenEmpty: boolean = false;
    @Input() public hintLabel: string = null;
    @Output() public onItemRemoved: EventEmitter<any> = new EventEmitter();
    @Output() public onItemAdded: EventEmitter<any> = new EventEmitter();
    @Output() public onItemRenamed: EventEmitter<any> = new EventEmitter();
    public filesDataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public foldersStack: File[] = [];
    public columns: string[] = [];
    private _types: FileType[] = [];
    private _families: FileFamily[] = [];
    private _isReady: boolean = false;
    private _pendingData: any = null;

    constructor(
        public conf: Conf,
        public utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _filesProvider: FilesProvider
    ) {
    }

    private _files: File[] = [];

    public get files() {
        return this._files;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set files(value: File[]) {
        if (!this._isReady) {
            this._pendingData = value;
            return;
        }

        this.parseValue(value);
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initDatasource();
        this.loadFileTypes()
            .then(() => {
                this._isReady = true;
                if (this._pendingData) {
                    this.files = this._pendingData;
                    this._pendingData = null;
                }
            })
            .catch((error) => this.onError(error));

        const columns: string[] = [];
        if (!this.hideIconColumn) {
            columns.push('icon');
        }
        columns.push('name');
        if (!this.hideTypeColumn) {
            columns.push('type');
        }
        if (!this.hideChangedColumn) {
            columns.push('change');
        }
        if (!this.hideSizeColumn) {
            columns.push('size');
        }
        columns.push('actions');

        this.columns = columns;

        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onAddFileClicked(): void {
        if ([FilesFilesComponent.EDITION_LEVEL_ALL_FILES, FilesFilesComponent.EDITION_LEVEL_OWN_FILES].indexOf(this.editionLevel) >= 0) {
            this.fileInput.nativeElement.click();
        }
    }

    public onAddFolderClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(NamePickerComponent, {
            data: {
                name: '',
                title: 'New_folder',
                placeholder: 'Name',
                maxLength: 255
            }
        });
        dialog.afterClosed().subscribe((newName: string) => {
            if (newName) {
                const folder: File = new File();
                folder.fileGroupId = this.fileGroupId;
                folder.fileTypeId = FileType.ID_FOLDER;
                folder.parentFileId = this.foldersStack.length > 0 ? this.foldersStack[this.foldersStack.length - 1].id : null;
                folder.name = newName;
                folder.size = 0;
                this.onItemAdded.emit([folder]);
            }
        });
    }

    public onRenameClicked(row: FileRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(NamePickerComponent, {
            data: {
                name: row.file.name,
                title: 'Rename',
                placeholder: 'New_name',
                maxLength: 255
            }
        });
        dialog.afterClosed().subscribe((newName) => {
            if (newName) {
                row.file.name = newName;
                // this.filesDataSource.set(rowIndex, row);
                this.onItemRenamed.emit(row.file);
            }
        });
    }

    public onRemoveClicked(row: FileRow): void {
        const message: string = row.type.id === FileFamily.ID_FOLDER ? 'Confirm_delete_folder' : 'Confirm_delete_file';
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: this._translater.instant(message)
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                // this.removeFile(row.file);
                this.onItemRemoved.emit(row.file);
            }
        });

    }

    public onNewFileSelected(event: any): void {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles: File[] = [];
            for (let i = 0; i < event.target.files.length; i++) {
                newFiles.push(this.buildFile(event.target.files[i]));
            }
            this.onItemAdded.emit(newFiles);
        }
    }

    public onDownloadClicked(row: any): void {
        if (this.downloadApi) {
            row.isDownloadLoading = true;
            this._filesProvider.downloadFile(this.downloadApi, row.file)
                .then((file: any) => {
                    const type: FileType = this.utils.findIn(row.file.fileTypeId, this._types, 'id');
                    const fileName: string = row.file.name + '.' + type.extension;
                    FileSaver.saveAs(file, fileName);
                    row.isDownloadLoading = false;
                })
                .catch((error) => this.onError(error));
        }
    }

    public onRowClicked(row: FileRow): void {
        if (row.type.id === FileFamily.ID_IMAGE) {
            this.openImage(row.file);
        } else if (row.type.id === FileFamily.ID_FOLDER) {
            this.openFolder(row.file.id);
        } else {
            this.onDownloadClicked(row);
        }
    }

    public onSortChange(event: any): void {
        this.filesDataSource.sortBy(event.active, event.direction);
        this.filesDataSource.addSortDefinition(
            'icon',
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                a.type.name.toLowerCase(),
                b.type.name.toLowerCase(),
                true
            ),
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                b.type.name.toLowerCase(),
                a.type.name.toLowerCase(),
                false
            )
        );
        this.filesDataSource.addSortDefinition(
            'name',
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                a.file.name.toLowerCase(),
                b.file.name.toLowerCase(),
                true
            ),
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                b.file.name.toLowerCase(),
                a.file.name.toLowerCase(),
                false
            )
        );
        this.filesDataSource.addSortDefinition(
            'type',
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                a.type.name.toLowerCase(),
                b.type.name.toLowerCase(),
                true
            ),
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                b.type.name.toLowerCase(),
                a.type.name.toLowerCase(),
                false
            )
        );
        this.filesDataSource.addSortDefinition(
            'change',
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                a.file.updateDate,
                b.file.updateDate,
                true
            ),
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                b.file.updateDate,
                a.file.updateDate,
                false
            )
        );
        this.filesDataSource.addSortDefinition(
            'size',
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                a.file.size,
                b.file.size,
                true
            ),
            (a: FileRow, b: FileRow) => this.utils.sortRow(
                b.file.size,
                a.file.size,
                false
            )
        );
    }

    public onHomeFolderClicked(): void {
        this.openFolder(null);
    }

    public onBackFolderClicked(): void {
        if (this.foldersStack.length > 1) {
            this.openFolder(this.foldersStack[this.foldersStack.length - 1].parentFileId);
            return;
        }

        this.openFolder(null);
    }

    public onStackFolderClicked(folder: File): void {
        this.openFolder(folder.id);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initDatasource(): void {
        this.filesDataSource.sort = this.sorter;
    }

    private loadFileTypes(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(FileType).get(),
                this._storage.select(FileFamily).get()
            ])
                .then((data: any[]) => {
                    this._types = data[0];
                    this._families = data[1];
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private parseValue(value: File[]): void {
        if (value) {
            if (this.isFoldersEnabled) {
                this._files = value;
            } else {
                // si on d�sactive les folders, on filtre tous les dossiers ...
                this._files = [];
                for (let i = 0; i < value.length; i++) {
                    if (value[i].fileTypeId !== FileType.ID_FOLDER) {
                        this._files.push(value[i]);
                    }
                }
            }

            let folderId: number = null;
            let i = this.foldersStack.length - 1;
            while (i >= 0 && !folderId) {
                folderId = this.utils.findIn(this.foldersStack[i].id, this._files, 'id') ? this.foldersStack[i].id : null;
                if (!folderId) {
                    this.foldersStack.splice(i, 1);
                }
                i--;
            }

            this.openFolder(folderId);
        } else {
            this._files = [];
            this.filesDataSource.replaceAllWith([]);
        }
    }

    private openFolder(folderId: number): void {
        // TODO disabler le mode search (s'il �tait actif)
        const folderData: FileRow[] = [];
        let folderFiles: File[] = [];

        // si les folders sont d�sactiv�s, on affiche tous les fichiers peu importe leur dossier parent
        if (this.isFoldersEnabled) {
            if (folderId) {
                folderFiles = this.utils.findManyIn(folderId ? [folderId] : [null, undefined, NaN], this._files, 'parentFileId');
            } else {
                // des fois parentFileId vaut null, des fois undefined et des fois NaN
                // on peut donc pas utiliser utils.findManyIn dans ce
                for (let i = 0; i < this._files.length; i++) {
                    if (!this._files[i].parentFileId) {
                        folderFiles.push(this._files[i]);
                    }
                }
            }
        } else {
            folderFiles = this._files; // les folders ont d�j� �t� filtr�s dans "parseValue"
        }

        for (let i = 0; i < folderFiles.length; i++) {
            folderData.push(this.buildFileRow(folderFiles[i]));
        }

        this.filesDataSource.replaceAllWith(folderData);

        const newStack: File[] = [];
        let nextFolder: File = this.utils.findIn(folderId, this._files, 'id');
        while (nextFolder) {
            newStack.splice(0, 0, nextFolder);
            nextFolder = this.utils.findIn(nextFolder.parentFileId, this._files, 'id');
        }
        this.foldersStack = newStack;
    }

    private buildFileRow(file: File): FileRow {
        let type: FileType = this.utils.findIn(file.fileTypeId, this._types, 'id');
        if (!type) {
            type = this._types[0];
        }
        const family: FileFamily = this.utils.findIn(type.fileFamilyId, this._families, 'id');

        return {
            file,
            icon: this.findIcon(type),
            type: family,
            isDownloadable: family.id !== FileFamily.ID_FOLDER,
            isDownloadLoading: false,
            editionLevel: this.getFileEditionLevel(file)
        };
    }

    private findIcon(type: FileType): string {
        return this.conf.assetsUrl + 'files/families/' + type.fileFamilyId + '.png';
    }

    private getRowIndex(row: any): number {
        for (let i = 0; i < this.filesDataSource.count; i++) {
            if (this.filesDataSource.get(i).file.id === row.file.id) {
                return i;
            }
        }

        return this.filesDataSource.count;
    }

    private removeFile(row: any): void {
        const rowIndex: number = this.getRowIndex(row);
        this.filesDataSource.remove(rowIndex);
        this._files.splice(rowIndex, 1);
        this.onItemRemoved.emit(row.file);
    }

    private buildFile(rawFile: any): File {
        const file: File = new File();
        const fileId: any = this.utils.randomString(16);
        const fileName: string[] = this.parseRawFileName(rawFile.name);
        const fileType: FileType = this.parseRawType(rawFile.type, fileName[1]);
        const fileSize: number = Math.round(rawFile.size / 1024);
        const fileData: any = rawFile;

        if (fileSize > this.maxFileSize) {
            this._toaster.open(this._translater.instant(
                    'File_is_too_big_max_x',
                    {size: Math.round(this.maxFileSize / 1024), unit: 'Mo'}),
                '',
                {duration: 5000}
            );
            return;
        }

        file.id = fileId; // on assigne pas directement pour �viter un probl�me de typage entre string et number
        file.name = fileName[0];
        if (fileType.id === FileType.ID_OTHER) {
            file.name += '.' + fileName[1];
        }
        file.size = fileSize;
        file.fileTypeId = fileType.id;
        file.file = fileData;
        file.parentFileId = this.foldersStack.length > 0 ? this.foldersStack[this.foldersStack.length - 1].id : null;
        file.fileGroupId = this.fileGroupId;
        file.updateDate = this.utils.DateToYmdHis(new Date());
        file.createrId = this.currentUser.id;

        return file;
    }

    private parseRawFileName(fileName: string): string[] {
        const dotIndex: number = fileName.lastIndexOf('.');
        if (dotIndex >= 0) {
            return [fileName.substr(0, dotIndex), fileName.substr(dotIndex + 1)];
        }

        return [fileName, ''];
    }

    private parseRawType(mime: string, extension: string): FileType {
        for (let i = 1; i < this._types.length; i++) {
            if (this._types[i].mimeType === mime && this._types[i].extension === extension) {
                return this._types[i].clone();
            }
        }

        return this._types[0].clone(); // le premier de la liste c'est "other" ...
    }

    private getFileEditionLevel(file: File): number {
        // si le composant est en mode "ALL" ou "NONE", on override les droits sp�cifiques du fichier
        // sinon on indique que l'utilisateur peut le modifier s'il est l'owener, sinon on retourne none;
        if (this.editionLevel === FilesFilesComponent.EDITION_LEVEL_ALL_FILES) {
            return FilesFilesComponent.EDITION_LEVEL_ALL_FILES;
        }
        if (this.editionLevel === FilesFilesComponent.EDITION_LEVEL_NONE) {
            return FilesFilesComponent.EDITION_LEVEL_NONE;
        }

        return file.createrId === this.currentUser.id ? FilesFilesComponent.EDITION_LEVEL_OWN_FILES : FilesFilesComponent.EDITION_LEVEL_NONE;
    }

    private openImage(file: File): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(FilesImageViewerDialogComponent, {
            data: {
                file,
                type: this.utils.findIn(file.fileTypeId, this._types, 'id'),
                downloadApi: this.downloadApi
            }
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}

export interface FileRow {
    file: File;
    icon: string;
    type: FileFamily;
    isDownloadable: boolean;
    isDownloadLoading: boolean;
    editionLevel: number;
}
