import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'info-dialog',
    providers: [],
    styleUrls: ['./info-dialog.scss'],
    templateUrl: './info-dialog.html'
})
export class InfoDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public text: string = '';
    public texts: string[] = [];
    public title: string = 'Information';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.text = this._data.text;
        this.texts = this._data.texts;
        if ('title' in this._data) {
            this.title = this._data.title;
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
