import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatDialog } from '@angular/material/dialog';
import { Conf } from '../../../_conf';
import { User } from '../../../_common/providers/models';

@Component({
    selector: 'users-user-picker',
    providers: [],
    styleUrls: ['./user-picker.scss'],
    templateUrl: './user-picker.html'
})
export class UsersUserPickerComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public availableUsers: User[] = [];
    @Output() public pickedUserChange: EventEmitter<User> = new EventEmitter<User>();
    @Output() public userPicked: EventEmitter<User> = new EventEmitter<User>();
    public userFormControl: FormControl = new FormControl('', [
        Validators.required,
        // doc : https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html#building-a-custom-validator
        (control: any) => {
            return this.checkUser() ? null : {exists: {valid: false}};
        },
    ]);
    public filteredUsers: Observable<User[]> = null;

    constructor(
        public conf: Conf,
        private _utils: Utils,
        private _translater: TranslateService,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _pickedUser: User = null;

    @Input()
    public get pickedUser() {
        return this._pickedUser;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public set pickedUser(user) {
        this._pickedUser = user;
        this.pickedUserChange.emit(user);
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.filteredUsers = this.userFormControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value.firstName + ' ' + value.lastName),
            map((userName: string) => userName ? this.filterUsers(userName) : this.availableUsers.slice())
        );
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public getUserName(user: User): string {
        return user ? user.firstName + ' ' + user.lastName : '';
    }

    public onUserSelected(event: any): void {
        this.userFormControl.setValue(event.option.value);
        this.userPicked.emit(event.option.value);
        this.pickedUser = event.option.value;
    }

    public onSearchChanged(): void {
        this.pickedUser = null;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private checkUser(): boolean {
        if (!this.userFormControl || typeof this.userFormControl.value !== 'object') {
            return false;
        }

        return this._utils.findIn(this.userFormControl.value.id, this.availableUsers, 'id') !== null;
    }

    private filterUsers(name: string): User[] {
        return this.availableUsers.filter((user: User) => {
            return this.getUserName(user).toLowerCase().includes(name.toLowerCase());
        });
    }
}
