import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { CurrentUser } from '../providers/current-user';

@Injectable()
export class IsLoggedInGuard implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _currentUser: CurrentUser,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._currentUser.isLoggedIn()) {
                resolve(true);
            } else {
                this._currentUser.tryLoginFromRemember()
                    .then((isLoggedIn) => {
                        if (!isLoggedIn) {
                            this.redirect(route);
                        }
                        resolve(isLoggedIn);
                    })
                    .catch((error) => {
                        this.redirect(route);
                        resolve(false);
                    });
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private redirect(route: ActivatedRouteSnapshot): void {
        const urlSegments: string[] = [];
        route.url.forEach((segment: UrlSegment) => urlSegments.push(segment.path));
        this._router.navigate([], {
            queryParams: {
                promptLogin: true,
                nextUrl: urlSegments.join('/')
            }
        });
    }

}
