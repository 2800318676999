import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../../_common/providers/utils';
import { LikeCountersProvider } from '../../providers/like-counters-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { LikeCounter_User, User } from '../../../_common/providers/models';

@Component({
    selector: 'like-counters-like-counter',
    providers: [],
    styleUrls: ['./like-counter.scss'],
    templateUrl: './like-counter.html'
})
export class LikeCountersLikeCounterComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public MAX_DISPLAYED_LIKERS: number = 10;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public isUserLiking: boolean = false;
    public likeCounter_users: LikeCounter_User[] = [];
    public likers: User[] = [];
    public likersNames: string = '';

    @Input() public disabled: boolean = false;
    @Input() public likeAPI: string = null;
    @Output() public likeCounterIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() public userChange: EventEmitter<User> = new EventEmitter<User>();
    @Output() public onLikeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _likeCounters: LikeCountersProvider,
        private _storage: ModelsStorage
    ) {
    }

    private _likeCounterId: number = 0;

    public get likeCounterId() {
        return this._likeCounterId;
    }

    @Input()
    public set likeCounterId(id: number) {
        this.isLoading = true;
        this.loadData(id)
            .then(() => {
                this.updateIsUserLiking();
                this.isLoading = false;
            })
            .catch((error: any) => this.onError(error));
    }

    private _user: User = null;

    public get user() {
        return this._user;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set user(user: User) {
        this._user = user;
        this.updateIsUserLiking();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onCounterClicked(event: any): void {
        this.onLikeClicked(event);
    }

    public onLikeClicked(event: any): void {
        event.stopPropagation();
        if (!this.disabled && this.likeAPI && this.user) {
            this.isLoading = true;
            this.like(!this.isUserLiking)
                .then(() => {
                    this.updateIsUserLiking();
                    this.updateLikersNames();
                    this.isLoading = false;
                    this.onLikeChanged.emit(this.isUserLiking);
                })
                .catch((error: any) => this.onError(error));
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(likeCounterId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(LikeCounter_User)
                .where('likeCounterId').equals(likeCounterId)
                .get()
                .then((likeCounter_users: LikeCounter_User[]) => {
                    this._storage.fromIds(User, this._utils.getKeyValues(likeCounter_users, 'userId'))
                        .then((users: User[]) => {
                            this._likeCounterId = likeCounterId;
                            this.likeCounter_users = likeCounter_users;
                            this.likers = users;
                            this.updateLikersNames();
                            resolve();
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateIsUserLiking(): void {
        if (!this._user) {
            this.isUserLiking = false;
            return;
        }

        this.isUserLiking = this._utils.findIn(this._user.id, this.likeCounter_users, 'userId') ? true : false;
    }

    private updateLikersNames(): void {
        const likersNames: string[] = [];
        for (let i = 0; i < this.likers.length && i < this.MAX_DISPLAYED_LIKERS; i++) {
            likersNames.push(this.likers[i].firstName + ' ' + this.likers[i].lastName);
        }

        if (this.likers.length > this.MAX_DISPLAYED_LIKERS) {
            likersNames.splice(this.MAX_DISPLAYED_LIKERS - 1, 1);
            likersNames.push(this._translater.instant('And_x_more_people', {count: this.likers.length - this.MAX_DISPLAYED_LIKERS + 1}));
        }

        // this.likersNames = likersNames.join(',\n'); // pour l'instant on affiche pas les noms de ceux qui aiment, mais ca pourrait revenir ...
        if (likersNames.length === 1) {
            this.likersNames = this._translater.instant('One_people_like_this');
        } else if (likersNames.length > 1) {
            this.likersNames = this._translater.instant('N_people_like_this', {count: this.likers.length});
        } else {
            this.likersNames = '';
        }
    }

    private like(like: boolean): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._likeCounters.updateLike(this.likeAPI, like)
                .then((likeCounter_user: LikeCounter_User) => {
                    if (like) {
                        this.likeCounter_users.push(likeCounter_user);
                        this.likers.push(this.user);
                        resolve();
                    } else {
                        for (let i = 0; i < this.likeCounter_users.length; i++) {
                            if (this.likeCounter_users[i].userId === likeCounter_user.userId) {
                                this.likeCounter_users.splice(i, 1);
                                i = this.likeCounter_users.length;
                            }
                        }
                        for (let i = 0; i < this.likers.length; i++) {
                            if (this.likers[i].id === likeCounter_user.userId) {
                                this.likers.splice(i, 1);
                                i = this.likers.length;
                            }
                        }

                        resolve();
                    }
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        this.isLoading = false;
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
