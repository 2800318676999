<div [style.bottom.px]="bottom"
     [style.right.px]="right"
     class="container">
    <div *ngIf="displayMode == 'fab'"
         class="fab-action">
        <button (click)="onClicked()"
                [color]="color"
                [disabled]="isLoading || disabled"
                [matTooltip]="title | translate"
                mat-fab
                matTooltipPosition="above">
            <mat-icon>{{ icon }}</mat-icon>
        </button>
        <mat-spinner *ngIf="isLoading"
                     [color]="'accent'"
                     [diameter]="72"
                     [mode]="'indeterminate'">
        </mat-spinner>
    </div>
    <div *ngIf="displayMode == 'button'"
         class="button-action">
        <button (click)="onClicked()"
                [color]="color"
                [disabled]="isLoading || disabled"
                mat-raised-button>
            <mat-icon *ngIf="icon && !isLoading">
                {{ icon }}
            </mat-icon>
            <mat-spinner *ngIf="isLoading"
                         [color]="'accent'"
                         [diameter]="24"
                         [mode]="'indeterminate'">
            </mat-spinner>
            <span>{{ title | translate }}</span>
        </button>
    </div>
</div>
