import { Pipe, PipeTransform } from '@angular/core';
import { AutoLinker } from '../../app.module';

@Pipe({name: 'autoLinker'})
export class AutoLinkerPipe implements PipeTransform {
    constructor() {
    }

    public transform(str: string) {
        return AutoLinker.link(str);
    }
}
