import { Injectable } from '@angular/core';
import { Utils } from './utils';
import { Conf } from '../../_conf';
import { CurrentUser } from './current-user';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LangsProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _currentUser: CurrentUser,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getBestLangCode(): string {
        if (this._currentUser.isLoggedIn()) {
            for (let i = 0; i < this._conf.availableLangs.length; i++) {
                if (this._conf.availableLangs[i].id === this._currentUser.user.langId) {
                    return this._conf.availableLangs[i].code;
                }
            }
        }

        const defaultLang = this._conf.defaultLang.code;
        const browserLang = this._translater.getBrowserLang();
        const langRegex = new RegExp(this.getLangCodes().join('|'));
        return browserLang.match(langRegex) ? browserLang : defaultLang;
    }

    public getBestLangId(): number {
        if (this._currentUser.isLoggedIn()) {
            return this._currentUser.user.langId;
        }

        return this._utils.findIn(this.getBestLangCode(), this._conf.availableLangs, 'code').id;
    }

    public getLangs(): any[] {
        const langs: any[] = [];
        for (let i = 0; i < this._conf.availableLangs.length; i++) {
            langs.push(this._conf.availableLangs[i]);
        }

        return langs;
    }

    public getLangCodes(): string[] {
        const langs: string[] = [];
        for (let i = 0; i < this._conf.availableLangs.length; i++) {
            langs.push(this._conf.availableLangs[i].code);
        }

        return langs;
    }

    public getLangIds(): number[] {
        const langs: number[] = [];
        for (let i = 0; i < this._conf.availableLangs.length; i++) {
            langs.push(this._conf.availableLangs[i].id);
        }

        return langs;
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
