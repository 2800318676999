<div *ngIf="isFoldersEnabled"
     class="nav-bar">
    <div class="stack">
        <button (click)="onHomeFolderClicked()"
                [matTooltip]="'Files' | translate"
                mat-icon-button>
            <mat-icon>home</mat-icon>
        </button>
        <ng-container *ngFor="let folder of foldersStack">
            <mat-icon class="separator">arrow_right</mat-icon>
            <button (click)="onStackFolderClicked(folder)"
                    mat-button>
                {{ folder.name }}
            </button>
        </ng-container>
    </div>
    <!--
    <button mat-icon-button
                [matTooltip]="'Search' | translate">
        <mat-icon>search</mat-icon>
    </button>
        -->
</div>
<div class="search-bar"></div>
<mat-table (matSortChange)="onSortChange($event)"
           *ngIf="columns.length > 0 && (!hideHeadersWhenEmpty || (hideHeadersWhenEmpty && filesDataSource.count > 0))"
           [dataSource]="filesDataSource"
           matSort>
    <mat-header-row *matHeaderRowDef="columns" [class.hidden]="hideHeaders"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;"></mat-row>
    <ng-container *ngIf="!hideIconColumn" matColumnDef="icon">
        <mat-header-cell *matHeaderCellDef class="icon-cell">
            <button (click)="onBackFolderClicked()"
                    *ngIf="foldersStack.length > 0"
                    [matTooltip]="'Files' | translate"
                    mat-icon-button>
                <mat-icon>arrow_back</mat-icon>
            </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="icon-cell">
            <img [matTooltip]="row.type.name"
                 [src]="row.icon"/>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="name-cell" mat-sort-header>
            <span class="button-padding">{{ 'Name' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="name-cell">
            <button (click)="onRowClicked(row)"
                    color="primary"
                    mat-button>
                {{ row.file.name }}
            </button>
        </mat-cell>
    </ng-container>
    <ng-container *ngIf="!hideTypeColumn" matColumnDef="type">
        <mat-header-cell *matHeaderCellDef class="type-cell" mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="type-cell legend">
            {{ row.type.name }}
        </mat-cell>
    </ng-container>
    <ng-container *ngIf="!hideChangedColumn" matColumnDef="change">
        <mat-header-cell *matHeaderCellDef class="change-cell"
                         mat-sort-header>{{ 'Changed' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="change-cell legend">
            {{ row.file.updateDate | dateClosest }}
        </mat-cell>
    </ng-container>
    <ng-container *ngIf="!hideSizeColumn" matColumnDef="size">
        <mat-header-cell *matHeaderCellDef class="size-cell" mat-sort-header>{{ 'Size' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row"
                  class="size-cell legend">
            <ng-container *ngIf="row.file.fileTypeId != 34">
                {{ row.file.size | fileSize:'ko' }}
            </ng-container>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef
                         class="actions-cell"></mat-header-cell>
        <mat-cell *matCellDef="let row"
                  class="actions-cell">
            <ng-container *ngIf="row.editionLevel == 1 && row.isDownloadable">
                <button (click)="onDownloadClicked(row)"
                        *ngIf="!row.isDownloadLoading"
                        [disabled]="isDisabled"
                        [matTooltipPosition]="'left'"
                        mat-icon-button
                        matTooltip="{{ 'Download' | translate }}">
                    <mat-icon>file_download</mat-icon>
                </button>
                <mat-spinner *ngIf="row.isDownloadLoading"
                             [diameter]="24"
                             class="download-spinner"></mat-spinner>
            </ng-container>
            <button *ngIf="row.editionLevel == 2 || row.editionLevel == 3"
                    [disabled]="isDisabled"
                    [matMenuTriggerFor]="moreMenu"
                    mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu"
                      xPosition="before">
                <button (click)="onDownloadClicked(row)"
                        *ngIf="row.isDownloadable"
                        mat-menu-item>
                    {{ 'Download' | translate }}
                </button>
                <button (click)="onRenameClicked(row)"
                        mat-menu-item>
                    {{ 'Rename' | translate }}
                </button>
                <button (click)="onRemoveClicked(row)"
                        mat-menu-item>
                    {{ 'Remove' | translate }}
                </button>
            </mat-menu>
        </mat-cell>
    </ng-container>
</mat-table>
<div (click)="onAddFileClicked()"
     *ngIf="filesDataSource.count == 0"
     [class.clickable]="editionLevel == 2 || editionLevel == 3"
     class="empty-legend">
    <span *ngIf="isFoldersEnabled">{{ 'This_folder_is_empty' | translate }}</span>
    <span *ngIf="!isFoldersEnabled">
        {{ 'There_is_no_file' | translate }}
    </span>
</div>
<div *ngIf="hintLabel && (editionLevel == 2 || editionLevel == 3)"
     class="hint-label">
    {{ hintLabel }}
</div>
<div *ngIf="editionLevel == 2 || editionLevel == 3"
     class="add-button-container">
    <button (click)="onAddFileClicked()"
            *ngIf="!isFoldersEnabled"
            [disabled]="isDisabled"
            mat-mini-fab
            matTooltip="{{ 'Add_file' | translate }}">
        <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="isFoldersEnabled"
            [disabled]="isDisabled"
            [matMenuTriggerFor]="addMenu"
            mat-mini-fab
            matTooltip="{{ 'Add_file_or_folder' | translate }}">
        <mat-icon>add</mat-icon>
    </button>
    <mat-menu #addMenu="matMenu"
              xPosition="before"
              yPosition="above">
        <button (click)="onAddFolderClicked()"
                mat-menu-item>
            {{ 'Add_folder' | translate }}
        </button>
        <button (click)="onAddFileClicked()"
                mat-menu-item>
            {{ 'Add_files' | translate }}
        </button>
    </mat-menu>
</div>
<input #fileInput
       (change)="onNewFileSelected($event)"
       class="file-input"
       multiple="multiple"
       type="file"/>
