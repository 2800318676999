import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ImageData } from '../../../_common/components/input-image/input-image';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectTeamMember, ProjectValidationState, TeamMemberType } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-team',
    providers: [],
    styleUrls: ['./edit-project-team.scss'],
    templateUrl: './edit-project-team.html'
})
export class ProjectsEditProjectTeamComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 250;
    public readonly FUNCTION_MAX_LENGTH: number = 250;
    public readonly EMAIL_MAX_LENGTH: number = 250;
    public readonly WEBSITE_MAX_LENGTH: number = 250;
    public readonly PHONE_MAX_LENGTH: number = 15;
    public readonly ADDRESS_MAX_LENGTH: number = 1000;
    public readonly IMAGE_MAX_SIZE: number = 2; // Mo
    public readonly IMAGE_MAX_HEIGHT: number = 5000;
    public readonly IMAGE_MAX_WIDTH: number = 5000;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showMore: boolean = false;

    public teamMemberFormControls: TeamMemberFormControl[] = [];
    public boardMemberFormControls: TeamMemberFormControl[] = [];
    public directorsMemberFormControls: TeamMemberFormControl[] = [];
    public teamImagePreview: any = null;
    public teamImageData: ImageData = {current: null, new: null};
    public contactFirstNameFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public contactLastNameFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public contactEmailFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.EMAIL_MAX_LENGTH),
        Validators.email,
        Validators.pattern(this._utils.EMAIL_REGEX),
    ]);
    public contactWebsiteFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.WEBSITE_MAX_LENGTH),
        Validators.pattern(this._utils.URL_REGEX)
    ]);
    public contactPhoneFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.PHONE_MAX_LENGTH),
        Validators.pattern(this._utils.INTERNATIONAL_PHONE_NUMBER_REGEX)
    ]);
    public contactAddressFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.ADDRESS_MAX_LENGTH)
    ]);
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() public teamMembersChange: EventEmitter<ProjectTeamMember[]> = new EventEmitter<ProjectTeamMember[]>();
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    private _isTeamMembersEdited: boolean = false;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    @Input()
    public set project(project: Project) {
        this._project = project;

        // si c'est la première fois qu'on affiche le formulaire,
        // on prérempli les infos de contact avec celles du user connecté
        if (this.project.updateDate === this.project.createDate) {
            this.contactFirstNameFormControl.setValue(this.currentUser.user.firstName);
            this.contactLastNameFormControl.setValue(this.currentUser.user.lastName);
            this.contactEmailFormControl.setValue(this.currentUser.user.email);
            this.fireChangeProject();
        } else {
            this.contactFirstNameFormControl.setValue(project.contactFirstName ? project.contactFirstName : '');
            this.contactLastNameFormControl.setValue(project.contactLastName ? project.contactLastName : '');
            this.contactEmailFormControl.setValue(project.contactEmail ? project.contactEmail : '');
        }
        this.contactWebsiteFormControl.setValue(project.contactWebsite ? project.contactWebsite : '');
        this.contactPhoneFormControl.setValue(project.contactPhone ? project.contactPhone : '');
        this.contactAddressFormControl.setValue(project.contactAddress ? project.contactAddress : '');
        this.teamImageData.current = project.teamPicture;
        this._filesProvider.initPreviewImage((data: any) => this.teamImagePreview = data, this.teamImageData, 'projects/' + this.project.id + '/download-team-picture');
        this.updateRights();

        this.showMore = !(project && ([ProjectValidationState.ID_DRAFT, ProjectValidationState.ID_PENDING_VALIDATION].indexOf(project.projectValidationStateId) >= 0));
    }

    private _teamMembers: ProjectTeamMember[] = [];

    public get teamMembers() {
        return this._teamMembers;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set teamMembers(teamMembers: ProjectTeamMember[]) {
        // on split les team member et des board members à la saisie pour des questions d'ux
        // et on les regroupe à la volée en retour parce que c'est plus facile à gérer coté backend
        if (teamMembers) {
            this._teamMembers = teamMembers;
            if (!this._isTeamMembersEdited) { // si on vérifie pas ce flag, on reset le formulaire à chaque fois qu'on tappe dedans
                this.buildTeamMembersFormControls();
                this.updateRights();
            }
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        for (let i = 0; i < this.teamMemberFormControls.length; i++) {
            if (this.teamMemberFormControls[i].firstNameFormControl.invalid
                || this.teamMemberFormControls[i].lastNameFormControl.invalid
                || this.teamMemberFormControls[i].functionFormControl.invalid) {
                return false;
            }
        }
        for (let i = 0; i < this.boardMemberFormControls.length; i++) {
            if (this.boardMemberFormControls[i].firstNameFormControl.invalid
                || this.boardMemberFormControls[i].lastNameFormControl.invalid
                || this.boardMemberFormControls[i].functionFormControl.invalid) {
                return false;
            }
        }
        for (let i = 0; i < this.directorsMemberFormControls.length; i++) {
            if (this.directorsMemberFormControls[i].firstNameFormControl.invalid
                || this.directorsMemberFormControls[i].lastNameFormControl.invalid
                || this.directorsMemberFormControls[i].functionFormControl.invalid) {
                return false;
            }
        }

        return !this.contactFirstNameFormControl.invalid
            && !this.contactLastNameFormControl.invalid
            && !this.contactEmailFormControl.invalid
            && !this.contactWebsiteFormControl.invalid
            && !this.contactPhoneFormControl.invalid
            && !this.contactAddressFormControl.invalid;
    }

    public onAddTeamMemberClicked(): void {
        this.addMemberFormControl(this.teamMemberFormControls);
    }

    public onAddBoardMemberClicked(): void {
        this.addMemberFormControl(this.boardMemberFormControls);
    }

    public onAddDirectorsMemberClicked(): void {
        this.addMemberFormControl(this.directorsMemberFormControls);
    }

    public onRemoveTeamMemberClicked(index: number): void {
        this.teamMemberFormControls.splice(index, 1);
        this.onTeamMembersFormChanged();
    }

    public onRemoveBoardMemberClicked(index: number): void {
        this.boardMemberFormControls.splice(index, 1);
        this.onTeamMembersFormChanged();
    }

    public onRemoveDirectorsMemberClicked(index: number): void {
        this.directorsMemberFormControls.splice(index, 1);
        this.onTeamMembersFormChanged();
    }

    public onTeamNameFieldChanged(index: number): void {
        this.teamMemberFormControls[index].firstNameFormControl.updateValueAndValidity();
        this.teamMemberFormControls[index].lastNameFormControl.updateValueAndValidity();
        this.onTeamMembersFormChanged();
    }

    public onBoardNameFieldChanged(index: number): void {
        this.boardMemberFormControls[index].firstNameFormControl.updateValueAndValidity();
        this.boardMemberFormControls[index].lastNameFormControl.updateValueAndValidity();
        this.onTeamMembersFormChanged();
    }

    public onDirectorsNameFieldChanged(index: number): void {
        this.directorsMemberFormControls[index].firstNameFormControl.updateValueAndValidity();
        this.directorsMemberFormControls[index].lastNameFormControl.updateValueAndValidity();
        this.onTeamMembersFormChanged();
    }

    public onTeamMembersFormChanged(): void {
        this._isTeamMembersEdited = true;
        this.fireChangeTeamMembers();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onProjectFormChanged(): void {
        this.fireChangeProject();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onToggleShowMore(): void {
        this.showMore = !this.showMore;
        if (!this.showMore) {
            setTimeout(() => {
                document.getElementsByTagName('html')[0].scrollTo({
                    left: 0,
                    top: 96 + (4 * 72),
                    behavior: 'smooth'
                });
            }, 10);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.teamMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.enable();
                control.lastNameFormControl.enable();
                control.functionFormControl.enable();
            });
            this.boardMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.enable();
                control.lastNameFormControl.enable();
            });
            this.directorsMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.enable();
                control.lastNameFormControl.enable();
            });
            this.contactAddressFormControl.enable();
            this.contactEmailFormControl.enable();
            this.contactFirstNameFormControl.enable();
            this.contactLastNameFormControl.enable();
            this.contactPhoneFormControl.enable();
            this.contactWebsiteFormControl.enable();
        } else {
            this.teamMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.disable();
                control.lastNameFormControl.disable();
                control.functionFormControl.disable();
            });
            this.boardMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.disable();
                control.lastNameFormControl.disable();
            });
            this.directorsMemberFormControls.forEach((control: TeamMemberFormControl) => {
                control.firstNameFormControl.disable();
                control.lastNameFormControl.disable();
            });
            this.contactAddressFormControl.disable();
            this.contactEmailFormControl.disable();
            this.contactFirstNameFormControl.disable();
            this.contactLastNameFormControl.disable();
            this.contactPhoneFormControl.disable();
            this.contactWebsiteFormControl.disable();
        }
    }

    private addMemberFormControl(formControls: TeamMemberFormControl[]): void {
        const control: TeamMemberFormControl = {
            teamMemberId: 0,
            firstNameFormControl: new FormControl(''),
            lastNameFormControl: new FormControl(''),
            functionFormControl: new FormControl('', [
                Validators.maxLength(this.FUNCTION_MAX_LENGTH)
            ])
        };
        control.firstNameFormControl.setValidators([
            Validators.maxLength(this.NAME_MAX_LENGTH),
            (itemControl: any) => this.checkNameControl(control.firstNameFormControl, control.lastNameFormControl) ? null : {required: {valid: false}}
        ]);
        control.lastNameFormControl.setValidators([
            Validators.maxLength(this.NAME_MAX_LENGTH),
            (itemControl: any) => this.checkNameControl(control.lastNameFormControl, control.firstNameFormControl) ? null : {required: {valid: false}}
        ]);
        formControls.push(control);
    }

    private buildTeamMembersFormControls(): void {
        const teamControls: TeamMemberFormControl[] = [];
        const boardControls: TeamMemberFormControl[] = [];
        const directorsControls: TeamMemberFormControl[] = [];
        for (let i = 0; i < this._teamMembers.length; i++) {
            const control: TeamMemberFormControl = {
                teamMemberId: this._teamMembers[i].id,
                firstNameFormControl: new FormControl('', [
                    Validators.maxLength(this.NAME_MAX_LENGTH),
                    (itemControl: any) => itemControl &&
                    this.checkNameControl(itemControl.firstNameFormControl, itemControl.lastNameFormControl) ? null
                        : {required: {valid: false}}
                ]),
                lastNameFormControl: new FormControl('', [
                    Validators.maxLength(this.NAME_MAX_LENGTH),
                    (itemControl: any) => itemControl &&
                    this.checkNameControl(itemControl.lastNameFormControl, itemControl.firstNameFormControl) ? null
                        : {required: {valid: false}}
                ]),
                functionFormControl: new FormControl('', [
                    Validators.maxLength(this.FUNCTION_MAX_LENGTH)
                ])
            };
            control.firstNameFormControl.setValue(this._teamMembers[i].firstName);
            control.lastNameFormControl.setValue(this._teamMembers[i].lastName);
            control.functionFormControl.setValue(this._teamMembers[i].teamFunction);

            if (this._teamMembers[i].teamMemberTypeId === TeamMemberType.ID_MEMBER) {
                teamControls.push(control);
            } else if (this._teamMembers[i].teamMemberTypeId === TeamMemberType.ID_ADVISORY_BOARD) {
                boardControls.push(control);
            } else if (this._teamMembers[i].teamMemberTypeId === TeamMemberType.ID_BOARD_OF_DIRECTORS) {
                directorsControls.push(control);
            }
        }

        this.teamMemberFormControls = teamControls;
        this.boardMemberFormControls = boardControls;
        this.directorsMemberFormControls = directorsControls;

        if (this.teamMemberFormControls.length === 0) {
            this.onAddTeamMemberClicked();
        }
        if (this.boardMemberFormControls.length === 0) {
            this.onAddBoardMemberClicked();
        }
        if (this.directorsMemberFormControls.length === 0) {
            this.onAddDirectorsMemberClicked();
        }
    }

    private fireChangeTeamMembers(): void {
        const teamMembers: ProjectTeamMember[] = [];
        for (let i = 0; i < this.teamMemberFormControls.length; i++) {
            const teamMember: ProjectTeamMember = this.buildTeamMember(this.teamMemberFormControls[i]);
            if (teamMember) {
                teamMember.teamMemberTypeId = TeamMemberType.ID_MEMBER;
                teamMembers.push(teamMember);
            }
        }
        for (let i = 0; i < this.boardMemberFormControls.length; i++) {
            const teamMember: ProjectTeamMember = this.buildTeamMember(this.boardMemberFormControls[i]);
            if (teamMember) {
                teamMember.teamMemberTypeId = TeamMemberType.ID_ADVISORY_BOARD;
                teamMembers.push(teamMember);
            }
        }
        for (let i = 0; i < this.directorsMemberFormControls.length; i++) {
            const teamMember: ProjectTeamMember = this.buildTeamMember(this.directorsMemberFormControls[i]);
            if (teamMember) {
                teamMember.teamMemberTypeId = TeamMemberType.ID_BOARD_OF_DIRECTORS;
                teamMembers.push(teamMember);
            }
        }

        this._teamMembers = teamMembers;
        this.teamMembersChange.emit(teamMembers);
    }

    private buildTeamMember(control: TeamMemberFormControl): ProjectTeamMember {
        const firstName: string = control.firstNameFormControl.value ? control.firstNameFormControl.value.trim() : '';
        const lastName: string = control.lastNameFormControl.value ? control.lastNameFormControl.value.trim() : '';
        const functionName: string = control.functionFormControl.value ? control.functionFormControl.value.trim() : '';
        if (firstName.length && lastName) {
            const teamMember: ProjectTeamMember = new ProjectTeamMember();
            teamMember.id = control.teamMemberId;
            teamMember.projectId = this._project.id;
            teamMember.firstName = firstName;
            teamMember.lastName = lastName;
            teamMember.teamFunction = functionName;
            return teamMember;
        }

        return null;
    }

    private fireChangeProject(): void {
        this._project.teamPicture = this.teamImageData.new ? this.teamImageData.new : this.teamImageData.current;
        this._project.contactFirstName = this.contactFirstNameFormControl.value;
        this._project.contactLastName = this.contactLastNameFormControl.value;
        this._project.contactEmail = this.contactEmailFormControl.value;
        this._project.contactWebsite = this.contactWebsiteFormControl.value;
        this._project.contactPhone = this.contactPhoneFormControl.value;
        this._project.contactAddress = this.contactAddressFormControl.value;

        this.projectChange.emit(this._project);
    }

    private checkNameControl(testedControl: FormControl, relatedControl: FormControl): boolean {
        // si le related control est null, c'est ok
        // si le related control contient quelque chose, le tested doit aussi contenir quelque chose
        const relatedValue: string = relatedControl?.value ? relatedControl?.value.trim() : '';
        if (relatedValue.length) {
            const testedValue: string = testedControl?.value ? testedControl?.value.trim() : '';

            return testedValue.length > 0;
        }

        return true;
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}

export interface TeamMemberFormControl {
    teamMemberId: number;
    firstNameFormControl: FormControl;
    lastNameFormControl: FormControl;
    functionFormControl?: FormControl;
}
