import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { UsersProvider } from '../../providers/users-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { LangsProvider } from '../../../_common/providers/langs-provider';
import { SimpleDataSource } from '../../../_common/providers/simple-data-source';
import { User } from '../../../_common/providers/models';

@Component({
    selector: 'users-pick-users-dialog',
    providers: [],
    styleUrls: ['./pick-users-dialog.scss'],
    templateUrl: './pick-users-dialog.html'
})
export class UsersPickUsersDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public availableUsersDataSource: SimpleDataSource = new SimpleDataSource([]);

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public dialog: MatDialogRef<UsersPickUsersDialogComponent>,
        public utils: Utils,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _storage: ModelsStorage,
        private _users: UsersProvider,
        private _langs: LangsProvider,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data.availableUsers) {
            this.loadDataSource(_data.availableUsers);
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onAddClicked(): void {
        this.dialog.close(this.getSelectedUsers());
    }

    public getSelectedUsers(): User[] {
        const items: User[] = [];
        for (let i = 0; i < this.availableUsersDataSource.data.value.length; i++) {
            if (this.availableUsersDataSource.data.value[i].selected) {
                items.push(this.availableUsersDataSource.data.value[i].user);
            }
        }

        return items;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadDataSource(availableUsers: User[]): void {
        const items: any[] = [];
        for (let i = 0; i < availableUsers.length; i++) {
            items.push({
                user: availableUsers[i],
                selected: false
            });
        }

        this.availableUsersDataSource = new SimpleDataSource(items);
    }
}
