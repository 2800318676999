import { AfterViewInit, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from '../../../_common/providers/utils';
import { Tag } from '../../../_common/providers/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'tags-tag-picker',
    providers: [],
    styleUrls: ['./tag-picker.scss'],
    templateUrl: './tag-picker.html'
})
export class TagsTagPickerComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public submitText: string = 'Add';

    public tagNameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    public tag: Tag = new Tag();

    public availableColors: any[] = [
        {color: '#f34336', textColor: '#fff'},
        {color: '#e81e63', textColor: '#fff'},
        {color: '#9c27b0', textColor: '#fff'},
        {color: '#673ab7', textColor: '#fff'},
        {color: '#3f51b5', textColor: '#fff'},
        {color: '#2196f2', textColor: '#fff'},
        {color: '#03a9f3', textColor: '#fff'},
        {color: '#00bcd3', textColor: '#fff'},
        {color: '#009688', textColor: '#fff'},
        {color: '#4caf50', textColor: '#fff'},
        {color: '#8bc24a', textColor: '#000'},
        {color: '#ccdb39', textColor: '#000'},
        {color: '#feea3b', textColor: '#000'},
        {color: '#fec007', textColor: '#000'},
        {color: '#fe9800', textColor: '#000'},
        {color: '#fe5722', textColor: '#fff'},
        {color: '#795548', textColor: '#fff'},
        {color: '#9e9e9e', textColor: '#fff'},
        {color: '#607d8b', textColor: '#fff'},
        {color: '#212121', textColor: '#fff'}
    ];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<TagsTagPickerComponent>,
        private _utils: Utils,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data && _data.tag) {
            this.tag = _data.tag;
            this.tagNameFormControl.setValue(this.tag.name);
        } else {
            this.selectColor(0);
        }

        if (_data && _data.submitText) {
            this.submitText = _data.submitText;
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onColorClicked(index: number): void {
        this.selectColor(index);
    }

    public onAddClicked(): void {
        this.add();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private selectColor(index: number): void {
        this.tag.colorHex = this.availableColors[index].color;
        this.tag.textColorHex = this.availableColors[index].textColor;
    }

    private add(): void {
        if (!this.tag.id) {
            const id: any = this._utils.randomString(16, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
            this.tag.id = id;
        }

        this.tag.name = this.tagNameFormControl.value;
        this.dialog.close(this.tag);
    }
}
