<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="project?.name"
            header>
    </header>
    <error-401 *ngIf="is401"></error-401>
    <div *ngIf="!is401"
         class="wrapper">
        <div class="container">
            <mat-spinner *ngIf="isLoading"
                         [diameter]="64">
            </mat-spinner>
            <mat-icon *ngIf="!isLoading"
                      color="primary">
                {{ isError ? 'close' : 'check' }}
            </mat-icon>
            <div class="texts">
                <ng-container *ngIf="!isError">
                    <div
                        class="legend">{{ (isLoading ? 'Sending_notification_to' : 'Notification_sent_to') | translate }}</div>
                    <div class="title">{{ user?.firstName + ' ' + user?.lastName }}</div>
                </ng-container>
                <div *ngIf="isError"
                     class="legend">
                    {{ 'Error_unknown' | translate }}
                </div>
            </div>
        </div>
        <button *ngIf="!isLoading"
                [routerLink]="'/academic-ventures/pending-validation'"
                color="accent"
                mat-raised-button>
            {{ 'Projects_pending_validation' | translate }}
        </button>
    </div>
    <footer footer></footer>
</page-content>
