<ng-container *ngIf="activePrivateDiscussion">
    <mat-card [ngClass]="{ 'maximized': !activePrivateDiscussion.isMinimized, 'minimized': activePrivateDiscussion.isMinimized }"
              class="window">
        <div *ngIf="!activePrivateDiscussion.isReady"
             class="loader">
            <mat-spinner diameter="40"></mat-spinner>
        </div>
        <ng-container *ngIf="activePrivateDiscussion.isReady">
            <div (click)="onToggleClicked()"
                 class="header background-color-primary text-primary-color">
                <ng-container *ngIf="activePrivateDiscussion.data.privateDiscussion.isGroup">
                    <img *ngIf="activePrivateDiscussion.data.privateDiscussion.image"
                         [src]="conf.storageRootUrl + activePrivateDiscussion.data.privateDiscussion.image"
                         class="icon"/>
                    <mat-icon *ngIf="!activePrivateDiscussion.data.privateDiscussion.imag"
                              class="icon">supervised_user_circle
                    </mat-icon>
                </ng-container>
                <ng-container *ngIf="!activePrivateDiscussion.data.privateDiscussion.isGroup">
                    <img
                        [src]="activePrivateDiscussion.data.usersData[0].user.avatar ? conf.storageRootUrl + activePrivateDiscussion.data.usersData[0].user.avatar : conf.assetsUrl + 'img/avatar.png'"
                        class="icon"/>
                </ng-container>
                <div class="texts">
                    <div class="title">{{ discussionName }}</div>
                    <div class="subtitle">
                        <ng-container *ngIf="activePrivateDiscussion.data.privateDiscussion.isGroup">
                            <span *ngFor="let userData of activePrivateDiscussion.data.usersData; let i = index">
                                {{ userData.user.firstName + ' ' + userData.user.lastName }}
                                <span *ngIf="i < activePrivateDiscussion.data.usersData.length - 1">,&nbsp;</span>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!activePrivateDiscussion.data.privateDiscussion.isGroup">
                            {{ 'Last_seen' | translate }}:&nbsp;
                            <span *ngIf="activePrivateDiscussion.data.usersData[0].privateDiscussion_user.lastSeen">
                                {{ activePrivateDiscussion.data.usersData[0].privateDiscussion_user.lastSeen | dateClosest }}
                            </span>
                            <span *ngIf="!activePrivateDiscussion.data.usersData[0].privateDiscussion_user.lastSeen">
                                {{ 'not_yet' | translate }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <button (click)="onCloseClicked($event)"
                        [matTooltip]="'Close' | translate"
                        mat-icon-button
                        matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="content">
                <discussions-discussion #discussionComponent
                                        [autoScrollToBottom]="true"
                                        [discussionId]="activePrivateDiscussion.data.discussion.id"
                                        [focusMessageInputOnLoad]="true"
                                        [inputMaxRows]="15"
                                        [isShowMore]="false"
                                        [isStackable]="false"
                                        [isStacked]="false"
                                        [newMessagePlaceholder]="'Write_a_message'"
                                        [noMessageText]="'No_message_yet'"
                                        [sendAPI]="'users/' + currentUser.user.id + '/discussions/' + activePrivateDiscussion.data.privateDiscussion.id + '/new-message'"
                                        [title]="null"></discussions-discussion>
            </div>
        </ng-container>
    </mat-card>
</ng-container>
