<module-content>
    <div>
        <div class="title">{{ 'List_of_prizes' | translate }}</div>
        <div class="subtitle">{{ 'List_of_prizes_info' | translate }}</div>
    </div>
    <div *ngFor="let control of prizesFormControls; let i = index;"
         class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onPrizesFormChanged()"
                   [formControl]="control.nameFormControl"
                   [maxlength]="PRIZE_MAX_LENGTH"
                   [placeholder]="'Prize_name' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="control.nameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ control.nameFormControl.value.length }}/{{ PRIZE_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <button (click)="onRemovePrizeClicked(i)"
                *ngIf="prizesFormControls.length > 1"
                [disabled]="!rights.EDIT"
                [matTooltip]="'Remove' | translate"
                mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="add-button-container">
        <button (click)="onAddPrizeClicked()"
                [disabled]="!rights.EDIT"
                [matTooltip]="'New_prize' | translate"
                color="accent"
                mat-mini-fab>
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div>
        <div class="title">{{ 'Past_and_current_funding' | translate }}</div>
        <div class="subtitle">{{ 'Past_and_current_funding_info' | translate }}</div>
    </div>
    <div *ngFor="let control of pastFundingsFormControls; let i = index;"
         class="line">
        <mat-form-field [hintLabel]="''"
                        class="flex-2"
                        color="primary">
            <input (keyup)="onPastFundingsFormChanged()"
                   [formControl]="control.sourceFormControl"
                   [maxlength]="SOURCE_MAX_LENGTH"
                   [placeholder]="'Funding_source' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="control.sourceFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ control.sourceFormControl.value.length }}/{{ SOURCE_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        class="flex-1"
                        color="primary">
            <input (keyup)="onPastFundingsFormChanged()"
                   [formControl]="control.amountFormControl"
                   [placeholder]="'Amount' | translate"
                   matInput
                   type="number">
            <span matPrefix>CHF&nbsp;</span>
            <mat-error *ngIf="control.amountFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
        <button (click)="onRemovePastFundingClicked(i)"
                *ngIf="pastFundingsFormControls.length > 1"
                [disabled]="!rights.EDIT"
                [matTooltip]="'Remove' | translate"
                mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="add-button-container">
        <button (click)="onAddPastFundingClicked()"
                [disabled]="!rights.EDIT"
                [matTooltip]="'New_funding' | translate"
                color="accent"
                mat-mini-fab>
            <mat-icon>add</mat-icon>
        </button>
    </div>
</module-content>
