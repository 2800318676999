<module-content [isLoading]="isLoading">
    <ng-container *ngIf="rights.ASK_FOR_REVIEW">
        <p>{{ 'Review_explanation_1' | translate }}</p>
        <p>{{ 'Review_explanation_2' | translate }}</p>
        <p *ngIf="!canSave"
           class="warning">
            <mat-icon>warning_outline</mat-icon>
            <span>{{ 'Project_contains_invalid_data' | translate }}</span>
        </p>
        <p *ngIf="missingMandatoryFields.length > 0"
           class="warning">
            <mat-icon>warning_outline</mat-icon>
            <span>{{ 'The_following_fields_must_be_completed_before_asking_a_review' | translate }}</span>
        </p>
        <div *ngFor="let group of missingMandatoryFields"
             class="mandatory-fields">
            <div class="step-name">{{ group.stepName | translate }}</div>
            <button (click)="onFieldClicked(group.stepIndex, field.fieldId)"
                    *ngFor="let field of group.fields"
                    color="warn"
                    mat-raised-button>
                <mat-icon>arrow_upward</mat-icon>&nbsp;{{ field.name | translate }}
            </button>
        </div>
        <button (click)="onAsForReviewClicked()"
                *ngIf="rights.ASK_FOR_REVIEW"
                [disabled]="!canAskForReview()"
                color="primary"
                mat-raised-button>
            {{ 'Ask_for_review' | translate }}
        </button>
    </ng-container>
    <ng-container *ngIf="rights.VALIDATE">
        <p>{{ 'Review_explanation_3' | translate }}</p>
        <p>{{ 'Review_explanation_4' | translate }}</p>
        <p>{{ 'Review_explanation_5' | translate }}</p>
        <div class="validation-buttons">
            <button (click)="onPublishClicked()"
                    [disabled]="isLoading"
                    color="primary"
                    mat-raised-button>
                {{ 'Publish' | translate }}
            </button>
            <button (click)="onRefuseClicked()"
                    [disabled]="isLoading"
                    color="warn"
                    mat-raised-button>
                {{ 'Refuse' | translate }}
            </button>
            <button (click)="onAskChangesClicked()"
                    [disabled]="isLoading"
                    mat-raised-button>
                {{ 'Ask_changes' | translate }}
            </button>
        </div>
    </ng-container>
    <p *ngIf="currentUser.user.isAdmin && project.projectValidationStateId == STATE_ID_DRAFT">{{ 'Review_explanation_6' | translate }}</p>
    <p *ngIf="currentUser.user.isProjectHolder && project.projectValidationStateId == STATE_ID_REVIEW">{{ 'Review_explanation_7' | translate }}</p>
    <p *ngIf="project.projectValidationStateId == STATE_ID_PUBLISHED">{{ 'Review_explanation_8' | translate }}</p>
    <p *ngIf="project.projectValidationStateId == STATE_ID_REFUSED">{{ 'Review_explanation_9' | translate }}</p>
</module-content>
