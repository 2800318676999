import { Injectable } from '@angular/core';
import { Conf } from '../../_conf';
import { Utils } from './utils';

@Injectable()
export class KeyValueStorage {

    //
    //
    // CONSTANTS
    //
    //

    public static STORE_MEMORY: string = 'memory';
    public static STORE_SESSION: string = 'session';
    public static STORE_LOCAL: string = 'local';

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _memoryStore: Object = {};
    private _stores: Object = {};

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils
    ) {
        this.initialize();
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public get(key: string, fallback: any = null, store: string = this._conf.defaultKeyValueStore): any {
        const value = this._stores[store][key];
        if (value === null || value === undefined) {
            return fallback;
        }

        return value;
    }

    public set(key: string, value: any, store: string = this._conf.defaultKeyValueStore): void {
        this._stores[store][key] = value;
    }

    public remove(key: string, store: string = this._conf.defaultKeyValueStore): void {
        delete this._stores[store][key];
    }

    public clear(store: string): void {
        if (store === KeyValueStorage.STORE_MEMORY) {
            this._memoryStore = {};
            this._stores[KeyValueStorage.STORE_MEMORY] = this._memoryStore;
        } else if (store === KeyValueStorage.STORE_SESSION) {
            window.sessionStorage.clear();
            this._stores[KeyValueStorage.STORE_SESSION] = window.sessionStorage;
        } else if (store === KeyValueStorage.STORE_LOCAL) {
            window.localStorage.clear();
            this._stores[KeyValueStorage.STORE_LOCAL] = window.localStorage;
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initialize(): void {
        this._stores[KeyValueStorage.STORE_MEMORY] = this._memoryStore;
        this._stores[KeyValueStorage.STORE_SESSION] = window.sessionStorage;
        this._stores[KeyValueStorage.STORE_LOCAL] = window.localStorage;
    }
}
