<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="backMenu"
            [pageSubtitle]="project.id ? ('Listing_completed_at_x' | translate:{ progress: listingProgress }) : null"
            [pageTitle]="'Academic_venture_information' | translate"
            header>
        <projects-project-state-indicator *ngIf="project.id"
                                          [project]="project"
                                          right-content>
            <mat-icon>more_vert</mat-icon>
        </projects-project-state-indicator>
    </header>
    <ng-container *ngIf="!is401 && !is404">
        <div *ngIf="lastUpdater"
             class="last-updater">
            {{ 'Last_changed_by_x_on_y' | translate: {
            x: lastUpdater.firstName + ' ' + lastUpdater.lastName,
            y: (project.updateDate | dateClosest)
        } }}
        </div>
        <mat-vertical-stepper #stepper
                              [linear]="false"
                              class="stepper">
            <mat-step>
                <ng-template matStepLabel>{{ 'Biotech_venture' | translate }}</ng-template>
                <projects-edit-project-project (onFormChanged)="onFormChanged()"
                                               [(project)]="project"
                                               [rights]="rights">
                </projects-edit-project-project>
                <div class="nav-buttons">
                    <button (click)="onChangeStepClicked(1)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>{{ 'Project_cases' | translate }}</ng-template>
                <div *ngIf="projectCases?.length > 0">
                    <div *ngFor="let projectCase of projectCases; let i = index">
                        <projects-edit-project-case (onAddProjectCase)="onAddProjectCase($event)"
                                                    (onFormChanged)="onFormChanged()"
                                                    (onProjectCaseProjectTypeChange)="onProjectCaseProjectTypeChange($event)"
                                                    (onProjectCaseTherapeuticAreaChange)="onProjectCaseTherapeuticAreaChange($event)"
                                                    (onRemoveProjectCase)="onRemoveProjectCase($event)"
                                                    [projectCaseProjectType]="getProjectCaseProjectType(projectCase.id)"
                                                    [projectCaseTherapeuticArea]="getProjectCaseTherapeuticArea(projectCase.id)"
                                                    [projectCase]="projectCases[i]"
                                                    [rights]="rights">
                        </projects-edit-project-case>
                    </div>
                </div>

                <div class="add-project-wrapper" id="step_project_cases">
                    <module-content>
                        <div class="add-project-container">
                            <div class="add-project-text">{{ 'add_new_project' | translate }}</div>
                            <div class="add-button-container add-project-button">
                                <button (click)="onAddNewProjectCaseClicked()"
                                        [disabled]="!rights.EDIT"
                                        [matTooltip]="'add_new_project' | translate"
                                        color="accent"
                                        mat-mini-fab>
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </module-content>
                </div>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(2)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>


            <mat-step>
                <ng-template matStepLabel>{{ 'Current_situation' | translate }}</ng-template>
                <projects-edit-project-situation (onFormChanged)="onFormChanged()"
                                                 [(project)]="project"
                                                 [rights]="rights">
                </projects-edit-project-situation>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(project.projectValidationStateId === 1 ? 5 : 3)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'Past_funding_and_prizes' | translate }}</ng-template>
                <projects-edit-project-funding (onFormChanged)="onFormChanged()"
                                               [(pastFundings)]="pastFundings"
                                               [(prizes)]="prizes"
                                               [(project)]="project"
                                               [rights]="rights">
                </projects-edit-project-funding>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(project.projectValidationStateId === 1 ? 5 : 4)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'Partnership_and_outlicensing' | translate }}</ng-template>
                <projects-edit-project-partnership (onFormChanged)="onFormChanged()"
                                                   [(project)]="project"
                                                   [rights]="rights">
                </projects-edit-project-partnership>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(5)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'Team_and_contacts' | translate }}</ng-template>
                <projects-edit-project-team (onFormChanged)="onFormChanged()"
                                            [(project)]="project"
                                            [(teamMembers)]="teamMembers"
                                            [rights]="rights">
                </projects-edit-project-team>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(6)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>
            <!--
            <mat-step>
                <ng-template matStepLabel>{{ 'Miscellaneous' | translate }}</ng-template>
                <projects-edit-projectCases-miscellaneous [(projectCases)]="projectCases"
                                                     [rights]="rights"
                                                     (onFormChanged)="onFormChanged()">
                </projects-edit-projectCases-miscellaneous>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button mat-button matStepperNext>{{ 'Next' | translate }}</button>
                </div>
            </mat-step>
            -->
            <mat-step>
                <ng-template matStepLabel>{{ 'Academic_link' | translate }}</ng-template>
                <projects-edit-project-academic (onFormChanged)="onFormChanged()"
                                                [(project)]="project"
                                                [rights]="rights">
                </projects-edit-project-academic>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button (click)="onChangeStepClicked(7)"
                            color="primary"
                            mat-raised-button>
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'Review' | translate }}</ng-template>
                <projects-edit-project-review (onAskedForReview)="onAskedForReview()"
                                              [(project)]="project"
                                              [canSave]="canSave()"
                                              [isLoading]="isLoading"
                                              [mandatoryFields]="mandatoryFields"
                                              [rights]="rights"
                                              [stepper]="stepper">
                </projects-edit-project-review>
                <div class="nav-buttons">
                    <button mat-button matStepperPrevious>{{ 'Back' | translate }}</button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
        <div>
            <button (click)="onDeleteClicked()"
                    *ngIf="rights.DELETE"
                    [disabled]="isLoading || isSaveLoading"
                    color="warn"
                    mat-raised-button>
                <mat-icon>delete_outline</mat-icon>&nbsp;{{ 'Delete' | translate }}
            </button>
        </div>
        <main-action (onAction)="onSaveClicked()"
                     *ngIf="rights.EDIT"
                     [disabled]="isLoading || !canSave()"
                     [displayMode]="'fab'"
                     [icon]="'check'"
                     [isLoading]="isSaveLoading"
                     [title]="'Save'">
        </main-action>
        <main-action (onAction)="onListUsersClicked()"
                     *ngIf="rights.LIST_PROJECT_MEMBERSHIPS && !currentUser.user.isProjectHolder"
                     [bottom]="96"
                     [color]="null"
                     [displayMode]="'fab'"
                     [icon]="'people'"
                     [title]="'Users_list' | translate">
        </main-action>
    </ng-container>
    <error-401 *ngIf="is401"></error-401>
    <error-404 *ngIf="is404"></error-404>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
