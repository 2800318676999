import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { FilesProvider } from '../../../files/providers/files-provider';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { FilesFilesComponent } from '../../../files/components/files/files';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { IProjectCaseView, ProjectsProvider } from '../../providers/projects-provider';
import { ProjectRights } from '../../providers/project-rights';
import { ApiBaseService } from '../../../_common/providers/api-base-service';
import {
    AcademicInstitution,
    DevelopmentPhase,
    File,
    FundingRound,
    InvestorProfile,
    MetricsRecord,
    PatentState,
    Project,
    ProjectCase,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectFavorite,
    ProjectMembership,
    ProjectPastFunding,
    ProjectPrize,
    ProjectTeamMember,
    ProjectType,
    TeamMemberType,
    TherapeuticArea,
    User_AcademicInstitution
} from '../../../_common/providers/models';

@Component({
    selector: 'projects-project-page',
    providers: [],
    styleUrls: ['./project.scss'],
    templateUrl: './project.html'
})
export class ProjectsProjectPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly FILES_EDITION_LEVEL: number = FilesFilesComponent.EDITION_LEVEL_NONE;
    public readonly INSTUTION_ID_NONE: number = AcademicInstitution.ID_NONE;
    public readonly P_MAX_LENGTH: number = 220;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public isFavoriteLoading: boolean = false;
    public is404: boolean = false;
    public rights: ProjectRights = new ProjectRights(this._utils);

    public backMenu: MenuItem = null;
    public backUrl: string = null;
    public backTitle: string = this._translater.instant('Back');

    public investorProfile: InvestorProfile = null;
    public project: Project = new Project();
    public projectTypes: ProjectType[] = [];
    public projectCaseProjectType: any[] = null;
    public projectCaseTherapeuticArea: any[] = null;
    public therapeuticAreas: TherapeuticArea[] = [];
    public logo: any = null;
    public illustration: any = null;
    public isFavorite: boolean = false;
    public developmentPhase: DevelopmentPhase[] = [];
    public patentState: PatentState[] = [];
    public academicInstitution1Title: string = null;
    public academicInstitution1: string = null;
    public academicInstitution2: string = null;
    public fundingRound: string = null;
    public teamPicture: any = null;
    public teamMembers: ProjectTeamMember[] = [];
    public boardMembers: ProjectTeamMember[] = [];
    public directorsMembers: ProjectTeamMember[] = [];
    public files: File[] = [];
    public prizes: ProjectPrize[] = [];
    public pastFundings: ProjectPastFunding[] = [];
    public projectCase: ProjectCase[] = [];
    public projectCaseView: IProjectCaseView[] = [];
    public upcomingFundingItems: string[] = [];

    public allPatentStates: PatentState[] = [];

    @ViewChild('illustrationInput', {static: false}) public illustrationInput;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        public projectsProvider: ProjectsProvider,
        private _title: Title,
        private _router: Router,
        private _dialoger: MatDialog,
        private _utils: Utils,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _currencyPipe: CurrencyPipe,
        private _api: ApiBaseService,
        private _filesProvider: FilesProvider,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._route.params.subscribe((params: any) => {
            this.loadProject(parseInt(params.projectId))
                .then(() => {
                    this.loadData()
                        .then(() => {
                            this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);
                            this.isLoading = false;
                            if (this.currentUser.user.isInvestor) {
                                this.projectsProvider.addProjectMetrics(this.project.id, MetricsRecord.TAG_PROJECT_SEEN)
                                    .catch((error: any) => this.onError(error));
                            }
                        })
                        .catch((error: any) => this.onError(error));
                })
                .catch((error: any) => this.onError(error));
        });
        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backUrl = params.backUrl;
                if (params.backTitle) {
                    this.backTitle = params.backTitle;
                    this.backMenu = {
                        icon: 'arrow_back',
                        title: this.backTitle,
                        url: this.backUrl
                    };
                }
            }
        });
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onFavoriteClicked(): void {
        this.isFavoriteLoading = true;
        this.projectsProvider.toggleProjectFavorite(this.project)
            .then((isFavorite: boolean) => {
                this.isFavorite = isFavorite;
                this.isFavoriteLoading = false;
            })
            .catch((error: any) => this.onError(error));
    }

    public onEditClicked(): void {
        this._router.navigate(['academic-ventures', this.project.id, 'edit'], {
            queryParams: {
                backUrl: this._router.url
            }
        });
    }

    public onListUsersClicked(): void {
        this._router.navigate(['academic-ventures', this.project.id, 'memberships'], {
            queryParams: {
                backUrl: this._router.url
            }
        });
    }

    public onContactMailClicked(): void {
        window.location.href = 'mailto:' + this.project.contactEmail;
    }

    public onContactPhoneClicked(): void {
        window.location.href = 'tel:' + this.project.contactPhone;
    }

    public onContactWebsiteClicked(): void {
        let url: string = this.project.contactWebsite;
        if (!this.project.contactWebsite.startsWith('http')) {
            url = 'http://' + url;
        }

        // on fait tout ce micmac pour ajouter un www automatiquement devant l'url au cas où
        // le type l'a oublié et que le webserver qui host le site n'a pas fait de redirection...
        // TODO ca marche pas ce micmac à cause des CORS...
        /*
        this._api.call('options', url)
            .then(() => window.open(url, '_blank'))
            .catch((error: any) => {
                if (url.startsWith('http://')) {
                    url = 'http://www.' + url.substr(7);
                }
                else if (url.startsWith('https://')) {
                    url = 'https://www.' + url.substr(8);
                }

                window.open(url, '_blank');
            });
        */
        window.open(url, '_blank');
    }

    public onEditIllustrationClicked(): void {
        this.illustrationInput.nativeElement.click();
    }

    public onFileSelectionChange(event): void {
        const files: FileList = event.target.files;
        if (files.length) {
            this.isLoading = true;
            this.selectFile(files[0])
                .then(() => {
                    this.isLoading = false;
                    this._toaster.open(this._translater.instant('Illustration_saved'), '', {duration: 5000});
                    this.updateIllustration();
                })
                .catch((error: any) => this.onError(error));
        }
    }

    public shouldDisplayProjectCase(singleProjectCase: IProjectCaseView) {
        return (singleProjectCase.projectCase.title !== '' && singleProjectCase.projectCase.title !== null) &&
            singleProjectCase.projectCase.developmentPhaseId !== null &&
            (singleProjectCase.projectCase.description !== '' && singleProjectCase.projectCase.description !== null) &&
            singleProjectCase.projectCase.patentStateId !== null &&
            singleProjectCase.projectTypes.length > 0 &&
            singleProjectCase.therapeuticArea.length > 0;
    }

    public displayDateOfClosing() {
        const dateToday = new Date();
        dateToday.setHours(0, 0, 0, 0);
        return new Date(this.project.dateOfClosingRound) >= dateToday;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadProject(projectId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this._storage.select(Project).where('id').equals(projectId).get(1),
                this._storage.select(ProjectCase).where('projectId').equals(projectId).get()
            ])
                .then((data: any[]) => {
                    if (!data[0]) {
                        reject({status: 404});
                        return;
                    }

                    this.project = data[0].clone(); // TODO si on clone pas, il y a un scénario quelque part que j'ai pas réussi à identifier qui fait altère le model en DB et je pige pas bien pourquoi ... à creuser
                    this.projectCase = data[1];

                    resolve();
                })
                .catch((error: any) => reject(error));

        });
    }

    private prepareProjectCase(): IProjectCaseView[] {
        this.projectCase.forEach((singleProjectCase) => {

            const projectCaseProjectTypes: number[] = this.projectCaseProjectType[singleProjectCase.id] ? this._utils.getKeyValues(this.projectCaseProjectType[singleProjectCase.id], 'projectTypeId') : [];
            const projectCaseTherapeuticArea: number[] = this.projectCaseTherapeuticArea[singleProjectCase.id] ? this._utils.getKeyValues(this.projectCaseTherapeuticArea[singleProjectCase.id], 'therapeuticAreaId') : [];

            const projectCaseView: IProjectCaseView = {
                projectCaseId: singleProjectCase.id,
                projectCase: singleProjectCase,
                developmentPhase: this.developmentPhase.find((item) => item.id === singleProjectCase.developmentPhaseId),
                patentState: this.patentState.find((item) => item.id === singleProjectCase.patentStateId),
                projectTypes: projectCaseProjectTypes.length > 0 ? this.projectTypes.filter((item) => projectCaseProjectTypes.indexOf(item.id) > -1) : [],
                therapeuticArea: projectCaseTherapeuticArea.length > 0 ? this.therapeuticAreas.filter((item) => projectCaseTherapeuticArea.indexOf(item.id) > -1) : []
            };

            this.projectCaseView.push(projectCaseView);
        });
        return [];
    }

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                /* 0 */ this._storage.select(InvestorProfile).where('userId').equals(this.currentUser.user.id).get(1),
                /* 1 */ this._storage.select(ProjectCase_ProjectType).where('projectCaseId').in(this._utils.getKeyValues(this.projectCase, 'id')).get(),
                /* 2 */ this._storage.select(ProjectCase_TherapeuticArea).where('projectCaseId').in(this._utils.getKeyValues(this.projectCase, 'id')).get(),
                /* 3 */ this._storage.select(ProjectFavorite)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('projectId').equals(this.project.id)
                    .get(1),
                /* 4 */ this._storage.select(DevelopmentPhase).where('id').in(this._utils.getKeyValues(this.projectCase, 'developmentPhaseId')).get(),
                /* 5 */ this._storage.select(PatentState).where('id').in(this._utils.getKeyValues(this.projectCase, 'patentStateId')).get(),
                /* 6 */ this._storage.select(AcademicInstitution).where('id').equals(this.project.academicInstitution1Id).get(1),
                /* 7 */ this._storage.select(AcademicInstitution).where('id').equals(this.project.academicInstitution2Id).get(1),
                /* 8 */ this._storage.select(FundingRound).where('id').equals(this.project.fundingRoundId).get(1),
                /* 9 */ this._storage.select(ProjectTeamMember)
                    .where('projectId').equals(this.project.id)
                    .and('teamMemberTypeId').equals(TeamMemberType.ID_MEMBER)
                    .get(),
                /* 10 */ this._storage.select(ProjectTeamMember)
                    .where('projectId').equals(this.project.id)
                    .and('teamMemberTypeId').equals(TeamMemberType.ID_ADVISORY_BOARD)
                    .get(),
                /* 11 */ this._storage.select(File).where('fileGroupId').equals(this.project.fileGroupId).get(),
                /* 12 */ this._storage.select(ProjectPrize).where('projectId').equals(this.project.id).get(),
                /* 13 */ this._storage.select(ProjectPastFunding).where('projectId').equals(this.project.id).get(),
                /* 14 */ this._storage.select(ProjectMembership)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('projectId').equals(this.project.id)
                    .get(1),
                /* 15 */ this._storage.select(TherapeuticArea).get(),
                /* 16 */ this._storage.select(ProjectType).get(),
                /* 17 */ this._storage.select(User_AcademicInstitution).where('userId').equals(this.currentUser.user.id).get(),
                /* 18 */ this._storage.select(ProjectTeamMember)
                    .where('projectId').equals(this.project.id)
                    .and('teamMemberTypeId').equals(TeamMemberType.ID_BOARD_OF_DIRECTORS)
                    .get()
            ])
                .then((data: any[]) => {
                    this.upcomingFundingItems = [];
                    this.investorProfile = data[0];
                    this.projectCaseProjectType = this._utils.groupBy(data[1], 'projectCaseId');
                    this.projectCaseTherapeuticArea = this._utils.groupBy(data[2], 'projectCaseId');
                    this.isFavorite = data[3] !== null;
                    this.developmentPhase = data[4];
                    this.patentState = data[5];
                    if (this.project.academicInstitution1Id === AcademicInstitution.ID_OTHER) {
                        this.academicInstitution1 = this.project.customAcademicInstitution ? this.project.customAcademicInstitution : (data[6] ? data[6].name : '');
                    } else {
                        this.academicInstitution1 = (data[6] ? data[6].name : '');
                    }
                    if (this.project.academicInstitution2Id && this.project.academicInstitution2Id !== AcademicInstitution.ID_NONE) {
                        if (this.project.academicInstitution2Id === AcademicInstitution.ID_OTHER) {
                            this.academicInstitution2 = this.project.customAcademicInstitution ? this.project.customAcademicInstitution : (data[7] ? data[7].name : '');
                        } else {
                            this.academicInstitution2 = (data[7] ? data[7].name : '');
                        }
                    }
                    this.updateAcademicInstitution1Title();
                    if (this.project.fundingRoundId) {
                        this.fundingRound = this.project.fundingRoundId === FundingRound.ID_OTHER ? this.project.customFundingRound : data[8].name;
                        if (this.fundingRound && this.fundingRound.length) {
                            this.upcomingFundingItems.push(this.fundingRound);
                        }
                    }
                    this.teamMembers = data[9];
                    this.boardMembers = data[10];
                    this.files = data[11];
                    this.prizes = data[12];
                    this.pastFundings = data[13];

                    this.therapeuticAreas = data[15];
                    this.projectTypes = data[16];
                    this.directorsMembers = data[18];

                    // prepare projectCases for display
                    this.prepareProjectCase();

                    if (this.project.logo) {
                        this._filesProvider.downloadFile('projects/' + this.project.id + '/download-logo', null, {noCache: true})
                            .then((fileData: any) => {
                                const reader: FileReader = new FileReader();
                                reader.addEventListener('load', () => this.logo = reader.result, false);
                                reader.readAsDataURL(fileData);
                            })
                            .catch((error: any) => this.onError(error));
                    }

                    this.updateIllustration();

                    if (this.project.teamPicture) {
                        this._filesProvider.downloadFile('projects/' + this.project.id + '/download-team-picture', null, {noCache: true})
                            .then((fileData: any) => {
                                const reader: FileReader = new FileReader();
                                reader.addEventListener('load', () => this.teamPicture = reader.result, false);
                                reader.readAsDataURL(fileData);
                            })
                            .catch((error: any) => this.onError(error));
                    }

                    if (this.project.raisingFundsAmount) {
                        this.upcomingFundingItems.push(this._currencyPipe.transform((this.project.raisingFundsAmount / 100), 'CHF ', 'symbol', '1.0-0'));
                    }

                    if (this.project.dateOfClosingRound) {
                        this.upcomingFundingItems.push(
                            this._translater.instant('opening_around')
                            + ' ' +
                            this._utils.formatDate(this.project.dateOfClosingRound, 'FjY')
                        );
                    }

                    this.rights.evaluate({
                        user: this.currentUser.user,
                        project: this.project,
                        membership: data[14],
                        investorProfile: this.investorProfile,
                        user_academicInstitutions: data[17]
                    });

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateIllustration(): void {
        if (this.project.illustration) {
            this._filesProvider.downloadFile('projects/' + this.project.id + '/download-illustration', null, {noCache: true})
                .then((fileData: any) => {
                    const reader: FileReader = new FileReader();
                    reader.addEventListener('load', () => this.illustration = reader.result, false);
                    reader.readAsDataURL(fileData);
                })
                .catch((error: any) => this.onError(error));
        } else {
            this.illustration = null;
        }
    }

    private selectFile(file: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const parts: string[] = file.name.split('.');
            if (this.projectsProvider.ILLUSTRATION_FILE_TYPES.indexOf(parts[parts.length - 1]) < 0) {
                this._toaster.open(this._translater.instant('Error_image_extension'), '', {duration: 5000});
                resolve();
                return;
            }

            const fileSize: number = file.size / 1048576; // converson o => Mo
            if (fileSize > this.projectsProvider.ILLUSTRATION_MAX_SIZE) {
                this._toaster.open(this._translater.instant('Error_image_size'), '', {duration: 5000});
                resolve();
                return;
            }

            const project: Project = this.project.clone();
            project.illustration = file;
            this.projectsProvider.saveProject(project)
                .then((updatedProject: Project) => {
                    this.project = updatedProject;
                    resolve();
                })
                .catch(reject);
        });
    }

    private updateAcademicInstitution1Title(): void {
        if (!this.project.isCompanyIncorporated) {
            this.academicInstitution1Title = 'Primary_academic_institution_related_with';
        } else {
            if (this.project.isSpinOff) {
                this.academicInstitution1Title = 'Spinoff_of';
            } else {
                this.academicInstitution1Title = 'Primary_academic_institution_related_with';
            }

        }
    }

    private onError(error: any): void {
        this.isLoading = false;
        this.isFavoriteLoading = false;
        console.log(error);

        if (error.status === 404) {
            this.is404 = true;
        } else if (error.status === 429) {
            // do nothing ...
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }
    }
}
