<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]=""
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isProjectHolder"></error-401>
    <div class="wrapper">
        <module-content *ngIf="currentUser.user.isProjectHolder">
            <div class="title">{{ 'Project_welcome_title' | translate:{name: currentUser.user.firstName} }}</div>
            <div class="subtitle">{{ 'Project_welcome_subtitle' | translate }}</div>
            <div class="sections">
                <div class="section">
                    <mat-icon color="primary">add</mat-icon>
                    <div>{{ 'Project_welcome_section1' | translate }}</div>
                </div>
                <div class="section">
                    <mat-icon color="primary">thumb_up_alt</mat-icon>
                    <div>{{ 'Project_welcome_section2' | translate }}</div>
                </div>
                <div class="section">
                    <mat-icon color="primary">edit</mat-icon>
                    <div>{{ 'Project_welcome_section3' | translate }}</div>
                </div>
                <div class="section">
                    <mat-icon color="primary">show_chart</mat-icon>
                    <div>{{ 'Project_welcome_section4' | translate }}</div>
                </div>
            </div>
            <div class="main-action-container">
                <button (click)="onCreateProjectClicked()"
                        color="accent"
                        mat-raised-button>
                    {{ 'Create_my_project' | translate }}
                </button>
            </div>
        </module-content>
    </div>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
