import { AfterViewInit, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'extensible-p',
    styleUrls: ['./extensible-p.scss'],
    templateUrl: './extensible-p.html'
})
export class ExtensiblePComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public max: number = 0;
    @Input() public color: string = 'accent';
    @Input() public isExtended: boolean = false;

    constructor(
        private _translater: TranslateService,
    ) {
    }

    private _text: string = '';

    public get text() {
        return this._text;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set text(text: string) {
        this._text = text ? text : '';
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onToggleClicked(): void {
        this.isExtended = !this.isExtended;
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
