import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

// doc :
//  - https://developers.google.com/maps/documentation/embed/guide#directions_mode
//  - https://stackoverflow.com/questions/38879993/angular-2-google-map-implementation
//  - https://stackoverflow.com/questions/21992498/disable-mouse-scroll-wheel-zoom-on-embedded-google-maps

@Component({
    selector: 'embed-map',
    providers: [],
    styleUrls: ['./embed-map.scss'],
    templateUrl: './embed-map.html'
})
export class EmbedMapComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input()
    public apiKey: string = '';
    @Input()
    public query: string = '';
    @Input()
    public zoom: number = 10;
    @Input()
    public width: string = '100%';
    @Input()
    public height: string = '450px';

    public isActivated: boolean = false;
    public showHint: boolean = false;

    private _iframeBaseUrl: string = 'https://www.google.com/maps/embed/v1/place';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public sanitizer: DomSanitizer,
        public conf: Conf,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        this.isActivated = false;
        this.showHint = false;
    }

    @HostListener('blur')
    public onBlur(): void {
        this.isActivated = false;
    }

    @HostListener('click')
    public onClick(): void {
        this.isActivated = true;
    }

    @HostListener('mouseenter')
    public onHover(): void {
        this.showHint = true;
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public getIframeUrl(): string {
        return this._iframeBaseUrl
            + '?key=' + this.apiKey
            + '&q=' + this.query
            + '&zoom=' + this.zoom;
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
