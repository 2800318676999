import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersProvider } from '../../providers/users-provider';
import { User } from '../../../_common/providers/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'users-add-user-dialog',
    providers: [],
    styleUrls: ['add-user-dialog.scss'],
    templateUrl: 'add-user-dialog.html'
})
export class UsersAddUserDialogComponent {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 200;
    public readonly CHECK_EMAIL_THRESHOLD: number = 750;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public userForm: FormGroup;
    public emailUnavailableError: boolean = false;

    public title: string = null;
    public infoMessage: string = null;

    private _checkEmailAvailabilityRequestTimestamp: number = 0;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<UsersAddUserDialogComponent>,
        private _fb: FormBuilder,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _usersProvider: UsersProvider,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.userForm = this._fb.group({
            firstName: new FormControl('', [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]),
            lastName: new FormControl('', [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]),
            email: new FormControl('', [
                Validators.required,
                Validators.email,
                Validators.maxLength(this.NAME_MAX_LENGTH),
                (control: any) => {
                    return this.emailUnavailableError ? {unavailable: {valid: false}} : null;
                }
            ])
        });

        this.title = data.title;
        this.infoMessage = data.infoMessage;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public onEmailChanged(): void {
        this.emailUnavailableError = false;
        this.userForm.controls.email.markAsTouched();
        this.userForm.controls.email.updateValueAndValidity();
        if (!this.userForm.controls.email.hasError('email')) {
            // on check la dispo que si l'email est valide ...
            this.checkEmailAvailability();
        }
    }

    public onAddClicked(): void {
        if (this.userForm.invalid) {
            return;
        }

        this.dialog.close(Object.assign(new User(), this.userForm.value));
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private checkEmailAvailability(): void {
        this._checkEmailAvailabilityRequestTimestamp = (new Date()).getTime();
        setTimeout(() => {
            const now: number = (new Date()).getTime();
            if (now - this._checkEmailAvailabilityRequestTimestamp >= this.CHECK_EMAIL_THRESHOLD) {
                this._usersProvider.checkEmailAvailability(this.userForm.controls.email.value)
                    .then((isAvailable: boolean) => {
                        this.emailUnavailableError = !isAvailable;
                        this.userForm.controls.email.updateValueAndValidity();
                    })
                    .catch((error: any) => this.onError(error));
            }
        }, this.CHECK_EMAIL_THRESHOLD);
    }

    private onError(error: any): void {
        console.log(error);

        if (error.status === 422) {
            this.emailUnavailableError = true;
            this.userForm.controls.email.updateValueAndValidity();
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }
    }
}
