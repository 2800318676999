<ng-container>
    <div class="columns-container">
        <div class="left">
            <module-content>
                <div class="highlights">
                    <div class="line">
                        <div class="title">{{ 'Project_title' | translate }}:</div>
                        <div class="text">{{ projectCase.projectCase.title }}</div>
                    </div>
                    <div *ngIf="projectCase.projectCase.subtitle"
                         class="line">
                        <div class="title">{{ 'Subtitle' | translate }}:</div>
                        <div class="text">{{ projectCase.projectCase.subtitle }}</div>
                    </div>
                    <div *ngIf="projectCase.developmentPhase"
                         class="line">
                        <div class="title">{{ 'Phase_of_development' | translate }}:</div>
                        <div class="text">{{ projectCase.developmentPhase.name }}</div>
                    </div>
                    <div *ngIf="projectCase.patentState"
                         class="line">
                        <div class="title">{{ 'Patent' | translate }}:</div>
                        <div class="text">{{ projectCase.patentState.name }}</div>
                    </div>

                    <div *ngIf="projectCase.projectTypes.length"
                         class="line">
                        <div class="title">{{ 'Type_of_project' | translate }}:</div>
                        <div class="text">
                            <ng-container *ngFor="let projectType of projectCase.projectTypes; let i = index">
                                <ng-container *ngIf="i < MAX_ITEMS_TO_DISPLAY">{{ projectType.name }}
                                    <ng-container *ngIf="i < projectCase.projectTypes.length - 1">,&nbsp;</ng-container>
                                </ng-container>
                            </ng-container>
                            <button (click)="onMoreProjectTypesClicked()"
                                    *ngIf="projectCase.projectTypes.length > MAX_ITEMS_TO_DISPLAY"
                                    color="primary"
                                    mat-button>
                                {{ 'and_n_more' | translate:{n: (projectCase.projectTypes.length - MAX_ITEMS_TO_DISPLAY)} }}
                            </button>
                        </div>
                    </div>


                    <div *ngIf="projectCase.therapeuticArea.length"
                         class="line">
                        <div class="title">{{ 'Therapeutic_area' | translate }}:</div>
                        <div class="text">
                            <ng-container *ngFor="let therapeuticArea of projectCase.therapeuticArea; let i = index">
                                <ng-container *ngIf="i < MAX_ITEMS_TO_DISPLAY">{{ therapeuticArea.name }}
                                    <ng-container *ngIf="i < projectCase.therapeuticArea.length - 1">,&nbsp;
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <button (click)="onMoreTherapeuticAreasClicked()"
                                    *ngIf="projectCase.therapeuticArea.length > MAX_ITEMS_TO_DISPLAY"
                                    color="primary"
                                    mat-button>
                                {{ 'and_n_more' | translate:{n: (projectCase.therapeuticArea.length - MAX_ITEMS_TO_DISPLAY)} }}
                            </button>
                        </div>
                    </div>

                </div>

                <div *ngIf="projectCase.projectCase.description"
                     class="content">
                    <div class="title">{{ 'Description' | translate }}</div>
                    <quill-view-html [content]="projectCase.projectCase.description"></quill-view-html>
                </div>
                <div *ngIf="projectCase.projectCase.uniqueness"
                     class="content">
                    <div class="title">{{ 'Uniqueness' | translate }}</div>
                    <extensible-p [max]="P_MAX_LENGTH"
                                  [text]="projectCase.projectCase.uniqueness">
                    </extensible-p>
                </div>
                <div *ngIf="projectCase.projectCase.unmetNeed"
                     class="content">
                    <div class="title">{{ 'Unmet_medical_need' | translate }}</div>
                    <extensible-p [max]="P_MAX_LENGTH"
                                  [text]="projectCase.projectCase.unmetNeed">
                    </extensible-p>
                </div>
                <div *ngIf="projectCase.projectCase.marketSize"
                     class="content">
                    <div class="title">{{ 'Market_size' | translate }}</div>
                    <extensible-p [max]="P_MAX_LENGTH"
                                  [text]="projectCase.projectCase.marketSize">
                    </extensible-p>
                </div>
                <div *ngIf="projectCase.projectCase.competition"
                     class="content">
                    <div class="title">{{ 'Current_and_future_competition' | translate }}</div>
                    <extensible-p [max]="P_MAX_LENGTH"
                                  [text]="projectCase.projectCase.competition">
                    </extensible-p>
                </div>
            </module-content>
        </div>
    </div>
</ng-container>
