<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageSubtitle]="('Dashboard' | translate) + ' (Beta)'"
            [pageTitle]="project?.name"
            header>
    </header>

    <error-401 *ngIf="!is404 && !rights.SEE_DASHBOARD"></error-401>
    <error-404 *ngIf="is404"></error-404>
    <div *ngIf="rights.SEE_DASHBOARD"
         class="main-container">
        <div class="left">
            <div class="module-container">
                <projects-dashboard-overview [project]="project"
                                             [rights]="rights">
                </projects-dashboard-overview>
            </div>
            <div class="module-container">
                <projects-dashboard-key-data [(project)]="project"
                                             [rights]="rights">
                </projects-dashboard-key-data>
            </div>
        </div>
        <div class="right">
            <div class="module-container">
                <projects-dashboard-metrics [project]="project"
                                            [rights]="rights">
                </projects-dashboard-metrics>
            </div>
        </div>
    </div>
    <footer footer></footer>
</page-content>
