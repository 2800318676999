import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { Utils } from '../../../_common/providers/utils';
import { AlertsProvider } from '../../providers/alerts-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { Alert } from '../../../_common/providers/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'alerts-list',
  providers: [],
  styleUrls: ['./list.scss'],
  templateUrl: './list.html'
})
export class AlertsListComponent implements AfterViewInit {

  //
  //
  // CONSTANTS
  //
  //

  //
  //
  // STATICS
  //
  //

  //
  //
  // ATTRIBUTES
  //
  //

  public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
  public columns: string[] = ['name', 'createDate', 'actions'];
  public alertTooltip: string = this._translater.instant('Alert_tooltip');

  @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) public sorter: MatSort;

  //
  //
  // CONSTRUCTOR
  //
  //

  constructor(
    public conf: Conf,
    public utils: Utils,
    public currentUser: CurrentUser,
    private _dialoger: MatDialog,
    private _translater: TranslateService,
    private _toaster: MatSnackBar,
    private _router: Router,
    private _alertsProvider: AlertsProvider,
    private _storage: ModelsStorage,
    private _changeDetector: ChangeDetectorRef
  ) {
  }

  //
  //
  // SUPER METHODS
  //
  //

  public ngAfterViewInit() {
    this.initDataSource();
    this.loadData().catch((error: any) => this.onError(error));
  }

  //
  //
  // PUBLIC METHODS
  //
  //

  public onDataSortChange(event: any): void {
    this.dataSource.sortBy(event.active, event.direction);
  }

  public onDeleteClicked(row: AlertsListRow): void {
    const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
      data: {confirmText: 'Confirm_delete_alert_warning'}
    });
    dialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        row.isLoading = true;
        this.deleteAlert(row.alert)
          .then(() => {
            this._toaster.open(this._translater.instant('Alert_deleted'), '', {duration: 5000});
          })
          .catch((error: any) => {
            row.isLoading = false;
            this.onError(error);
          });
      }
    });
  }

  //
  //
  // PRIVATE METHODS
  //
  //

  private initDataSource(): void {
    this.dataSource.sort = this.sorter;
    this.dataSource.paginator = this.paginator;

    this.dataSource.addSortDefinition(
      'name',
      (a: Alert, b: Alert) => this.utils.sortRow(a.name.toLowerCase(), b.name.toLowerCase()),
      (a: Alert, b: Alert) => this.utils.sortRow(b.name.toLowerCase(), a.name.toLowerCase())
    );

    this.dataSource.addSortDefinition(
      'createDate',
      (a: Alert, b: Alert) => this.utils.sortRow(a.createDate, b.createDate),
      (a: Alert, b: Alert) => this.utils.sortRow(b.createDate, a.createDate)
    );
  }

  private loadData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._storage.select(Alert).where('ownerId').equals(this.currentUser.user.id).orderBy('name').get()
        .then((alerts: Alert[]) => {
          const rows: AlertsListRow[] = [];
          alerts.forEach((alert: Alert) => rows.push({alert, isLoading: false}));

          this.dataSource.replaceAllWith(rows);
        })
        .catch((error: any) => reject(error));
    });
  }

  private deleteAlert(alert: Alert): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._alertsProvider.deleteAlert(alert)
        .then(() => {
          for (let i = 0; i < this.dataSource.count; i++) {
            if (this.dataSource.get(i).alert.id === alert.id) {
              this.dataSource.remove(i);
              i = this.dataSource.count;
            }

            resolve();
          }
        })
        .catch((error: any) => reject(error));
    });
  }

  private onError(error: any): void {
    console.log(error);
  }
}

export interface AlertsListRow {
  alert: Alert;
  isLoading: boolean;
}
