<header [backMenu]="null"
        [logoAlwaysDisplayed]="true"
        [pageSubtitle]="null"
        [pageTitle]="null"></header>
<div class="big-wrapper">
    <acventures-common-home-welcome>
        <button (click)="onCreateProjectAccountClicked()"
                class="big-button"
                color="accent"
                mat-raised-button>
            {{ 'Create_an_account_free' | translate }}
        </button>
    </acventures-common-home-welcome>
    <projects-home-introduction>
    </projects-home-introduction>
    <footer [hasLeftMenu]="false"></footer>
</div>
