<div mat-dialog-title>{{ 'New_project' | translate }}</div>
<mat-dialog-content>
    <div class="line">
        <mat-form-field class="text-box">
            <input [formControl]="nameFormControl"
                   matInput
                   maxlength="{{ NAME_MAX_LENGTH }}"
                   placeholder="{{ 'Project_name' | translate }}"/>
            <mat-error *ngIf="nameFormControl.hasError('required')">{{ 'Field_required' | translate }}</mat-error>
            <mat-hint align="end">{{ nameFormControl.value?.length }} / {{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <!--
    <mat-form-field *ngIf="academicInstitution1FormControl.value == INSTITUTION_OTHER"
                    color="primary"
                    [hintLabel]="''">
        <input matInput
               type="text"
               [formControl]="customAcademicInstitutionFormControl"
               [maxlength]="CUSTOM_INSTITUTION_MAX_LENGTH"
               [placeholder]="'Academic_institution_name' | translate"
               (keyup)="_onFormChanged()">
        <mat-error *ngIf="customAcademicInstitutionFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        <mat-hint align="end">{{ customAcademicInstitutionFormControl.value?.length }}/{{ CUSTOM_INSTITUTION_MAX_LENGTH }}</mat-hint>
    </mat-form-field>
    -->
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onSaveClicked()"
            [disabled]="nameFormControl.invalid || isLoading"
            color="accent"
            mat-raised-button>
        {{ 'Save' | translate}}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading"
                      color="accent"
                      mode="indeterminate">
    </mat-progress-bar>
</div>
