import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { InvestorsProvider } from '../../providers/investors-provider';
import { SyncServiceProvider } from '../../../_common/providers/sync-service';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Company, CompanyCategory, User } from '../../../_common/providers/models';
import { Utils } from '../../../_common/providers/utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-register-dialog',
    providers: [],
    styleUrls: ['./register-dialog.scss'],
    templateUrl: './register-dialog.html'
})
export class InvestorsRegisterDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly NAME_MAX_LENGTH: number = 100;
    public readonly EMAIL_MAX_LENGTH: number = 200;
    public readonly PASSWORD_MAX_LENGTH: number = 200;
    public readonly PASSWORD_MIN_LENGTH: number = 6;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showPassword: boolean = false;

    public firstNameFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public lastNameFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    public emailUnavailableError: boolean = false;
    public emailFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.EMAIL_MAX_LENGTH),
        Validators.email,
        Validators.pattern(this.utils.EMAIL_REGEX),
        (control: any) => {
            return this.emailUnavailableError ? {unavailable: {valid: false}} : null;
        }
    ]);
    public passwordFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.PASSWORD_MAX_LENGTH),
        Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]);
    public password2FormControl: FormControl = new FormControl('', [
        Validators.required,
        (control: any) => {
            // TODO carrément supprimer ce field
            return null;
            /*
            return this.passwordFormControl &&
                this.password2FormControl &&
                this.passwordFormControl.value == this.password2FormControl.value ?
                null : { passwordsDontMatch: { valid: false } };
            */
        }
    ]);

    public companyFormControl: FormControl = new FormControl('', [
        Validators.required,
    ]);
    public terms: boolean = false;

    public companyCategories: CompanyCategory[] = [];
    public companies: Company[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<InvestorsRegisterDialogComponent>,
        public utils: Utils,
        private _investorsProvider: InvestorsProvider,
        private _translater: TranslateService,
        private _sync: SyncServiceProvider,
        private _currentUser: CurrentUser,
        private _changeDetector: ChangeDetectorRef,
        private _storage: ModelsStorage,
        private _router: Router,
        private _toaster: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {

    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
        this.passwordFormControl.valueChanges.subscribe(() => {
            this.password2FormControl.updateValueAndValidity();
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onRegisterClicked(): void {
        this.isLoading = true;
        this.register()
            .then((user: User) => {
                this.dialog.afterClosed().subscribe(() => this._router.navigate(['welcome']));
                this.dialog.close();
                this._toaster.open(this._translater.instant('Account_created'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onEmailChanged(): void {
        this.emailUnavailableError = false;
        this.emailFormControl.updateValueAndValidity();
    }

    public onCompanyChanged(): void {
        this._changeDetector.detectChanges();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(Company).orderBy('name').get()
                .then((data: Company[]) => {
                    this.companies = data;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private register(): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            this._investorsProvider.registerAccount(
                this.firstNameFormControl.value,
                this.lastNameFormControl.value,
                this.emailFormControl.value,
                this.passwordFormControl.value,
                this.companyFormControl.value,
            )
                .then((user: User) => {
                    this._currentUser.login(this.emailFormControl.value, this.passwordFormControl.value, false)
                        .then(() => {
                            this._sync.sync(true, 0)
                                .then(() => {
                                    resolve(user);
                                })
                                .catch((error) => reject(error));
                        })
                        .catch((error) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);
        this.isLoading = false;

        if (error.status === 422 && error.error.errors['email']) {
            this.emailUnavailableError = true;
            this.emailFormControl.updateValueAndValidity();
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }
    }
}
