import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'myStripHttp'})
export class MyStripHttpPipe implements PipeTransform {
    constructor() {
    }

    public transform(str: string) {
        if (str.startsWith('https')) {
            return str.substring(8);
        }
        if (str.startsWith('http')) {
            return str.substring(7);
        }

        return str;
    }
}
