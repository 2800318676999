<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Projects_in_draft_state' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isAdmin"></error-401>
    <ng-container *ngIf="currentUser.user.isAdmin">
        <module-content *ngIf="institutions.length"
                        class="legend">
            <p>{{ 'You_can_admin_following_insitutions' | translate }}:</p>
            <ul>
                <li *ngFor="let institution of institutions">{{ institution.name }}</li>
            </ul>
        </module-content>
        <module-content *ngIf="projects.length === 0"
                        class="legend">
            <p>{{ 'No_projects_pending_validation' | translate }}</p>
        </module-content>
        <projects-list (onDeleteClicked)="onDeleteClicked($event)"
                       (onEditClicked)="onEditRowClicked($event)"
                       (onRowClicked)="onRowClicked($event)"
                       [disableSearchEngine]="true"
                       [preserveFilters]="false"
                       [projects]="projects">
        </projects-list>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
