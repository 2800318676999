<module-content [mainActions]="isAddEnabled ? [addMenu] : []"
                [title]="title">
    <div *ngIf="dataSource.count === 0"
         class="legend">
        <p>{{ noDataMessage | translate }}</p>
    </div>
    <mat-table (matSortChange)="onDataSortChange($event)"
               *ngIf="dataSource.count > 0"
               [dataSource]="dataSource"
               matSort>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns; let i = index;"></mat-row>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="name-cell"
                             mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="name-cell">
                <div (click)="onRowClicked.emit(row)"
                     class="link-button clickable">
                    {{ row.user.firstName + ' ' + row.user.lastName }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef class="name-cell hideable-500"
                             mat-sort-header>{{ 'Email' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="name-cell hideable-500">
                {{ row.user.email }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef class="date-cell hideable-500"
                             mat-sort-header>{{ 'Registration_date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="date-cell hideable-500">
                {{ row.user.createDate | dateClosest:'FjY' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <button *ngIf="!row.isLoading"
                        [matMenuTriggerFor]="menu"
                        mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button (click)="onDeleteRowClicked.emit(row)"
                            [disabled]="!row.rights.DELETE"
                            mat-menu-item>
                        <mat-icon>delete_outline</mat-icon>&nbsp;{{ 'Delete' | translate }}
                    </button>
                </mat-menu>
                <mat-spinner *ngIf="row.isLoading"
                             [diameter]="24">
                </mat-spinner>
            </mat-cell>
        </ng-container>
    </mat-table>
    <mat-paginator [class.hidden]="dataSource.count == 0"
                   [hidePageSize]="true"
                   [pageSizeOptions]="[10]">
    </mat-paginator>
</module-content>
