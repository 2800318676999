import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ModuleMenu } from '../../../_common/components/module-content/module-content';
import { CurrentUser } from '../../../_common/providers/current-user';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InstitutionLocalExpertRow, LocalExpertRow } from '../../providers/users-provider';
import { UserRights } from '../../providers/user-rights';
import { AcademicInstitution, User, User_AcademicInstitution } from '../../../_common/providers/models';

@Component({
    selector: 'users-local-experts-list',
    providers: [],
    styleUrls: ['./local-experts-list.scss'],
    templateUrl: './local-experts-list.html'
})
export class UsersLocalExpertsListComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly AVOIDED_ACADEMIC_INSTITUTION_IDS: number[] = [
        AcademicInstitution.ID_NONE,
        AcademicInstitution.ID_OTHER
    ];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public institutions: AcademicInstitution[] = [];

    public columns: string[] = [];

    @Input() public title: string = null;
    @Input() public addTitle: string = 'Add';
    @Input() public noDataMessage: string = 'No_data_yet';
    @Input() public isAddEnabled: boolean = false;
    @Input() public hideNameColumn: boolean = false;
    @Input() public hideEmailColumn: boolean = false;
    @Input() public hideCreateDateColumn: boolean = false;
    @Input() public hideActionsColumns: boolean = false;
    @Input() public hideInstitutionsColumn: boolean = false;
    @Output() public onRowClicked: EventEmitter<LocalExpertRow> = new EventEmitter<LocalExpertRow>();
    @Output() public onAddClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onDeleteRowClicked: EventEmitter<LocalExpertRow> = new EventEmitter<LocalExpertRow>();
    @Output() public onAcademicInstitutionsChanged: EventEmitter<LocalExpertRow> = new EventEmitter<LocalExpertRow>();
    @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) public sorter: MatSort;
    public addMenu: ModuleMenu = null;
    private _hasChanges: any = {};

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _dialoger: MatDialog,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _router: Router,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _users: User[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set users(users: User[]) {
        this._users = users;
        this.loadData(users).catch((error: any) => this.onError(error));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.initColumns();
        this.initDataSource();
        this.addMenu = {
            icon: 'add',
            title: this.addTitle,
            url: null,
            onClick: () => this.onAddClicked.emit()
        };
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    public stopPropagation(event: any): void {
        event.stopPropagation();
    }

    public onRowLocalExpertChanged(row: LocalExpertRow, detailsRow: InstitutionLocalExpertRow): void {
        this._hasChanges[row.user.id] = true;
        // si il n'est plus expert, on s'assure qu'il ne soit plus dispatcher non plus
        if (!detailsRow.isLocalExpert && detailsRow.isDispatcher) {
            detailsRow.isDispatcher = false;
        }

        this.updateRow(row);
    }

    public onRowDispatcherChanged(row: LocalExpertRow, detailsRow: InstitutionLocalExpertRow): void {
        this._hasChanges[row.user.id] = true;
        // si il est dispatcher, on s'assure qu'il soit aussi local expert
        if (detailsRow.isDispatcher && !detailsRow.isLocalExpert) {
            detailsRow.isLocalExpert = true;
        }

        this.updateRow(row);
    }

    public _onAcademicInstitutionsChanged(row: LocalExpertRow): void {
        if (this._hasChanges[row.user.id]) {
            this._hasChanges[row.user.id] = false;
            this.onAcademicInstitutionsChanged.emit(row);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateRow(row: LocalExpertRow): void {
        const expertInstitutions: AcademicInstitution[] = [];
        row.institutionDetails.forEach((detail: InstitutionLocalExpertRow) => {
            if (detail.isLocalExpert) {
                expertInstitutions.push(detail.institution);
            }
        });
        row.expertInstitutions = expertInstitutions;
    }

    private initColumns(): void {
        const columns: string[] = [];
        if (!this.hideNameColumn) {
            columns.push('name');
        }
        if (!this.hideEmailColumn) {
            columns.push('email');
        }
        if (!this.hideInstitutionsColumn) {
            columns.push('institution');
        }
        if (!this.hideCreateDateColumn) {
            columns.push('createDate');
        }
        if (!this.hideActionsColumns) {
            columns.push('actions');
        }

        this.columns = columns;
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.paginator = this.paginator;

        this.dataSource.addSortDefinition(
            'name',
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase(), b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase()),
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(b.user.firstName.toLowerCase() + b.user.lastName.toLowerCase(), a.user.firstName.toLowerCase() + a.user.lastName.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'email',
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(a.user.email.toLowerCase(), b.user.email.toLowerCase()),
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(b.user.email.toLowerCase(), a.user.email.toLowerCase())
        );

        this.dataSource.addSortDefinition(
            'createDate',
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(a.user.createDate, b.user.createDate),
            (a: LocalExpertRow, b: LocalExpertRow) => this.utils.sortRow(b.user.createDate, a.user.createDate)
        );
    }

    private loadData(users: User[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(AcademicInstitution).orderBy('ordinal').get(),
                this._storage.select(User_AcademicInstitution).where('userId').in(this.utils.getKeyValues(users)).get()
            ])
                .then((data: any[]) => {
                    this.institutions = data[0];
                    const rows: LocalExpertRow[] = [];
                    users.forEach((user: User) => {
                        const user_institutions: User_AcademicInstitution[] = this.utils.findManyIn([user.id], data[1], 'userId');
                        const institutions: InstitutionLocalExpertRow[] = [];
                        const expertInstitutions: AcademicInstitution[] = [];
                        this.institutions.forEach((institution: AcademicInstitution) => {
                            const user_institution: User_AcademicInstitution = this.utils.findIn(institution.id, user_institutions, 'academicInstitutionId');
                            if (user_institution) {
                                expertInstitutions.push(institution);
                            }
                            institutions.push({
                                institution,
                                isLocalExpert: user_institution != null,
                                isDispatcher: user_institution != null && user_institution.isDispatcher
                            });
                        });
                        const rights: UserRights = new UserRights(this.utils);
                        rights.evaluate({
                            user: this.currentUser.user,
                            subjectUser: user
                        });
                        rows.push({
                            user,
                            rights,
                            institutionDetails: institutions,
                            expertInstitutions
                        });
                    });
                    this.dataSource.replaceAllWith(rows);

                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}
