import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { UsersProvider } from '../../providers/users-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { DiscussionsProvider } from '../../../discussions/providers/discussions-provider';
import { User } from '../../../_common/providers/models';

@Component({
    selector: 'users-user-profile-dialog',
    providers: [],
    styleUrls: ['./user-profile-dialog.scss'],
    templateUrl: './user-profile-dialog.html'
})
export class UsersUserProfileDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public user: User = new User();
    public userLinksData: any[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public dialog: MatDialogRef<UsersUserProfileDialogComponent>,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _discussions: DiscussionsProvider,
        private _users: UsersProvider,
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data.user) {
            this.user = this._data.user;
            this.loadUserLinks();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onDiscussClicked(): void {
        this._discussions.startOneToOneDiscussion(this.user)
            .catch((error: any) => this.onError(error));
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadUserLinks(): void {
        this._users.getUserUserLinksData(this.user.id, false)
            .then((data: any[]) => this.userLinksData = data)
            .catch((error: any) => this.onError(error));
    }

    private onError(error: any): void {
        console.log(error);
    }
}
