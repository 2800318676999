import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Company, InvestorProfile, InvestorValidationState, ProjectMembership } from '../../../_common/providers/models';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { Conf } from '../../../_conf';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'acventures-common-home-welcome',
    providers: [],
    styleUrls: ['./home-welcome.scss'],
    templateUrl: './home-welcome.html'
})
export class AcventuresCommonHomeComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public tryRedirection: boolean = true;
    public companies: Company[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _router: Router,
        private _route: ActivatedRoute,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        if (this.tryRedirection && this.currentUser.isLoggedIn()) {
            this.tryRedirect();
        } else {
            this.loadData().catch((error) => this.onError(error));
        }

        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._storage.select(Company).where('isDisplayedOnHomepage').equals(1).get()
                .then((companies: Company[]) => {
                    this.companies = companies.filter((company) => company.logo);
                })
                .catch(reject);
        });
    }

    private tryRedirect(): void {
        if (this.currentUser.user.isProjectHolder) {
            this._storage.select(ProjectMembership).where('userId').equals(this.currentUser.user.id).get(1)
                .then((membership: ProjectMembership) => {
                    if (membership) {
                        this._router.navigate(['academic-ventures', membership.projectId, 'dashboard']);
                    } else {
                        this._router.navigate(['academic-ventures', 'welcome']);
                    }
                })
                .catch((error: any) => this.onError(error));
        } else if (this.currentUser.user.isInvestor) {
            this._storage.select(InvestorProfile).where('userId').equals(this.currentUser.user.id).get(1)
                .then((investorProfile: InvestorProfile) => {
                    if (investorProfile.investorValidationStateId === InvestorValidationState.ID_ACCEPTED) {
                        this._router.navigate(['investors', 'dashboard']);
                    } else {
                        this._router.navigate(['welcome']);
                    }
                })
                .catch((error: any) => this.onError(error));
        } else if (this.currentUser.user.isGuest) {
            this._router.navigate(['academic-ventures']);
        } else if (this.currentUser.user.isAdmin) {
            this._router.navigate(['academic-ventures']);
        } else if (this.currentUser.user.isLocalExpert) {
            this._router.navigate(['academic-ventures']);
        }
    }

    private onError(error: any): void {
        console.log(error);
    }
}
