<module-content>
    <div class="line">
        <mat-form-field [hintLabel]="''">
            <mat-select (selectionChange)="onAcademicInstitutionChanged()"
                        [formControl]="academicInstitution1FormControl"
                        [placeholder]="('Academic_institution_from' | translate)"
                        id="step0_academicInstitution1">
                <ng-container *ngFor="let item of academicInstitionGroups">
                    <mat-optgroup *ngIf="EXCLUDED_INSTITUTION1_GROUP_IDS.indexOf(item.group.id) < 0"
                                  [label]="item.group.name">
                        <mat-option *ngFor="let institution of item.institutions"
                                    [disabled]="institution.id == academicInstitution2FormControl.value"
                                    [value]="institution.id">
                            {{ institution.name }}
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="academicInstitution1FormControl.value == INSTITUTION_OTHER"
                        [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="customAcademicInstitutionFormControl"
                   [maxlength]="CUSTOM_INSTITUTION_MAX_LENGTH"
                   [placeholder]="'Academic_institution_name' | translate"
                   matInput
                   type="text">
            <mat-error
                *ngIf="customAcademicInstitutionFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ customAcademicInstitutionFormControl.value?.length }}
                /{{ CUSTOM_INSTITUTION_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''">
            <mat-select (selectionChange)="onAcademicInstitutionChanged()"
                        [formControl]="academicInstitution2FormControl"
                        [placeholder]="'Other_acamedic_institution_from' | translate">
                <mat-optgroup *ngFor="let item of academicInstitionGroups"
                              [label]="item.group.name">
                    <mat-option *ngFor="let institution of item.institutions"
                                [disabled]="institution.id == academicInstitution1FormControl.value"
                                [value]="institution.id">
                        {{ institution.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="academicInstitution2FormControl.value == INSTITUTION_OTHER"
                        [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="customAcademicInstitutionFormControl"
                   [maxlength]="CUSTOM_INSTITUTION_MAX_LENGTH"
                   [placeholder]="'Academic_institution_name' | translate"
                   matInput
                   type="text">
            <mat-error
                *ngIf="customAcademicInstitutionFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ customAcademicInstitutionFormControl.value?.length }}
                /{{ CUSTOM_INSTITUTION_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <p class="notice">
        {{ 'Academic_link_notice' | translateCut:{index: 0} }}
        <a href="https://switt.ch/about-switt/institutions"
           target="_blank">{{ 'Academic_link_notice' | translateCut:{index: 1} }}</a>
        {{ 'Academic_link_notice' | translateCut:{index: 2} }}
    </p>
    <div class="line">
        <mat-checkbox (change)="onIsInstitutionLicenseOwnedChanged()"
                      [(ngModel)]="isInstitutionLicenseOwned"
                      [disabled]="!rights.EDIT"
                      color="primary">
            <span>{{ 'My_company_owns_a_license_from_institution' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="line">
        <mat-checkbox (change)="onIsTTOAgreementAcceptedChanged()"
                      [(ngModel)]="isTTOAgreementAccepted"
                      [disabled]="!rights.EDIT"
                      color="primary"
                      id="step_0_tto">
            <span>{{ 'I_confirm_TTO_stuff' | translate }}</span>
        </mat-checkbox>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onIsTTOAgreementAcceptedChanged()"
                   [formControl]="ttoContactFormControl"
                   [maxlength]="CUSTOM_INSTITUTION_MAX_LENGTH"
                   [placeholder]="'My_TTO_contact' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="ttoContactFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ ttoContactFormControl.value?.length }}
                /{{ CUSTOM_INSTITUTION_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
</module-content>
