import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../providers/current-user';
import { MenuItem } from '../side-menu/side-menu';
import { ModelsStorage } from '../../providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { IProjectCaseSearchResultRow, ProjectsProvider } from '../../../projects/providers/projects-provider';
import { ProjectRights } from '../../../projects/providers/project-rights';
import {
    InvestorProfile,
    InvestorValidationState,
    Project,
    ProjectCase,
    ProjectValidationState,
} from '../../providers/models';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog';

@Component({
    template: ''
})
export class ListBaseComponent implements AfterViewInit {

    public readonly INVESTOR_VALIDATION_STATE_PENDING: number = InvestorValidationState.ID_PENDING;
    public readonly INVESTOR_VALIDATION_STATE_ACCEPTED: number = InvestorValidationState.ID_ACCEPTED;

    public isLoading: boolean = true;
    public rights: ProjectRights = new ProjectRights(this._utils);
    public is404: boolean = false;
    public backMenu: MenuItem = null;

    public investorProfile: InvestorProfile = null;
    public projects: Project[] = [];
    public projectCases: ProjectCase[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        public _title: Title,
        public _router: Router,
        public _dialoger: MatDialog,
        public _utils: Utils,
        public _toaster: MatSnackBar,
        public _route: ActivatedRoute,
        public _changeDetector: ChangeDetectorRef,
        public _projectsProvider: ProjectsProvider,
        public _translater: TranslateService,
        public _storage: ModelsStorage
    ) {
    }

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
    }

    public async loadData(): Promise<any> {
        try {
            this.investorProfile = await this._storage.select(InvestorProfile)
                .where('userId').equals(this.currentUser.user.id).get(1);
            this.projects = await this._storage.select(Project)
                .where('projectValidationStateId').equals(ProjectValidationState.ID_PUBLISHED)
                .orderBy('name').get();
            const projectCases = await this._storage.select(ProjectCase).get();
            this.projectCases = projectCases.filter((item) => this._utils.getKeyValues(this.projects).indexOf(item.projectId) > -1);

            this.rights.evaluate({
                user: this.currentUser.user,
                project: null,
                membership: null,
                investorProfile: this.investorProfile,
            });

        } catch (e) {
            this.onError(e);
        }
    }

    public onFavoriteClicked(row: IProjectCaseSearchResultRow): void {
        row.isLoading = true;
        this._projectsProvider.toggleProjectFavorite(row.project)
            .then((isFavorite: boolean) => {
                row.isFavorite = isFavorite;
                row.isLoading = false;
                this.onError({status: 429});
            })
            .catch((error: any) => {
                row.isLoading = false;
                this.onError(error);
            });
    }

    public onDeleteClicked(row: IProjectCaseSearchResultRow): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_project'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this._projectsProvider.deleteProjectCase(row.projectCase)
                    .then(() => {
                        this.projectCases = this.projectCases.filter((item) => item.id !== row.projectCase.id);
                        this._toaster.open(this._translater.instant('Project_deleted'), '', {duration: 5000});
                    })
                    .catch((error: any) => {
                        row.isLoading = false;
                        this.onError(error);
                    });
            }
        });
    }

    public onEditClicked(row: IProjectCaseSearchResultRow): void {
        this._router.navigate(['academic-ventures', row.project.id, 'edit'], {
            queryParams: {
                backUrl: this._router.url,
                backTitle: this._translater.instant('Search_results')
            }
        });
    }

    public onError(error: any): void {
        this.isLoading = false;

        this.is404 = error.status === 404;

        if (error.status !== 429) {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        }

    }
}
