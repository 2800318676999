<module-content [isLoading]="isLoading"
                [title]="'Overview' | translate">
    <projects-project-state-indicator [project]="project"
                                      [size]="'big'">
    </projects-project-state-indicator>
    <mat-progress-bar [value]="listingProgress"
                      mode="determinate">
    </mat-progress-bar>
    <div class="progress-legend">{{ 'Listing_completed_at_x' | translate:{progress: listingProgress} }}</div>
    <div class="actions-container">
        <button [routerLink]="'/academic-ventures/' + project?.id + '/edit'"
                color="accent"
                mat-raised-button>
            {{ 'Complete_profile' | translate }}
        </button>
    </div>
</module-content>
