import { Injectable } from '@angular/core';
import { Utils } from './utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from './api-base-service';
import { KeyValueStorage } from './key-value-storage';
import { ModelsStorage } from './models-storage';
import { User } from './models';

@Injectable()
export class CurrentUser {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public user: User;

    private _accessToken: string;
    private _loginRequest: Promise<any> = null;
    private _hasPendingRequest: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _api: ApiBaseService,
        private _keyValueStore: KeyValueStorage,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public checkEmail(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const params: Object = {email};
            this._api.call('get', 'auth/check-email', params)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    }

    public checkResetPassword(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const params: Object = {email};
            this._api.call('get', 'auth/check-reset-password', params)
                .then((result) => {
                    resolve(result.isValid);
                })
                .catch((error) => reject(error));
        });
    }

    public login(email: string, password: string, remember: boolean): Promise<any> {
        if (!this._hasPendingRequest) {
            this._hasPendingRequest = true;
            this._loginRequest = new Promise((resolve, reject) => {
                if (this.isLoggedIn()) {
                    this._hasPendingRequest = false;
                    resolve(true);
                } else {
                    this._api.call('post', 'oauth/token', {
                        grant_type: 'password',
                        client_id: this._conf.apiPasswordGrantClient,
                        client_secret: this._conf.apiPasswordGrantSecret,
                        username: email,
                        password,
                        scope: '*'
                    })
                        .then((result) => {
                            this.retrieveUser(result.access_token)
                                .then(() => {
                                    this._accessToken = result.access_token;
                                    this.saveAccessToken(remember);
                                    this._hasPendingRequest = false;
                                    resolve(true);
                                })
                                .catch((error) => {
                                    this._hasPendingRequest = false;
                                    reject({code: 2, message: 'Could not retrieve user data', originalError: error});
                                });
                        })
                        .catch((error) => {
                            this._hasPendingRequest = false;
                            reject({code: 1, message: 'Wrong email/password', originalError: error});
                        });
                }
            });
        }

        return this._loginRequest;
    }

    public tryLoginFromRemember(): Promise<boolean> {
        if (!this._hasPendingRequest) {
            this._loginRequest = new Promise((resolve, reject) => {
                this._hasPendingRequest = true;
                if (this.isLoggedIn()) {
                    this._hasPendingRequest = false;
                    resolve(true);
                } else if (this.retrieveAccessToken()) {
                    this.retrieveUser(this._accessToken)
                        .then(() => {
                            this._hasPendingRequest = false;
                            resolve(true);
                        })
                        .catch(() => {
                            this._hasPendingRequest = false;
                            resolve(false);
                        });
                } else {
                    this._hasPendingRequest = false;
                    resolve(false);
                }
            });
        }

        return this._loginRequest;
    }

    public logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const reset: any = () => {
                this.user = null;
                this._accessToken = null;
                this._keyValueStore.clear(KeyValueStorage.STORE_MEMORY);
                this._keyValueStore.clear(KeyValueStorage.STORE_SESSION);
                this._keyValueStore.clear(KeyValueStorage.STORE_LOCAL);
            };
            this._api.call('post', 'auth/logout', {}, {accessToken: this._accessToken})
                .then((result) => {
                    reset();
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reset();
                    resolve();
                });
        });
    }

    public isLoggedIn(): boolean {
        return this.user != null && this._accessToken != null;
    }

    public getAccessToken() {
        return this._accessToken;
    }

    public sendPasswordResetEmail(email: string, resetUrl: string): Promise<any> {
        return this._api.call('post', 'auth/password/reset-token', {
            email,
            resetUrl
        });
    }

    public resetPassword(email: string, password: string, token: string): Promise<any> {
        return this._api.call('post', 'auth/password/reset', {
            token,
            email,
            password,
            password_confirmation: password
        });
    }

    public register(email: string, password: string, remembe: boolean, langId: number, firstName: string, lastName: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this._api.call('post', 'auth/register', {
                email,
                password,
                firstName,
                lastName,
                langId
            })
                .then((result) => {
                    this.login(email, password, remembe)
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private saveAccessToken(remember: boolean = true): void {
        this._keyValueStore.set(
            'currentUser_accessToken',
            this._accessToken,
            remember ? KeyValueStorage.STORE_LOCAL : KeyValueStorage.STORE_SESSION
        );
    }

    private retrieveAccessToken(): boolean {
        let accessToken = this._keyValueStore.get('currentUser_accessToken', null, KeyValueStorage.STORE_LOCAL);
        if (!accessToken) {
            accessToken = this._keyValueStore.get('currentUser_accessToken', null, KeyValueStorage.STORE_SESSION);
        }

        if (accessToken) {
            this._accessToken = accessToken;
            return true;
        }

        return false;
    }

    private retrieveUser(accessToken: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this._api.call('get', 'auth/current-user', {}, {accessToken})
                .then((result) => {
                    this.user = User.fromAPI(result.user);
                    this._storage.save([this.user]);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }
}
