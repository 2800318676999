import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { ProjectsProvider } from '../../providers/projects-provider';
import { Project, ProjectValidationState, User, User_AcademicInstitution } from '../../../_common/providers/models';

@Component({
    selector: 'projects-dispatch-page',
    providers: [],
    styleUrls: ['./dispatch.scss'],
    templateUrl: './dispatch.html'
})
export class ProjectsDispatchPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public is401: boolean = false;
    public isError: boolean = false;

    public project: Project = null;
    public user: User = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
        this._route.params.subscribe((params: any) => {
            this.loadData(parseInt(params.projectId), parseInt(params.userId))
                .then(() => {
                    this.sendNotification()
                        .then(() => this.isLoading = false)
                        .catch((error: any) => this.onError(error));
                })
                .catch((error: any) => this.onError(error));
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(projectId: number, userId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.fromId(Project, [projectId]),
                this._storage.fromId(User, [userId])
            ])
                .then((data: any[]) => {
                    this.project = data[0];
                    this.user = data[1];

                    resolve();
                })
                .catch(reject);
        });
    }

    private sendNotification(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.checkRights()
                .then((isAuthorized: boolean) => {
                    if (!isAuthorized) {
                        reject({status: 401});
                        return;
                    }

                    this._projectsProvider.dispatchNotification(this.project, this.user)
                        .then(() => resolve())
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    private checkRights(): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            if (!this.project || !this.user || this.project.projectValidationStateId !== ProjectValidationState.ID_PENDING_VALIDATION) {
                resolve(false);
                return;
            }

            Promise.all([
                this._storage.select(User_AcademicInstitution)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('academicInstitutionId').equals(this.project.academicInstitution1Id)
                    .get(1),
                this._storage.select(User_AcademicInstitution)
                    .where('userId').equals(this.user.id)
                    .and('academicInstitutionId').equals(this.project.academicInstitution1Id)
                    .get(1)
            ])
                .then((data: any[]) => {
                    resolve(data[0] && data[1] && data[0].isDispatcher && !data[1].isDispatcher);
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        if (error.status === 401) {
            this.is401 = true;
            return;
        }

        this.isError = true;
        console.log(error);
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
