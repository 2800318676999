<div mat-dialog-title>{{ 'Add_users' | translate }}</div>
<mat-dialog-content>
    <div *ngIf="availableUsersDataSource.data.value.length == 0">{{ 'There_is_no_available_user' | translate }}</div>
    <ng-container *ngIf="availableUsersDataSource.data.value.length > 0">
        <div class="line">
            <div class="legend">{{ 'Name' | translate }}</div>
        </div>
        <div *ngFor="let line of availableUsersDataSource.data.value"
             class="line">
            <users-user-sticker [avatarHideable450]="true"
                                [project]="project"
                                [user]="line.user"></users-user-sticker>
            <mat-checkbox [(ngModel)]="line.selected"
                          color="primary"></mat-checkbox>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onAddClicked()"
            [disabled]="isLoading || availableUsersDataSource.data.value.length == 0 || getSelectedUsers().length == 0"
            color="accent"
            mat-raised-button>
        {{ 'Add' | translate}}
    </button>
</mat-dialog-actions>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
