import { animate, state, style, transition, trigger } from '@angular/animations';

export const translateFadeBeforeAfterAnimation = trigger('translateFadeBeforeAfter', [
    state('before', style({transform: 'translateX(-350px)', opacity: '0', height: '0px'})),
    state('active', style({transform: 'translateX(0)', opacity: '1', height: 'auto'})),
    state('after', style({transform: 'translateX(350px)', opacity: '0', height: '0px'})),
    transition('before => active', animate('200ms linear')),
    transition('active => before', animate('200ms linear')),
    transition('active => after', animate('200ms linear')),
    transition('after => active', animate('200ms linear'))
]);
