import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectPastFunding, ProjectPrize } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-funding',
    providers: [],
    styleUrls: ['./edit-project-funding.scss'],
    templateUrl: './edit-project-funding.html'
})
export class ProjectsEditProjectFundingComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly PRIZE_MAX_LENGTH: number = 250;
    public readonly SOURCE_MAX_LENGTH: number = 250;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public prizesFormControls: prizeFormControl[] = [];
    public pastFundingsFormControls: pastFoundingFormControls[] = [];
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() public prizesChange: EventEmitter<ProjectPrize[]> = new EventEmitter<ProjectPrize[]>();
    @Output() public pastFundingsChange: EventEmitter<ProjectPastFunding[]> = new EventEmitter<ProjectPastFunding[]>();
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    private _isPrizesEdited: boolean = false;
    private _isPastFundingsEdited: boolean = false;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.updateRights();
    }

    private _prizes: ProjectPrize[] = [];

    public get prizes() {
        return this._prizes;
    }

    @Input()
    public set prizes(prizes: ProjectPrize[]) {
        if (prizes) {
            this._prizes = prizes;
            if (!this._isPrizesEdited) { // si on vérifie pas ce flag, on reset le formulaire à chaque fois qu'on tappe dedans
                this.buildPrizesFormControls();
                this.updateRights();
            }
        }
    }

    private _pastFundings: ProjectPastFunding[] = [];

    public get pastFundings() {
        return this._pastFundings;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set pastFundings(pastFundings: ProjectPastFunding[]) {
        if (pastFundings) {
            this._pastFundings = pastFundings;
            if (!this._isPastFundingsEdited) { // si on vérifie pas ce flag, on reset le formulaire à chaque fois qu'on tappe dedans
                this.buildPastFundingFormControls();
                this.updateRights();
            }
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        for (let i = 0; i < this.prizesFormControls.length; i++) {
            if (this.prizesFormControls[i].nameFormControl.invalid) {
                return false;
            }
        }

        for (let i = 0; i < this.pastFundingsFormControls.length; i++) {
            if (this.pastFundingsFormControls[i].amountFormControl.invalid || this.pastFundingsFormControls[i].sourceFormControl.invalid) {
                return false;
            }
        }

        return true;
    }

    public onAddPrizeClicked(): void {
        this.prizesFormControls.push({
            prizeId: 0,
            nameFormControl: new FormControl('', [
                Validators.maxLength(this.PRIZE_MAX_LENGTH)
            ])
        });
    }

    public onRemovePrizeClicked(index: number): void {
        this.prizesFormControls.splice(index, 1);
        this.onPrizesFormChanged();
    }

    public onAddPastFundingClicked(): void {
        this.pastFundingsFormControls.push({
            pastFoundingId: 0,
            sourceFormControl: new FormControl('', [
                Validators.maxLength(this.SOURCE_MAX_LENGTH)
            ]),
            amountFormControl: new FormControl('', [
                Validators.min(0)
            ])
        });
    }

    public onRemovePastFundingClicked(index: number): void {
        this.pastFundingsFormControls.splice(index, 1);
        this.onPastFundingsFormChanged();
    }

    public onPrizesFormChanged(): void {
        this._isPrizesEdited = true;
        this.fireChangePrizes();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onPastFundingsFormChanged(): void {
        this._isPastFundingsEdited = true;
        this.fireChangePastFundings();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.prizesFormControls.forEach((control: prizeFormControl) => control.nameFormControl.enable());
            this.pastFundingsFormControls.forEach((control: pastFoundingFormControls) => {
                control.amountFormControl.enable();
                control.sourceFormControl.enable();
            });
        } else {
            this.prizesFormControls.forEach((control: prizeFormControl) => control.nameFormControl.disable());
            this.pastFundingsFormControls.forEach((control: pastFoundingFormControls) => {
                control.amountFormControl.disable();
                control.sourceFormControl.disable();
            });
        }
    }

    private buildPrizesFormControls(): void {
        const controls: prizeFormControl[] = [];
        for (let i = 0; i < this._prizes.length; i++) {
            const control: prizeFormControl = {
                prizeId: this._prizes[i].id,
                nameFormControl: new FormControl('', [
                    Validators.maxLength(this.PRIZE_MAX_LENGTH)
                ])
            };
            control.nameFormControl.setValue(this._prizes[i].name);

            controls.push(control);
        }

        this.prizesFormControls = controls;

        if (this.prizesFormControls.length === 0) {
            this.onAddPrizeClicked();
        }
    }

    private buildPastFundingFormControls(): void {
        const controls: pastFoundingFormControls[] = [];
        for (let i = 0; i < this._pastFundings.length; i++) {
            const control: pastFoundingFormControls = {
                pastFoundingId: this._pastFundings[i].id,
                sourceFormControl: new FormControl('', [
                    Validators.maxLength(this.SOURCE_MAX_LENGTH)
                ]),
                amountFormControl: new FormControl('', [
                    Validators.min(0),
                    (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
                ])
            };
            control.sourceFormControl.setValue(this._pastFundings[i].source);
            control.amountFormControl.setValue(this._pastFundings[i].amount);
            controls.push(control);
        }

        this.pastFundingsFormControls = controls;

        if (this.pastFundingsFormControls.length === 0) {
            this.onAddPastFundingClicked();
        }
    }

    private fireChangePrizes(): void {
        const prizes: ProjectPrize[] = [];
        for (let i = 0; i < this.prizesFormControls.length; i++) {
            const prizeName: string = this.prizesFormControls[i].nameFormControl.value ? this.prizesFormControls[i].nameFormControl.value.trim() : '';
            if (prizeName.length) {
                const prize: ProjectPrize = new ProjectPrize();
                prize.id = this.prizesFormControls[i].prizeId;
                prize.projectId = this._project.id;
                prize.name = prizeName;
                prizes.push(prize);
            }
        }
        this._prizes = prizes;

        this.prizesChange.emit(prizes);
    }

    private fireChangePastFundings(): void {
        const fundings: ProjectPastFunding[] = [];
        for (let i = 0; i < this.pastFundingsFormControls.length; i++) {
            const sourceName: string = this.pastFundingsFormControls[i].sourceFormControl.value ? this.pastFundingsFormControls[i].sourceFormControl.value.trim() : '';
            const amount: number = this.pastFundingsFormControls[i].amountFormControl.value ? this.pastFundingsFormControls[i].amountFormControl.value : null;
            if (sourceName.length || amount) {
                const funding: ProjectPastFunding = new ProjectPastFunding();
                funding.id = this.pastFundingsFormControls[i].pastFoundingId;
                funding.projectId = this._project.id;
                funding.source = sourceName;
                funding.amount = amount;
                fundings.push(funding);
            }
        }
        this._pastFundings = fundings;

        this.pastFundingsChange.emit(this._pastFundings);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}

export interface prizeFormControl {
    prizeId: number;
    nameFormControl: FormControl;
}

export interface pastFoundingFormControls {
    pastFoundingId: number;
    sourceFormControl: FormControl;
    amountFormControl: FormControl;
}
