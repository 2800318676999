import { Injectable } from '@angular/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { CurrentUser } from '../../_common/providers/current-user';
import { LikeCounter_User } from '../../_common/providers/models';

@Injectable()
export class LikeCountersProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _api: ApiBaseService,
        private _storage: ModelsStorage,
        private _currentUser: CurrentUser
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public updateLike(api: string, like: boolean): Promise<LikeCounter_User> {
        return new Promise<any>((resolve, reject) => {
            this._api.call(
                'post',
                api,
                {
                    like: like ? 1 : 0
                },
                this._currentUser.getAccessToken()
            )
                .then((result: any) => {
                    const item: LikeCounter_User = LikeCounter_User.fromAPI(result.likeCounter_user);
                    this._storage.save([item])
                        .then(() => resolve(item))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
