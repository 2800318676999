<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]=""
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isInvestor"></error-401>
    <div *ngIf="currentUser.user.isInvestor"
         class="wrapper">
        <module-content>

            <div class="header">
                <div>
                    <div
                        class="title">{{ 'Investor_welcome_title' | translate:{name: currentUser.user.firstName} }}</div>
                    <div class="subtitle">{{ 'Investor_welcome_subtitle1' | translate }}</div>
                </div>
            </div>
            <div *ngIf="company && investorProfile" class="status-section">
                <mat-divider></mat-divider>
                <div class="completed-banner">
                    <div>{{ 'User profile' | translate }}</div>
                    <button [routerLink]="'/users/' + currentUser.user.id + '/edit'"
                            class="welcome-action"
                            color="accent"
                            disabled="{{ isProfileCompleted() }}"
                            mat-raised-button>
                        {{ isProfileCompleted() ? ('completed'  | translate) : ('Complete_profile' | translate) }}
                    </button>
                </div>
                <mat-divider></mat-divider>
                <div class="completed-banner">
                    <div>{{ company.name }}</div>
                    <button [routerLink]="'/investors/companies/' + investorProfile.companyId + '/edit'"
                            class="welcome-action"
                            color="accent"
                            disabled="{{ company.isCompleted === 1 }}"
                            mat-raised-button>
                        {{ company.isCompleted ? ('completed'  | translate) : ('complete_company_action' | translate) }}
                    </button>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="sections">
                <div class="tips-title">{{ 'Investor_welcome_sections_title' | translate }}:</div>
                <div class="section">
                    <mat-icon color="primary">star</mat-icon>
                    <div>{{ 'Investor_welcome_section1' | translate }}</div>
                </div>
                <div class="section">
                    <mat-icon color="primary">notifications</mat-icon>
                    <div>{{ 'Investor_welcome_section2' | translate }}</div>
                </div>
            </div>

        </module-content>
    </div>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
