import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Conf } from '../../../_conf';

export const INPUT_IMAGE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputImageComponent),
    multi: true
};

// doc pour le ngModel : http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'input-image',
    providers: [INPUT_IMAGE_VALUE_ACCESSOR],
    styleUrls: ['./input-image.scss'],
    templateUrl: './input-image.html'
})
export class InputImageComponent implements AfterViewInit, ControlValueAccessor {

    //
    //
    // CONSTANTS
    //
    //

    public ERROR_EXTENSION: number = 1;
    public ERROR_SIZE: number = 2;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @ViewChild('fileInput', {static: false}) public fileInput;
    @ViewChild('textInput', {static: false}) public textInput;

    @Input() public previewImage: any;
    public imageConf: ImageData = {current: null, new: null};
    public errorCode: number = 0;

    @Input() public placeholder: string;
    @Input() public hint: string = null;
    @Input() public hintLeft: string = null;
    @Input() public imageHost: string = null;
    @Input() public allowedExtensions: string[] = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];
    @Input() public maxFileSize: number = 0; // in Mo, 0 or less = unlimited
    @Output() public imageChange: EventEmitter<any> = new EventEmitter();
    @Output('change') public change: EventEmitter<any> = new EventEmitter();
    public imageFormControl: FormControl = new FormControl('', [
        // doc : https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html#building-a-custom-validator
        (control: any) => {
            return this.errorCode !== this.ERROR_EXTENSION ? null : {extension: {valid: false}};
        },
        (control: any) => {
            return this.errorCode === this.ERROR_SIZE ? {size: {valid: false}} : null;
        }
    ]);
    private _required: boolean = false;

    constructor(
        public conf: Conf
    ) {
    }

    private _disabled: boolean = false;

    public get disabled() {
        return this._disabled;
    }

    @Input()
    public set disabled(disabled: boolean) {
        this._disabled = disabled;
        if (this._disabled) {
            this.imageFormControl.disable();
        } else {
            this.imageFormControl.enable();
        }
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set isRequired(required: boolean) {
        this._required = required;
        if (this._required) {
            this.imageFormControl.setValidators(Validators.required);
            this.imageFormControl.updateValueAndValidity();
        } else {
            this.imageFormControl.clearValidators();
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    public writeValue(value: any): void {
        if (value) {
            this.imageConf.current = value.current;
            this.imageConf.new = value.new;
        } else {
            this.imageConf.current = null;
            this.imageConf.new = null;
        }
    }

    @HostListener('imageChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onFocus(): void {
        if (this.disabled) {
            return;
        }

        this.openFileBrowser();
    }

    public onTextChange(): void {
        if (this.disabled) {
            return;
        }

        this.openFileBrowser();
        this.imageFormControl.setValue('');
    }

    public onRemoveImageClicked(): void {
        if (this.disabled) {
            return;
        }

        this.removeImage();
    }

    public onFileSelectionChange(event): void {
        if (this.disabled) {
            return;
        }

        const files: FileList = event.target.files;
        if (files.length) {
            this.selectFile(files[0]);
        }
    }

    public getSelectedImage(): File {
        return this.imageConf.new;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private openFileBrowser(): void {
        this.fileInput.nativeElement.click();
    }

    private selectFile(file: File): void {
        const fileExtension: string = this.getFileExtension(file);
        if (this.allowedExtensions.indexOf(fileExtension) < 0) {
            this.onError(this.ERROR_EXTENSION, fileExtension);
            return;
        }

        const fileSize: number = file.size / 1048576; // converson o => Mo
        if (this.maxFileSize && fileSize > this.maxFileSize) {
            this.onError(this.ERROR_SIZE, fileSize);
            return;
        }

        this.errorCode = 0;
        this.imageConf.new = file;
        const reader: FileReader = new FileReader();
        reader.onload = () => {
            this.previewImage = reader.result;
        };
        reader.readAsDataURL(file);
        this.onChange(this.imageConf);
    }

    private removeImage(): void {
        this.fileInput.nativeElement.value = null;
        this.imageConf.new = null;
        this.imageConf.current = null;
        this.previewImage = null;
        this.onChange(this.imageConf);
        this.change.emit(this.imageConf);
    }

    private getFileExtension(file: File): string {
        const parts: string[] = file.name.split('.');

        return parts[parts.length - 1];
    }

    private onError(errorCode: number, extra: any): void {
        this.errorCode = errorCode;
        if (this.textInput) {
            this.textInput.nativeElement.blur();
        }
        this.imageFormControl.updateValueAndValidity();
    }
}

export interface ImageData {
    current: string;
    // on peut pas explicitement mettre un type File parce que
    // sinon on peut pas attribuer cette valeur � un model
    // dont le champs file est d�clar� en type string
    // new: File;
    new: any;
}
