import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTradeType } from '../../providers/models';
import { ModelsStorage } from '../../providers/models-storage';
import { MatSnackBar } from '@angular/material/snack-bar';

export const PUBLIC_PRIVATE_RADIO_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PublicPrivateRadioComponent),
    multi: true
};

@Component({
    selector: 'public-private-radio',
    providers: [PUBLIC_PRIVATE_RADIO_VALUE_ACCESSOR],
    styleUrls: ['./public-private-radio.scss'],
    templateUrl: './public-private-radio.html'
})
export class PublicPrivateRadioComponent implements AfterViewInit, ControlValueAccessor {

    @Input() public label: string = '';
    @Input() public color: string = 'primary';
    @Input() public disabled: boolean = false;
    @Output() public valueChange: EventEmitter<number> = new EventEmitter();
    @Output('change') public change: EventEmitter<number> = new EventEmitter();

    public projectTradeTypes: ProjectTradeType[] = [];

    constructor(
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _toaster: MatSnackBar,
    ) {
    }

    private _internalValue: number = null;

    public get internalValue() {
        return this._internalValue;
    }

    public set internalValue(val: number) {
        this._internalValue = val;
        this.onChange(val);
        this.change.emit(val);
    }

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    public writeValue(value: any): void {
        this._internalValue = value;
    }

    @HostListener('valChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    private async loadStaticData(): Promise<any> {
        try {
            this.projectTradeTypes = await this._storage.select(ProjectTradeType).get();
        } catch (e) {
            this.onError(e);
        }
    }

    private onError(error: any): void {
        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
