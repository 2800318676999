import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectVisibility } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-miscellaneous',
    providers: [],
    styleUrls: ['./edit-project-miscellaneous.scss'],
    templateUrl: './edit-project-miscellaneous.html'
})
export class ProjectsEditProjectMiscellaneousComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public projectVisibilityId: number = null;
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    public projectVisibilities: ProjectVisibility[] = [];

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;

        this.projectVisibilityId = project.projectVisibilityId;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public canSave(): boolean {
        return true;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(ProjectVisibility).get()
            ])
                .then((data: any[]) => {
                    this.projectVisibilities = data[0];
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private fireChange(): void {
        this._project.projectVisibilityId = this.projectVisibilityId;

        this.projectChange.emit(this._project);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
