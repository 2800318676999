import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { CurrentUser } from '../../../_common/providers/current-user';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project, ProjectMembership, User } from '../../../_common/providers/models';
import { InviteUserDialogComponent } from '../../../project-membership/invite-user-dialog/invite-user-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersProvider } from '../../../users/providers/users-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import {
    ProjectMembershipRow,
    ProjectsMembershipsProvider
} from '../../../project-membership/providers/projects-memberships-provider';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'projects-memberships-page',
    providers: [],
    styleUrls: ['./memberships.scss'],
    templateUrl: './memberships.html'
})
export class ProjectsMembershipsPageComponent implements AfterViewInit {
    public isLoading: boolean = true;
    public is404: boolean = false;

    public backMenu: MenuItem = null;
    public backUrl: string = null;
    public backTitle: string = this._translater.instant('Back');

    public rights: ProjectRights = new ProjectRights(this._utils);
    public projectMemberships: ProjectMembershipRow[] = [];
    public project: Project = null;
    private _membership: ProjectMembership = null;

    private projectId: number;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _utils: Utils,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _route: ActivatedRoute,
        private _router: Router,
        private dialog: MatDialog,
        private _projectMembershipsProvider: ProjectsMembershipsProvider,
        private userProvider: UsersProvider,
        private _toaster: MatSnackBar,
    ) {
    }

    public ngAfterViewInit() {
        this._route.params.subscribe((params: any) => {
            this.projectId = +params.projectId;
            this.isLoading = true;
            this.loadData()
                .then(() => {
                    this._title.setTitle(this.project.name + ' | ' + this.conf.platformName);
                    this.isLoading = false;
                })
                .catch((error: any) => this.onError(error));
        });
        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backUrl = params.backUrl;
                if (params.backTitle) {
                    this.backTitle = params.backTitle;
                } else {
                    this.backTitle = this._translater.instant('Back');
                }
                this.backMenu = {
                    icon: 'arrow_back',
                    title: this.backTitle,
                    url: this.backUrl
                };
            }
        });

        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
    }

    public onDeleteClicked(row: ProjectMembershipRow) {
        const dialog: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'ProjectConfirmDeleteMembership',
                confirmActionText: 'Delete',
                confirmColor: 'warn'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                row.isLoading = true;
                this._projectMembershipsProvider.remove(row.membership)
                    .then((result) => {
                        for (let i = 0; i < this.projectMemberships.length; i++) {
                            if (this.projectMemberships[i].user.id === row.user.id) {
                                this.projectMemberships.splice(i, 1);
                                i = this.projectMemberships.length;
                            }
                        }
                        this.projectMemberships = this.projectMemberships.slice();
                        this._toaster.open(this._translater.instant('Project_membership_deleted'), '', {duration: 5000});
                        row.isLoading = false;
                        if (row.membership.userId === this.currentUser.user.id) {
                            window.location.href = '';
                        }
                    })
                    .catch((error) => {
                        this._toaster.open(error.error.message, '', {duration: 5000});
                    });
            }
        });
    }

    /**
     * Open a dialog to add a user to the users project.
     */
    inviteUserDialog(): void {
        const dialogRef = this.dialog.open(InviteUserDialogComponent, {
            width: '412px',
            data: {
                projectId: this.projectId
            }
        });

        dialogRef.afterClosed().subscribe((data: User) => {
            if (data) {
                this.isLoading = true;
                this.userProvider.invite(data, this.projectId)
                    .then((result: any) => {
                        const rights: ProjectRights = new ProjectRights(this._utils);
                        rights.evaluate({
                            membership: this._membership,
                            project: this.project,
                            user: this.currentUser.user
                        });
                        this.projectMemberships.push({
                            membership: result.membership,
                            project: this.project,
                            user: result.user,
                            rights
                        });
                        this.projectMemberships = this.projectMemberships.slice();

                        const message = result.isNewUser ? 'Invitation_link_sent' : 'User_successfully_added';
                        this._toaster.open(this._translater.instant(message), '', {duration: 5000});
                        this.isLoading = false;
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(User).get(),
                this._storage.select(ProjectMembership).where('projectId').equals(this.projectId).get(),
                this._storage.fromId(Project, [this.projectId]),
                this._storage.select(ProjectMembership)
                    .where('userId').equals(this.currentUser.user.id)
                    .and('projectId').equals(this.projectId)
                    .get(1)
            ])
                .then((data: any[]) => {
                    this.project = data[2];
                    if (!this.project) {
                        reject({status: 404});
                        return;
                    }

                    const rows: ProjectMembershipRow[] = [];
                    data[1].forEach((membership: ProjectMembership, i: number) => {
                        const rights: ProjectRights = new ProjectRights(this._utils);
                        rights.evaluate({
                            membership: data[3],
                            project: this.project,
                            user: this.currentUser.user
                        });
                        rows.push({
                            membership,
                            project: this.project,
                            user: this._utils.findIn(membership.userId, data[0]),
                            rights
                        });
                    });
                    this.projectMemberships = rows;

                    this._membership = data[3];
                    this.rights.evaluate({
                        user: this.currentUser.user,
                        project: this.project,
                        membership: this._membership
                    });

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        if (error.status === 404) {
            this.is404 = true;
        } else if (error.status === 422 && error.error && error.error.errors && error.error.errors.email) {
            this._toaster.open(this._translater.instant('User_cannot_be_added_to_project_error'), '', {duration: 7500});
        } else if (error.status === 400) {
            this._toaster.open(this._translater.instant('User_is_already_member'), '', {duration: 7500});
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
            console.log(error);
        }
    }
}
