import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Conf } from '../../_conf';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class HasPendingChangesGuard implements CanActivate, CanDeactivate<any> {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    /*
    *   { url: { warned: boolean, nextUrl: string }, } // false = pas encore warné, true = déjà warné
    */
    private _changes: Object = {};

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService,
        private _router: Router,
        private _conf: Conf,
        private _dialoger: MatDialog
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.hasPendingChanges(route.url.join('/'));
    }

    public canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> {
        if (!nextState) {
            return new Promise((resolve, reject) => resolve(true));
        }

        return this.hasPendingChanges(nextState.url);
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public registerChanges(url: string = null): void {
        if (!url) {
            url = this._router.url;
        }
        this._changes[url] = {warned: false, nextUrl: ''};
    }

    public unregisterChanges(url: string = null): void {
        if (!url) {
            url = this._router.url;
        }
        delete this._changes[url];
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private hasPendingChanges(nextUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // s'il n'y a pas de clé, c'est qu'il n'y a pas de changement
            // s'il y une clé, c'est qu'on surveille les changements
            // si cette clé vaut false, on doit warner l'utilisateur avant de laisser naviguer
            // si cette clé vaut true, on doit le laisser naviguer et supprimer la clé
            const url = this._router.url;
            if (this._changes.hasOwnProperty(url) && !this._changes[url].warned) {
                // this._changes[url].nextUrl = route.url.join('/');
                this._changes[url].nextUrl = nextUrl;
                this.warn();
                resolve(false);
            } else {
                resolve(true);
            }
        });
    }

    private warn(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'PendingChangesWarning'
            }
        });
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.onLeaveConfirmed();
            }
        });
    }

    private onLeaveConfirmed(): void {
        const nextUrl = this._changes[this._router.url].nextUrl;
        this.unregisterChanges();
        this._router.navigateByUrl(nextUrl);
    }

}
