import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { CompanyDataRow, InvestorsProvider } from '../../providers/investors-provider';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import {
    Company,
    Company_DevelopmentPhase,
    Company_ProjectType,
    Company_TherapeuticArea,
    CompanyCompanyCategory,
    CompanyContact,
    DevelopmentPhase,
    InvestorProfile,
    ProjectType,
    TherapeuticArea
} from '../../../_common/providers/models';
import { CompanyRights } from '../../providers/company-rights';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { SimpleMatDataSource } from '../../../_common/providers/simple-mat-data-source';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-company-page',
    providers: [],
    styleUrls: ['./company.scss'],
    templateUrl: './company.html'
})
export class InvestorsCompanyPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = true;
    public is404: boolean = false;
    public is401: boolean = false;

    public backMenu: MenuItem = {
        icon: 'back',
        title: this._translater.instant('Companies'),
        url: '/investors/companies'
    };

    public companyData: CompanyDataRow = null;

    public categories: CompanyCompanyCategory[] = [];
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhases: DevelopmentPhase[] = [];
    public projectTypes: ProjectType[] = [];
    public rights: CompanyRights = new CompanyRights(this.utils);

    public dataSource: SimpleMatDataSource = new SimpleMatDataSource([]);
    public columns: string[] = ['name', 'email', 'phone'];

    @ViewChild(MatSort, {static: false}) public sorter: MatSort;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        public utils: Utils,
        public investorsProvider: InvestorsProvider,
        private _title: Title,
        private _router: Router,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backMenu = {
                    icon: 'arrow_back',
                    title: params.backTitle || this._translater.instant('Back'),
                    url: params.backUrl || '/investors/companies'
                };
                this._changeDetector.detectChanges();
            }
        });
        this._route.params.subscribe((params: any) => {
            this.initPage(parseInt(params.id, 10)).catch((error) => this.onError(error));
        });
        this.navigation.updateMenus();
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onEditClicked(): void {
        this._router.navigate(['investors', 'companies', this.companyData.company.id, 'edit'], {
            queryParams: {backUrl: this._router.url, backTitle: this._translater.instant('Company_details')}
        });
    }

    public onWebsiteClicked(): void {
        let url: string = this.companyData.company.website;
        if (!url.startsWith('http')) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
    }

    public onDataSortChange(event: any): void {
        this.dataSource.sortBy(event.active, event.direction);
    }

    public displayAdress(): boolean {
        return this.companyData.company.noContactInfos ? false : !!(this.companyData.company.street1
            && this.companyData.company.city
            && this.companyData.company.country
            && this.companyData.company.email
            && this.companyData.company.phone);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initPage(companyId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.isLoading = true;
            this.loadData(companyId)
                .then(() => {
                    this.initDataSource();
                    this._title.setTitle(
                        (this.companyData.company.name || this._translater.instant('Company_details'))
                        + ' | ' +
                        this.conf.platformName
                    );
                    this.is401 = !this.rights.SEE;
                    this.isLoading = false;

                    resolve();
                })
                .catch(reject);

            this._changeDetector.detectChanges();
        });
    }

    private loadData(companyId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(Company).where('id').equals(companyId).get(1),
                this._storage.select(CompanyContact).where('companyId').equals(companyId).get(),
                this._storage.select(Company_TherapeuticArea).where('companyId').equals(companyId).get(),
                this._storage.select(Company_DevelopmentPhase).where('companyId').equals(companyId).get(),
                this._storage.select(CompanyCompanyCategory).get(),
                this._storage.select(TherapeuticArea).get(),
                this._storage.select(DevelopmentPhase).orderBy('ordinal').get(),
                this._storage.select(ProjectType).get(),
                this._storage.select(Company_ProjectType).where('companyId').equals(companyId).get(),
                this.currentUser.user.isInvestor && this._storage.select(InvestorProfile).where('userId').equals(this.currentUser.user.id).get(1)
            ])
                .then((data: any[]) => {
                    if (!data[0]) {
                        this.is404 = true;
                        reject({});
                        return;
                    }

                    this.categories = data[4];
                    this.therapeuticAreas = data[5];
                    this.developmentPhases = data[6];
                    this.projectTypes = data[7];

                    const company: Company = data[0];

                    this.rights.evaluate({
                        user: this.currentUser.user,
                        company: data[0],
                        investorProfile: this.currentUser.user.isInvestor ? data[9] : null
                    });
                    const therapeuticAreaIds = this.utils.getKeyValues(data[2], 'therapeuticAreaId');
                    const developmentPhaseIds = this.utils.getKeyValues(data[3], 'developmentPhaseId');
                    const projectTypeIds = this.utils.getKeyValues(data[8], 'projectTypeId');
                    this.companyData = {
                        company,
                        companyCategory: this.utils.findIn(company.companyCompanyCategoryId, data[4]),
                        companyContacts: data[1],
                        therapeuticAreasOfInterest: this.utils.findManyIn(therapeuticAreaIds, this.therapeuticAreas),
                        therapeuticAreasIdsOfInterest: therapeuticAreaIds,
                        developmentPhasesOfInterest: this.utils.findManyIn(developmentPhaseIds, this.developmentPhases),
                        developmentPhasesIdsOfInterest: developmentPhaseIds,
                        projectTypesOfInterest: this.utils.findManyIn(projectTypeIds, this.projectTypes),
                        projectTypesIdsOfInterest: projectTypeIds,
                        rights: this.rights
                    };
                    this.dataSource.replaceAllWith(data[1]);

                    resolve();

                })
                .catch(reject);
        });
    }

    private initDataSource(): void {
        this.dataSource.sort = this.sorter;

        this.dataSource.addSortDefinition(
            'name',
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(a.firstName + a.lastName), this.utils.normalizeString(b.firstName + b.lastName)
            ),
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(b.firstName + b.lastName), this.utils.normalizeString(a.firstName + a.lastName)
            )
        );

        this.dataSource.addSortDefinition(
            'email',
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(a.email || ''), this.utils.normalizeString(b.email || '')
            ),
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(b.email || ''), this.utils.normalizeString(a.email || '')
            )
        );

        this.dataSource.addSortDefinition(
            'phone',
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(a.phone || ''), this.utils.normalizeString(b.phone || '')
            ),
            (a: CompanyContact, b: CompanyContact) => this.utils.sortRow(
                this.utils.normalizeString(b.phone || ''), this.utils.normalizeString(a.phone || '')
            )
        );

    }

    private onError(error: any, row: CompanyDataRow = null): void {
        this.isLoading = false;
        if (row) {
            row.isLoading = false;
        }
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
