<div class="container">
    <button [matMenuTriggerFor]="menu"
            mat-icon-button>
        <mat-icon>playlist_add_check</mat-icon>
        <div *ngIf="notifications.notifications.length > 0"
             class="counter background-color-warn">
            {{ notifications.notifications.length }}
        </div>
    </button>
</div>
<mat-menu #menu="matMenu" xPosition="before">
    <div class="main-title">{{ 'Todo_list' | translate }}</div>
    <div *ngIf="notifications.notifications.length == 0"
         class="info">
        {{ 'All_caught_up' | translate }}
    </div>
    <ng-container *ngIf="notifications.notifications.length > 0">
        <div *ngFor="let notification of notifications.notifications"
             class="notification-item"
             mat-menu-item
             routerLink="{{ notification.url }}">
            <div>
                <mat-icon>{{ notification.icon }}</mat-icon>
            </div>
            <div class="content">
                <div class="legend">
                    <ng-container *ngIf="notification.legend">{{ notification.legend | ellipsize:35 }}</ng-container>
                </div>
                <div class="title">{{ notification.title | ellipsize:25 }}</div>
                <div class="text">
                    <ng-container *ngIf="notification.text">{{ notification.text | ellipsize:80 }}</ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</mat-menu>
