<module-content *ngIf="projects.length === 0"
                [title]="'Favorite_projects' | translate"
                class="legend">
    <p>{{ 'No_favorites_yet' | translate }}</p>
    <button color="accent"
            mat-raised-button
            routerLink="/academic-ventures">
        {{ 'Search_for_academic_ventures' | translate }}
    </button>
</module-content>
<projects-list (onFavoriteClicked)="onFavoriteClicked($event)"
               (onRowClicked)="onRowClicked($event)"
               *ngIf="currentUser.user.isInvestor || currentUser.user.isGuest"
               [disableSearchEngine]="true"
               [investorProfile]="investorProfile"
               [moduleTitle]="'Favorite_projects' | translate"
               [preserveFilters]="false"
               [projects]="projects">
</projects-list>
