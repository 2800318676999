<div (click)="onClicked($event)"
     [ngClass]="{ 'reverse': orientation == 'right' }"
     class="container">
    <ng-container *ngIf="!avatarOnly">
        <img *ngIf="user.avatar"
             [attr.src]="conf.storageRootUrl + user.avatar"
             [ngClass]="{'hideable-450': avatarHideable450}"
             class="avatar"/>
        <mat-icon *ngIf="!user.avatar">
            account_circle
        </mat-icon>
        <div [ngClass]="{ 'reverse': orientation == 'right' }"
             class="texts">
            <div class="title">{{ user.firstName + ' ' + user.lastName }}</div>
            <div *ngIf="isEmailVisible" class="legend">{{ user.email }}</div>
            <div *ngIf="isRegistrationDateVisible" class="legend">{{ 'Registered_since' | translate }}
                : {{ user.createDate | dateClosest }}</div>
            <div *ngIf="legend.length" class="legend">{{ legend }}</div>
        </div>
    </ng-container>
    <ng-container *ngIf="avatarOnly">
        <img [attr.src]="user.avatar ? conf.storageRootUrl + user.avatar : conf.assetsUrl + 'img/avatar.png'"
             [matTooltipPosition]="'above'"
             class="avatar"
             matTooltip="{{ avatarOnlyTooltip }}"/>
    </ng-container>
</div>
