<div class="title">{{ 'Investors' | translate:{platformName: conf.platformName} }}*</div>
<div *ngIf="companies.length"
     class="logos">
    <button (click)="onPreviousClicked()"
            mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <img [alt]="companies[currentIndex].name"
         [class.hide-effect]="currentIndex % 2 > 0"
         [matTooltip]="companies[currentIndex].name"
         [src]="conf.storageRootUrl + companies[aIndex].logo">
    <img [alt]="companies[currentIndex].name"
         [class.hide-effect]="currentIndex % 2 === 0"
         [matTooltip]="companies[currentIndex].name"
         [src]="conf.storageRootUrl + companies[bIndex].logo">
    <button (click)="onNextClicked()"
            mat-icon-button>
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>
<div class="title-asterisk">*{{ 'Company_logo_title_asterisk' | translate }}</div>
