import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUser } from '../../_common/providers/current-user';
import { IsLoggedInGuard } from '../../_common/guards/is-logged-in';

@Injectable()
export class UsersCanEditUserGuard implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _currentUser: CurrentUser,
        private _isLoggedIn: IsLoggedInGuard,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._isLoggedIn.canActivate(route, state)
                .then((isLoggedIn) => {
                    const userId: number = this.getUserId(route);

                    if (isLoggedIn && (this._currentUser.user.id === userId || this._currentUser.user.isAdmin)) {
                        resolve(true);
                    } else if (isLoggedIn) {
                        this.redirect();
                        resolve(false);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    this.redirect();
                    resolve(false);
                });
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private redirect(): void {
        this._router.navigate(['401']);
    }

    private getUserId(route: ActivatedRouteSnapshot): number {
        if (route.params.userId) {
            return parseInt(route.params.userId, 10);
        }

        return null;
    }

}
