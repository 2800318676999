import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GuardsSequenceGuard implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _isReady: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _injector: Injector,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (route.data._guardsSequence && route.data._guardsSequence.length) {
                const guards: any[] = route.data._guardsSequence.slice();
                this.runGuards(route, state, guards)
                    .then((canActivate: boolean) => resolve(canActivate))
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    });
            } else {
                resolve(true);
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private runGuards(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, guards: any[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (guards.length === 0) {
                resolve(true);
                return;
            }

            const guard: any = this._injector.get<any>(guards[0]);
            guard.canActivate(route, state)
                .then((canActivate) => {
                    if (!canActivate) {
                        resolve(false);
                        return;
                    }

                    guards.splice(0, 1);
                    this.runGuards(route, state, guards)
                        .then((canActivate) => resolve(canActivate))
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

}
