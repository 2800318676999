import { AfterViewInit, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from '../../providers/utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'name-picker',
    providers: [],
    styleUrls: ['./name-picker.scss'],
    templateUrl: './name-picker.html'
})
export class NamePickerComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public nameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);

    public title: string = '';
    public placeholder: string = '';
    public maxLength: number = 0;
    public explaination: string = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<NamePickerComponent>,
        private _utils: Utils,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.nameFormControl.setValue(this._data.name ? this._data.name : '');
        this.title = this._data.title;
        this.placeholder = this._data.placeholder;
        if (this._data.maxLength) {
            this.maxLength = this._data.maxLength;
        }
        this.explaination = this._data.explaination;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onTyped(): void {
        if (this.maxLength && this.nameFormControl.value && this.nameFormControl.value.length > this.maxLength) {
            this.nameFormControl.setValue(this.nameFormControl.value.substr(0, this.maxLength));
        }
    }

    public onSaveClicked(): void {
        this.save();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private save(): void {
        this.dialog.close(this.nameFormControl.value);
    }
}
