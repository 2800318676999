import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import {
    Project,
    ProjectCase_ProjectType,
    ProjectCase_TherapeuticArea,
    ProjectTeamMember
} from '../../../_common/providers/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'projects-dashboard-overview',
    providers: [],
    styleUrls: ['./dashboard-overview.scss'],
    templateUrl: './dashboard-overview.html'
})
export class ProjectsDashboardOverviewComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public isLoading: boolean = false;
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    public listingProgress: number = 0;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _dialoger: MatDialog,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.loadData().catch((error: any) => this.onError(error));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._storage.select(ProjectCase_ProjectType).where('projectId').equals(this.project.id).get(),
                this._storage.select(ProjectCase_TherapeuticArea).where('projectId').equals(this.project.id).get(),
                this._storage.select(ProjectTeamMember).where('projectId').equals(this.project.id).get()
            ])
                .then((data: any[]) => {
                    this.listingProgress = this._projectsProvider.computeListingProgress(
                        this.project,
                        data[0].length,
                        data[1].length,
                        data[2].length
                    );

                    resolve();
                })
                .catch(reject);
        });
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
