import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { DiscussionMessage, Project, User } from '../../../_common/providers/models';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export const DISCUSSION_MESSAGE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DiscussionsMessageComponent),
    multi: true
};

@Component({
    selector: 'discussions-message',
    providers: [DISCUSSION_MESSAGE_VALUE_ACCESSOR],
    styleUrls: ['./message.scss'],
    templateUrl: './message.html'
})
export class DiscussionsMessageComponent implements AfterViewInit, ControlValueAccessor {

    //
    //
    // CONSTANTS
    //
    //

    public DELETE_MESSAGE_PATTERNS: any = DeleteMessagePattern;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isAuthor: boolean = false;

    public message: DiscussionMessage = null;
    public author: User = null;

    @Input() public project: Project = null;
    @Input() public deleteMessagePattern: DeleteMessagePattern = DeleteMessagePattern.NONE;
    @Output() public valueChange: EventEmitter<any> = new EventEmitter();
    @Output() public onDeleteMessage: EventEmitter<DiscussionMessage> = new EventEmitter<DiscussionMessage>();

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _title: Title,
        private _router: Router,
        private _route: ActivatedRoute,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _dialoger: MatDialog
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    public writeValue(value: DiscussionMessage): void {
        if (value) {
            this.loadMessage(value)
                .catch((error) => console.log(error));
        } else {
            this.message = null;
            this.author = null;
        }
    }

    @HostListener('valueChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    public onDeleteMessageClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: this._translater.instant('Confirm_delete_irreversible')
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.onDeleteMessage.emit(this.message);
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadMessage(message: DiscussionMessage): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.fromId(User, [message.createrId], true)
                .then((author: User) => {
                    this.message = message;
                    this.author = author;
                    this.isAuthor = this.author.id === this.currentUser.user.id;

                    resolve();
                })
                .catch((error) => reject(error));
        });
    }
}

export enum DeleteMessagePattern {
    NONE,
    OWN,
    ALL
}
