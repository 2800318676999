<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="backMenu"
            [pageTitle]="alert?.name"
            header>
    </header>
    <module-content
        *ngIf="!is404 && !rights.LIST_PROJECTS && investorProfile && investorProfile.investorValidationStateId == INVESTOR_VALIDATION_STATE_PENDING">
        <div class="info">
            <mat-icon>info_outline</mat-icon>
            {{ 'You_can_not_access_here_yet' | translate }}</div>
    </module-content>
    <error-401
        *ngIf="!is404 && !rights.LIST_PROJECTS && (!investorProfile || investorProfile.investorValidationStateId != INVESTOR_VALIDATION_STATE_PENDING)"></error-401>
    <error-404 *ngIf="is404"></error-404>
    <project-cases-list (onDeleteClicked)="onDeleteClicked($event)"
                        (onEditClicked)="onEditClicked($event)"
                        (onFavoriteClicked)="onFavoriteClicked($event)"
                        (onRowClicked)="onRowClicked($event)"
                        *ngIf="!is404 && rights.LIST_PROJECTS && projectCases.length > 0"
                        [investorProfile]="investorProfile"
                        [preserveFilters]="true"
                        [projectCases]="projectCases">
    </project-cases-list>
    <module-content *ngIf="projectCases.length === 0"
                    class="legend">
        <p>{{ 'No_projects' | translate }}</p>
    </module-content>

    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
