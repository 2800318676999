<header [backMenu]="null"
        [logoAlwaysDisplayed]="true"
        [pageSubtitle]="null"
        [pageTitle]="null"></header>
<div class="big-wrapper">
    <acventures-common-home-welcome>
        <button (click)="onCallToAction1Clicked()"
                class="big-button"
                color="accent"
                mat-raised-button>
            {{ 'Call_to_action1' | translate }}
        </button>
        <button (click)="onCallToAction2Clicked()"
                class="big-button"
                color="accent"
                mat-raised-button>
            {{ 'Call_to_action2' | translate }}
        </button>
    </acventures-common-home-welcome>
    <investors-home-introduction [showRoomMode]="'dialog'">
        <button (click)="onCreateInvestorAccountClicked()"
                color="accent"
                mat-raised-button>
            {{ 'Create_an_account_free' | translate }}
        </button>
    </investors-home-introduction>
    <projects-home-introduction [showRoomMode]="'dialog'">
        <button (click)="onCreateProjectAccountClicked()"
                color="primary"
                mat-raised-button>
            {{ 'Create_an_account_free' | translate }}
        </button>
    </projects-home-introduction>
    <!--
    <div class="section-4"
         [style.backgroundImage]="'url(\'' + conf.assetsUrl + 'img/home/section-4-background.jpg\')'">
        <div class="title">{{ 'Partner_universities' | translate }}</div>
        <div class="logos">
            <img *ngFor="let institution of institutions"
                 [src]="conf.assetsUrl + 'img/home/partners/' + institution.id + '.png'"
                 [matTooltip]="institution.name" />
        </div>
    </div>
    -->
    <footer [hasLeftMenu]="false"></footer>
</div>
