import { Injectable } from '@angular/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { LangsProvider } from '../../_common/providers/langs-provider';
import { CurrentUser } from '../../_common/providers/current-user';
import { UserRights } from './user-rights';
import {
    AcademicInstitution,
    InvestorProfile,
    ProjectMembership,
    User,
    User_AcademicInstitution,
    User_UserLink,
    UserLink
} from '../../_common/providers/models';

@Injectable()
export class UsersProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _api: ApiBaseService,
        private _storage: ModelsStorage,
        private _langs: LangsProvider,
        private _currentUser: CurrentUser
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getAdministrators(): Promise<User[]> {
        return this._storage
            .select(User)
            .where('isAdmin').equals(1)
            .orderBy('firstName')
            .get();
    }

    public saveUser(user: User): Promise<any> {
        return new Promise((resolve, reject) => {
            this._api.call(
                'post',
                'users/' + user.id,
                user.toJson(),
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    user = User.fromAPI(result.user);
                    this._storage.save([user])
                        .then(() => resolve(user))
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    public changePassword(user: User, newPassword: string, currentPassword: string): Promise<any> {
        return this._api.call(
            'post',
            'users/' + user.id + '/change-password',
            {
                newPassword,
                currentPassword
            },
            this._currentUser.getAccessToken()
        );
    }

    public saveUserLinks(userId: number, user_userLinks: User_UserLink[]): Promise<User_UserLink> {
        return new Promise<any>((resolve, reject) => {
            this._api.call(
                'post',
                'users/' + userId + '/user-links',
                {user_userLinks: this._utils.buildModelParams(user_userLinks, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((result: any) => {
                    user_userLinks = User_UserLink.fromAPIList(result.user_userLinks);
                    this._storage.save(user_userLinks)
                        .then(() => resolve(user_userLinks))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public getUserUserLinksData(userId: number, addMissing: boolean = true): Promise<any> {
        /**
         * Retourne un objet formaté comme suit :
         *   [{
         *       userLink: UserLink,
         *       user_userLink: User_UserLink
         *   },]
         */
        return new Promise<any>((resolve, reject) => {
            Promise.all([
                this._storage.select(UserLink).get(),
                this._storage.select(User_UserLink)
                    .where('userId').equals(userId)
                    .get()
            ])
                .then((results: any[]) => {
                    const data: any[] = [];
                    for (let i = 0; i < results[0].length; i++) {
                        let user_userLink: User_UserLink = this._utils.findIn(results[0][i].id, results[1], 'userLinkId');
                        if (!user_userLink && addMissing) {
                            user_userLink = new User_UserLink();
                            user_userLink.userId = userId;
                            user_userLink.userLinkId = results[0][i].id;
                        }

                        if (user_userLink || addMissing) {
                            data.push({
                                userLink: results[0][i],
                                user_userLink
                            });
                        }
                    }

                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }

    public saveToStorage() {
        this._storage.save([User]);
    }

    public findAllMembershipsByProjectId(projectId): User[] {
        return this._storage
            .select(User)
            .where('id')
            .equals(ProjectMembership, 'userId')
            .and('projectId', ProjectMembership)
            .equals(projectId)
            .get()
            ;
    }

    public invite(user, projectId): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._api.call(
                'post',
                'projects/' + projectId + '/memberships/invite',
                {user},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    user = User.fromAPI(data.user);
                    const membership = ProjectMembership.fromAPI(data.projectMembership);
                    Promise.all([
                        this._storage.save([user]),
                        this._storage.save([membership])
                    ])
                        .then(() => resolve({user, membership, isNew: data.isNew}))
                        .catch((error: any) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    public checkEmailAvailability(email: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._api.call(
                'get',
                'users/email-availability',
                {email}
            )
                .then((result: any) => resolve(result.isAvailable))
                .catch((error: any) => reject(error));
        });
    }

    public deleteUser(user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'users/' + user.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    // TODO c'est tout pourri comme architecture ... il aurait fallu faire en sorte que ce soit le investorProvider qui delete le user ...
                    if (user.isInvestor) {
                        this._storage.select(InvestorProfile).where('userId').equals(user.id).get()
                            .then((profiles: InvestorProfile[]) => {
                                Promise.all([
                                    this._storage.delete([user]),
                                    this._storage.delete(profiles)
                                ])
                                    .then(() => resolve())
                                    .catch((error: any) => reject(error));
                            })
                            .catch((error: any) => reject(error));
                    } else {
                        this._storage.delete([user])
                            .then(() => resolve())
                            .catch((error: any) => reject(error));
                    }
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveUserAcademicInstitutions(userId: number, user_academicInstitutions: User_AcademicInstitution[]): Promise<User_AcademicInstitution[]> {
        return new Promise<User_AcademicInstitution[]>((resolve, reject) => {
            this._api.call(
                'post',
                'users/' + userId + '/academic-institutions',
                {user_academicInstitutions: this._utils.buildModelParams(user_academicInstitutions, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(User_AcademicInstitution).where('userId').equals(userId).get()
                        .then((currentUser_academicInstitutions: User_AcademicInstitution[]) => {
                            user_academicInstitutions = User_AcademicInstitution.fromAPIList(data.user_academicInstitutions);
                            Promise.all([
                                this._storage.delete(currentUser_academicInstitutions),
                                this._storage.save(user_academicInstitutions)
                            ])
                                .then((results: any[]) => resolve(user_academicInstitutions))
                                .catch((error: any) => reject(error));
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public exportUsers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._api.call(
                'get',
                'users/export',
                {},
                {
                    accessToken: this._currentUser.getAccessToken(),
                    resultType: 'file'
                }
            )
                .then((file: any) => {
                    resolve(file);
                })
                .catch((error) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}

export interface UsersRow {
    user: User;
    rights: UserRights;
    isLoading?: boolean;
}

export interface LocalExpertRow {
    user: User;
    institutionDetails: InstitutionLocalExpertRow[];
    expertInstitutions: AcademicInstitution[];
    rights: UserRights;
    isLoading?: boolean;
}

export interface InstitutionLocalExpertRow {
    institution: AcademicInstitution;
    isLocalExpert: boolean;
    isDispatcher: boolean;
}
