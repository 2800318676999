<mat-spinner *ngIf="!isReady"></mat-spinner>
<ng-container *ngIf="isReady && !disableSearchEngine">
    <div [class.hidden]="investorProfiles.length == 0"
         class="filters-actions-container">
        <div class="filters-container">
            <projects-filter-block [label]="'Company_category' | translate"
                                   [selectionLabel]="filteredCompanyCategories.length > 0 ? (filteredCompanyCategories.length === 1 ? filteredCompanyCategories[0].name : 'n_categories_of_company'  | translate:{count: filteredCompanyCategories.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onCompanyCategoryClicked($event, category)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let category of companyCategories; let i = index;"
                                  [(ngModel)]="companyCategoriesCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ category.name }}</div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllCompanyCategoriesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoCompanyCategoriesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [label]="'Therapeutic_areas_of_interest' | translate"
                                   [selectionLabel]="filteredTherapeuticAreas.length > 0 ? (filteredTherapeuticAreas.length === 1 ? filteredTherapeuticAreas[0].name : 'n_therapeutic_areas'  | translate:{count: filteredTherapeuticAreas.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onTherapeuticAreaClicked($event, area)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let area of therapeuticAreas; let i = index;"
                                  [(ngModel)]="therapeuticAreasCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ area.name }}</div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllTherapeuticAreasClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoTherapeuticAreasClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [label]="'Development_phases_of_interest' | translate"
                                   [selectionLabel]="filteredDevelopmentPhases.length > 0 ? (filteredDevelopmentPhases.length === 1 ? filteredDevelopmentPhases[0].name : 'n_therapeutic_areas'  | translate:{count: filteredDevelopmentPhases.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onDevelopmentPhaseClicked($event, phase)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let phase of developmentPhases; let i = index;"
                                  [(ngModel)]="developmentPhasesCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ phase.name }}</div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllDevelopmentPhasesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoDevelopmentPhasesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [isInvalid]="!isMoreFiltersValid()"
                                   [label]="'More_filters' | translate"
                                   [selectionLabel]="moreFiltersSelectionLabel">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <div class="section">
                        <div>{{ 'Investment_range' | translate }}</div>
                        <div class="line">
                            <mat-form-field [hintLabel]=""
                                            color="primary">
                                <input (click)="stopPropagation($event)"
                                       (keyup)="onMoreFiltersItemClicked($event)"
                                       [formControl]="investmentRangeFromFormControl"
                                       [placeholder]="'From' | translate"
                                       matInput
                                       type="number">
                                <span matPrefix>CHF&nbsp;</span>
                                <mat-error
                                    *ngIf="investmentRangeFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field [hintLabel]=""
                                            color="primary">
                                <input (click)="stopPropagation($event)"
                                       (keyup)="onMoreFiltersItemClicked($event)"
                                       [formControl]="investmentRangeToFormControl"
                                       [placeholder]="'To' | translate"
                                       matInput
                                       type="number">
                                <span matPrefix>CHF&nbsp;</span>
                                <mat-error
                                    *ngIf="investmentRangeToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="section">
                        <div class="title">{{ 'Seeking_partnerships' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isSeekingPartnerhips"
                                      color="primary">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotSeekingPartnerhips"
                                      color="primary">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                    <div class="section">
                        <div class="title">{{ 'Out_licensing_opportunities' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isOutlicensing"
                                      color="primary">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotOutlicensing"
                                      color="primary">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                    <div class="section">
                        <div class="title">{{ 'Not_for_profit_ventures' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotForProfit"
                                      color="primary">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotNotForProfit"
                                      color="primary">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                </div>
            </projects-filter-block>
        </div>
        <div class="filters-main-actions">
            <button (click)="onResetFiltersClicked()"
                    *ngIf="hasFilters()"
                    [matTooltip]="'Reset_filters' | translate"
                    color="warn"
                    mat-mini-fab>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div [class.hidden]="investorProfiles.length == 0"
         class="search-bar">
        <div *ngIf="!hasFilters() && searchInput.value.length == 0">
        </div>
        <div class="title">
            <ng-container *ngIf="hasFilters() || searchInput.value.length">
                <span *ngIf="dataSource.filteredData.length > 1">
                    {{ 'Investor_search_results' | translate:{count: dataSource.filteredData.length} }}
                </span>
                <span *ngIf="dataSource.filteredData.length == 1"
                      class="title">
                    {{ 'Investor_search_result' | translate }}
                </span>
                <span *ngIf="dataSource.filteredData.length == 0"
                      class="title">
                    {{ 'Investor_search_no_results' | translate }}
                </span>
            </ng-container>
        </div>
        <mat-form-field [class.hidden]="dataSource.filteredData.length == 0 && searchInput.value.length == 0"
                        color="primary">
            <input [formControl]="searchInput"
                   matInput
                   maxlength="255"
                   placeholder="{{ 'Search_in_the_list' | translate }}"
                   type="text">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
</ng-container>
<module-content [class.hidden]="(!disableSearchEngine && dataSource.filteredData.length == 0 && investorProfiles.length > 0) || !isReady"
                [title]="title">
    <div *ngIf="dataSource.count === 0"
         class="legend">
        <p>{{ noDataMessage | translate }}</p>
    </div>
    <mat-table (matSortChange)="onDataSortChange($event)"
               *ngIf="dataSource.count > 0"
               [dataSource]="dataSource"
               matSort>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns; let i = index;"></mat-row>
        <ng-container matColumnDef="validationState">
            <mat-header-cell *matHeaderCellDef class="actions-cell"
                             mat-sort-header>{{ 'State' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <investors-investor-state-indicator [displayMode]="'chip'"
                                                    [state]="row.validationState">
                </investors-investor-state-indicator>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="clickable-investor-cell"
                             mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable-investor-cell">
                <div (click)="onRowClicked.emit(row)"
                     class="link-button clickable">
                    {{ row.user.firstName + ' ' + row.user.lastName }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef class="clickable-investor-cell hideable-500"
                             mat-sort-header>{{ 'Company' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable-investor-cell">
                <div (click)="onCompanyClicked.emit(row)" *ngIf="row.company"
                     class="link-button clickable">
                    <div>{{ row.company.name }}</div>
                    <div *ngIf="row.companyCategory">
                        <div class="legend">{{ row.companyCategory.name }}</div>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef class="date-cell hideable-500"
                             mat-sort-header>{{ 'Registration_date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="date-cell hideable-500">
                {{ row.profile.createDate | dateClosest:'FjY' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contactable">
            <mat-header-cell *matHeaderCellDef
                             class="actions-cell hideable-500"
                             mat-sort-header>
                <mat-icon [matTooltip]="'Accepted_to_be_contacted' | translate">contact_mail</mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row"
                      class="actions-cell hideable-500">
                <mat-icon *ngIf="row.profile.acceptToBeContacted">check</mat-icon>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <button *ngIf="!row.isLoading"
                        [matMenuTriggerFor]="menu"
                        mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button (click)="onDeleteRowClicked.emit(row)"
                            [disabled]="!row.rights.DELETE"
                            mat-menu-item>
                        <mat-icon>delete_outline</mat-icon>&nbsp;{{ 'Delete' | translate }}
                    </button>
                </mat-menu>
                <mat-spinner *ngIf="row.isLoading"
                             [diameter]="24">
                </mat-spinner>
            </mat-cell>
        </ng-container>
    </mat-table>
    <mat-paginator [class.hidden]="dataSource.count == 0"
                   [hidePageSize]="true"
                   [pageSizeOptions]="[50]">
    </mat-paginator>
</module-content>
