<module-content>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="titleFormControl"
                   [id]="getIdField('title')"
                   [maxlength]="TITLE_MAX_LENGTH"
                   [placeholder]="('Name' | translate) + ' *'"
                   matInput
                   type="text">
            <mat-error *ngIf="titleFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ titleFormControl.value.length }}/{{ TITLE_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="subtitleFormControl"
                   [maxlength]="SUBTITLE_MAX_LENGTH"
                   [placeholder]="'Subtitle' | translate"
                   matInput
                   type="text">
            <mat-error *ngIf="subtitleFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ subtitleFormControl.value.length }}/{{ SUBTITLE_MAX_LENGTH }}</mat-hint>
        </mat-form-field>

        <button (click)="removeProjectCase()" *ngIf="rights.DELETE"
                [disabled]="!rights.EDIT"
                [matTooltip]="'Remove' | translate"
                class="project-case-remove"
                mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>

    </div>
    <div class="line">
        <mat-form-field>
            <mat-select (selectionChange)="onProjectTypeIdChanged()"
                        [formControl]="projectTypeFormControl"
                        [id]="getIdField('type')"
                        [placeholder]="('Type_of_project' | translate) + ' *'"
                        multiple>
                <div class="all-none-container">
                    <button (click)="onAllProjectTypesClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoProjectTypesClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
                <ng-container *ngFor="let item of projectTypeGroups">
                    <div (click)="onProjectTypeGroupClicked(item)"
                         class="group-label">
                        <mat-icon>{{ getProjectGroupTypeIcon(item) }}</mat-icon>
                        <span>{{ item.group.name }}</span>
                    </div>
                    <mat-option *ngFor="let type of item.types"
                                [value]="type.id"
                                class="group-option">
                        {{ type.name }}
                    </mat-option>
                </ng-container>
                <div class="all-none-container">
                    <button (click)="onAllProjectTypesClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoProjectTypesClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select (selectionChange)="_onFormChanged()"
                        [formControl]="therapeuticAreaFormControl"
                        [id]="getIdField('area')"
                        [placeholder]="('Therapeutic_area' | translate) + ' *'"
                        multiple>
                <div class="all-none-container">
                    <button (click)="onAllTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
                <mat-option *ngFor="let area of therapeuticAreas"
                            [value]="area.id">
                    {{ area.name }}
                </mat-option>
                <div class="all-none-container">
                    <button (click)="onAllTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoTherapeuticAreasClicked()"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <rich-text-editor
        (changeFunction)="_onFormChanged()"
        (keyup)="_onFormChanged()"
        [control]="descriptionFormControl"
        [id]="getIdField('description')"
        [maxFieldLength]="DESCRIPTION_MAX_LENGTH"
        [placeholder]="('Description' | translate) + ' *'">
    </rich-text-editor>
    <div class="line">
        <mat-form-field [hintLabel]="_projectTypeIds.length >= 0 ? '' : 'You_must_specify_project_type' | translate">
            <mat-select
                (selectionChange)="_onFormChanged()"
                [formControl]="developmentPhaseFormControl"
                [id]="getIdField('developmentPhase')"
                [placeholder]="('Phase_of_development' | translate) + ' *'">
                <mat-option *ngFor="let item of developmentPhases"
                            [value]="item.id">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [hintLabel]="">
            <mat-select
                (selectionChange)="_onFormChanged()"
                [formControl]="patentStateFormControl"
                [id]="getIdField('patent')"
                [placeholder]="('Patent' | translate) + ' *'">
                <mat-option *ngFor="let item of patentStates"
                            [value]="item.id">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-container *ngIf="hasMissingFields()">
        <div class="required-warning">{{('required_warning' | translate)}}</div>
    </ng-container>
    <ng-container *ngIf="showMore">
        <div class="line">
            <mat-form-field [hintLabel]="'Uniqueness_hint' | translate"
                            color="primary">
                <textarea (keyup)="_onFormChanged()"
                          [formControl]="uniquenessFormControl"
                          [maxlength]="UNIQUENESS_MAX_LENGTH"
                          [placeholder]="'Uniqueness_of_the_project' | translate"
                          matInput
                          matTextareaAutosize></textarea>
                <mat-error *ngIf="uniquenessFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ uniquenessFormControl.value.length }}/{{ UNIQUENESS_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="line">
            <mat-form-field [hintLabel]=""
                            color="primary">
                <textarea (keyup)="_onFormChanged()"
                          [formControl]="unmetNeedFormControl"
                          [maxlength]="UNMET_NEED_MAX_LENGTH"
                          [placeholder]="'Unmet_medical_need' | translate"
                          matInput
                          matTextareaAutosize></textarea>
                <mat-error *ngIf="unmetNeedFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ unmetNeedFormControl.value.length }}/{{ UNMET_NEED_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="line">
            <mat-form-field [hintLabel]=""
                            color="primary">
                <textarea (keyup)="_onFormChanged()"
                          [formControl]="marketSizeFormControl"
                          [maxlength]="MARKET_SIZE_MAX_LENGTH"
                          [placeholder]="'Market_size' | translate"
                          matInput
                          matTextareaAutosize></textarea>
                <mat-error *ngIf="marketSizeFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ marketSizeFormControl.value.length }}/{{ MARKET_SIZE_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="line">
            <mat-form-field [hintLabel]=""
                            color="primary">
                <textarea (keyup)="_onFormChanged()"
                          [formControl]="competitionFormControl"
                          [maxlength]="COMPETITION_MAX_LENGTH"
                          [placeholder]="'Current_and_future_competition' | translate"
                          matInput
                          matTextareaAutosize></textarea>
                <mat-error *ngIf="competitionFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ competitionFormControl.value.length }}/{{ COMPETITION_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
    </ng-container>
    <button (click)="onToggleShowMore()"
            color="accent"
            mat-button>
        {{ showMore ? ('Hide_optional_fields' | translate) : ('Show_n_more_optional_fields' | translate:{n: 8}) }}
    </button>
</module-content>
