<div class="container">
    <button [matMenuTriggerFor]="menu"
            mat-icon-button>
        <mat-icon>message</mat-icon>
        <div *ngIf="discussions.privateDiscussionsDataWithUnreadMessages.length > 0"
             class="counter background-color-warn">
            {{ discussions.privateDiscussionsDataWithUnreadMessages.length }}
        </div>
    </button>
</div>
<mat-menu #menu="matMenu" xPosition="before">
    <div class="main-title">{{ 'Private_discussions' | translate }}</div>
    <div *ngIf="discussions.privateDiscussionsData.length == 0"
         class="info">
        {{ 'You_have_no_discussion_yet' | translate }}
    </div>
    <ng-container *ngIf="discussions.privateDiscussionsData.length > 0">
        <div (click)="onPrivateDiscussionClicked(privateDiscussionData)"
             *ngFor="let privateDiscussionData of discussions.privateDiscussionsData"
             class="discussion-item"
             mat-menu-item>
            <div class="icon">
                <ng-container *ngIf="privateDiscussionData.privateDiscussion.isGroup">
                    <img *ngIf="privateDiscussionData.privateDiscussion.image"
                         [src]="conf.storageRootUrl + privateDiscussionData.privateDiscussion.image"/>
                    <mat-icon *ngIf="!privateDiscussionData.privateDiscussion.image">supervised_user_circle</mat-icon>
                </ng-container>
                <ng-container *ngIf="!privateDiscussionData.privateDiscussion.isGroup">
                    <img
                        [src]="privateDiscussionData.usersData[0].user.avatar ? conf.storageRootUrl + privateDiscussionData.usersData[0].user.avatar : conf.assetsUrl + 'img/avatar.png'"/>
                </ng-container>
            </div>
            <div class="content">
                <div class="content-line">
                    <div class="title">
                        <ng-container *ngIf="privateDiscussionData.privateDiscussion.isGroup">
                            {{ privateDiscussionData.privateDiscussion.name }}
                        </ng-container>
                        <ng-container *ngIf="!privateDiscussionData.privateDiscussion.isGroup">
                            {{ privateDiscussionData.usersData[0].user.firstName + ' ' + privateDiscussionData.usersData[0].user.lastName }}
                        </ng-container>
                    </div>
                    <div class="date">
                        <ng-container *ngIf="privateDiscussionData.messages.length > 0">
                            {{ privateDiscussionData.messages[privateDiscussionData.messages.length - 1].createDate | dateClosest:'closest-short' }}
                        </ng-container>
                        <ng-container *ngIf="privateDiscussionData.messages.length == 0">
                            {{ privateDiscussionData.privateDiscussion.createDate | dateClosest:'closest-short' }}
                        </ng-container>
                    </div>
                </div>
                <div class="content-line">
                    <div class="subtitle">
                        <ng-container *ngIf="privateDiscussionData.messages.length > 0">
                            {{ privateDiscussionData.messages[privateDiscussionData.messages.length - 1].message }}
                        </ng-container>
                    </div>
                    <div *ngIf="privateDiscussionData.unreadMessagesCount"
                         class="counter background-color-warn text-color-warn">
                        {{ privateDiscussionData.unreadMessagesCount }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--
    <ng-container *ngIf="organization">
        <div class="separation"></div>
        <button mat-menu-item
                [routerLink]="'/' + organization.code + '/members'">
            <mat-icon>group</mat-icon>&nbsp;
            {{ 'All_organization_members' | translate }}
        </button>
        <button mat-menu-item
                (click)="onCreateGroupClicked()">
            <mat-icon>group_add</mat-icon>&nbsp;
            {{ 'Create_group' | translate }}
        </button>
    </ng-container>
        -->
</mat-menu>
<div *ngIf="discussions.activePrivateDiscussions.length > 0"
     class="active-discussions-container">
    <discussions-private-discussion-window
        (onClose)="onPrivateDiscussionClosed(discussions.activePrivateDiscussions[i])"
        *ngFor="let discussionData of discussions.activePrivateDiscussions; let i = index"
        [(activePrivateDiscussion)]="discussions.activePrivateDiscussions[i]"
    ></discussions-private-discussion-window>
</div>
