import { Injectable } from '@angular/core';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { CurrentUser } from '../../_common/providers/current-user';
import { Project, ProjectMembership, User } from '../../_common/providers/models';
import { ProjectRights } from '../../projects/providers/project-rights';
import { ModelsStorage } from '../../_common/providers/models-storage';

@Injectable()
export class ProjectsMembershipsProvider {

    constructor(
        private _api: ApiBaseService,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {

    }

    remove(membership: ProjectMembership): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._api.call(
                'delete',
                'projects/' + membership.projectId + '/memberships/' + membership.userId + '',
                {},
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    this._storage.delete([membership])
                        .then(() => resolve(result))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }
}

export interface ProjectMembershipRow {
    user?: User;
    project?: Project;
    membership: ProjectMembership;
    rights: ProjectRights;
    isLoading?: boolean;
}
