import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ProjectsProvider } from '../../providers/projects-provider';
import { Project, ProjectValidationState } from '../../../_common/providers/models';

@Component({
    selector: 'projects-project-state-indicator',
    providers: [],
    styleUrls: ['./project-state-indicator.scss'],
    templateUrl: './project-state-indicator.html'
})
export class ProjectsProjectStateIndicatorComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly STATE_ID_DRAFT: number = ProjectValidationState.ID_DRAFT;
    public readonly STATE_ID_PENDING_VALIDATION: number = ProjectValidationState.ID_PENDING_VALIDATION;
    public readonly STATE_ID_PUBLISHED: number = ProjectValidationState.ID_PUBLISHED;
    public readonly STATE_ID_REFUSED: number = ProjectValidationState.ID_REFUSED;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //
    @Input() public size: 'normal' | 'big' = 'normal';
    public state: ProjectValidationState = new ProjectValidationState();

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _projectProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _project: Project = null;

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.loadState().catch((error: any) => this.onError(error));
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadState(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(ProjectValidationState).where('id').equals(this._project.projectValidationStateId).get(1)
                .then((state: ProjectValidationState) => {
                    this.state = state;
                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

    private onError(error: any): void {
        console.log(error);
    }
}
