<mat-spinner *ngIf="!isReady"></mat-spinner>
<ng-container *ngIf="isReady && !disableSearchEngine">
    <div [class.hidden]="projects.length == 0"
         class="filters-alarm-container">
        <div class="filters-container">
            <projects-filter-block [label]="'Type_of_project' | translate"
                                   [selectionLabel]="filteredProjectTypes.length > 0 ? (filteredProjectTypes.length === 1 ? filteredProjectTypes[0].name : 'n_types_of_project'  | translate:{count: filteredProjectTypes.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <div *ngFor="let group of projectTypeGroups; let i = index;"
                         class="section">
                        <div (click)="onProjectTypeGroupClicked($event, group, i)"
                             class="group-checkbox">
                            <mat-icon>{{ getGroupCheckboxIcon(group.types, filteredProjectTypes) }}</mat-icon>
                            {{ group.group.name }}
                        </div>
                        <mat-checkbox (change)="onProjectTypeClicked($event, type)"
                                      (click)="stopPropagation($event)"
                                      *ngFor="let type of group.types; let j = index;"
                                      [(ngModel)]="projectTypesCheckboxes[i][j]"
                                      class="sub-checkbox"
                                      color="primary">
                            <div class="label">{{ type.name }}</div>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllProjectTypesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoProjectTypesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [label]="'Therapeutic_area' | translate"
                                   [selectionLabel]="filteredTherapeuticAreas.length > 0 ? (filteredTherapeuticAreas.length === 1 ? filteredTherapeuticAreas[0].name : 'n_therapeutic_areas'  | translate:{count: filteredTherapeuticAreas.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onTherapeuticAreaClicked($event, area)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let area of therapeuticAreas; let i = index;"
                                  [(ngModel)]="therapeuticAreasCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ area.name }}</div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllTherapeuticAreasClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoTherapeuticAreasClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [label]="'Phase_of_development' | translate"
                                   [selectionLabel]="filteredDevelopmentPhases.length > 0 ? (filteredDevelopmentPhases.length === 1 ? filteredDevelopmentPhases[0].name : 'n_development_phases'  | translate:{count: filteredDevelopmentPhases.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onDevelopmentPhaseClicked($event, phase.developmentPhase)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let phase of developmentPhasesWithAssociatedProjectTypeGroups; let i = index;"
                                  [(ngModel)]="developmentPhasesCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ phase.developmentPhase.name }}
                            <!--<br /><span class="legend">(<span *ngFor="let group of phase.groups; let i=index">{{ group.name + (i < phase.groups.length - 1 ? ', ' : '') }}</span>)</span>--></div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllDevelopmentPhasesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoDevelopmentPhasesClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [label]="'Funding_round' | translate"
                                   [selectionLabel]="filteredFundingRound.length > 0 ? (filteredFundingRound.length === 1 ? filteredFundingRound[0].name : 'n_funding_rounds'  | translate:{count: filteredFundingRound.length}) : null">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <mat-checkbox (change)="onFundingRoundClicked($event, round)"
                                  (click)="stopPropagation($event)"
                                  *ngFor="let round of fundingRound; let i = index;"
                                  [(ngModel)]="fundingRoundsCheckboxes[i]"
                                  color="primary">
                        <div class="label">{{ round.name }}
                            <!--<br /><span class="legend">(<span *ngFor="let group of phase.groups; let i=index">{{ group.name + (i < phase.groups.length - 1 ? ', ' : '') }}</span>)</span>--></div>
                    </mat-checkbox>
                </div>
                <div class="selection-all-buttons">
                    <button (click)="onAllFundingRoundsClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'All' | translate }}
                    </button>
                    <button (click)="onNoFundingRoundsClicked($event)"
                            color="accent"
                            mat-raised-button>
                        {{ 'None' | translate }}
                    </button>
                </div>
            </projects-filter-block>
            <projects-filter-block [isInvalid]="!isMoreFiltersValid()"
                                   [label]="'More_filters' | translate"
                                   [selectionLabel]="moreFiltersSelectionLabel">
                <div (click)="stopPropagation($event)"
                     class="filter-panel">
                    <div class="section">
                        <div class="title">{{ 'Companies' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isRaisingFunds"
                                      color="primary">
                            <div class="label">{{ 'Currently_raising_funds' | translate }}</div>
                        </mat-checkbox>
                        <!--
                        <ng-container *ngIf="filteredOthers.isRaisingFunds">
                            <div>{{ 'Amount_raised_USD' | translate }}</div>
                            <div class="line">
                                <mat-form-field color="primary"
                                                [hintLabel]="">
                                    <input matInput
                                           type="number"
                                           [formControl]="amountRaisedFromFormControl"
                                           [placeholder]="'From' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="amountRaisedFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                                <mat-form-field color="primary"
                                                [hintLabel]="">
                                    <input matInput
                                           type="number"
                                           [formControl]="amountRaisedToFormControl"
                                           [placeholder]="'To' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="amountRaisedToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        -->
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotRaisingFunds"
                                      color="primary">
                            <div class="label">{{ 'Currently_not_raising_funds' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isCompanyIncorporated"
                                      color="primary">
                            <div class="label">{{ 'Company_incorporated' | translate }}</div>
                        </mat-checkbox>
                        <!--
                        <ng-container *ngIf="filteredOthers.isCompanyIncorporated">
                            <div>{{ 'Year_of_incorporation' | translate }}</div>
                            <div class="line">
                                <mat-form-field color="primary"
                                                [hintLabel]="'Min_value_x' | translate:{x: INCORPORATION_YEAR_MIN}">
                                    <input matInput
                                           type="number"
                                           [formControl]="yearOfIncorporationFromFormControl"
                                           [placeholder]="'From' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="yearOfIncorporationFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                                <mat-form-field color="primary"
                                                [hintLabel]="'Max_value_x' | translate:{x: INCORPORATION_YEAR_MAX}">
                                    <input matInput
                                           type="number"
                                           [formControl]="yearOfIncorporationToFormControl"
                                           [placeholder]="'To' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="yearOfIncorporationToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        -->
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isCompanyNotIncorporated"
                                      color="primary">
                            <div class="label">{{ 'Company_not_incorporated' | translate }}</div>
                        </mat-checkbox>
                        <!--
                        <ng-container *ngIf="filteredOthers.isCompanyNotIncorporated">
                            <div>{{ 'Targeted_year_of_incorporation' | translate }}</div>
                            <div class="line">
                                <mat-form-field color="primary"
                                                [hintLabel]="'Min_value_x' | translate:{x: TARGETED_INCORPORATION_YEAR_MIN}">
                                    <input matInput
                                           type="number"
                                           [formControl]="targetedYearOfIncorporationFromFormControl"
                                           [placeholder]="'From' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="targetedYearOfIncorporationFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                                <mat-form-field color="primary"
                                                [hintLabel]="'Max_value_x' | translate:{x: TARGETED_INCORPORATION_YEAR_MAX}">
                                    <input matInput
                                           type="number"
                                           [formControl]="targetedYearOfIncorporationToFormControl"
                                           [placeholder]="'To' | translate"
                                           (click)="stopPropagation($event)"
                                           (keyup)="onMoreFiltersItemClicked($event)">
                                    <mat-error *ngIf="targetedYearOfIncorporationToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        -->
                    </div>
                    <div class="section">
                        <div class="title">{{ 'Seeking_partnerships' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isSeekingPartnerhips"
                                      color="primary">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotSeekingPartnerhips"
                                      color="primary">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                    <div class="section">
                        <div class="title">{{ 'Out_licensing_opportunities' | translate }}</div>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isOutlicensing"
                                      color="primary">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox (change)="onMoreFiltersItemClicked($event)"
                                      (click)="stopPropagation($event)"
                                      [(ngModel)]="filteredOthers.isNotOutlicensing"
                                      color="primary">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                    <!--
                    <div class="section">
                        <div class="title">{{ 'Not_for_profit_ventures' | translate }}</div>
                        <mat-checkbox color="primary"
                                      [(ngModel)]="filteredOthers.isNotForProfit"
                                      (click)="stopPropagation($event)"
                                      (change)="onMoreFiltersItemClicked($event)">
                            <div class="label">{{ 'Yes' | translate }}</div>
                        </mat-checkbox>
                        <mat-checkbox color="primary"
                                      [(ngModel)]="filteredOthers.isNotNotForProfit"
                                      (click)="stopPropagation($event)"
                                      (change)="onMoreFiltersItemClicked($event)">
                            <div class="label">{{ 'No' | translate }}</div>
                        </mat-checkbox>
                    </div>
                    -->
                </div>
            </projects-filter-block>
        </div>
        <div class="filters-main-actions">
            <button (click)="onResetFiltersClicked()"
                    *ngIf="hasFilters()"
                    [disabled]="isAlertLoading"
                    [matTooltip]="'Reset_filters' | translate"
                    color="warn"
                    mat-mini-fab>
                <mat-icon>close</mat-icon>
            </button>
            <button (click)="onAddAlertClicked()"
                    *ngIf="rights.ADD_ALERTS"
                    [disabled]="!hasFilters() || !areFiltersValid() || isAlertLoading"
                    [matTooltip]="'Investor_welcome_section2' | translate"
                    class="alert-button"
                    color="accent"
                    mat-fab>
                <mat-icon>notifications</mat-icon>
                <mat-spinner *ngIf="isAlertLoading"
                             [color]="'accent'"
                             [diameter]="56"
                             [mode]="'indeterminate'">
                </mat-spinner>
            </button>
        </div>
    </div>
    <div [class.hidden]="projects.length == 0"
         class="search-bar">
        <div *ngIf="!hasFilters() && searchInput.value.length == 0">
        </div>
        <div class="title">
            <ng-container *ngIf="hasFilters() || searchInput.value.length">
                <span *ngIf="dataSource.filteredData.length > 1">
                    {{ 'Project_search_results' | translate:{count: dataSource.filteredData.length} }}
                </span>
                <span *ngIf="dataSource.filteredData.length == 1"
                      class="title">
                    {{ 'Project_search_result' | translate }}
                </span>
                <span *ngIf="dataSource.filteredData.length == 0"
                      class="title">
                    {{ 'Project_search_no_results' | translate }}
                </span>
            </ng-container>
        </div>
        <mat-form-field [class.hidden]="dataSource.filteredData.length == 0 && searchInput.value.length == 0"
                        color="primary">
            <input [formControl]="searchInput"
                   matInput
                   maxlength="255"
                   placeholder="{{ 'Search_in_the_list' | translate }}"
                   type="text">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
</ng-container>
<module-content [class.hidden]="dataSource.filteredData.length == 0 || !isReady"
                [title]="moduleTitle">
    <div *ngIf="dataSource.count === 0"
         class="legend">
        {{ 'No_item_yet' | translate }}
    </div>
    <mat-table (matSortChange)="onDataSortChange($event)"
               *ngIf="dataSource.count > 0"
               [dataSource]="dataSource"
               matSort>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns; let i = index;"></mat-row>
        <!--        <ng-container matColumnDef="logo">-->
        <!--            <mat-header-cell *matHeaderCellDef class="image-cell"></mat-header-cell>-->
        <!--            <mat-cell *matCellDef="let row" class="image-cell">-->
        <!--                <img [src]="row.logoImage ? row.logoImage : conf.assetsUrl + '/img/projects/default-illustration-2.jpg'" />-->
        <!--            </mat-cell>-->
        <!--        </ng-container>-->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="name-cell"
                             mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="name-cell">
                <div (click)="_onRowClicked(row)"
                     [class.clickable]="row.rights.SEE"
                     class="link-button">
                    <div class="title">{{ row.project.name }}</div>
                    <div class="subtitle">{{ row.project?.subtitle }}</div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="website">
            <mat-header-cell *matHeaderCellDef class="hideable-500 website-cell"
                             mat-sort-header>{{ 'Website' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="hideable-500 website-cell">

                <div *ngIf="row.project.contactWebsite" class="website-wrapper">
                    <ng-container *ngIf="row.project.contactWebsite.includes('http'); else withProtocol">
                        <a href="{{ row.project.contactWebsite }}" target="_blank">{{ row.project.contactWebsite }}</a>
                    </ng-container>
                    <ng-template #withProtocol><a href="https://{{ row.project.contactWebsite }}"
                                                  target="_blank">{{ row.project.contactWebsite }}</a></ng-template>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef class="hideable-500 institution-cell"
                             mat-sort-header>{{ 'Type_of_project' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="hideable-500 institution-cell">
                <ng-container *ngFor="let type of row.projectTypes; let j = index">
                    <ng-container *ngIf="j <= MAX_PROJECT_TYPES_DISPLAYED">
                        {{ type.name }}
                        <ng-container *ngIf="j < row.projectTypes.length - 1">,&nbsp;</ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="row.projectTypes.length > MAX_PROJECT_TYPES_DISPLAYED">
                    {{ 'and_n_more' | translate:{n: (row.projectTypes.length - MAX_PROJECT_TYPES_DISPLAYED)} }}
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <button (click)="_onFavoriteClicked(row)"
                        *ngIf="!row.isLoading && row.rights.ADD_TO_FAVORITES"
                        [matTooltip]="(row.isFavorite ? 'Remove_from_favorites' : 'Investor_welcome_section1') | translate"
                        mat-icon-button>
                    <ng-container *ngIf="!row.isLoading">
                        <mat-icon *ngIf="row.isFavorite">star</mat-icon>
                        <mat-icon *ngIf="!row.isFavorite">star_outline</mat-icon>
                    </ng-container>
                </button>
                <button *ngIf="!row.isLoading && (row.rights.DELETE || row.rights.EDIT)"
                        [matMenuTriggerFor]="menu"
                        mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button (click)="onEditClicked.emit(row)"
                            *ngIf="row.rights.DELETE"
                            mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        {{ 'Edit' | translate }}
                    </button>
                    <button (click)="onDeleteClicked.emit(row)"
                            *ngIf="row.rights.DELETE"
                            mat-menu-item>
                        <mat-icon>delete_outline</mat-icon>
                        {{ 'Delete' | translate }}
                    </button>
                </mat-menu>
                <mat-spinner *ngIf="row.isLoading"
                             [diameter]="24">
                </mat-spinner>
            </mat-cell>
        </ng-container>
    </mat-table>
    <mat-paginator [class.hidden]="dataSource.count == 0"
                   [pageSizeOptions]="[50]"
                   hidePageSize
                   showFirstLastButtons>
    </mat-paginator>
</module-content>
