import { AfterViewInit, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export const TRUE_FALSE_RADIO_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrueFalseRadioComponent),
    multi: true
};

// doc pour le ngModel : http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'true-false-radio',
    providers: [TRUE_FALSE_RADIO_VALUE_ACCESSOR],
    styleUrls: ['./true-false-radio.scss'],
    templateUrl: './true-false-radio.html'
})
export class TrueFalseRadioComponent implements AfterViewInit, ControlValueAccessor {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public label: string = '';
    @Input() public trueLabel: string = this._translater.instant('Yes');
    @Input() public falseLabel: string = this._translater.instant('No');
    @Input() public color: string = 'primary';
    @Input() public disabled: boolean = false;
    @Output() public valueChange: EventEmitter<boolean> = new EventEmitter();
    @Output('change') public change: EventEmitter<boolean> = new EventEmitter();

    private _value: boolean = false;

    constructor(
        private _translater: TranslateService,
    ) {
    }

    private _internalValue: boolean = false;

    public get internalValue() {
        return this._internalValue;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public set internalValue(val: boolean) {
        this._internalValue = val;
        this._value = val;
        this.onChange(val);
        this.change.emit(val);
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    public writeValue(value: any): void {
        this._value = value;
        this._internalValue = value;
    }

    @HostListener('valChange')
    public onChange = (_: any) => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
