import { Injectable } from '@angular/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { CurrentUser } from '../../_common/providers/current-user';
import { SyncListener } from '../../_common/providers/sync-service';
import { Discussion, DiscussionMessage, PrivateDiscussion, PrivateDiscussion_User, User } from '../../_common/providers/models';

@Injectable()
export class DiscussionsProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _api: ApiBaseService,
        private _storage: ModelsStorage,
        private _currentUser: CurrentUser
    ) {

    }

    private _privateDiscussionsData: PrivateDiscussionData[] = [];

    public get privateDiscussionsData() {
        return this._privateDiscussionsData;
    }

    private _activePrivateDiscussions: ActivePrivateDiscussion[] = [];

    public get activePrivateDiscussions() {
        return this._activePrivateDiscussions;
    }

    private _privateDiscussionsDataWithUnreadMessages: PrivateDiscussionData[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    public get privateDiscussionsDataWithUnreadMessages() {
        return this._privateDiscussionsDataWithUnreadMessages;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getSyncListeners(): SyncListener[] {
        const privateDiscussionsListener: SyncListener = {
            id: '',
            onCommonDataSynced: null,
            onUserDataSynced: () => {
                this.updatePrivateDiscussions();
            }
        };

        return [privateDiscussionsListener];
    }

    public updatePrivateDiscussions(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.loadPrivateDiscussionsData()
                .then(() => {
                    this.updateActivePrivateDiscussion();
                    resolve();
                })
                .catch((error) => console.log(error));
        });
    }

    public sendNewMessage(api: string, message: DiscussionMessage): Promise<DiscussionMessage> {
        return new Promise<DiscussionMessage>((resolve, reject) => {
            this._api.call(
                'post',
                api,
                {discussionMessage: message.toJson()},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    message = DiscussionMessage.fromAPI(data.discussionMessage);
                    this._storage.save([message])
                        .then(() => resolve(message))
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    public deleteMessage(api: string, message: DiscussionMessage): Promise<DiscussionMessage | void> {
        return new Promise<DiscussionMessage | void>((resolve, reject) => {
            this._api.call(
                'post',
                api,
                {discussionMessage: message.toJson()},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    this._storage.delete([message])
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    public startGroupDiscussion(group: PrivateDiscussion): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // on check d'abord dans les discussions active si elle existe
            //      si c'est le cas
            //          si elle est minimisée, on la maximise
            //          sinon on fait rien du tout
            //      si c'est pas le cas
            //          on check si elle existe déjà dans les discussions disponibles
            //              si c'est le cas, on ajoute ses données dans la discussion active qu'on a déjà créée
            //              si c'est pas le cas, on retourne une erreur
            for (let i = 0; i < this._activePrivateDiscussions.length; i++) {
                if (this._activePrivateDiscussions[i].data.privateDiscussion.id === group.id) {
                    if (this._activePrivateDiscussions[i].isMinimized) {
                        this._activePrivateDiscussions[i].isMinimized = false;
                    }

                    this.updateLastSeen(this._privateDiscussionsData[i].privateDiscussion_user)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                    return;
                }
            }

            for (let i = 0; i < this._privateDiscussionsData.length; i++) {
                if (this._privateDiscussionsData[i].privateDiscussion.id === group.id) {
                    this._activePrivateDiscussions.push({
                        data: this._privateDiscussionsData[i],
                        isMinimized: false,
                        isReady: true
                    });

                    this.updateLastSeen(this._privateDiscussionsData[i].privateDiscussion_user)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                    return;
                }
            }

            reject({status: 404, message: 'Private group discussion not found.'});
        });
    }

    public startOneToOneDiscussion(user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // on check d'abord dans les discussions active si elle existe
            //      si c'est le cas
            //          si elle est minimisée, on la maximise
            //          sinon on fait rien du tout
            //      si c'est pas le cas
            //          on check si elle existe déjà dans les discussions disponibles
            //              si c'est le cas, on ajoute ses données dans la discussion active qu'on a déjà créée
            //              si c'est pas le cas
            //                  on check que l'utilisateur n'ait pas été supprimé
            //                      si c'est le cas, on retourne une erreur unknown
            //                      si c'est pas le cas
            //                          on ajoute une dicussion active sans donnée
            //                          on créé la discussion via l'api
            //                          on sauve les données
            //                          on ajoute les données à la liste des discussions disponibles
            //                          on ajoute les données dans la discussion active qu'on a déjà créée
            for (let i = 0; i < this._activePrivateDiscussions.length; i++) {
                if (!this._activePrivateDiscussions[i].data.privateDiscussion.isGroup && this._activePrivateDiscussions[i].data.usersData[0].user.id === user.id) {
                    if (this._activePrivateDiscussions[i].isMinimized) {
                        this._activePrivateDiscussions[i].isMinimized = false;
                    }

                    this.updateLastSeen(this._privateDiscussionsData[i].privateDiscussion_user)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                    return;
                }
            }

            for (let i = 0; i < this._privateDiscussionsData.length; i++) {
                if (!this._privateDiscussionsData[i].privateDiscussion.isGroup && this._privateDiscussionsData[i].usersData[0].user.id === user.id) {
                    this._activePrivateDiscussions.push({
                        data: this._privateDiscussionsData[i],
                        isMinimized: false,
                        isReady: true
                    });

                    this.updateLastSeen(this._privateDiscussionsData[i].privateDiscussion_user)
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                    return;
                }
            }

            if (user.deleteDate) {
                reject({error: 'User has been deleted'});
                return;
            }

            const randomId: any = this._utils.randomString(16);
            const tempPrivateDiscussion: PrivateDiscussion = new PrivateDiscussion();
            tempPrivateDiscussion.id = randomId;
            const newDiscussion: ActivePrivateDiscussion = {
                data: {
                    privateDiscussion: tempPrivateDiscussion,
                    privateDiscussion_user: new PrivateDiscussion_User,
                    usersData: [{
                        privateDiscussion_user: new PrivateDiscussion_User(),
                        user
                    }],
                    discussion: new Discussion(),
                    messages: [],
                    unreadMessagesCount: 0
                },
                isMinimized: false,
                isReady: false
            };
            this._activePrivateDiscussions.push(newDiscussion);
            const privateDiscussion: PrivateDiscussion = new PrivateDiscussion();
            privateDiscussion.isGroup = 0;
            privateDiscussion.name = null;
            privateDiscussion.image = null;
            this.savePrivateDiscussion(privateDiscussion, [user.id])
                .then((data: PrivateDiscussionData) => {
                    newDiscussion.data = data;
                    newDiscussion.isReady = true;

                    this.updateLastSeen(data.privateDiscussion_user)
                        .then(() => {
                            resolve();
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error) => {
                    for (let i = 0; i < this._activePrivateDiscussions.length; i++) {
                        if (this._activePrivateDiscussions[i].data.privateDiscussion.id === randomId) {
                            this._activePrivateDiscussions.splice(i);
                        }
                    }

                    reject(error);
                });
        });
    }

    public updateLastSeen(privateDiscussion_user: PrivateDiscussion_User): Promise<PrivateDiscussion_User> {
        return new Promise<PrivateDiscussion_User>((resolve, reject) => {
            this._api.call(
                'post',
                'users/' + privateDiscussion_user.userId + '/discussions/' + privateDiscussion_user.privateDiscussionId + '/messages-seen',
                {},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    privateDiscussion_user = PrivateDiscussion_User.fromAPI(data.privateDiscussion_user);
                    this._storage.save([privateDiscussion_user])
                        .then(() => {
                            for (let i = 0; i < this._privateDiscussionsData.length; i++) {
                                if (this._privateDiscussionsData[i].privateDiscussion_user.id === privateDiscussion_user.id) {
                                    const unreadMessagesCount: number = this.countUnreadMessages(privateDiscussion_user, this._privateDiscussionsData[i].messages);
                                    let hadUnreadMessages: boolean = false;
                                    for (let j = 0; !hadUnreadMessages && j < this._privateDiscussionsDataWithUnreadMessages.length; j++) {
                                        if (this._privateDiscussionsDataWithUnreadMessages[j].privateDiscussion_user.id === privateDiscussion_user.id) {
                                            hadUnreadMessages = true;
                                            if (unreadMessagesCount === 0) {
                                                this._privateDiscussionsDataWithUnreadMessages.splice(j, 1);
                                            }
                                        }
                                    }

                                    if (!hadUnreadMessages && unreadMessagesCount > 0) {
                                        this._privateDiscussionsDataWithUnreadMessages.push(this._privateDiscussionsData[i]);
                                    }

                                    this._privateDiscussionsData[i].privateDiscussion_user = privateDiscussion_user;
                                    this._privateDiscussionsData[i].unreadMessagesCount = unreadMessagesCount;
                                    resolve(privateDiscussion_user);
                                    return;
                                }
                            }

                            reject({error: 'Failed to update privateDiscussion_user in provider'});
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public closePrivateDiscussion(activePrivateDiscussion: ActivePrivateDiscussion): void {
        for (let i = 0; i < this._activePrivateDiscussions.length; i++) {
            if (this._activePrivateDiscussions[i].data.privateDiscussion.id === activePrivateDiscussion.data.privateDiscussion.id) {
                this._activePrivateDiscussions.splice(i, 1);
                return;
            }
        }
    }

    public savePrivateDiscussion(privateDiscussion: PrivateDiscussion, userIds: number[]): Promise<PrivateDiscussionData> {
        return new Promise<PrivateDiscussionData>((resolve, reject) => {
            this._api.call(
                'post',
                'users/' + this._currentUser.user.id + '/discussions/' + (privateDiscussion.id ? privateDiscussion.id : 0),
                {
                    userIds,
                    isGroup: privateDiscussion.isGroup ? 1 : 0,
                    name: privateDiscussion.name,
                    image: privateDiscussion.image
                },
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    privateDiscussion = PrivateDiscussion.fromAPI(data.privateDiscussion);
                    const privateDiscussion_users: PrivateDiscussion_User[] = PrivateDiscussion_User.fromAPIList(data.privateDiscussion_users);
                    const discussion: Discussion = Discussion.fromAPI(data.discussion);
                    Promise.all([
                        this._storage.save([discussion]),
                        this._storage.save([privateDiscussion]),
                        this._storage.save(privateDiscussion_users),
                        this._storage.select(User).get()
                    ])
                        .then((storageData: any[]) => {
                            const privateDiscussion_user: PrivateDiscussion_User = this._utils.findIn(this._currentUser.user.id, privateDiscussion_users, 'userId');
                            let messages: DiscussionMessage[] = [];
                            let unreadCount: number = 0;
                            let dataIndex: number = -1;
                            for (let i = 0; i < this._privateDiscussionsData.length; i++) {
                                if (this._privateDiscussionsData[i].privateDiscussion.id === privateDiscussion.id) {
                                    dataIndex = i;
                                    messages = this._privateDiscussionsData[i].messages;
                                    unreadCount = this._privateDiscussionsData[i].unreadMessagesCount;
                                    i = this._privateDiscussionsData.length;
                                }
                            }
                            const usersData: PrivateDiscussionUserData[] = [];
                            for (let j = 0; j < privateDiscussion_users.length; j++) {
                                if (privateDiscussion_users[j].userId !== this._currentUser.user.id) {
                                    usersData.push({
                                        user: this._utils.findIn(privateDiscussion_users[j].userId, storageData[3], 'id'),
                                        privateDiscussion_user: privateDiscussion_users[j]
                                    });
                                }
                            }
                            const privateDiscussionData: PrivateDiscussionData = {
                                privateDiscussion,
                                privateDiscussion_user,
                                usersData,
                                discussion,
                                messages,
                                unreadMessagesCount: unreadCount
                            };
                            if (dataIndex >= 0) {
                                this._privateDiscussionsData.push(privateDiscussionData);
                            } else {
                                this._privateDiscussionsData.splice(0, 0, privateDiscussionData);
                            }

                            resolve(privateDiscussionData);
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadPrivateDiscussionsData(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this._currentUser.isLoggedIn()) {
                this._privateDiscussionsData = [];
                resolve();
                return;
            }

            this._storage.select(PrivateDiscussion_User).where('userId').equals(this._currentUser.user.id).get()
                .then((privateDiscussion_users: PrivateDiscussion_User[]) => {
                    const privateDiscussionIds: number[] = this._utils.getKeyValues(privateDiscussion_users, 'privateDiscussionId');
                    Promise.all([
                        this._storage.fromIds(PrivateDiscussion, privateDiscussionIds),
                        this._storage.select(PrivateDiscussion_User)
                            .where('privateDiscussionId').in(privateDiscussionIds)
                            .and('userId').notEquals(this._currentUser.user.id)
                            .get()
                    ])
                        .then((data1: any[]) => {
                            const discussionIds: number[] = this._utils.getKeyValues(data1[0], 'discussionId');
                            const userIds: number[] = this._utils.getKeyValues(data1[1], 'userId', true);
                            Promise.all([
                                this._storage.fromIds(Discussion, discussionIds),
                                this._storage.select(DiscussionMessage).where('discussionId').in(discussionIds).get(),
                                this._storage.fromIds(User, userIds, true)
                            ])
                                .then((data2: any[]) => {
                                    const data: PrivateDiscussionData[] = [];
                                    const unreadData: PrivateDiscussionData[] = [];
                                    for (let i = 0; i < data1[0].length; i++) {
                                        const usersData: PrivateDiscussionUserData[] = [];
                                        for (let j = 0; j < data1[1].length; j++) {
                                            if (data1[1][j].privateDiscussionId === data1[0][i].id) {
                                                usersData.push({
                                                    user: this._utils.findIn(data1[1][j].userId, data2[2], 'id'),
                                                    privateDiscussion_user: data1[1][j]
                                                });
                                            }
                                        }

                                        const privateDiscussion_user: PrivateDiscussion_User = this._utils.findIn(data1[0][i].id, privateDiscussion_users, 'privateDiscussionId');
                                        const messages: DiscussionMessage[] = this._utils.findManyIn([data1[0][i].discussionId], data2[1], 'discussionId');
                                        const unreadMessagesCount: number = this.countUnreadMessages(privateDiscussion_user, messages);
                                        const privateDiscussionData: PrivateDiscussionData = {
                                            privateDiscussion: data1[0][i],
                                            privateDiscussion_user,
                                            usersData,
                                            discussion: this._utils.findIn(data1[0][i].discussionId, data2[0], 'id'),
                                            messages,
                                            unreadMessagesCount
                                        };
                                        data.push(privateDiscussionData);
                                        if (unreadMessagesCount > 0) {
                                            unreadData.push(privateDiscussionData);
                                        }
                                    }

                                    this._privateDiscussionsData = this.sortPrivateDiscussionsData(data);
                                    this._privateDiscussionsDataWithUnreadMessages = unreadData;
                                    resolve();
                                })
                                .catch((error) => reject(error));
                        })
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    private updateActivePrivateDiscussion(): void {
        let i: number = 0;
        while (i < this._activePrivateDiscussions.length) {
            let found: boolean = false;
            for (let j = 0; !found && j < this._privateDiscussionsData.length; j++) {
                if (this._privateDiscussionsData[j].privateDiscussion.id === this._activePrivateDiscussions[i].data.privateDiscussion.id) {
                    found = true;
                    this._activePrivateDiscussions[i].data = this._privateDiscussionsData[j];
                }
            }

            if (!found) {
                this._activePrivateDiscussions.splice(i, 1);
            } else {
                i++;
            }
        }
    }

    private countUnreadMessages(privateDiscussion_user: PrivateDiscussion_User, messages: DiscussionMessage[]): number {
        if (!privateDiscussion_user.lastSeen) {
            return messages.length;
        }

        let count: number = 0;
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].createDate > privateDiscussion_user.lastSeen) {
                count++;
            }
        }

        return count;
    }

    private sortPrivateDiscussionsData(data: PrivateDiscussionData[]): PrivateDiscussionData[] {
        data.sort((a: PrivateDiscussionData, b: PrivateDiscussionData) => {
            let aDate: string = a.privateDiscussion.createDate;
            if (a.messages.length > 0) {
                aDate = a.messages[a.messages.length - 1].createDate;
            }
            let bDate: string = b.privateDiscussion.createDate;
            if (b.messages.length > 0) {
                bDate = b.messages[b.messages.length - 1].createDate;
            }

            return aDate < bDate ? 1 : -1;
        });

        return data;
    }
}

export interface PrivateDiscussionUserData {
    user: User;
    privateDiscussion_user: PrivateDiscussion_User;
}

export interface PrivateDiscussionData {
    privateDiscussion: PrivateDiscussion;
    privateDiscussion_user: PrivateDiscussion_User;
    usersData: PrivateDiscussionUserData[];
    discussion: Discussion;
    messages: DiscussionMessage[];
    unreadMessagesCount: number;
}

export interface ActivePrivateDiscussion {
    data: PrivateDiscussionData;
    isMinimized: boolean;
    isReady: boolean;
}
