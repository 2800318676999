import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { Conf } from '../../../_conf';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { InvestorRights } from '../../providers/investor-rights';
import { InvestorsProvider } from '../../providers/investors-provider';
import {
    DevelopmentPhaseWithAssociatedProjectTypeGroups,
    ProjectsProvider
} from '../../../projects/providers/projects-provider';
import {
    Company,
    CompanyCategory,
    InvestorProfile,
    InvestorValidationState,
    TherapeuticArea
} from '../../../_common/providers/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'investors-edit-investor-profile',
    providers: [],
    styleUrls: ['./edit-investor-profile.scss'],
    templateUrl: './edit-investor-profile.html'
})
export class InvestorsEditInvestorProfileComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public companyFormControl: FormControl = new FormControl('', [
        Validators.min(1)
    ]);

    public investmentRangeFromFormControl: FormControl = new FormControl('', [
        Validators.min(0),
        (control: any) => this.checkInvestmentRange(),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);

    public investmentRangeToFormControl: FormControl = new FormControl('', [
        Validators.min(0),
        (control: any) => this.checkInvestmentRange(),
        (control: any) => !control.value || Number.isInteger(control.value) ? null : {integer: {valid: false}}
    ]);

    public therapeuticAreaFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.min(1)
    ]);

    public developmentPhaseFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.min(1)
    ]);

    public isInterstedInNotForProfit: boolean = false;
    public isInterstedInPartnerships: boolean = false;
    public isInterstedInLicenses: boolean = false;
    public acceptedToBeContacted: boolean = false;
    public isNotificationsAlertEnabled: boolean = false;
    public isNotificationsDevPhaseEnabled: boolean = false;
    public isNotificationsSeekingFundsEnabled: boolean = false;
    public isNotificationsLeadInvestorEnabled: boolean = false;
    @Output() public investorProfileChange: EventEmitter<InvestorProfile> = new EventEmitter<InvestorProfile>();
    @Output() public therapeuticAreaIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() public developmentPhaseIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() public onFormChanged: EventEmitter<any> = new EventEmitter<any>();
    public companyCategories: CompanyCategory[] = [];
    public therapeuticAreas: TherapeuticArea[] = [];
    public developmentPhasesWithAssociatedProjectTypeGroups: DevelopmentPhaseWithAssociatedProjectTypeGroups[] = [];
    public companies: Company[] = [];
    public linkedCompany: Company = null;
    public ID_ACCEPTED: number = InvestorValidationState.ID_ACCEPTED;

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        private _investorsProvider: InvestorsProvider,
        private _projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _changeDetector: ChangeDetectorRef,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _investorProfile: InvestorProfile = new InvestorProfile();

    public get investorProfile() {
        return this._investorProfile;
    }

    @Input()
    public set investorProfile(investorProfile: InvestorProfile) {
        if (!investorProfile) {
            this.investorProfile = new InvestorProfile();
            return;
        }

        this._investorProfile = investorProfile;
        this.companyFormControl.setValue(investorProfile.companyId || '');
        this.investmentRangeFromFormControl.setValue(investorProfile.investmentRangeFrom);
        this.investmentRangeToFormControl.setValue(investorProfile.investmentRangeTo);
        this.isInterstedInNotForProfit = investorProfile.isInterstedInNotForProfit ? true : false;
        this.isInterstedInPartnerships = investorProfile.isInterstedInPartnerships ? true : false;
        this.isInterstedInLicenses = investorProfile.isInterstedInLicenses ? true : false;
        this.acceptedToBeContacted = investorProfile.acceptToBeContacted ? true : false;
        this.isNotificationsAlertEnabled = investorProfile.isNotificationsAlertEnabled ? true : false;
        this.isNotificationsDevPhaseEnabled = investorProfile.isNotificationsDevPhaseEnabled ? true : false;
        this.isNotificationsSeekingFundsEnabled = investorProfile.isNotificationsSeekingFundsEnabled ? true : false;
        this.isNotificationsLeadInvestorEnabled = investorProfile.isNotificationsLeadInvestorEnabled ? true : false;
    }

    private _therapeuticAreaIds: number[] = [];

    public get therapeuticAreaIds() {
        return this._therapeuticAreaIds;
    }

    @Input()
    public set therapeuticAreaIds(therapeuticAreaIds: number[]) {
        this._therapeuticAreaIds = therapeuticAreaIds;
        this.therapeuticAreaFormControl.setValue(therapeuticAreaIds);
    }

    private _developmentPhaseIds: number[] = [];

    public get developmentPhaseIds() {
        return this._developmentPhaseIds;
    }

    @Input()
    public set developmentPhaseIds(developmentPhaseIds: number[]) {
        this._developmentPhaseIds = developmentPhaseIds;
        this.developmentPhaseFormControl.setValue(developmentPhaseIds);
    }

    private _investorRights = new InvestorRights(this._utils);

    public get investorRights() {
        return this._investorRights;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set investorRights(investorRights: InvestorRights) {
        this._investorRights = investorRights ? investorRights : new InvestorRights(this._utils);
        this.updateRights();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.loadStaticData().catch((error: any) => this.onError(error));
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        return !this.companyFormControl.invalid
            && !this.investmentRangeFromFormControl.invalid
            && !this.investmentRangeToFormControl.invalid
            && !this.therapeuticAreaFormControl.invalid
            && !this.developmentPhaseFormControl.invalid;
    }

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    public onCategoryChanged(): void {
        this._onFormChanged();
        this._changeDetector.detectChanges();
    }

    public onInvestmentRangeChanged(): void {
        this.investmentRangeFromFormControl.updateValueAndValidity();
        this.investmentRangeToFormControl.updateValueAndValidity();
        this._onFormChanged();
    }

    public onValidateClicked(accepted: boolean): void {
        /*
        let dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: accepted ? 'Investor_validation_explanation_1' : 'Investor_validation_explanation_2',
                confirmActionText: accepted ? 'Accept' : 'Refuse',
                confirmColor: accepted ? 'primary' : 'warn'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this._investorsProvider.validateProfile(this.investorProfile, accepted ? InvestorValidationState.ID_ACCEPTED : InvestorValidationState.ID_REFUSED)
                    .then(() => {
                        this.isLoading = false;
                        this._router.navigate(['investors', 'pending-validation']);
                        this._toaster.open(this._translater.instant('User_saved'), '', { duration: 5000 });
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
        */
        this.isLoading = true;
        this._investorsProvider.validateProfile(this.investorProfile, accepted ? InvestorValidationState.ID_ACCEPTED : InvestorValidationState.ID_REFUSED)
            .then(() => {
                this.isLoading = false;
                this._router.navigate(['investors', 'pending-validation']);
                this._toaster.open(this._translater.instant('User_saved'), '', {duration: 5000});
            })
            .catch((error: any) => this.onError(error));
    }

    public onAllTherapeuticAreasClicked(): void {
        this.therapeuticAreaFormControl.setValue(this._utils.getKeyValues(this.therapeuticAreas, 'id'));
        this._onFormChanged();
    }

    public onNoTherapeuticAreasClicked(): void {
        this.therapeuticAreaFormControl.setValue([]);
        this._onFormChanged();
    }

    public onEditClicked(): void {
        this._router.navigate(['investors', 'companies', this._investorProfile.companyId, 'edit'], {
            queryParams: {backUrl: this._router.url, backTitle: this._translater.instant('My_account')}
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private loadStaticData(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            Promise.all([
                this._storage.select(CompanyCategory).orderBy('ordinal').get(),
                this._storage.select(TherapeuticArea).orderBy('name').get(),
                this._projectsProvider.getDevelopmentPhasesWithAssociatedProjectTypeGroups(),
                this._storage.select(Company).get()
            ])
                .then((data: any[]) => {
                    this.companyCategories = data[0];
                    this.therapeuticAreas = data[1];
                    this.developmentPhasesWithAssociatedProjectTypeGroups = data[2];
                    this.companies = data[3];
                    this.linkedCompany = this._utils.findIn(this._investorProfile.companyId, this.companies);
                })
                .catch((error: any) => reject(error));
        });
    }

    private updateRights(): void {
        this.companyFormControl.disable();
        if (this.investorRights.EDIT) {
            this.therapeuticAreaFormControl.enable();
            this.developmentPhaseFormControl.enable();
            this.investmentRangeFromFormControl.enable();
            this.investmentRangeToFormControl.enable();
        } else {
            this.therapeuticAreaFormControl.disable();
            this.developmentPhaseFormControl.disable();
            this.investmentRangeFromFormControl.disable();
            this.investmentRangeToFormControl.disable();
        }

        if (this.currentUser.user.isAdmin) {
            this.companyFormControl.enable();
        }
    }

    private fireChange(): void {
        this._investorProfile.companyId = this.companyFormControl.value;
        this._investorProfile.investmentRangeFrom = this.investmentRangeFromFormControl.value;
        this._investorProfile.investmentRangeTo = this.investmentRangeToFormControl.value;
        this._investorProfile.isInterstedInNotForProfit = this.isInterstedInNotForProfit ? 1 : 0;
        this._investorProfile.isInterstedInPartnerships = this.isInterstedInPartnerships ? 1 : 0;
        this._investorProfile.isInterstedInLicenses = this.isInterstedInLicenses ? 1 : 0;
        this._investorProfile.acceptToBeContacted = this.acceptedToBeContacted ? 1 : 0;
        this._investorProfile.isNotificationsAlertEnabled = this.isNotificationsAlertEnabled ? 1 : 0;
        this._investorProfile.isNotificationsDevPhaseEnabled = this.isNotificationsDevPhaseEnabled ? 1 : 0;
        this._investorProfile.isNotificationsSeekingFundsEnabled = this.isNotificationsSeekingFundsEnabled ? 1 : 0;
        this._investorProfile.isNotificationsLeadInvestorEnabled = this.isNotificationsLeadInvestorEnabled ? 1 : 0;
        this._therapeuticAreaIds = this.therapeuticAreaFormControl.value ? this.therapeuticAreaFormControl.value : [];
        this._developmentPhaseIds = this.developmentPhaseFormControl.value ? this.developmentPhaseFormControl.value : [];

        this.investorProfileChange.emit(this._investorProfile);
        this.therapeuticAreaIdsChange.emit(this._therapeuticAreaIds);
        this.developmentPhaseIdsChange.emit(this._developmentPhaseIds);
    }

    private checkInvestmentRange(): any {
        if (
            this.investmentRangeFromFormControl
            && this.investmentRangeToFormControl
            && this.investmentRangeFromFormControl.value
            && this.investmentRangeToFormControl.value
            && this.investmentRangeFromFormControl.value > this.investmentRangeToFormControl.value
        ) {
            return {inconsistency: {valid: false}};
        }

        return null;
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
