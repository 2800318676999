<div mat-dialog-title>{{ 'New_tag' | translate }}</div>
<mat-dialog-content>
    <mat-form-field class="text-box"
                    color="accent">
        <input [formControl]="tagNameFormControl"
               matInput
               maxlength="255"
               placeholder="{{ 'Tag_name' | translate }}"/>
        <mat-error *ngIf="tagNameFormControl.hasError('required')">{{ 'Tag_name_required' | translate }}</mat-error>
    </mat-form-field>
    <div>{{ 'Tag_color' | translate }}</div>
    <div class="colors">
        <div (click)="onColorClicked(i)"
             *ngFor="let color of availableColors; let i = index"
             [style.background-color]="color.color"
             [style.color]="color.textColor"
             class="color">
            <mat-icon *ngIf="color.color === tag.colorHex">check</mat-icon>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
    <button (click)="onAddClicked()"
            [disabled]="tagNameFormControl.hasError('required')
                || tag.colorHex === null
                || tag.textColorHex === null"
            color="accent"
            mat-raised-button>
        {{ submitText | translate}}
    </button>
</mat-dialog-actions>
