import { Routes } from '@angular/router';

//
// PAGES
//
import { HomePageComponent } from './_common/pages/home/home';
import { Error401PageComponent } from './_common/pages/error-401/error-401';
import { Error404PageComponent } from './_common/pages/error-404/error-404';
import { UsersEditPageComponent } from './users/pages/edit/edit';
import { LogoutPageComponent } from './_common/pages/logout/logout';
import { ProjectsEditPageComponent } from './projects/pages/edit/edit';
import { ProjectsProjectsPageComponent } from './projects/pages/projects/projects';
import { AlertsAlertsPageComponent } from './alerts/pages/alerts/alerts';
import { ProjectsFavoritesPageComponent } from './projects/pages/favorites/favorites';
import { ProjectsProjectPageComponent } from './projects/pages/project/project';
import { AlertsAlertPageComponent } from './alerts/pages/alert/alert';
import { ProjectsPendingValidationPageComponent } from './projects/pages/pending-validation/pending-validation';
import { InvestorsPendingValidationPageComponent } from './investors/pages/pending-validation/pending-validation';
import { InvestorsInvestorsPageComponent } from './investors/pages/investors/investors';
import { UsersAdminPageComponent } from './users/pages/admins/admins';
import { UsersGuestsPageComponent } from './users/pages/guests/guests';
import { ProjectsNotProjectYetPageComponent } from './projects/pages/no-project-yet/no-project-yet';
import { TermsPageComponent } from './_common/pages/terms/terms';
import { UsersLocalExpertsPageComponent } from './users/pages/local-experts/local-experts';
import { ProjectsDraftsPageComponent } from './projects/pages/drafts/drafts';
import { ProjectsDispatchPageComponent } from './projects/pages/dispatch/dispatch';
import { ProjectsDashboardPageComponent } from './projects/pages/dashboard/dashboard';
import { InvestorsHomePageComponent } from './investors/pages/home/home';
import { ProjectsHomePageComponent } from './projects/pages/home/home';
import { InvestorsWelcomePageComponent } from './investors/pages/welcome/welcome';
import { InvestorsEditCompanyPageComponent } from './investors/pages/edit-company/edit-company';

//
// GUARDS
//
import { GuardsSequenceGuard } from './_common/guards/guards-sequence';
import { IsAppReadyGuard } from './_common/guards/is-app-ready';
import { IsLoggedInGuard } from './_common/guards/is-logged-in';
import { IsAdminGuard } from './_common/guards/is-admin';
import { HasPendingChangesGuard } from './_common/guards/has-pending-changes';
import { UsersCanEditUserGuard } from './users/guards/can-edit-user';
import { HasHomepageRedirectGuard } from './_common/guards/has-homepage-redirect';
import { ProjectsMembershipsPageComponent } from './projects/pages/memberships/memberships';
import { InvestorsCompaniesPageComponent } from './investors/pages/companies/companies';
import { CanSeeCompanies } from './investors/guards/can-see-companies';
import { InvestorsCompanyPageComponent } from './investors/pages/company/company';
import { CanSeeCompany } from './investors/guards/can-see-company';
import { InvestorsDashboardPageComponent } from './investors/pages/dashboard/dashboard';

export const ROUTES: Routes = [
    {
        path: '',
        component: HomePageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, HasHomepageRedirectGuard]}
    },
    {
        path: '401',
        component: Error401PageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard]}
    },
    {
        path: '404',
        component: Error404PageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard]}
    },
    {
        path: 'logout',
        component: LogoutPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'terms',
        component: TermsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard]}
    },
    {
        path: 'auth?*nextUrl=*',
        component: HomePageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard]}
    },
    {
        path: 'users/:userId/edit',
        component: UsersEditPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, UsersCanEditUserGuard]}
    },
    {
        path: 'users/admins',
        component: UsersAdminPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'users/guests',
        component: UsersGuestsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'users/local-experts',
        component: UsersLocalExpertsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'investors/pending-validation',
        component: InvestorsPendingValidationPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'investors/companies',
        component: InvestorsCompaniesPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, CanSeeCompanies]}
    },
    {
        path: 'investors/companies/:id/edit',
        component: InvestorsEditCompanyPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, CanSeeCompany]}
    },
    {
        path: 'investors/companies/new',
        component: InvestorsEditCompanyPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'investors/companies/:id',
        component: InvestorsCompanyPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, CanSeeCompany]}
    },
    {
        path: 'investors/dashboard',
        component: InvestorsDashboardPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'investor',
        component: InvestorsHomePageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, HasHomepageRedirectGuard]}
    },
    {
        path: 'investors',
        component: InvestorsInvestorsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsAdminGuard]}
    },
    {
        path: 'academic-ventures/:projectId/dispatch/:userId',
        component: ProjectsDispatchPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/:projectId/edit/preview',
        component: ProjectsProjectPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/:projectId/edit',
        component: ProjectsEditPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/:projectId/dashboard',
        component: ProjectsDashboardPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/:projectId/memberships',
        component: ProjectsMembershipsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/pending-validation',
        component: ProjectsPendingValidationPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/drafts',
        component: ProjectsDraftsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/welcome',
        component: ProjectsNotProjectYetPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'alerts/:alertId',
        component: AlertsAlertPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures',
        component: ProjectsProjectsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'project-leader',
        component: ProjectsHomePageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, HasHomepageRedirectGuard]}
    },
    {
        path: 'welcome',
        component: InvestorsWelcomePageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'academic-ventures/:projectId',
        component: ProjectsProjectPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'alerts',
        component: AlertsAlertsPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: 'favorites',
        component: ProjectsFavoritesPageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard, IsLoggedInGuard]}
    },
    {
        path: '**',
        component: Error404PageComponent,
        canActivate: [GuardsSequenceGuard],
        canDeactivate: [HasPendingChangesGuard],
        data: {_guardsSequence: [IsAppReadyGuard]}
    },
];
