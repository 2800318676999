import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsCreateDialogComponent } from '../../projects/components/create-dialog/create-dialog';
import { MenuGroup, MenuItem } from '../../_common/components/side-menu/side-menu';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { CurrentUser } from '../../_common/providers/current-user';
import { UsersProvider } from '../../users/providers/users-provider';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { Company, InvestorProfile, InvestorValidationState, Project, ProjectMembership } from '../../_common/providers/models';

import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AcventuresNavigationProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _searchProjectsMenu: MenuItem = {
        icon: 'search',
        title: this._translater.instant('Search'),
        url: '/academic-ventures'
    };
    private _projectsPendingValidationMenu: MenuItem = {
        icon: 'assignment_late',
        title: this._translater.instant('Pending_validation'),
        url: '/academic-ventures/pending-validation'
    };
    private _projectsDraftsMenu: MenuItem = {
        icon: 'extension',
        title: this._translater.instant('Drafts'),
        url: '/academic-ventures/drafts'
    };
    private _searchInvestorsMenu: MenuItem = {
        icon: 'search',
        title: this._translater.instant('Search_investors'),
        url: '/investors'
    };
    private _investorsPendingValidationMenu: MenuItem = {
        icon: 'assignment_late',
        title: this._translater.instant('Investors_pending_validation'),
        url: '/investors/pending-validation'
    };
    private _guestUsersMenu: MenuItem = {
        icon: 'people',
        title: this._translater.instant('Guests'),
        url: '/users/guests'
    };
    private _localExpertUsersMenu: MenuItem = {
        icon: 'people',
        title: this._translater.instant('Local_experts'),
        url: '/users/local-experts'
    };
    private _adminUsersMenu: MenuItem = {
        icon: 'people',
        title: this._translater.instant('Administrators'),
        url: '/users/admins'
    };
    private _companiesMenu: MenuItem = {
        icon: 'business',
        title: this._translater.instant('Companies'),
        url: '/investors/companies'
    };
    private _exportUsersMenu: MenuItem = {
        icon: 'save_alt',
        title: this._translater.instant('Download_users_list'),
        onClicked: () => {
            this.onExportUsersMenuClicked();
        }
    };

    constructor(
        private _conf: Conf,
        private _translater: TranslateService,
        private _router: Router,
        private _usersProvider: UsersProvider,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {
    }

    private _menus: MenuGroup[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    public get menus() {
        return this._menus;
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public updateMenus(): void {
        if (this._currentUser.isLoggedIn()) {
            if (this._currentUser.user.isAdmin) {
                this.updateAdminMenus();
            } else if (this._currentUser.user.isGuest || this._currentUser.user.isInvestor) {
                this.updateInvestorMenus();
            } else if (this._currentUser.user.isProjectHolder) {
                this.updateProjectHolderMenus();
            } else if (this._currentUser.user.isLocalExpert) {
                this.updateLocalExpertMenus();
            }
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //
    //
    private addExternalLinks(): void {
        if (!this._menus.some((item) => item.title === 'Swiss Biotech Association')) {
            this._menus.push({
                title: 'Swiss Biotech Association',
                menus: [{
                    icon: 'analytics',
                    title: 'swissbiotech.org',
                    onClicked: () => {
                        if (window) {
                            window.open('https://swissbiotech.org');
                        }
                    }
                }]
            });
        }
    }

    private onExportUsersMenuClicked(): void {
        this._exportUsersMenu.isLoading = true;
        this._usersProvider.exportUsers()
            .then((file: any) => {
                const fileName: string = this._conf.platformName + ' - '
                    + this._translater.instant('Users')
                    + '.xlsx';
                FileSaver.saveAs(file, fileName);
                this._exportUsersMenu.isLoading = false;
            })
            .catch((error: any) => {
                this._exportUsersMenu.isLoading = false;
                console.log(error);
                this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
            });
    }

    private updateAdminMenus(): void {
        const menus: MenuGroup[] = [
            {
                title: this._translater.instant('Academic_ventures'),
                menus: [
                    this._searchProjectsMenu,
                    this._projectsPendingValidationMenu,
                    this._projectsDraftsMenu
                ]
            }, {
                title: this._translater.instant('Investors'),
                menus: [
                    this._searchInvestorsMenu,
                    this._investorsPendingValidationMenu,
                    this._companiesMenu
                ]
            }, {
                title: this._translater.instant('Back_office'),
                menus: [
                    this._guestUsersMenu,
                    this._localExpertUsersMenu,
                    this._adminUsersMenu,
                    this._exportUsersMenu
                ]
            }
        ];

        this._menus = menus;
        this.addExternalLinks();
    }

    private updateLocalExpertMenus(): void {
        const menus: MenuGroup[] = [
            {
                title: this._translater.instant('Academic_ventures'),
                menus: [
                    this._searchProjectsMenu,
                    this._projectsPendingValidationMenu
                ]
            }
        ];

        this._menus = menus;
        this.addExternalLinks();
    }

    private async updateInvestorMenus() {
        const menus: MenuGroup[] = [];

        try {
            const [profile, company] = await Promise.all([
                this._storage.select(InvestorProfile)
                    .where('userId').equals(this._currentUser.user.id).get(1),
                this._storage.select(Company).where('id')
                    .equals(InvestorProfile, 'companyId')
                    .and('userId', InvestorProfile).equals(this._currentUser.user.id).get(1)
            ]);

            if (this._currentUser.user.isGuest
                || profile.investorValidationStateId === InvestorValidationState.ID_ACCEPTED
                || profile.investorValidationStateId === InvestorValidationState.ID_PENDING) {
                menus.push({
                    title: this._translater.instant('Academic_ventures'),
                    menus: [
                        {
                            icon: 'dashboard',
                            title: 'Dashboard',
                            url: 'investors/dashboard',
                            chipColor: 'primary'
                        },
                        {
                            icon: 'search',
                            title: 'Search',
                            url: '/academic-ventures'
                        },
                        {
                            icon: 'star',
                            title: 'Favorites',
                            url: '/favorites'
                        },
                        {
                            icon: 'notifications',
                            title: 'Alerts',
                            url: '/alerts'
                        }
                    ]
                });
            }

            // SB-787 Allow unvalidated investors to update company
            if (!company.isCompleted) {
                // related company
                menus.push({
                    title: company.name,
                    menus: [
                        {
                            icon: 'edit',
                            title: 'Company_information',
                            url: '/investors/companies/' + company.id + '/edit'
                        },
                        {
                            icon: 'slideshow',
                            title: 'View_company',
                            url: '/investors/companies/' + company.id
                        }
                    ]
                });
            }

            this._menus = menus;
            this.addExternalLinks();

        } catch (e) {
            this.onError(e);
        }
    }

    private updateProjectHolderMenus(): void {
        const menus: MenuGroup[] = [{
            title: this._translater.instant('Investors'),
            menus: [
                this._companiesMenu
            ]
        }];
        this._storage.select(Project)
            .where('id').equals(ProjectMembership, 'projectId')
            .and('userId', ProjectMembership).equals(this._currentUser.user.id)
            .orderBy('name')
            .get()
            .then((projects: Project[]) => {
                for (let i = 0; i < projects.length; i++) {
                    menus.push({
                        title: projects[i].name,
                        menus: [
                            {
                                icon: 'dashboard',
                                title: 'Dashboard',
                                url: '/academic-ventures/' + projects[i].id + '/dashboard',
                                // chip: 'Beta',
                                chipColor: 'primary'
                            },
                            {
                                icon: 'edit',
                                title: 'Academic_venture_information',
                                url: '/academic-ventures/' + projects[i].id + '/edit'
                            },
                            {
                                icon: 'people',
                                title: 'Users',
                                url: '/academic-ventures/' + projects[i].id + '/memberships'
                            },
                            {
                                icon: 'slideshow',
                                title: 'Preview',
                                url: '/academic-ventures/' + projects[i].id + '/edit/preview'
                            }
                        ]
                    });
                }
                menus.push({
                    title: '',
                    menus: [{
                        icon: 'add_circle_outline',
                        title: 'New_project',
                        onClicked: () => {
                            this._dialoger.open(ProjectsCreateDialogComponent, {});
                        }
                    }]
                });
                this.addExternalLinks();
            })
            .catch((error: any) => this.onError(error));

        this._menus = menus;
    }

    private onError(error: any): void {
        console.log(error);
        this._menus = [];
    }
}

export interface GetMenuExtra {
}
