<h2 mat-dialog-title>{{ 'Invite_user' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="inviteUserForm" class="example-form" novalidate>
        <div class="line">
            <mat-form-field>
                <input formControlName="firstName"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{ 'First_name' | translate }}">
                <mat-error
                    *ngIf="f.firstName.errors && f.firstName.errors.required">{{ 'Field_required' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input formControlName="lastName"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{ 'Last_name' | translate }}">
                <mat-error
                    *ngIf="f.lastName.errors && f.lastName.errors.required">{{ 'Field_required' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div class="line">
            <mat-form-field class="full-width">
                <input formControlName="email"
                       matInput
                       maxlength="{{ NAME_MAX_LENGTH}}"
                       placeholder="{{'Email' | translate}}">
                <mat-error
                    *ngIf="f.email.errors && f.email.errors.required">{{ 'Field_required' | translate }}</mat-error>
                <mat-error
                    *ngIf="f.email.errors && f.email.errors.email">{{ 'Field_invalid_email' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </form>
    <p>{{ 'Project_invite_user_info' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
    <button (click)="onInviteClicked()" [disabled]="inviteUserForm.invalid" color="primary" mat-dialog-close
            mat-raised-button>{{ 'action.invite' | translate }}</button>
</mat-dialog-actions>

