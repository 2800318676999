import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'terms-page',
    providers: [],
    styleUrls: ['./terms.scss'],
    templateUrl: './terms.html'
})
export class TermsPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _title: Title,
        private _router: Router,
        private _route: ActivatedRoute,
        private _dialoger: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._title.setTitle(this._translater.instant('Terms_and_conditions') + ' | ' + this.conf.platformName);
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
