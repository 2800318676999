<module-content>
    <div class="splitter">
        <div class="flex-1">
            <div class="line title">
                {{ 'Project_visibility_for_the_public' | translate }}
            </div>
            <div class="line">
                <mat-radio-group (change)="_onFormChanged()"
                                 [(ngModel)]="projectVisibilityId"
                                 [disabled]="!rights.EDIT">
                    <mat-radio-button *ngFor="let option of projectVisibilities"
                                      [value]="option.id"
                                      color="primary">
                        {{ option.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="info-box flex-2">
            <mat-icon>info_outline</mat-icon>
            <div>{{ 'Project_visibility_info' | translate }}</div>
        </div>
    </div>
</module-content>
