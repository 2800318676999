import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Conf } from '../../../_conf';
import { Utils } from '../../../_common/providers/utils';
import { CurrentUser } from '../../../_common/providers/current-user';
import { UsersProvider } from '../../providers/users-provider';
import { LangsProvider } from '../../../_common/providers/langs-provider';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { UsersChangePasswordDialogComponent } from '../../components/change-password-dialog/change-password-dialog';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { ConfirmDialogComponent } from '../../../_common/components/confirm-dialog/confirm-dialog';
import {
    InvestorsEditInvestorProfileComponent
} from '../../../investors/components/edit-investor-profile/edit-investor-profile';
import { InvestorsProvider } from '../../../investors/providers/investors-provider';
import { InvestorRights } from '../../../investors/providers/investor-rights';
import { UserRights } from '../../providers/user-rights';
import {
    DevelopmentPhaseOfInterest,
    InvestorProfile,
    InvestorValidationState,
    Lang,
    TherapeuticAreaOfInterest,
    User
} from '../../../_common/providers/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'users-edit-page',
    providers: [],
    styleUrls: ['./edit.scss'],
    templateUrl: './edit.html'
})
export class UsersEditPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    public DESCRIPTION_MAX_LENGTH: number = 5000;

    //
    //
    // ATTRIBUTES
    //
    //

    public moreMenus: any[] = [];
    public hierarchy: any[] = [];
    public isLoading: boolean = true;
    public isError404: boolean = false;
    public isFromModerators: boolean = false;

    public backMenu: MenuItem = null;
    public backUrl: string = null;
    public backTitle: string = this._translater.instant('Back');

    public firstNameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);
    public lastNameFormControl: FormControl = new FormControl('', [
        Validators.required
    ]);
    public phoneFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.investorProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH),
        Validators.pattern(this._utils.INTERNATIONAL_PHONE_NUMBER_REGEX)
    ]);
    public langFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.min(1)
    ]);
    public introFormControl: FormControl = new FormControl('');
    public emailUnavailableError: boolean = false;
    public emailFormControl: FormControl = new FormControl('', [
        Validators.email,
        // doc : https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html#building-a-custom-validator
        (control: any) => {
            return this.emailUnavailableError ? {unavailable: {valid: false}} : null;
        }
    ]);
    public userLinkFormControls: FormControl[] = [];

    public user: User = new User();
    public avatarImage: any = {};
    public langs: Lang[] = [];
    public userLinksData: any[] = [];
    public investorProfile: InvestorProfile = null;
    public therapeuticAreaIdsOfInterest: number[] = [];
    public developmentPhaseIdsOfInterest: number[] = [];

    public investorRights: InvestorRights = new InvestorRights(this._utils);
    public userRights: UserRights = new UserRights(this._utils);

    @ViewChild(InvestorsEditInvestorProfileComponent, {static: false}) public investorForm: InvestorsEditInvestorProfileComponent;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        public investorProvider: InvestorsProvider,
        private _utils: Utils,
        private _title: Title,
        private _router: Router,
        private _route: ActivatedRoute,
        private _users: UsersProvider,
        private _langs: LangsProvider,
        private _storage: ModelsStorage,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _changesGuard: HasPendingChangesGuard,
        private _changeDetector: ChangeDetectorRef,
        private _dialoger: MatDialog
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this._route.params.subscribe((params) => {
            this.initPage();
        });
        this._route.queryParams.subscribe((params: any) => {
            if (params.backUrl) {
                this.backUrl = params.backUrl;
                if (params.backTitle) {
                    this.backTitle = params.backTitle;
                }
                this.backMenu = {
                    icon: 'arrow_back',
                    title: this.backTitle,
                    url: this.backUrl
                };
                this._changeDetector.detectChanges();
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //

    public canSave() {
        if (this.investorForm) {
            return this.isAddressValid() &&
                this.investorForm.therapeuticAreaFormControl.valid &&
                this.investorForm.developmentPhaseFormControl.valid;
        }
        return this.isAddressValid();
    }

    public validateFields(): void {
        this.phoneFormControl.markAsTouched();
        this.firstNameFormControl.markAsTouched();
        this.lastNameFormControl.markAsTouched();
        this.emailFormControl.markAsTouched();
        this.investorForm.therapeuticAreaFormControl.markAsTouched();
        this.investorForm.developmentPhaseFormControl.markAsTouched();
    }

    public onSaveClicked(): void {

        if (this.currentUser.user.isInvestor) {
            this.validateFields();

            if (!this.canSave()) {
                this._toaster.open(this._translater.instant('Field_validation_failure'), '', {duration: 5000});
                return;
            }
        }

        this.isLoading = true;
        this.save()
            .then((result) => {
                this.user = result[0];
                this.isLoading = false;
                this._changesGuard.unregisterChanges();

                if (this.currentUser.user.id === this.user.id
                    && (this.currentUser.user.avatar !== this.user.avatar
                        || this.currentUser.user.langId !== this.user.langId)) {
                    window.location.href = this._route.snapshot.url.join('/') + '?' + this._utils.randomString(8);
                } else {
                    this._toaster.open(this._translater.instant('User_saved'), '', {duration: 5000});
                    this.initNav();
                    if (this.currentUser.user.isInvestor &&
                        this.investorProfile.investorValidationStateId === InvestorValidationState.ID_PENDING) {
                        this._router.navigate(['/welcome']);
                    }
                }
            })
            .catch((error) => this.onError(error));
    }

    public onFormChange(): void {
        this._changesGuard.registerChanges();
    }

    public onEmailChange(): void {
        this._changesGuard.registerChanges();
        this.emailUnavailableError = false;
        this.emailFormControl.updateValueAndValidity();
    }

    public onAvatarImageChange(): void {
        this._changesGuard.registerChanges();
    }

    public onIntroChange(): void {
        this._changesGuard.registerChanges();
        if (this.user.intro && this.user.intro.length > this.DESCRIPTION_MAX_LENGTH) {
            this.user.intro = this.user.intro.substr(0, this.DESCRIPTION_MAX_LENGTH);
        }
    }

    public onLangChange(): void {
        this._changesGuard.registerChanges();
    }

    public onUserLinksChanged(): void {
        this._changesGuard.registerChanges();
    }

    public onChangePasswordClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(UsersChangePasswordDialogComponent, {
            data: {
                user: this.user
            }
        });
    }

    public onDeleteClicked(): void {
        const dialog: MatDialogRef<any> = this._dialoger.open(ConfirmDialogComponent, {
            data: {
                confirmText: 'Confirm_delete_user'
            }
        });
        dialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.isLoading = true;
                this._users.deleteUser(this.user)
                    .then(() => {
                        this.isLoading = false;
                        this._router.navigate(['/']);
                        this._toaster.open(this._translater.instant('User_deleted'), '', {duration: 5000});
                    })
                    .catch((error: any) => this.onError(error));
            }
        });
    }

    public isFormValid(): boolean {
        if (this.firstNameFormControl.invalid
            || this.lastNameFormControl.invalid
            || this.emailFormControl.invalid
        ) {
            return false;
        }

        for (let i = 0; i < this.userLinkFormControls.length; i++) {
            if (this.userLinkFormControls[i].invalid) {
                return false;
            }
        }

        if (this.investorForm) {
            if (!this.investorForm.canSave() || this.phoneFormControl.invalid) {
                return false;
            }
        }

        return true;
    }

    //
    private isAddressValid(): boolean {
        return this.phoneFormControl.valid &&
            this.emailFormControl.valid &&
            this.firstNameFormControl.valid &&
            this.lastNameFormControl.valid;
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private initPage(): void {
        this.loadData()
            .then(() => {
                this.initForms();
                this.initNav();
                this.isLoading = false;
                this._changeDetector.detectChanges();
            })
            .catch((error) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    private initNav(): void {
        const userName: string = this.user.firstName + ' ' + this.user.lastName;
        this._title.setTitle(userName + ' | ' + this.conf.platformName);
        this.moreMenus = [
            {
                name: 'Change_password', link: '.', click: () => {
                    this.onChangePasswordClicked();
                }
            }
        ];
        this.navigation.updateMenus();
    }

    private initForms(): void {
        this.firstNameFormControl.setValue(this.user.firstName);
        this.lastNameFormControl.setValue(this.user.lastName);
        this.emailFormControl.setValue(this.user.email);
        this.introFormControl.setValue(this.user.intro);
        this.langFormControl.setValue(this.user.langId);

        if (this.user.isInvestor) {
            this.phoneFormControl.setValue(this.investorProfile.phone || '');
        }

        if (this.userRights.EDIT) {
            this.firstNameFormControl.enable();
            this.lastNameFormControl.enable();
            this.emailFormControl.enable();
            this.phoneFormControl.enable();
            this.introFormControl.enable();
            this.langFormControl.enable();
        } else {
            this.firstNameFormControl.disable();
            this.lastNameFormControl.disable();
            this.emailFormControl.disable();
            this.phoneFormControl.disable();
            this.introFormControl.disable();
            this.langFormControl.disable();
        }

        this.userLinkFormControls = [];
        for (let i = 0; i < this.userLinksData.length; i++) {
            const control: FormControl = new FormControl('', [
                (control: any) => {
                    if (!control.value || !this.userLinksData[i].userLink.matchWith) {
                        return null;
                    }

                    return control.value.startsWith(this.userLinksData[i].userLink.matchWith) ? null : {wrongLink: {valid: false}};
                }
            ]);
            if (!this.userRights.EDIT) {
                control.disable();
            }
            this.userLinkFormControls.push(control);
        }
    }

    private loadData(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.langs = this._langs.getLangs();
            const userId: number = this.parseUrl();
            if (userId) {
                const promises: Promise<any>[] = [
                    /* 0 */ this._storage.fromId(User, [userId]),
                    /* 1 */ this._users.getUserUserLinksData(userId)
                ];

                Promise.all(promises)
                    .then((results) => {
                        if (results[0]) {
                            this.user = results[0];
                            this.avatarImage = {current: this.user.avatar, new: null};
                            this.userLinksData = results[1];

                            if (this.user.isInvestor) {
                                this._storage.select(InvestorProfile).where('userId').equals(this.user.id).get(1)
                                    .then((investorProfile: InvestorProfile) => {
                                        this.investorProfile = investorProfile;
                                        this.investorProfile.investmentRangeFrom = this.investorProfile.investmentRangeFrom ? this.investorProfile.investmentRangeFrom / 100 : this.investorProfile.investmentRangeFrom;
                                        this.investorProfile.investmentRangeTo = this.investorProfile.investmentRangeTo ? this.investorProfile.investmentRangeTo / 100 : this.investorProfile.investmentRangeTo;
                                        Promise.all([
                                            this._storage.select(TherapeuticAreaOfInterest).where('investorProfileId').equals(this.investorProfile.id).get(),
                                            this._storage.select(DevelopmentPhaseOfInterest).where('investorProfileId').equals(this.investorProfile.id).get()
                                        ])
                                            .then((data: any[]) => {
                                                this.therapeuticAreaIdsOfInterest = this._utils.getKeyValues(data[0], 'therapeuticAreaId');
                                                this.developmentPhaseIdsOfInterest = this._utils.getKeyValues(data[1], 'developmentPhaseId');

                                                this.investorRights.evaluate({
                                                    user: this.currentUser.user,
                                                    investorProfile: this.investorProfile
                                                });
                                                this.userRights.evaluate({
                                                    user: this.currentUser.user,
                                                    subjectUser: this.user
                                                });

                                                resolve();
                                            })
                                            .catch((error: any) => reject(error));
                                    })
                                    .catch((error: any) => reject(error));
                            } else {
                                this.userRights.evaluate({
                                    user: this.currentUser.user,
                                    subjectUser: this.user
                                });

                                resolve();
                            }
                        } else {
                            reject({status: 404});
                        }
                    })
                    .catch((error) => this.onError(error));
            } else {
                reject({status: 404});
            }
        });
    }

    private parseUrl(): number {
        let userId: number = 0;
        this.isFromModerators = false;
        const param1: any = this._route.snapshot.url[1].path;
        if (param1 === 'moderators') {
            userId = parseInt(this._route.snapshot.url[2].path, 10);
            this.isFromModerators = true;
        } else {
            userId = parseInt(param1, 10);
        }

        return userId;
    }

    private save(): Promise<any> {
        // on est obligé de cloner parce que sinon l'image qui est bindée
        // va devenir un fichier au lieu d'une url dans la barre de navigation
        const user: User = this.user.clone();
        if (this.avatarImage.new) {
            user.avatar = this.avatarImage.new;
        } else if (!this.avatarImage.current) {
            user.avatar = null;
        }
        user.firstName = this.firstNameFormControl.value;
        user.lastName = this.lastNameFormControl.value;
        user.email = this.emailFormControl.value;
        user.intro = this.introFormControl.value;
        user.langId = this.langFormControl.value;

        const promises: Promise<any>[] = [
            this._users.saveUser(user)
        ];

        if (this.user.isInvestor && (this.investorRights.EDIT || this.currentUser.user.isAdmin)) {
            const profile: InvestorProfile = this.investorProfile.clone();
            profile.phone = this.phoneFormControl.value;
            profile.investmentRangeFrom = profile.investmentRangeFrom ? profile.investmentRangeFrom * 100 : profile.investmentRangeFrom;
            profile.investmentRangeTo = profile.investmentRangeTo ? profile.investmentRangeTo * 100 : profile.investmentRangeTo;
            promises.push(this.investorProvider.saveInvestorProfile(profile));
            promises.push(this.investorProvider.saveTherapeuticAreaOfInterests(this.investorProfile.id, this.therapeuticAreaIdsOfInterest));
            promises.push(this.investorProvider.saveDevelopmentPhaseOfInterests(this.investorProfile.id, this.developmentPhaseIdsOfInterest));
        }

        return Promise.all(promises);
    }

    private onError(error: any): void {
        this.isLoading = false;

        if (error.status === 422) {
            if (error.error.errors['email']) {
                this.emailUnavailableError = true;
                this.emailFormControl.updateValueAndValidity();
            } else {
                this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
                console.log(error);
            }
        } else if (error.status === 404) {
            this._router.navigate(['404']);
        } else {
            this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
            console.log(error);
        }
    }

}
