import { AfterViewInit, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../providers/utils';

@Component({
    selector: 'item-list-dialog',
    providers: [],
    styleUrls: ['./item-list-dialog.scss'],
    templateUrl: './item-list-dialog.html'
})
export class ItemListDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public title: string = '';
    public items: string[] = [];

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<ItemListDialogComponent>,
        private _utils: Utils,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        this.title = this._data.title;
        this.items = this._data.items;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
