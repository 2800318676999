<div *ngIf="workspaces && workspaces.length > 0"
     [matMenuTriggerFor]="workspaceMenu"
     class="workspace-selector">
    <img *ngIf="activeWorkspaceLogo" class="logo" src="{{ activeWorkspaceLogo }}"/>
    <mat-icon *ngIf="!activeWorkspaceLogo" class="logo">cloud</mat-icon>
    <span class="name">{{ activeWorkspaceName }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
</div>
<mat-menu #workspaceMenu="matMenu">
    <ng-container *ngIf="currentUser.isLoggedIn()">
        <div *ngFor="let workspace of workspaces; let i = index"
             [class.active]="workspace.active"
             class="workspace-menu"
             mat-menu-item
             routerLink="{{ workspace.url }}">
            <img *ngIf="workspace.logo" class="logo" src="{{ workspace.logo }}"/>
            <mat-icon *ngIf="!workspace.logo" class="logo">cloud</mat-icon>
            <div class="name">{{ workspace.name }}</div>
        </div>
        <div class="workspace-menu-separation"></div>
        <button mat-menu-item>
            {{ 'New_organization' | translate }}
        </button>
        <ng-container *ngIf="currentUser.user.isAdmin && 1 > 2">
            <div class="workspace-menu-separation">
                {{ 'Administration' | translate }}
            </div>
            <button mat-menu-item routerLink="/new">
                {{ 'New_company' | translate }}
            </button>
            <button mat-menu-item routerLink="/users/moderators">
                {{ 'Moderators' | translate }}
            </button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!currentUser.isLoggedIn()">
        <auth>
            <button mat-menu-item>{{ 'Connection' | translate }}</button>
        </auth>
    </ng-container>
</mat-menu>
