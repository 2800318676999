import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tags-tag',
    providers: [],
    styleUrls: ['./tag.scss'],
    templateUrl: './tag.html'
})
export class TagsTagComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public text: string = '';
    @Input() public color: string = '#000';
    @Input() public textColor: string = '#fff';
    @Input() public actionIcon: string = null;
    @Input() public actionTooltip: string = null;
    @Output('action') public action: EventEmitter<any> = new EventEmitter();

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onActionClicked(): void {
        this.action.emit(true);
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
