import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { LangsProvider } from '../../_common/providers/langs-provider';
import { CurrentUser } from '../../_common/providers/current-user';
import { ModelsStorage } from '../../_common/providers/models-storage';
import {
    Alert,
    Alert_AcademicInstitution,
    Alert_DevelopmentPhase,
    Alert_FundingRound,
    Alert_ProjectType,
    Alert_TherapeuticArea
} from '../../_common/providers/models';

@Injectable()
export class AlertsProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _translater: TranslateService,
        private _api: ApiBaseService,
        private _langs: LangsProvider,
        private _utils: Utils,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public saveAlert(alert: Alert): Promise<Alert> {
        return new Promise<Alert>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + (alert.id ? alert.id : 0),
                this._utils.buildModelParams([alert], this._langs.getLangIds())[0],
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    alert = Alert.fromAPI(data.alert);
                    this._storage.save([alert])
                        .then(() => resolve(alert))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAlertProjectTypes(alertId: number, projectTypeIds: number[]): Promise<Alert_ProjectType[]> {
        return new Promise<Alert_ProjectType[]>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + alertId + '/project-types',
                {ids: projectTypeIds},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(Alert_ProjectType).where('alertId').equals(alertId).get()
                        .then((oldItems: Alert_ProjectType[]) => {
                            const newItems: Alert_ProjectType[] = Alert_ProjectType.fromAPIList(data.alert_projectTypes);
                            Promise.all([
                                this._storage.delete(oldItems),
                                this._storage.save(newItems)
                            ])
                                .then(() => resolve(newItems))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAlertTherapeuticAreas(alertId: number, therapeuticAreaIds: number[]): Promise<Alert_TherapeuticArea[]> {
        return new Promise<Alert_TherapeuticArea[]>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + alertId + '/therapeutic-areas',
                {ids: therapeuticAreaIds},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(Alert_TherapeuticArea).where('alertId').equals(alertId).get()
                        .then((oldItems: Alert_TherapeuticArea[]) => {
                            const newItems: Alert_TherapeuticArea[] = Alert_TherapeuticArea.fromAPIList(data.alert_therapeuticAreas);
                            Promise.all([
                                this._storage.delete(oldItems),
                                this._storage.save(newItems)
                            ])
                                .then(() => resolve(newItems))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAlertDevelopmentPhases(alertId: number, developmentPhaseIds: number[]): Promise<Alert_DevelopmentPhase[]> {
        return new Promise<Alert_DevelopmentPhase[]>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + alertId + '/development-phases',
                {ids: developmentPhaseIds},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(Alert_DevelopmentPhase).where('alertId').equals(alertId).get()
                        .then((oldItems: Alert_DevelopmentPhase[]) => {
                            const newItems: Alert_DevelopmentPhase[] = Alert_DevelopmentPhase.fromAPIList(data.alert_developmentPhases);
                            Promise.all([
                                this._storage.delete(oldItems),
                                this._storage.save(newItems)
                            ])
                                .then(() => resolve(newItems))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAlertFundingRounds(alertId: number, fundingRoundIds: number[]): Promise<Alert_FundingRound[]> {
        return new Promise<Alert_FundingRound[]>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + alertId + '/funding-rounds',
                {ids: fundingRoundIds},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(Alert_FundingRound).where('alertId').equals(alertId).get()
                        .then((oldItems: Alert_FundingRound[]) => {
                            const newItems: Alert_FundingRound[] = Alert_FundingRound.fromAPIList(data.alert_fundingRounds);
                            Promise.all([
                                this._storage.delete(oldItems),
                                this._storage.save(newItems)
                            ])
                                .then(() => resolve(newItems))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveAlertAcademicInstitutions(alertId: number, academicInstitutionIds: number[]): Promise<Alert_AcademicInstitution[]> {
        return new Promise<Alert_AcademicInstitution[]>((resolve, reject) => {
            this._api.call(
                'post',
                'alerts/' + alertId + '/academic-institutions',
                {ids: academicInstitutionIds},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    this._storage.select(Alert_AcademicInstitution).where('alertId').equals(alertId).get()
                        .then((oldItems: Alert_AcademicInstitution[]) => {
                            const newItems: Alert_AcademicInstitution[] = Alert_AcademicInstitution.fromAPIList(data.alert_academicInstitutions);
                            Promise.all([
                                this._storage.delete(oldItems),
                                this._storage.save(newItems)
                            ])
                                .then(() => resolve(newItems))
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public deleteAlert(alert: Alert): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'alerts/' + alert.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    this._storage.delete([alert])
                        .then(() => resolve())
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
