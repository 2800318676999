<module-content [isLoading]="isLoading"
                [isLoading]="isLoading"
                [shortcutMenus]="menus"
                [title]="'Key_data' | translate">
    <div class="line">
        <ng-container *ngIf="!isRaisingFundsEditMode">
            <div class="content">
                <div class="label">{{ 'Last_changed' | translate }}
                    : {{ project.isRaisingFundsUpdateDate | dateClosest }}</div>
                <div
                    class="text">{{ (project.isRaisingFunds ? 'Currently_raising_funds' : 'Currently_not_raising_funds') | translate }}</div>
            </div>
            <div *ngIf="rights.EDIT"
                 class="actions">
                <button (click)="onEditIsRaisingFundsClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Edit' | translate"
                        color="primary"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isRaisingFundsEditMode && rights.EDIT">
            <div class="content">
                <true-false-radio [(ngModel)]="isRaisingFunds"
                                  [label]="'Currently_raising_funds' | translate">
                </true-false-radio>
            </div>
            <div class="actions">
                <button (click)="onCancelEditIsRaisingFundsClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Cancel' | translate"
                        mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
                <button (click)="onSaveClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Save' | translate"
                        color="primary"
                        mat-mini-fab>
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
    <div *ngIf="project.isRaisingFunds"
         class="line">
        <ng-container *ngIf="!isLeadInvestorIdentifiedEditMode">
            <div class="content">
                <div class="label">{{ 'Last_changed' | translate }}
                    : {{ project.isLeadInvestorIdentifiedUpdateDate | dateClosest }}</div>
                <div
                    class="text">{{ (project.isLeadInvestorIdentified ? 'Lead_investor_identified' : 'Lead_investor_not_identified_yet') | translate }}</div>
            </div>
            <div *ngIf="rights.EDIT"
                 class="actions">
                <button (click)="onEditIsLeadInvestorIdentifiedClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Edit' | translate"
                        color="primary"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isLeadInvestorIdentifiedEditMode && rights.EDIT">
            <div class="content">
                <true-false-radio [(ngModel)]="isLeadInvestorIdentified"
                                  [label]="'Lead_investor_identified' | translate">
                </true-false-radio>
            </div>
            <div class="actions">
                <button (click)="onCancelEditIsLeadInvestorIdentifiedClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Cancel' | translate"
                        mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
                <button (click)="onSaveClicked()"
                        [disabled]="isLoading"
                        [matTooltip]="'Save' | translate"
                        color="primary"
                        mat-mini-fab>
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</module-content>
