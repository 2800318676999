<mat-dialog-content #dialogContainer>
    <div class="header">
        <img src="{{ image }}"/>
        <span>{{ title }}</span>
    </div>
    <div class="steps">
        <!-- ------------------------------------------------------------ EMAIL ------------------------------ -->
        <!--<div class="step" [@translateFadeBeforeAfter]="emailStepState">-->
        <div *ngIf="emailStepState == 'active'"
             class="step">
            <div class="title">
                <div *ngIf="isIHaveAnAccountText">{{ 'Connection' | translate }}</div>
                <div *ngIf="isIHaveAnAccountText"
                     class="sub">{{ 'Use_your_x_account' | translate:{platformName: conf.platformName} }}</div>
                <div *ngIf="!isIHaveAnAccountText">{{ 'Registration' | translate }}</div>
                <div *ngIf="!isIHaveAnAccountText"
                     class="sub">{{ 'Create_your_x_account' | translate:{platformName: conf.platformName} }}</div>
            </div>
            <div class="input">
                <mat-form-field color="primary">
                    <input #emailControl
                           (keyup.enter)="onCheckEmailClicked($event)"
                           [formControl]="emailFormControl"
                           matInput
                           maxlength="255"
                           placeholder="{{ 'Email_address' | translate }}"
                           type="email"/>
                    <span matPrefix><mat-icon>mail_outline</mat-icon> &nbsp;</span>
                    <mat-error *ngIf="emailFormControl.hasError('email')">{{ 'Email_invalid' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="actions">
                <button (click)="onCheckEmailClicked($event)"
                        [disabled]="isLoading || emailFormControl.hasError('email') || emailFormControl.hasError('required') ? '':null"
                        color="accent"
                        mat-raised-button>
                    {{ 'Next' | translate }}
                </button>
                <ng-container *ngIf="!isRegistrationDisabled">
                    <button (click)="onNoAccountClicked()"
                            *ngIf="isIHaveAnAccountText"
                            [disabled]="isLoading"
                            class="link"
                            color="accent"
                            mat-button>
                        {{ 'I_dont_have_an_account' | translate }}
                    </button>
                    <button (click)="onUseAccountClicked()"
                            *ngIf="!isIHaveAnAccountText"
                            [disabled]="isLoading"
                            class="link"
                            color="accent"
                            mat-button>
                        {{ 'I_have_an_account' | translate }}
                    </button>
                </ng-container>
            </div>
        </div>
        <!-- ------------------------------------------------------------ PASSWORD ------------------------------ -->
        <!--div class="step" [@translateFadeBeforeAfter]="passwordStepState">-->
        <div *ngIf="passwordStepState == 'active'"
             class="step">
            <div class="title">
                <div>{{ 'Welcome' | translate }}</div>
                <div (click)="onNotYouClicked()" class="sub link">
                    <mat-icon>arrow_back</mat-icon>
                    {{ emailFormControl.value }}</div>
            </div>
            <div class="input">
                <mat-form-field *ngIf="!currentUser.isLoggedIn()" color="primary">
                    <input #passwordControl
                           (keyup)="onPasswordKeyPressed()"
                           (keyup.enter)="onCheckPasswordClicked($event)"
                           [formControl]="passwordFormControl"
                           [type]="showLoginPassword ? 'text' : 'password'"
                           matInput
                           maxlength="255"
                           placeholder="{{ 'Password' | translate }}"/>
                    <span matPrefix><mat-icon>lock_outline</mat-icon> &nbsp;</span>
                    <mat-error
                        *ngIf="passwordFormControl.hasError('required')">{{ 'Password_required' | translate }}</mat-error>
                    <mat-error
                        *ngIf="passwordFormControl.hasError('passwordWrong')">{{ 'Password_wrong' | translate }}</mat-error>
                    <button (click)="showLoginPassword = !showLoginPassword"
                            [matTooltip]="(showLoginPassword ? 'Hide' : 'Show') | translate"
                            mat-icon-button
                            matSuffix>
                        <mat-icon>{{ showLoginPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                    </button>
                </mat-form-field>
                <div>
                    <mat-checkbox [(ngModel)]="rememberMe"
                                  color="primary">{{ 'Remember_me' | translate }}</mat-checkbox>
                </div>
            </div>
            <div class="actions">
                <button (click)="onCheckPasswordClicked($event)"
                        [disabled]="isLoading || passwordFormControl.hasError('required') || passwordFormControl.hasError('passwordWrong') ? '':null"
                        color="accent"
                        mat-raised-button>
                    {{ 'Next' | translate }}
                </button>
                <button (click)="onForgotPasswordClicked()"
                        [disabled]="isLoading"
                        class="link"
                        color="accent"
                        mat-button>
                    {{ 'Forgot_password' | translate }} ?
                </button>
            </div>
        </div>
        <!-- ------------------------------------------------------------ UNAUTHORIZED ------------------------------ -->
        <!--<div class="step" [@translateFadeBeforeAfter]="unauthorizedStepState">-->
        <div *ngIf="unauthorizedStepState == 'active'"
             class="step">
            <div class="title">
                <div>:-(</div>
                <div class="sub">{{ 'You_are_not_authorized_here' | translate }}</div>
            </div>
            <div class="actions">
                <button (click)="onNotYouClicked()"
                        [disabled]="isLoading"
                        color="accent"
                        mat-raised-button>
                    {{ 'Back' | translate }}
                </button>
            </div>
        </div>
        <!-- ------------------------------------------------------------ FORGOT PASSWORD ------------------------------ -->
        <!--<div class="step" [@translateFadeBeforeAfter]="forgotPasswordState">-->
        <div *ngIf="forgotPasswordState == 'active'"
             class="step">
            <div class="title">
                <div>{{ 'Recover_password' | translate }}</div>
                <div
                    class="sub">{{ 'Instructions_to_renew_password_sent' | translate:{email: emailFormControl.value} }}</div>
            </div>
            <div class="actions">
                <button (click)="onNotYouClicked()"
                        [disabled]="isLoading"
                        color="accent"
                        mat-raised-button>
                    {{ 'Ok' | translate }}
                </button>
            </div>
        </div>
        <!-- ------------------------------------------------------------ RESET PASSWORD ------------------------------ -->
        <!--<div class="step" [@translateFadeBeforeAfter]="resetPasswordState">-->
        <div *ngIf="resetPasswordState == 'active'"
             class="step">
            <div class="title">
                <div>{{ 'Create_password' | translate }}</div>
                <div class="sub">{{ emailFormControl.value }}</div>
            </div>
            <div class="input">
                <mat-form-field *ngIf="!currentUser.isLoggedIn()"
                                color="primary">
                    <input #resetPasswordControl
                           (keyup.enter)="onResetPasswordClicked($event)"
                           [formControl]="resetPasswordFormControl"
                           [type]="showResetPasswordPassword ? 'text' : 'password'"
                           autocomplete="off"
                           matInput
                           maxlength="255"
                           placeholder="{{ 'New_password' | translate }}"/>
                    <mat-hint>{{ 'New_password_hint' | translate }}</mat-hint>
                    <mat-error
                        *ngIf="resetPasswordFormControl.hasError('required')">{{ 'Password_required' | translate }}</mat-error>
                    <mat-error
                        *ngIf="resetPasswordFormControl.hasError('minlength')">{{ 'Password_too_short' | translate:{length: PASSWORD_MIN_LENGTH} }}</mat-error>
                    <button (click)="showResetPasswordPassword = !showResetPasswordPassword"
                            [matTooltip]="(showResetPasswordPassword ? 'Hide' : 'Show') | translate"
                            mat-icon-button
                            matSuffix>
                        <mat-icon>{{ showResetPasswordPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                    </button>
                </mat-form-field>
                <!--
                <mat-form-field *ngIf="!currentUser.isLoggedIn()"
                                color="primary">
                    <input #resetPassword2Control
                            matInput
                            maxlength="255"
                            placeholder="{{ 'Repeat_password' | translate }}"
                            (keyup.enter)="onResetPasswordClicked($event)"
                            [formControl]="resetPassword2FormControl"
                            autocomplete="off"
                            type="password" />
                    <mat-error *ngIf="resetPassword2FormControl.invalid">{{ 'Passwords_dont_match' | translate }}</mat-error>
                </mat-form-field>
                -->
            </div>
            <div class="actions">
                <button (click)="onResetPasswordClicked($event)"
                        [disabled]="isLoading || resetPasswordFormControl.invalid || resetPassword2FormControl.invalid"
                        color="accent"
                        mat-raised-button>
                    {{ 'Send' | translate }}
                </button>
            </div>
        </div>
        <!-- ------------------------------------------------------------ REGISTER ------------------------------ -->
        <!--<div class="step" [@translateFadeBeforeAfter]="registerStepState">-->
        <div *ngIf="registerStepState == 'active'"
             class="step">
            <div class="title">
                <div>{{ 'Registration' | translate }}</div>
                <div (click)="onNotYouClicked()" class="sub link">{{ emailFormControl.value }}</div>
            </div>
            <div class="input">
                <div class="in-line">
                    <mat-form-field color="primary">
                        <input #registerFirstNameControl
                               (keyup.enter)="onCheckRegisterClicked($event)"
                               [formControl]="registerFirstNameFormControl"
                               matInput
                               maxlength="255"
                               placeholder="{{ 'First_name' | translate }}"
                               type="text"/>
                        <span matPrefix><mat-icon>perm_identity</mat-icon> &nbsp;</span>
                        <mat-error
                            *ngIf="registerFirstNameFormControl.hasError('required')">{{ 'First_name_required' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary">
                        <input #registerLastNameControl
                               (keyup.enter)="onCheckRegisterClicked($event)"
                               [formControl]="registerLastNameFormControl"
                               matInput
                               maxlength="255"
                               placeholder="{{ 'Last_name' | translate }}"
                               type="text"/>
                        <mat-error
                            *ngIf="registerLastNameFormControl.hasError('required')">{{ 'Last_name_required' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field *ngIf="!currentUser.isLoggedIn()" color="primary">
                    <input #registerPasswordControl
                           (keyup.enter)="onCheckRegisterClicked($event)"
                           [formControl]="registerPasswordFormControl"
                           autocomplete="off"
                           matInput
                           maxlength="255"
                           placeholder="{{ 'Password' | translate }}"
                           type="password"/>
                    <span matPrefix><mat-icon>lock_outline</mat-icon> &nbsp;</span>
                    <mat-hint>{{ 'New_password_hint' | translate }}</mat-hint>
                    <mat-error
                        *ngIf="registerPasswordFormControl.hasError('required')">{{ 'Password_required' | translate }}</mat-error>
                    <mat-error
                        *ngIf="registerPasswordFormControl.hasError('minlength')">{{ 'Password_too_short' | translate:{length: PASSWORD_MIN_LENGTH} }}</mat-error>
                </mat-form-field>
                <div class="remember-box">
                    <mat-checkbox [(ngModel)]="rememberMe"
                                  color="primary">{{ 'Remember_me' | translate }}</mat-checkbox>
                </div>
            </div>
            <div class="actions">
                <button (click)="onCheckRegisterClicked($event)"
                        [disabled]="isLoading
                        || registerPasswordFormControl.hasError('required')
                        || registerPasswordFormControl.hasError('minlength')
                        || registerFirstNameFormControl.hasError('required')
                        || registerLastNameFormControl.hasError('required') ?
                            '' : null"
                        color="accent"
                        mat-raised-button>
                    {{ 'Next' | translate }}
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="progress-container">
    <mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
</div>
