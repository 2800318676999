<div [class.no-left-menu]="!hasLeftMenu" class="line logo">
    <img [src]="conf.assetsUrl + 'img/sbv-logo-neg.svg'"/>
</div>
<div [class.no-left-menu]="!hasLeftMenu" class="line address">
    <div>Platform operated by</div>
    <div>Swiss Biotech Association</div>
</div>
<div *ngIf="conf.helpEmail"
     [class.no-left-menu]="!hasLeftMenu"
     class="line">
</div>

<div [class.no-left-menu]="!hasLeftMenu" class="line">
    <a class="social-icon icon-facebook" href="https://www.facebook.com/www.swissbiotech.org/" target="_blank">
        <fa-icon [icon]="faFacebook"></fa-icon>
    </a>
    <a class="social-icon icon-twitter" href="https://twitter.com/swissbiotech" target="_blank">
        <fa-icon [icon]="faTwitter"></fa-icon>
    </a>
    <a class="social-icon icon-linkedin" href="https://www.linkedin.com/company/swiss-biotech-association"
       target="_blank">
        <fa-icon [icon]="faLinkedin"></fa-icon>
    </a>
</div>

<div [class.no-left-menu]="!hasLeftMenu" class="line line-copyright">
    <!--
    <div><a href="{{ conf.developerLink }}" target="_blank">{{ 'Developped_by' | translate:{developer: conf.developer} }}</a></div>
    -->
    <div>&copy; {{ year }}, Swiss Biotech Association - <a href="https://www.swissbiotech.org/imprint/" target="_blank">Imprint</a>
    </div>
</div>
