import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../providers/utils';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
    constructor(
        private _utils: Utils
    ) {
    }

    public transform(str: string, params: DurationParams): string {
        let num: number = parseFloat(str);
        if (!num) {
            return str;
        }

        if (!params.input) {
            params.input = 'minutes';
        }
        if (!params.output) {
            params.output = 'hours';
        }
        if (!params.precision) {
            params.precision = 1;
        }

        const conversion: any = {
            miliseconds: {ordinal: 0, divider: 1000},
            seconds: {ordinal: 1, divider: 60},
            minutes: {ordinal: 2, divider: 60},
            hours: {ordinal: 3, divider: 24},
            days: {ordinal: 4, divider: 7},
            weeks: {ordinal: 5, divider: 1}
        };
        const index: string[] = ['miliseconds', 'seconds', 'minutes', 'hours', 'days', 'weeks'];

        const from: any = conversion[params.input];
        const to: any = conversion[params.output];

        if (from.ordinal < to.ordinal) {
            for (let i = conversion[params.output].ordinal - 1; i >= conversion[params.input].ordinal; i--) {
                num /= conversion[index[i]].divider;
            }
        } else if (from.ordinal > to.ordinal) {
            for (let i = conversion[params.output].ordinal; i < conversion[params.input].ordinal; i++) {
                num *= conversion[index[i]].divider;
            }
        }

        return '' + (Math.round(num * (10 * params.precision))) / (params.precision > 0 ? (10 * params.precision) : 1);
    }
}

export interface DurationParams {
    input: string;
    output: string;
    precision: number;
}
