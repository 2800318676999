import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class Conf {

    //
    //
    // MISC
    //
    //

    public prodMode: boolean;
    public platformName: string = 'Swiss Biotech Ventures';
    public googleMapsApiKey: string = '';
    public developer: string = 'tokiwi';
    public developerLink: string = 'https://tokiwi.ch';
    public isRegistrationDisabled: boolean = true;
    public helpEmail: string = null;

    //
    //
    // URLs & APIs
    //
    //

    public apiRootUrl: string;
    public storageRootUrl: string;
    public apiPasswordGrantClient: number;
    public apiPasswordGrantSecret: number;
    public facebookPageUrl: string;
    public assetsUrl: string;
    public homepageRedirect: string;

    //
    //
    // STORAGE
    //
    //

    public defaultKeyValueStore = 'memory';

    //
    //
    // LOCALES
    //
    //

    public availableLangs: any[] = [
        {id: 1, name: 'English', code: 'en', dateLocale: 'en-GB'},
        // { id: 2, name: 'Français', code: 'fr', dateLocale: 'fr-FR' },
        // { id: 3, name: 'Deutsch', code: 'de', dateLocale: 'de-DE' }
    ];

    public defaultLang: any = this.availableLangs[0];

    //
    //
    // ENVIRONNEMENTS (constantes privées pour être utilisées par proxy)
    //
    //

    private _env: string = environment.stage;
    private _envConfigs: Object = {
        dev: {
            prodMode: false,
            apiRootUrl: '/api/',
            storageRootUrl: '/api/storage/',
            assetsUrl: '/assets/res_8_5/',
            apiPasswordGrantClient: 4,
            apiPasswordGrantSecret: 'QBpuSvwmzzZvAHVr1RQIoWp04jH2xPBYH1s40BKg',
            facebookPageUrl: 'https://facebook.com',
            homepageRedirect: null,
            helpEmail: 'help@academic-ventures.org'
        },
        docker: {
            prodMode: false,
            apiRootUrl: '/api/',
            storageRootUrl: '/api/storage/',
            assetsUrl: '/assets/res_8_5/',
            apiPasswordGrantClient: 4,
            apiPasswordGrantSecret: 'QBpuSvwmzzZvAHVr1RQIoWp04jH2xPBYH1s40BKg',
            facebookPageUrl: 'https://facebook.com',
            homepageRedirect: null,
            helpEmail: 'help@academic-ventures.org'
        },
        staging: {
            prodMode: true,
            apiRootUrl: '/api/',
            storageRootUrl: '/api/storage/',
            assetsUrl: '/assets/res_8_5/',
            apiPasswordGrantClient: 4,
            apiPasswordGrantSecret: 'QBpuSvwmzzZvAHVr1RQIoWp04jH2xPBYH1s40BKg',
            facebookPageUrl: 'https://facebook.com/',
            homepageRedirect: null,
            helpEmail: 'help@academic-ventures.org'
        },
        prod: {
            prodMode: true,
            apiRootUrl: '/api/',
            storageRootUrl: '/api/storage/',
            assetsUrl: '/assets/res_8_5/',
            apiPasswordGrantClient: 4,
            apiPasswordGrantSecret: 'QBpuSvwmzzZvAHVr1RQIoWp04jH2xPBYH1s40BKg',
            facebookPageUrl: 'https://facebook.com/',
            homepageRedirect: null,
            helpEmail: 'help@academic-ventures.org'
        },
    };

    constructor() {
        this.prodMode = this._envConfigs[this._env].prodMode;
        this.apiRootUrl = this._envConfigs[this._env].apiRootUrl;
        this.storageRootUrl = this._envConfigs[this._env].storageRootUrl;
        this.assetsUrl = this._envConfigs[this._env].assetsUrl;
        this.apiPasswordGrantClient = this._envConfigs[this._env].apiPasswordGrantClient;
        this.apiPasswordGrantSecret = this._envConfigs[this._env].apiPasswordGrantSecret;
        this.facebookPageUrl = this._envConfigs[this._env].facebookPageUrl;
        this.homepageRedirect = this._envConfigs[this._env].homepageRedirect;
        this.helpEmail = this._envConfigs[this._env].helpEmail;
    }
}
