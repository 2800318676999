<module-content>
    <div>
        <div class="title">{{ 'Contact' | translate }}</div>
        <div class="subtitle">{{ 'Contact_info' | translate }}</div>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        class="flex-3"
                        color="primary">
            <input (keyup)="onProjectFormChanged()"
                   [formControl]="contactFirstNameFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="('First_name' | translate) + ' *'"
                   id="step5_firstName"
                   matInput
                   type="text">
            <mat-error *ngIf="contactFirstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ contactFirstNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        class="flex-3"
                        color="primary">
            <input (keyup)="onProjectFormChanged()"
                   [formControl]="contactLastNameFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="('Last_name' | translate) + ' *'"
                   id="step5_lastName"
                   matInput
                   type="text">
            <mat-error *ngIf="contactLastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ contactLastNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <!--
        <mat-form-field color="primary"
                        [hintLabel]="'International_phone_number_hint' | translate">
            <input matInput
                   id="step5_phone"
                   type="text"
                   [formControl]="contactPhoneFormControl"
                   [maxlength]="PHONE_MAX_LENGTH"
                   [placeholder]="'Phone_number' | translate"
                   (keyup)="onProjectFormChanged()">
            <mat-error *ngIf="contactPhoneFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
        </mat-form-field>
        -->
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onProjectFormChanged()"
                   [formControl]="contactEmailFormControl"
                   [maxlength]="EMAIL_MAX_LENGTH"
                   [placeholder]="('Email' | translate) + ' *'"
                   id="step5_email"
                   matInput
                   type="text">
            <mat-error *ngIf="contactEmailFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ contactEmailFormControl.value.length }}/{{ EMAIL_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="onProjectFormChanged()"
                   [formControl]="contactWebsiteFormControl"
                   [maxlength]="WEBSITE_MAX_LENGTH"
                   [placeholder]="('Website' | translate) + ' *'"
                   id="step5_website"
                   matInput
                   type="text">
            <mat-error *ngIf="contactWebsiteFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ contactWebsiteFormControl.value.length }}/{{ WEBSITE_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
                  <textarea (keyup)="onProjectFormChanged()"
                            [formControl]="contactAddressFormControl"
                            [maxlength]="ADDRESS_MAX_LENGTH"
                            [placeholder]="('Address' | translate) + ' *'"
                            id="step5_address"
                            matInput
                            matTextareaAutosize></textarea>
            <mat-error *ngIf="contactAddressFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ contactAddressFormControl.value.length }}/{{ ADDRESS_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <ng-container *ngIf="showMore">
        <div>
            <div class="title">{{ 'Team' | translate }}</div>
            <div class="subtitle">{{ 'Team_info' | translate }}</div>
        </div>
        <div *ngFor="let control of teamMemberFormControls; let i = index;"
             class="line">
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onTeamNameFieldChanged(i)"
                       [formControl]="control.firstNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'First_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.firstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.firstNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onTeamNameFieldChanged(i)"
                       [formControl]="control.lastNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'Last_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.lastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.lastNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onTeamMembersFormChanged()"
                       [formControl]="control.functionFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'Function' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.functionFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.functionFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <button (click)="onRemoveTeamMemberClicked(i)"
                    *ngIf="teamMemberFormControls.length > 1"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'Remove' | translate"
                    mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="add-button-container">
            <button (click)="onAddTeamMemberClicked()"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'New_team_member' | translate"
                    color="accent"
                    mat-mini-fab>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="line">
            <input-image (change)="onProjectFormChanged()"
                         [(ngModel)]="teamImageData"
                         [allowedExtensions]="projectsProvider.ILLUSTRATION_FILE_TYPES"
                         [disabled]="!rights.EDIT"
                         [hint]="'Image_file_hint' | translate:{width: IMAGE_MAX_WIDTH, height: IMAGE_MAX_HEIGHT, size: IMAGE_MAX_SIZE, fileTypes: projectsProvider.ILLUSTRATION_FILE_TYPES.join(', ')}"
                         [maxFileSize]="IMAGE_MAX_SIZE"
                         [placeholder]="'Team_picture' | translate"
                         [previewImage]="teamImagePreview"
                         color="primary">
            </input-image>
        </div>
        <div>
            <div class="title">{{ 'Advisory_board' | translate }}</div>
            <div class="subtitle">{{ 'Advisory_board_info' | translate }}</div>
        </div>
        <div *ngFor="let control of boardMemberFormControls; let i = index;"
             class="line">
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onBoardNameFieldChanged(i)"
                       [formControl]="control.firstNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'First_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.firstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.firstNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onBoardNameFieldChanged(i)"
                       [formControl]="control.lastNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'Last_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.lastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.lastNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <button (click)="onRemoveBoardMemberClicked(i)"
                    *ngIf="boardMemberFormControls.length > 1"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'Remove' | translate"
                    mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="add-button-container">
            <button (click)="onAddBoardMemberClicked()"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'New_board_member' | translate"
                    color="accent"
                    mat-mini-fab>
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div>
            <div class="title">{{ 'Board_of_directors' | translate }}</div>
            <div class="subtitle">{{ 'Board_of_directors_info' | translate }}</div>
        </div>
        <div *ngFor="let control of directorsMemberFormControls; let i = index;"
             class="line">
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onDirectorsNameFieldChanged(i)"
                       [formControl]="control.firstNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'First_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.firstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.firstNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <input (keyup)="onDirectorsNameFieldChanged(i)"
                       [formControl]="control.lastNameFormControl"
                       [maxlength]="NAME_MAX_LENGTH"
                       [placeholder]="'Last_name' | translate"
                       matInput
                       type="text">
                <mat-error *ngIf="control.lastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ control.lastNameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <button (click)="onRemoveDirectorsMemberClicked(i)"
                    *ngIf="directorsMemberFormControls.length > 1"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'Remove' | translate"
                    mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="add-button-container">
            <button (click)="onAddDirectorsMemberClicked()"
                    [disabled]="!rights.EDIT"
                    [matTooltip]="'New_directors_member' | translate"
                    color="accent"
                    mat-mini-fab>
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
    <button (click)="onToggleShowMore()"
            color="accent"
            mat-button>
        {{ showMore ? ('Hide_optional_fields' | translate) : ('Show_n_more_optional_fields' | translate:{n: 5}) }}
    </button>
</module-content>
