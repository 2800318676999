import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Conf } from '../../../_conf';
import { IProjectCaseView, ProjectsProvider, } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { Utils } from '../../../_common/providers/utils';
import { ItemListDialogComponent } from '../../../_common/components/item-list-dialog/item-list-dialog';

@Component({
    selector: 'projects-view-project-case',
    providers: [],
    styleUrls: ['./view-project-case.scss'],
    templateUrl: './view-project-case.html'
})
export class ProjectsViewProjectCaseComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly P_MAX_LENGTH: number = 255;
    public readonly MAX_ITEMS_TO_DISPLAY: number = 1;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public showMore: boolean = false;
    @Input() public projectCase: IProjectCaseView;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _dialoger: MatDialog,
        private _utils: Utils,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onMoreProjectTypesClicked(): void {
        this._dialoger.open(ItemListDialogComponent, {
            data: {
                title: this._translater.instant('Type_of_project'),
                items: this._utils.getKeyValues(this.projectCase.projectTypes, 'name')
            }
        });
    }

    public onMoreTherapeuticAreasClicked(): void {
        this._dialoger.open(ItemListDialogComponent, {
            data: {
                title: this._translater.instant('Therapeutic_area'),
                items: this._utils.getKeyValues(this.projectCase.therapeuticArea, 'name')
            }
        });
    }
}
