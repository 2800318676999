import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { Utils } from '../../providers/utils';

@Component({
    selector: 'slider-control',
    providers: [],
    styleUrls: ['./slider-control.scss'],
    templateUrl: './slider-control.html'
})
export class SliderControlComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Output() public currentItemChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() public itemsCount: number = 1;
    @Output() public onSlided: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public conf: Conf,
        public utils: Utils,
        private _translater: TranslateService,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _currentItem: number = 1;

    public get currentItem() {
        return this._currentItem;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set currentItem(currentItem: number) {
        this._currentItem = currentItem;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onNextClicked(): void {
        if (this._currentItem >= this.itemsCount) {
            return;
        }

        const previous: number = this._currentItem;
        this._currentItem++;
        this.currentItemChange.emit(this._currentItem);
        this.onSlided.emit({previous, next: this._currentItem});
    }

    public onPreviousClicked(): void {
        if (this._currentItem <= 1) {
            return;
        }

        const previous: number = this._currentItem;
        this._currentItem--;
        this.currentItemChange.emit(this._currentItem);
        this.onSlided.emit({previous, next: this._currentItem});
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
