import { AfterViewInit, Component } from '@angular/core';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'page-content',
    providers: [],
    styleUrls: ['./page-content.scss'],
    templateUrl: './page-content.html'
})
export class PageContentComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
