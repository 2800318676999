import { AfterViewInit, Component, Input } from '@angular/core';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'full-screen-loader',
    providers: [],
    styleUrls: ['./full-screen-loader.scss'],
    templateUrl: './full-screen-loader.html'
})
export class FullScreenLoaderComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public progress: number = 0;
    @Input() public indeterminate: boolean = true;
    @Input() public message: string = 'Loading';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //
}
