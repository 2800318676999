import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { LangsProvider } from '../../_common/providers/langs-provider';
import { CurrentUser } from '../../_common/providers/current-user';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { InvestorRights } from './investor-rights';
import {
    Alert,
    Company,
    Company_DevelopmentPhase,
    Company_ProjectType,
    Company_TherapeuticArea,
    CompanyCategory,
    CompanyCategoryGroup,
    CompanyCompanyCategory,
    CompanyContact,
    DevelopmentPhase,
    DevelopmentPhaseOfInterest,
    InvestorProfile,
    InvestorValidationState,
    ProjectType,
    TherapeuticArea,
    TherapeuticAreaOfInterest,
    User
} from '../../_common/providers/models';
import { CompanyRights } from './company-rights';

@Injectable()
export class InvestorsProvider {

    //
    //
    // CONSTANTS
    //
    //

    public readonly COMPANY_NAME_MAX_LENGTH: number = 250;
    public readonly COMPANY_DESCRIPTION_MAX_LENGTH: number = 2000;
    public readonly COMPANY_INVESTMENT_COMMENT_MAX_LENGTH: number = 2000;
    public readonly COMPANY_CONTACT_NAME_MAX_LENGTH: number = 250;
    public readonly COMPANY_CONTACT_EMAIL_MAX_LENGTH: number = 250;
    public readonly COMPANY_CONTACT_PHONE_MAX_LENGTH: number = 250;
    public readonly COMPANY_CONTACT_DEFAULT_TEXT_LENGTH: number = 250;
    public readonly COMPANY_LOGO_MAX_SIZE: number = 2; // Mo
    public readonly COMPANY_LOGO_MAX_HEIGHT: number = 5000;
    public readonly COMPANY_LOGO_MAX_WIDTH: number = 5000;
    public readonly COMPANY_LOGO_FILE_TYPES: string[] = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public investorFiltersState: InvestorFiltersState = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _translater: TranslateService,
        private _router: Router,
        private _api: ApiBaseService,
        private _langs: LangsProvider,
        private _utils: Utils,
        private _currentUser: CurrentUser,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getCompanyCategoryGroups(): Promise<CompanyCategoryGoupWithCategories[]> {
        return new Promise<CompanyCategoryGoupWithCategories[]>((resolve, reject) => {
            Promise.all([
                this._storage.select(CompanyCategoryGroup).get(),
                this._storage.select(CompanyCategory).get()
            ])
                .then((data: any[]) => {
                    const items: CompanyCategoryGoupWithCategories[] = [];
                    for (let i = 0; i < data[0].length; i++) {
                        items.push({
                            group: data[0][i],
                            categories: this._utils.findManyIn([data[0][i].id], data[1], 'companyCategoryGroupId')
                        });

                    }
                    resolve(items);
                })
                .catch((error: any) => reject(error));
        });
    }

    public async saveInvestorProfile(investorProfile: InvestorProfile): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const data: any = await this._api.call(
                    'post',
                    'investors/' + investorProfile.id,
                    this._utils.buildModelParams([investorProfile], this._langs.getLangIds())[0],
                    this._currentUser.getAccessToken()
                );

                investorProfile = InvestorProfile.fromAPI(data.investorProfile);
                await this.updateInvestorAlert(data, 'investorPartnershipAlert');

                await this._storage.save([investorProfile]);

                resolve();

            } catch (e) {
                reject(e);
            }
        });
    }

    public saveTherapeuticAreaOfInterests(investorProfileId: number, therapeuticAreaIds: number[]): Promise<TherapeuticAreaOfInterest[] | void> {
        return new Promise<TherapeuticAreaOfInterest[] | void>((resolve, reject) => {
            let therapeuticAreaOfInterests: TherapeuticAreaOfInterest[] = [];
            for (let i = 0; i < therapeuticAreaIds.length; i++) {
                const item: TherapeuticAreaOfInterest = new TherapeuticAreaOfInterest();
                item.investorProfileId = investorProfileId;
                item.therapeuticAreaId = therapeuticAreaIds[i];
                therapeuticAreaOfInterests.push(item);
            }

            this._api.call(
                'post',
                'investors/' + investorProfileId + '/therapeutic-areas-of-interest',
                {therapeuticAreaOfInterests: this._utils.buildModelParams(therapeuticAreaOfInterests, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then(async (data: any) => {
                    therapeuticAreaOfInterests = TherapeuticAreaOfInterest.fromAPIList(data.therapeuticAreaOfInterests);

                    await this.updateInvestorAlert(data, 'investorTherapeuticAreaAlert');

                    this._storage.select(TherapeuticAreaOfInterest).where('investorProfileId').equals(investorProfileId).get()
                        .then((toDelete: TherapeuticAreaOfInterest[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(therapeuticAreaOfInterests)
                            ])
                                .then(() => resolve())
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public saveDevelopmentPhaseOfInterests(investorProfileId: number, developmentPhaseIds: number[]): Promise<DevelopmentPhaseOfInterest[] | void> {
        return new Promise<DevelopmentPhaseOfInterest[] | void>((resolve, reject) => {
            let items: DevelopmentPhaseOfInterest[] = [];
            for (let i = 0; i < developmentPhaseIds.length; i++) {
                const item: DevelopmentPhaseOfInterest = new DevelopmentPhaseOfInterest();
                item.investorProfileId = investorProfileId;
                item.developmentPhaseId = developmentPhaseIds[i];
                items.push(item);
            }

            this._api.call(
                'post',
                'investors/' + investorProfileId + '/development-phases-of-interest',
                {developmentPhaseOfInterests: this._utils.buildModelParams(items, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then(async (data: any) => {
                    items = DevelopmentPhaseOfInterest.fromAPIList(data.developmentPhaseOfInterests);

                    await this.updateInvestorAlert(data, 'investorDevelopmentPhaseAlert');

                    this._storage.select(DevelopmentPhaseOfInterest).where('investorProfileId').equals(investorProfileId).get()
                        .then((toDelete: DevelopmentPhaseOfInterest[]) => {
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(items)
                            ])
                                .then(() => resolve())
                                .catch((error: any) => reject(error));
                        })
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public validateProfile(investorProfile: InvestorProfile, stateId: number): Promise<InvestorProfile> {
        return new Promise<InvestorProfile>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/' + investorProfile.id + '/validate',
                {investorValidationStateId: stateId},
                this._currentUser.getAccessToken()
            )
                .then((data: any) => {
                    investorProfile = InvestorProfile.fromAPI(data.investorProfile);
                    this._storage.save([investorProfile])
                        .then(() => resolve(investorProfile))
                        .catch((error: any) => reject(error));
                })
                .catch((error: any) => reject(error));
        });
    }

    public registerAccount(firstName: string, lastName: string, email: string, password: string, companyId: number): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/account',
                {
                    firstName,
                    lastName,
                    email,
                    password,
                    companyId,
                }
            )
                // on stock pas localement les données parce qu'après cette opération, on serait censé lancé un sync ...
                .then((data: any) => resolve(User.fromAPI(data.user)))
                .catch((error: any) => reject(error));
        });
    }

    public saveCompany(company: Company): Promise<Company> {
        const data: any = this._utils.buildModelParams([company], this._langs.getLangIds())[0];
        data.item.isDisplayedOnHomepage = data.item.isDisplayedOnHomepage ? 1 : 0;
        data.item.isInterstedInLicenses = data.item.isInterstedInLicenses ? 1 : 0;
        data.item.isInterstedInPartnerships = data.item.isInterstedInPartnerships ? 1 : 0;
        data.item.isInvestingInPrivate = Number(data.item.isInvestingInPrivate);
        data.item.isInvestingInPublic = Number(data.item.isInvestingInPublic);
        return new Promise<Company>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/company/' + (company.id ? company.id : 0),
                data,
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    const newCompany: Company = Company.fromAPI(result.company);
                    this._storage.save([newCompany])
                        .then(() => resolve(newCompany))
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public saveCompanyTherapeuticAreas(companyId: number, therapeuticAreaIds: number[]): Promise<Company_TherapeuticArea[]> {
        return new Promise<Company_TherapeuticArea[]>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/company/' + companyId + '/therapeutic-areas',
                {therapeuticAreaIds},
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    const company_threapeuticAreas = Company_TherapeuticArea.fromAPIList(result.company_therapeuticAreas);
                    this._storage.save(company_threapeuticAreas)
                        .then(() => resolve(company_threapeuticAreas))
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public async saveCompanyProjectTypes(companyId: number, projectTypesIds: number[]): Promise<Company_ProjectType[]> {
        try {
            const result = await this._api.call('post', 'investors/company/' + companyId + '/project-types',
                {projectTypesIds},
                this._currentUser.getAccessToken()
            );
            const companyProjectTypes = await Company_ProjectType.fromAPIList(result.company_projectTypes);
            return await this._storage.save(companyProjectTypes);
        } catch (e) {
            return e;
        }
    }

    public saveCompanyDevelopmentPhases(companyId: number, developmentPhaseIds: number[]): Promise<Company_DevelopmentPhase[]> {
        return new Promise<Company_DevelopmentPhase[]>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/company/' + companyId + '/development-phases',
                {developmentPhaseIds},
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    const company_developmentPhases = Company_DevelopmentPhase.fromAPIList(result.company_developmentPhases);
                    this._storage.save(company_developmentPhases)
                        .then(() => resolve(company_developmentPhases))
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public saveCompanyContacts(companyId: number, companyContacts: CompanyContact[]): Promise<CompanyContact[]> {
        return new Promise<CompanyContact[]>((resolve, reject) => {
            this._api.call(
                'post',
                'investors/company/' + companyId + '/contacts',
                {companyContacts: this._utils.buildModelParams(companyContacts, this._langs.getLangIds())},
                this._currentUser.getAccessToken()
            )
                .then((result) => {
                    this._storage.select(CompanyContact).where('companyId').equals(companyId).get()
                        .then((toDelete) => {
                            const toSave: CompanyContact[] = CompanyContact.fromAPIList(result.companyContacts);
                            Promise.all([
                                this._storage.delete(toDelete),
                                this._storage.save(toSave)
                            ])
                                .then(() => resolve(toSave))
                                .catch(reject);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public deleteCompany(company: Company): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._api.call(
                'delete',
                'investors/company/' + company.id,
                {},
                this._currentUser.getAccessToken()
            )
                .then(() => {
                    Promise.all([
                        this._storage.select(Company_TherapeuticArea).where('companyId').equals(company.id).get(),
                        this._storage.select(Company_DevelopmentPhase).where('companyId').equals(company.id).get(),
                        this._storage.select(Company_ProjectType).where('companyId').equals(company.id).get()
                    ])
                        .then((relatedData: any[]) => {
                            Promise.all([
                                this._storage.delete([company]),
                                this._storage.delete(relatedData[0]),
                                this._storage.delete(relatedData[1]),
                                this._storage.delete(relatedData[2])
                            ])
                                .then(() => resolve())
                                .catch(reject);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    private async updateInvestorAlert(data: any, key: string) {
        if (data[key]) {
            const alert: Alert = Alert.fromAPI(data[key]);
            if (!alert.deleteDate) {
                return this._storage.save([alert]);
            } else {
                return this._storage.delete([alert]);
            }
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}

export interface CompanyCategoryGoupWithCategories {
    group: CompanyCategoryGroup;
    categories: CompanyCategory[];
}

export interface InvestorDataRow {
    user: User;
    profile: InvestorProfile;
    company: Company;
    validationState: InvestorValidationState;
    companyCategory: CompanyCompanyCategory;
    therapeuticAreasOfInterest: TherapeuticArea[];
    developmentPhasesOfInterest: DevelopmentPhase[];
    therapeuticAreasIdsOfInterest?: number[];
    developmentPhasesIdsOfInterest?: number[];
    rights: InvestorRights;
    isLoading?: boolean;
}

export interface CompanyDataRow {
    company: Company;
    companyCategory: CompanyCompanyCategory;
    companyContacts: CompanyContact[];
    therapeuticAreasOfInterest: TherapeuticArea[];
    developmentPhasesOfInterest: DevelopmentPhase[];
    projectTypesOfInterest: ProjectType[];
    therapeuticAreasIdsOfInterest?: number[];
    developmentPhasesIdsOfInterest?: number[];
    projectTypesIdsOfInterest?: number[];
    rights: CompanyRights;
    isLoading?: boolean;
}

export interface InvestorFiltersState {
    companyCategoryIds: number[];
    therapeuticAreaIds: number[];
    developmentPhaseIds: number[];
    projectTypeIds?: number[];
    investmentRangeFrom: number;
    investmentRangeTo: number;
    isSeekingPartnerships: number;
    isNotSeekingPartnerships: number;
    isOutlicensing: number;
    isNotOutlicensing: number;
    isNotForProfit: number;
    isNoNotForProfit: number;
    pageIndex: number;
}
