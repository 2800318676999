import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Conf } from '../../_conf';
import { SyncListener, SyncServiceProvider } from '../providers/sync-service';
import { CurrentUser } from '../providers/current-user';
import { LangsProvider } from '../providers/langs-provider';
import { NotificationsTodosProvider } from '../../notifications/providers/todos-provider';
import { DiscussionsProvider } from '../../discussions/providers/discussions-provider';

@Injectable()
export class IsAppReadyGuard implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _isReady: boolean = false;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService,
        private _currentUser: CurrentUser,
        private _router: Router,
        private _sync: SyncServiceProvider,
        private _conf: Conf,
        private _langs: LangsProvider,
        private _todos: NotificationsTodosProvider,
        private _discussions: DiscussionsProvider
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._isReady) {
                resolve(true);
            } else {
                this.initI18n();
                this._currentUser.tryLoginFromRemember()
                    .then(() => {
                        this._sync.start()
                            .then(() => {
                                Promise.all([
                                    this.initTodos(),
                                    this.initDiscussions()
                                ])
                                    .then(() => {
                                        this.onReady();
                                        resolve(true);
                                    })
                                    .catch((error: any) => {
                                        this.onReady();
                                        resolve(true);
                                    });
                            })
                            .catch((error) => {
                                this.onReady();
                                resolve(true);
                            });
                    });
            }
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private onReady(): void {
        this._translater.use(this._langs.getBestLangCode());
        this._isReady = true;
    }

    private initI18n(): void {
        this._translater.addLangs(this._langs.getLangCodes());
        this._translater.setDefaultLang(this._conf.defaultLang.code);
        this._translater.use(this._langs.getBestLangCode());
    }

    private registerSyncListeners(): void {
        let listeners: SyncListener[] = this._todos.getSyncListeners();
        listeners = listeners.concat(this._discussions.getSyncListeners());
        for (let i = 0; i < listeners.length; i++) {
            this._sync.addSyncListener(listeners[i]);
        }
    }

    private initTodos(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._todos.updateTodos();
            const listeners: SyncListener[] = this._todos.getSyncListeners();
            for (let i = 0; i < listeners.length; i++) {
                this._sync.addSyncListener(listeners[i]);
            }

            resolve();
        });
    }

    private initDiscussions(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._discussions.updatePrivateDiscussions();
            const listeners: SyncListener[] = this._discussions.getSyncListeners();
            for (let i = 0; i < listeners.length; i++) {
                this._sync.addSyncListener(listeners[i]);
            }

            resolve();
        });
    }

}
