<div class="title">{{ label }}</div>
<mat-radio-group [(ngModel)]="internalValue"
                 [disabled]="disabled">
    <mat-radio-button [color]="color"
                      [value]="true">
        {{ trueLabel }}
    </mat-radio-button>
    <mat-radio-button [color]="color"
                      [value]="false">
        {{ falseLabel }}
    </mat-radio-button>
</mat-radio-group>
