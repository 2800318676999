import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DiscussionsDiscussionComponent } from '../discussion/discussion';
import { Conf } from '../../../_conf';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ActivePrivateDiscussion, DiscussionsProvider } from '../../providers/discussions-provider';
import { CurrentUser } from '../../../_common/providers/current-user';

@Component({
    selector: 'discussions-private-discussion-window',
    providers: [],
    styleUrls: ['./private-discussion-window.scss'],
    templateUrl: './private-discussion-window.html'
})
export class DiscussionsPrivateDiscussionWindowComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Output() public activePrivateDiscussionChange: EventEmitter<ActivePrivateDiscussion> = new EventEmitter<ActivePrivateDiscussion>();
    @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('discussionComponent', {static: false}) public discussionComponent: DiscussionsDiscussionComponent;
    public discussionName: string = '';
    private _activePrivatediscussion: ActivePrivateDiscussion = null;

    constructor(
        public conf: Conf,
        public utils: Utils,
        public discussions: DiscussionsProvider,
        public currentUser: CurrentUser,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    public get activePrivateDiscussion() {
        return this._activePrivatediscussion;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set activePrivateDiscussion(data: ActivePrivateDiscussion) {
        this._activePrivatediscussion = data;
        this.updateDiscussionName();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {

    }

    public onToggleClicked(): void {
        this._activePrivatediscussion.isMinimized = !this._activePrivatediscussion.isMinimized;
        if (!this._activePrivatediscussion.isMinimized && this.discussionComponent) {
            this.discussionComponent.focusInput();
        }
        this.activePrivateDiscussionChange.emit(this._activePrivatediscussion);
        this.discussions.updateLastSeen(this.activePrivateDiscussion.data.privateDiscussion_user)
            .catch((error: any) => console.log(error));
    }

    public onCloseClicked(event: any): void {
        event.stopPropagation();
        this.onClose.emit();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateDiscussionName(): void {
        if (this.activePrivateDiscussion.data.privateDiscussion.isGroup) {
            this.discussionName = this.activePrivateDiscussion.data.privateDiscussion.name;
        } else {
            this.discussionName =
                this.activePrivateDiscussion.data.usersData[0].user.firstName +
                ' ' +
                this.activePrivateDiscussion.data.usersData[0].user.lastName;
        }
    }
}
