import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, QuillModule.forRoot(), ReactiveFormsModule, MatFormFieldModule, TranslateModule],
    exports: [RichTextEditorComponent],
    declarations: [RichTextEditorComponent],
})
export class RichTextEditorModule {
}
