import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { InvestorProfile, Project } from '../../../_common/providers/models';

@Component({
    selector: 'projects-favorites-page',
    providers: [],
    styleUrls: ['./favorites.scss'],
    templateUrl: './favorites.html'
})
export class ProjectsFavoritesPageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public projects: Project[] = [];
    public investorProfile: InvestorProfile = null;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _toaster: MatSnackBar,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this._title.setTitle(this._translater.instant('Favorites'));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
