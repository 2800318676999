import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../_common/providers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Conf } from '../../../_conf';
import { ProjectsProvider } from '../../providers/projects-provider';
import { HasPendingChangesGuard } from '../../../_common/guards/has-pending-changes';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { FilesProvider } from '../../../files/providers/files-provider';
import { ProjectRights } from '../../providers/project-rights';
import { Project } from '../../../_common/providers/models';

@Component({
    selector: 'projects-edit-project-partnership',
    providers: [],
    styleUrls: ['./edit-project-partnership.scss'],
    templateUrl: './edit-project-partnership.html'
})
export class ProjectsEditProjectPartnershipComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    public readonly PARTNERSHIP_TERMS_MAX_LENGTH: number = 2000;
    public readonly OUTLICENSING_MAX_LENGTH: number = 2000;

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;

    public isSeekingPartnership: boolean = null;
    public partnershipTermsFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.PARTNERSHIP_TERMS_MAX_LENGTH)
    ]);
    public isOutlicensing: boolean = null;
    public outlicensingFormControl: FormControl = new FormControl('', [
        Validators.maxLength(this.OUTLICENSING_MAX_LENGTH)
    ]);
    @Output() public projectChange: EventEmitter<Project> = new EventEmitter<Project>();
    @Input() public rights: ProjectRights = new ProjectRights(this._utils);
    @Output() onFormChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public projectsProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _toaster: MatSnackBar,
        private _utils: Utils,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changesGuard: HasPendingChangesGuard,
        private _router: Router
    ) {
    }

    private _project: Project = new Project();

    public get project() {
        return this._project;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set project(project: Project) {
        this._project = project;
        this.isSeekingPartnership = project.isSeekingPartnerships ? true : false;
        this.partnershipTermsFormControl.setValue(project.partnershipTerms ? project.partnershipTerms : '');
        this.isOutlicensing = project.isOfferingLicensing ? true : false;
        this.outlicensingFormControl.setValue(project.licensingOffer ? project.licensingOffer : '');
        this.updateRights();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public canSave(): boolean {
        return !this.partnershipTermsFormControl.invalid
            && !this.outlicensingFormControl.invalid;
    }

    public _onFormChanged(): void {
        this.fireChange();
        this._changesGuard.registerChanges();
        this.onFormChanged.emit();
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private updateRights(): void {
        if (this.rights.EDIT) {
            this.partnershipTermsFormControl.enable();
            this.outlicensingFormControl.enable();
        } else {
            this.partnershipTermsFormControl.disable();
            this.outlicensingFormControl.disable();
        }
    }

    private fireChange(): void {
        this._project.isSeekingPartnerships = this.isSeekingPartnership ? 1 : 0;
        this._project.partnershipTerms = this.partnershipTermsFormControl.value;
        this._project.isOfferingLicensing = this.isOutlicensing ? 1 : 0;
        this._project.licensingOffer = this.outlicensingFormControl.value;

        this.projectChange.emit(this._project);
    }

    private onError(error: any): void {
        this.isLoading = false;

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
        console.log(error);
    }
}
