<module-content [title]="'List_of_alerts' | translate" [tooltipText]="alertTooltip">
    <div *ngIf="dataSource.count === 0"
         class="legend">
        <p>{{ 'No_alert_yet' | translate }}</p>
        <button [routerLink]="'/academic-ventures'"
                color="accent"
                mat-raised-button>
            {{ 'Search_for_academic_ventures' | translate }}
        </button>
    </div>
    <mat-table (matSortChange)="onDataSortChange($event)"
               *ngIf="dataSource.count > 0"
               [dataSource]="dataSource"
               matSort>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns; let i = index;"></mat-row>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="name-cell"
                             mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="name-cell">
                <div [routerLink]="['/alerts', row.alert.id]"
                     class="link-button clickable">
                    {{ row.alert.name }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef class="hideable-500 date-cell"
                             mat-sort-header>{{ 'Creation_date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="hideable-500 date-cell">
                {{ row.alert.createDate | dateClosest:'FjY' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="actions-cell">
                <button *ngIf="!row.isLoading"
                        [matMenuTriggerFor]="rowMoreMenu"
                        mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #rowMoreMenu="matMenu"
                          xPosition="before">
                    <button (click)="onDeleteClicked(row)"
                            mat-menu-item>
                        {{ 'Delete' | translate }}
                    </button>
                </mat-menu>
                <mat-spinner *ngIf="row.isLoading"
                             [diameter]="24">
                </mat-spinner>
            </mat-cell>
        </ng-container>
    </mat-table>
    <mat-paginator [class.hidden]="dataSource.count == 0"
                   [hidePageSize]="true"
                   [pageSizeOptions]="[10]">
    </mat-paginator>
</module-content>
