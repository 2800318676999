import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUser } from '../../_common/providers/current-user';
import { IsLoggedInGuard } from '../../_common/guards/is-logged-in';

@Injectable()
export class CanSeeCompanies implements CanActivate {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _currentUser: CurrentUser,
        private _isLoggedIn: IsLoggedInGuard,
        private _router: Router
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._isLoggedIn.canActivate(route, state)
                .then((isLoggedIn) => {
                    if (isLoggedIn) {
                        const canAccess = this._currentUser.user.isAdmin
                            || this._currentUser.user.isProjectHolder;
                        if (!canAccess) {
                            this.redirect();
                        }
                        resolve(!!canAccess);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    this.redirect();
                    resolve(false);
                });
        });
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private redirect(): void {
        this._router.navigate(['401']);
    }

}
