<mat-form-field color="primary">
    <input (input)="onSearchChanged()"
           [formControl]="userFormControl"
           [matAutocomplete]="userAutoComplete"
           [placeholder]="'Person' | translate"
           matInput>
    <mat-autocomplete #userAutoComplete="matAutocomplete"
                      (optionSelected)="onUserSelected($event)"
                      [displayWith]="getUserName">
        <mat-option *ngFor="let user of filteredUsers | async"
                    [value]="user">
            <div class="available-item">
                <div class="image">
                    <img [src]="user.avatar ? conf.storageRootUrl + user.avatar : conf.assetsUrl + '/img/avatar.png'"
                         class="rounded"/>
                </div>
                <div class="name">{{ getUserName(user) }}</div>
            </div>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="userFormControl.invalid">{{ 'Select_a_valid_user' | translate }}</mat-error>
</mat-form-field>
