import { Injectable } from '@angular/core';
import { Utils } from '../../_common/providers/utils';
import { Conf } from '../../_conf';
import { ApiBaseService } from '../../_common/providers/api-base-service';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { CurrentUser } from '../../_common/providers/current-user';
import { ImageData } from '../../_common/components/input-image/input-image';

import { File } from '../../_common/providers/models';

@Injectable()
export class FilesProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _conf: Conf,
        private _utils: Utils,
        private _api: ApiBaseService,
        private _storage: ModelsStorage,
        private _currentUser: CurrentUser
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public downloadFile(api: string, file: File = null, options: any = null): Promise<any> {
        if (!options) {
            options = {};
        }
        const params: any = {};
        if (options.noCache) {
            params.noCache = (new Date()).getTime();
        }

        const downloadApi: string = file ? api + '/' + file.id : api;
        return new Promise<any>((resolve, reject) => {
            this._api.call(
                'get',
                downloadApi,
                params,
                {
                    accessToken: this._currentUser.getAccessToken(),
                    resultType: 'file'
                }
            )
                .then((data: any) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }

    public initPreviewImage(callback: any, imageData: ImageData, downloadApi: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!imageData.current) {
                resolve();
                return;
            }

            this.downloadFile(downloadApi, null, {noCache: true})
                .then((fileData: any) => {
                    // tiré de : https://stackoverflow.com/questions/45530752/getting-image-from-api-in-angular-4
                    const reader: FileReader = new FileReader();
                    reader.addEventListener('load', () => callback(reader.result), false);
                    reader.readAsDataURL(fileData);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
