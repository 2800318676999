<div class="section-2">
    <div [style.backgroundImage]="'url(\'' + conf.assetsUrl + 'img/home/section-2-background.jpg\')'"
         class="left">
    </div>
    <div class="right">
        <div class="container">
            <div class="title">
                <div>{{ 'home_section2_text1' | translate }}</div>
                <div>{{ 'home_section2_text2' | translate }}</div>
            </div>
            <p class="content">{{ 'home_section2_text3' | translate }}</p>
            <p class="content">{{ 'home_section2_text4' | translate }}</p>
            <div class="actions">
                <ng-content></ng-content>
                <button (click)="onMoreInformationClicked()"
                        *ngIf="showRoomMode == 'dialog'"
                        mat-button>
                    <mat-icon>info_outline</mat-icon>
                    <span>{{ 'More_information' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showRoomMode == 'normal'"
     class="section2-showroom showroom">
    <ng-container *ngFor="let showRoom of showRoomsData; let i = index">
        <acventures-common-home-showroom [icon]="showRoom.icon"
                                         [image]="showRoom.image"
                                         [orientation]="showRoom.orientation"
                                         [text]="showRoom.text | translate"
                                         [title]="showRoom.title | translate">
        </acventures-common-home-showroom>
        <mat-divider *ngIf="i < showRoomsData.length - 1"></mat-divider>
    </ng-container>
</div>
