<div class="line {{ orientation == 'left' ? 'left-right' : 'right-left' }}">
    <div class="image-container">
        <img [src]="image"/>
    </div>
    <div class="texts">
        <mat-icon color="accent">{{ icon }}</mat-icon>
        <div>
            <div class="title"> {{ title }}</div>
            <div>{{ text }}</div>
        </div>
    </div>
</div>
