import { AfterViewInit, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    providers: [],
    styleUrls: ['./confirm-dialog.scss'],
    templateUrl: './confirm-dialog.html'
})
export class ConfirmDialogComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public confirmText: string = '';
    public confirmActionText: string = '';
    public confirmColor: string = 'warn';

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public dialog: MatDialogRef<ConfirmDialogComponent>,
        private _translater: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data
    ) {
        if (_data && _data.confirmText) {
            this.confirmText = _data.confirmText;
        } else {
            this.confirmText = 'AreYouSureDeleteWarning';
        }

        if (_data && _data.confirmActionText) {
            this.confirmActionText = _data.confirmActionText;
        } else {
            this.confirmActionText = 'Delete';
        }

        if (_data && _data.confirmColor) {
            this.confirmColor = _data.confirmColor;
        }
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onConfirmClicked(): void {
        this.confirm(true);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    private confirm(confirmed: boolean): void {
        this.dialog.close(confirmed);
    }
}
