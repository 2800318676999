<module-content>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
            <input (keyup)="_onFormChanged()"
                   [formControl]="nameFormControl"
                   [maxlength]="NAME_MAX_LENGTH"
                   [placeholder]="('Name' | translate) + ' *'"
                   id="step0_name"
                   matInput
                   type="text">
            <mat-error *ngIf="nameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ nameFormControl.value.length }}/{{ NAME_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="line">
        <mat-form-field [hintLabel]="''"
                        color="primary">
              <textarea (keyup)="_onFormChanged()"
                        [formControl]="biotechUrlFormControl"
                        [maxlength]="BIOTECH_URL_MAX_LENGTH"
                        [placeholder]="('Biotech_Directory_Url' | translate)"
                        id="step0_biotech_url"
                        matInput
                        matTextareaAutosize></textarea>
            <mat-error *ngIf="biotechUrlFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
            <mat-hint align="end">{{ biotechUrlFormControl.value.length }}/{{ BIOTECH_URL_MAX_LENGTH }}</mat-hint>
        </mat-form-field>
    </div>
    <ng-container *ngIf="showMore">
        <div class="line">
            <mat-form-field [hintLabel]="''"
                            color="primary">
                <textarea (keyup)="_onFormChanged()"
                          [formControl]="pipelineFormControl"
                          [maxlength]="PIPELINE_MAX_LENGTH"
                          [placeholder]="'Pipeline' | translate"
                          matInput
                          matTextareaAutosize></textarea>
                <mat-error *ngIf="pipelineFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ pipelineFormControl.value.length }}/{{ PIPELINE_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
        <!--
        <div class="line">
            <mat-form-field color="primary"
                            [hintLabel]="'Keywords_hint' | translate">
                <input matInput
                       type="text"
                       [formControl]="keywordsFormControl"
                       [maxlength]="KEYWORDS_MAX_LENGTH"
                       [placeholder]="'Keywords' | translate"
                       (keyup)="_onFormChanged()">
                <mat-error *ngIf="keywordsFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                <mat-hint align="end">{{ keywordsFormControl.value.length }}/{{ KEYWORDS_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
        </div>
        -->
        <div class="line">
            <input-image (change)="_onFormChanged()"
                         [(ngModel)]="logoImageData"
                         [allowedExtensions]="projectsProvider.ILLUSTRATION_FILE_TYPES"
                         [disabled]="!rights.EDIT"
                         [hint]="'Image_file_hint' | translate:{width: projectsProvider.ILLUSTRATION_MAX_WIDTH, height: projectsProvider.ILLUSTRATION_MAX_HEIGHT, size: projectsProvider.ILLUSTRATION_MAX_SIZE, fileTypes: projectsProvider.ILLUSTRATION_FILE_TYPES.join(', ')}"
                         [maxFileSize]="projectsProvider.ILLUSTRATION_MAX_SIZE"
                         [placeholder]="'Logo' | translate"
                         [previewImage]="logoPreview"
                         color="primary">
            </input-image>
        </div>
        <div class="line extra-margin-bottom-550">
            <input-image (change)="_onFormChanged()"
                         [(ngModel)]="illustrationImageData"
                         [allowedExtensions]="projectsProvider.ILLUSTRATION_FILE_TYPES"
                         [disabled]="!rights.EDIT"
                         [hintLeft]="'Project_illustration_hint' | translate"
                         [hint]="'Image_file_hint' | translate:{width: projectsProvider.ILLUSTRATION_MAX_WIDTH, height: projectsProvider.ILLUSTRATION_MAX_HEIGHT, size: projectsProvider.ILLUSTRATION_MAX_SIZE, fileTypes: projectsProvider.ILLUSTRATION_FILE_TYPES.join(', ')}"
                         [maxFileSize]="projectsProvider.ILLUSTRATION_MAX_SIZE"
                         [placeholder]="'Main_illustration' | translate"
                         [previewImage]="illustrationPreview"
                         color="primary">
            </input-image>
        </div>
        <div class="line files-header">
            <mat-icon>attach_file</mat-icon>
            <span>{{ 'Upload_files_hint' | translate }}</span>
        </div>
        <div class="line">
            <files-files (onItemAdded)="onFilesAdded($event)"
                         (onItemRemoved)="onFileRemoved($event)"
                         (onItemRenamed)="onFileRenamed($event)"
                         [(files)]="files"
                         [currentUser]="currentUser.user"
                         [downloadApi]="'projects/' + project.id + '/files'"
                         [editionLevel]="FILES_EDITION_LEVEL"
                         [fileGroupId]="project.fileGroupId"
                         [hideHeadersWhenEmpty]="true"
                         [hideSizeColumn]="false"
                         [hideTypeColumn]="false"
                         [hintLabel]="'Upload_project_files_hint' | translate:{maxSize: FILE_MAX_SIZE}"
                         [isDisabled]="isLoading"
                         [isFoldersEnabled]="false"
                         [maxFileSize]="FILE_MAX_SIZE * 1024">
            </files-files>
        </div>
    </ng-container>
    <button (click)="onToggleShowMore()"
            color="accent"
            mat-button>
        {{ showMore ? ('Hide_optional_fields' | translate) : ('Show_n_more_optional_fields' | translate:{n: 8}) }}
    </button>
</module-content>
