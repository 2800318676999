import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../_common/providers/utils';
import { ModelsStorage } from '../../_common/providers/models-storage';
import { CurrentUser } from '../../_common/providers/current-user';
import { Notification, NotificationsProvider } from './notifications-provider';
import { SyncListener } from '../../_common/providers/sync-service';

@Injectable()
export class NotificationsTodosProvider {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _todosCollectors: any[] = []; // a function that return a Promise<Notification>

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        private _translater: TranslateService,
        private _utils: Utils,
        private _storage: ModelsStorage,
        private _currentUser: CurrentUser,
        private _notifications: NotificationsProvider
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public getSyncListeners(): SyncListener[] {
        const todoListener: SyncListener = {
            id: '',
            onCommonDataSynced: null,
            onUserDataSynced: () => {
                this.updateTodos();
            }
        };

        return [todoListener];
    }

    public updateTodos(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this._currentUser.isLoggedIn()) {
                const promises: Promise<Notification>[] = [];
                for (let i = 0; i < this._todosCollectors.length; i++) {
                    promises.push(this._todosCollectors[i]());
                }
                Promise.all(promises)
                    .then((results: any[]) => {
                        let notifications: Notification[] = [];
                        for (let i = 0; i < results.length; i++) {
                            notifications = notifications.concat(results[i]);
                        }
                        notifications.sort((a: Notification, b: Notification) => {
                            return a.date > b.date ? 1 : -1;
                        });

                        this._notifications.replaceAll(notifications);
                        resolve();
                    })
                    .catch((error) => reject(error));
            } else {
                this._notifications.clear();
                resolve();
            }
        });
    }

    public registerTodosCollector(collector: any): void {
        this._todosCollectors.push(collector);
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}
