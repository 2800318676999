import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { MenuItem } from '../../../_common/components/side-menu/side-menu';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import { IProjectCaseSearchResultRow, ProjectsProvider } from '../../../projects/providers/projects-provider';
import { Alert, } from '../../../_common/providers/models';
import { ListBaseComponent } from '../../../_common/components/list-base/list-base';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'alerts-alert-page',
    providers: [],
    styleUrls: ['./alert.scss'],
    templateUrl: './alert.html'
})
export class AlertsAlertPageComponent extends ListBaseComponent implements AfterViewInit {

    public backMenu: MenuItem = null;
    public alert: Alert = null;

    constructor(
        conf: Conf,
        currentUser: CurrentUser,
        navigation: AcventuresNavigationProvider,
        _title: Title,
        _router: Router,
        _dialoger: MatDialog,
        _utils: Utils,
        _toaster: MatSnackBar,
        _route: ActivatedRoute,
        _changeDetector: ChangeDetectorRef,
        _projectsProvider: ProjectsProvider,
        _translater: TranslateService,
        _storage: ModelsStorage
    ) {
        super(conf, currentUser, navigation, _title, _router, _dialoger, _utils,
            _toaster, _route, _changeDetector, _projectsProvider, _translater, _storage);
    }

    public ngAfterViewInit() {
        this._route.params.subscribe((params: any) => {
            Promise.all([
                super.loadData(),
                this.loadAlertData(parseInt(params.alertId))
            ])
                .then(() => {
                    this._title.setTitle(this.alert.name + ' | ' + this.conf.platformName);
                    this.backMenu = {
                        icon: 'arrow_back',
                        title: this._translater.instant('Alerts'),
                        url: '/alerts'
                    };
                    this.isLoading = false;
                })
                .catch((error: any) => this.onError(error));
        });
        super.ngAfterViewInit();
    }

    public onRowClicked(row: IProjectCaseSearchResultRow): void {
        this._router.navigate(['academic-ventures', row.project.id], {
            queryParams: {backUrl: this._router.url, backTitle: this.alert.name}
        });
    }

    private loadAlertData(alertId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._storage.select(Alert).where('id').equals(alertId).get(1)
                .then((data: Alert) => {
                    this.alert = data;
                    if (!this.alert) {
                        reject({status: 404});
                        return;
                    }

                    resolve();
                })
                .catch((error: any) => reject(error));
        });
    }

}
