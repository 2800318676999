import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'side-menu',
    providers: [],
    styleUrls: ['./side-menu.scss'],
    templateUrl: './side-menu.html'
})
export class SideMenuComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isMenuVisibleOnSmallScreen: boolean = false;
    public currentUrl: string = null;

    constructor(
        public conf: Conf,
        private _router: Router,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService
    ) {
    }

    private _menuGroups: MenuGroup[] = [];

    public get menuGroups() {
        return this._menuGroups;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set menuGroups(menuGroups: MenuGroup[]) {
        this._menuGroups = menuGroups;
        this._changeDetector.detectChanges();
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.currentUrl = this._router.url;
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onToggleMenuClicked(event: any): void {
        event.stopPropagation();
        this.isMenuVisibleOnSmallScreen = !this.isMenuVisibleOnSmallScreen;
    }

    public onMenuItemClicked(menu: MenuItem): void {
        if (menu.onClicked) {
            menu.onClicked();
        } else if (menu.url) {
            this._router.navigateByUrl(menu.url);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}

export interface MenuGroup {
    title: string;
    menus: MenuItem[];
}

export interface MenuItem {
    title: string;
    icon: string;
    url?: string;
    onClicked?: any;
    chip?: string;
    chipColor?: string;
    isLoading?: boolean;
}
