<header [backMenu]="null"
        [logoAlwaysDisplayed]="true"
        [pageSubtitle]="null"
        [pageTitle]=""></header>
<div class="big-wrapper">
    <div class="content">
        <h1>{{ 'Terms_and_conditions' | translate }}</h1>
        <p class="legend">This version of the Terms and Conditions (V1.0) for the Swiss Biotech Ventures Platform (SBVP)
            will come into effect on July 5, 2021. The platform is currently still being developed. The formal launch is
            planned for Sept. 7, 2021 in the context of the Swiss Biotech Day 2021 in Basel. </p>
        <p>The platform is developed and operated by the <a href="https://www.swissbiotech.org/imprint/"
                                                            target="_blank">Swiss Biotech Association</a>.</p>
        <p><span class="bold">Welcome to the Swiss Biotech Ventures Platform (SBVP)</span></p>
        <p>The SBVP provides a platform where biotech ventures can meet investors and industry partners. Users of this
            website may create an account as a “Biotech venture” (biotech venture companies looking for visibility,
            funding and/or partnership), or as an “Investor or industry partner” (who are looking for investment and
            partnering opportunities). Employees of investors and industry partners can establish their personal account
            which is linked to the investors/industry partners company account. Biotech ventures can see the
            investors/industry partner company information and vice versa.</p>
        <p>The main purpose of the SBVP is twofold:</p>
        <ol>
            <li>Increasing the transparency by listing the biotech investment opportunities in Switzerland and by
                disclosing the investment preferences of global investors and the partnering focus of industry partners.
            </li>
            <li>Facilitating the matchmaking so that biotech ventures and investors as well as industry partners avoid
                wasting time in their search for suitable investors/business partners and can contact each other easily.
            </li>
        </ol>
        <p>By using SBVP, including by simply viewing content on the site, you are agreeing that you, and each person
            you allow to access the site through your account, will abide by these Terms and Conditions. Your access to
            and use of the platform are conditioned on your acceptance of and compliance with this Terms and Conditions
            (Agreement).</p>
        <p>Two key principles shall apply for all users:</p>
        <ul>
            <li>Any information shared on the platform must be uploaded by the users themselves</li>
            <li>No confidential data shall be shared on the SBVP</li>
        </ul>

        <h2>Definitions</h2>
        <mat-divider></mat-divider>
        <h3>SWISS BIOTECH VENTURES</h3>
        <p>Swiss biotech ventures represent companies that develop innovative drug or cell-based products, technologies,
            devices, materials, diagnostics that help to avoid, monitor, treat or cure diseases.</p>
        <h3>Investors</h3>
        <p>Investors are individuals either working for public or private institutions that fund life science ventures
            (eg venture capital firms, foundations, banks) or invest directly for their own account (professional
            investors). Both institutions and individual investors must have a track record of significant investment in
            life science ventures (documentation to be provided upon request). Business angels investing via an angel
            community will be represented on SBVP by the management and the deal leaders of their community.</p>
        <h3>Industry partners</h3>
        <p>Industry partners are individuals working in the industry (in particular the biotech, medtech and pharma
            industry) and directly involved in the development of partnerships between industry and biotech projects and
            spin-offs (eg Search &amp; Evaluation or Business Development professionals).</p>

        <h2>User obligations</h2>
        <mat-divider></mat-divider>
        <p class="margin-top">You understand and confirm that:</p>
        <ol type="A">
            <li>Leader of biotech ventures
                <ol type="a">
                    <li>you have adequate rights to all copyrights, trademarks, trade secrets, intellectual property or
                        other material provided by you for display on SBVP;
                    </li>
                    <li>you will update your registration information as needed so that it remains true, correct and
                        complete;
                    </li>
                    <li>you have the right, authority, and capacity to enter into this Agreement on your own behalf and
                        on behalf on any entity for whom you are acting and to abide by all of the terms and conditions
                        contained herein, and that if any aspect of your participation in SBVP violates provisions of
                        the law to which you are subject, you will cease using the platform and close your account;
                    </li>
                    <li>you shall not use a name or address owned or controlled by another person with the intent to
                        impersonate that person or for any other reason;
                    </li>
                    <li>you shall not use a User ID name that is subject to any rights of a person other than yourself
                        without appropriate authorization;
                    </li>
                    <li>you shall be solely responsible for maintaining the confidentiality of your password;</li>
                    <li>you will be solely responsible for complying with applicable law regarding any transaction,
                        including without limitation the determination of whether any investor is an Accredited Investor
                        and whether any investment complies with the terms of local law;
                    </li>
                    <li>you will obtain such professional advice as is appropriate to protect your interests, including
                        legal, tax, accounting and other advice (i.e., get a good startup attorney);
                    </li>
                    <li>you shall not have access to details about investors registered on the platform, with the
                        exception of the name of the institution or company they work for;
                    </li>
                    <li>your profile is reviewed by the administrator or the local experts who reserve the right to
                        accept or decline a registration without further explanation.
                    </li>
                </ol>
            </li>
            <li class="margin-top">Investors, industry partners
                <ol type="a">
                    <li>you shall not use the platform to market own services (eg consulting) or contact biotech
                        ventures to offer unspecific services;
                    </li>
                    <li>you shall not use a false name, function, or email;</li>
                    <li>you shall not use a name or address owned or controlled by another person with the intent to
                        impersonate that person or for any other reason;
                    </li>
                    <li>your profile is reviewed by the administrator or the local experts who reserve the right to
                        accept or decline a registration without further explanation;
                    </li>
                    <li>you do not expect SBVP to evaluate, confirm or otherwise stand behind any user’s statements or
                        recommend any investment;
                    </li>
                    <li>SBVP does not, endorse, support, represent or guarantee the completeness, truthfulness, accuracy
                        or reliability of any content on SBVP;
                    </li>
                    <li>by using the platform, you may be exposed to content that might be inaccurate or otherwise
                        inappropriate, and that you have no claim against SBVP for any such material;
                    </li>
                    <li>you will use your own judgment before making any decision to invest or to accept an
                        investment;
                    </li>
                    <li>you are aware of the risks of investing and/or partnering with early biotech projects and
                        spin-offs;
                    </li>
                    <li>you will not copy or distribute content except as specifically allowed in this Agreement;</li>
                    <li>will hold the Company harmless against any damage that may happen to us as a result of your use
                        of SBVP;
                    </li>
                    <li>by registering on SBVP, or otherwise using the platform, you may have an opportunity to see
                        exclusive information although we disadvise all users to publish any confidential data. It is
                        expected that you will use discretion in determining what you do with that information. You
                        agree that you will not republish any information you acquire through the platform;
                    </li>
                    <li>your profile will not be shared with leaders of biotech ventures, with the exception of the name
                        of your institution or company.
                    </li>
                </ol>
            </li>
            <li class="margin-top">General
                <ol type="a">
                    <li>SBVP is not responsible for doing diligence on the ventures, investors, industry partners you
                        meet through SBVP or verifying any representations, materials or other information provided by
                        them;
                    </li>
                    <li>SBVP does not recommend any ventures for investment, endorse their fitness for investment or
                        verify or claim the accuracy of information provided by startups on the platform or in our
                        emails. In particular, we do not act as an investment adviser to any investor(s);
                    </li>
                    <li>SBVP does not control or endorse the content, messages or information found on the website,
                        therefore, SBVP specifically disclaims any responsibility with regard thereto;
                    </li>
                    <li>SBVP may, but is not required to, monitor, or control the content posted on the platform. Our
                        failure to exercise this right does not give you any right to make a claim against SBVP. Any
                        content that has been uploaded on the platform may be deleted at any time without notice to you;
                    </li>
                    <li>SBVP reserves the right to discontinue its services or to change its services in any way and at
                        any time, with or without notice to you, without liability;
                    </li>
                    <li>SBVP reserves the right to terminate your access to the platform without notice and, if you
                        violate this Agreement, to pursue other remedies at law or in equity. We may delete your account
                        for any reason or for no reason at all, and if we delete your account, you will lose all access
                        to any information, connections or other features that may have value to you that may have been
                        associated with your account;
                    </li>
                    <li>SBVP has the right to refuse registration of or cancel your user account in its discretion for
                        any reason or for no reason. In addition, the SBVP reserves the right at all times (but will not
                        have an obligation) to remove any content on the platform and to terminate users and/or reclaim
                        usernames. We also reserve the right to access, read, preserve, and disclose any information as
                        we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process
                        or governmental request, (ii) enforce this Agreement (including investigation of potential
                        violations hereof), (iii) detect, prevent or otherwise address fraud, security or technical
                        issues, (iv) respond to user support requests or (v) protect the rights, property or safety of
                        SBVP, its users and the public;
                    </li>
                    <li>Without limiting the generality of the foregoing, you specifically acknowledge that SBVP is
                        exempt from liability to any person for any claim based upon its termination of an account or
                        disabling of access to or removal of any content, including material it believes, in its sole
                        discretion to violate this Agreement, regardless of whether the material ultimately is
                        determined to be infringing or otherwise prohibited, and regardless of whether such termination
                        or disabling has the effect of reducing the value of any content or opportunities that might
                        otherwise have been available to you. By using the platform, you agree that notice to you
                        through an email to the email address you provided in your profile constitutes reasonable
                        efforts to notify you of any removal or disabling if such notice is required;
                    </li>
                    <li>SBVP has no obligation to monitor or enforce any intellectual property rights that may be
                        associated with content you provide to us, but SBVP does have the right to enforce such rights
                        through any means it sees fit, including bringing and controlling actions on your behalf.
                    </li>
                </ol>

            </li>
        </ol>
        <h2>Termination</h2>
        <mat-divider></mat-divider>
        <p class="margin-top">Unless terminated by SBVP, this Agreement will remain in full force and effect while you
            use any of the services. You may terminate this Agreement at any time by deleting all content you have
            provided to SBVP and ceasing to use the services. SBVP may terminate this Agreement at any time,
            particularly if you are suspected of violating any provision of this Agreement. Upon termination of this
            Agreement for any reason, you shall destroy and remove from all computers, and other storage media all
            copies of any intellectual property owned by SBVP or any other user of the services that you acquired via
            use of the platform. Your representations in this Agreement shall survive any termination or expiration of
            this Agreement.</p>
        <h2>Jurisdiction & applicable law</h2>
        <mat-divider></mat-divider>
        <p class="margin-top">Swiss Law shall apply to these Terms and Conditions. Place of jurisdiction is Zurich.</p>
    </div>
    <footer [hasLeftMenu]="false"></footer>
</div>
