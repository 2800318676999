<mat-spinner *ngIf="isLoading"
             [diameter]="24">
</mat-spinner>
<button (click)="onLikeClicked($event)"
        *ngIf="!isLoading"
        [color]="isUserLiking ? 'accent' : 'primary'"
        [disabled]="disabled || !likeAPI || !user"
        [matTooltip]="isUserLiking ? ('Unlike' | translate) : ('Like' | translate)"
        mat-icon-button
        matTooltipPosition="above">
    <mat-icon>thumb_up</mat-icon>
</button>
<div (click)="onCounterClicked($event)"
     [class.liked]="isUserLiking"
     [class.text-color-accent-face]="isUserLiking && !disabled"
     [class.text-color-disabled-face]="disabled"
     [class.text-color-primary-face]="!isUserLiking && !disabled"
     [matTooltip]="likersNames"
     class="counter"
     matTooltipClass="tooltip-multi-line"
     matTooltipPosition="above">
    {{ likeCounter_users.length }}
</div>
