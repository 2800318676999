import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../../_common/providers/utils';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { AcventuresNavigationProvider } from '../../../_acventures-common/providers/navigation-provider';
import {
    Company,
    DevelopmentPhaseOfInterest,
    InvestorProfile,
    TherapeuticAreaOfInterest
} from '../../../_common/providers/models';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'investors-welcome-page',
    providers: [],
    styleUrls: ['./welcome.scss'],
    templateUrl: './welcome.html'
})
export class InvestorsWelcomePageComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    public isLoading: boolean = false;
    public investorProfile: InvestorProfile = null;
    public company: Company = null;

    private hasTherapeuticAreaOfInterest: TherapeuticAreaOfInterest = null;
    private hasDevelopmentPhaseOfInterest: DevelopmentPhaseOfInterest = null;
    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        public currentUser: CurrentUser,
        public navigation: AcventuresNavigationProvider,
        private _title: Title,
        private _router: Router,
        private _utils: Utils,
        private _dialoger: MatDialog,
        private _toaster: MatSnackBar,
        private _route: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _translater: TranslateService,
        private _storage: ModelsStorage
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
        this.navigation.updateMenus();
        this.loadData()
            .then(() => {
                this.isLoading = false;
            })
            .catch((error: any) => this.onError(error));
        this._changeDetector.detectChanges();
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public isProfileCompleted(): boolean {
        return this.currentUser.user.email &&
            this.currentUser.user.firstName &&
            this.currentUser.user.lastName &&
            Boolean(this.investorProfile.phone) &&
            Boolean(this.hasTherapeuticAreaOfInterest) &&
            Boolean(this.hasDevelopmentPhaseOfInterest);
    }

    //
    //
    // PRIVATE METHODS
    //
    //

    public async loadData(): Promise<any> {
        try {
            this.investorProfile = await this._storage.select(InvestorProfile)
                .where('userId').equals(this.currentUser.user.id).get(1);
            this.company = await this._storage.select(Company)
                .where('id').equals(this.investorProfile.companyId).get(1);
            this.hasTherapeuticAreaOfInterest = await this._storage.select(TherapeuticAreaOfInterest)
                .where('investorProfileId').equals(this.investorProfile.id).get(1);
            this.hasDevelopmentPhaseOfInterest = await this._storage.select(DevelopmentPhaseOfInterest)
                .where('investorProfileId').equals(this.investorProfile.id).get(1);
        } catch (e) {
            this.onError(e);
        }
    }

    private onError(error: any): void {
        this.isLoading = false;

        console.log(error);

        this._toaster.open(this._translater.instant('Error_unknown'), '', {duration: 5000});
    }
}
