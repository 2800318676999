import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conf } from '../../../_conf';
import { CurrentUser } from '../../../_common/providers/current-user';
import { Utils } from '../../../_common/providers/utils';
import { ModelsStorage } from '../../../_common/providers/models-storage';
import { ProjectsProvider } from '../../providers/projects-provider';
import { FilesProvider } from '../../../files/providers/files-provider';

@Component({
    selector: 'projects-filter-block',
    providers: [],
    styleUrls: ['./filter-block.scss'],
    templateUrl: './filter-block.html'
})
export class ProjectsFilterBlockComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public label: string = '';
    @Input() public isInvalid: boolean = false;

    constructor(
        public conf: Conf,
        public utils: Utils,
        public currentUser: CurrentUser,
        private _projectProvider: ProjectsProvider,
        private _translater: TranslateService,
        private _filesProvider: FilesProvider,
        private _storage: ModelsStorage,
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _selectionLabel: string = null;

    public get selectionLabel() {
        return this._selectionLabel;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    @Input()
    public set selectionLabel(label: string) {
        this._selectionLabel = label;
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    //
    //
    // PRIVATE METHODS
    //
    //

    private onError(error: any): void {
        console.log(error);
    }
}
