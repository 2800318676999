import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Conf } from '../../../_conf';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'module-content',
    providers: [],
    styleUrls: ['./module-content.scss'],
    templateUrl: './module-content.html'
})
export class ModuleContentComponent implements AfterViewInit {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    @Input() public title: string = '';
    @Input() public tooltipText: string = undefined;
    @Input() public moreMenus: ModuleMenu[] = [];
    @Input() public shortcutMenus: ModuleMenu[] = [];
    @Input() public mainActions: ModuleMenu[] = [];
    @Input() public mainActionsPosition: 'top' | 'bottom' = 'bottom';
    @Input() public coverImage: string = null;
    @Input() public coverImageFit: string = 'cover';
    @Input() public coverImageBackgroundColor: string = '#000';
    @Input() public isLoading: boolean = false;
    @Input() public loaderPosition: 'top' | 'bottom' = 'bottom';
    @Input() public disableMainActionsWhenLoading: boolean = true;

    //
    //
    // CONSTRUCTOR
    //
    //

    constructor(
        public conf: Conf,
        private _router: Router,
        private _translater: TranslateService
    ) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    public ngAfterViewInit() {
    }

    //
    //
    // PUBLIC METHODS
    //
    //

    public onMenuClicked(menu: ModuleMenu): void {
        if (menu.onClick) {
            menu.onClick();
        } else if (menu.url) {
            this._router.navigateByUrl(menu.url);
        }
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}

export interface ModuleMenu {
    title: string;
    icon: string;
    url: string;
    onClick: any;
}
