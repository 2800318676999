<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [pageTitle]="'Administrators' | translate"
            header>
    </header>
    <error-401 *ngIf="!currentUser.user.isAdmin"></error-401>
    <users-list (onAddClicked)="onAddClicked()"
                (onDeleteRowClicked)="onDeleteRowClicked($event)"
                (onRowClicked)="onRowClicked($event)"
                *ngIf="currentUser.user.isAdmin"
                [addTitle]="'Add_administrator' | translate"
                [isAddEnabled]="rights.ADD_ADMIN"
                [noDataMessage]="'No_administrators'"
                [users]="users">
    </users-list>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
