import { MatTableDataSource } from '@angular/material/table';

export class SimpleMatDataSource extends MatTableDataSource<any> {

    //
    //
    // CONSTANTS
    //
    //

    //
    //
    // STATICS
    //
    //

    //
    //
    // ATTRIBUTES
    //
    //

    private _originalData: any[] = [];
    private _sortDefinitions: {} = {};
    private _sortState: SortState = null;

    constructor(_data: any[]) {
        super(_data);
        this._originalData = _data.slice();
    }

    public get count() {
        return this.data.length;
    }

    //
    //
    // CONSTRUCTOR
    //
    //

    public set count(count: number) {
    }

    //
    //
    // SUPER METHODS
    //
    //

    //
    //
    // PUBLIC METHODS
    //
    //

    public push(item: any): void {
        this._originalData.push(item);

        if (this._sortState) {
            this.sortBy(this._sortState.name, this._sortState.direction);
        } else {
            this.data = this._originalData.slice();
        }
    }

    public set(index: number, item: any): void {
        if (this._sortState) {
            for (let i = 0; i < this._originalData.length; i++) {
                if (this._originalData[i] == this.data[index]) {
                    this._originalData[i] = item;
                    i = this._originalData.length;
                }
            }

            this.sortBy(this._sortState.name, this._sortState.direction);
        } else {
            this._originalData[index] = item;
            this.data = this._originalData.slice();
        }
    }

    public get(index: number): any {
        return this.data[index];
    }

    public getAll(): any[] {
        return this.data;
    }

    public remove(index: number): void {
        const data: any[] = this.data.slice();
        if (this._sortState) {
            for (let i = 0; i < this._originalData.length; i++) {
                if (this._originalData[i] == data[index]) {
                    this._originalData.splice(i, 1);
                    i = this._originalData.length;
                }
            }
        } else {
            this._originalData.splice(index, 1);
        }

        data.splice(index, 1);

        this.data = data;
    }

    public replaceAllWith(data: any[]): void {
        this._originalData = data.slice();

        if (this._sortState) {
            this.sortBy(this._sortState.name, this._sortState.direction);
        } else {
            this.data = data.slice();
        }
    }

    public addSortDefinition(name: string, asc: (a: any, b: any) => number, desc: (a: any, b: any) => number): void {
        this._sortDefinitions[name] = {
            name,
            asc,
            desc
        };
    }

    public removeSortDefinition(name: string): void {
        delete this._sortDefinitions[name];
    }

    public sortBy(name: string, direction: 'asc' | 'desc' | ''): void {
        if (!this._sortDefinitions[name]) {
            return;
        }

        if (direction !== 'asc' && direction !== 'desc') {
            this.data = this._originalData.slice();
            this._sortState = null;

            return;
        }

        const data: any[] = this._originalData.slice();
        data.sort(this._sortDefinitions[name][direction]);
        this._sortState = {name, direction};

        this.data = data;
    }

    /**
     * Remove an element by his id. The element MUST HAVE an id attribute.
     *
     * @param id
     */
    public removeElementById(id): void {
        const filtered = this.data.filter(function(el) {
            return el.id != id;
        });

        this.replaceAllWith(filtered);
    }

    //
    //
    // PRIVATE METHODS
    //
    //
}

export interface SortDefinition {
    name: string;
    asc: (a: any, b: any) => number;
    desc: (a: any, b: any) => number;
}

export interface SortState {
    name: string;
    direction: 'asc' | 'desc';
}
