<mat-card>
    <img *ngIf="coverImage"
         [src]="coverImage"
         [style.background-color]="coverImageBackgroundColor"
         [style.object-fit]="coverImageFit"
         class="cover-image"
         mat-card-image/>
    <mat-card-title *ngIf="title || moreMenus.length > 0 || shortcutMenus.length > 0"
                    class="header">
        <div class="title">
            <span *ngIf="title">
                {{ title | translate }}
            </span>
            <mat-icon *ngIf="tooltipText" [matTooltip]="tooltipText" matTooltipClass="module-content-title-tooltip"
                      matTooltipPosition="right">info_outline
            </mat-icon>
        </div>
        <div>
            <button (click)="onMenuClicked(shortcut)"
                    *ngFor="let shortcut of shortcutMenus"
                    [matTooltip]="shortcut.title | translate"
                    class="shortcut-menu"
                    mat-icon-button>
                <mat-icon>{{ shortcut.icon }}</mat-icon>
            </button>
            <button *ngIf="moreMenus.length > 0"
                    [matMenuTriggerFor]="moreMenu"
                    mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu"
                      xPosition="before">
                <button (click)="onMenuClicked(menu)"
                        *ngFor="let menu of moreMenus"
                        mat-menu-item>
                    <mat-icon *ngIf="menu.icon">{{ menu.icon }}</mat-icon>
                    {{ menu.title | translate }}
                </button>
            </mat-menu>
        </div>
    </mat-card-title>
    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>
    <div *ngIf="mainActions.length > 0"
         [class.main-actions-bottom]="mainActionsPosition == 'bottom'"
         [class.main-actions-top]="mainActionsPosition == 'top'"
         class="main-actions">
        <button (click)="onMenuClicked(action)"
                *ngFor="let action of mainActions"
                [disabled]="disableMainActionsWhenLoading && isLoading"
                [matTooltipPosition]="mainActionsPosition == 'bottom' ? 'above' : 'below'"
                [matTooltip]="action.title | translate"
                color="accent"
                mat-fab>
            <mat-icon>{{ action.icon }}</mat-icon>
        </button>
    </div>
    <mat-progress-bar *ngIf="isLoading"
                      [class.loader-bottom]="loaderPosition == 'bottom'"
                      [class.loader-top]="loaderPosition == 'top'"
                      class="loader"
                      color="accent"
                      mode="indeterminate"></mat-progress-bar>
</mat-card>
