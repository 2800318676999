<page-content>
    <side-menu [menuGroups]="navigation.menus"
               left-menu>
    </side-menu>
    <header [backMenu]="!currentUser.user.isInvestor && backMenu"
            [pageTitle]="companyData?.company?.name ? companyData.company.name : ('Edit_company' | translate)"
            header>
    </header>
    <error-404 *ngIf="is404"></error-404>
    <error-401 *ngIf="is401"></error-401>
    <ng-container *ngIf="!is404 && !is401">
        <module-content [title]="'Company' | translate">
            <div class="line">
                <mat-form-field [hintLabel]="''"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="formGroup.nameFormControl"
                           [maxlength]="investorsProvider.COMPANY_NAME_MAX_LENGTH"
                           [placeholder]="('Company_name' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error *ngIf="formGroup.nameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ formGroup.nameFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_NAME_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]="''">
                    <mat-select (change)="onFormChanged()"
                                [formControl]="formGroup.companyCategoryFormControl"
                                [placeholder]="('Category' | translate) + '*'">
                        <mat-option *ngFor="let category of categories"
                                    [value]="category.id">
                            {{ category.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.companyCategoryFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <rich-text-editor
                (keyup)="onFormChanged()"
                [control]="formGroup.descriptionFormControl"
                [maxFieldLength]="investorsProvider.COMPANY_DESCRIPTION_MAX_LENGTH"
                [placeholder]="'Description' | translate">
            </rich-text-editor>
            <div class="line">
                <mat-form-field [hintLabel]="''"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="formGroup.websiteFormControl"
                           [placeholder]="('Website' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="formGroup.websiteFormControl.invalid && formGroup.websiteFormControl.value !== ''">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-error
                        *ngIf="formGroup.websiteFormControl.invalid && formGroup.websiteFormControl.value === ''">{{ 'No_website_validation' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-checkbox (change)="onCheckboxChanged()"
                              [(ngModel)]="noWebsite"
                              color="primary">
                    {{ 'No_website' | translate }}
                </mat-checkbox>
            </div>
            <div class="line">
                <input-image (change)="onFormChanged()"
                             [(ngModel)]="logoImageData"
                             [allowedExtensions]="investorsProvider.COMPANY_LOGO_FILE_TYPES"
                             [disabled]="noLogo"
                             [hint]="'Image_file_hint' | translate:{width: investorsProvider.COMPANY_LOGO_MAX_WIDTH, height: investorsProvider.COMPANY_LOGO_MAX_HEIGHT, size: investorsProvider.COMPANY_LOGO_MAX_SIZE}"
                             [imageHost]="conf.storageRootUrl"
                             [isRequired]="!noLogo && currentUser.user.isInvestor"
                             [maxFileSize]="investorsProvider.COMPANY_LOGO_MAX_SIZE"
                             [placeholder]="('Logo' | translate) + '*'"
                             color="primary">
                </input-image>
            </div>
            <div class="line">
                <mat-checkbox (change)="onCheckboxChanged()"
                              [(ngModel)]="noLogo"
                              color="primary">
                    {{ 'No_logo' | translate }}
                </mat-checkbox>
            </div>
        </module-content>
        <module-content [title]="'Investment_preferences' | translate">
            <div class="line">
                <mat-form-field>
                    <mat-select (change)="onFormChanged()"
                                [formControl]="formGroup.therapeuticAreaFormControl"
                                [placeholder]="('Therapeutic_areas_of_interest' | translate )  + '*'"
                                multiple>
                        <div class="all-none-container">
                            <button (click)="onAllTherapeuticAreasClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'All' | translate }}
                            </button>
                            <button (click)="onNoTherapeuticAreasClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'None' | translate }}
                            </button>
                        </div>
                        <mat-option *ngFor="let area of therapeuticAreas"
                                    [value]="area.id">
                            {{ area.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.therapeuticAreaFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field>
                    <mat-select (change)="onFormChanged()"
                                [formControl]="formGroup.developmentPhaseFormControl"
                                [placeholder]="('Development_phases_of_interest' | translate)"
                                multiple>
                        <div class="all-none-container">
                            <button (click)="onAllDevelopmentPhasesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'All' | translate }}
                            </button>
                            <button (click)="onNoDevelopmentPhasesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'None' | translate }}
                            </button>
                        </div>
                        <mat-option *ngFor="let phase of developmentPhases"
                                    [value]="phase.id">
                            {{ phase.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.developmentPhaseFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
            </div>

            <div class="line">

                <mat-form-field>
                    <mat-select (selectionChange)="onProjectTypeIdChanged()"
                                [formControl]="formGroup.projectTypeFormControl"
                                [placeholder]="('Type_of_project' | translate) + '*'"
                                id="step1_type"
                                multiple>
                        <div class="all-none-container">
                            <button (click)="onAllProjectTypesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'All' | translate }}
                            </button>
                            <button (click)="onNoProjectTypesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'None' | translate }}
                            </button>
                        </div>
                        <ng-container *ngFor="let item of projectTypeGroups">
                            <div (click)="onProjectTypeGroupClicked(item)"
                                 class="group-label">
                                <mat-icon>{{ getProjectGroupTypeIcon(item) }}</mat-icon>
                                <span>{{ item.group.name }}</span>
                            </div>
                            <mat-option *ngFor="let type of item.types"
                                        [value]="type.id"
                                        class="group-option">
                                {{ type.name }}
                            </mat-option>
                        </ng-container>
                        <div class="all-none-container">
                            <button (click)="onAllProjectTypesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'All' | translate }}
                            </button>
                            <button (click)="onNoProjectTypesClicked()"
                                    color="accent"
                                    mat-raised-button>
                                {{ 'None' | translate }}
                            </button>
                        </div>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.projectTypeFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>

            </div>

            <div>
                <div class="title">{{ 'Investment_range' | translate }}</div>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]="''"
                                class="flex"
                                color="primary">
                    <input (change)="onFormChanged()"
                           (keyup)="onInvestmentRangeChanged()"
                           [formControl]="investmentRangeFromFormControl"
                           [placeholder]="('Investment_range_from' | translate)"
                           matInput
                           type="number">
                    <span matPrefix>CHF&nbsp;</span>
                    <mat-error
                        *ngIf="investmentRangeFromFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hintLabel]="''"
                                class="flex"
                                color="primary">
                    <input (change)="onFormChanged()"
                           (keyup)="onInvestmentRangeChanged()"
                           [formControl]="investmentRangeToFormControl"
                           [placeholder]="('Investment_range_to' | translate)"
                           matInput
                           type="number">
                    <span matPrefix>CHF&nbsp;</span>
                    <mat-error
                        *ngIf="investmentRangeToFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]="''"
                                color="primary">
            <textarea (change)="onFormChanged()"
                      [formControl]="formGroup.investmentCommentFormControl"
                      [maxlength]="investorsProvider.COMPANY_INVESTMENT_COMMENT_MAX_LENGTH"
                      [placeholder]="('Comment' | translate)"
                      matInput
                      matTextareaAutosize></textarea>
                    <mat-error
                        *ngIf="formGroup.investmentCommentFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ formGroup.investmentCommentFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_INVESTMENT_COMMENT_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-checkbox (change)="onFormChanged()"
                              [(ngModel)]="isInterstedInPartnerships"
                              color="primary">
                    <span>{{ 'Intersted_in_partnerships' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="line">
                <mat-checkbox (change)="onFormChanged()"
                              [(ngModel)]="isInterstedInLicenses"
                              color="primary">
                    <span>{{ 'Intersted_to_in_licence_a_technology_of_product' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="line">
                <mat-checkbox (change)="onFormChanged()"
                              [(ngModel)]="isInvestingInPrivate"
                              color="primary">
                    <span>{{ 'Investing_in_private' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="line">
                <mat-checkbox (change)="onFormChanged()"
                              [(ngModel)]="isInvestingInPublic"
                              color="primary">
                    <span>{{ 'Investing_in_public' | translate }}</span>
                </mat-checkbox>
            </div>
        </module-content>

        <module-content [title]="'Company Address' | translate">
            <div class="line">
                <mat-form-field [hintLabel]=""
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.street1FormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH"
                           [placeholder]="('Street1' | translate)"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="contactInformationFormControl.street1FormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.street1FormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]=""
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.street2FormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH"
                           [placeholder]="'Street2' | translate"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="contactInformationFormControl.street2FormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.street2FormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]=""
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.cityFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH"
                           [placeholder]="('City' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="contactInformationFormControl.cityFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.cityFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]=""
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.countryFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH"
                           [placeholder]="('Country' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="contactInformationFormControl.countryFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.countryFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_DEFAULT_TEXT_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]=""
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.emailFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_EMAIL_MAX_LENGTH"
                           [placeholder]="('Email_switchboard' | translate) + '*' "
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="contactInformationFormControl.emailFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.emailFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_EMAIL_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="line">
                <mat-form-field [hintLabel]="'International_phone_number_hint' | translate"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="contactInformationFormControl.phoneFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH"
                           [placeholder]="('Phone_switchboard' | translate)"
                           matInput
                           type="text"
                    >
                    <mat-error
                        *ngIf="contactInformationFormControl.phoneFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ contactInformationFormControl.phoneFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
            </div>
        </module-content>

        <module-content [title]="'Contacts' | translate">
            <div *ngFor="let control of companyContactFormControls; let i = index;"
                 class="line">
                <mat-form-field [hintLabel]="''"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="control.firstNameFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH"
                           [placeholder]="('First_name' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error
                        *ngIf="control.firstNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ control.firstNameFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
                <mat-form-field [hintLabel]="''"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="control.lastNameFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH"
                           [placeholder]="('Last_name' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error *ngIf="control.lastNameFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ control.lastNameFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
                <mat-form-field [hintLabel]="''"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="control.emailFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_NAME_MAX_LENGTH"
                           [placeholder]="('Email' | translate) + '*'"
                           matInput
                           type="text">
                    <mat-error *ngIf="control.emailFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hintLabel]="'International_phone_number_hint' | translate"
                                color="primary">
                    <input (change)="onFormChanged()"
                           [formControl]="control.phoneFormControl"
                           [maxlength]="investorsProvider.COMPANY_CONTACT_PHONE_MAX_LENGTH"
                           [placeholder]="'Phone' | translate"
                           matInput
                           type="text">
                    <mat-error *ngIf="control.phoneFormControl.invalid">{{ 'Invalid_value' | translate }}</mat-error>
                    <mat-hint align="end">{{ control.phoneFormControl.value.length }}
                        /{{ investorsProvider.COMPANY_NAME_MAX_LENGTH }}</mat-hint>
                </mat-form-field>
                <button (click)="onRemoveCompanyContact(i)"
                        [matTooltip]="'Remove' | translate"
                        mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div *ngIf="companyContactFormControls.length === 0"
                 class="no-contact-legend">
                {{ 'No_company_contact' | translate }}
            </div>
            <div *ngIf="!companyContactExists" class="no-contact-validation">
                {{ 'No_contact_validation' | translate }}
            </div>
            <div class="add-button-container">
                <button (click)="onAddCompanyContactFormControl()"
                        [matTooltip]="'New_contact' | translate"
                        color="accent"
                        mat-mini-fab>
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </module-content>
        <module-content [title]="'Miscellaneous' | translate">
            <div class="line">
                <mat-checkbox (change)="onFormChanged()"
                              [(ngModel)]="isDisplayedOnHomepage"
                              color="primary">
                    <span>{{ 'Displayed_on_homepage' | translate }}</span>
                </mat-checkbox>
            </div>
        </module-content>
        <div>
            <button (click)="onDeleteClicked()"
                    *ngIf="rights.DELETE"
                    [disabled]="!canSave()"
                    color="warn"
                    mat-raised-button>
                <mat-icon>delete_outline</mat-icon>&nbsp;{{ 'Delete' | translate }}
            </button>
        </div>
        <main-action (onAction)="onSaveClicked()"
                     *ngIf="rights.EDIT"
                     [color]="currentUser.user.isInvestor ? (canSave() ? 'accent' : '') : 'accent'"
                     [displayMode]="'fab'"
                     [icon]="'check'"
                     [isLoading]="isSaveLoading"
                     [title]="'Save'">
        </main-action>
    </ng-container>
    <footer footer></footer>
</page-content>
<full-width-loader *ngIf="isLoading">
</full-width-loader>
